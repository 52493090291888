import React, { Component } from 'react'
import CheckSvg from './svg/CheckSvg';
import axios from 'axios';
import { MAIN_API } from '../MAIN_API';
import { mixinAlert } from '../actions/SweetAlerts';

export class SendVerifyBtn extends Component {
    state={
        loading: false,
        sendText:'Verify Email',
        disabled:false
    }
    sendVerifyEmailBtn=(data)=>{
        this.setState({loading:true, disabled:true});
        axios.post(`${MAIN_API}/email-verify-manual`, data, {
            headers:{"Authorization": `Bearer ${localStorage.getItem("userToken")}`}
        }).then(resp=>{
            this.setState({loading:false, sendText:'Email was sent.'});
            mixinAlert("success", "Verify email sent successfully. Please check your email")
        }).catch(err=>{
            this.setState({loading:false, disabled:false});
            mixinAlert("error", "Something went wrong");
        })
    }
  render() {
    const {data} = this.props;
    const {loading, sendText, disabled} = this.state;
    return (
      <div>
        {
            data?.email_verify=="0" ||  data?.email_verify=="1"?
            <div>
                {
                    data?.email_verify=="1"?
                    <span className='flex items-center gap-[10px] text-[14px] font-regular'>Email Verified <CheckSvg /> </span>:
                    <button disabled={disabled} onClick={this.sendVerifyEmailBtn.bind(this, data)} className='border border-[var(--blue)] rounded-[6px] text-[12px] font-semibold p-[4px_7px] text-[var(--blue)]'>{loading? 'Sending...': sendText}</button>
                }
            </div>:null
        }
      </div>
    )
  }
}

export default SendVerifyBtn
