import { initialState } from "../../../redux/initialState"; 
// const lang = initialState.lang;
export const getPages=(lang)=>{
    const managerRoleButtons = [
        {
            part: lang?.student_navs?.home,
            id: "default"
        },
        {
            part : lang?.student_navs?.part1,
            pages: [
                {
                    title: lang?.student_navs?.gen_english,
                    id: "unlock_exams"
                },
                {
                    title: lang?.student_navs?.gen_english_hw,
                    id: "unlock_hws"
                },
                {
                    title: lang?.student_navs?.essays,
                    id: "unlock_essays"
                },
                {
                    title: lang?.student_navs?.speakings,
                    id: "unlock_speakings"
                },
                {
                    title: lang?.student_navs?.a_level,
                    id: "unlock_ial_exams"
                },
            ]
        },
    
        {
            part: lang?.student_navs?.part2,
            pages:[
                {
                    title: lang?.student_navs?.gen_english_res,
                    id: "exam_results"
                },
                {
                    title: lang?.student_navs?.gen_english_hw_res,
                    id: "hw_results"
                },
                {
                    title: lang?.student_navs?.all_ielts,
                    id: "all_ielts"
                },
                {
                    title: lang?.student_navs?.sat_math,
                    id: "sat_math"
                },
                {
                    title: lang?.student_navs?.sat_math_hw,
                    id: "sat_math_hw"
                },
                {
                    title: lang?.student_navs?.sat_verbal,
                    id: "sat_verbal"
                },
                {
                    title: lang?.student_navs?.sat_verbal_hw,
                    id: "sat_verbal_hw"
                },
                {
                    title: lang?.student_navs?.mock_exam,
                    id: "mock_exam"
                },
                {
                    title: lang?.student_navs?.all_others,
                    id: "all_others"
                },
                {
                    title: lang?.student_navs?.all_others_pt,
                    id: "all_others_pt"
                },
                
            ]
        },
        {
            part:lang?.student_navs?.part3,
            pages: [
                {
                    title: lang?.student_navs?.essays,
                    id: "essay_review"
                },
                {
                    title: lang?.student_navs?.a_level,
                    id: "ial_ex_reviews"
                },
                {
                    title: lang?.student_navs?.sp_record,
                    id: "speakings"
                },
                {
                    title: lang?.student_navs?.sp_practice,
                    id: "sp_practice"
                },
            ]
        },
        {
            part: "other",
            pages:[
                {
                    title: lang?.student_navs?.payments,
                    id: "payments"
                },
                
                
                {
                    title: lang?.student_navs?.certs,
                    id: "certs"
                },
                {
                    title: lang?.student_navs?.reports,
                    id: "reports"
                },
                {
                    title: lang?.student_navs?.notes,
                    id: "notes"
                },
                {
                    title: lang?.student_navs?.logs,
                    id: "logs"
                },
                {
                    title: lang?.student_navs?.end_exam_sch,
                    id: "schedule"
                },
                
            ]
        }
        
    ];
    const superadminRoleButtons = [
        {
            part: lang?.student_navs?.home,
            id: "default"
        },
        {
            part : lang?.student_navs?.part1,
            pages: [
                {
                    title: lang?.student_navs?.gen_english,
                    id: "unlock_exams"
                },
                {
                    title: lang?.student_navs?.gen_english_hw,
                    id: "unlock_hws"
                },
                {
                    title: lang?.student_navs?.essays,
                    id: "unlock_essays"
                },
                {
                    title: lang?.student_navs?.speakings,
                    id: "unlock_speakings"
                },
                {
                    title: lang?.student_navs?.a_level,
                    id: "unlock_ial_exams"
                },
            ]
        },
    
        {
            part: lang?.student_navs?.part2,
            pages:[
                {
                    title: lang?.student_navs?.gen_english_res,
                    id: "exam_results"
                },
                {
                    title: lang?.student_navs?.gen_english_hw_res,
                    id: "hw_results"
                },
                {
                    title: lang?.student_navs?.all_ielts,
                    id: "all_ielts"
                },
                {
                    title: lang?.student_navs?.sat_math,
                    id: "sat_math"
                },
                {
                    title: lang?.student_navs?.sat_math_hw,
                    id: "sat_math_hw"
                },
                {
                    title: lang?.student_navs?.sat_verbal,
                    id: "sat_verbal"
                },
                {
                    title: lang?.student_navs?.sat_verbal_hw,
                    id: "sat_verbal_hw"
                },
                {
                    title: lang?.student_navs?.mock_exam,
                    id: "mock_exam"
                },
                {
                    title: lang?.student_navs?.all_others,
                    id: "all_others"
                },
                {
                    title: lang?.student_navs?.all_others_pt,
                    id: "all_others_pt"
                },
                
            ]
        },
        {
            part:lang?.student_navs?.part3,
            pages: [
                {
                    title: lang?.student_navs?.essays,
                    id: "essay_review"
                },
                {
                    title: lang?.student_navs?.a_level,
                    id: "ial_ex_reviews"
                },
                {
                    title: lang?.student_navs?.sp_record,
                    id: "speakings"
                },
                {
                    title: lang?.student_navs?.sp_practice,
                    id: "sp_practice"
                },
            ]
        },
        {
            part: "other",
            pages:[
                {
                    title: lang?.student_navs?.payments,
                    id: "payments"
                },
                
                
                {
                    title: lang?.student_navs?.certs,
                    id: "certs"
                },
                {
                    title: lang?.student_navs?.reports,
                    id: "reports"
                },
                {
                    title: lang?.student_navs?.notes,
                    id: "notes"
                },
                {
                    title: lang?.student_navs?.logs,
                    id: "logs"
                },
                {
                    title: lang?.student_navs?.end_exam_sch,
                    id: "schedule"
                },
                {
                    title: "Enrol Pay",
                    id: "enrol_payments"
                },
                
            ]
        }
        
    ];
    const officeManagerRoleButtons = [
        {
            part: lang?.student_navs?.home,
            id: "default"
        },
        {
            part : lang?.student_navs?.part1,
            pages: [
                {
                    title: lang?.student_navs?.gen_english,
                    id: "unlock_exams"
                },
                {
                    title: lang?.student_navs?.gen_english_hw,
                    id: "unlock_hws"
                },
                {
                    title: lang?.student_navs?.essays,
                    id: "unlock_essays"
                },
                {
                    title: lang?.student_navs?.speakings,
                    id: "unlock_speakings"
                },
                {
                    title: lang?.student_navs?.a_level,
                    id: "unlock_ial_exams"
                },
            ]
        },
    
        {
            part: lang?.student_navs?.part2,
            pages:[
                {
                    title: lang?.student_navs?.gen_english_res,
                    id: "exam_results"
                },
                {
                    title: lang?.student_navs?.gen_english_hw_res,
                    id: "hw_results"
                },
                {
                    title: lang?.student_navs?.all_ielts,
                    id: "all_ielts"
                },
                {
                    title: lang?.student_navs?.sat_math,
                    id: "sat_math"
                },
                {
                    title: lang?.student_navs?.sat_math_hw,
                    id: "sat_math_hw"
                },
                {
                    title: lang?.student_navs?.sat_verbal,
                    id: "sat_verbal"
                },
                {
                    title: lang?.student_navs?.sat_verbal_hw,
                    id: "sat_verbal_hw"
                },
                {
                    title: lang?.student_navs?.mock_exam,
                    id: "mock_exam"
                },
                {
                    title: lang?.student_navs?.all_others,
                    id: "all_others"
                },
                {
                    title: lang?.student_navs?.all_others_pt,
                    id: "all_others_pt"
                },
                
            ]
        },
        {
            part:lang?.student_navs?.part3,
            pages: [
                {
                    title: lang?.student_navs?.essays,
                    id: "essay_review"
                },
                {
                    title: lang?.student_navs?.a_level,
                    id: "ial_ex_reviews"
                },
                {
                    title: lang?.student_navs?.sp_record,
                    id: "speakings"
                },
                {
                    title: lang?.student_navs?.sp_practice,
                    id: "sp_practice"
                },
            ]
        },
        {
            part: "other",
            pages:[
                {
                    title: lang?.student_navs?.payments,
                    id: "payments"
                },
                {
                    title: lang?.student_navs?.certs,
                    id: "certs"
                },
                {
                    title: lang?.student_navs?.reports,
                    id: "reports"
                },
                {
                    title: lang?.student_navs?.notes,
                    id: "notes"
                },
                {
                    title: lang?.student_navs?.logs,
                    id: "logs"
                },
                {
                    title: lang?.student_navs?.end_exam_sch,
                    id: "schedule"
                },
            ]
        }
    ];
    const headTeacherRoleButtons = [
        // {
        //     part: lang?.student_navs?.unlock_hws,
        //     id: 'unlock_hws'
        // },
        {
            part: lang?.student_navs?.home,
            id: "default"
        },
        {
            part: lang?.student_navs?.part2,
            pages:[
                {
                    title: lang?.student_navs?.gen_english,
                    id: "exam_results"
                },
                {
                    title: lang?.student_navs?.gen_english_hw,
                    id: "hw_results"
                },
                {
                    title: lang?.student_navs?.all_ielts,
                    id: "all_ielts"
                },
                {
                    title: lang?.student_navs?.sat_math,
                    id: "sat_math"
                },
                {
                    title: lang?.student_navs?.sat_math_hw,
                    id: "sat_math_hw"
                },
                {
                    title: lang?.student_navs?.sat_verbal,
                    id: "sat_verbal"
                },
                {
                    title: lang?.student_navs?.sat_verbal_hw,
                    id: "sat_verbal_hw"
                },
                {
                    title: lang?.student_navs?.mock_exam,
                    id: "mock_exam"
                },
                {
                    title: lang?.student_navs?.all_others,
                    id: "all_others"
                },
                {
                    title: lang?.student_navs?.all_others_pt,
                    id: "all_others_pt"
                },
                
            ]
        },
        {
            part: lang?.student_navs?.part3,
            pages: [
                {
                    title: lang?.student_navs?.essays,
                    id: "essay_review"
                },
                {
                    title: lang?.student_navs?.a_level,
                    id: "ial_ex_reviews"
                },
                {
                    title: lang?.student_navs?.sp_record,
                    id: "speakings"
                },
                {
                    title: lang?.student_navs?.sp_practice,
                    id: "sp_practice"
                },
            ]
        },
        {
            part: "other",
            pages:[
                {
                    title: lang?.student_navs?.payments,
                    id: "payments"
                },
                {
                    title: lang?.student_navs?.certs,
                    id: "certs"
                },
                {
                    title: lang?.student_navs?.reports,
                    id: "reports"
                },
               
                {
                    title: lang?.student_navs?.other_reports,
                    id: "other_reports"
                },
                {
                    title: lang?.student_navs?.notes,
                    id: "notes"
                },
            ]
        }
       
    ]
    const teacherRoleButtons = [
        {
            part: lang?.student_navs?.home,
            id: "default"
        },
        // {
        //     part: lang?.student_navs?.unlock_hws,
        //     id: 'unlock_hws'
        // },
        {
            part: lang?.student_navs?.part2,
            pages:[
                {
                    title: lang?.student_navs?.gen_english,
                    id: "exam_results"
                },
                {
                    title: lang?.student_navs?.gen_english_hw,
                    id: "hw_results"
                },
                {
                    title: lang?.student_navs?.all_ielts,
                    id: "all_ielts"
                },
                {
                    title: lang?.student_navs?.sat_math,
                    id: "sat_math"
                },
                {
                    title: lang?.student_navs?.sat_math_hw,
                    id: "sat_math_hw"
                },
                {
                    title: lang?.student_navs?.sat_verbal,
                    id: "sat_verbal"
                },
                {
                    title: lang?.student_navs?.sat_verbal_hw,
                    id: "sat_verbal_hw"
                },
                {
                    title: lang?.student_navs?.mock_exam,
                    id: "mock_exam"
                },
                {
                    title: lang?.student_navs?.all_others,
                    id: "all_others"
                },
                {
                    title: lang?.student_navs?.all_others_pt,
                    id: "all_others_pt"
                },
                
            ]
        },
        {
            part: lang?.student_navs?.part3,
            pages: [
               
                {
                    title: lang?.student_navs?.essays,
                    id: "essay_review"
                },
                {
                    title: lang?.student_navs?.a_level,
                    id: "ial_ex_reviews"
                },
                {
                    title: lang?.student_navs?.sp_record,
                    id: "speakings"
                },
                {
                    title: lang?.student_navs?.sp_practice,
                    id: "sp_practice"
                },
            ]
        },
        {
            part: "other",
            pages:[
                {
                    title: lang?.student_navs?.payments,
                    id: "payments"
                },
                {
                    title: lang?.student_navs?.certs,
                    id: "certs"
                },
                {
                    title: lang?.student_navs?.reports,
                    id: "reports"
                },
                {
                    title: lang?.student_navs?.notes,
                    id: "notes"
                },
                
            ]
        }
       
    ]
    const supportRoleButtons=[
        {
            part : "other",
            pages: [
                {
                    title: lang?.student_navs?.gen_english,
                    id: "unlock_exams"
                },
               
                {
                    title: lang?.student_navs?.essays,
                    id: "unlock_essays"
                },
                {
                    title: lang?.student_navs?.speakings,
                    id: "unlock_speakings"
                },
                {
                    title: "Essay graded",
                    id: "essay_review"
                },
                {
                    title: "Enrol Pay",
                    id: "enrol_payments"
                },
                {
                    title: "Exam Results",
                    id: "supp_gen_exam"
                },
            ]
        },
    ]
    return {
        managerRoleButtons,
        headTeacherRoleButtons,
        officeManagerRoleButtons,
        teacherRoleButtons,
        supportRoleButtons,
        superadminRoleButtons

    }
}
