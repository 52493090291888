import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
export class Buttons extends Component {
  getAnswers(model, count){
    let answers = [];
    for(let i =0; i < count; i++){
      answers.push(model)
    }

    return answers
  }
  openQuestionModal(data, type){

    this.props.changeStateValue({
      name: 'answerCount',
      value: data.children.length +1
    })
 
    const item = {
      position: false,
      questionPositon: true,
      id: '',
      title: '',
      audio_file: null,
      description: '<p></p>',
      question_image: null,
      question_description: '',
      score: '',
      sub_type: '',
      type: type,
      question_option: [],
      video_file: null,
      video_link: null,
      exam_id: data.exam_id,
      parent_id: data.id
    }
    this.props.changeStateValue({
      name: 'adminExamModalData',
      value:item
    })
    this.props.changeStateValue({
      name: 'previousAnswers',
      value:item
    })
    if(type == 'single_choice' || type == 'single_image_type' || type == 'single_audio_type' || type == 'single_image_choice' || type == 'single_audio_type_with_image' || type == 'single_audio_with_image'){
      this.props.changeStateValue({
        name: 'adminExamModalData.question_option',
        value: this.getAnswers({id: '', type: type, check: false, points: 0, title:''}, 3)
      })
      this.props.changeStateValue({
        name: 'previousAnswers.question_option',
        value: this.getAnswers({id: '', type: type, check: false, points: 0, title:''}, 3)
      })
    }else if(type == 'single_boolean_type'){
      this.props.changeStateValue({
        name: 'adminExamModalData.question_option',
        value: [{id: '', type: type, check: false, points: 0, title:'true'},{id: '', type: type, check: false, points: 0, title:'false'}]
      })
      this.props.changeStateValue({
        name: 'previousAnswers.question_option',
        value: [{id: '', type: type, check: false, points: 0, title:'true'},{id: '', type: type, check: false, points: 0, title:'false'}]
      })
    }else if(type == 'multiple_choice'){
      this.props.changeStateValue({
        name: 'adminExamModalData.question_option',
        value: this.getAnswers({id: '', type: type, check: false, points: 0, title:''}, 4)
      })
      this.props.changeStateValue({
        name: 'previousAnswers.question_option',
        value: this.getAnswers({id: '', type: type, check: false, points: 0, title:''}, 4)
      })
    }else if(type == 'dropdown_question_type'){
      this.props.changeStateValue({
        name: 'adminExamModalData.question_option',
        value: this.getAnswers({id: '', type: type, check: false, points: 0, title:''}, 2)
      })
      this.props.changeStateValue({
        name: 'previousAnswers.question_option',
        value: this.getAnswers({id: '', type: type, check: false, points: 0, title:''}, 2)
      })
    }
    
  }
  openRoboQuestionModal(data){
    this.props.changeStateValue({
      name: 'modalRoboQuestion',
      value: {
        position: true,
        parentDesscription: data.title+" "+data?.description,
        parentId: data?.id
      }
    })
  }
  render() {
    const {data} = this.props;
    return (
      <div className='flex gap-[20px] flex-wrap mt-[20px]'>
        <button onClick={this.openQuestionModal.bind(this,data, 'single_choice')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Single choice</button>
        <button onClick={this.openRoboQuestionModal.bind(this, data)} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Single Choice Robo</button>
        <button onClick={this.openQuestionModal.bind(this,data, 'single_image_choice')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Single Image Choice</button>
        <button onClick={this.openQuestionModal.bind(this,data, 'single_image_type')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Single Image Type</button>
        <button onClick={this.openQuestionModal.bind(this,data, 'single_audio_type')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Single Audio Type</button>
        <button title="Single Audio Type With Image" onClick={this.openQuestionModal.bind(this,data, 'single_audio_type_with_image')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Single Aud. T. Img.</button>
        <button title="Single Audio With Image" onClick={this.openQuestionModal.bind(this,data, 'single_audio_with_image')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Single Aud. with Img.</button>
        <button onClick={this.openQuestionModal.bind(this,data, 'free_text')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Free Text</button>
        <button onClick={this.openQuestionModal.bind(this,data, 'multiple_choice')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Multiple Choice</button>
        <button onClick={this.openQuestionModal.bind(this,data, 'dropdown_question_type')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Dropdown Question</button>
        <button onClick={this.openQuestionModal.bind(this,data, 'single_boolean_type')} className='text-[16px] font-semibold txt-blue pt-[6px] pb-[6px] pl-[10px] pr-[10px] shadow-md rounded-[8px] bg-white'>Single Boolean Type</button>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Buttons)
