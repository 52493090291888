import React, { Component } from 'react'

export class CheckInTimeSvg extends Component {
  render() {
    return (
        <svg width="35" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.24146 12.5406C3.24146 10.7726 3.68346 9.13465 4.55879 7.63532C5.43412 6.13598 6.62146 4.94865 8.12079 4.07331C9.62012 3.19798 11.2495 2.76465 13.0088 2.76465C14.3261 2.76465 15.5915 3.02465 16.7961 3.53598C18.0008 4.04732 19.0321 4.74932 19.9075 5.61598C20.7828 6.48265 21.4761 7.52265 21.9875 8.73598C22.4988 9.94932 22.7588 11.206 22.7588 12.5406C22.7588 13.858 22.4988 15.1233 21.9875 16.328C21.4761 17.5326 20.7741 18.5726 19.9075 19.4393C19.0408 20.306 18.0008 20.9993 16.7961 21.5106C15.5915 22.022 14.3348 22.282 13.0088 22.282C11.6828 22.282 10.4088 22.022 9.20412 21.5106C7.99946 20.9993 6.95946 20.2973 6.08412 19.4306C5.20879 18.564 4.52412 17.524 4.00412 16.328C3.48412 15.132 3.24146 13.8666 3.24146 12.5406ZM5.39079 12.5406C5.39079 14.5946 6.13612 16.38 7.63546 17.8966C9.13479 19.396 10.9201 20.1413 13.0088 20.1413C14.3781 20.1413 15.6521 19.8033 16.8135 19.1186C17.9748 18.434 18.9108 17.5153 19.5955 16.3453C20.2801 15.1753 20.6268 13.91 20.6268 12.5406C20.6268 11.1713 20.2801 9.89732 19.5955 8.72731C18.9108 7.55731 17.9835 6.62998 16.8135 5.94532C15.6435 5.26065 14.3781 4.92265 13.0088 4.92265C11.6395 4.92265 10.3655 5.26065 9.20412 5.94532C8.04279 6.62998 7.10679 7.55731 6.41346 8.72731C5.72012 9.89732 5.39079 11.1713 5.39079 12.5406ZM12.2548 12.5406V6.76865C12.2548 6.56931 12.3241 6.39598 12.4628 6.25731C12.6015 6.11865 12.7748 6.04932 12.9741 6.04932C13.1735 6.04932 13.3468 6.11865 13.4855 6.25731C13.6241 6.39598 13.6935 6.56931 13.6935 6.76865V12.0986L16.8048 13.91C16.9781 14.014 17.0821 14.1613 17.1341 14.352C17.1861 14.5426 17.1601 14.7246 17.0561 14.8893C16.9175 15.132 16.7095 15.2533 16.4321 15.2533C16.2848 15.2533 16.1635 15.2186 16.0681 15.1493L12.7575 13.2166C12.6101 13.1733 12.4888 13.0866 12.3935 12.9653C12.2981 12.844 12.2548 12.7053 12.2548 12.5406Z" fill="#EE7100"/>
        </svg>        
    )
  }
}

export default CheckInTimeSvg
