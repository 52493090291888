import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import Header from '../../components/header/Header';
import { getOfficeStudents } from '../../actions/StaffAction';
import Card4 from '../../components/cards/Card4';
import { getRole } from '../../actions/getRole';
import StudentMoreModal from '../../components/modals/StudentMoreModal';
import Search from '../../components/Search';
import LoadMoreBtn from '../../components/LoadMoreBtn';
export class AuditorStudents extends Component {
    state={
        moreDataModal:{
            position: false
        },
        search:'',
        page:1
    }
    componentDidMount(){
        this.props.getOfficeStudents(1, '', this.props.params.id, "", "", ""); 
    }
    showMoreData=(data)=>{
        this.setState({
            moreDataModal: {position:true, ...data}
        })
    }

    onSearchData=(search)=>{
        this.setState({search});
        this.props.getOfficeStudents(this.state.page, search, this.props.params.id, "", "", ""); 
    }
    loadMore=(page)=>{
        this.setState({page});
        this.props.getOfficeStudents(page, this.state.search, this.props.params.id, "", "", "");
    }
  render() {
    const {officeStudents, lang, user} = this.props;
    const {moreDataModal} = this.state
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={this.props.params.company_name?.replaceAll("%20", " ")} />
            <div className=' max-[1100px]:mt-[120px] max-[800px]:mt-[150px] items-start p-[20px] rounded-[20px] mt-[40px]'>
                <div className='flex justify-center  max-[1100px]:mt-[120px] max-[800px]:mt-[170px] '>
                    <Search largWidth={true} onSearchData={this.onSearchData} />
                </div>
            <div className='mt-[42px] grid-full-250'>
            {
                officeStudents?.map((data, i)=>{
                return(
                    <Card4
                        key={i}
                        name={data?.full_name}
                        // addCertificate={this.addCertificateBtn.bind(this, data)}
                        // cert={true}
                        // email={data?.email}
                        phone={data?.phone_number}
                        image={data?.avatar_full_url}
                        role={getRole(data?.role, lang)}
                        headerBtn={true}
                        // onClickEdit={this.addUser.bind(this,data)}
                        lock={data?.attendance_lock_status === 0 && data?.manual_lock_status === 0? 1:0}
                        // stat={true}
                        info={`/students/${data?.id}`}
                        course={data?.student_enroll_class.length !==0? data?.student_enroll_class[0]?.lesson_mode:null}
                        // payMode={data.payment_Reminder_max_value === '12' ? "Standart":  "Intensive"}
                        // schYear={data.school_year}
                        teacher={data.teacher.length !== 0? data?.teacher[0]?.first_name+" "+data?.teacher[0]?.last_name:null}
                        // onClickLockUnlock={this.studentLockUnlock.bind(this, data)}
                        // onClickStat={()=>this.setState({statModal: {position: true, student_id:data?.id}})}
                        checkExam={user?.role === "teacher" || user?.role === "head_teacher"?true:false}
                        // onClickCheck={this.checkStudentExam.bind(this, data)}
                        onClickInfo={()=>{localStorage.setItem("goPayment", "true")}}
                        blank={true}
                        more={true}
                        onClickMore={this.showMoreData.bind(this, data)}
                        widthPermission={false}
                        editLink={`/students/${data?.id}/edit`}
                    />
                )
                })
            }
            </div>
            {
            officeStudents?.length >=20?
            <LoadMoreBtn onLoadData={this.loadMore} count={officeStudents?.length} />:null
            }
            </div>
            {moreDataModal?.position? <StudentMoreModal data={moreDataModal} closeBtn={()=>this.setState({moreDataModal:{position:false}})} />:null}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    officeStudents: state.Data.officeStudents,
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {getOfficeStudents}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AuditorStudents))
