import React, { Component } from 'react'

export class ExamSvg extends Component {
  render() {
    const {color = "#585874"} = this.props
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
      >
        <path
          fill={color}
          d="M7.5 5.625a.375.375 0 0 1 .375-.375h3a.375.375 0 0 1 0 .75h-3a.375.375 0 0 1-.375-.375Zm.375 1.125a.375.375 0 0 0 0 .75h3a.375.375 0 0 0 0-.75h-3ZM7.5 10.5a.375.375 0 0 1 .375-.375h3a.375.375 0 0 1 0 .75h-3A.375.375 0 0 1 7.5 10.5Zm.375 1.125a.375.375 0 1 0 0 .75h3a.375.375 0 0 0 0-.75h-3Z"
        />
        <path
          fill={color}
          fillRule="evenodd"
          d="M3.75 10.125a.375.375 0 0 1 .375-.375H6a.375.375 0 0 1 .375.375V12a.375.375 0 0 1-.375.375H4.125A.375.375 0 0 1 3.75 12v-1.875Zm.75.375v1.125h1.125V10.5H4.5Z"
          clipRule="evenodd"
        />
        <path
          fill={color}
          d="M6.64 5.89a.375.375 0 0 0-.53-.53L4.875 6.595 4.39 6.11a.375.375 0 0 0-.53.53l1.015 1.015L6.64 5.89Z"
        />
        <path
          fill={color}
          fillRule="evenodd"
          d="M3.75 2.25a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h7.5a1.5 1.5 0 0 0 1.5-1.5V3.75a1.5 1.5 0 0 0-1.5-1.5h-7.5ZM3 3.75A.75.75 0 0 1 3.75 3h7.5a.75.75 0 0 1 .75.75v10.5a.75.75 0 0 1-.75.75h-7.5a.75.75 0 0 1-.75-.75V3.75ZM13.5 6a1.125 1.125 0 1 1 2.25 0v7.614L14.625 15.3 13.5 13.614V6Zm1.125-.375A.375.375 0 0 0 14.25 6v.75H15V6a.375.375 0 0 0-.375-.375Zm0 8.324-.375-.563V7.5H15v5.886l-.375.563Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default ExamSvg
