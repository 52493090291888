import React, { Component } from 'react'
import { examCheckModel } from '../../../../actions/getReviewGpt';
import { connect } from 'react-redux';
import { getTurboModel } from '../../../../actions/robotActions';

export class ExamCheckBtn extends Component {
    state={
        answer:'',
        btnDisable:false
    }
    recheckBtn=(data)=>{
        this.setState({answer:'Sending...', btnDisable:true})
        let answ = "";
        for(const x of data?.answers){
            answ+= `${x?.title} `;
        }
        this.props.getTurboModel(examCheckModel(data?.title, data?.description, answ, this.props.adminExamQuestions.find(x=>x?.id == data?.parent_id)?.description))
        .then(resp=>{
            // console.log(resp)
            this.setState({answer:resp, btnDisable:false})
        })
        
    }
  render() {
    const {data, adminExamQuestions} = this.props;
    // console.log(adminExamQuestions)
    const {answer, btnDisable} = this.state;
    return (
      <div>
        <button disabled={btnDisable} onClick={this.recheckBtn.bind(this, data)} className='bg-blue text-[12px] font-regular p-[4px_7px] rounded-[5px] text-white'>Recheck</button>
        <p className='mt-[20px] text-[14px]'>{answer}</p>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  adminExamQuestions: state.Data.adminExamQuestions
})
const mapDispatchToProps = {getTurboModel}
export default connect(mapStateToProps, mapDispatchToProps)(ExamCheckBtn)
