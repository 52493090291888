import moment from 'moment';
import React, { Component } from 'react'
import LeftSvg from '../../../components/svg/LeftSvg';
import RightSvg from '../../../components/svg/RightSvg';
import CheckSvg from '../../../components/svg/CheckSvg';
import ExamSvg from '../../../components/svg/ExamSvg';
import CheckInModal from '../../../components/modals/CheckInModal';
import { connect } from 'react-redux';
import { getExamPartScore, insertNewData } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
import MoneySvg from '../../../components/svg/MoneySvg';
import Header from '../../../components/modals/Header';
import { bigAlert, confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { Link } from 'react-router-dom';
import LockSvg from '../../../components/svg/LockSvg';
import AddCheckinModal from '../../../components/modals/AddCheckinModal';
export class TeacherEventCalendar extends Component {
    state={
        date: this.props.date?this.props.date: new Date(),
        days: [],
        modal: {
            position:false,
            teacher:'',
            class: '',
            time: ''
        },
        feeModal:{
            position:false
        },
        columnNumber:'',
        addCheckinModal:{
            position:false
        }
    }
    getDate(date){
        const allDays = new Date(
            date.getFullYear(),
            date.getMonth()+1,
            0
        ).getDate();
        let days = [];
        for(let i =1; i <=allDays; i++){
            days.push(i)
        }
        this.setState({days})
    }
    componentDidMount(){
        this.getDate(this.state.date)
    }
    componentDidUpdate(prevProps, prevState){
        // console.log(prevState?.date, this.state?.date)
        // if(prevProps?.date != this.state.date){
            // this.getDate(this.props.date)
        // }
    }
    prevMonth=(date)=>{
        let month = date.getMonth() - 1;
        date.setMonth(month);
        this.getDate(date);
        this.props.onChangeDate(date)
    }
    nextMonth=(date)=>{
        let month = date.getMonth() + 1;
        date.setMonth(month);
        this.getDate(date);
        this.props.onChangeDate(date)
    }
    getZeroPad(num){
        if(num<10){
            return `0${num}`
        }else{
            return num
        }
    }
    calendarEvent=(item, payment, date)=>{
      
        return payment.filter(x=>  x?.date == moment(date).format(`YYYY-MM-${this.getZeroPad(item)}`))
    }
    showCheckin=(data, e)=>{
        this.setState({
            modal: {
              position:true,
              teacher:JSON.parse(data?.event)?.details?.split("</th><td>")[1].split("</td></tr><tr><th>")[0],
              class: data?.lesson_mode,
              time: moment(data?.created_at).format("HH:m"),
              course_title: data?.course?.title
            }
          })
    }
    showExamResults=(data)=>{
        // console.log(data)
        // return
        this.props.getExamPartScore({id:data?.id, type:'exam', exam_type:'current'})
        .then(resp=>{
          if(resp == 'success'){
            this.props.changeStateValue({  name:'partScoreModal.position', value:true });
            this.props.changeStateValue({  name:'partScoreModal.title', value:data?.title});
          }
        })
    }
    edinFeeBtn=(data)=>{
        // this.setState({
        //     feeModal:{
        //         position:true,
        //         ...data
        //     }
        // })
    }

    saveEditFeeBtn=(data)=>{
        if(data?.fee !=''){
            let formData = new FormData();
            for(const property in data){
                if(data[property])
                formData.append(property, data[property]);    
            }
            
            formData.append("_method", "put");
            let url = `teacherEnrolls/${data?.id}`;
            this.props.insertNewData(url, formData)
            .then(resp=>{
                if(resp=="success"){
                   mixinAlert("success", "Fee updated successfully");
                    this.setState({
                        feeModal:{
                            position:false
                        }
                    }) 
                    this.props.onChangeDate(this.state.date)
                }
                
            })
        }
        
    }
    getZeropad(num){
        if(num <10){
            return `0${num}`;
        }else{
            return num;
        }
    }
    formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + '-' + minutes + ' ' + ampm;

        return strTime;
    }
    onClickCheckin=(index, dates, data, e)=>{

        if(typeof e.target.className == 'string' && e.target.className.includes("chckn")){
            let date =moment(dates).format(`YYYY-MM-${this.getZeropad(index)}`)
            if(this.props.user?.role === "teacher" || this.props.user?.role === "head_teacher" || this.props.user?.role === "speaking_teacher"){
                if(date !== moment(new Date()).format("YYYY-MM-DD")){
                    return
                }
            }
            const params = 
            {
                "event":`{\"name\":\"Class\",\"start\":1628168315358,\"timed\":true,\"color\":\"black\",\"details\":\"<table class=\\\"table table-borderless table-striped table-hover\\\"><tr><th>Teacher</th><td>${this.props.teacher?.full_name}</td></tr><tr><th>Class</th><td>${data?.enroll?.lesson_mode}</td></tr><tr><th>Start</th><td>${this.formatAMPM(new Date(date))}</td></tr></table>\"}`,
                "lesson_mode": `${data?.enroll?.lesson_mode}`,
                "student_id": `${data?.student?.id}`,
                "teacher_id": `${this.props.teacher?.id}`,
                "today":`${date}`,
                "course_id":`${data?.course?.id}`,
                "enroll_id": `${data?.enroll?.id}`
            }
        
            confirmAlert("", `${data?.student?.full_name} <br /> ${data?.course?.title}`, "", "Yes, Check-in", `Are you sure about doing the check-in? `)
            .then(alertResp=>{
                if(alertResp=="success"){
                    let url = "add-attendance-manually";
                    if(this.props.user?.role == "teacher" || this.props.user?.role == "head_teacher"  || this.props.user?.role == "speaking_teacher"){
                        url="attendances"
                    }
                    this.props.insertNewData(url, params, "checkin")
                    .then(resp=>{
                        if(resp === "success"){
                            this.props.getAfterChange()
                            bigAlert(this.props.lang?.checkIn_submit, this.props.lang?.checkin_success, "success")
                        }
                    })
                }
            })
        }
        
    }
    
  render() {
    const {days, date, modal, feeModal, columnNumber, addCheckinModal} = this.state;
    const {studentData, dateVisible=true, daysProps=[], getAfterChange=()=>{}, user} = this.props;
    let allDays = [];
    if(daysProps.length !=0){
        allDays=daysProps
    }else{
        allDays=days
    }

    
    let result = studentData.reduce(function (r, a) {
        r[a.student?.id] = r[a.student?.id] || [];
        r[a.student?.id].push(a);
        return r;
    }, {});
    return (
      <div className=' overflow-x-auto scrollHeightView'>
        <div className='bg-white  rounded-[10px] min-w-[1200px]'>
        <div className='bg-[var(--blue)] flex items-center  p-[10px] rounded-[10px]'>
            <div className='min-w-[250px] flex items-center '>
                {
                    dateVisible?
                    <>
                    <button onClick={this.prevMonth.bind(this, date)}>
                        <LeftSvg />
                    </button>
                    <span className='text-white'>{moment(date).format("MMMM YYYY")}</span>
                    <button onClick={this.nextMonth.bind(this, date)}>
                        <RightSvg />
                    </button>
                    </>:null
                }
                
            </div>
            <ul className='flex w-full'>
                {
                    allDays.map((item, i)=>{
                        return (
                            <li onMouseOver={()=>this.setState({columnNumber:item})} onMouseOut={()=>this.setState({columnNumber:''})} style={{ width: `calc(100% / ${allDays.length})`, minWidth: `calc(100% / ${allDays.length})`, maxWidth: `calc(100% / ${allDays.length})` }} className='border border-r text-center text-white text-[14px]' key={i}>{item}</li>
                        )
                    })
                }
            </ul>
        </div>
        <div className='flex flex-col gap-[0px] pl-[10px] pr-[10px] pb-[10px] '>
        {
             Object.values(result).map((item, i)=>{
               return(
                item?.map((data, k)=>{
                    return(
                        <div key={k} className=' flex mt-[10px] hover:bg-gray-100'>
                            <div className='min-w-[250px] border-t border-l border-b flex items-center pl-[10px] justify-between pr-[10px]'>
                               <div className='flex items-center gap-[3px] min-w-[120px] border-r'>
                                {
                                    user?.role === "company_head" ||  user?.role === "office_manager"?
                                    <div className='relative group'>
                                        <button onClick={this.edinFeeBtn.bind(this, data?.enroll)}>
                                            <MoneySvg />
                                        </button>
                                        <span className=' hidden group-hover:inline-block bg-blue p-[3px_4px] rounded-[5px] text-[12px] text-white absolute z-50'>{data?.enroll?.fee}</span>
                                    </div>:null
                                }
                                    
                                    <div className=' relative group'>
                                        <Link target='_blank' to={`/students/${data?.student?.id}`}>{data?.student?.first_name} </Link>
                                        <Link target='_blank' className='absolute left-0 bottom-0 bg-white shadow p-[2px_4px] rounded-[3px]  hidden group-hover:block z-50 w-[120px]' to={`/students/${data?.student?.id}`}>{data?.student?.full_name}</Link>
                                    </div>
                                    {
                                        data?.enroll?.status==0?<LockSvg color="var(--red)" />:null
                                    }
                               </div>
                                <div className='block min-w-[130px] pl-[10px] cursor-pointer group relative'>
                                    {
                                        data?.course?.title?.replace('CELT', '').length<12?
                                        <span>
                                                {data?.course?.title?.replace('CELT', '')}
                                        </span>:
                                       <div>
                                         <span>
                                                {data?.course?.title?.replace('CELT', '')?.substring(0, 12)}...
                                        </span>
                                         <span className='absolute bg-white shadow p-[2px_4px] rounded-[3px] hidden group-hover:block bottom-0 z-50'>
                                                {data?.course?.title?.replace('CELT', '')}
                                        </span>
                                       </div>
                                    }
                                   
                                </div>
                            </div>
                            <ul className='flex w-full '>
                            {
                                allDays.map((item, j)=>{
                                    return (
                                        <li onClick={data?.enroll?.status==0?()=>{}: this.onClickCheckin.bind(this, item, date, data)} style={{ width: `calc(100% / ${allDays.length})` }} className={`chckn group border relative border-r text-center text-white text-[14px] flex flex-col items-center ${columnNumber==item?'bg-gray-100':''}` } key={j}>
                                            <span className=' absolute bg-blue inline-block w-[30px] text-white top-0 rounded-[5px] mt-[-20px] hidden group-hover:inline-block'>{item}</span>
                                            {
                                               
                                                this.calendarEvent(item, data?.payment?.filter(x=>x.status == data?.enroll?.status), date).map((event, k)=>{
                                                    if(event?.event){
                                                       return(
                                                            <span className='cursor-pointer' onClick={this.showCheckin.bind(this, event)} key={k} ><CheckSvg /> </span>
                                                        ) 
                                                    }else if(!event?.event && event?.status==1){
                                                        return(
                                                            <span key={k} className='cursor-pointer relative group/item' onClick={this.showExamResults.bind(this, event)} >
                                                                <span className='bg-blue p-[5px] rounded-[3px] shadow absolute bottom-[19px] left-[-5px] hidden group-hover/item:inline'>
                                                                    {event?.score}
                                                                </span>
                                                                <ExamSvg color="var(--blue)" /> 
                                                            </span>
                                                        ) 
                                                    }
                                                    
                                                })
                                            }
                                        </li>
                                    )
                                })
                            }
                            </ul>
                        </div>
                    )
                })
               )
                
            })
        }
        </div>
        {  modal?.position? <CheckInModal closeBtn={()=>{this.setState({ modal: { position:false,  teacher:'',  class: '',  time: ''  }})}} data={modal} /> :null }
        {
            feeModal?.position?
            <div className='background_shadow flex items-center justify-center'>
                <div className='w-[300px]'>
                    <Header closeBtn={()=>{this.setState({ feeModal:{position:false}})}} title="Edit Fee" />
                    <div className='bg-white p-[10px] rounded-b-[10px] flex flex-col gap-[10px]'>
                        <span className='text-[14px]'>Fee</span>
                        <input type='number' className='border w-full p-[6px] rounded-[6px] outline-none' value={feeModal?.fee} onChange={(e)=>this.setState({feeModal:{ ...feeModal,  fee:e.target.value  }})} />
                        <button onClick={this.saveEditFeeBtn.bind(this, feeModal)} className='border border-[var(--blue)] p-[5px] txt-blue text-[14px] font-semibold rounded-[5px]'>
                            Update
                        </button>
                    </div>
                </div>
            </div>:null
        }
      </div>

      {/* {addCheckinModal?.position ?<AddCheckinModal getAfterChange={()=>getAfterChange(date)} data={addCheckinModal} closeBtn={()=>{this.setState({addCheckinModal: { position:false,  teacher_id:'', date:'', student_id:''}})}} />:null } */}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getExamPartScore, changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherEventCalendar)
