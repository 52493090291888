import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getIalExReviews } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1';
import moment from 'moment';
import { getRole } from '../../actions/getRole';
import { changeStateValue } from '../../redux/MainReducer';
import EmptyPage from '../../components/EmptyPage';
import Search from '../../components/Search';
import LoadMoreBtn from '../../components/LoadMoreBtn';
export class IALEXReview extends Component {
    state={
        page:1,
        search:''
    }
    componentDidMount(){
        this.props.getIalExReviews('',1, '');
        window.scrollTo(0,0)
    }
    openModal=(data)=>{
        const params = {
            position:true,
            type:'ial_essay',
            modalType: 'review',
            title: data?.essay?.title,
            question: `${data?.essay?.question} ${data?.essay?.image_full_url? `<img src="${data?.essay?.image_full_url}" />`:""}`,
            essay: data?.answer,
            reviews: data?.reviews[0]?.review,
            teacher: data?.reviews[0]?.user?.full_name,
            role: getRole(data?.reviews[0]?.user?.role, this.props.lang),
            stuent: data?.user?.full_name,
            robot_review:data?.robot_review,
            grade: data?.reviews[0]?.grade?data?.reviews[0]?.grade:" ",
            model_answer: data?.model_answer?.answer,
        }
        this.props.changeStateValue({name: 'mainModal', value: params});

    }
    onSearchData=(search)=>{
        this.setState({search: search});
        this.props.getIalExReviews('',this.state.page, search)
    }
    loadMore=(page)=>{
        this.setState({page: page});
        this.props.getIalExReviews('',page, this.state.search)
    }
  render() {
    const {ialExReviews} = this.props;
    // console.log(ialExReviews)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={"ial_ex_rws"} />
            <div className='flex justify-center  max-[1100px]:mt-[120px] max-[800px]:mt-[170px] '>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            {ialExReviews?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
           
            <div className='mt-[42px] grid-full-250 '>
               {
                ialExReviews.map((data, i)=>{
                    return(
                        <Card1 
                            key={i}
                            title={data?.essay?.title}
                            grade={`${data?.reviews[0]?.grade?data?.reviews[0]?.user?.first_name:'Teacher'}: ${data?.reviews[0]?.grade?data?.reviews[0]?.grade:''}`}
                            grade2={`Selty: ${data?.robot_review?.grade?data?.robot_review?.grade:''}`}
                            date={moment(data?.submit_date).format("DD/MM/YYYY")}
                            status={data.is_closed}
                            onCLickBtn={this.openModal.bind(this, data)}

                        />
                    )
                })
               }
            </div>
            {
                ialExReviews?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={ialExReviews?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    ialExReviews: state.Data.ialExReviews,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getIalExReviews, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(IALEXReview)
