import moment from 'moment'
import React, { Component } from 'react'
import LeftBigSvg from './svg/LeftBigSvg';
import RightBigSvg from './svg/RightBigSvg';

export class MonthlyCalendar extends Component {
    state={
        month: moment(new Date()).format("YYYY-MM"),
        date: new Date(),
        visible:false
    }

    changeYear(type){
        let date = this.state.date;
        date.setFullYear( type === "next"? date.getFullYear() + 1 :date.getFullYear() - 1);
        this.setState({month: moment(date).format("YYYY-MM")})
        // this.props.onSelect(moment(date).format("YYYY-MM"))
    }
    setMonth(month){
        let date = this.state.date;
        date.setMonth(month-1)
        this.setState({month: moment(date).format("YYYY-MM"), visible:false});
        this.props.onSelect(moment(date).format("YYYY-MM"))
    }
    componentDidMount(){
        window.addEventListener("click", (e)=>{
            if(typeof e.target.className === "string" && !e.target.className.includes("notClosePermission")){
                this.setState({visible:false})
            }
        })
    }
  render() {
    const {month, visible} = this.state;
    const {bg="bg-light-gray", bgCont="bg-white", rounded="30px", wFull=true} = this.props;
    return (
        <div className={`relative ${bgCont} rounded-[${rounded}] block p-[10px] notClosePermission max-[450px]:w-full`}>
            <div className={` ${bg} relative w-[214px] h-[35px] ${wFull? 'max-md:w-[100%]':'ml-auto mr-auto'}  rounded-[${rounded}] flex justify-between items-center gap-[5px] notClosePermission`}>
                <button onClick={this.changeYear.bind(this, 'prev')} className='w-[30px] h-[30px]  text-center rounded-full inline-flex justify-center items-center ml-[8px] pr-[2px] notClosePermission'>
                    <LeftBigSvg />
                </button>
                <span onClick={()=>this.setState({visible: !visible})} className='cursor-pointer  w-[55%] rounded-[6px] items-center justify-center h-[30px] inline-flex notClosePermission'>{month}</span>
                <button onClick={this.changeYear.bind(this, 'next')} className='w-[30px] h-[30px] text-center rounded-full inline-flex justify-center items-center mr-[8px] pl-[3px] notClosePermission'>
                    <RightBigSvg />
                </button>
            </div>
            {
                visible?
                <div className='bg-white absolute w-full p-[10px] mt-[14px] left-0 shadow rounded-[20px] notClosePermission z-50'>
                    <ul className='grid grid-cols-3 gap-[5px]'>
                        <li onClick={this.setMonth.bind(this, 1)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>JAN</li>
                        <li onClick={this.setMonth.bind(this, 2)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>FEB</li>
                        <li onClick={this.setMonth.bind(this, 3)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>MAR</li>
                        <li onClick={this.setMonth.bind(this, 4)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>APR</li>
                        <li onClick={this.setMonth.bind(this, 5)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>MAY</li>
                        <li onClick={this.setMonth.bind(this, 6)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>JUN</li>
                        <li onClick={this.setMonth.bind(this, 7)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>JUL</li>
                        <li onClick={this.setMonth.bind(this, 8)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>AUG</li>
                        <li onClick={this.setMonth.bind(this, 9)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>SEP</li>
                        <li onClick={this.setMonth.bind(this, 10)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>OCT</li>
                        <li onClick={this.setMonth.bind(this, 11)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>NOV</li>
                        <li onClick={this.setMonth.bind(this, 12)} className='text-center cursor-pointer font-semibold txt-light-black h-[40px] flex items-center justify-center rounded-[6px] bg-gray-100'>DEC</li>
                    </ul>
                </div>:null
            }
            
        </div>
    )
  }
}

export default MonthlyCalendar
