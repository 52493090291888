import React, { Component } from 'react'
import CardEditSvg from '../../../../components/svg/CardEditSvg'
import PrevAnswer from './PrevAnswer'
import { connect } from 'react-redux'
import { changeStateValue } from '../../../../redux/MainReducer'
export class PrevQuestion extends Component {
    state={
        questionPoisition:false,
        descPosition:false,
        correctAns:false
    }
    getInpValue(index, name, e){
        this.props.changeStateValue({
            name: `modalRoboPrev.questions.${index}.${name}`,
            value: e.target.value
        })
    }
  render() {
    const {data, parentIndex} = this.props
    const {questionPoisition, descPosition, correctAns} = this.state
    return (
      <div className='border-b border-dashed p-[10px]'>
        <div className='flex items-start gap-[10px] '>
            {
                questionPoisition?
                <input type='text' className='w-full h-[30px] border outline-none text-[14px] pl-[4px] border-gray-400 rounded-[6px]' onChange={this.getInpValue.bind(this, parentIndex, "question")} value={data?.question} />:
                <h2 className='txt-blue'>{data?.question} </h2>
            }
            
            <button onClick={()=>this.setState({questionPoisition: !questionPoisition})}>
                <CardEditSvg /> 
            </button>
        </div>
        {
            data?.description?
            <div className='flex items-start gap-[10px] '>
                {
                    descPosition?
                    <input type='text' className='w-full h-[30px] border outline-none text-[14px] pl-[4px] border-gray-400 rounded-[6px]' onChange={this.getInpValue.bind(this, parentIndex, "description")} value={data?.description} />:
                    <h2 className='txt-orange text-[14px]'>{data?.description}</h2>
                }
                <button onClick={()=>this.setState({descPosition: !descPosition})}> 
                    <CardEditSvg /> 
                </button>
            </div>:null
        }
        
        <div className='flex items-start gap-[10px] '>
            {
                correctAns?
                <input type='text' className='w-full h-[30px] border outline-none text-[14px] pl-[4px] border-gray-400 rounded-[6px]' onChange={this.getInpValue.bind(this, parentIndex, "correct_answer")} value={data?.correct_answer} />:
                <h2 className='text-green-600 text-[14px]'>Correct: {data?.correct_answer}</h2>
            }
            <button onClick={()=>this.setState({correctAns: !correctAns})}>
                <CardEditSvg /> 
            </button>
        </div>

        <div className='flex flex-col gap-[2px] mt-[10px]'>
            {
                data?.answers?.map((item, j)=>{
                    return(
                        <PrevAnswer 
                            key={j}
                            index={j}
                            parentIndex={parentIndex}
                            data={item}
                            correct={data?.correct_answer}
                        />
                    )
                })
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(PrevQuestion)
