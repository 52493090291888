import React, { Component } from 'react'
import { connect } from 'react-redux'
import CoachPartCard from './dashboard/CoachPartCard';

export class CoachEndOfExam extends Component {
  render() {
    const {endOfCourseData} = this.props;
    // console.log(endOfCourseData)
    return (
     <div className=' mb-[20px]s p-[20px] rounded-[20px] bg-white'>
        <h2 className='mb-[20px]s'>Missing End of Course</h2>
         <div className='grid-full-320 '>
        {
            endOfCourseData?.map((data, i)=>{
                return(
                    <CoachPartCard 
                       data={[]} 
                       teacher_id={data?.teacher_id} 
                       key={i} 
                       student_id={data?.student_id} 
                       student_name={data?.student?.full_name} 
                       teacher_name={data?.teacher?.full_name} 
                       course_title={data?.lesson?.title} 
                      //  lesson_title={data?.lesson?.title}
                       status={2}  />
                )
            })
        }
      </div>
     </div>
    )
  }
}
const mapStateToProps = (state) =>({
    endOfCourseData: state.Data.endOfCourseData
})
export default connect(mapStateToProps)(CoachEndOfExam)
