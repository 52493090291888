import React, { Component } from 'react'

export class LockSvg extends Component {
  render() {
    const {color="#545454"} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
      >
        <g clipPath="url(#a)">
          <path
            fill={color}
            fillRule="evenodd"
            d="M3.5 4.667a3.5 3.5 0 0 1 7 0h.583a1.167 1.167 0 0 1 1.167 1.166v5.834a1.166 1.166 0 0 1-1.167 1.166H2.917a1.167 1.167 0 0 1-1.167-1.166V5.833a1.167 1.167 0 0 1 1.167-1.166H3.5ZM7 2.333a2.333 2.333 0 0 1 2.333 2.334H4.667A2.333 2.333 0 0 1 7 2.333Zm1.167 5.834a1.167 1.167 0 0 1-.584 1.01v.74a.583.583 0 0 1-1.166 0v-.74a1.167 1.167 0 1 1 1.75-1.01Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h14v14H0z" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default LockSvg
