import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getOfficeStudents, getTeachers } from '../../actions/StaffAction';
import DropSearch from '../../components/DropSearch';
import { changeStateValue } from '../../redux/MainReducer';
import Card1 from '../../components/cards/Card1';
import EmptyPage from '../../components/EmptyPage';
export class StudentStats extends Component {
   
    componentDidMount(){
        this.props.changeStateValue({name:'officeStudents', value:[]})
        if(this.props.user !== ""){
            if(this.props.user?.role === "company_head" || this.props.user?.role === "office_manager"){
                this.props.getTeachers(20, '', this.props.user?.company_id);  
            }else{
                this.props.getOfficeStudents(1, '', '', '', '', '', this.props.user?.id);  
            }
        }
        window.scrollTo(0,0)
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps?.user !== this.props.user){
            if(this.props.user?.role === "company_head" || this.props.user?.role === "office_manager"){
                this.props.getTeachers(20, '', this.props.user?.company_id);  
            }else{
                this.props.getOfficeStudents(1, '', '', '', '', '', this.props.user?.id);  
            }
        }
    }
    getStats=(id)=>{
        this.props.getOfficeStudents(1, '', '', '', '', '', id);  
    }

    
  render() {
    const {officeStudents, teachers, user, lang} = this.props;
    let teacherOption = [];
    for(const data of teachers){
        teacherOption.push({
            label: data?.full_name,
            value: data?.id
        })
    }
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="student_stats" />
            <div className='flex white-search mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {
                    user?.role === "company_head" || user?.role === "office_manager"?
                    <DropSearch options={teacherOption} placeholder={lang?.select_teacher} selectData={this.getStats.bind(this)} />:null
                }
            </div>
            {officeStudents?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please select a teacher" subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
                {
                    officeStudents?.map((data, i)=>{
                        return(
                            <Card1 
                                key={i}
                                title={data?.full_name}
                                statusBisible={false}
                                btn={false}
                                email={data?.email}
                                phone={data?.phone_number}
                                gEng={data.student_lesson_mode?data.student_lesson_mode?.find(type => type.lesson_mode === 'General English')?true:false:false}
                                essay={data.answers_count}
                                speaking={data.speaking_answers_count}
                                gExam={data.monthly_general_english_exams_taken}
                                gHw={data.monthly_general_english_homework_taken}
                                sat={data.student_lesson_mode? data.student_lesson_mode.find(type => type.lesson_mode === 'SAT Verbal' ||  type.lesson_mode === 'SAT Math')?true:false:false}
                                satExam={data?.monthly_sat_exam_takens}
                                satHw={data?.monthly_sat_homework_taken}
                                satVerbExam={data?.monthly_sat_verbal_exam_takens}
                                satVerbHw={data?.monthly_sat_verbal_homework_taken}
                                showEmptyCard={true}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps  =(state)=>({
    officeStudents: state.Data.officeStudents,
    user: state.Data.user,
    teachers: state.Data.teachers,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getOfficeStudents, getTeachers, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(StudentStats)
