import React, { Component } from 'react'

export class ReplySvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#1C274C"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m4.5 12 5-5m-5 5 5 5m-5-5h10c1.667 0 5 1 5 5"
        />
      </svg>
    )
  }
}

export default ReplySvg
