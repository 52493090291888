import React, { Component } from 'react'
import { getExamId, getExamStart, insertNewData } from '../../../actions/MainAction';
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import { jwtDecode } from 'jwt-decode';
import { getSingleData } from '../../../actions/StaffAction';
import { Link } from 'react-router-dom';
import Countdown, {zeroPad} from 'react-countdown';
import { mixinAlert } from '../../../actions/SweetAlerts';
import { changeStateValue } from '../../../redux/MainReducer';
export class StartMockExam extends Component {
  state={
    examsParents:[],
    examsInfo:'',
    timer:'',
    minute:''
  }

  componentDidMount(){
    if(localStorage.getItem('userToken')){
      const data = jwtDecode(localStorage.getItem('userToken'));
      this.props.getExamId(this.props.params.id, data.sub)
      .then(resp=>{
        if(resp==="error"){
          this.props.navigate("/")
          this.props.changeStateValue({name:"sideBarVisible", value:true})
        }else{
          this.setState({examsInfo: resp, timer: resp?.updated_at, minute: resp?.exam?.duration_minutes})
          this.props.getSingleData(`student-dynamic-parents?exam_id=${resp?.id}`)
          .then(parentResp=>{
            this.setState({examsParents: parentResp})
          })
        }
      })
    }
  }
  getOverAllBtn(examsParents){
    let inc = 0;
    for(const data of examsParents){
      if(data?.is_submit == 1){
        inc++;
      }
    }

    if(inc === examsParents.length){
      return true;
    }else{
      return false;
    }
  }

  onClickScore=()=>{
    this.props.insertNewData(`calculate-mock-exam/${this.state.examsInfo.id}`)
    .then(resp=>{
      this.props.navigate("/exam-results")
      this.props.changeStateValue({name:"sideBarVisible", value:true})
    })
  }

  renderer( { hours, minutes, seconds, completed}){
    // console.log("fdff", hours, minutes, seconds)
    if(!completed){
        if(hours === 0 && minutes === 2 && seconds === 0 && window.location.pathname.split('/').includes('start-pooling-exams')){
            mixinAlert("info", "You have 2 minutes left. Please submit your exam. After 2 minutes, the system will lock the exam and you will get 0 score. Nothing will be saved. Please submit now")
        }
      return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    }else{
        return <span className='txt-blue text-[20px] font-bold'>00:00:00</span>
    }
  }

  stopTimer(){
    if( window.location.pathname.split('/').includes('start-mock-exams')){
      this.props.changeStateValue({name:"sideBarVisible", value:true})
        // this.props.navigate("/exam-results");
        // this.props.onClickRight("submit", "not_time")
        // this.submitExamData(this.state.examId)
        this.onClickScore()
    }
  }
  render() {
    const {examsParents, examsInfo, timer, minute} = this.state;
    // console.log(examsInfo)
    return (
      <div className='flex justify-center pt-[50px] flex-col gap-[20px] items-center'>
          <h3 className='txt-blue text-[28px] font-[600] text-center'>{examsInfo?.exam_title} </h3>
          <h4>
          {
            timer != ''?
            <span className='txt-blue text-[20px] font-bold'>Time: {` `}
                <Countdown date={new Date(timer).getTime() + (minute)*60000} renderer={this.renderer.bind(this)}
                    onComplete={this.stopTimer.bind(this)}>
                </Countdown>
            </span>:<span className='txt-blue text-[20px] font-bold'>00:00:00</span>
        }
          </h4>
          <div className='grid grid-cols-4 gap-[20px] w-[1000px]'>
            {
              examsParents?.map((data, i)=>{
                return (
                  <div key={i} className='bg-white p-[20px] rounded-[10px] shadow'>
                    <h3 className='text-center txt-blue text-[18px] font-[600]'>{data?.title}</h3>
                    {
                      data?.score?
                      <h3 className='text-center mt-[27px] txt-blue'>Grade: {data?.score?.toFixed(1)}</h3>:null
                    }
                    
                    <div className='flex justify-center mt-[10px]'>
                      {
                        data?.is_submit == 1?
                        <button className='bg-red text-[14px] font-[600] text-white p-[5px_10px] rounded-[6px]'>Submitted</button>:
                        <Link to={`/moc-details/${data?.id}/${this.props.params.id}`} className='bg-blue text-[14px] font-[600] text-white p-[5px_10px] rounded-[6px]'>Start Exam</Link>
                      }
                     
                    </div>
                  </div>
                )
              })
            }
          </div>
          {
            this.getOverAllBtn(examsParents)?
            <div className='mt-[20px]'>
              <button onClick={this.onClickScore.bind(this)} className='bg-blue text-white text-[14px] p-[10px_20px] rounded-[5px]'>
                Calculate Overall Score
              </button>
            </div>:null
          }
         
      </div>
    )
  }
}

const mapStateToProps = (state)=>({

})
const mapDispatchToProps = {getExamStart, getExamId, getSingleData, insertNewData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StartMockExam))
