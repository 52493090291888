import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import PublicCard1 from '../components/cards/PublicCard1'
import { connect } from 'react-redux';
import { getAccStudent, getAllCountries, getEduDegree, getDisciplines } from '../../actions/PublicActions';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import { Link } from 'react-router-dom';
import DropSearch from '../../components/DropSearch';
import { getDropValue } from '../../actions/getRole';
export class AcceptedStudents extends Component {
  state={
    page:1,
    countryId:'',
    degreeId:'',
    disciplineId:'',
    sch:'0'
  }
  componentDidMount(){
    this.props.getAccStudent(this.props.page, this.props.companyId);
    this.props.getAllCountries();
    this.props.getEduDegree();
    this.props.getDisciplines();
  }
  loadMore=(page)=>{
    this.setState({page:page})
    this.props.getAccStudent(page*this.props.page, this.props.companyId, this.state.countryId, this.state.degreeId, this.state.disciplineId, this.state.sch)
  }
  filterByCountry=(countryId)=>{
    this.setState({countryId:countryId})
    this.props.getAccStudent(this.state.page*this.props.page, this.props.companyId, countryId, this.state.degreeId, this.state.disciplineId, this.state.sch)
  }
  filterByDegree=(degreeId)=>{
    this.setState({degreeId:degreeId})
    this.props.getAccStudent(this.state.page*this.props.page, this.props.companyId,  this.state.countryId, degreeId, this.state.disciplineId, this.state.sch)
  }
  filterByDiscipline=(disciplineId)=>{
    this.setState({disciplineId:disciplineId})
    this.props.getAccStudent(this.state.page*this.props.page, this.props.companyId,  this.state.countryId, this.state.degreeId, disciplineId, this.state.sch)
  }
  filterBySch=(sch)=>{
    this.setState({sch:sch==='0'?'1':'0'})
    this.props.getAccStudent(this.state.page*this.props.page, this.props.companyId,  this.state.countryId, this.state.degreeId, this.state.disciplineId, sch==='0'?'1':'0')
  }
  render() {
    const {acceptedStudents, viewMore=false, loadPage, defCount=8, allCountries, eduDegree, disciplines} = this.props;
    const {countryId, degreeId, disciplineId, sch} = this.state;
    // console.log(acceptedStudents)
    // console.log(viewMore)
    return (
      <>
      {
        acceptedStudents.length !==0?
        <section>
          <SectionHeader title="Accepted Students" />
          {
            !viewMore?
            <div className='container ml-auto mr-auto mb-[20px] flex gap-[20px]'>
            <DropSearch value={countryId} options={getDropValue(allCountries, "name", "countries_id")} placeholder="Select a country" selectData={this.filterByCountry.bind(this)} />
            <DropSearch value={degreeId} options={getDropValue(eduDegree, "education_type", "education_degree_id")} placeholder="Select a Degree" selectData={this.filterByDegree.bind(this)} />
            <DropSearch value={disciplineId} options={getDropValue(disciplines, "name", "discipline_id")} placeholder="Select a Discipline" selectData={this.filterByDiscipline.bind(this)} />
            <button onClick={this.filterBySch.bind(this, sch)} className={`bg-gray-100 rounded-[5px] p-[0_6px] text-[15px] text-gray-500 ${sch=='1'?"border border-[var(--blue)]":""}` }>Filter by Scholarship</button>
          </div>:null
          }
         
          <div className='grid grid-full-295 container ml-auto mr-auto gap-[50px] max-[1000px]:grid-cols-2 max-[685px]:grid-cols-1 max-[685px]:pl-[20px]  max-[685px]:pr-[20px]'>
          {/* <div className='grid-full-320 container '> */}
              {
                acceptedStudents?.map((data, i)=>{
                  return(
                    <PublicCard1 
                      key={i}
                      data={data}
                    />
                  )
                })
              }
          </div>
            {
                acceptedStudents?.length >=loadPage?
                <LoadMoreBtn onLoadData={this.loadMore} defCount={defCount} count={acceptedStudents?.length} />:null
            }
            {
              viewMore?
              <div className='flex justify-center mt-[40px] items-center'>
                <Link to="/accepted-students" className='bg-white w-[120px] h-[35px] text-center pt-[5px] txt-blue border border-[var(--blue)] font-semibold rounded-full'>View All</Link>
              </div>:null
            }
           
        </section>:
        <>
        {
         !viewMore &&  (countryId !=="" || degreeId !=="" || disciplineId !=="")?
          <section>
          <SectionHeader title="Accepted Students" />
          <div className='container ml-auto mr-auto mb-[20px] flex gap-[20px]'>
            <DropSearch value={countryId} options={getDropValue(allCountries, "name", "countries_id")} placeholder="Select a country" selectData={this.filterByCountry.bind(this)} />
            <DropSearch value={degreeId} options={getDropValue(eduDegree, "education_type", "education_degree_id")} placeholder="Select a Degree" selectData={this.filterByDegree.bind(this)} />
            <DropSearch value={disciplineId} options={getDropValue(disciplines, "name", "discipline_id")} placeholder="Select a Discipline" selectData={this.filterByDiscipline.bind(this)} />
            <button onClick={this.filterBySch.bind(this, sch)} className={`bg-gray-100 rounded-[5px] p-[0_6px] text-[15px] text-gray-500 ${sch=='1'?"border border-[var(--blue)]":""}` }>Filter by Scholarship</button>
          </div>
          </section>:null
        }
         
        </>
      }
      </>
    )
  }
}
const mapStateToProps = (state) =>({
  acceptedStudents: state.Data.acceptedStudents,
  allCountries: state.Data.allCountries,
  eduDegree: state.Data.eduDegree,
  disciplines: state.Data.disciplines,
});
const mapDispatchToProps = {getAccStudent, getAllCountries, getEduDegree, getDisciplines}
export default connect(mapStateToProps, mapDispatchToProps)(AcceptedStudents)
