import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { getIALExamsById, insertNewData } from '../../actions/MainAction';
import { withHooks } from '../../actions/withHooks';
import TextEditor from '../../components/TextEditor';
import { mixinAlert } from '../../actions/SweetAlerts';
import { getIALExamsGPTGradeImage, getIALExamsGPTReviewImage, getIALExamsMistralGrade, getIALExamsMistralReview } from '../../actions/getReviewGpt';
import { getMistralModel, getTurboModel } from '../../actions/robotActions';
import { resetStateValue } from '../../redux/MainReducer';
import { getStringGrade } from '../../actions/getRole';

export class StartIALExams extends Component {
    state={
        btnDisabled:false
    }

    getWordCount(text){
        var parser = new DOMParser();
        var doc = parser.parseFromString(text, 'text/html');
          return doc.children[0].children[1].textContent.match(/(\w+)/g) ? doc.children[0].children[1].textContent.match(/(\w+)/g).length: 0
      }
    componentDidMount(){
        this.props.getIALExamsById(this.props.params.id)
        .then(resp=>{
            if(resp === 'error'){
                mixinAlert("error", "Essay already written.");

                this.closeBtn()
            }
        })
        window.scrollTo(0,0)
    }

    closeBtn=()=>{
        this.props.navigate("/ial-exams")
    }

    saveBtn(data, type){
        if(type === '0' && this.getWordCount(data?.answer) >=5 || type == '1' && this.getWordCount(data?.answer) >=50){
            const essayData = {
                id: data?.answers[0]?.id,
                answer: data?.answer,
                essay_id: this.props.params.id,
                is_submitted: type
            }
            this.setState({btnDisabled:true})
          
            if(type == "1"){
                this.props.changeStateValue({
                    name:'progressLoading',
                    value:{
                      position:true,
                      text:"Please wait. I'm reviewing your A Level submission. Don't close the window.",
                      complete:false
                    }
                  })

                  this.props.insertNewData('save-new-essays', { ...essayData })
                  .then(resp=>{
                      this.setState({btnDisabled:false})
                      if(resp == 'success'){
                          this.props.changeStateValue({
                              name:'progressLoading',
                              value:{
                                position:true,
                                text:"Your A Level is ready. Please check your grade.",
                                complete:true
                              }
                            })
                            setTimeout(()=>{
                              this.props.resetStateValue({name:'progressLoading'})
                            }, 2000)
                          this.props.navigate('/ial-ex-reviews')
                          mixinAlert("success", `Essay ${type ==='1'? 'submitted':'saved'} successfilly!`);
                      }else{
                          mixinAlert("error", "Something went wronge.");
                      }
                      
                  })
               
            }else{
                const roboData = {
                    robot_review: "error",
                    robot_grade: "error"
                }
                this.props.insertNewData('save-new-essays', { ...essayData, ...roboData })
                .then(resp=>{
                    this.setState({btnDisabled:false})
                    if(resp == 'success'){
                        if(type === '1'){
                            this.closeBtn();
                        }
                        mixinAlert("success", `Essay ${type ==='1'? 'submitted':'saved'} successfilly!`);
                    }else{
                        mixinAlert("error", "Something went wronge.");
                    }
                    
                })
            }


            
          
        }else{
            if(type === '0'){
                mixinAlert("info", "Should be at least 5 words to save the essay");
            }else{
                mixinAlert("info", "Should be at least 50 words to submit the essay");
            }
        }
    }

  render() {
    const {ialExamsData, lang} = this.props;
    const {btnDisabled} = this.state;
    // console.log(getStringGrade('ddfwfwfwf { "grade":A }'))
    return (
        <div className='w-full pl-[27px] pr-[27px]  max-[320px]:pl-[10px] max-[320px]:pr-[10px]'>
            <Header title={ialExamsData?.title} />
            <div className='mt-[42px] bg-white p-[20px] rounded-[12px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <div dangerouslySetInnerHTML={{ __html: ialExamsData?.question }}></div>
                {
                    ialExamsData?.image_full_url?
                    <img className='mt-[20px] max-w-[100%]' src={ialExamsData?.image_full_url} />:null
                }
                <div className='mt-[60px]'>
                    {
                        ialExamsData?.answer || ialExamsData?.answer ==''?
                        <TextEditor value={ialExamsData?.answer} name={'ialExamsData.answer'} />:null
                    }
                    
                </div>

                <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={()=>this.closeBtn()} className='bg-red h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,ialExamsData, '0')} className=' h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] txt-blue border-[1px] border-[var(--blue)] font-semibold'>{lang?.save}</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this, ialExamsData, '1')} className='bg-blue h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.submit}</button>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    ialExamsData: state.Data.ialExamsData,
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getIALExamsById, insertNewData, getMistralModel,getTurboModel, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StartIALExams))
