import React, { Component } from 'react'
import { changeStateValue } from '../../../../redux/MainReducer';
import { connect } from 'react-redux';
import CloseSvg from '../../../../components/svg/CloseSvg';
import PDFDef from "../../../../images/pdfImg.png"
export class PaymentReceipt extends Component {
    state={
        base64:'',
        nameInp:''
    }
    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = () => {
                res(reader.result);
            }
            reader.readAsDataURL(file)
        })
    }
    getInpValue=(e)=>{
       this.onRemoveBtn()
        this.getBase64(e.target.files[0])
        .then(resp=>{
           setTimeout(()=>{
            this.setState({ nameInp: e.target.files[0]?.name});
            this.props.changeStateValue({name: e.target.name, value: e.target.files[0]})
            this.props.changeStateValue({name: `${e.target.name}_full_url`, value:resp})
           },500)
            // onChangeParent(e.target.files[0])
        })
    }
    onRemoveBtn=()=>{
        this.props.changeStateValue({name: "studentCalculatorModal.image", value: ""})
        this.props.changeStateValue({name: "studentCalculatorModal.file", value: ""})
        this.props.changeStateValue({name: "studentCalculatorModal.image_full_url", value: ""})
        this.props.changeStateValue({name: "studentCalculatorModal.file_full_url", value: ""})
    }
  render() {
    const {base64, nameInp} = this.state;
    const {studentCalculatorModal} = this.props;
    // console.log(studentCalculatorModal)
    return (
        <div>
            <span  className='text-[14px] txt-light-black'>Upload payment receipt</span>
           <div className='flex items-center gap-[20px] mt-[10px]'>
                <label htmlFor='payReceiptImg' className={`text-[12px] cursor-pointer bg-blue text-white rounded-[4px] inline-flex items-center justify-center w-[150px] font-regular h-[25px] p-[0_5px] `}>Image</label>
                <label htmlFor='payReceiptPdf' className={`text-[12px] cursor-pointer bg-blue text-white rounded-[4px] inline-flex items-center justify-center w-[150px] font-regular h-[25px] p-[0_5px]  `}>PDF</label>
                <input  name="studentCalculatorModal.image" onChange={this.getInpValue.bind(this)} id='payReceiptImg' type='file' className='hidden'  accept="image/*"  />
                <input  name="studentCalculatorModal.file" onChange={this.getInpValue.bind(this)} id='payReceiptPdf' type='file' className='hidden'  accept="application/pdf"  />
            </div> 
            {
                studentCalculatorModal?.image_full_url !=""?
               <div className='mt-[20px] relative flex flex-col items-end  w-[150px]'>
                <button onClick={this.onRemoveBtn.bind(this)}>
                    <CloseSvg color={"#000"} />
                </button>
                 <img src={studentCalculatorModal?.image_full_url} className='w-[150px] h-[150px] inline-block  rounded-[10px]' />
                {/* <span>{nameInp}</span> */}
               </div>:null
            } 
            {
                studentCalculatorModal?.file_full_url !=""?
               <div className='mt-[20px] relative flex flex-col items-end w-[150px]'>
                <button onClick={this.onRemoveBtn.bind(this)}>
                    <CloseSvg color={"#000"} />
                </button>
                 <img src={PDFDef} className=' h-[150px] inline-block  rounded-[10px]' />
                 {/* <span>{nameInp}</span> */}
               </div>:null
            } 

        </div>
     
    )
  }
}
const mapStateToProps = (state) =>({
    studentCalculatorModal: state.Data.studentCalculatorModal
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentReceipt)
