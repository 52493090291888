import React, { Component } from 'react'
import CardEditSvg from '../../../../components/svg/CardEditSvg'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
export class PrevAnswer extends Component {
    state={
        inpPosition:false
    }
    getInpValue=(parentIndex, index, e)=>{
        this.props.changeStateValue({
            name: `modalRoboPrev.questions.${parentIndex}.answers.${index}`,
            value: e.target.value
        })
    }
  render() {
    const {index, parentIndex, data, correct} = this.props;
    const {inpPosition} = this.state;
    return (
        <div className='flex items-start gap-[10px] '>
            {
                inpPosition?
                <input type='text' className='w-full h-[30px] border outline-none text-[14px] pl-[4px] border-gray-400 rounded-[6px]' onChange={this.getInpValue.bind(this, parentIndex,index)} value={data} />:
                <h2 className={`${data === correct? "text-green-700": "text-gray-500"} text-[14px]`}>{data}</h2>
            }
          
            <button onClick={()=>this.setState({inpPosition: !inpPosition})}>
                <CardEditSvg /> 
            </button>
        </div>
    )
  }
}
const mapStateToProps= (state)=>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(PrevAnswer)
