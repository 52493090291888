import React, { Component } from 'react'
import DotsSvg from '../svg/DotsSvg'
import img from "../../images/defaultUser.jpeg"
import { getLinkFormat } from './getLinks';
import PdfSvg from '../svg/PdfSvg';
import ReplySvg from '../svg/ReplySvg';
import EraseSvg from '../svg/EraseSvg';
import ToolTop from './ToolTop';

export class ToCard extends Component {
    state={
        dotVisible:false
    }
  render() {
    const {userImg="", role, 
            userName, forward=false, 
            forwardUser, forwardMsg, msg, 
            date, color, forwardColor,
            msgImage="",
            msgAudio="",
            msgPdf="",
            data, onReply=()=>{},noReply=false
        } = this.props;
        const {dotVisible} = this.state;
    return (
        <div className='flex gap-[12px] max-[520px]:gap-[5px] items-start'>
            {dotVisible? <div onClick={()=>this.setState({dotVisible:false})} className='absolute w-full h-[100vh] top-0 left-0 z-20'></div>:null}
            
            <img alt="user image" className='max-[520px]:w-[20px] max-[520px]:h-[20px] w-[40px] h-[40px] rounded-full' src={userImg && userImg !== ""? userImg:img} />
            <div className='rounded-[12px] p-[12px] rounded-tl-[0px] max-w-[60%] flex max-[490px]:max-w-[80%] flex-col items-start'>
                <div>
                    <div className='flex mb-[-6px] relative'> 
                        <h4 className={`${color} font-medium text-[12px]`}>{userName}</h4>
                        {
                            !noReply?
                             <button onClick={()=>this.setState({dotVisible:true})}>
                                <DotsSvg />
                            </button>:null
                        }
                       
                        {
                            dotVisible?
                            <ToolTop onClose={()=> this.setState({dotVisible:false})} onReply={()=>{onReply(); this.setState({dotVisible:false})}} data={data} />:null
                        }
                        
                    </div>
                    <span className='font-medium text-[10px] txt-light-black'>{role}</span>
                </div>
                <div className='bg-white  p-[7px] rounded-[5px] mt-[2px] items-start flex flex-col pr-[15px]'>
                    {
                        forward?
                        <div className={`bg-[#F5F9FF] w-[100%] rounded-[5px] border-l border-l-[2.3px] border-l-[var(--blue)] p-[7px] mb-[5px]`}>
                            <span className={`${forwardColor} text-[12px]`}>{forwardUser}</span>
                            <p  className='text-[#5C5C5C] text-[11px] break-words'>{forwardMsg}</p>
                        </div>:null
                    }
                    {
                        msgImage && msgImage !== ""?
                        <a href={msgImage}  download={true} target='_blank'>
                            <img className='rounded-[6px] w-[500px] mb-[10px] max-[490px]:w-[100%]' src={msgImage} />
                        </a>:
                        msgAudio && msgAudio !== ""?
                        <div className='w-[300px] msg-audio'>
                            <audio className='bg-white  listening-audio '  src={msgAudio} controls />
                        </div>:
                        msgPdf && msgPdf !==""?
                        <a download={true} target='_blank' className='inline-flex items-center bg-[#F5F9FF] p-[4px] rounded-[6px] text-[13px] gap-[4px]' href={msgPdf}><PdfSvg /> {msg}</a>:
                        <pre className='text-[14px] whitespace-break-spaces text-[#5C5C5C] font-regular text ' dangerouslySetInnerHTML={{ __html: getLinkFormat(msg) }}></pre>
                    }
                    <span className='flex text-[10px] font-medium w-[100%] justify-end text-[#707070] ml-[9px]'>{date}</span>
                </div>
            </div>
        </div>
    )
  }
}

export default ToCard
