import React, { Component } from 'react'
import StarSvg from '../svg/StarSvg';

export class ReviewGrade extends Component {
  render() {
    const {title,subTitle, point, onClickGrade=()=>{}} = this.props;
    return (
      <div className='flex w-full bg-gray-100 p-[10px] items-center justify-between rounded-[6px] max-sm:flex-col '>
        <div className='flex gap-[6px] '>
            <span className='txt-blue text-[15px] font-semibold'>{title}</span>
            <span className='text-gray-600 font-[300] text-[15px]'>{subTitle}</span>
        </div>
        <div className='flex gap-[3px]'>
            {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data, i)=>{
                    if(data <= point){
                        return(
                           <button onClick={()=>onClickGrade(data)} key={i}>
                             <StarSvg color="#FFC500" />
                           </button>
                        )
                    }else{
                        return(
                            <button onClick={()=>onClickGrade(data)} key={i}>
                                <StarSvg color="#E0E8F1" />
                            </button>
                        )
                    }
                })
            }
        </div>
      </div>
    )
  }
}

export default ReviewGrade
