import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getAllTeacherCheck } from '../../actions/StaffAction';
import TeacherEventCalendar from './teacher_info/TeacherEventCalendar';
import moment from 'moment';
import { changeStateValue } from '../../redux/MainReducer';
import { getTeacherStudentPayment } from '../../actions/StaffAction';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import LeftSvg from '../../components/svg/LeftSvg';
import RightSvg from '../../components/svg/RightSvg';
import MenuBarSvg from '../../components/svg/MenuBarSvg';
import MenuCloseSvg from '../../components/svg/MenuCloseSvg';
import { getTeachers } from '../../actions/StaffAction';
import DropSearch from '../../components/DropSearch';
export class TeacherCheck extends Component {
  state={
    date: new Date(),
    page:1,
    days:[],
    teacher_id:''
  }
  componentDidMount(){
    this.props.changeStateValue({name:'sideBarVisible', value:false})
    this.getDate(this.state.date)
    let date = new Date();
    const allDays = new Date(
      date.getFullYear(),
      date.getMonth()+1,
      0
  ).getDate()
    this.props.getAllTeacherCheck(1,  moment(date).format('YYYY-MM-01'),  moment(date).format(`YYYY-MM-${allDays}`))
    if(this.props.user !=""){
      this.props.getTeachers(10, "", this.props.user?.company_id)
    }
    
  }
  componentWillUnmount(){
    this.props.changeStateValue({name:'sideBarVisible', value:true})
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.user != this.props.user){
      this.props.getTeachers(10, "", this.props.user?.company_id)
    }
  }
  onChangeDate=(i,data, date)=>{
    this.setState({date:date})
    const allDays = new Date(
      date.getFullYear(),
      date.getMonth()+1,
      0
    ).getDate()
    this.props.changeStateValue({name:"loaderLong", value:true})
    this.props.getTeacherStudentPayment('',  moment(date).format('YYYY-MM-01'),  moment(date).format(`YYYY-MM-${allDays}`), data.id)
    .then(resp=>{
        this.setState({studentData:resp});
        this.props.changeStateValue({
          name:`teacherAllChecks.${i}.students`,
          value: resp
        })
        this.props.changeStateValue({name:"loaderLong", value:false})
    })
  }

  loadMore=(page)=>{
    this.setState({page:page})
    let date = this.state.date;
      const allDays = new Date(
        date.getFullYear(),
        date.getMonth()+1,
        0
    ).getDate()
    this.props.getAllTeacherCheck(page,  moment(date).format('YYYY-MM-01'),  moment(date).format(`YYYY-MM-${allDays}`), this.state.teacher_id)
  }
  getDate(date){
    const allDays = new Date(
        date.getFullYear(),
        date.getMonth()+1,
        0
    ).getDate();
    let days = [];
    for(let i =1; i <=allDays; i++){
        days.push(i)
    }
    this.setState({days})
}
  prevMonth=(date)=>{
    let month = date.getMonth() - 1;
    date.setMonth(month);
    this.getDate(date)
    const allDays = new Date(
      date.getFullYear(),
      date.getMonth()+1,
      0
  ).getDate()
  this.props.getAllTeacherCheck(this.state.page,  moment(date).format('YYYY-MM-01'),  moment(date).format(`YYYY-MM-${allDays}`), this.state.teacher_id)
  }

  nextMonth=(date)=>{
    let month = date.getMonth() + 1;
    date.setMonth(month);
    this.getDate(date)
    const allDays = new Date(
      date.getFullYear(),
      date.getMonth()+1,
      0
  ).getDate()
  this.props.getAllTeacherCheck(this.state.page,  moment(date).format('YYYY-MM-01'),  moment(date).format(`YYYY-MM-${allDays}`), this.state.teacher_id)
  }
  changeMenuBar=(position)=>{
    
    this.props.changeStateValue({name:'sideBarVisible', value:position})
}

getAfterChange=()=>{
  const allDays = new Date(
    this.state.date.getFullYear(),
    this.state.date.getMonth()+1,
    0
).getDate()
  this.props.getAllTeacherCheck(this.state.page,  moment(this.state.date).format('YYYY-MM-01'),  moment(this.state.date).format(`YYYY-MM-${allDays}`), this.state.teacher_id)
}
  getCheckinsByTeacher=(data)=>{
    this.setState({teacher_id:data})
    let date = this.state.date;
      const allDays = new Date(
        date.getFullYear(),
        date.getMonth()+1,
        0
    ).getDate()
    this.props.getAllTeacherCheck(this.state.page,  moment(date).format('YYYY-MM-01'),  moment(date).format(`YYYY-MM-${allDays}`), data)
  }
  render() {
    const {teacherAllChecks, sideBarVisible, teachers, lang} = this.props;
    const {date, days} = this.state
    //get-all-teacher-payment-events
    // console.log(teachers)
    let teacherOption = [];
    for(const data of teachers){
        teacherOption.push({
            label: data?.full_name,
            value: data?.id
        })
    }
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title="teacher_check" />
     
        <button onClick={this.changeMenuBar.bind(this, !sideBarVisible)}>
              {!sideBarVisible? <MenuBarSvg />: <MenuCloseSvg /> }
          </button>
        <div className='flex white-search mt-[20px] mb-[20px]'>
        <DropSearch options={teacherOption} placeholder={lang?.select_teacher} selectData={this.getCheckinsByTeacher.bind(this)} /> 
       </div>
        <div>
            <button onClick={this.prevMonth.bind(this, date)}>
                <LeftSvg color="var(--blue)" />
            </button>
            <span className='txt-blue'>{moment(date).format("MMMM YYYY")}</span>
            <button onClick={this.nextMonth.bind(this, date)}>
                <RightSvg color="var(--blue)" />
            </button>
        </div>
        <div>
          {
            teacherAllChecks?.map((data, i)=>{
              return(
                <div key={i} className='bg-white mt-[20px] shadow p-[10px] rounded-[10px]'>
                  <h3 className='txt-blue text-[18px] mb-[10px]'>{data?.full_name}</h3>
                  <TeacherEventCalendar daysProps={days} date={date} dateVisible={false} onChangeDate={this.onChangeDate.bind(this, i, data)} getAfterChange={this.getAfterChange.bind(this)}  teacher={data} studentData={data?.students} key={i} />
                </div>
              )
            })
          }
           {
                teacherAllChecks?.length >=2?
                <LoadMoreBtn onLoadData={this.loadMore}  defCount={2} count={teacherAllChecks?.length} />:null
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  teacherAllChecks: state.Data.teacherAllChecks,
  sideBarVisible: state.Data.sideBarVisible,
  teachers: state.Data.teachers,
  user: state.Data.user,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getAllTeacherCheck, changeStateValue, getTeacherStudentPayment, getTeachers}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherCheck)
