import React, { Component } from 'react'

export class CardExamCheck extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 32 32"
        fill="#1E398F"
      >
        <title>{"exam-mode"}</title>
        <path d="M25 23v3H7v-9.17l3.59 3.58L12 19l-6-6-6 6 1.41 1.41L5 16.83V26a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2v-3ZM27 7v9.17l3.59-3.58L32 14l-6 6-6-6 1.41-1.41L25 16.17V7H13V5h12a2 2 0 0 1 2 2ZM8 10H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2ZM4 4v4h4V4Z" />
        <path
          d="M0 0h32v32H0z"
          data-name="&lt;Transparent Rectangle&gt;"
          style={{
            fill: "none",
          }}
        />
      </svg>
    )
  }
}

export default CardExamCheck
