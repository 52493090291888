import React, { Component } from 'react'
import { getStudentsReview } from '../../actions/MainAction';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import Search from '../../components/Search';
import EmptyPage from '../../components/EmptyPage';
import ReviewCard from '../../components/cards/ReviewCard';
import LoadMoreBtn from '../../components/LoadMoreBtn';

export class StudentTReviews extends Component {
    state={
        page:1
    }
    componentDidMount(){
        this.props.getStudentsReview(1, '')
    }

    loadMore=(page)=>{
        this.setState({page})
        this.props.getStudentsReview(page, '')
    }

    getAfterChange=()=>{
        this.props.getStudentsReview(this.state.page, '')
    }
  render() {
    const {studentsReview} = this.props;
    // console.log(studentsReview)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="teacher_reviews" />
            {/* <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <Search onSelectStudent={this.onSelectStudent} student={false} searchVisible={false}/>
            </div> */}
            {studentsReview?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, select a student" subTitle="" /></div>:null}
            <div className='w-full flex flex-col gap-[20px] mt-[20px]'>
            {
                studentsReview?.map((data, i)=>{
                    return(
                        <ReviewCard
                            key={i}
                            data={data}
                            btn={false}
                            readBtn={true}
                            readStatus={data?.read_status}
                            getAfterChange={this.getAfterChange.bind(this)}
                        />
                    )
                })
            }
            </div>
            {
                studentsReview?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={studentsReview?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentsReview: state.Data.studentsReview
});
const mapDispatchToProps = {getStudentsReview}
export default connect(mapStateToProps, mapDispatchToProps)(StudentTReviews)
 
