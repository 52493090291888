import React, { Component } from 'react'
import CardExamSvg from '../svg/CardExamSvg'
import CardScoreSvg from '../svg/CardScoreSvg'
import CardDateSvg from '../svg/CardDateSvg'
import moment from 'moment';
import CloseSvg from '../svg/CloseSvg';
import CardEditSvg from '../../../components/svg/CardEditSvg';
import CardDeleteUserSvg from '../../../components/svg/CardDeleteUserSvg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

export class PublicCard2 extends Component {
  state={
    img:''
  }

  getScore(type, score){
    if(type===1){
      if(String(score).length == 1){
        return `${score}.0`
      }else{
        return score
      }
    }else{
      return score
    }
  }
  render() {
    const {data, edit=false, onClickEdit=()=>{}, user, onClickDelete=()=>{}} = this.props;
    const {img} = this.state;
    return (
      <div className='bg-white rounded-[14px] shadow flex flex-col items-center pl-[18px] pr-[18px]'>
        {
          edit?
          <div className='flex justify-between w-full mt-[10px] mb-[-40px]'>
              <button onClick={()=>onClickDelete()} className='bg-gray-100 p-[7px] rounded-[6px]'>
                <CardDeleteUserSvg />
              </button>
            <button onClick={()=>onClickEdit()} className='bg-gray-100 p-[7px] rounded-[6px]'>
              <CardEditSvg />
            </button>
          </div>:null
        }
       
        <div className='shadow w-[78px] h-[78px] bg-white mt-[27px] p-[4px] rounded-full flex items-center justify-center'>
            <img src={data?.image_full_url} alt ={data?.student_name} className='w-full h-full rounded-full object-fill' />
        </div>
        <div className=' flex flex-col items-center'>
            <h2 className='text-[16px] font-medium mt-[17px] text-center'>{data?.student_name}</h2>
            <span className='text-[#ACACAC] text-[12px] mt-[5px]'>Student</span>
            <Link to={`/centers/${data?.company?.id}`} className='text-blue-600 text-[12px] font-semibold mt-[5px]'>{data?.company?.title}</Link>
        </div>
        <div className='w-full border-t border-t-[#ACACAC] border-dashed mt-[16px] mb-[16px]'></div>

        <div>
            <ul className='flex flex-col gap-[10px]'>
                <li className='flex gap-[12px] items-center'>
                    <CardExamSvg />
                   <div className='inline-flex items-center gap-[5px]'>
                        <span className='text-[14px] text-[#464646]'>Exam :</span>
                        <span className='text-[14px] text-[#000]'>{data?.exam?.title}</span>
                   </div>
                </li>
                <li className='flex gap-[12px] items-center'>
                    <CardScoreSvg />
                   <div className='inline-flex items-center gap-[5px]'>
                        <span className='text-[14px] text-[#464646]'>Score :</span>
                        <span className='text-[14px] text-[#000]'>{this.getScore(data?.exam_type_id, data?.score)}</span>
                   </div>
                </li>
                <li className='flex gap-[12px] items-center'>
                    <CardDateSvg />
                   <div className='inline-flex items-center gap-[5px]'>
                        <span className='text-[14px] text-[#464646]'>Date :</span>
                        <span className='text-[14px] text-[#000]'>{moment(data?.date).format("DD MMM YYYY")}</span>
                   </div>
                </li>
            </ul>
        </div>
        <div className='flex justify-center mt-[22px] mb-[22px] flex-col items-center gap-[10px]'>
          {/* onClick={()=>this.setState({img: data?.cert_image_full_url})} */}
            <a href={data?.cert_image_full_url} target='_blank' className='bg-blue text-[14px] text-white font-regular p-[14px] pt-[5px] pb-[5px] rounded-[100px]'>View Certificate</a>
           
        </div>
       
        {
          img !==""?
          <div className='bg-[rgb(31,41,55)] fixed top-0 left-0 w-full h-screen flex items-center justify-center z-[999]'>
            <button onClick={()=>this.setState({img:''})} className='w-[40px] h-[40px] inline-flex items-center justify-center rounded-full top-[30px] left-[30px] absolute border border-gray'>
              <CloseSvg />
            </button>
            <img className='max-h-[70vh] max-w-[80%]' src={img} />
          </div>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user
})
export default connect(mapStateToProps)(PublicCard2)
