import React, { Component } from 'react';
import {connect} from 'react-redux';
import Timer from './Timer'
export class Recorder extends Component {
    state={
        wafeForm: false,
        timer: '',
        btnRef: React.createRef(),
        stopPermission: true,
        timerVisible: false,
        stopBtnVisible: false,
        prepTimeVisible: false,
        prepTimer: 0
    }
    supportsRecording(mimeType)
    {
        if (!window.MediaRecorder)
            return false;
        if (!MediaRecorder.isTypeSupported)
            return mimeType.startsWith("audio/mp4") || mimeType.startsWith("video/mp4");
        return MediaRecorder.isTypeSupported(mimeType);
    }
    componentDidMount () {
        setTimeout(()=>{
            if(this.props.prepTime){
                this.setState({
                    prepTimeVisible: true
                })
                setTimeout(()=>{
                    let prepInt;
                    let time = 0
                    prepInt = setInterval(()=>{
                        time++;
                        this.setState({
                            prepTimer: this.props.prepTime - time
                        })
                        if(this.state.prepTimer == 0){
                            clearInterval(prepInt);
                            startRecording()
                            this.setState({
                                timerVisible: true
                            })
                            let recordTime = 0
                            let int = setInterval(()=>{
                                recordTime++;
                                this.setState({
                                    timer: this.props.time - recordTime
                                });

                                if(this.state.timer == 0){
                                    clearInterval(int)
                                    endRecording()
                                }
                            }, 1000)

                        }
                    }, 1000)
                }, 3000)
            }else{
                this.setState({
                    prepTimeVisible: true
                })
                setTimeout(()=>{
                    startRecording()
                    let time = 0
                    let prepInt = setInterval(()=>{
                        time++;
                     
                        this.setState({
                            prepTimer: this.props.time - time
                        })
                        if(this.state.prepTimer == 0){
                            clearInterval(prepInt)
                            endRecording()
                        }
                    }, 1000)
                }, 3000)

            }

        }, 10000)
        
          let blobs = [];
          let stream;
          let mediaRecorder;
          const  startRecording = async()  =>
          {
            blobs = []
           this.setState({
            wafeForm: true
           })
          
            
                
            navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
              stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
              mediaRecorder = new MediaRecorder(stream);
              mediaRecorder.ondataavailable = (event) => {
                
               
                // Let's append blobs for now, we could also upload them to the network.
                if (event.data)
                      blobs.push(event.data);
                      
                      
              }
              mediaRecorder.onstop = doPreview;
              // Let's receive 1 second blobs
              mediaRecorder.start(10);
          }
           const endRecording =() =>
          {
              // Let's stop capture and recording
              mediaRecorder.stop();
              stream.getTracks().forEach(track => track.stop());
              const newBlob =  new Blob(blobs, { 'type' : 'audio/mp4'})
              const audio_url = (window.URL || window.webkitURL).createObjectURL(newBlob);
              if(audio_url.length !== 0){
               
                this.setState({
                    wafeForm: false
                   })
                
                const audioName =new File([newBlob], '.mp4');
                localStorage.setItem('recorderResult', audio_url)
                this.props.continueBtn(this.props.studentToeflSpeakingExam, audioName)
                // blobs = []
              }
              
          }
          function doPreview()
          {
              if (!blobs.length)
                  return;
          }
        
    
    }
    getTime(time, defaultTime){
        if(time == ''){
          return defaultTime
        }else if(time != '' && time < 10){
          return `0${time}`
        }else{
          return time
        }
      }
      componentWillUnmount(){
        this.setState = (state,callback)=>{
            return;
        };
    }
    render() {
        const {wafeForm, timer, btnRef, timerVisible, stopBtnVisible, prepTimeVisible, prepTimer} = this.state;
        const {prepTime, studentToeflSpeakingExam, time, section1=false} = this.props
        
        return (
            <div className="speaking_dialog_recorder_box " style={{ flexDirection: 'column', alignItems: 'center' }}>
               {
                prepTime != '' && prepTimeVisible && !timerVisible ?
                <div className='toefl_speaking_timer_container' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                    <h3>Preperation Time</h3>
                    <p>00:00:{this.getTime(prepTimer,prepTime?prepTime:time )}</p>
                </div>:null
               }
              
                {
                    timerVisible?
                    <Timer defaultTime={this.props.time} time={timer}  />:null
                }
                {/* <ReactMic
                    record={wafeForm}
                    className="recorder_waveForm_container"
                    strokeColor="gray"
                    backgroundColor="#F2F2F2" /> */}
               <div>
                
               
                {
                    stopBtnVisible && studentToeflSpeakingExam.part == 1?
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <button  ref={btnRef} className='toefl_exam_continue_btn'>Stop Recording</button>
                    </div>:null

                }
               
               </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    studentToeflSpeakingExam: state.Data.studentToeflSpeakingExam
})
const mapDispatchToProps = {} 
export default connect(mapStateToProps, mapDispatchToProps)(Recorder)