import React, { Component } from 'react'
import Audio from '../exams/Audio'
import PlaySvg from '../svg/PlaySvg';

export class Card3 extends Component {
  state={
    audioVisible:false
  }
  render() {
    const {title, file_url} = this.props;
    const {audioVisible} = this.state;
    return (
      <div className='bg-white rounded-full p-[7px] flex items-center gap-[10px]'>
        <span className='h-[36px] pl-[7px] pr-[7px] min-w-[36px]  inline-flex justify-center items-center txt-blue font-medium text-[18px] bg-light-blue rounded-full'>{title}</span>
        {
          audioVisible?
          <audio preload="auto|metadata|none" className='h-[36px] listening-audio' src={file_url} controls /> :
          <div className='flex items-center gap-[10px]'>
            <button onClick={()=>this.setState({audioVisible:true})}>
              <PlaySvg />
            </button>
            <span className='text-[14px]'>Tap to play Audio</span>
          </div>
        }
        
      </div>
    )
  }
}

export default Card3
