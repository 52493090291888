import React, { Component } from 'react'
import Headerbar from './Headerbar'
import { connect } from 'react-redux';
import LockSvg from '../svg/LockSvg';
import UnlockSvg from '../svg/UnlockSvg';
import StLockSvg from '../svg/StLockSvg';
import StUnlockSvg from '../svg/StUnlockSvg';

export class Header extends Component {
  getTitle=(title, lang)=>{
    if(lang[title]){
      return lang[title]
    }else{
      return title
    }
  }
  render() {
    const {title, lang, lockVisible=false, lockStatus=true, lginBlockVisible=false, loginBlockStatus=1, onClickBlock=()=>{}} = this.props;
    return (
      <header className='flex justify-between items-center pt-[24px] '>
        <h1 className='txt-dark-blue font-bold text-[34px] max-[400px]:text-[24px] flex items-center gap-[9px]'>{this.getTitle(title, lang?.navs)} {lockVisible?lockStatus?<StLockSvg />:<StUnlockSvg />:null}
            {lginBlockVisible? <button onClick={()=>onClickBlock()} className={`text-[14px] text-white h-[30px] p-[3px_5px] rounded-[6px] ${loginBlockStatus==1?"bg-green-600":"bg-red-600"}`}>Login block</button>:null}
           </h1>
        <Headerbar />
      </header>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(Header)
