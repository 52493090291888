import React, { Component } from 'react'

export class RecycleSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 -3 32 32"
        >
            <title>{"replay"}</title>
            <path
            fill="var(--blue)"
            fillRule="evenodd"
            d="M31.67 11h-2.843c-.965-6.228-6.339-11-12.843-11-5.015 0-9.36 2.839-11.533 6.991l1.864.745A11.01 11.01 0 0 1 15.984 2c5.397 0 9.878 3.881 10.821 9h-3.416c-.31.301-.606.486-.162 1.007l3.73 3.794c.31.3.811.3 1.119 0l3.756-3.794c.309-.301.147-.706-.162-1.007ZM15.984 24c-5.039 0-9.282-3.384-10.588-8h3.251c.31-.3.471-.706.163-1.006l-3.756-3.795a.808.808 0 0 0-1.119 0L.204 14.994c-.443.521-.147.706.162 1.006h2.981c1.356 5.73 6.487 10 12.637 10 4.68 0 8.77-2.476 11.063-6.18l-1.883-.752A10.998 10.998 0 0 1 15.984 24Z"
            />
        </svg>
    )
  }
}

export default RecycleSvg
