import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
export class EssayEditor extends Component {
    getTextFromHTML = (text) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(text, 'text/html');
        return doc.children[0].children[1].textContent;
    }
    changeInpValue=(e)=>{
        this.props.changeStateValue({
            name: e.target.name,
            value: e.target.value
        })
    }
  render() {
    const {name, value} = this.props;
    return (
      <div className='border mt-[10px] rounded-[6px] flex flex-col'>
        <div className='bg-[#F9F9F9] w-full h-[30px] rounded-[6px_6px_0_0] border-b'></div>
        <textarea spellCheck={false} placeholder='Start Writing' onCopy={(e)=>e.preventDefault()} onCut={(e)=>e.preventDefault()}  onDrag={(e)=>e.preventDefault()}  onDrop={(e)=>e.preventDefault()}   onPaste={(e)=>e.preventDefault()} className='outline-none w-full h-[180px]  rounded-[0_0_6px_6px] text-[15px] p-[10px]' onChange={this.changeInpValue.bind(this)} name={name} value={this.getTextFromHTML(value)}  />
        <div className='bg-[#F9F9F9] w-full h-[15px] rounded-[0_0_6px_6px] border-t'></div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({})
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(EssayEditor)
