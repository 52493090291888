import React, { Component } from 'react'
import pdfImg from "../../images/defPdf.png"
import { connect } from 'react-redux';
import { insertNewData,  } from '../../actions/MainAction';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { getSupportMessages } from '../../actions/MessageAction';
export class MsgFilePreview extends Component {
  state={
    disable:false
  }
  closebtn=()=>{
    this.props.resetStateValue({ name:'supportMsgFile' })
  }
  sendBtn=(data)=>{
    if(data?.file !== ''){
      this.setState({disable:true})
      let formData = new FormData();
      
      formData.append(data?.type, data?.file);
      formData.append('message', data?.file.name);
      if(data?.reply_id !=""){
        formData.append('reply_id', data?.reply_id);
      }
      this.props.insertNewData("support-message", formData)
      .then(resp=>{ 
        this.setState({disable:false})
        this.props.getSupportMessages(...this.props.afterCallParams)
        this.props.onClose()
        this.closebtn()
      })
    }
  }
  render() {
    const {supportMsgFile, lang} = this.props;
    const {disable} = this.state;
    // console.log(supportMsgFile)
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white p-[20px] rounded-[10px] w-[500px] max-h-[70vh] overflow-auto'>
          {
            supportMsgFile?.type === "image"?
            <img className='rounded-[6px] w-full' src={supportMsgFile?.preview} />:
            supportMsgFile?.type === "pdf"?
            <img className='w-full' src={pdfImg} />:
            supportMsgFile?.type === "audio"?
            <audio src={supportMsgFile?.preview} className='listening-audio' controls />:
            <video className='w-full h-[300px] rounded-[10px]' controls >
                <source src={supportMsgFile?.preview} type="video/mp4"></source>
            </video>

          }
            {/*  */}
            {/* <audio className='listening-audio' controls /> */}
            {/* */}
            <div className='flex justify-between mt-[10px]'>
                <button disabled={disable} onClick={this.closebtn.bind(this)} className='p-[6px] text-[14px] pt-[4px] pb-[4px] text-white font-semibold bg-red rounded-[6px]'>{lang?.close}</button>
                <button disabled={disable} onClick={this.sendBtn.bind(this, supportMsgFile)} className='p-[6px] text-[14px] pt-[4px] pb-[4px] text-white font-semibold bg-blue rounded-[6px]'>{lang?.send}</button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  supportMsgFile: state.Data.supportMsgFile,
  lang: state.Data.lang,
});
const mapDispatchToProps = {insertNewData, changeStateValue, resetStateValue,getSupportMessages}
export default connect(mapStateToProps, mapDispatchToProps)(MsgFilePreview)
