import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getTotalStats } from '../../actions/AdminActions';
import StatisticCard from '../../components/cards/StatisticCard';
import { getTotalEssay, getTotalExams, getTotalSpeaking, getTotalSpeakingPr } from '../../actions/getGeneralStats';
export class AdminGeneralStats extends Component {
    state={
        totalData:''
    }
    componentDidMount(){
        this.props.getTotalStats()
        .then(resp=>{
            this.setState({totalData:resp})
        })
    }
  render() {
    const {totalData} = this.state;
    // console.log(totalData)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="General stats" />

            <div className='w-full grid-full-320 mt-[42px] items-start'>
                <StatisticCard data={getTotalExams(totalData)} title={"Total Exam Status"} />
                <StatisticCard data={getTotalEssay(totalData)} title={"Total Essays Status"} />
                <StatisticCard data={getTotalSpeaking(totalData)} title={"Total Speaking Status"} />
                <StatisticCard data={getTotalSpeakingPr(totalData)} title={"Total Speaking Pr Status"} />
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {getTotalStats}
export default connect(mapStateToProps, mapDispatchToProps)(AdminGeneralStats)
