import React, { Component } from 'react'
import Header from "../../components/header/Header"
import HeaderButtons from '../../components/HeaderButtons'
import { connect } from 'react-redux';
import { getSpeakingTeacherCheckinsView } from '../../actions/StaffAction';
import Card2 from '../../components/cards/Card2';
import moment from 'moment';
import EmptyPage from '../../components/EmptyPage';
import { changeStateValue } from '../../redux/MainReducer';
export class SpeakingTeacherCheckinsView extends Component {
  state={
    buttons:[],
    selected:0
  }
  getLastWeekRange(date){
    function getLastWeek() {
      var today = new Date();
      var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - date);
      return lastWeek;
    }
      
    var lastWeek = getLastWeek();
    var lastWeekMonth = lastWeek.getMonth() + 1;
    var lastWeekDay = lastWeek.getDate();
    var lastWeekYear = lastWeek.getFullYear();
    
    var lastWeekDisplay = lastWeekYear + "-" +lastWeekMonth  + "-" + lastWeekDay  ;
    return lastWeekDisplay;
  }

  componentDidMount(){
    this.props.changeStateValue({
        name:'speakingTeacherCheckinsView',
        value:[]
    })
    window.scrollTo(0,0)
    this.setState({buttons:[
      {
           title: this.props.lang?.today,
           value: {start_date: this.getLastWeekRange(0), end_date: this.getLastWeekRange(0)}
      },
      {
           title: this.props.lang?.yesterday,
           value: {start_date: this.getLastWeekRange(1), end_date: this.getLastWeekRange(1)}
      },
      {
          title: this.props.lang?.last_7_dat,
          value: {start_date: this.getLastWeekRange(7), end_date: this.getLastWeekRange(0)}
      }
   ]})
   this.props.getSpeakingTeacherCheckinsView({start_date: this.getLastWeekRange(0), end_date: this.getLastWeekRange(0), query_type:'checkins'})
  }
  changeDate=(data, index)=>{
    this.setState({selected:index})
    this.props.getSpeakingTeacherCheckinsView({...data?.value, query_type:'checkins'})
  }

  componentWillUnmount(){
    this.props.changeStateValue({
        name:'speakingTeacherCheckinsView',
        value:[]
    })
  }
  render() {
    const {buttons, selected} = this.state;
    const {speakingTeacherCheckinsView} = this.props;
   
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title="sp_teacher_checkin" />
        <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
          <HeaderButtons selected={selected} onClick={(data, i)=>this.changeDate(data, i)} buttons={buttons}  />
        </div>
        {speakingTeacherCheckinsView.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, change date to show check-ins" subTitle="" /></div>:null}
        {
         <div className='mt-[32px] grid-full-205'>
         {
             speakingTeacherCheckinsView?.map((data, i)=>{
                 return(
                   <Card2 
                     key={i}
                     title={data?.student}
                     subTitle={moment(data?.date).format("DD/MM/YYYY")}
                     btn={false}
                   />
                 )
             })
         }
     
     </div>
    }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  speakingTeacherCheckinsView: state.Data.speakingTeacherCheckinsView,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getSpeakingTeacherCheckinsView, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SpeakingTeacherCheckinsView)


