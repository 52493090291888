import React, { Component } from 'react'
import Header from './Header';
import { connect } from 'react-redux';
import { getCoursesByStudent } from '../../actions/StaffAction';
import { getLessons, checkStudentReminder } from '../../actions/MainAction';
import DropSearch from '../DropSearch';
export class CheckExamReminderModal extends Component {
    state={
        courses:[],
        lessons:[],
        course_id:''
    }
    closeBackground(e){
        if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
          this.props.closeModal();
        }
    }

    componentDidMount(){
        this.props.getCoursesByStudent(this.props.studentId)
        .then(resp=>{this.setState({courses:resp})})
    }
    onSelectCourse=(courseId)=>{
        this.setState({course_id: courseId})
        this.props.getLessons(courseId)
        .then(resp=>{this.setState({lessons: resp})})
    }
    onSelectLesson=(lessonId)=>{
        const data={
            student_id: this.props.studentId,
            course_id: this.state.course_id,
            lesson_id: lessonId
        }
        this.props.checkStudentReminder(data)
    }
  render() {
    const {closeModal, lang} = this.props;
    const {courses, lessons} = this.state;
    let courseOption = [];
    for(const data of courses){
        courseOption.push({
            label: data?.title,
            value: data?.id
        })
    }
    let lessonsOption = [];
    for(const data of lessons){
        lessonsOption.push({
            label: data?.title,
            value: data?.id
        })
    }
    return (
        <div onClick={this.closeBackground.bind(this)} className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[338px]  rounded-[10px]'>
                <Header closeBtn={closeModal} title={lang?.exam_reminder}/>
                <div className='p-[20px]'>
                    <DropSearch title={lang?.select_course} placeholder={lang?.select_course} options={courseOption} selectData={this.onSelectCourse.bind(this)}  />
                    <div className='mt-[20px]'>
                    {
                        lessonsOption.length !== 0?
                        <DropSearch title={lang?.select_unit} placeholder={lang?.select_unit} options={lessonsOption} selectData={this.onSelectLesson.bind(this)}  />:null
                    }
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang
});
const mapDispatchToProps = {getCoursesByStudent, getLessons, checkStudentReminder}
export default connect(mapStateToProps, mapDispatchToProps)(CheckExamReminderModal)
