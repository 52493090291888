import React, { Component } from 'react'
import CardCertSvg from '../svg/CardCertSvg';
import { Link } from 'react-router-dom';
import ReNewSvg from '../svg/ReNewSvg';
import CoppiedSvg from '../svg/CoppiedSvg';
import { mixinAlert } from '../../actions/SweetAlerts';

export class CardLine extends Component {
  render() {
    const {svg, title, rounded=false, userLink="", cheatingColor=false, generate=false, onGenerate=()=>{}} = this.props;
    // console.log(cheatingColor)
    return (
        <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
            <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] ${rounded? "rounded-full": "rounded-[3px]"}  inline-flex items-center justify-center`}>
                {svg}
            </div>
            {
              generate?
              <button onClick={()=>{navigator.clipboard.writeText(title); mixinAlert("success", "Copied successfully")}}>
                <CoppiedSvg />
              </button>:null
            }
            {
              typeof title === "string"?
              title.length <=16?
              <Link to={userLink} target='_blank' onClick={(e)=>{if(userLink==""){e.preventDefault()}}} className={`text-[14px]  font-semibold ${cheatingColor?'text-red-600':'txt-dark-gray'}`}> {title}</Link>:
              <div className='relative completeText'>
                <Link  to={userLink} target='_blank' onClick={(e)=>{if(userLink==""){e.preventDefault()}}} className='text-[14px] txt-dark-gray font-semibold minitext'> {title?.substring(0, 16)}...</Link>
                <Link  to={userLink} target='_blank' onClick={(e)=>{if(userLink==""){e.preventDefault()}}} className='text-[14px] txt-dark-gray font-semibold longtext'> {title}</Link>
              </div>:
               <Link  to={userLink} target='_blank' onClick={(e)=>{if(userLink==""){e.preventDefault()}}} className={`text-[14px]  font-semibold ${cheatingColor?'text-red-600':'txt-dark-gray'}`}> {title}</Link>
            }
            {
              generate?
             <button onClick={()=>onGenerate()}> <ReNewSvg /></button>:null
            }
        </li>
    )
  }
}

export default CardLine
