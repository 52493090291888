import React, { Component } from 'react'
import Buttons from './Buttons'
import QuestionCard from './QuestionCard';
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { deleteData, insertNewData } from '../../../actions/MainAction';
import { withHooks } from '../../../actions/withHooks';
import { getExamQuestions } from '../../../actions/AdminActions';
export class ExamParts extends Component {
    state={
        visible: false,
        saveAccess: false,
        completed: false,
        btnDisable:false,
        score:''
    }
    editParent=(data)=>{
        this.props.changeStateValue({
            name:'adminExamModalData',
            value:{
                position: true,
                descriptionPosition: true,
                ...data
            }
        })
    }
    deleteQuestionBtn(data){
        if(data?.children.length !==0){
            mixinAlert("warning", "Please delete all child questions before delete parent");
            return
        }
        confirmAlert("warning", 'Are you sure?', "You cannot revert this")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`questions/${data?.id}`)
                .then(resp=>{
                    if(resp === 'success'){
                        this.props.getExamQuestions(this.props.params.id)
                        mixinAlert("success", "Parent deleted successfully");
                    }else{
                        mixinAlert("error", "Something went wrong");
                    }
                })
            }
        })
    }

    changeSaveAcces=()=>{
        this.setState({
            saveAccess: false,
            completed: false
        })
    }
    saveRowChange=()=>{
        this.reorder = {
            order: JSON.stringify(this.props.adminExamQuestions),
          };
         
          let formData = new FormData();
          let url = "questionReorder";
        
          for (const property in this.reorder) {
              if (this.reorder[property])
                  formData.append(property, this.reorder[property]);
          }
        
          this.props.insertNewData(url, formData)
          .then(resp=>{
            if(resp == 'success'){
              mixinAlert('success', 'Orders saved successfully')
              this.setState({completed: true})
              this.props.getExamQuestions(this.props.data.exam_id)
            }else{
              mixinAlert('error', 'Something went wronge. Please, try again later')
            }
          })
    }
    checkRowChange=(data)=>{
        let newArray = [];
        let newArray2 = [];
        for(const item of data){  newArray.push(item) }
        let changedData = [];
        for(let i = 0; i< newArray.length; i++){
          if(typeof newArray[i].sort_id == 'string'){
            changedData.push(newArray.splice(i, 1)[0])
          }
        }
        for(let i = 0; i < changedData.length; i++){
            let x = changedData[i].sort_id
          newArray.splice(+x-1, 0, changedData[i])
        }
        for(let i = 0; i < newArray.length; i++){
            newArray2.push({
                ...newArray[i],
                sort_id : i+1
            });
        }
        this.props.changeStateValue({
            name: `adminExamQuestions.${this.props.parentRow}.children`,
            value: newArray2
        })
        this.setState({
            saveAccess: true
          })
    }

    addScoreBtn=(score, parent_id)=>{
        if(score && score !==""){
            this.setState({btnDisable:true})
            this.props.insertNewData("exam-auto-score", {exam_id: parent_id, score: score})
            .then(resp=>{
                this.setState({btnDisable:false, score:''})
                if(resp === "success"){
                    this.props.getExamQuestions(this.props.params.id)
                    mixinAlert("success", "Score added successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else{
            mixinAlert("warning", "Please add score firstly.")
        }
    }
  render() {
    const {visible, saveAccess, completed, score, btnDisable} = this.state;
    const {data, parentRow} = this.props;
    // console.log(data)
    return (
        <div className='bg-white p-[10px] rounded-[10px] shadow'>
            <div className='flex justify-between gap-[30px]'>
                <h2 onClick={()=>this.setState({visible: !visible})} className='text-[20px] txt-blue font-semibold cursor-pointer w-full'>{data?.title}</h2>
                <div className='flex gap-[20px]'>
                    {
                        data?.children?.length !=0?
                        <div className='flex items-start gap-[10px] w-[200px]'>
                            <input value={score} onChange={(e)=>this.setState({score:e.target.value})} className='bg-gray-100 w-[100px] h-[30px] outline-none rounded-[6px] text-[13px] pl-[4px]' type='number' placeholder='Score' />
                            <button disabled={btnDisable} onClick={this.addScoreBtn.bind(this, score, data?.children[0]?.parent_id)} className='bg-orange p-[5px] rounded-[6px] text-white font-semibold text-[14px]'>Add score</button>
                        </div>:null
                    }
                   
                    <button onClick={this.editParent.bind(this, data)} className='bg-blue text-[14px] font-semibold text-white rounded-[6px] p-[6px]'>Edit</button>
                    <button onClick={this.deleteQuestionBtn.bind(this, data)} className='bg-red text-[14px] font-semibold text-white rounded-[6px] p-[6px]'>Delete</button>
                </div>
            </div>
            {
                visible?
                <div>
                    <Buttons data={data} />
                    <div className='bg-gray-100 p-[10px] rounded-[10px] mt-[20px] flex flex-col gap-[10px]'>
                        {
                            data?.children?.map((item ,j)=>{
                                return(
                                    <QuestionCard changeSaveAcces={this.changeSaveAcces} row={j} parentRow={parentRow} key={j} data={item}/>
                                )
                            })
                        }
                    </div>
                    {
                        data?.children?.length !==0?
                        <div className='mt-[20px] flex items-center justify-center'>
                            {
                                saveAccess && !completed?
                                <button className='bg-blue text-[14px] text-white font-semibold rounded-[6px] p-[7px]' onClick={this.saveRowChange.bind(this)}>Confirm Sorting</button>:
                                !saveAccess && !completed?
                                <button className='bg-blue text-[14px] text-white font-semibold rounded-[6px] p-[7px]' onClick={this.checkRowChange.bind(this, data?.children)}>View Sorting</button>:
                                completed?
                                <button className='bg-blue text-[14px] text-white font-semibold rounded-[6px] p-[7px]' >Completed</button>:null
                            }
                        </div>:null
                    }
                </div>:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({adminExamQuestions: state.Data.adminExamQuestions});
const mapDispatchToProps = {changeStateValue, deleteData, getExamQuestions, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamParts))
