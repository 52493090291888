import React, { Component } from 'react'

export class Image extends Component {
  render() {
    const {url} = this.props;
    return (
      <div>
        <img src={url}  alt="img"   className='w-full rounded-[12px] max-w-[50%] max-[700px]:max-w-full'/>
      </div>
    )
  }
}

export default Image
