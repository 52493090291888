import React, { Component } from 'react'

export class GroupUserAddSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        className='w-[24px] h-[24px] max-[500px]:w-[15px] max-[500px]:h-[15px]'
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="var(--blue)"
          fillRule="evenodd"
          d="M10 8a5 5 0 1 1 10 0 5 5 0 0 1-10 0Zm5-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
          clipRule="evenodd"
        />
        <path
          fill="var(--blue)"
          d="M5 8a1 1 0 0 1 1 1v2h2a1 1 0 1 1 0 2H6v2a1 1 0 1 1-2 0v-2H2a1 1 0 1 1 0-2h2V9a1 1 0 0 1 1-1Z"
        />
        <path
          fill="var(--blue)"
          fillRule="evenodd"
          d="M15 14c-1.994 0-3.905.254-5.356.899C8.165 15.555 7 16.714 7 18.5c0 .467.085.998.403 1.504.317.507.805.89 1.435 1.175 1.209.547 3.146.821 6.162.821s4.953-.274 6.162-.821c.63-.285 1.118-.668 1.435-1.175.318-.506.403-1.037.403-1.504 0-1.786-1.165-2.945-2.644-3.601C18.905 14.254 16.994 14 15 14Zm-6 4.5c0-.714.402-1.305 1.456-1.774C11.54 16.246 13.128 16 15 16c1.872 0 3.461.246 4.544.726C20.598 17.195 21 17.786 21 18.5c0 .224-.04.35-.097.442-.058.092-.195.247-.565.415-.791.358-2.354.643-5.338.643s-4.547-.285-5.338-.643c-.37-.168-.507-.323-.565-.415C9.04 18.85 9 18.724 9 18.5Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default GroupUserAddSvg
