import React, { Component } from 'react'
import Input from '../../../components/modals/components/Input'
import TextEditor from '../../../components/TextEditor'
import FileInput from '../../../components/FileInput'
import AnswerBox from './AnswerBox'
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer'
import { connect } from 'react-redux'
import { mixinAlert } from '../../../actions/SweetAlerts'
import { insertNewData } from '../../../actions/MainAction'
import { getOneExamQuestion, getPoolingExamQuestions } from '../../../actions/StaffAction'
import { withHooks } from '../../../actions/withHooks'

export class ExamAutoAddModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{    
        this.props.changeStateValue({ 
            name:"poolingExamAutoAdd",
            value:{
                ...this.props.poolingExamAutoAdd,
                position:false,
                id:'',
                title:'',
                instruction:'',
                description:'',
                question_type:'',
                audio:'',
                video:'',
                image:'',
                score:'',
                answer_options:[]
            }
        })
    }
    getTextFromHTML = (text) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(text, 'text/html');
        return doc.children[0].children[1].textContent;
    }
    extractWords(text) {
        const regex = /\{\{\{([^}]+)\}\}\}/g;
        const matches = [];
        let match;
        
        while (match = regex.exec(text)) {
          matches.push(match[1].trim());
        }
        
        return matches;
      }
    addQuestionBtn=(data)=>{
        if(data?.title =="" || data?.score ==""){
            mixinAlert("warning", "Please fill the all required fields");
            return;
        }
        if(data?.question_type == "single_choice" || data?.question_type == "multiple_choice"){
            const checkAnswers = data?.answer_options.find(item=>item?.is_correct==1);
            if(!checkAnswers){
                mixinAlert("warning", "Please select a correct answer");
                return;
            }
        }
        
        let formData = new FormData();
        for(const property in data){
            if(data[property]){
                formData.append(property, data[property])
            }
        }
        if(data?.question_type == "single_choice" || data?.question_type == "multiple_choice"){
            formData.append("answer_options", JSON.stringify(data?.answer_options))
        }else if(data?.question_type == "free_text" || data?.question_type == "drop_down"){
           let free_text_answers = JSON.stringify(this.extractWords(this.getTextFromHTML(data?.description)))     
            formData.append("answer_options", free_text_answers)
        }
        
        let url = "pooling-exam-questions-auto"
        if(data?.id && data?.id !=""){
            url = `pooling-exam-questions-auto/${data?.id}`;
            formData.append("_method", "put")
        }
        this.props.insertNewData(url, formData)
        .then(resp=>{
            if(resp === "success"){
                this.closeModal();
                this.props.getPoolingExamQuestions(this.props.params.section_id,this.props.activePage, this.props.level)
            }else{
                mixinAlert("error", "Semothing went wrong")
            }
        })
    }

    addAnswerBtn=(data)=>{
        let ans = [...data?.answer_options];
        // console.log(ans)
        ans.push({
            id:'',
            title:'',
            is_correct:0,
            question_id:'',
            type:ans[0]?.type,
            deleted:false
        });
        this.props.changeStateValue({
            name:"poolingExamAutoAdd.answer_options",
            value: ans
        })
    }
  render() {
    const {poolingExamAutoAdd} = this.props;
    const {btnDisabled} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white w-[900px] p-[20px] shadow rounded-[10px] max-h-[80vh] overflow-auto'>
            <div>
                <Input name="poolingExamAutoAdd.title" value={poolingExamAutoAdd?.title} required title='Title' />
                <div className='mt-[20px]'>    
                    <TextEditor name="poolingExamAutoAdd.instruction" value={poolingExamAutoAdd?.instruction} title="Instruction" />
                </div>
                <div className='mt-[20px]'>    
                    <TextEditor name="poolingExamAutoAdd.description" value={poolingExamAutoAdd?.description} title={poolingExamAutoAdd?.question_type == "free_text"? "Answers": "Description"} />
                </div>
              
                <div className='flex mt-[20px]'>
                    <Input title="Score" type="number" name="poolingExamAutoAdd.score" value={poolingExamAutoAdd?.score} required />
                </div>
                <div className='grid grid-cols-3 gap-[20px] mt-[20px]'>
                    <FileInput type="image" name="poolingExamAutoAdd.image" placeholder={'Choose a image'} value={poolingExamAutoAdd?.image_full_url} valueName="poolingExamAutoAdd.image_full_url" title="Choose a image"/>
                    <FileInput type="video" name="poolingExamAutoAdd.video" placeholder={'Choose a video'} value={poolingExamAutoAdd?.video_full_url} valueName="poolingExamAutoAdd.video_full_url" title="Choose a video"/>
                    <FileInput type="audio" name="poolingExamAutoAdd.audio" placeholder={'Choose a audio'} value={poolingExamAutoAdd?.audio_full_url} valueName="poolingExamAutoAdd.audio_full_url" title="Choose a audio"/>
                </div>
                {
                    poolingExamAutoAdd?.question_type == "single_choice" || poolingExamAutoAdd?.question_type == "multiple_choice"?
                    <>
                     <div className='mt-[20px] grid-cols-2 gap-[20px] grid'>
                    {
                        poolingExamAutoAdd?.answer_options?.map((data, i)=>{
                            if(!data?.deleted){
                                return (
                                    <AnswerBox question_type={poolingExamAutoAdd?.question_type} correct={data?.is_correct==1} row={i} data={data} />
                                )
                            }
                        })
                    }
                    </div>
                    <div className='flex items-center justify-center mt-[30px]'>
                        <button onClick={this.addAnswerBtn.bind(this, poolingExamAutoAdd)}  className='font-semibold text-[19px] border-b border-b-[2px] border-b-[var(--blue)]'>+ ADD ANSWER</button>
                    </div>
                    </>:null
                }
               
            </div>
            <div className='flex justify-between items-center mt-[30px]'>
                <button disabled={btnDisabled} onClick={this.closeModal.bind(this)}  className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Close</button>
                <button onClick={this.addQuestionBtn.bind(this, poolingExamAutoAdd)} disabled={btnDisabled} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Save</button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    poolingExamAutoAdd: state.Data.poolingExamAutoAdd
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData, getOneExamQuestion, getPoolingExamQuestions}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamAutoAddModal))


