import React, { Component } from 'react'
import { connect } from 'react-redux';

export class Questions extends Component {
    getGrade(data){
        let lastGrade= 0;
        if(!data?.reviews){
            lastGrade = data?.grade
        }else{
            if(data?.grade == 0){
                lastGrade = '0';
            }
           
            if(data?.robot_review && (data?.robot_review?.grade || data?.robot_review?.grade==0)){
                if(data?.grade2){
                    lastGrade =  ((data?.robot_review?.grade+data.grade+data?.grade2)/3).toFixed(1)
                }else{
                    lastGrade = ((data?.robot_review?.grade+data.grade)/2).toFixed(1)
                }
                
               
            }else{
                lastGrade = data?.grade;
            }
        }

        if(lastGrade<10){
            let checkhalf = lastGrade-Math.floor(lastGrade);
            if(checkhalf==0.5){
                return lastGrade;
            }else if(checkhalf == 0){
                return lastGrade;
            }else{
                if(checkhalf >0.5){
                    return Math.floor(lastGrade)+1;
                }else{
                    return Math.floor(lastGrade)+0.5
                }
            }
        }else{
            return lastGrade;
        }
    }
  render() {
    const {mainModal, lang} = this.props;
    // console.log(mainModal)
    return (
        <div className='flex justify-between items-start max-sm:flex-col-reverse max-sm:gap-[20px]'>
            <div className={mainModal?.grade && mainModal?.grade ?'max-w-[70%]  max-sm:max-w-[100%]': 'w-[100%]'}>
            {
                mainModal?.question == ''?
                <div className='flex justify-between'>
                    <p className='flex items-center'>{lang?.robo_question_waiting_text}<div className="loaderGPT">Loading...</div></p>
                </div>:
                <div className='w-[100%] no-copy' dangerouslySetInnerHTML={{ __html: mainModal?.question }}></div>
            }
            {
                mainModal?.type === "essay_write"?
                <h3 className='font-[700]'>{this.props.lang?.last_50?.replace("50", mainModal?.submit_word_count)}</h3>:null
            }
            {/* {
               mainModal?.question && mainModal?.question != '' && mainModal?.outline && mainModal?.outline === 'empty'?
                <div className='flex justify-between border-t mt-[10px] pt-[10px]'>
                    <p className='flex items-center'>{lang?.robo_outline_waiting_text}<div className="loaderGPT">Loading...</div></p>
                </div>: mainModal?.question && mainModal?.question != '' && mainModal?.outline &&mainModal?.outline !== ''?
                <div className='w-[100%]  max-sm:max-w-[100%] border-t mt-[10px] pt-[10px] no-copy' dangerouslySetInnerHTML={{ __html: `<pre className='generatedEssayStyle'>${mainModal?.outline}</pre>` }}></div>:null
            } */}
            </div>
            {
                mainModal?.grade && mainModal?.grade != ''?
                <span className='pl-[12px] pr-[12px] bg-blue text-[14px] font-bold text-white p-[6px] rounded-[10px] max-sm:w-[100%] max-sm:text-center'>{lang?.final_grade}: {mainModal?.type == 'ial_essay'? mainModal?.grade+ " "+ (mainModal?.robot_review?.grade?mainModal?.robot_review?.grade:''): this.getGrade(mainModal)}</span>:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
})
export default connect(mapStateToProps)(Questions)
