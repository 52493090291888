import React, { Component } from 'react'
import FileInput from '../../FileInput'
import Input from './Input'
import { mixinAlert } from '../../../actions/SweetAlerts'
import { connect } from 'react-redux'
import { insertNewData } from '../../../actions/MainAction';
import { getStudentPaymentsEnroll } from '../../../actions/StaffAction'
export class PayModalBody extends Component {
    state={
        count:'',
        note:'',
        fileType:'image',
        image:null,
        image_full_url:null,
        file:null,
        file_full_url:null,
        btnDisable:false,
        visible:false
    }

    changeFileType=(type)=>{
        this.setState({
            fileType:"",
            image:null,
            file:null
        });

        setTimeout(()=>{
            this.setState({
                fileType:type
            });
        },100)
    }

    uploadPay=(data)=>{
        if(data?.count =="" || (!data?.image && !data?.file)){
            mixinAlert("warning", "Please add count or upload file");
            return
        }
        this.setState({btnDisable:true});
        let formData = new FormData();
        for(const property in data){
            if(data[property]){
                formData.append(property, data[property]);
            }
        }
        formData.append("enroll_id", this.props.enroll_id);

        let url = 'enroll-payments';
        this.props.insertNewData(url, formData)
        .then(resp=>{
            this.setState({btnDisable:false});
            if(resp == "success"){
                mixinAlert("success", "Payment added successfully");
                this.props.getStudentPaymentsEnroll(this.props.student_id);
                this.props.openCardBtn(999);
                this.setState({
                    count:'',
                    note:'',
                    fileType:'image',
                    image:null,
                    image_full_url:null,
                    file:null,
                    file_full_url:null,
                    btnDisable:false,
                    visible:false
                })
            }else{
                mixinAlert("error", "Something went wrong");
            }
        })
    }
  render() {
    const {count, note, fileType, file, image_full_url, visible } = this.state;
    const {data, openCard, index, openCardBtn=()=>{}} = this.props
    return (
        <div>
        <div className='flex items-center justify-between gap-[10px]'>
            <span className='w-1/3 text-[13px] inline-block border-r'>{data?.lesson_mode}</span>
            <span className='w-1/3 text-[13px] inline-block border-r'>{data?.course?.title}</span>
            <div className='w-1/3 text-[13px] inline-block flex justify-center'>
                <button onClick={()=>openCardBtn(openCard==index?999:index)} className=' bg-blue text-white text-[12px] p-[2px_5px] rounded-[6px]'> Pay</button>
            </div>
        </div>
        {
            openCard==index?
            <div className='bg-gray-200 p-[10px] rounded-[10px] mt-[10px] '>
                <div className='grid grid-cols-2 max-[500px]:grid-cols-1 gap-[20px] '>
                    <Input onChangeParent={(value)=>this.setState({count:value})} required name="addPaymentModal.count" type="number" value={count}  title={"Checkin count"} />
                    <Input onChangeParent={(value)=>this.setState({note:value})} name="addPaymentModal.note" type="text" value={note}  title={"Checkin note"} />
                    <div>
                        <div className='flex items-center gap-[10px] mb-[10px]'>
                            <button onClick={this.changeFileType.bind(this, 'image')} className={`text-[12px] ${fileType=="image"? ' bg-blue text-white':'border txt-blue'} rounded-[4px] font-regular h-[20px] p-[0_5px] `}>Image</button>
                            <button onClick={this.changeFileType.bind(this, 'pdf')} className={`text-[12px] ${fileType=="pdf"? ' bg-blue text-white':'border txt-blue'} rounded-[4px] font-regular h-[20px] p-[0_5px] `}>PDF</button>
                        </div>
                        {
                        fileType=="image"?
                        <FileInput onChangeParent={(value)=>this.setState({image:value})} required type="image" name="addPaymentModal.image" title="Upload Image" value={image_full_url} valueName="addPaymentModal.image_full_url"  />:
                        fileType == "pdf"?
                        <FileInput required onChangeParent={(value)=>this.setState({file:value})} type="application/pdf" name="addPaymentModal.file" value={file} title="Upload file" valueName="addPaymentModal.file_full_url" />:null
                        }
                    </div>
                    
                </div>
                <div className='flex mt-[20px] justify-end'>
                    <button onClick={this.uploadPay.bind(this, this.state)} className=' bg-blue text-white text-[12px] p-[2px_5px] rounded-[6px]'>Pay & Upload</button>
                </div>
        
        </div>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    
});
const mapDispatchToProps = {insertNewData, getStudentPaymentsEnroll}
export default connect(mapStateToProps, mapDispatchToProps)(PayModalBody)
