import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../../actions/withHooks';
import { getIalExReviews, insertNewData } from '../../../../actions/MainAction';
import Card1 from '../../../../components/cards/Card1';
import moment from 'moment';
import { changeStateValue } from '../../../../redux/MainReducer';
import { getRole } from '../../../../actions/getRole';
import { getTurboModel, getMistralModel } from '../../../../actions/robotActions';
import { getIALExamsGPT, getIALExamsGPTGradeImage, getIALExamsGPTReviewImage, getIALExamsMistralGrade, getIALExamsMistralReview } from '../../../../actions/getReviewGpt';
import EmptyPage from '../../../../components/EmptyPage';
import LoadMoreBtn from '../../../../components/LoadMoreBtn';
import Search from '../../../../components/Search';
import { recheckIALEssay } from '../../../../actions/StaffAction';

export class IalExReviews extends Component {
    state={
        reviews:[],
        page: '',
        search:''
    }
    componentDidMount(){
        this.props.getIalExReviews(this.props.params.id,1, '')
        .then(resp=> this.setState({reviews: resp}));
        window.scrollTo(0,0)
    }
    matchDate(created_at){
        const date = moment(new Date(created_at)).add(1, 'week').format('YYYY/MM/DD');
        const newDate = new Date(date);
        const now = new Date();
       return newDate <= now;
    }

    checkOpen(data){
        if(data?.reviews.length !==0){
            return false
        }else if(this.matchDate(data?.submit_date)){
            return false
        }else{
            return true
        }
    }
    getClosePosition(data){
        if(this.props.user?.role === "head_teacher"){
           
            if(data.essay?.essay_type ===  "midterm_end_course"){
                if(data?.is_closed ===0){
                    return true;
                }else{
                    return false;
                }
            }else{
              
                if(data?.reviews.length === 0 && !this.matchDate(data?.submit_date)){
                    return true;
                }else{
                    return false;
                }
            }
        }else if(this.props.user?.role === "teacher" || this.props.user?.role === "speaking_teacher"){
            if(data?.reviews.length === 0 || this.matchDate(data?.created_at)){
                return true;
            }else{
                return false;
            }
        }else{
            return false
        }
    }
    openModal=(data)=>{
      
        const params = {
            position:true,
            type:'ial_essay',
            modalType: 'teacher_review',
            title: data?.essay?.title,
            question: `${data?.essay?.question} ${data?.essay?.image_full_url? `<img src="${data?.essay?.image_full_url}" />`:""}`,
            essay: data?.answer,
            reviews: data?.reviews[0]?.review,
            teacher: data?.reviews[0]?.user?.full_name,
            role: getRole(data?.reviews[0]?.user?.role, this.props.lang),
            stuent: data?.user?.full_name,
            robot_review:data?.robot_review,
            grade: data?.reviews[0]?.grade?data?.reviews[0]?.grade:" ",
            model_answer: data?.model_answer?.answer,
            essay_answer_id: data?.id,
            grade_inp:'',
            review_input:'',
            teacherEditor: this.getClosePosition(data),
            // teacherEditor: (this.props.user?.role === "teacher" ||  this.props.user?.role === "head_teacher" ||  this.props.user?.role === "speaking_teacher") && data?.reviews.length === 0 && data?.is_closed ===0?true:false ,
            essay_type: data?.essay?.essay_type,
            afterCallFunction: this.props.getIalExReviews,
            funcParams: [this.props.params.id, this.state.page, this.state.search],
            gradeDrop: this.getClosePosition(data),
            // gradeDrop:(this.props.user?.role === "teacher" ||  this.props.user?.role === "head_teacher" ||  this.props.user?.role === "speaking_teacher") && data?.reviews.length === 0 && data?.is_closed ===0?true:false,
            robo_waiting: !data?.robot_review?true:false,

        }
        if(!data?.robot_review){
            recheckIALEssay({id:data?.id})
            .then(resp=>{
                if(resp !== ""){
                    this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade: resp?.grade, review:resp?.review}});
                    this.props.getIalExReviews(this.props.params.id,this.state.page, this.state.search)
                }else{
                    this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade: "", review:""}});
                }
            })
        }
        this.props.changeStateValue({name: 'mainModal', value: params});

    }

    loadMore=(page)=>{
        this.setState({page:page});
        this.props.getIalExReviews(this.props.params.id,page, this.state.search)
        .then(resp=> this.setState({reviews: resp}))
    }
    onSearchData=(search)=>{
        this.setState({search:search});
        this.props.getIalExReviews(this.props.params.id,this.state.page, search)
        .then(resp=> this.setState({reviews: resp}))
    }
  render() {
    const {reviews} = this.state;
    const {ialExReviews, lang} = this.props;
    return (
        <div className='w-full'>
        <div className='flex justify-center'>
            <Search largWidth={true} onSearchData={this.onSearchData} />
        </div>
        {ialExReviews.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
       
        <div className='w-full grid-full-250 mt-[20px]'>
            {
                ialExReviews.map((data, i)=>{
                    return(
                        <Card1 
                            key={i}
                            title={data?.essay?.title}
                            grade={`${data?.reviews[0]?.grade?data?.reviews[0]?.user?.first_name:'Teacher'}: ${data?.reviews[0]?.grade?data?.reviews[0]?.grade:''}`}
                            grade2={`Selty: ${data?.robot_review?.grade?data?.robot_review?.grade:''}`}
                            date={moment(data?.submit_date).format("DD/MM/YYYY")}
                            status={data?.is_closed==0?0:this.checkOpen(data)?0:1}
                            onCLickBtn={this.openModal.bind(this, data)}
                            // close={data.is_closed === 0? lang?.close: lang?.status_closed}
                            greenStatus={true}
                        />
                    )
                })
               }
        </div>
            {
                ialExReviews?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={ialExReviews?.length} />:null
            }
        </div>
        
    )
  }
}
const mapStateToProps = (state) =>({
    ialExReviews: state.Data.ialExReviews,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getIalExReviews, changeStateValue, getTurboModel, insertNewData, getMistralModel}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(IalExReviews))
