import React, { Component } from 'react'

export class CheckInSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={19}
            fill="none"
        >
            <path
            fill="#585874"
            d="M3.75 16.05c-.413 0-.766-.146-1.06-.44a1.442 1.442 0 0 1-.44-1.06V4.05c0-.412.147-.765.441-1.059.294-.294.647-.44 1.059-.44H6.9c.162-.45.434-.813.816-1.088.381-.275.81-.412 1.284-.412.475 0 .903.137 1.285.412.381.275.653.638.815 1.088h3.15c.412 0 .766.147 1.06.44.294.295.44.648.44 1.06v10.5c0 .412-.147.765-.441 1.06-.294.293-.647.44-1.059.44H3.75Zm0-1.5h10.5V4.05H3.75v10.5ZM6 13.05h3.75c.213 0 .39-.071.535-.215a.723.723 0 0 0 .215-.534.726.726 0 0 0-.216-.535.723.723 0 0 0-.534-.215H6a.726.726 0 0 0-.535.216.723.723 0 0 0-.215.534c0 .212.072.39.216.534a.723.723 0 0 0 .534.216Zm0-3h6c.213 0 .39-.071.535-.215a.723.723 0 0 0 .215-.534.726.726 0 0 0-.216-.535.723.723 0 0 0-.534-.215H6a.726.726 0 0 0-.535.216.723.723 0 0 0-.215.534c0 .212.072.39.216.535A.723.723 0 0 0 6 10.05Zm0-3h6c.213 0 .39-.071.535-.215a.723.723 0 0 0 .215-.534.726.726 0 0 0-.216-.535.723.723 0 0 0-.534-.215H6a.726.726 0 0 0-.535.216.723.723 0 0 0-.215.534c0 .212.072.39.216.535A.723.723 0 0 0 6 7.05Zm3-3.562a.544.544 0 0 0 .563-.562A.544.544 0 0 0 9 2.363a.544.544 0 0 0-.563.563.544.544 0 0 0 .563.562Z"
            />
        </svg>
    )
  }
}

export default CheckInSvg
