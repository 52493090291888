import React, { Component } from 'react'
import EyeSvg from '../../svg/EyeSvg';

export class PaymentModalBody extends Component {
    state={
        openCheckins:false,
      
    }
  
  render() {
    const {i, item, selecteds=[], selectPayment=()=>{}} = this.props;
    const {openCheckins } = this.state;
   
    return (
        <div key={i} className='bg-gray-100 p-[6px] rounded-[10px]'>
            <div className='flex justify-between'>
                <div className='flex items-center gap-[7px]'>
                        <span className='inline-flex w-[15px] justify-center'>{i+1}.</span>
                        <input onChange={()=>selectPayment(selecteds, item)} checked={selecteds.find(a=>a.lesson_mode === item?.lesson_mode)?true:false} className='cursor-pointer' type='checkbox' />
                        <span>{item?.lesson_mode}</span>
                        
                </div>
                <div className='w-[40%] flex items-center justify-between'>
                    <span>{item?.chekins.length}</span>
                    <button onClick={()=>this.setState({openCheckins: !openCheckins})}>
                        <EyeSvg />
                    </button>
                </div>
            </div>
            {
                openCheckins?
                <ul className='p-[10px] bg-gray-200 rounded-[10px] mt-[10px]'>
                    {
                        item?.chekins.map((data, j)=>{
                            return(
                                <li className='text-[14px]' key={j}>{data?.date}</li>
                            )
                        })
                    }
                </ul>:null
            }
        </div>
    )
  }
}

export default PaymentModalBody
