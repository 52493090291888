import React, { Component } from 'react'
import { getExamCategories, getSingleData } from '../../../actions/StaffAction';
import { connect } from 'react-redux';
import DropSearch from '../../../components/DropSearch';
import { getDropValue } from '../../../actions/getRole';
import Input from '../../../components/modals/components/Input';
import { changeStateValue } from '../../../redux/MainReducer';

export class ExamSettings extends Component {
    state={
        subCategories:[],
        sections:[],
        bodyVisible:false
    }
    componentDidMount(){
        this.props.getExamCategories();
        if(this.props.category_id && this.props.category_id !=""){
            this.onSelectCategory(this.props.category_id)
        }
        if(this.props.sub_category_id && this.props.sub_category_id !=""){
            this.onSelectSubCategory(this.props.sub_category_id)
        }
    }
    onSelectCategory=(id)=>{
        this.props.getSingleData(`exam-sub-by-categories/${id}`)
        .then(resp=>{
            this.setState({subCategories: resp})
        })
    }
    onSelectSubCategory=(id)=>{
        this.props.getSingleData(`exam-sections-by-subcategory/${id}`)
        .then(resp=>{
            this.setState({sections: resp})
        })
    }

    onDeleteItem=(index, data, allData)=>{
        let newAllData = [...allData]
        if(data?.id && data?.id !=""){
            let newData = {
                ...data,
                deleted:true,
            }
            newAllData[index] = newData;
        }else{
            newAllData.splice(index, 1);
        }
        this.props.changeStateValue({
            name:"examPoolingModaldata.standart_exam_settings",
            value:newAllData
        })
    }
  render() {
    const {
        category_id, sub_category_id, 
        section_id, count, index,
        examCategories, data, allData} = this.props;
    const {subCategories, sections, bodyVisible} = this.state;

    let sectionOptions = [];
    for(const data of sections){
        sectionOptions.push({
            label: `${data?.title} (${data?.questions_count})`,
            value: data?.id
        })
    }
        // console.log(sectionOptions)
    return (
      <div className='bg-white p-[5px] rounded-[5px] '>
       <div className='flex items-center gap-[15px]'>
            <button onClick={()=>this.setState({bodyVisible: !bodyVisible})} className='bg-blue text-white text-[12px] p-[4px_6px] rounded-[4px]'>
                {bodyVisible?"Hide":"Show"}
            </button>
            {
                index !=0?
                <button onClick={this.onDeleteItem.bind(this, index, data, allData)} className='bg-red text-white text-[12px] p-[4px_6px] rounded-[4px]'>
                    Delete
                </button>:null
            }
       </div>
        {
            bodyVisible?
            <div className='grid grid-cols-2 max-[700px]:grid-cols-1 gap-[20px]  mt-[10px]'>
                <DropSearch 
                    options={getDropValue(examCategories, "title", "id")}
                    name={`examPoolingModaldata.standart_exam_settings.${index}.category_id`}
                    value={category_id}
                    title={"Select a category"}
                    placeholder={"Select a category"}
                    selectData={this.onSelectCategory.bind(this)}
                />
                <DropSearch 
                    options={getDropValue(subCategories, "title", "id")}
                    name={`examPoolingModaldata.standart_exam_settings.${index}.sub_category_id`}
                    value={sub_category_id}
                    title={"Select a sub category"}
                    placeholder={"Select a sub category"}
                    selectData={this.onSelectSubCategory.bind(this)}
                />
                <DropSearch 
                    options={sectionOptions}
                    name={`examPoolingModaldata.standart_exam_settings.${index}.section_id`}
                    value={section_id}
                    title={"Select a section"}
                    placeholder={"Select a section"}
                />
                <Input 
                    name={`examPoolingModaldata.standart_exam_settings.${index}.count`}
                    value={count}
                    title={"Count"}
                />
            </div>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    examCategories: state.Data.examCategories
});
const mapDispatchToProps = {getSingleData, getExamCategories, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ExamSettings)
