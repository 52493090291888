import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Card5 from '../../components/cards/Card5'
import DropSearch from '../../components/DropSearch'
import MonthlyCalendar from '../../components/MonthlyCalendar'
import { connect } from 'react-redux'
import { getTeachers, getTeacherSalary, getLessonModes, getUserData } from '../../actions/StaffAction'
import moment from 'moment'
import {SalaryCounting} from '../../actions/salaryCounting'
import EnrollCheckInModal from '../../components/modals/EnrollCheckInModal'
import { withHooks } from '../../actions/withHooks'
import EyeSvg from '../../components/svg/EyeSvg'
import BonusDataModal from '../../components/modals/BonusDataModal'
export class AccountingCounting extends Component {
    state={
        date: moment(new Date()).format("YYYY-MM-DD"),
        teacherId:'',
        teacher_status:0,
        teacher_name:'',
        salaryCounting: new SalaryCounting(),
        checkinsModal:{
            position: false
        },
        bonus_data:[]
    }
    getDataByTeacher=(data)=>{
        this.setState({teacherId:data?.id, teacher_status:data?.teacher_status, teacher_name: data?.full_name});
        this.props.getTeacherSalary(data?.id, this.state.date)
        this.setState({salaryCounting: new SalaryCounting(data?.teacher_status, this.props.lessonModes)})
    }
    selectMonth=(date)=>{
        this.setState({date:date})
        if(this.state.teacherId !== ""){
            this.props.getTeacherSalary(this.state.teacherId ,date)
        }
    }
    componentDidMount(){
        if(this.props.user !== ""){
           this.props.getTeachers(20, '', this.props.user?.company_id);  
        }
        this.props.getLessonModes()
        .then(resp=>{
            this.setState({salaryCounting: new SalaryCounting(0, resp)})
        });
        window.scrollTo(0,0);
        this.props.getUserData(this.props.params?.id)
        .then(resp=>{
           setTimeout(()=>{
            this.getDataByTeacher(resp)
           },1000)
        })
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps?.user !== this.props.user){
            this.props.getTeachers(20, '', this.props.user?.company_id);  
        }
    }
    
    showCheckins=(data)=>{
        this.setState({checkinsModal:{position:true, ...data}})
    }
    
   
  render() {
    const {teachers, teacherSalary, lang} = this.props;
    const {salaryCounting, teacher_name, checkinsModal, bonus_data} = this.state;
    let teacherOption = [];
    for(const data of teachers){
        teacherOption.push({
            label: data?.full_name,
            value: data
        })
    }
    let result = [];
    if(teacherSalary.check_ins){
       result = teacherSalary.check_ins.reduce(function (r, a) {
        r[a.group_name] = r[a.group_name] || [];
        r[a.group_name].push(a);
        return r;
      }, {});
    }
    // console.log(teacherSalary)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="t_counting" />
            <div className='flex gap-[20px] items-center white-search mt-[20px] max-[450px]:flex-col max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {/* <DropSearch options={teacherOption} placeholder="Select a teacher" selectData={this.getDataByTeacher.bind(this)} /> */}
                <MonthlyCalendar bgCont="bg-none" rounded="5px" bg="bg-white" onSelect={this.selectMonth.bind(this)} />
            </div>
            
            {
                teacher_name !== ""?
                <div className='flex mt-[20px]'>
                    <div className='bg-white rounded-[10px] w-[300px]'>
                        <h3 className='text-white bg-blue rounded-t-[10px] text-center h-[35px] pt-[5px] font-semobold text-[16px]'>{teacher_name} {lang?.summary}</h3>
                        <ul>
                            <li className='flex justify-between border-b'>
                                <span  className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.sub_total}</span>
                                <span className='txt-light-black p-[6px] w-1/2 font-regular text-[14px]'>{(salaryCounting.getGroupAllValue(Object.values(result))+salaryCounting.getIndividualAllValue(teacherSalary.check_ins)).toFixed(2)}</span>
                            </li>
                            <li className='flex justify-between border-b'>
                                <span   className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.bonus}</span>
                                <span  className='txt-light-black p-[6px] inline-flex justify-between w-1/2 font-regular text-[14px]'>{teacherSalary.bonus} 
                                {
                                    teacherSalary?.bonus_data?.length !==0?
                                    <button onClick={()=>this.setState({bonus_data: teacherSalary?.bonus_data})}>
                                        <EyeSvg />
                                    </button>:null

                                }
                                </span>
                            </li>
                            <li className='flex justify-between border-b'>
                                <span   className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_this_month}</span>
                                <span  className='txt-light-black p-[6px] w-1/2 font-regular text-[14px]'>{(salaryCounting.getGroupAllValue(Object.values(result))+salaryCounting.getIndividualAllValue(teacherSalary.check_ins)+teacherSalary.bonus).toFixed(2)}</span>
                            </li>
                        </ul>
                    </div>
                </div>:null
            }
            
            <div className='mt-[42px] items-start grid-full-320'>
                {
                     Object.values(result).map((data, i)=>{
                        if(data[0]?.group_name !== "One to One" && (!data[0]?.group_name.includes("Locked") || data[0]?.group_name.includes("Locked") && data.find(result => result.check_in.length !== 0))){
                            return(
                                <Card5 
                                    key={i}
                                    group={data[0]?.group_name.replace("Locked", "")} 
                                    time={salaryCounting.getLessonHour(data[0]?.lesson_houre)} 
                                    subject={data[0]?.lesson_mode}
                                    type={data[0]?.study_mode}
                                    students={salaryCounting.getGroupStudents(data)}
                                    money={salaryCounting.getTotalAmounts(data)}
                                    onShowCheckins={this.showCheckins.bind(this)}
                                    groupLockStatus={data[0]?.group_name.includes("Locked")?true:false}
                                />
                            )
                        }else if(data[0]?.group_name === "One to One"){
                            return(
                                data.map((item, j)=>{
                                    if(item?.status === "1" || (item?.status === "0" && item?.check_in.length !== 0)){
                                       return(
                                            <Card5 
                                                key={i+""+j}
                                                group={item?.group_name.replace("Locked", "")} 
                                                time={salaryCounting.getLessonHour(item?.lesson_houre)} 
                                                subject={item?.lesson_mode}
                                                type={item?.study_mode}
                                                students={salaryCounting.getIndividualStudents(item)}
                                                money={salaryCounting.getTotalAmounts([item])}
                                                onShowCheckins={this.showCheckins.bind(this)}
                                                groupLockStatus={item?.status ==="1"? false:true}
                                            />
                                        ) 
                                    }
                                    
                                })
                               
                            )
                        }
                     })
                }
                
            </div>
            {checkinsModal?.position? <EnrollCheckInModal data={checkinsModal} closeModalBtn={()=>{this.setState({checkinsModal:{position:false}})}} />:null}
            {bonus_data?.length !==0? <BonusDataModal bonus_data={bonus_data} onClose={()=>this.setState({bonus_data:[]})} />:null}
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    teachers: state.Data.teachers,
    user: state.Data.user,
    teacherSalary: state.Data.teacherSalary,
    lessonModes: state.Data.lessonModes,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getTeachers, getTeacherSalary, getLessonModes, getUserData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AccountingCounting))


