import React, { Component } from 'react'
import Header from '../../../components/header/Header'
import { getSubByCategory } from '../../../actions/StaffAction';
import { connect } from 'react-redux';
import Card1 from '../../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { deleteData } from '../../../actions/MainAction';
import { changeStateValue } from '../../../redux/MainReducer';
import HeaderButtons from '../../../components/HeaderButtons';
import { withHooks } from '../../../actions/withHooks';

export class ExamSubCategories extends Component {
    componentDidMount(){
        this.props.getSubByCategory(this.props.params.id)
    }
    onDeleteData=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
          if(alertResp === "success"){
            this.props.deleteData(`exam-sub-categories/${id}`)
            .then(resp=>{
              if(resp == "success"){
                this.props.getSubByCategory(this.props.params.id)
                mixinAlert("success", "Data deleted successfully")
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }
        })
    }

    onEditAddBtn=(data="")=>{
        this.props.changeStateValue({
            name: "adminModalData",
            value:{
                position: true,
                id: data?.id?data?.id:"",
                title: data?.title? data?.title:"",
                category_id: this.props.params?.id,
                modalTitle: data?.id?"Edit Sub Category":"Add Sub Category",
                requireds: ["title"],
                afterCallFunction: this.props.getSubByCategory,
                afterCallParams: [this.props.params.id],
                url: data?.id?`exam-sub-categories/${data?.id}`:"exam-sub-categories",
                // errorMsg: "Email already exists"
            }
        })
    }
  render() {
    const {examSubCategories, user} = this.props;
    // console.log(examSubCategories)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Exam Sub categories" />
            <div className='flex justify-end mt-[40px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons onClick={this.onEditAddBtn.bind(this, '')} buttons={[{title: "Add Sub Category", id:0}]} selected={0} />
            </div>
            <div className='grid grid-full-250 mt-[40px]'>
                {
                    examSubCategories?.map((data, i)=>{
                        return(
                            <Card1 
                                key={i}
                                title={data?.category?.title}
                                course={data?.title}
                                statusBisible={false}
                                status={1}
                                btn2Text={"Edit"}
                                btn={false}
                                btn2={true}
                                btn2Color={"bg-gray-500"}
                                url={`/exam-categories/${data?.category_id}/${data?.id}`}
                                adminDelete={user?.role === "super_admin"}
                                onDeleteBtn={this.onDeleteData.bind(this, data?.id)}
                                onCLickBtn={this.onEditAddBtn.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examSubCategories: state.Data.examSubCategories,
    user: state.Data.user,
});
const mapDispatchToProps = {getSubByCategory, deleteData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamSubCategories))


