import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import { withHooks } from '../../actions/withHooks';

export class AccountantCompanyData extends Component {
  render() {
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={this.props.params.company_name?.replaceAll("%20", " ")} />
            <div >

            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AccountantCompanyData))
