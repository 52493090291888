import React, { Component } from 'react'
import NavLink from './NavLink'
import { connect } from 'react-redux'
import { navLinks } from './roleLinks'
import { changeStateValue } from '../../redux/MainReducer'
export class Navigation extends Component {
  closeNav=()=>{
    const nav = document.querySelector('#navBtnCheckox');
      if(nav){
        nav.checked = false;
        this.props.changeStateValue({name:'navBtn', value:true})
      }
  }
  render() {
    const {user, lang, searchNav} = this.props;
    let navs = [];
    if(user?.role === "student"){
      // console.log(user)
      navs = navLinks(lang, user?.send_email_status).studentRole;
    }else if(user?.role === "parent"){
      navs = navLinks(lang).parentRole;
    }else if(user?.role === "company_head"){
      navs = navLinks(lang).companyHeadRole;
    }else if(user?.role === "office_manager"){
      navs = navLinks(lang).officeManagerRole;
    }else if(user?.role === "head_teacher"){
      navs = navLinks(lang).headTeacherRole;
    }else if(user?.role === "teacher"){
      navs = navLinks(lang).teacherRole;
    }else if(user?.role === "speaking_teacher"){
      navs = navLinks(lang).speakingTeacherRole
    }else if(user?.role === "content_manager"){
      navs =navLinks(lang).contentRole
    }else if(user?.role === "super_admin"){
      navs =navLinks(lang).superAdminRole
    }else if(user?.role === "accountant"){
      navs =navLinks(lang).accountantRole
    }else if(user?.role === "auditor"){
      navs =navLinks(lang).auditorRole
    }else if(user?.role === "support"){
      navs =navLinks(lang).supportRole
    }
    return (
      <nav style={{ height: 'calc(100vh - 100px)' }} onClick={this.closeNav.bind(this)} className='pt-[16px] overflow-auto nav_scroll'>
        {
          navs.map((data, i)=>{
            return(
              <div key={i}>
                { data?.part !== ""? <span className='pl-[26px] txt-dark-gray text-[10px] font-regular'>{data?.part}</span>:null  }
                <ul className='p-[13px] flex flex-col gap-[2px]'>
                  {
                    data?.pages?.map((page, j)=>{
                      if(page?.title?.toLowerCase().includes(searchNav?.toLowerCase())){
                        return(
                          <NavLink key={i+""+j} title={page?.title} svg={page?.svg} path={page?.path} />
                        )
                      }
                    })
                  }
                </ul>
                { data?.line?<div className='h-[1px] bg-light-gray ml-[24px] mr-[24px]'></div>:null }
              </div>
            )
          })
        }
      </nav>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  lang: state.Data.lang,
  searchNav: state.Data.searchNav,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
