import React, { Component } from 'react'

export class SpReviewSvg extends Component {
  render() {
    return (
        
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
      >
        <g clipPath="url(#a)">
          <path
            fill="#585874"
            d="M15.75 14.063v-4.5a6.75 6.75 0 0 0-13.5 0v4.5c-.166 1.603-2.214 1.184-2.25 0v-4.5a9 9 0 0 1 18 0v4.5c-.166 1.603-2.214 1.184-2.25 0ZM3.937 10.687h1.126c.31 0 .562.252.562.563v5.625a.562.562 0 0 1-.563.563H3.938a.562.562 0 0 1-.562-.563V11.25c0-.311.252-.563.563-.563Zm9 0h1.126c.31 0 .562.252.562.563v5.625a.562.562 0 0 1-.563.563h-1.124a.562.562 0 0 1-.563-.563V11.25c0-.311.251-.563.563-.563Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h18v18H0z" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default SpReviewSvg
