import React, { Component } from 'react'
import Calendar from '../../../components/Calendar'
import moment from 'moment';
import LeftSvg from '../../../components/svg/LeftSvg';
import RightSvg from '../../../components/svg/RightSvg';

export class SelectDate extends Component {
    state={
        days:[],
        date: new Date(),
        selectedDate:"",
    }
    renderCalendar(date){
        let days = [];
        const  week_days=[
            "Sun",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat"
          ]
        const weeks = week_days;
        const startDay = new Date(
            date.getFullYear(),
            date.getMonth(),
            1
        )
        const week = weeks.indexOf(String(startDay).split(" ")[0]);
        const prevMonthDays = new Date(
            date.getFullYear(),
            date.getMonth(),
            0
        ).getDate()
       

        for(let x = prevMonthDays - week+1; x<= prevMonthDays; x++){
            const prevDate = new Date(
                date.getFullYear(),
                date.getMonth()-1,
                x
            )
            days.push({
                type: 'prev',
                day:`${x}`,
                date: moment(prevDate).format("YYYY-MM-DD")
            })
        }


        const allDays = new Date(
            date.getFullYear(),
            date.getMonth()+1,
            0
        ).getDate()
       

        for(let i = 1; i<=allDays; i++){
            const allDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                i
            );
            days.push({
                type: 'current',
                day: `${i}`,
                date:moment(allDate).format("YYYY-MM-DD")
            })
        }

        // return next month days
        const nexDays = new Date(
            date.getFullYear(),
            date.getMonth()+1,
            0
        ).getDay()
        

        for(let j = 1; j<=(7 -nexDays -1); j++){
            const nexDate = new Date(
                date.getFullYear(),
                date.getMonth()+1,
                j
            )
            days.push({
                type:'next',
                day: `${j}`,
                date: moment(nexDate).format("YYYY-MM-DD")
            })
        }
    
        this.setState({days:days})
        return days;
    }
    componentDidMount(){
        this.renderCalendar(this.state.date)
    }
    nextMonth(date){
        let month = date.getMonth() + 1;
        date.setMonth(month);
        this.renderCalendar(date);
    }
    prevMonth(date){
        let month = date.getMonth() - 1;
        date.setMonth(month);
        this.renderCalendar(date);
    }

    selectDay(day, date){
        const selcetedDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            day
        );
        this.setState({selectedDate: moment(selcetedDate).format("YYYY-MM-DD")});
        this.props.setDateProps(moment(selcetedDate).format("YYYY-MM-DD"))
  
    }
  render() {
    const {days, date} = this.state;
    const {onSelectDate=()=>{}, selected} = this.props;
    const  week_days=[
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ];
    return (
        <div className='mt-[20px] max-h-[60vh] overflow-auto'>
            <div className='flex justify-center'>
                <h3 className='txt-blue font-semibold text-[24px]'>Select a Date</h3>
                
            </div>
            <div>
            <div className='flex bg-blue justify-center items-center gap-[20px] h-[35px] rounded-t-[10px]'>
                <button onClick={this.prevMonth.bind(this, date)}><LeftSvg /></button>
                <span className='text-white'>{moment(date).format("MMMM")}</span>
                <button onClick={this.nextMonth.bind(this, date)}><RightSvg /></button>
            </div>
            <div className='border'>
                <div className='grid grid-cols-7'>
                    {
                       week_days?.map((data, i)=>{
                            return(
                                <span className='w-1/7 text-center border-r h-[30px] pt-[5px] text-[14px]' key={i}>{data}</span>
                            )
                        })
                    }
                </div>
                <div className='grid grid-cols-7 rounded-b-[10px]'>
                    {
                        days.map((data, i)=>{
                            return(
                                 <span onClick={()=>onSelectDate(data)}  className={`w-1/7 inline-block text-center border-t border-r h-[90px] inline-flex justify-center items-center text-[14px] font-semibold cursor-pointer ${data?.date == selected? "bg-blue text-white":''}`} key={i}>{data?.day}</span>
                            )
                        })
                    }
                </div>
            </div>
            </div>
        </div>
    )
  }
}

export default SelectDate
