import React, { Component } from 'react'
import CloseReplySvg from '../svg/CloseReplySvg'

export class ReplyContainer extends Component {
  render() {
    const {name, msg, onClose=()=>{}, color} = this.props
    return (
        <div className='bg-[#F5F9FF] gap-[16px] mt-[7px] rounded-[10px] flex items-center justify-between p-[10px] h-[60px] w-full'>
            <div className='bg-[rgba(0,0,0,0.03)] pl-[20px] pt-[5px] rounded-[6px] max-h-[50px] overflow-hidden w-full border-l-[2px] border-l-[var(--blue)]'>
                <h4 className={`text-[14px] ${color}`}>{name}</h4>
                <span className='text-[12px]'>{msg}</span>
            </div>
            <button onClick={()=>onClose()}>
                <CloseReplySvg />
            </button>
        </div>
    )
  }
}

export default ReplyContainer
