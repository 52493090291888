import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import { connect } from 'react-redux'
import { getStaffEssayReview, recheckEssay } from '../../actions/StaffAction'
import Card1 from '../../components/cards/Card1'
import moment from 'moment'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { changeStateValue } from '../../redux/MainReducer'
import { getGradeAll, getGrades, getRole } from '../../actions/getRole'
import { getTurboModel, getMistralModel } from '../../actions/robotActions'
import { getEssayGpt, getEssayGradeWritingGpt, getEssayGradeWritingIELTSGpt, getEssayGradeWritingIELTSImageGpt, getEssayModelAnswerGpt, getEssayWritingGpt, getEssayWritingImageGpt } from '../../actions/getReviewGpt';
import { insertNewData, checkEssayPlagiarism } from '../../actions/MainAction'
import EmptyPage from '../../components/EmptyPage'
export class CeltEssaysReview extends Component {
    state={
        search:'',
        page:1
    }
    onSearchData=(search)=>{
        this.setState({search:search});
        this.props.getStaffEssayReview({page: 1, keyword: search, type:'', essay_type: ''})
    }

    componentDidMount(){
        this.props.getStaffEssayReview({page: 1, keyword: '', type:'', essay_type: ''});
        window.scrollTo(0,0)
    }

    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getStaffEssayReview({page: page, keyword: this.state.search, type:'', essay_type: ''})
    }
    getGrade(reviews, autoGrade, grade){
      
        if(reviews.length == 0){
            return grade
        }else{
            if(grade == 0){
                return '0';
            }
            let grades = 0;
            for(const data of reviews){
                grades+=data.grade;
            }
            if(autoGrade){
                return ((grades+autoGrade.grade)/(reviews.length+1)).toFixed(0)
            }else{
                return grade;
            }
        }
    }
    getPlagiarism(plagiarism){
        if(plagiarism.length === 0){
            return 'not checked yet';
        }else if(plagiarism[0].percentage == 0){
            return 'nothing found';
        }else{
            let value = [];

            for(let data of plagiarism){
                value.push(+data.percentage)
            }

            return `${Math.round(Math.max(...value))}%`;
        }
    }
    matchDate(created_at){
        const date = moment(new Date(created_at)).add(1, 'week').format('YYYY/MM/DD');
        const newDate = new Date(date);
        const now = new Date();
       return newDate <= now;
    }
    getClosePosition(data){
        if(data.essay?.essay_type ===  "midterm_end_course"){
            return false;
        }
        if(this.props.user?.role === "head_teacher"){
            if(data.essay?.essay_type ===  "midterm_end_course"){
                if(data?.is_closed ===0){
                    return true;
                }else{
                    return false;
                }
            }else{
                if(data?.reviews.length === 0 || this.matchDate(data?.updated_at)){
                    return true;
                }else{
                    return false;
                }
            }
        }else if(this.props.user?.role === "teacher" || this.props.user?.role === "speaking_teacher"){
            if(data?.reviews.length === 0 || this.matchDate(data?.created_at)){
                return true;
            }else{
                return false;
            }
        }else{
            return false
        }
    }
    closeOpenBtn=(data)=>{
        if(this.props.user?.role == "head_teacher" || this.props.user?.role == "company_head"){
            if(data?.is_closed === 1 &&  this.props.user?.role == "company_head"){
                let formData = new FormData();
                formData.append("_method", "put")
                this.props.insertNewData(`essay-answers/${data?.id}`, formData)
                .then(resp=>this.props.getStaffEssayReview({page: this.state.page, keyword: this.state.search, type:'', essay_type: ''}))
            }else if(data?.is_closed === 0){
                if(data?.grade !== null){
                    let formData = new FormData();
                    formData.append("_method", "put")
                    this.props.insertNewData(`essay-answers/${data?.id}`, formData)
                    .then(resp=>this.props.getStaffEssayReview({page: this.state.page, keyword: this.state.search, type:'', essay_type: ''}))
                }
            }
        }
    }

    openModal=(data,  btnType="")=>{
      
        const params = {
            position:true,
            type:'essay',
            modalType: 'teacher_review',
            title: data?.essay?.title,
            question: data?.question ? `<pre class='generatedEssayStyle'>${data?.question} ${data?.essay?.image_full_url?`<img src="${data?.essay?.image_full_url}" />`:'' }<br /><hr /> <br />${data?.outline && data?.outline!="empty"?data?.outline:''}</pre>`: `${data?.essay?.question}  ${data?.essay?.image_full_url?`<img src="${data?.essay?.image_full_url}" />`:'' }`,
            essay: data?.answer,
            is_closed: data?.is_closed,
            reviews: data?.reviews[0]?.review,
            teacher: data?.reviews[0]?.user?.full_name,
            reviews2: data?.reviews[1]?.review,
            teacher2: data?.reviews[1]?.user?.full_name,
            grade2: data?.reviews[1]?.grade,
            role: getRole(data?.reviews[0]?.user?.role, this.props.lang),
            role2: getRole(data?.reviews[1]?.user?.role, this.props.lang),
            stuent: data?.user?.full_name,
            robot_review: btnType=="recheck"?null: data?.robot_review,
            grade: data?.grade,
            model_answer: data?.model_answer?.answer,
            essay_answer_id: data?.id,
            grade_inp:'',
            head_teacher_confirm: this.props.user?.role === "head_teacher" && data?.essay?.essay_type === "midterm_end_course"? true:false,
            review_input:'',
            robo_waiting: !data?.robot_review?true:false,
            teacherEditor: this.getClosePosition(data) ,
            gradeInp: this.getClosePosition(data) ,
            essay_type: data?.essay?.essay_type,
            afterCallFunction: this.props.getStaffEssayReview,
            funcParams: [{page: this.state.page, keyword: this.state.search, type:'', essay_type: ''}],
        }
        if(btnType == "recheck"){
            setTimeout(()=>{
                this.props.changeStateValue({
                    name:'mainModal.robo_waiting',
                    value:true
                })
            }, 500)
        }
        if(!data?.robot_review || btnType == "recheck"){
            recheckEssay({id: data?.id})
            .then(resp=>{
                if(resp !== ""){
                    this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade: resp?.grade, review:resp?.review}});
                    this.props.getStaffEssayReview({page: this.state.page, keyword: this.state.search, type:'', essay_type: ''})
                }else{
                    this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade: "", review:""}});
                }
            })
        }
        this.props.changeStateValue({name: 'mainModal', value: params});
        let type = 'unit';
        if(data.essay.title.split(' ').includes('Movie')){
            type = 'movie'
        }
      
        this.props.checkEssayPlagiarism(data.id, data.essay_id, type, data.user.company_id)
    }

    checkOpen(data){
        if(data?.reviews.length !==0){
            return false
        }else if(this.matchDate(data?.submit_date)){
            return false
        }else{
            return true
        }
    }
  render() {
    const {staffEssay, lang} = this.props;
    // console.log(staffEssay)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="es_reviews" />
            <div className='flex justify-center  max-[1100px]:mt-[120px] max-[800px]:mt-[170px] '>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            {staffEssay.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No results found" subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
                {
                    staffEssay?.map((data, i)=>{
                        return(
                            <Card1 
                                key={i}
                                title={data?.essay?.title}
                                name={data?.user?.full_name}
                                userLink={`/students/${data?.user?.id}`}
                                grade={getGradeAll(data.reviews,  data.robot_review, data.grade)}
                                date={data?.submit_date? moment(data?.submit_date).format("DD/MM/YYYY"):null}
                                cheating={this.getPlagiarism(data.plagiarism) === "not checked yet"?lang?.waiting:this.getPlagiarism(data.plagiarism) === "nothing found"?lang.cheating+" 0%":lang.cheating+" "+ this.getPlagiarism(data.plagiarism)}
                                // close={ data.is_closed === 0?this.props.lang?.status_close:this.props.lang?.status_closed}
                                onLineClick={this.closeOpenBtn.bind(this, data)}
                                status={getGradeAll(data.reviews,  data.robot_review, data.grade)>=data?.essay?.course_prompt?.grade?1:0}
                                statusText={getGradeAll(data.reviews,  data.robot_review, data.grade)>=data?.essay?.course_prompt?.grade?lang?.pass: lang?.fail}
                                // greenStatus={true}
                                onCLickBtn={this.openModal.bind(this, data, "")}
                                cheatingColor={this.getPlagiarism(data.plagiarism) === "not checked yet"?false:this.getPlagiarism(data.plagiarism) === "nothing found"?false:true}
                                reCheckBtn={(data?.robot_review?.grade==1  && data?.robot_review?.grade_error) && data?.robot_review?.recheck_count <=3?true:false}
                                onReCheck={this.openModal.bind(this, data, "recheck")}
                            />
                        )
                    })
                }
            </div>
            {
                staffEssay?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={staffEssay?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    staffEssay: state.Data.staffEssay,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getStaffEssayReview, changeStateValue, getTurboModel, insertNewData, checkEssayPlagiarism, getMistralModel}
export default connect(mapStateToProps, mapDispatchToProps)(CeltEssaysReview)
