import React, { Component } from 'react'
import CardEditSvg from '../svg/CardEditSvg';

export class CardLineBtn extends Component {
  render() {
    const {svg, title, onLineClick=()=>{}, editSvg=false} = this.props;
    return (
        <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
            <div className='bg-light-blue w-[22px] h-[22px] rounded-[3px] inline-flex items-center justify-center'>
                {svg}
            </div>
            <button onClick={()=>onLineClick()} className='text-[14px] txt-dark-gray font-semibold inline-flex gap-[10px]'> {title} {editSvg? <CardEditSvg />:null} </button>
        </li>
    )
  }
}

export default CardLineBtn
