import React, { Component } from 'react'

export class CardExamSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={21}
        fill="none"
      >
        <path
          fill="#545454"
          d="M8.75 6.563a.437.437 0 0 1 .438-.438h3.5a.438.438 0 0 1 0 .875h-3.5a.437.437 0 0 1-.438-.438Zm.438 1.312a.437.437 0 1 0 0 .875h3.5a.438.438 0 0 0 0-.875h-3.5ZM8.75 12.25a.438.438 0 0 1 .438-.438h3.5a.438.438 0 0 1 0 .876h-3.5a.438.438 0 0 1-.438-.438Zm.438 1.313a.438.438 0 0 0 0 .874h3.5a.438.438 0 0 0 0-.874h-3.5Z"
        />
        <path
          fill="#545454"
          fillRule="evenodd"
          d="M4.375 11.813a.438.438 0 0 1 .438-.438H7a.438.438 0 0 1 .438.438V14a.438.438 0 0 1-.438.438H4.812A.438.438 0 0 1 4.375 14v-2.188Zm.875.437v1.313h1.313V12.25H5.25Z"
          clipRule="evenodd"
        />
        <path
          fill="#545454"
          d="M7.747 6.872a.438.438 0 0 0-.619-.619l-1.44 1.44-.566-.565a.438.438 0 0 0-.619.619L5.687 8.93l2.06-2.06Z"
        />
        <path
          fill="#545454"
          fillRule="evenodd"
          d="M4.375 2.625a1.75 1.75 0 0 0-1.75 1.75v12.25a1.75 1.75 0 0 0 1.75 1.75h8.75a1.75 1.75 0 0 0 1.75-1.75V4.375a1.75 1.75 0 0 0-1.75-1.75h-8.75ZM3.5 4.375a.875.875 0 0 1 .875-.875h8.75a.875.875 0 0 1 .875.875v12.25a.875.875 0 0 1-.875.875h-8.75a.875.875 0 0 1-.875-.875V4.375ZM15.75 7a1.313 1.313 0 0 1 2.625 0v8.883l-1.313 1.968-1.312-1.968V7Zm1.313-.438a.438.438 0 0 0-.438.438v.875h.875V7a.438.438 0 0 0-.438-.438Zm0 9.712-.438-.657V8.75h.875v6.867l-.438.657Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default CardExamSvg
