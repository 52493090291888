import React, { Component } from 'react'
import { getFailedStudents, getStudentCourses } from '../../actions/StaffAction';
import { connect } from 'react-redux';
import Header from './Header';
import FileInput from '../FileInput';
import DropSearch from '../DropSearch';
import { getDropValue } from '../../actions/getRole';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { mixinAlert } from '../../actions/SweetAlerts';
import { insertNewData } from '../../actions/MainAction';

export class FailedStudentExcpModal extends Component {
    state={
        btnDisabled:false
    }
    componentDidMount(){
        this.props.getStudentCourses(this.props.student?.id)
    }
    closeModal=()=>{
        this.props.closeBtn()
    }
    saveBtn=(data)=>{
        if(data?.image !="" && data?.course_id !=""){
            let formData = new FormData();
            formData.append("image", data?.image);
            formData.append("course_id", data?.course_id);
            formData.append("student_id", this.props.student?.id);
            formData.append("status", '0');
            formData.append("type", 'exception');
            
            this.props.insertNewData("first-failed-exception", formData)
            .then(resp=>{
                if(resp=="success"){
                    this.props.closeBtn()
                    this.props.getFailedStudents("single", this.props.student?.id)
                    this.props.resetStateValue({name:"failedStModal"})
                    mixinAlert("success", "Your request upload successfully.")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else{
            mixinAlert("warning", "Please fill the required fields")
        }
    }
    onSelectData=(data)=>{
        this.props.changeStateValue({name:"failedStModal.course_id", value: data})
    }
  render() {
    const {studentCoursesAll, failedStModal, lang, selecteds} = this.props;
    const {btnDisabled} = this.state;
    let options = [];
    for(const data of studentCoursesAll){
        const check = selecteds.find(x=> x.course_id == data?.id);
        if(!check){
            options.push({
                label: data?.title,
                value:data?.id
            })
        }
    }
    // console.log(failedStModal)
    return (
        <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[500px]  shadow bg-white rounded-[10px] max-h-[70vh]'>
                <Header closeBtn={this.closeModal.bind(this)} title={'Failed student Exception'} />
                <div className='p-[20px] max-h-[60vh]'>
                    <FileInput required inpOtherType={true} type="image/png, image/jpeg, image/jpg" title={lang?.upload_id_card} valueName="failedStModal.image_full_url" value={failedStModal?.image_full_url} name="failedStModal.image" />
                    <div>
                        <DropSearch required options={options} title="Select a course" placehodler="Select a course" name="" selectData={this.onSelectData.bind(this)} value={failedStModal?.course_id} />
                    </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,failedStModal)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.upload}</button>
                    </div>
                </div>
                
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentCoursesAll: state.Data.studentCoursesAll,
    failedStModal: state.Data.failedStModal,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getStudentCourses, getFailedStudents, resetStateValue, changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(FailedStudentExcpModal)
