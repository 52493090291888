import React, { Component } from 'react'
import FileInput from '../FileInput'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import Header from './Header'
import DropSearch from '../DropSearch'
import { getDropValue } from '../../actions/getRole'
import { mixinAlert } from '../../actions/SweetAlerts'
import { insertNewData } from '../../actions/MainAction'
import { getEndOfExamExceptions } from '../../actions/StaffAction'

export class EndOfExceptionModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.closeBtn()
    }
    saveBtn=(data)=>{
        if(data?.image !="" && data?.course_id !=""){
            let formData = new FormData();
            formData.append("image", data?.image);
            formData.append("course_id", data?.course_id);
            formData.append("user_id", this.props.student?.id);
            formData.append("lesson_id", data?.lesson_id);
            formData.append("status", '0');
            
            this.props.insertNewData("end-ofexam-exceptions", formData)
            .then(resp=>{
                if(resp=="success"){
                    this.props.closeBtn()
                    this.props.getEndOfExamExceptions("single", this.props.student?.id)
                    this.props.resetStateValue({name:"endOfExceptModal"})
                    mixinAlert("success", "Your request upload successfully.")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else{
            mixinAlert("warning", "Please fill the required fields")
        }
    }
    onSelectData=(data)=>{
        // console.log(data)
        this.props.changeStateValue({name:"endOfExceptModal.course_id", value: data?.lesson?.course_id})
        this.props.changeStateValue({name:"endOfExceptModal.lesson_id", value: data?.lesson?.id})
    }
  render() {
    const {lang, endOfExceptModal, courses, endOfExamExceptions} = this.props;
    // console.log(courses, )
    // console.log(endOfExamExceptions, )
    const {btnDisabled} = this.state;
    let options = [];
    for(const data of courses){
        const check = endOfExamExceptions.find(x=>x.lesson_id == data?.end_of_course_id);
        if(!check){
            options.push({
                label: data?.lesson?.title,
                value: data
            })
        }
    }
    return (
      <div className='background_shadow flex justify-center items-center'>
          <div className='bg-white w-[500px]  shadow bg-white rounded-[10px] max-h-[70vh]'>
                <Header closeBtn={this.closeModal.bind(this)} title={'End of Course Exception'} />
                <div className='p-[20px] max-h-[60vh]'>
                    <FileInput required inpOtherType={true} type="image/png, image/jpeg, image/jpg" title={lang?.upload_id_card} valueName="endOfExceptModal.image_full_url" value={endOfExceptModal?.image_full_url} name="endOfExceptModal.image" />
                    <div>
                        <DropSearch required options={options} title="Select a course" placehodler="Select a course" name="" selectData={this.onSelectData.bind(this)} value={endOfExceptModal?.course_id} />
                    </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,endOfExceptModal)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.upload}</button>
                    </div>
                </div>
               
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    endOfExceptModal: state.Data.endOfExceptModal,
    lang: state.Data.lang,
});
const mapDispatchToProps = {changeStateValue, insertNewData, getEndOfExamExceptions, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(EndOfExceptionModal)
