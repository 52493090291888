import React, { Component } from 'react'
import img from "../../images/searchingGif.gif";
export class ResponseWaitModal extends Component {
  render() {
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='bg-white p-[10px] w-[400px] rounded-[10px] pt-[1px]'>
            <h2 className='text-center font-semibold text-[18px] mt-[20px] txt-blue'>Exam approval request</h2>
            {/* <div className="loader"></div> */}
            <div className='flex justify-center'>
                <img className='w-[120px]' src={img} />
            </div>
            <h3 className='mt-[0px] txt-blue font-semibold text-[18px] text-center'>
                Waiting <br />
                Your request has been sent.
            </h3>
        </div>
      </div>
    )
  }
}

export default ResponseWaitModal
