import React, { Component } from 'react'
import TeacherSelect from './TeacherSelect'
import GroupSelect from './GroupSelect';
import { connect } from 'react-redux';
import EnrollParams from './EnrollParams';
import { getAdminPriceCalculator } from '../../../../actions/StaffAction';
import PaymentParams from './PaymentParams';
import { resetStateValue } from '../../../../redux/MainReducer';

export class StudentCalculatorModal extends Component {
  componentDidMount(){
    this.props.getAdminPriceCalculator()
  }
  render() {
    const {student, studentCalculatorModal, onClose=()=>{}} = this.props;
    return (
      <div className='background_shadow flex justify-center items-center'>
        {
            studentCalculatorModal?.page === "teacher"?
            <TeacherSelect onClose={()=>{onClose(); this.props.resetStateValue({name:"studentCalculatorModal"})}} student={student} />:
            studentCalculatorModal?.page === "group"?
            <GroupSelect onClose={()=>{onClose(); this.props.resetStateValue({name:"studentCalculatorModal"})}} student={student} />:
            studentCalculatorModal?.page === "enroll_params"?
            <EnrollParams onClose={()=>{onClose(); this.props.resetStateValue({name:"studentCalculatorModal"})}} student={student} />:
            studentCalculatorModal?.page === "pay_params"?
            <PaymentParams onClose={()=>{onClose(); this.props.resetStateValue({name:"studentCalculatorModal"})}} student={student} />:null
        }
        {/*  */}
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentCalculatorModal: state.Data.studentCalculatorModal
});
const mapDispatchToProps = {getAdminPriceCalculator, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(StudentCalculatorModal)
