import React, { Component } from 'react'

export class PrivateMsgSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={15}
        height={15}
        viewBox="0 0 502.664 502.664"
      >
        <path
          d="m374.404 214.834 128.238-109.062V75.767H0v30.048l128.217 109.062c4.314 3.581-81.753-50.044-128.217-79.014v291.033h502.664V135.842c-46.485 28.97-132.531 82.595-128.26 78.992zm-200.889-25.712c0-42.861 34.945-77.741 77.849-77.741 42.796 0 77.72 34.858 77.72 77.741v45.148h-29.315v-45.148c0-26.705-21.765-48.534-48.362-48.534-26.748 0-48.534 21.83-48.534 48.534v45.148h-29.336l-.022-45.148zM345.78 384.92H156.863V247.751H345.78V384.92z"
          style={{
            fill: "#010002",
          }}
        />
        <path
          d="M241.981 309.012 237.99 348.4h26.597l-4.055-39.388c5.004-3.128 8.305-8.628 8.305-14.862 0-9.707-7.873-17.537-17.472-17.537-9.793 0-17.58 7.83-17.58 17.537.021 6.234 3.321 11.756 8.196 14.862z"
          style={{
            fill: "#010002",
          }}
        />
      </svg>
    )
  }
}

export default PrivateMsgSvg
