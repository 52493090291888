import React, { Component } from 'react'

export class LoginEmailSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={16}
            fill="none"
            style={{ minWidth:'20px' }}
        >
            <path
            stroke="#64748B"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M17 1H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Z"
            />
            <path
            stroke="#64748B"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="m1 3 9 6 9-6"
            />
        </svg>
    )
  }
}

export default LoginEmailSvg
