import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getOfficeManagers } from '../../actions/StaffAction';
import Header from '../../components/header/Header';
import Card4 from '../../components/cards/Card4';
import SectionHeader from '../../components/SectionHeader';
import { getRole } from '../../actions/getRole';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import { changeStateValue } from '../../redux/MainReducer';
import { deleteData, insertNewData } from '../../actions/MainAction';
import { bigAlert, confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import EmptyPage from '../../components/EmptyPage';
export class OfficeManagers extends Component {
    state={
        page:1,
        search:''
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps?.user !== this.props.user){
           this.props.getOfficeManagers(1, '', this.props.user?.company_id);  
        }
    }
    componentDidMount(){
        if(this.props.user !== ""){
            this.props.getOfficeManagers(1, '', this.props.user?.company_id);  
        }
        window.scrollTo(0,0)
    }
    loadMore=(page)=>{
        this.setState({
            page:page
        })
        this.props.getOfficeManagers(page, this.state.search, this.props.user?.company_id);  
    }
    searchManager=(search)=>{
        this.setState({
            search:search
        })
        this.props.getOfficeManagers( this.state.page,search, this.props.user?.company_id);  
    }
    addUser=(data)=>{
        this.props.changeStateValue({
            name: "userModalData",
            value:{
                position: true,
                id: data?.id?data?.id:"",
                first_name: data?.first_name? data?.first_name:"",
                last_name: data?.last_name? data?.last_name:"",
                email: data?.email? data?.email:"",
                phone_number: data?.phone_number? data?.phone_number:"",
                date_of_birth: data?.date_of_birth? data?.date_of_birth:"",
                password: data?.password? data?.password:"",
                role: "office_manager",
                title: data?.id?"Edit Manager":"Add Manager",
                unRequireds: ["id",  "date_of_birth","password", "unRequireds", "afterCallFunction", "funcParams", "email_verify"],
                afterCallFunction: this.props.getOfficeManagers,
                funcParams: [1, '', this.props.user?.company_id],
                company_id: this.props.user?.company_id,
                url:'users',
                errorMsg: "Email already exists",
                email_verify:data?.email_verify?data?.email_verify:null,
                user_active:data?.user_active?data?.user_active:"0",
            }
        })
    }
    deleteUser=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(alertResp=>{
            if(alertResp === "success"){
                this.props.deleteData(`users/${id}`)
                .then(resp=>{
                    if(resp==="success"){
                        bigAlert("Success!", "Manager Deleted successfully!", "success");
                        this.props.getOfficeManagers(1, '', this.props.user?.company_id);  
                    }else{
                        bigAlert("Error!", "Something went wrong!", "error")
                    }
                })
            }
        })
    }

    lockUnlockBtn=(id,status)=>{
        // console.log(status)
        this.props.insertNewData("change-user-status", {id, status: status==0?'1':'0'})
        .then(resp=>{
            if(resp=="success"){
                this.props.getOfficeManagers( this.state.page, this.state.search, this.props.user?.company_id);  
                mixinAlert("success", "User status changed successfully")
            }else{
                mixinAlert("error", "Something went wrong")
            }
        })
       

    }
  render() {
    const {officeManagers, user} = this.props;
    // console.log(officeManagers)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="managers" />
            <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
                <SectionHeader right={false} onClickAdd={this.addUser.bind(this, "")} onSearchData={(search)=>this.searchManager(search)} lockUnlock={false} calendar={false} btnTitle={"Add a Manager"} />
            </div>
            {officeManagers?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
            {
                officeManagers?.map((data, i)=>{
                    return(
                        <Card4
                        key={i}
                        name={data?.full_name}
                        email={data?.email}
                        phone={data?.phone_number}
                        image={data?.avatar_full_url}
                        role={getRole(data?.role, this.props.lang)}
                        headerBtn={true}
                        lockUnlock={true}
                        deleteBtn={true}
                        onClickEdit={this.addUser.bind(this,data)}
                        onClickDelete={this.deleteUser.bind(this,data?.id)}
                        bonus={`/office-managers/${data?.id}/bonus`}
                        lock={data?.user_active}
                        company={user?.role == "super_admin"? data?.company?.name:''}
                        onClickLockUnlock={user?.role == "super_admin"?this.lockUnlockBtn.bind(this, data?.id):()=>{}}
                        />
                    )
                })
            }
            </div>
            {
                officeManagers?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={officeManagers?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    officeManagers: state.Data.officeManagers,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getOfficeManagers, changeStateValue, deleteData, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(OfficeManagers)
