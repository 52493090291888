import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { getAssesmentsCoach } from '../../actions/CoachAction';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import SkeletonCard from "../../components/cards/SkeletonCard"
import { withHooks } from '../../actions/withHooks';
export class AssessmentCoachPage extends Component {
    state={
        loader: true
    }
    componentDidMount(){
        this.props.getAssesmentsCoach(this.props.params?.id)
        .then(resp=>{
            this.setState({loader:false})
        })
    }
  render() {
    const {loader} = this.state;
    const {assessmentCoach, lang} = this.props;
    // console.log(assessmentCoach)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Parent coach" />
            <div  className='flex flex-col gap-[20px] w-full  max-[1100px]:mt-[120px] max-[800px]:mt-[150px]'>
                <div className='mt-[42px] p-[20px] bg-white shadow rounded-[10px]'>
                    <h2 className='text-[18px] font-[300]'>{lang?.unread_reports}</h2>
                    <div className=' grid-full-320  items-start mt-[10px]'>
                        
                        {
                            assessmentCoach.length ==0?
                            <>
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            </>:
                            assessmentCoach?.filter(x=> x.read_status ==1)
                            .map((data, i) => {
                                return (
                                <div key={i} className='border border-[2px] border-[var(--red)] p-[10px] rounded-[7px] flex flex-col gap-[6px]'>
                                        <Link className='text-[14px] font-semibold text-center' to={`/students/${data?.user?.id}`} target='_blank'>{data?.user?.full_name}</Link>
                                        {
                                            data?.user?.children_parent?.map((item, j)=>{
                                                return(
                                                    <div key={j} className='bg-gray-100 p-[5px] rounded-[6px] flex flex-col'>
                                                        <h3 className='text-[12px] font-semibold'>{item?.first_name+ " "+ item?.last_name}</h3>
                                                        <span className='text-[12px] font-semibold'>{item?.phone_number}</span>
                                                        <span className='text-[12px] font-semibold'>{item?.email}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                        <span className='text-[14px]'>Report date: {moment(data?.created_at)?.format("DD/MM/YYYY")}</span>
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
               <div className='mt-[22px] p-[20px] bg-white shadow rounded-[10px]'>
                    <h2 className='text-[18px] font-[300]'>{lang?.read_reports}</h2>
                    <div className=' grid-full-320 items-start mt-[10px]'>

                        {
                            assessmentCoach.length ==0?
                            <>
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            <SkeletonCard />
                            </>:
                            assessmentCoach?.filter(x=> x.read_status ==0)
                            .map((data, i) => {
                                return (
                                <div key={i} className='border border-[2px] border-green-700 p-[10px] rounded-[7px] flex flex-col gap-[6px]'>
                                        <Link className='text-[14px] font-semibold text-center' to={`/students/${data?.user?.id}`} target='_blank'>{data?.user?.full_name}</Link>
                                        {
                                            data?.user?.children_parent?.map((item, j)=>{
                                                return(
                                                    <div key={j} className='bg-gray-100 p-[5px] rounded-[6px] flex flex-col'>
                                                        <h3 className='text-[12px] font-semibold'>{item?.first_name+ " "+ item?.last_name}</h3>
                                                        <span className='text-[12px] font-semibold'>{item?.phone_number}</span>
                                                        <span className='text-[12px] font-semibold'>{item?.email}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                        <span className='text-[14px]'>Report date: {moment(data?.created_at)?.format("DD/MM/YYYY")}</span>
                                </div>
                                )
                            })
                        }
                    </div>
               </div>
            </div>
            
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    assessmentCoach: state.Data.assessmentCoach, 
    lang: state.Data.lang, 
});
const mapDispatchToProps = {getAssesmentsCoach}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AssessmentCoachPage))
