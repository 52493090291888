import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import { connect } from 'react-redux'
import { getPoolingExamQuestions } from '../../actions/StaffAction'
import Card1 from '../../components/cards/Card1'
import { bigAlert, confirmAlert } from '../../actions/SweetAlerts'
import { deleteData } from '../../actions/MainAction'
import { withHooks } from '../../actions/withHooks'
import HeaderButtons from '../../components/HeaderButtons'
import { resetStateValue } from '../../redux/MainReducer'

export class AdminExamPoolQuestions extends Component {
    componentDidMount(){
        this.props.getPoolingExamQuestions()
    }
    onSearchData=(search)=>{

    }

    onDeleteData=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(alertResp=>{
            if(alertResp === "success"){
                this.props.deleteData(`pooling-exam-questions/${id}`)
                .then(resp=>{
                    if(resp==="success"){
                        bigAlert("Success!", "Question Deleted successfully!", "success");
                        this.props.getPoolingExamQuestions()
                    }else{
                        bigAlert("Error!", "Something went wrong!", "error")
                    }
                })
            }
        })
    }

    openModal=()=>{
        this.props.resetStateValue({name:"poolingExamParentData"})
        this.props.navigate("/exam-pooling-questions/create")
    }
  render() {
    const {poolingExamQuestions, user} = this.props;
    // console.log(poolingExamQuestions)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Pooling exam questions" />
            <div className='flex justify-end mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
             <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Exam", id:0}]} selected={0} />
            </div>
            <div className='flex justify-center '>
                {/* <Search largWidth={true} onSearchData={this.onSearchData} /> */}
            </div>
            <div className='grid-full-250 mt-[20px]'>
            {
                poolingExamQuestions.map((data,i)=>{
                    return(
                        <Card1
                            key={i}
                            title={data?.category?.title}
                            exam={data?.title}
                            close={data?.sub_category?.title}
                            status={1}
                            statusBisible={false}
                            btn={false}
                            url={`/exam-pooling-questions/${data?.id}`}
                            blank={true}
                            // activeStatus={data?.active_status}
                            // adminActiveVisible={user?.role === "super_admin"}
                            // onClickChangeStatus={this.changeStatus.bind(this, data)}
                            adminDelete={user?.role === "super_admin"}
                            onDeleteBtn={this.onDeleteData.bind(this, data?.id)}
                            // user={data?.user?.full_name}
                        />
                    )
                })
            }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    poolingExamQuestions: state.Data.poolingExamQuestions,
    user: state.Data.user,
});
const mapDispatchToProps = {getPoolingExamQuestions, deleteData, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminExamPoolQuestions))
