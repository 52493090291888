import React, { Component } from 'react'
import Header from './Header'
import CheckinTeacherSvg from '../svg/CheckinTeacherSvg'
import CheckInClassSvg from '../svg/CheckInClassSvg'
import CheckInTimeSvg from '../svg/CheckInTimeSvg'
import CheckinCourseSvg from '../svg/CheckinCourseSvg'

export class CheckInModal extends Component {
  closeBackground(e){
    if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
      this.props.closeBtn();
    }
  }
  render() {
    const {data, closeBtn} = this.props;
    return (
      <div onClick={this.closeBackground.bind(this)} className='background_shadow flex items-center justify-center'>
        <div className='bg-white w-[338px] h-[350px] rounded-[10px] ml-[10px] mr-[10px]'>
            <Header closeBtn={closeBtn} title="Class" />
            <ul className='mt-[40px] ml-[24px] flex flex-col gap-[36px]'>
                <li className='flex items-center gap-[10px]'>
                    <CheckinTeacherSvg/>
                    <span className='txt-light-black text-[20px]'>{data?.teacher}</span>
                </li>
                <li className='flex items-center gap-[10px]'>
                    <CheckInClassSvg/>
                    <span className='txt-light-black text-[20px]'>{data?.class}</span>
                </li>
                <li className='flex items-center gap-[16px] pl-[5px]'>
                    <CheckinCourseSvg/>
                    <span className='txt-light-black text-[20px]'>{data?.course_title}</span>
                </li>
                <li className='flex items-center gap-[10px]'>
                    <CheckInTimeSvg/>
                    <span className='txt-light-black text-[20px]'>{data?.time}</span>
                </li>
            </ul>
        </div>
      </div>
    )
  }
}

export default CheckInModal
