import React, { Component } from 'react'

export class EmptySvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={80}
        height={80}
        viewBox="0 0 512 512"
      >
        <path
          d="M256 0C114.625 0 0 114.617 0 256c0 141.382 114.625 256 256 256 141.39 0 256-114.618 256-256C512 114.617 397.39 0 256 0zm77.828 353.218a12.951 12.951 0 0 1-13.266 3.406c-6.844-2.125-10.656-9.406-8.516-16.258 11.172-23.954 19.578-57.875 19.578-57.875-35.266 0-63.86-28.594-63.86-63.859 0-35.274 28.594-63.867 63.86-63.867 35.297 0 63.875 28.594 63.875 63.867.001 29.055-17.358 86.102-61.671 134.586zm-151.265 0c-3.313 3.454-8.422 4.938-13.266 3.406-6.859-2.125-10.672-9.406-8.531-16.258 11.172-23.954 19.578-57.875 19.578-57.875-35.266 0-63.844-28.594-63.844-63.859 0-35.274 28.578-63.867 63.844-63.867 35.297 0 63.89 28.594 63.89 63.867 0 29.055-17.359 86.102-61.671 134.586z"
          style={{
            fill: "var(--blue)",
          }}
        />
      </svg>
    )
  }
}

export default EmptySvg
