import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import PublicCard2 from '../components/cards/PublicCard2'
import { connect } from 'react-redux';
import { getExamResults } from '../../actions/PublicActions';
import { Link } from 'react-router-dom';
import LoadMoreBtn from '../../components/LoadMoreBtn';
export class ExamResults extends Component {
  state={
    results:[]
  }
  componentDidMount(){
    this.props.getExamResults(this.props.page, this.props.type, this.props.examId, this.props.companyId)
    .then(resp=>{this.setState({results:resp})})
  }
  loadMore=(page)=>{
    this.props.getExamResults(page*this.props.page, this.props.type, this.props.examId, this.props.companyId)
    .then(resp=>{this.setState({results:resp})})
  }
  render() {
    const {results} = this.state;
    const {viewMore=false, loadPage} = this.props;
    // console.log(results)
    return (
      <>
        {
          results.length !==0?
          <section>
            <SectionHeader title={`${results[0]?.exam?.title} exam results`} />
            <div className='grid grid-cols-4 container ml-auto mr-auto gap-[30px] max-[1000px]:grid-cols-2  max-[685px]:grid-cols-1  max-[685px]:pl-[20px]  max-[685px]:pr-[20px]'>
                {
                  results?.map((data, i)=>{
                    return(
                      <PublicCard2 key={i} data={data}/>
                    )
                  })
                }
            </div>
            {
                results?.length >=loadPage?
                <LoadMoreBtn onLoadData={this.loadMore} defCount={loadPage} count={results?.length} />:null
            }
            {
              viewMore?
              <div className='flex justify-center mt-[40px] items-center'>
                <Link to={`/exam-results/${this.props.examId}`} className='bg-white w-[120px] h-[35px] text-center pt-[5px] txt-blue border border-[var(--blue)] font-semibold rounded-full'>View All</Link>
              </div>:null
            }
          </section>:null
        }
      </>
       
    )
  }
}
const mapStateToProps = (state)=>({
  
});
const mapDispatchToProps  = {getExamResults}
export default connect(mapStateToProps, mapDispatchToProps)(ExamResults)
