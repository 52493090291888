import React, { Component } from 'react'
import Header from "../../components/header/Header";
import HeaderButtons from "../../components/HeaderButtons"
import Search from "../../components/Search"
import { connect } from 'react-redux';
import { getCourses, deleteData } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1';
import { changeStateValue } from '../../redux/MainReducer';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { getAdminLessonModes } from '../../actions/AdminActions';
export class AdminCourses extends Component {
  state={
    search:'',
    lesson_mode_id:''
  }
  componentDidMount(){
    this.props.getCourses();
    this.props.getAdminLessonModes()
  }
  getLessonModes(data){
    let arr = [];
    for(const item of data){
      arr.push({label: item?.lesson_mode?.title, value:item?.lesson_mode_id})
    }
    return arr;
  }
  addBtn=(data="")=>{
    this.props.changeStateValue({
      name:'adminModalData',
      value: {
          position: true,
          id:data?.id? data?.id:'',
          title:data?.title? data?.title:'',
          level:data?.level? data?.level:'',
          grade:data?.grade? data?.grade:'',
          // lesson_mode_id:data?.lesson_mode_id? data?.lesson_mode_id:'',
          afterCallFunction:this.props.getCourses,
          afterCallParams:[],
          requireds:["title", "level", "grade", ],
          url: data?.id? `courses/${data?.id}`:'courses',
          modalTitle: data?.id? `Edit Course`:'Add Course',
          lesson_mode:data==""?[]: this.getLessonModes(data?.lesson_mode)
      }
  });
  }

  onDeleteBtn=(id)=>{
    confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
    .then(alertResp=>{
        if(alertResp === "success"){
        this.props.deleteData(`courses/${id}`)
        .then(resp=>{
            if(resp == "success"){
                this.props.getCourses()
                mixinAlert("success", "Data deleted successfully")
            }else{
                mixinAlert("error", "Something went wrong")
            }
        })
        }
    })
  }

  searchData=(search)=>{
    this.setState({search})
  }
  selectLessonMode=(id)=>{
    this.setState({lesson_mode_id:id})
    this.props.getCourses(id);
  }
  render() {
    const {studentCourses, adminLessonModes} = this.props;
    const {search, lesson_mode_id} = this.state;
    // console.log(studentCourses)
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
          <Header title="Courses" />
          <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
              <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Add a course", id:'0'}]} selected={0} />
          </div>
          <div className='flex justify-center'>
            <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
          </div>
          <div className='flex flex-wrap gap-[20px] mt-[20px]'>
            {
                adminLessonModes.map((data, i)=>{
                    return(
                        <button onClick={this.selectLessonMode.bind(this, data?.id)} key={i} className={`p-[10px_20px] bg-white shadow rounded-[8px] ${lesson_mode_id === data?.id?"border border-[var(--blue)] border-[2px]":""}`}>
                            {
                                data?.title
                            }
                        </button>
                    )
                })
            }
            </div>
          <div className='mt-[30px] grid-full-250 items-start'>
            {
              studentCourses?.map((data, i)=>{
                if(data.title.toLowerCase().includes(search.toLowerCase())){
                  return (
                    <Card1 
                      key={i}
                      statusBisible={false}
                      status={1}
                      btn={false}
                      title={data?.title}
                      grade={data?.grade}
                      exam={data?.level}
                      btn2={true}
                      btn2Text={"Edit"}
                      btn2Color={"bg-blue"}
                      adminDelete={true}
                      onCLickBtn={this.addBtn.bind(this, data)}
                      onDeleteBtn={this.onDeleteBtn.bind(this, data?.id)}
                      moreOption={this.getLessonModes(data?.lesson_mode)}
                    />
                  )
                }
                
              })
            }
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  studentCourses: state.Data.studentCourses,
  adminLessonModes: state.Data.adminLessonModes,
});
const mapDispatchToProps = {getCourses, changeStateValue, deleteData, getAdminLessonModes}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCourses)
