import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Countdown, {zeroPad} from 'react-countdown';
import Card2Line from './Card2Line';
import { connect } from 'react-redux';

export class Card2 extends Component {
  state={
    btnPermission: true
  }
  stopTimer(){
    this.setState({
      btnPermission:false
    })
  }

  renderer( { hours, minutes, seconds, completed}){
    if(!completed){
      return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    }else{
      if(this.state.btnPermission){
        this.setState({btnPermission:false})
      }
      // 
    }
  }
  render() {
    const {title = '', subTitle = '',bigLine=false, shadow=false, bg='bg-blue', btnTitle=this.props.lang?.start, btnUrl='', download=false, downloadUrl='', downBtnTitle=this.props.lang?.view, status='1', onClickBtn=()=>{}, timer="", minute=0, target="", btn=true} = this.props;
    const {btnPermission} = this.state;
    return (
      <div className={`bg-white rounded-[12px] flex flex-col  justify-between p-[20px] ${shadow? "shadow shadow-lg border border-gray-300":''}`}>
        <ul className='flex flex-col gap-[10px]'>
          {
            !bigLine && title != ''?
            <Card2Line title={title} />:null
          }
          {
            bigLine && title != ''?
            <span className='txt-light-black text-[15px] font-bold'>{title}</span>:null
          }
          
          {
            subTitle != ''?
            <Card2Line title={subTitle} />:null
          }
          {
            timer != ''?
            <li className='flex justify-center'>
              <span className='txt-blue text-[15px] font-bold'>{
                <Countdown date={new Date(timer).getTime() + (minute)*60000} renderer={this.renderer.bind(this)}
                  onComplete={this.stopTimer.bind(this)}>
              </Countdown>
              }</span>
            </li>:null
          }
        </ul>
        {
          btn?
          <div className='mt-[20px] flex justify-center'>
          {
            download?
            <a target='_blank' download={true} href={downloadUrl} className='bg-blue text-white text-[10px] font-semibold pl-[15px] pr-[15px] pt-[3px] pb-[3px] rounded-[3px] h-[25px] inline-flex items-center justify-center'>{downBtnTitle}</a>:
            btnPermission?
            <Link to={btnUrl} onClick={(e)=>onClickBtn(e)} target={target} className={`${status == '1'? bg: 'bg-red'} text-white text-[10px] font-semibold pl-[15px] pr-[15px] pt-[3px] pb-[3px] rounded-[3px] h-[25px] inline-flex items-center justify-center`}>{btnTitle}</Link>:
            <button className={`${'bg-red'} text-white text-[10px] font-semibold pl-[15px] pr-[15px] pt-[3px] pb-[3px] rounded-[3px] h-[25px] inline-flex items-center justify-center`}>{btnTitle}</button>
          }
          
        </div>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(Card2)
