import React, { Component } from 'react'
import UnlockSvg from './svg/UnlockSvg'
import LockSvg from './svg/LockSvg'
import { connect } from 'react-redux';

export class LockUnlockBtns extends Component {
    state={
        active: 'unlock'
    }
    changeBtn(type){
        this.setState({active:type});
        this.props.onSelectType(type)
    }
  render() {
    const {active} = this.state;
    const {lang} = this.props;
    return (
      <div className='flex items-center gap-[23px] ml-[24px]'>
        <button onClick={this.changeBtn.bind(this, "unlock")} className={`flex items-center gap-[7px] font-bold text-[14px] ${active === "unlock"? "txt-blue": "text-gray-400"}`}>
            <UnlockSvg color={active === "unlock"? undefined: "#818181"} />
            <span>{lang?.unlocked}</span>
        </button>
        <button onClick={this.changeBtn.bind(this, "lock")} className={`flex items-center gap-[7px] font-bold text-[14px] ${active === "lock"? "txt-blue": "text-gray-400"}`}>
            <LockSvg color={active === "lock"? "#1E398F": "#818181"} />
            <span>{lang?.locked}</span>
        </button>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(LockUnlockBtns)
