import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux'
import { insertNewData } from '../../actions/MainAction'
import { mixinAlert } from '../../actions/SweetAlerts'
export class PaymentNoteModal extends Component {
    state={
        btnDisabled:false,
        note:''
    }
    saveBtn=(data)=>{
        if(this.state.note.trim() !== ''){
            this.setState({btnDisabled:true})
            let params = {
                ...data,
                note: this.state.note
            }
            this.props.insertNewData("payment-note", params)
            .then(resp=>{
                this.setState({btnDisabled:false})
                mixinAlert("success","Check in note added successfully");
                this.props.closeBtn();
                this.props.getAfterChange();
            })
        }
       
    }
  render() {
    const {closeBtn=()=>{}, data, lang} = this.props;
    const {btnDisabled, note} = this.state;
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[500px] max-sm:w-[90%] p- rounded-[10px]'>
                <Header closeBtn={closeBtn} title="Check-Ins" />
                <div className='p-[20px]'>
                <div className='flex flex-col items-start'>
                    <label htmlFor={"note"} className='text-[14px] txt-light-black'>{lang?.note}</label>
                    <input onChange={(e)=>this.setState({note: e.target.value})} placeholder={"Note"} id={"note"} name={"note"} value={note} type={'text'} className='bg-gray-100 w-full h-[35px] rounded-[6px] p-[5px] outline-none text-[15px]' />
                    {
                        note === ''?
                        <span className='text-[11px] text-red-700'>{lang?.field_required}</span>:null
                    }
                </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={()=>{closeBtn()}} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save}</button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentNoteModal)


