import React, { Component } from 'react'
import CardLine from './CardLine'
import CardUserSvg from '../svg/CardUserSvg'
import CardPhoneSvg from '../svg/CardPhoneSvg'
import CardEmailSvg from '../svg/CardEmailSvg'
import CardExamCheck from '../svg/CardExamCheck'
import CardDateSvg from '../svg/CardDateSvg'
import moment from 'moment'
import CardExamSvg from '../svg/CardExamSvg'
import CardTimeSvg from '../svg/CardTimeSvg'
import { connect } from 'react-redux'
export class Card7 extends Component {

 
  render() {
    const {data, acceptStudent=()=>{}, rejectStudent=()=>{}} = this.props
    return (
      <div className='bg-white p-[20px] rounded-[10px]'>
          <ul className='flex flex-col gap-[10px]'>
            <CardLine svg={<CardUserSvg />} title={data?.student?.full_name} />
            <CardLine svg={<CardEmailSvg />} title={data?.student?.email} />
            <CardLine svg={<CardPhoneSvg />} title={data?.student?.phone_number} />
            <CardLine svg={<CardExamSvg />} title={"Level "+data?.student?.exam?.student_level} />
            <CardLine svg={<CardExamCheck />} title={data?.type=="face_to_face"? "Face to Face": "Online"} />
            <CardLine svg={<CardDateSvg />} title={moment(data?.date).format("DD/MM/YYYY")} />
            <CardLine svg={<CardTimeSvg />} title={data?.status ===0? "Waiting": "Rejected"} />
          </ul>
         
           <div className='mt-[20px] flex justify-between'>
            {
              data?.status ===0?
              <button onClick={()=>rejectStudent(data)} className='bg-red text-[12px] font-semibold text-white pl-[7px] pr-[7px] h-[20px] rounded-[5px]'>Reject</button>
            :null
            }
              <button onClick={()=>acceptStudent(data)} className='bg-blue text-[12px] font-semibold text-white pl-[7px] pr-[7px] h-[20px] rounded-[5px]'>Accept</button>
            </div>
      </div>
    )
  }
}

export default Card7
