import React, { Component } from 'react'
import { getOficeSchedule } from '../../actions/StaffAction';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import Card4 from '../../components/cards/Card4';
import moment from 'moment';
import { getRole } from '../../actions/getRole';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer';

export class OficeExamSchedule extends Component {
    componentDidMount(){
        this.props.getOficeSchedule();
    }
    deleteUser=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`exam-schedule/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getOficeSchedule()
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
    addPdfBtn=(data)=>{
       
        this.props.changeStateValue({
            name:"randevuPdfModal",
            value:{
                position:true,
                name:data?.user?.full_name,
                center:data?.user?.company?.name,
                date:moment(data?.date).format("DD MMMM, YYYY"),
                time:data?.time
            }
        })
    }
  render() {
    const {oficeExamSchedule} = this.props;
    // console.log(oficeExamSchedule)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="appointments" />
            <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
                <div className='mt-[42px] grid-full-250'>
                {
                    oficeExamSchedule?.map((data, i)=>{
                        return(
                            <Card4
                                key={i}
                                name={data?.user?.full_name}
                                email={data?.user?.email}
                                phone={data?.user?.phone_number}
                                image={data?.user?.avatar_full_url}
                                role={getRole(data?.user?.role, this.props.lang)}
                                date={moment(new Date(data?.date)).format("DD MMMM")+", "+data?.time}
                                course={data?.course?.title}
                                time={data?.time}
                                headerBtn={true}
                                pdf={true}
                                addPdfBtn={this.addPdfBtn.bind(this, data)}
                                lockUnlock={false}
                                lesson={data?.exams?.title?data?.exams?.title:" "}
                                // deleteUserBtn={true}
                                // onClickDelete={this.deleteUser.bind(this,data?.id)}
                                info={`/students/${data?.user?.id}?select_course`}
                                editLink={`/students/${data?.user?.id}/edit`}
                        />
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    oficeExamSchedule: state.Data.oficeExamSchedule
});
const mapDispatchToProps = {getOficeSchedule, deleteData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(OficeExamSchedule)
