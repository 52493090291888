import React, { Component } from 'react'
import Header from './Header';
import { connect } from 'react-redux';

export class EnrollCheckInModal extends Component {
    closeModal=()=>{
        this.props.closeModalBtn()
    }
    closeModalShadow(e){
        if(typeof e.target.className == 'string' && e.target.className?.includes("background_shadow")) this.closeModal();
    }
  render() {
    const {data, lang} = this.props;
    return (
        <div onClick={this.closeModalShadow.bind(this)} className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[400px]  shadow bg-white rounded-[10px] pb-[20px]'>
                <Header closeBtn={this.closeModal.bind(this)} title={data?.name} />
                <h2 className='p-[20px] pt-[10px] pb-[10px] text-[20px] txt-blue font-semibold'>
                    {lang?.popup_checkin}
                </h2>
                <ul className='p-[0px] pt-[0px] pb-[0px]'>
                    <li className='flex justify-between'>
                        <span className='w-1/3 text-center text-[18px] font-semibold  border-l border-t p-[4px]'>{lang?.hour}</span>
                        <span className='w-1/3 text-center text-[18px] font-semibold  border-l border-t p-[4px]'>{lang?.lesson_mode}</span>
                        <span className='w-1/3 text-center text-[18px] font-semibold  border-l border-t p-[4px]'>{lang?.date}</span>
                    </li>
                </ul>
                <ul className='p-[0px] pt-[0px] max-h-[60vh] overflow-auto'>
                    {
                        data?.checkins?.map((item, i)=>{
                            return(
                                <li key={i} className='flex justify-between'>
                                    <span className='w-1/3 text-center text-[15px] border-l border-t p-[4px] font-[300]'>{+item?.lesson_houre/60} hrs</span>
                                    <span className='w-1/3 text-center text-[15px] border-l border-t p-[4px] font-[300]'>{item?.lesson_mode}</span>
                                    <span className='w-1/3 text-center text-[15px] border-l border-t p-[4px] font-[300]'>{item?.date}</span>
                                </li>
                            )
                        })

                    }
                </ul>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
})
export default connect(mapStateToProps)(EnrollCheckInModal)
