import React, { Component } from 'react'
import ErrorSvg from './svg/ErrorSvg'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
export class NotAllowdLogin extends Component {
    closeData=()=>{
        this.props.changeStateValue({
            name:'notAllowData',
            value:{
                position:false,
                type: ''
            }
        })
    }
  render() {
    const {notAllowData} = this.props;
    const teacher = 'Your account is locked by CELT Colleges for routine control. Please ask your manager or assistman to unlock it for you.';
    const student = "Your account is locked by CELT Colleges Headquarter. Please complete your payment in your CELT Center to unlock the system."
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white p-[20px] rounded-[12px] shadow max-w-[400px] flex flex-col items-center gap-[10px]'>
                <ErrorSvg />
                <h1 className='text-gray-500 font-semibold text-[26px]'>Not allowed to login</h1>
                <p className='text-center'>{notAllowData?.type === "student"? student:teacher}</p>
                <Link onClick={this.closeData.bind(this)} to="/login" className='bg-blue text-[14px] rounded-[6px] h-[25px] w-[60px] text-white font-semibold text-center pt-[2px]'>OK</Link>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    notAllowData: state.Data.notAllowData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(NotAllowdLogin)
