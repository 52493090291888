import React, { Component } from 'react'

export class ParentsSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={18}
        height={18}
        viewBox="0 0 512 512"
        fill='#585874'
      >
        <path d="M153.6 324.267a8.53 8.53 0 0 0 8.533-8.533v-62.106c5.077-2.961 8.533-8.405 8.533-14.694 0-9.412-7.654-17.067-17.067-17.067-9.412 0-17.067 7.654-17.067 17.067 0 6.289 3.456 11.733 8.533 14.694v62.106a8.534 8.534 0 0 0 8.535 8.533zM85.658 134.426c1.63 16.401 8.764 30.839 9.105 31.522 7.74 15.471 19.43 38.852 58.837 38.852 39.407 0 51.098-23.381 58.837-38.852.341-.683 7.475-15.121 9.105-31.522 5.274-2.901 8.858-8.525 8.858-14.959V102.4c0-6.306-3.43-11.819-8.533-14.771v-2.295c0-28.237-22.963-51.2-51.2-51.2h-34.133c-28.237 0-51.2 22.963-51.2 51.2v2.295C80.23 90.581 76.8 96.094 76.8 102.4v17.067c0 6.434 3.584 12.057 8.858 14.959zm8.209-32.026a8.53 8.53 0 0 0 8.533-8.533v-8.533c0-18.825 15.309-34.133 34.133-34.133h34.133c18.825 0 34.133 15.309 34.133 34.133v8.533a8.53 8.53 0 0 0 8.533 8.533v17.067a8.53 8.53 0 0 0-8.533 8.533c0 14.763-7.56 30.174-7.637 30.319-8.883 17.766-16.717 29.414-43.563 29.414-26.846 0-34.68-11.648-43.554-29.406-.085-.154-7.646-15.565-7.646-30.327a8.53 8.53 0 0 0-8.533-8.533V102.4z" />
        <path d="M170.667 119.467h8.533a8.53 8.53 0 0 0 8.533-8.533 8.53 8.53 0 0 0-8.533-8.533h-8.533a8.53 8.53 0 0 0-8.533 8.533 8.53 8.53 0 0 0 8.533 8.533zM128 119.467h8.533a8.53 8.53 0 0 0 8.533-8.533 8.53 8.53 0 0 0-8.533-8.533H128a8.53 8.53 0 0 0-8.533 8.533 8.53 8.53 0 0 0 8.533 8.533zM59.733 324.267A8.53 8.53 0 0 0 51.2 332.8v136.533c0 4.71 3.814 8.533 8.533 8.533s8.533-3.823 8.533-8.533V332.8a8.53 8.53 0 0 0-8.533-8.533zM452.83 324.267h-.034c-4.693 0-8.516 3.797-8.533 8.499l-.529 136.533c-.017 4.71 3.78 8.55 8.499 8.568h.034a8.529 8.529 0 0 0 8.533-8.499l.529-136.533c.017-4.711-3.78-8.551-8.499-8.568zM375.023 108.237c-1.152-3.49-4.412-5.837-8.09-5.837s-6.929 2.347-8.09 5.837c-6.417 19.243-25.301 19.755-26.044 19.763a8.53 8.53 0 0 0-8.533 8.533v34.133c0 28.501 19.473 68.267 51.2 68.267s51.2-39.765 51.2-68.267v-34.133a8.53 8.53 0 0 0-8.533-8.533c-.367 0-36.693-.538-43.11-19.763zm34.577 62.43c0 23.194-15.224 51.2-34.133 51.2s-34.133-28.006-34.133-51.2v-26.641c8.354-1.894 18.671-6.656 26.249-16.35 11.955 11.955 31.019 15.693 42.018 16.862v26.129z" />
        <path d="M491.076 264.542c-16.725-16.691-41.847-25.506-72.687-25.506-4.779.094-8.764 3.729-8.789 8.508-.009 1.741-.597 42.59-34.133 42.59s-34.125-40.841-34.133-42.581c-.009-4.702-3.823-8.508-8.533-8.508a8.525 8.525 0 0 0-8.533 8.533c0 19.081 9.216 53.837 42.667 58.931v43.358c0 4.71 3.814 8.533 8.533 8.533s8.533-3.823 8.533-8.533V306.5c28.8-4.395 39.646-30.746 42.095-50.185 22.63 1.271 40.815 8.218 52.924 20.309 10.266 10.249 15.915 24.132 15.915 39.108v136.533a8.53 8.53 0 0 0 8.533 8.533 8.53 8.53 0 0 0 8.533-8.533V315.733c-.001-19.541-7.433-37.717-20.925-51.191zM247.467 324.267a8.53 8.53 0 0 0-8.533 8.533v136.533c0 4.71 3.814 8.533 8.533 8.533s8.533-3.823 8.533-8.533V332.8a8.53 8.53 0 0 0-8.533-8.533zM366.933 68.267H384c22.46 0 49.749 23.057 51.226 43.324l8.533 110.933c.341 4.471 4.079 7.876 8.499 7.876.213 0 .444-.008.666-.026 4.693-.358 8.209-4.463 7.851-9.165l-8.533-110.899C449.963 79.036 412.186 51.2 384 51.2h-17.067c-31.061 0-65.493 29.747-68.241 59.076l-8.533 110.933c-.358 4.702 3.157 8.806 7.851 9.165 4.787.307 8.806-3.157 9.165-7.851l8.525-110.797c1.902-20.308 29.192-43.459 51.233-43.459z" />
        <path d="M196.821 221.978c-4.224-.068-8.055 2.645-8.909 6.793L153.6 393.404 119.287 228.77c-.862-4.147-4.821-6.784-8.926-6.793-44.826 0-79.121 9.6-96.606 27.042C4.634 258.133 0 269.099 0 281.6v170.667c0 4.71 3.814 8.533 8.533 8.533s8.533-3.823 8.533-8.533V281.6c0-7.919 2.867-14.626 8.747-20.506 13.295-13.269 41.574-21.163 78.191-21.982l41.225 197.709c0 .017.009.034.009.051l.008.068c.06.265.239.469.316.725.247.802.512 1.596.981 2.287.273.418.683.708 1.024 1.067.427.427.785.913 1.289 1.254.563.375 1.22.555 1.86.785.393.145.717.41 1.143.495h.06c.538.111 1.126.009 1.69.009.563 0 1.152.102 1.698-.009h.034c.282-.06.495-.239.759-.324.794-.247 1.587-.512 2.27-.973.427-.29.725-.708 1.092-1.067.41-.401.879-.751 1.203-1.237.461-.674.708-1.459.956-2.244.085-.265.273-.486.333-.768l.008-.068c0-.017.009-.034.009-.051l41.225-197.709c36.625.819 64.896 8.721 78.191 21.982 5.88 5.879 8.747 12.587 8.747 20.506v170.667c0 4.71 3.814 8.533 8.533 8.533s8.533-3.823 8.533-8.533V281.6c0-12.501-4.634-23.467-13.756-32.58-17.485-17.451-51.78-27.042-96.623-27.042z" />
      </svg>
    )
  }
}

export default ParentsSvg
