import React, { Component } from 'react'

export class Audio extends Component {
  render() {
    const {url} = this.props;
    return (
      <div className='mt-[20px]'>
        <audio src={url} className='audio_player' controls />
      </div>
    )
  }
}

export default Audio
