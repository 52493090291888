import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getLoginLogs } from '../../actions/StaffAction';
import { getRole } from '../../actions/getRole';
import Card4 from '../../components/cards/Card4';
import { bigAlert } from '../../actions/SweetAlerts';
import Search from '../../components/Search';
import moment from 'moment';

export class LoginLogs extends Component {
    state={
        search:''
    }
    componentDidMount(){
        this.props.getLoginLogs()
    }
    searchData=(search)=>{
        this.setState({search:search})
    }
  render() {
    const {loginLogs} = this.props;
    const {search} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
        <Header title="Login logs" />
            <div className='mt-[30px] flex justify-center items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
            </div>
            <div className='mt-[42px] grid-full-250'>
            {
                loginLogs?.map((data, i)=>{
                    if(data?.user?.email?.toLowerCase().includes(search?.toLowerCase()) || data?.user?.full_name?.toLowerCase().includes(search?.toLowerCase()) || data?.user?.company?.name?.toLowerCase().includes(search?.toLowerCase())){
                        return(
                            <Card4
                                key={i}
                                name={data?.user?.full_name}
                                email={data?.user?.email}
                                phone={data?.user?.phone_number}
                                image={data?.user?.avatar_full_url}
                                role={getRole(data?.user?.role)}
                                editBtnPermisiion={false}
                                more={true}
                                date={moment(data?.created_at).format("DD/MM, hh:mm")}
                                onClickMore={()=>bigAlert("", data?.info)}
                                // headerBtn={true}
                                // lockUnlock={false}
                                // deleteBtn={true}
                                // onClickEdit={this.addBtn.bind(this,data?.user)}
                                // onClickDelete={this.deleteUser.bind(this,data?.user?.id)}
                            />
                        )
                    }
                    
                })
            }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    loginLogs: state.Data.loginLogs
});
const mapDispatchToProps = {getLoginLogs}
export default connect(mapStateToProps, mapDispatchToProps)(LoginLogs)
