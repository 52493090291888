import React, { Component } from 'react'
import MsgGroupSvg from '../svg/MsgGroupSvg'
import { connect } from 'react-redux'
import { getMsgGroupUsers, getGroupsUsers, getPinneds } from '../../actions/MessageAction'
import { getRole } from '../../actions/getRole'
import GroupUserAddSvg from '../svg/GroupUserAddSvg'
import MsgCreateGroupModal from '../modals/MsgCreateGroupModal'
import BackSvg from '../svg/BackSvg';
import { changeStateValue } from '../../redux/MainReducer'
import PinSvg from '../svg/PinSvg'
import MessagePinnedModal from '../modals/MessagePinnedModal'
export class BoxHeader extends Component {
    state={
        groupUsers:[],
        groupModal:{
            position:false
        },
        pinnedMessages:[]
    }
    getUsersBtn=(data)=>{
        if(data?.type !== "group"){
            this.props.getMsgGroupUsers(data.teacher_id, data.student_id, data.student.company_id)
            .then(resp=>{
                let arr = []
                arr.push({name: resp?.student?.full_name, role: resp?.student?.role});
                arr.push({name: resp?.teacher?.full_name, role: resp?.teacher?.role});
                arr.push({name: resp?.teacher?.full_name, role: resp?.teacher?.role});
                for(const item of resp?.parent){
                    arr.push({  name: item?.first_name+" "+item?.last_name, role: "parent" })
                }
                for(const item of resp?.company_head){
                    arr.push({  name: item?.full_name, role: item?.role })
                }
                for(const item of resp?.office_managers){
                    arr.push({  name: item?.full_name, role: item?.role })
                }
                this.setState({groupUsers: arr})
            })
        }else{
            this.props.getGroupsUsers(data?.id)
            .then(resp=>{
                let arr = [];
                for(const item of resp){
                    arr.push({
                        name: item?.full_name,
                        role: item?.role
                    })
                }
                this.setState({groupUsers: arr})
            })
        }
    }
    getPinnedMessages=(data)=>{
        this.props.getPinneds(data?.id, data?.type === "group"? "group":"chat")
        .then(resp=>{
            this.setState({pinnedMessages: resp})
        })
    }
  render() {
    const {data, visible, user, lang, msgBoxInfo} = this.props;
    const {groupUsers, groupModal, pinnedMessages} = this.state;
 
    return (
        <div className='flex items-center justify-between h-[65px]'>
            <div>
                <h3 className='text-[20px] font-bold txt-light-black'>{data?.student?.full_name}</h3>
                <div className='flex items-center gap-[10px] mt-[10px] max-[650px]:flex-col  max-[650px]:gap-[0px]  max-[650px]:items-start max-[650px]:mt-[0px]'>
                    {
                        data?.teacher?
                        <div className='flex items-center gap-[2px]'>
                            <span className='text-[#64748B] text-[14px] font-bold'>{lang?.teacher} :</span>
                            <span className='text-[#64748B] text-[14px] font-regular'>{data?.teacher?.full_name}</span>
                        </div> :null
                    }
                    
                    <div className='flex items-center gap-[2px]'>
                        <span className='text-[#64748B] text-[14px] font-bold'>{lang?.class} :</span>
                        <span className='text-[#64748B] text-[14px] font-regular'>{data?.lesson_mode}</span>
                    </div> 
                
                </div>
            </div>
            {groupUsers.length ? <div onClick={()=>this.setState({groupUsers:[]})} className='absolute z-20 w-full h-[100vh] top-0 left-0 z-20'></div>:null}
           <div className='flex items-center gap-[20px] max-[500px]:gap-[10px]'>
              <div className='flex items-center justify-between  gap-[20px] max-[800px]:flex-col max-[800px]:gap-[0px] max-[500px]:gap-[10px]'>
                <button onClick={this.getPinnedMessages.bind(this, msgBoxInfo)} className='bg-[#F8FAFC] max-[500px]:w-[30px] max-[500px]:h-[25px] max-[500px]:min-h-[25px] w-[58px] min-h-[44px] h-[44px] mt-[-5px] inline-flex items-center justify-center rounded-[10px]'>
                    <PinSvg color="var(--blue)" size={20} />
                </button>
                
                {
                    data?.type === "group"  &&user.role !== 'student' && user.role !== 'parent'?
                    <button onClick={()=>this.setState({groupModal:{position:true}})}  className='bg-[#F8FAFC] max-[500px]:w-[30px] max-[500px]:h-[25px] max-[500px]:min-h-[25px] w-[58px] min-h-[44px] h-[44px] mt-[-5px] inline-flex items-center justify-center rounded-[10px]'>
                        <GroupUserAddSvg />
                    </button>:null
                }
              </div>
                
                <div className='flex items-center justify-between  gap-[20px] max-[800px]:flex-col max-[800px]:gap-[0px] max-[500px]:gap-[10px]'>
                    <div className='relative'>
                        <button className='bg-[#F8FAFC] max-[500px]:w-[30px] max-[500px]:h-[25px] w-[58px] h-[44px] mt-[-5px] inline-flex items-center justify-center rounded-[10px]' onClick={this.getUsersBtn.bind(this, data)}>
                            <MsgGroupSvg />
                        </button>
                        {
                            groupUsers.length !== 0?
                            <div className='absolute z-50 w-[240px] max-h-[300px] overflow-auto p-[10px] shadow rounded-[7px] bg-white right-0'>
                                <ul className='flex gap-[10px] flex-col'>
                                    {
                                        groupUsers.map((item, i)=>{
                                            return(
                                                <li className='bg-gray-100 p-[10px] rounded-[6px]' key={i}>
                                                    <h4 className='text-[15px] font-semibold txt-blue'>{item?.name}</h4>
                                                    <span className='text-[13px] font-regular '>{getRole(item?.role, lang)}</span>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>:null
                        }
                    </div>
                    {
                        !visible?
                        <button onClick={()=>this.props.changeStateValue({name:"msgBoxInfo", value:""})} className='bg-[#F8FAFC] max-[500px]:w-[30px] max-[500px]:h-[25px] w-[58px] h-[44px] mt-[-5px] inline-flex items-center justify-center rounded-[10px]'>
                            <BackSvg />
                        </button>:null
                    }
                </div>
           </div>
           {groupModal?.position ?<MsgCreateGroupModal name={data?.group_name} id={data?.id} type="edit" onClose={()=>this.setState({groupModal: {position:false}})} />:null}
            {pinnedMessages.length !==0?  <MessagePinnedModal messages={pinnedMessages} closeModalBtn={()=>this.setState({pinnedMessages:[]})} />:null}
        </div>
    )
  }
}

const mapStateToProps = (state) =>({
    msgGroupUsers: state.Data.msgGroupUsers,
    user: state.Data.user,
    lang: state.Data.lang,
    msgBoxInfo: state.Data.msgBoxInfo,
});
const mapDispatchToProps = {getMsgGroupUsers, getGroupsUsers, changeStateValue, getPinneds}
export default connect(mapStateToProps, mapDispatchToProps)(BoxHeader)
