import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux'
import {insertNewData} from "../../actions/MainAction"
import { mixinAlert } from '../../actions/SweetAlerts'
import { getChatUsers } from '../../actions/MessageAction'
export class SendAllModal extends Component {
    state={
        btnDisabled:false,
        inpValue:''
    }
    closeModal=()=>{
        this.props.closeModal()
    }
    saveBtn=(data)=>{
        if(data.trim() !== ""){
            this.setState({btnDisabled:true});
            this.props.insertNewData("send-all-message",{message:data})
            .then(resp=>{
                this.setState({btnDisabled:false});
                if(resp=== 'success'){
                    mixinAlert("success", "Message sent successfully");
                    this.props.getChatUsers();
                    this.closeModal()
                }else{
                    mixinAlert("error", "Something went wrong");
                }
            })
        }
    }
  render() {
    const {btnDisabled, inpValue} = this.state;
    const {lang} = this.props;
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white w-[800px]  shadow bg-white rounded-[10px] '>
            <Header closeBtn={this.closeModal.bind(this)} title={'Send message for all'} />
            <div className='bg-white p-[20px] rounded-b-[10px] flex flex-col gap-[10px] max-h-[60vh] overflow-auto'>
                <textarea value={inpValue} onChange={(e)=>this.setState({inpValue:e.target.value})} placeholder='Write a message' className='border p-[7px] text-[14px]  rounded-[10px] outline-none h-[200px]' />
                <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,inpValue)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save}</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang
});
const mapDispatchToProps = {insertNewData, getChatUsers}
export default connect(mapStateToProps, mapDispatchToProps)(SendAllModal)
