import React, { Component } from 'react'
import { changeStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';
import { get2FAAuthSecret, insertNewDataFA } from '../actions/TwoFAActions';
import { withHooks } from '../actions/withHooks';
import { checkUser, insertDataWithParams } from '../actions/MainAction';
import { mixinAlert } from '../actions/SweetAlerts';

export class Verify2FA extends Component {
    state={
        otp:'',
        secret:'',
        btnDisable: false
    }
    componentDidMount(){
       
        this.props.get2FAAuthSecret()
        .then(resp=>{
            if(resp ===""){
                this.props.navigate("/")
            }else{
                this.setState({
                    secret: resp?.data?.secret
                })
                this.props.changeStateValue({
                    name:"loader",
                    value:false
                })
            }
        });
       
    }
    verifyOTP=(secret,otp)=>{
        if(!localStorage.getItem("FAToken")){
            this.props.navigate("/");
        }
        if(secret !="" && otp?.trim() !==""){
            this.setState({btnDisable:true})
            this.props.insertNewDataFA('verify-2fa', {token: otp, secret})
            .then(resp=>{
                this.setState({btnDisable:false})
                if(resp?.success){
                    localStorage.setItem("userToken", atob(localStorage.getItem("FAToken")))
                    this.props.checkUser()
                    .then(respUser=>{
                        if(respUser === "success"){
                            mixinAlert("success", "2FA verified successfully")
                            this.props.navigate("/")
                        }
                        // console.log(respUser)
                       
                    })
                }else{
                    mixinAlert("error", "Invalid OTP")
                }
            })
        }
    }
  render() {
    const {otp, secret, btnDisable} = this.state;
    // console.log(secret)
    // console.log(atob("c3Nz"))
    return (
        <div className='w-full pl-[27px] pr-[27px] pt-[100px]'>
            <div className='h-screen flex justify-center items-center'>
                <div className='bg-gray-100 p-[10px] w-[500px] rounded-[10px] border '>
                    <h3 className='text-center text-[22px] font-[600] txt-blue'>Enter 2FA Code</h3>
                    <div className='flex flex-col mt-[30px]'>
                        <label  className='text-[14px] font-[500]'>Enter 6 digit Auth code to verify</label>
                        <input value={otp} onChange={(e)=>this.setState({otp: e.target.value})} className='h-[40px] rounded-[6px] outline-none pl-[5px]' placeholder='6 digit Auth Code' type="number" />
                    </div>
                    <div className='mt-[20px] flex justify-center'>
                        <button disabled={btnDisable} onClick={this.verifyOTP.bind(this, secret,otp)} className='bg-blue p-[5px_10px] text-white text-[14px] font-[500] rounded-[6px]'>
                            Verify
                        </button>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue, get2FAAuthSecret, insertNewDataFA, checkUser}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Verify2FA))
