import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import { connect } from 'react-redux';
import { getAdminLessonModes } from '../../actions/AdminActions';
import Card1 from '../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer';
export class AdminLessonModes extends Component {
    componentDidMount(){
        this.props.getAdminLessonModes()
    }
    addBtn=(data="")=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                title:data?.title? data?.title:'',
                price:data?.price? data?.price:'0',
                senior_price:data?.senior_price? data?.senior_price:'0',
                row:data?.row? data?.row:this.props.adminLessonModes.length+1,
                afterCallFunction:this.props.getAdminLessonModes,
                afterCallParams:[],
                requireds:["price", "title","row","senior_price"],
                url: data?.id? `lesson-mode/${data?.id}`:'lesson-mode',
                modalTitle: data?.id? `Edit Lesson mode`:'Add Lesson mode',
            }
        });
    }
    onDeleteBtn=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`lesson-mode/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getAdminLessonModes()
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {adminLessonModes} = this.props;
    // console.log(adminLessonModes)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Lesson Modes" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Add a Lesson mode", id:'0'}]} selected={0} />
            </div>
            
            <div className='mt-[30px] grid-full-250'>
            {
              adminLessonModes?.map((data, i)=>{
                return (
                    <Card1
                      key={i}
                      statusBisible={false}
                      status={1}
                      btn={false}
                      title={data?.title}
                      exam={`${data?.price} AZN`}
                      close={data?.row}
                      btn2={true}
                      btn2Text={"Edit"}
                      btn2Color={"bg-blue"}
                      adminDelete={true}
                      onCLickBtn={this.addBtn.bind(this, data)}
                      onDeleteBtn={this.onDeleteBtn.bind(this, data?.id)}
                    />
                  )
              })
            }
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminLessonModes: state.Data.adminLessonModes
});
const mapDispatchToProps = {getAdminLessonModes, deleteData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminLessonModes)
