import React, { Component } from 'react'
import Header from './Header';

export class ModelAnswer extends Component {
    closeModal=()=>{
        this.props.closeModalBtn()
    }
    closeModalShadow(e){
        if(typeof e.target.className == 'string' && e.target.className?.includes("background_shadow")) this.closeModal();
    }
  render() {
    const {data} = this.props;
    return (
        <div onClick={this.closeModalShadow.bind(this)} className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[600px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={'Model Essay'} />
                <div className='p-[20px] max-h-[70vh] overflow-auto'>
                   <p>{data}</p>
                </div>
            </div>
        </div>
    )
  }
}

export default ModelAnswer
