import React, { Component } from 'react'

export class ContractPdfSvg extends Component {
  render() {
    const {size=40} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 52 52"
        fill="var(--blue)"
      >
        <path d="M28.6 11.4h5.1a1.11 1.11 0 0 0 1.1-1.1 1.32 1.32 0 0 0-.3-.8l-7.2-7.1a.89.89 0 0 0-.7-.3 1.11 1.11 0 0 0-1.1 1.1v5.1a3.12 3.12 0 0 0 3.1 3.1zM49.83 32.45A1.43 1.43 0 0 0 48.44 31h-12A1.43 1.43 0 0 0 35 32.44v1.44a1.43 1.43 0 0 0 1.4 1.44h6.14l-11 11a1.42 1.42 0 0 0 0 2l1 1a1.54 1.54 0 0 0 1.09.45 1.32 1.32 0 0 0 .94-.38l11-11v6A1.43 1.43 0 0 0 47 45.83h1.53a1.43 1.43 0 0 0 1.47-1.4zM18.35 20.68h-1.46v4.93h1.46a1.56 1.56 0 0 0 1.56-1.1 3.93 3.93 0 0 0 .24-1.44 3.61 3.61 0 0 0-.36-1.77 1.53 1.53 0 0 0-1.44-.62zM11.29 20.93a1.27 1.27 0 0 0-.84-.25H9v2.23h1.46a1.18 1.18 0 0 0 .84-.28 1.09 1.09 0 0 0 .3-.86 1 1 0 0 0-.33-.84z" />
        <path d="M33.24 16H25.4a4.67 4.67 0 0 1-4.7-4.6V3.6a1.42 1.42 0 0 0-1.34-1.5H6.8a4.67 4.67 0 0 0-4.7 4.6v29.4a4.6 4.6 0 0 0 4.62 4.6H24.6v-.1a15.92 15.92 0 0 1 10.2-14.9v-8.1a1.58 1.58 0 0 0-1.56-1.6zm-20.75 7.65a2.93 2.93 0 0 1-1.89.56H9v2.71H7.49v-7.55h3.24a2.64 2.64 0 0 1 1.76.63 2.3 2.3 0 0 1 .66 1.8 2.35 2.35 0 0 1-.66 1.85zm8.66 1.9A2.73 2.73 0 0 1 18.62 27h-3.26v-7.63h3.26a3.85 3.85 0 0 1 1.17.17 2.49 2.49 0 0 1 1.28.95 3.43 3.43 0 0 1 .54 1.22 5.6 5.6 0 0 1 .14 1.29 5 5 0 0 1-.6 2.55zm7.92-4.84h-3.81v1.74h3.34v1.31h-3.34v3.16h-1.57v-7.54h5.38z" />
      </svg>
    )
  }
}

export default ContractPdfSvg
