import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getIALExams } from '../../actions/MainAction';
import Card2 from '../../components/cards/Card2';
import EmptyPage from '../../components/EmptyPage';
export class IALExams extends Component {
    state={
        page:1,
        search:''
    }
    componentDidMount(){
        this.props.getIALExams(1, '');
        window.scrollTo(0,0)
    }
    
  render() {
    const {ialExams} = this.props;
   
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="ial_exams" />
            {ialExams?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
           
            <div className='mt-[42px] grid-full-205 max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {
                    ialExams?.map((data, i) => {
                        return(
                            <Card2 
                                key={i}
                                title={data?.course?.title}
                                subTitle={data?.title}
                                btnUrl={`/ial-exams/${data?.id}`}
                            />
                        )
                    })
                }
            </div>
           
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    ialExams: state.Data.ialExams
});
const mapDispatchToProps = {getIALExams}
export default connect(mapStateToProps, mapDispatchToProps)(IALExams)
