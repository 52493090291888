import React, { Component } from 'react'

export class DefaulData extends Component {
  render() {
    const { value="", title=""} = this.props;
    return (
        <div className='flex flex-col items-start'>
            <label className='text-[14px] txt-light-black'>{title}</label>
            <div className='bg-gray-100 w-full text-gray-500 pl-[10px] h-[35px] border-red-600 border-[1.5px] rounded-[6px] p-[5px] outline-none text-[15px] cursor-not-allowed'>
                <span>{value}</span>
            </div>
        </div>
    )
  }
}

export default DefaulData
