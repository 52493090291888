import React, { Component } from 'react'
import QuestionDescription from './QuestionDescription';
import Audio from './Audio';
import Video from './Video';
import Image from './Image';
import { mixinAlert } from '../../actions/SweetAlerts';
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
export class MultiChoice extends Component {
    
    selectAnswer(parentIndex, index,data, item){
        let selected = [...data?.given_answer];
        const findIndex = selected.findIndex(s=>s.id === item.id);
        if(findIndex !== -1){
            selected.splice(findIndex, 1);
        }else{
            if(selected.length ===2){
                selected.splice(0, 1);
                selected.push(item)
            }else{
                selected.push(item)
            }
            
        }
        this.props.changeStateValue({
            name: `examsData.${parentIndex}.student_exam_questions.${index}.given_answer`,
            value: selected
        })
    }
  render() {
    const {data, parentIndex, index} = this.props;
    return (
        <div className='mt-[40px]'>
            <h4 className='txt-blue font-bold text-[18px]'>{data?.title}</h4>
            {
            data?.description?
            <QuestionDescription text={data?.description} />:null
            }
            {
            data?.audio_full_url?
            <Audio url={ data?.audio_full_url} />:null
            }
            
            {
            data?.video_full_url?
            <Video url={data?.video_full_url} />:null
            }
            {
            data?.image_full_url?
            <Image url={data?.image_full_url} />:null
            }
           
            <ul className='mt-[20px] flex flex-col gap-[10px] '>
                {
                    data?.student_exam_question_answers?.map((item, i)=>{
                        return(
                            <li key={i} className='flex items-center gap-[10px]'>
                                <input checked={data?.given_answer?.find(s=> s.id === item.id)?true:false} onChange={this.selectAnswer.bind(this, parentIndex, index, data, item)} id={item?.id} type='checkbox' />
                                <label className='txt-light-black text-[14px] font-bold  w-full' htmlFor={item?.id}>{item?.title}</label>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MultiChoice)
