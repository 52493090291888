import React, { Component } from 'react'

export class TodoSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22}
        height={22}
        fill="none"
      >
        <rect width={22} height={22} fill="#E5F0FF" rx={3} />
        <path
          fill="#1E398F"
          d="M11 13.5c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25Zm0-3.75c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25ZM11 6c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.563-1.25-1.25C9.75 6.562 10.313 6 11 6Zm-3.75 7.5c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25C6.562 16 6 15.437 6 14.75c0-.688.563-1.25 1.25-1.25Zm0-3.75c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25C6.562 12.25 6 11.687 6 11c0-.688.563-1.25 1.25-1.25Zm0-3.75c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25C6.562 8.5 6 7.937 6 7.25 6 6.562 6.563 6 7.25 6Zm7.5 7.5c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25Zm0-3.75c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25Zm0-3.75c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25Z"
        />
      </svg>
    )
  }
}

export default TodoSvg
