import React, { Component } from 'react'

export class CardChildSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={10}
        height={10}
        fill="none"
      >
        <path
          fill="#1E398F"
          fillRule="evenodd"
          d="M.75.75h9.042v1.458h-.584v-.875H1.333v6.709h6.238v.583H.75V.75ZM9.5 4.542a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75Zm.592.586c.38 0 .68.17.875.433.184.247.261.552.279.833.017.289-.022.578-.116.852-.087.253-.235.516-.463.696v2.87a.437.437 0 0 1-.87.072l-.376-2.259h-.087l-.425 2.268a.438.438 0 0 1-.867-.08V6.65l-.173.268-.023.036-.006.01-.001.002a.438.438 0 0 1-.372.206H6.01a.438.438 0 0 1 0-.875h1.218c.071-.11.165-.252.263-.395.102-.149.214-.306.315-.43.048-.06.103-.123.158-.175a.751.751 0 0 1 .11-.088.502.502 0 0 1 .27-.082h1.75Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default CardChildSvg
