import React, { Component } from 'react'
import SearchSvg from '../svg/SearchSvg'

export class Search extends Component {
  render() {
    const {value, onSubmit=()=>{}, onChange=()=>{}} = this.props;
    return (
        <form onSubmit={(e)=>onSubmit(e)} className='ml-[15px] mr-[15px] h-[38px] rounded-[12px] bg-[#F8FAFC] mt-[22px] flex items-center pl-[4px] pr-[4px]'>
            <input onChange={(e)=>onChange(e.target.value)} value={value} type='text' className='w-[90%] bg-[#F8FAFC] outline-none text-[14px] txt-light-gray' placeholder='Search' />
            <button>
              <SearchSvg />
            </button>
        </form>
    )
  }
}

export default Search
