import React, { Component } from 'react'

export class ChildSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={12}
    height={12}
    baseProfile="tiny"
    viewBox="-63 65 128 128"
    fill="var(--vlue)"
  >
    <path d="M1 66.7c8.6 0 15.6 7 15.6 15.6S9.6 97.9 1 97.9s-15.6-7-15.6-15.6 7-15.6 15.6-15.6zm16.3 35.1h-32.6c-8.2.4-14.8 7.2-14.8 15.5v21.5c.1 3.2 2.7 5.7 5.8 5.7s5.8-2.6 5.8-5.7v-18c0-1.1.9-1.9 2-1.9s1.9.9 1.9 1.9v63.6c0 3.8 3 6.8 6.8 6.8s6.8-3 6.8-6.8v-32.1c0-1.1.9-1.9 1.9-1.9 1.1 0 1.9.9 1.9 1.9v32.1c0 3.8 3 6.8 6.8 6.8s6.8-3 6.8-6.8v-63.6c0-1.1.8-1.9 1.9-1.9s2 .9 2 1.9v18c.1 3.2 2.7 5.7 5.8 5.7s5.8-2.6 5.8-5.7v-21.5c.2-8.3-6.3-15.1-14.6-15.5z" />
  </svg>
    )
  }
}

export default ChildSvg
