import React, { Component } from 'react'

export class LoginUserSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
      >
        <g clipPath="url(#a)">
          <path
            fill="#545454"
            d="M9.993 10.573a4.501 4.501 0 0 0 1.722-8.657 4.5 4.5 0 1 0-1.722 8.657ZM10 0a6 6 0 0 1 3.04 11.174c3.688 1.11 6.458 4.218 6.955 8.078.047.367-.226.7-.61.745-.383.045-.733-.215-.78-.582-.54-4.19-4.17-7.345-8.57-7.345-4.425 0-8.101 3.161-8.64 7.345-.047.367-.397.627-.78.582-.384-.045-.657-.378-.61-.745.496-3.844 3.28-6.948 6.975-8.068A6 6 0 0 1 10 0Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h20v20H0z" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default LoginUserSvg
