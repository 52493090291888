import React, { Component } from 'react'
import img from "../../images/defaultUser.jpeg"
import { getLinkFormat } from '../messages/getLinks';
import PdfSvg from '../svg/PdfSvg';
import DotsSvg from '../svg/DotsSvg';
import ToolTop from './ToolTop';
import MsgReadedSvg from '../svg/MsgReadedSvg';
import LockSvg from '../svg/LockSvg';
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import { insertNewData } from '../../actions/MainAction';
import { getSupportMessages } from '../../actions/MessageAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import EmailSvg from '../svg/EmailSvg';
export class FromCard extends Component {
    state={
        dotVisible:false,
        visible: false
    }
    sendPrivateMessage=(data)=>{
        this.props.changeStateValue({
            name:"privateMsgModal",
            value:{
                position: true,
                ...data
            }
        })
    }
    changeStatus=(type, data)=>{
        let formData = new FormData();
        for(const property in data){
            if(data[property]){
                formData.append(property, data[property])
            }
            
        }
        formData.append("status", type)
        formData.append("team_id", this.props.user?.id)
        formData.append("_method", "put")
        this.props.insertNewData(`support-message/${data?.id}`, formData)
        .then(resp=>{
            this.setState({visible:false})
            this.props.getSupportMessages(1)
            if(resp==="success"){
                mixinAlert("success", "Status changed successfully")
            }else{
                mixinAlert("error", "Something went wrong")
            }
        })
    }

    sendEmail=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, send it!")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.insertNewData("send-support-email", {id: data?.id})
                .then(resp=>{
                    if(resp === "success"){
                        mixinAlert("success", "Email sent successfully");
                    }else{
                        mixinAlert("error", "Something went wrong!");
                    }
                })
               
            }
        })

    }
  render() {
    const {msg="", date, forward=false, 
        forwardUser, forwardMsg, userImg="",   
        msgImage="",
        msgAudio="",
        data, 
        msgPdf="", onReply=()=>{}, readStatus=true, privateMode=1, lang, user, msgVideo=""} = this.props;
    const {dotVisible, visible} = this.state;
    return (
        <div id={data?.id} onClick={privateMode==0 && data?.message ==null?this.sendPrivateMessage.bind(this, data):()=>{}} className='flex gap-[12px] max-[520px]:gap-[5px] items-start justify-end cursor-pointer'>
             {dotVisible || visible? <div onClick={()=>this.setState({dotVisible:false})} className='absolute w-full h-[100vh] top-0 left-0 z-20'></div>:null}
             {
                privateMode===0?
                <span><LockSvg /></span>:null
            }
            <div className='relative'>
              {
                user?.role === "support" || user?.role == "super_admin"?
                <button onClick={this.sendEmail.bind(this, data)}>
                    <EmailSvg />
                </button>:null
              }
                <button onClick={()=>this.setState({dotVisible:true})}>
                    <DotsSvg />
                </button>
                {
                    dotVisible?
                    <ToolTop  onClose={()=> this.setState({dotVisible:false})} data={data} onReply={()=>{onReply(); this.setState({dotVisible:false})}}  left={true} />:null
                }
                
            </div>
            
            <div className={`${privateMode==1? 'bg-white ':"bg-blue-100"} ${data?.status === 1?"border border-[#214693]": data?.status === 2?"border border-green-600":""} max-w-[70%] max-[490px]:max-w-[80%] p-[7px] rounded-[5px] mt-[2px] items-start flex flex-col pr-[15px]`}>
                {
                    forward?
                    <div className='bg-[#F5F9FF] w-[100%] rounded-[5px] border-l border-l-[2.3px] border-l-[var(--blue)] p-[7px] mb-[5px]'>
                        <span className='text-[#32BEA6] text-[12px]'>{forwardUser}</span>
                        <p  className='text-[#5C5C5C] text-[11px] break-words'>{forwardMsg}</p>
                    </div>:null
                }
                
                {
                    msgImage && msgImage !== ""?
                    <a href={msgImage}  download={true} target='_blank'>
                        <img className='rounded-[6px] w-[500px] mb-[10px] max-[490px]:w-[100%]' src={msgImage} />
                    </a>:
                    msgAudio && msgAudio !== ""?
                    <div className='w-[300px] msg-audio'>
                        <audio className='bg-white  listening-audio '  src={msgAudio} controls />
                    </div>:
                    msgVideo && msgVideo !== ""?
                    <div className='w-[300px] msg-audio'>
                        <video className='bg-white '  controls >
                            <source  src={msgVideo}   type="video/mp4"/>
                        </video>
                    </div>:
                    msgPdf && msgPdf !==""?
                    <a download={true} target='_blank' className='inline-flex items-center bg-[#F5F9FF] p-[4px] rounded-[6px] text-[13px] gap-[4px]' href={msgPdf}><PdfSvg /> {msg}</a>:
                    <pre className='text-[14px] whitespace-break-spaces text-[#5C5C5C] font-regular text ' dangerouslySetInnerHTML={{ __html: getLinkFormat(msg) }}></pre>
                }
                <span className='flex text-[10px] font-medium w-[100%] gap-[5px] justify-end text-[#707070] ml-[9px]'>{date} </span>
                {
                    data?.user?.role !=="support"?
                    <div className='relative'>
                    {
                        visible?
                        <div className='bg-white shadow absolute z-50 p-[5px] flex flex-col items-start gap-[5px] w-[70px] rounded-[5px] mb-[10px] mt-[-10px]'>
                            <button onClick={this.changeStatus.bind(this, '1', data)} className='text-[12px]'>
                                {lang?.msgReviewing}
                            </button>
                            <button  onClick={this.changeStatus.bind(this, '2', data)} className='text-[12px]'>
                                {lang?.msgSolved}
                            </button>
                        </div>:null
                    }
                    
                    {data?.status === 1? 
                        <span onClick={user?.role === "support"? ()=>this.setState({visible:true}):()=>{}} className='text-[12px] font-[500] cursor-pointer  text-[#214693]'>{lang?.msgReviewing}</span>: data?.status===2? 
                        <span onClick={user?.role === "support"? ()=>this.setState({visible:true}):()=>{}} className='text-[12px] font-[500] cursor-pointer text-green-600'>{lang?.msgSolved}</span>:user?.role === "support" 
                        && data?.status===3?
                        <span onClick={user?.role === "support"? ()=>this.setState({visible:true}):()=>{}} className='text-[12px] font-[500] cursor-pointer text-red-500'>Waiting</span>: 
                        null}
                   </div>:null
                }
                
            </div>
            <img alt="user image" className='w-[40px] h-[40px] rounded-full max-[520px]:w-[20px] max-[520px]:h-[20px]' src={userImg && userImg !== ""? userImg:img} />
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {changeStateValue, insertNewData, getSupportMessages}
export default connect(mapStateToProps, mapDispatchToProps)(FromCard)
