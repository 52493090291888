import React, { Component } from 'react'

export class TeacherEnroll extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={35}
            height={35}
            viewBox="0 0 18 18"
        >
            <path
            fill="var(--blue)"
            fillRule="evenodd"
            d="M17 1H1c-.6 0-1 .5-1 1v14c0 .6.5 1 1 1h16c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1zm-6 4h4c.6 0 1 .5 1 1s-.4 1-1 1h-4c-.6 0-1-.5-1-1s.4-1 1-1zm0 4h4c.6 0 1 .4 1 1s-.4 1-1 1h-4c-.6 0-1-.4-1-1s.4-1 1-1zM3 5c0-1 1-2 2-2s2 1 2 2v1c0 1-1 2-2 2S3 7 3 6V5zm0 10c-.5 0-1-.4-1-1v-2c0-1.7 1.4-3 3-3 1.7 0 3 1.3 3 3v2c0 .6-.5 1-1 1H3zm8 0c-.6 0-1-.4-1-1s.4-1 1-1h4c.6 0 1 .4 1 1s-.4 1-1 1h-4z"
            />
        </svg>
    )
  }
}

export default TeacherEnroll
