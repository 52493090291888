import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeStateValue } from '../redux/MainReducer'
export class PasswordStrength extends Component {
    getColor(value){
        if(value===0){
            this.props.changeStateValue({name:'passwordPermission', value:false})
            return "bg-red-800"
        }else if(value===1 || value===2){
            this.props.changeStateValue({name:'passwordPermission', value:false})
            return "bg-orange-400"
        }else if(value >= 3){
            this.props.changeStateValue({name:'passwordPermission', value:true})
            return "bg-green-600"
        }
    }
  render() {
    const {strength=0, lang} = this.props
    return (
        <div className='flex flex-col gap-[3px] mt-[5px]'>
            <div className='flex items-center gap-[3px]'>
                <span className={`${this.getColor(strength)} h-[3px] w-[20px] inline-block rounded-[3px]`}></span>
                <span className={`${this.getColor(strength)} h-[3px] w-[20px] inline-block rounded-[3px]`}></span>
                <span className={`${this.getColor(strength)} h-[3px] w-[20px] inline-block rounded-[3px]`}></span>
                <span className={`${this.getColor(strength)} h-[3px] w-[20px] inline-block rounded-[3px]`}></span>
            </div>
            <span className='text-[13px] text-gray-600 font-[500]'>{lang?.pass_req_title} </span>
            <span className='text-[13px] text-gray-600 ml-[10px]'>1. {lang?.pass_req_line_1}</span>
            <span className='text-[13px] text-gray-600 ml-[10px]'>2. {lang?.pass_req_line_2}</span>
            <span className='text-[13px] text-gray-600 ml-[10px]'>3. {lang?.pass_req_line_3}</span>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(PasswordStrength)
