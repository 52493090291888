import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { deleteData } from '../../../actions/MainAction';
import { withHooks } from '../../../actions/withHooks';
import { getExamQuestions } from '../../../actions/AdminActions';
export class QuestionCard extends Component {

    editBtn=(data)=>{
        let answers = [];
        for(const answer of data.answers){
            answers.push({
                ...answer,
                points : answer.score,
                type : data.type,
                check : answer?.is_correct == '1'
            })
            
        }
        this.props.changeStateValue({
            name: 'adminExamModalData',
            value: data
        })
        this.props.changeStateValue({
            name: 'adminExamModalData.question_option',
            value: answers
        })
        this.props.changeStateValue({
            name: 'adminExamModalData.questionPositon',
            value: true
        })
    }
    getRowValue(row, parentRow, e){
        this.props.changeStateValue({
          name:`adminExamQuestions.${parentRow}.children.${row}.sort_id`,
          value: e.target.value
        })
        this.props.changeSaveAcces()
    }
    deleteBtn=(data)=>{
        confirmAlert("warning", 'Are you sure?', "You won't be able to revert this!")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`questions/${data?.id}`)
                .then(resp=>{
                    if(resp === "success"){
                        this.props.getExamQuestions(this.props.params.id)
                        mixinAlert("success", "Question created successfully");
                    }else{
                        mixinAlert("error", "Something went wrong.");
                    }
                })
            }
        })
    }
  render() {
    const {data, row, parentRow} = this.props;
    // console.log(data)
    return (
        <div className='bg-white p-[10px] rounded-[10px] shadow flex justify-between gap-[30px]'>
            <div className='flex items-center gap-[10px]'>
                <div className='bg-gray-200 h-[28px] p-[5px] min-w-[50px] rounded flex gap-[3px] items-center rounded-[8px]'>
                    <span className='font-semibold'>Q</span>
                    <input onChange={this.getRowValue.bind(this, row, parentRow)} value={data?.sort_id} className='bg-none w-[25px] h-[20px]  rounded-[6px] text-center outline-none text-[14px]' type='number' />
                </div>
                <h3 className='text-[15px] font-semibold'>{data?.title}</h3>
            </div>
            <div className='flex gap-[20px]'>
                <div className='flex items-center gap-[10px]'>
                    {
                        data?.correct_answer?.map((item, i)=>{
                            return(
                                <h3 className='text-[13px]' key={i}>{item?.title}</h3>
                            )
                        })
                    }
                </div>
                
                <button onClick={this.editBtn.bind(this, data)} className='bg-blue text-[13px] font-semibold text-white rounded-[6px] p-[4px]'>Edit</button>
                <button onClick={this.deleteBtn.bind(this, data)} className='bg-red text-[13px] font-semibold text-white rounded-[6px] p-[4px]'>Delete</button>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    adminExamQuestions: state.Data.adminExamQuestions
});
const mapDispatchToProps = {changeStateValue, deleteData, getExamQuestions}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(QuestionCard))
