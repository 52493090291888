import React, { Component } from 'react'

export class InfoSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          cx={12}
          cy={12}
          r={10}
          stroke="#1C274C"
          strokeWidth={1.5}
          opacity={0.5}
        />
        <path
          stroke="#1C274C"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M12 17v-6"
        />
        <circle
          cx={1}
          cy={1}
          r={1}
          fill="#1C274C"
          transform="matrix(1 0 0 -1 11 9)"
        />
      </svg>
    )
  }
}

export default InfoSvg
