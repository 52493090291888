import React, { Component } from 'react'
import ExamResults from '../sections/ExamResults'
import { withHooks } from '../../actions/withHooks'

export class PublicExamSingle extends Component {
  render() {
    return (
        <div className='pt-[110px]'>
         <ExamResults loadPage={20} page="1" type="filter_by_exam" examId={this.props.params?.id} companyId="" />
      </div>
    )
  }
}

export default withHooks(PublicExamSingle)
