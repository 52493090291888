import React, { Component } from 'react'
import Audio from '../../exams/Audio'

export class ToeflModalParts extends Component {
  render() {
    const {title, description, listening_title, listening_url, score, modalType, answer, answer_url, part, speaking_question} = this.props
    return (
        <div>
            {
               description?
                <div>
                    <h3 className='text-[18px] font-semibold txt-blue'>{title}</h3>
                    <div className='mt-[20px]' dangerouslySetInnerHTML={{ __html:description }}></div>
                </div>:null
            }
            {
                listening_url?
                <div >
                    <div className='h-[1px] w-full bg-gray-300 mt-[10px] mb-[10px]'></div>
                    <h3 className='text-[18px] font-semibold txt-blue'>{listening_title}</h3>
                    <Audio url={listening_url} />
                </div>:null
            }
            {
                speaking_question?
                <div className='mt-[20px]' dangerouslySetInnerHTML={{ __html:speaking_question }}></div>:null
            }
            <div>
                <div className='h-[1px] w-full bg-gray-300 mt-[10px] mb-[10px]'></div>
                <div className='flex justify-between'>
                    <h3 className='text-[18px] font-semibold txt-blue'>Student Answer ({part})</h3>
                    <h3 className='text-[18px] font-semibold txt-blue'>{part} score: {score}</h3>
                </div>
                {
                    modalType==="writing"?
                    <p className='text-[15px] font-[300]'>{answer}</p>:
                    <Audio url={answer_url} />
                }
            </div>
            <div className='h-[1px] w-full bg-gray-300 mt-[10px] mb-[10px]'></div>
        </div>
    )
  }
}

export default ToeflModalParts
