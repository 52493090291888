import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAuditCoach } from '../../actions/CoachAction';
import { Link } from 'react-router-dom';
import { getCompanies } from '../../actions/AdminActions';
import Header from '../../components/header/Header';
export class AuditorCompanies extends Component {
    componentDidMount(){
        this.props.getCompanies(10)
    }
   
  render() {
    const {adminCompanies} = this.props;
    // console.log(adminCompanies)
   
    return (
      <div className=' max-[1100px]:mt-[120px] max-[800px]:mt-[150px] items-start p-[20px] rounded-[20px] mt-[40px]'>
        <Header title="Select a company" />
        <div className='grid-full-250 mt-[30px]'>
            {
                adminCompanies.map((data, i)=>{
                    if(data?.id !== 32 && 
                        data?.id !== 29 &&
                        data?.id !== 28 &&
                        data?.id !== 27 &&
                        data?.id !== 26 &&
                        data?.id !== 25 &&
                        data?.id !== 24 &&
                        data?.id !== 22 &&
                        data?.id !== 19 &&
                        data?.id !== 18 &&
                        data?.id !== 31 &&
                        data?.id !== 34 &&
                        data?.id !== 35 &&
                        data?.id !== 17 
                    ){
                        return(
                            <Link target='_blank' to={`/${this.props.path}/${data?.id}/${data?.name}`} key={i} className='shadow p-[20px] rounded-[10px] bg-white'>
                                <h3>{data?.name}</h3>
                                <div className='flex mt-[10px] justify-center'>
                                    <button className='pl-[5px] pr-[5px] bg-blue rounded-[5px] pt-[2px] pb-[2px] text-white text-[12px]'>View</button>
                                </div>
                            </Link>
                        )
                    }
                    
                })
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    adminCompanies: state.Data.adminCompanies
});
const mapDispatchToProps = {getAuditCoach, getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(AuditorCompanies)


