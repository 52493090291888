import React, { Component } from 'react'
import AddDynamicParent from './modals/AddDynamicParent'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import { getMocExamParents } from '../../../actions/AdminActions';
import DynamicParentCard from './DynamicParentCard';

export class DynamicExamParents extends Component {
    componentDidMount(){
        this.props.getMocExamParents(this.props.params.id)
    }
    openModalParent=(data)=>{
        this.props.changeStateValue({
            name:"dynamicExamParentModal",
            value:{
                position: true,
                exam_id: this.props.params.id,
                id: data?.id?data?.id:'',
                title: data?.title? data?.title:'',
                duration: data?.duration? data?.duration:'',
                max_score: data?.max_score? data?.max_score:'',
                type: data?.type? data?.type:'',
                afterCallFunction: this.props.getMocExamParents,
                afterCallParams: [this.props.params.id]
            }
        })
    }
  render() {
    const {dynamicExamParentModal, mocExamParents} = this.props;
    // console.log(mocExamParents)
    return (
        <div className='bg-white p-[20px] mt-[20px] rounded-[12px] shadow'>
            <div className='flex items-center justify-between'>
                <h2 className='text-[22px] font-semibold txt-blue cursor-pointer'>MOC Exam Questions</h2>
            </div>
            <div>
                <div className='flex items-center gap-[20px] mt-[20px]'>
                    <button onClick={this.openModalParent.bind(this, "")} className='h-[35px] pl-[7px] pr-[7px] text-[14px] font-semibold bg-blue text-white rounded-[6px]'>Add Exam Parent</button>
                </div>

                <div className='bg-gray-100 rounded-[10px] p-[10px] mt-[20px] flex flex-col gap-[10px]'>
                    {
                        mocExamParents?.map((data, i)=> {
                            return (
                                <DynamicParentCard 
                                    data={data}
                                    key={i}
                                    onEdit={(item)=>this.openModalParent(item)}
                                />
                            )
                        })
                    }
                </div>
            </div>
            {dynamicExamParentModal?.position? <AddDynamicParent />:null}
            
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    dynamicExamParentModal: state.Data.dynamicExamParentModal,
    mocExamParents: state.Data.mocExamParents,
});
const mapDispatchToProps = {changeStateValue, getMocExamParents}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(DynamicExamParents))
