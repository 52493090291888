import React, { Component } from 'react'
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { avatarAppConfig } from './azure_components/config';
import SpeechToText from './azure_components/SpeechToText';
import waitingVideo from "./azure_components/videos/waiting.webm"
import speakVideo from "./azure_components/videos/speak.webm"
import { connect } from 'react-redux';
import gif from "../../images/searchingGif.gif"
import SpeechToTextLevelCheck from './azure_components/SpeechToTextLevelCheck';
export class SpPracticeModalVideoLevelCheck extends Component {
    state={
        videoRef: React.createRef(),
        convTitle:true,
        gifPosition:false
    }
    speakText=(text, callFunction)=>{
        const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(avatarAppConfig.cogSvcSubKey, avatarAppConfig.cogSvcRegion);
        speechConfig.speechSynthesisVoiceName = avatarAppConfig.voiceName; // Replace with your desired voice name
        this.videoPlay(speakVideo)
        const synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig);
        synthesizer.speakTextAsync(
            text,
            result => {
              if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                // console.log('Speech synthesized to speaker');
                
              } else {
                // console.error('Speech synthesis failed:', result.errorDetails);
              }
                // console.log(result)
                const time1 =new Date(new Date().getTime())
                const time2 = new Date(new Date().getTime()+result?.privAudioData?.byteLength)
                const differenceInMilliseconds = time2 - time1;

                const differenceInSeconds = differenceInMilliseconds / 1000;
                let ms = Math.floor(differenceInSeconds/5.625)*1000
                setTimeout(()=>{
                    // console.log("bitdi")
                    this.videoPlay(waitingVideo)
                    callFunction()
                }, ms-200)
                synthesizer.close();
               
            },

            error => {
            //   console.error('Error occurred:', error);
              synthesizer.close();
            },
        )
    }

    videoPlay(src){
      
        this.setState({gifPosition:true});
        setTimeout(()=>{
          
            const video = this.state.videoRef?.current;
            setTimeout(()=>{
                if(video){
                    video.src = src
                    video.autoplay = true;
                    video.playsInline = true;
                    setTimeout(()=>{
                        this.setState({gifPosition:false});
                      
                    },500)
                 
                }
            },100)
        },500)
      
        
       
        
    }
    componentDidMount(){
        // this.videoPlay(waitingVideo)
        // console.log(video)
    }
    autoPlay=()=>{
        this.videoPlay(waitingVideo)
    }
  render() {
    const {modalData, lang, spTimer} = this.props;
    const {videoRef,convTitle, gifPosition} = this.state;
    // console.log(modalData)
    return (
      <div style={{ backgroundColor:'white' }} className='background_shadow '>
        <div className='w-full h-screen flex justify-center items-center flex-col bg-white max-[500px]:h-[80vh]'>
            <p className='bg-blue p-[4px_8px] text-white rounded-[6px] font-semibold absolute top-[40px] right-[40px]'>{spTimer}</p>
            {
                !convTitle ?
                <video onContextMenu={(e) => e.preventDefault()} controlsList="nodownload" onEnded={this.autoPlay.bind(this)} id="videoPlayerTag" className="h-screen w-full mb-[-10px] mt-[-10px]" ref={videoRef}></video>:null
            }
            {
                gifPosition?
                <div className='absolute backdrop-blur-[1px] w-full h-screen bg-white/30'>
                    {/* <img className='w-[200px]' src={gif} /> */}
                </div>:null
            }
           
            {
            convTitle?
            <div className="absolute text-center">
                <div>
                    <div dangerouslySetInnerHTML={{ __html: modalData?.question}}></div>
                    <button className="right-[20px] top-[20px] bg-blue p-[5px_10px] text-white rounded-[6px] mt-[20px]"  onClick={()=>{this.setState({convTitle:false}); this.videoPlay(waitingVideo)}}>
                        {lang?.start_conv}
                    </button>
                </div>
            </div>:null
        }
                
        </div>
        {/* <button onClick={this.speakText.bind(this, "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.")}>speak</button> */}
        {!convTitle?<SpeechToTextLevelCheck  speakSelectedText={(text, callFunction)=>this.speakText(text, callFunction)} modalData={modalData} />:null}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang,
    spTimer: state.Data.spTimer,
})
export default connect(mapStateToProps)(SpPracticeModalVideoLevelCheck)


