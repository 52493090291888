import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAssesmentsCoach, getCompanyCoach, getEndOfCourseData, getFirstFailedCoach } from '../../actions/CoachAction';
import CoachPartCard from '../../components/dashboard/CoachPartCard';
import EmptySvg from '../../components/svg/EmptySvg';
import SkeletonCard from '../../components/cards/SkeletonCard';
import { getCoachStatus, getTotalStudentCount } from '../../actions/getRole';
import CompanyWeeklyCoach from '../../components/CompanyWeeklyCoach';
import moment from 'moment';
import CoachEndOfExam from '../../components/CoachEndOfExam';
import { checkNewApplies, getManagerCompanies } from '../../actions/StaffAction';
import CompanyFailedStudentsCoach from '../../components/CompanyFailedStudentsCoach';
import { Link } from 'react-router-dom';
import AssesmentCoach from '../../components/AssesmentCoach';
import { changeStateValue } from '../../redux/MainReducer';
import ExceptionCoach from '../../components/ExceptionCoach';
import FailedExamStudents from '../../components/FailedExamStudents';
export class CompanyDashboard extends Component {
    state={
        loader:true,
        doneVisible:false
    }

    componentDidMount(){
        this.props.getCompanyCoach("auto")
        .then(resp=>{
            this.props.changeStateValue({name:"dashTitle", value: `Top Tier: ${this.getPercentage(resp)?.stCount >=150?"VIP":this.getPercentage(resp)?.stCount >=100 && this.getPercentage(resp)?.stCount <150?"1": this.getPercentage(resp)?.stCount <100 &&  this.getPercentage(resp)?.stCount >=75? "2": this.getPercentage(resp)?.stCount <75 &&  this.getPercentage(resp)?.stCount >=50? "3": this.getPercentage(resp)?.stCount <50 &&  this.getPercentage(resp)?.stCount >=25? "4": "0"}`})
            this.setState({loader:false})
        })
        this.props.getEndOfCourseData()

        this.props.checkNewApplies();
        this.props.getFirstFailedCoach();
        this.props.getManagerCompanies();
    }
    getManuallyCoach=()=>{
        this.setState({loader:true});
        this.props.getCompanyCoach("manual")
        .then(resp=>{this.setState({loader:false})})
    }
    getCoachStatsAll(companyCoach){
        let arr = [];
        companyCoach.forEach((item=>{
            for(const x of item?.statistics){
                let stats = [];
                if(x?.exam !=""){
                    stats.push({
                        ...x.exam,
                        title:"Exam",
                        type:'exam'
                    })
                }
                if(x?.assessments !=""){
                    stats.push({
                        ...x.assessments,
                        title:"RP.",
                        type:'assessments'
                    })
                }
                if(x?.essay !=""){
                    stats.push({
                        ...x.essay,
                        title:"Es.",
                        type:'essay'
                    })
                }
                if(x?.speaking !=""){
                    stats.push({
                        ...x.speaking,
                        title:"Sp.",
                        type:'speaking'
                    })
                }
                if(x?.homework !=""){
                    stats.push({
                        ...x.homework,
                        title:"PT",
                        type:'pt'
                    })
                }
                if(x?.speaking_practice !=""){
                    stats.push({
                        ...x.speaking_practice,
                        title:"Sp. P",
                        type:'sp_practice'
                    })
                }
               
                let info={
                    course_title: x.course_title,
                    lesson_mode: x.lesson_mode,
                    student_name: item?.full_name,
                    teacher_name: x?.teacher?.full_name,
                    student_id:item?.id,
                    statistics: stats,
                    start_date: x?.start_date,
                    end_date: x?.end_date,
                    // status:x?.exam?.status?.status
                    status: getCoachStatus(x),
                }

                arr.push(info)
            }
        }))
        return arr
    }
  
    getPercentage(companyCoach, endOfCourseData=[]){
        let errors =  this.getCoachStatsAll(companyCoach).filter(a=>a.status == 2).length;
        let warnings = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1).length;
        let success = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0).length;
        let total = errors + warnings + success + endOfCourseData?.length + this.props.exceptionCoach?.stats;
        let stCount = errors + warnings + success;
        if(getTotalStudentCount(companyCoach) != ""){
            stCount = getTotalStudentCount(companyCoach);
        }
        // console.log(getTotalStudentCount(companyCoach))
        return {
            total: total,
            errors: Math.round(((errors+endOfCourseData?.length+this.props.exceptionCoach?.stats)*100)/total),
            warnings: Math.round((warnings*100)/total),
            success: Math.round((success*100)/total),
            stCount: stCount
        }
    }   
  render() {
    const {companyCoach, lang, endOfCourseData, newApplyCheck, firstFailedCoach, managerCompanies, user, assessmentCoach} = this.props;
    const {loader, doneVisible} = this.state;
    // console.log(companyCoach)
    return (
        <div className='flex flex-col gap-[20px] w-full  max-[1100px]:mt-[120px] max-[800px]:mt-[150px]'>
        {
            managerCompanies?.length !=0 && user?.role == "company_head"?
            <div className='flex flex-wrap gap-[20px]'>
                {
                    managerCompanies?.map((data, i)=>{
                        return (
                            <Link key={i} className='bg-white txt-blue p-[5px_10px] font-[500] rounded-[10px] shadow' to={`/audit-company/${data?.company?.id}/${data?.company?.name}`}>{data?.company?.name}</Link>
                        )
                    })
                }
            </div>:null
        }
        {/* <div>
            <h3 className='txt-blue font-semibold text-[18px] text-center'></h3>
        </div> */}
        {
            this.getCoachStatsAll(companyCoach)?.length != 0?
            <div className='bg-white p-[20px] rounded-[20px] mb-[20px]'>
                   <div className='flex justify-between items-center '>
                        <h2>{lang?.coach_tracker}</h2>
                        <button className='bg-blue p-[4px_7px] rounded-[6px] text-white text-[12px] font-semibold' onClick={this.getManuallyCoach.bind(this)}>{lang?.coach_refresh_btn}</button>
                   </div>
                    <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center mt-[10px]'>
                        <div style={{ width: `${this.getPercentage(companyCoach, endOfCourseData)?.errors}%`}} className='h-[30px] rounded-l-[10px] w-[20%] bg-[#fd2e63] flex items-center justify-end pr-[10px]'>
                            <span className='text-[14px] text-white'>{this.getPercentage(companyCoach, endOfCourseData)?.errors}%</span>
                        </div>
                        <div style={{ width: `${this.getPercentage(companyCoach, endOfCourseData)?.warnings}%`}} className='h-[30px]  w-[20%] bg-[#ff5f24] flex items-center justify-end pr-[10px]'>
                            <span className='text-[14px] text-white'>{this.getPercentage(companyCoach, endOfCourseData)?.warnings}%</span>
                        </div>
                        <div style={{ width: `${this.getPercentage(companyCoach, endOfCourseData)?.success}%`}} className='h-[30px] rounded-r-[10px] w-[20%] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                            <span className='text-[14px] text-white'>{this.getPercentage(companyCoach, endOfCourseData)?.success}%</span>
                        </div>
                    </div>  
               </div>:null
        }
       
        <CompanyWeeklyCoach  padding="" />
        <AssesmentCoach />
        <div className='mt-[0px]'>
            <ExceptionCoach />
        </div>
        {endOfCourseData?.length !=0?  <CoachEndOfExam />:null}
        <FailedExamStudents />
        {firstFailedCoach?.length != 0? <CompanyFailedStudentsCoach  margin={false} />:null}
        {   loader?
            <div className=' gap-[30px]  items-start  rounded-[20px]'>
                <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <div className='grid-full-320 '>
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                        <SkeletonCard />
                    </div>
                </div>
            </div>:
             this.getCoachStatsAll(companyCoach).length !==0?
            <div className=' gap-[30px]  items-start rounded-[20px]'>
                
               
               
               <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <h2>{lang?.coach_mustbe_done}</h2>
                    {
                        this.getCoachStatsAll(companyCoach).filter(a=>a.status == 2).length!=0?
                        <div className='grid-full-320 '>
                            {
                                    this.getCoachStatsAll(companyCoach).filter(a=>a.status == 2)?.map((data, i)=>{
                                        return(
                                           
                                            <CoachPartCard start_date={moment(data?.start_date)?.format("DD/MM/YYYY")} end_date={moment(data?.end_date)?.format("DD/MM/YYYY")} key={i} data={data?.statistics} student_id={data?.student_id} student_name={data?.student_name} teacher_name={data?.teacher_name} course_title={data?.course_title}  status={data?.status}  />
                                        )
                                    })
                                }
                        </div>:
                        <p>{lang?.coach_outstanding}</p>
                    }
                    
               </div>
               <div className=' bg-white p-[20px] mt-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <h2>{lang?.coach_need_to_be_done}</h2>
                    {
                        this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1).length!=0?
                        <div className='grid-full-320'>
                        {
                            this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1)?.map((data, i)=>{
                                return(
                                    <CoachPartCard start_date={moment(data?.start_date)?.format("DD/MM/YYYY")} end_date={moment(data?.end_date)?.format("DD/MM/YYYY")} key={i} data={data?.statistics} student_id={data?.student_id}  teacher_name={data?.teacher_name}  student_name={data?.student_name} course_title={data?.course_title}  status={data?.status}  />
                                )
                            })
                        }
                        </div>:
                        <p>{lang?.coach_outstanding}</p>
                    }
                   
               </div>
               
               <div className=' bg-white p-[20px]  mt-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <h2 className='cursor-pointer' onClick={()=>this.setState({doneVisible: !doneVisible})}>{lang?.coach_already_done}</h2>
                    {doneVisible?
                        this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0).length!=0?
                        <div className='grid-full-320'>
                        {
                            this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0)?.map((data, i)=>{
                                return(
                                    <CoachPartCard start_date={moment(data?.start_date)?.format("DD/MM/YYYY")} end_date={moment(data?.end_date)?.format("DD/MM/YYYY")} key={i} data={data?.statistics} student_id={data?.student_id}  teacher_name={data?.teacher_name}  student_name={data?.student_name} course_title={data?.course_title}  status={data?.status} />
                                )
                            })
                        }
                        </div>:
                        <p>{lang?.coach_outstanding}</p>:null
                    }
                    
               </div>
                
            </div>:
            <div className='w-full h-[623px] bg-white rounded-[12px] flex justify-center items-center pl-[20px] pr-[20px]'>
                <div className='flex justify-center items-center flex-col'>
                
                    <EmptySvg />
                    <h3 className='text-[24px] txt-blue font-bold mt-[15px] text-center'>No data available</h3>
                
                </div>
            </div>
        }
        </div>
      
    )
  }
}
const mapStateToProps = (state) =>({
    companyCoach: state.Data.companyCoach,
    lang: state.Data.lang,
    endOfCourseData: state.Data.endOfCourseData,
    user: state.Data.user,
    newApplyCheck: state.Data.newApplyCheck,
    firstFailedCoach: state.Data.firstFailedCoach,
    managerCompanies: state.Data.managerCompanies,
    assessmentCoach: state.Data.assessmentCoach,
    exceptionCoach: state.Data.exceptionCoach
});
const mapDispatchToProps = {getCompanyCoach, getEndOfCourseData, checkNewApplies, getFirstFailedCoach, getManagerCompanies, getAssesmentsCoach, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyDashboard)


