import React, { Component } from 'react'

export class CardPhoneSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={10}
            height={10}
            fill="none"
        >
            <g clipPath="url(#a)">
            <path
                fill="#1E398F"
                d="m6.353 6.713.7-.7a.684.684 0 0 1 .731-.15l.854.34a.681.681 0 0 1 .425.625v1.563a.678.678 0 0 1-.713.672C2.372 8.69 1.166 3.628.938 1.69a.678.678 0 0 1 .678-.753h1.509a.675.675 0 0 1 .625.425l.34.853a.675.675 0 0 1-.146.73l-.7.7s.403 2.729 3.11 3.067Z"
            />
            </g>
            <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h10v10H0z" />
            </clipPath>
            </defs>
        </svg>
    )
  }
}

export default CardPhoneSvg
