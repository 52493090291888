import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getResources } from '../../actions/MainAction';
import Card2 from '../../components/cards/Card2';
import EmptyPage from '../../components/EmptyPage';
import HeaderButtons from '../../components/HeaderButtons';
import { changeStateValue } from '../../redux/MainReducer';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
export class AdminResources extends Component {
    componentDidMount(){
        this.props.getResources();
        window.scrollTo(0,0)
    }
    
    openModal=(data)=>{
        this.props.changeStateValue({
            name: "adminModalData",
            value:{
                position: true,
                id: data?.id?data?.id:"",
                title: data?.title? data?.title:"",
                status: data?.status? data?.status:"",
                assign_role: data?.assign_role? data?.assign_role:[],
                attachment: data?.attachment? data?.attachment:"",
                modalTitle: data?.id?"Edit Resource":"Add Resource",
                requireds: ["title"],
                afterCallFunction: this.props.getResources,
                afterCallParams: [],
                url: data?.id?`resources/${data?.id}`:"resources",
                overflow_def:true
            }
        })
    }

    onDeleteData=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`resources/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getResources();
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {resourcesData, lang} = this.props;
    // console.log(resourcesData)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="downloads" />
            <div className='mt-[30px] flex justify-between items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Resource", id:0}]} selected={0} />
            </div>
            {resourcesData?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-205 max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
               {
                resourcesData?.map((data, i)=>{
                    return(
                        <div className='bg-white rounded-[10px]' key={i}>
                            <Card2 
                               
                                title={data?.title}
                                download={true}
                                downloadUrl={data?.image_full_url}
                                downBtnTitle={lang?.download}
                            />
                            <div className='p-[10px]'>
                                <button className='text-[14px] p-[3px_6px] bg-red text-white rounded-[5px]' onClick={this.onDeleteData.bind(this, data)}>Delete</button>
                            </div>
                        </div>
                    )
                })
               }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    resourcesData: state.Data.resourcesData,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getResources, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminResources)


