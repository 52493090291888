import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAdminCourseRules } from '../../actions/AdminActions';
import HeaderButtons from '../../components/HeaderButtons';
import Header from '../../components/header/Header';
import { changeStateValue } from '../../redux/MainReducer';
import Card1 from '../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
export class AdminCourseRules extends Component {
    state={
        activePage:0,
        pageType: 'speaking'
    }
    componentDidMount(){
        this.props.getAdminCourseRules(this.state.pageType)
    }
    rulePayMode=()=>{
        const btns = [
            { label: "Standart (12)", value: "12" },
            { label: "Intensive (24)", value: "24" },
            { label: "Businnes II (8)", value: "8" },
            { label: "Businnes I (4)", value: "4" },
        ]
        return btns
    }
    openModal=(data)=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                course_id:data?.course_id? data?.course_id:'',
                payment_mode:data?.payment_mode? data?.payment_mode:'',
                duration:data?.duration? data?.duration:'',
                exam:data?.exam? data?.exam:'',
                essay:data?.essay? data?.essay:'',
                speaking:data?.speaking? data?.speaking:'',
                speaking_practice:data?.speaking_practice? data?.speaking_practice:'',
                attendance:data?.attendance? data?.attendance:'',
                homework:data?.homework? data?.homework:'',
                assessments:data?.assessments? data?.assessments:'',

                afterCallFunction:this.props.getAdminCourseRules,
                afterCallParams:[],
                typeOptions: this.rulePayMode(),
                requireds:["course_id", "payment_mode", "duration"],
                url: data?.id? `course-rules/${data?.id}`:'course-rules',
                modalTitle: data?.id? `Edit Rule`:'Add Rule',
            }
        });
    }

    onDeleteData=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`course-rules/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getAdminCourseRules()
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }

  
  render() {
    const {adminCourseRules, user} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Rules" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Rule", id:0}]} selected={0} />
            </div>
            <div className='mt-[30px] grid-full-250'>
                {
                    adminCourseRules.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.course?.title}
                                cheating={this.rulePayMode().find(item=>item?.value==data?.payment_mode)?.label}
                                status={1}
                                statusBisible={false}
                                btn={false}
                                btn2={true}
                                btn2Text={"Edit"}
                                btn2Color={"bg-gray-400"}
                                onCLickBtn={this.openModal.bind(this, data)}
                                adminDelete={user?.role === "super_admin"}
                                onDeleteBtn={this.onDeleteData.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminCourseRules: state.Data.adminCourseRules,
    user: state.Data.user,
});
const mapDispatchToProps = {getAdminCourseRules, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCourseRules)


