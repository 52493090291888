import Swal from "sweetalert2";
import { MAIN_API, MAIN_APIV2, PUBLIC_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";
import axios from "axios";
import { getErrors } from "./MainAction";
const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
        "Accept": "application/json",
        // "Content-Type": "application/json"
    }
}
export const getEssayUngraded = (keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/essays-ungraded`, {
        params:{keyword:keyword},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "essayUngraded", value:resp?.data}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getSpeakingUngraded = (keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/speakings`, {
        params:{keyword: keyword, query_type: 'speaking_ungraded'},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "speakingUngraded", value:resp?.data}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getStaffEssayReview = (params) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/essay-answers`, {
        params:params,
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "staffEssay", value:resp?.data}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getStaffSpeakingReview = (params) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/speakings`, {
        params:params,
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "staffSpeaking", value:resp?.data}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getStaffExamResults = (params) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/studentAnswer`, {
        params:params,
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "staffExamResults", value:resp?.data}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeachers = (page, keyword, company_id="", url="users") => async dispatch => {

    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/${url}`, {
        params:{page, keyword, user_type: "company_head_teacher", company_id:company_id},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "teachers", value:resp?.data}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getParents= (page, keyword, company_id="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/users`, {
        params:{page, keyword, user_type: "parent", company_id:company_id},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "parents", value:resp?.data}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficeManagers= (page, keyword, company_id="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/users`, {
        params:{page, keyword, user_type: "office_manager", company_id:company_id},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "officeManagers", value:resp?.data}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficeAllStudents= (company_id="") => async dispatch => {
    return axios.get(`${MAIN_API}/users`, {
        params:{ user_type: "celt_students", company_id:company_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "officeAllStudents", value:resp?.data}));
        return 'success';
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficeStudents= (page, keyword, company_id="", lockedFilter, lessonFilter, schoolYearFilter, id='', date="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/users`, {
        params:{ page, keyword, user_type: "company_head_student", company_id:company_id, assign_to_user:id, lockedFilter, lessonFilter, schoolYearFilter, date:date},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "officeStudents", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getUserData= (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/users/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getCoursesByStudent= (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/users/${id}/courses`, {
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getActiveCoursesByStudent= (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-active-courses?student_id=${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeacherStats= (date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/manager-all-teacher-statistics`, {
        params: {date: date},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "teacherStats", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getUnlockExams= (course_id, lesson_id, student_id, hw=0, user_type="student_lock") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exams`, {
        params:{keyword: "", course_id: course_id, lesson_id: lesson_id, student_id: student_id, home_work: hw, user_type:user_type},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getSpeakingReviewByStudent= (page, id, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/speakings`, {
        params: {query_type: 'student_answer_tab', student_id: id, keyword: keyword, page: page},
        headers: apiHeaders()
    }).then(resp=>{
        
        dispatch(changeStateValue({name: "speakingByStudent", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getStudentLogs= (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/students-log`, {
        params: {student_id: id,},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return 'error'
    })
}
export const getStudentAssesments= (page, id, keyword, query_type = '') => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/assessments`, {
        params: {page: page, keyword: keyword, user_id: id, query_type:query_type},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getStudentPaymentsTeachers= (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/students-payment-teacher`, {
        params: {student_id:id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getStudentPaymentsEnroll= (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-enroll-payments`, {
        params: {student_id:id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "enrollPaymentsModal", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getStudentWeeklyStats= (data) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-student-weekly-taken`, {
        params: data,
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}


export const getStudentPayment= (id, start_date, end_date, ) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/getPaymentEvent`, {
        params:{user_id: id, start: start_date, end: end_date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeacherStudentPayment= (id, start_date, end_date,teacher_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-teacher-payment-events`, {
        params:{user_id: id, start: start_date, end: end_date, teacher_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return []
    })
}
export const getTeacherClass= (user_id, date, teacher_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-teacher-class`, {
        params:{teacher_id: teacher_id, student_id: user_id, today: date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeacherClassCourse= (user_id, date, teacher_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-teacher-class-course`, {
        params:{teacher_id: teacher_id, student_id: user_id, today: date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getLessonModes= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/lesson-mode`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "lessonModes", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getCompanyWiseReports= (start_date, end_date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/company-wise-assessment`, {
        params:{startdate: start_date, enddate:end_date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "companyWiseReports", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getCompanyWaitingReports= (start_date, end_date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/inactive-assessments`, {
        params:{startdate: start_date, enddate:end_date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "companyWiseReports", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getCompanyWiseReportsUngraded= (start_date, end_date, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/assessment-ungraded`, {
        params:{startdate: start_date, enddate:end_date, keyword:keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "assesmentsUngraded", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeacherSalary= (id, date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/teacher-salary?teacher_id=${id}&date=${date}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "teacherSalary", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getGroupsByTeacher= (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-teacher-groups?teacher_id=${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "groupsByTeacher", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeacherBonuses= (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/teacher-bonus?teacher_id=${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "teacherBonuses", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficeManagerBonuses= (office_manager_id = '', date = '', company_id = '') => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/office-manager-bonus`, {
        params:{office_manager_id: office_manager_id, date: date, company_id: company_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "officeManagerBonuses", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficeToeflExams= (url) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/${url}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "officeToeflExams", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficeBonuses= (date, company_id = '') => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/bonuses`, {
        params: {date: date, company_id: company_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "bonuses", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficePaymentDue= (company_id = '') => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/users/attendances`, {
        params: {company_id: company_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "officePaymentDue", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getSpeakingTeacherCheckins= (params) => async dispatch => {
    
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/speaking-teacher-attendance`, {
        params: params,
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "speakingTeacherCheckins", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getSpeakingTeacherCheckinsView= (params) => async dispatch => {
    
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/speaking-teacher-attendance`, {
        params: params,
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "speakingTeacherCheckinsView", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeacherOwnSalary= (date) => async dispatch => {
    dispatch(changeStateValue({name: "loaderLong", value:true}));
    return axios.get(`${MAIN_API}/teacher-own-salary`, {
        params: {date:date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "teacherOwnSalary", value:resp?.data}));
        dispatch(changeStateValue({name: "loaderLong", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loaderLong", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}

export const getOfficeEnrolledStudent= (date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/manager-company-enrolled-students`, {
        params: {date:date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "officeEnrollStudents", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return 'error'
    })
}

export const getStatRef= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-stat-ref`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return []
    })
}
export const getTypeOfStudents= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/type-of-student`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "typeOfStudents", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficeExamTakes= (date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-taken`, {
        params: {date:date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "officeExamTakens", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficeTeacherManagerData= (date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/teacher-manager-data`, {
        params: {date:date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "officeTeacherManagerData", value:resp?.data?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getSpPracticeReviews= (page, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-speaking-practice`, {
        params: {page, keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "officeSpPractice", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getOfficeApplicant= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${PUBLIC_API}/student-apply`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("celtPublicToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name: "officePublicApplicants", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}


export const getTeacherStatsEssReview= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/essay-answers`, {
        params: {page: 1, keyword: '', type: 'essay_not_review'},
        headers:apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeacherStatsSpReview= (role="teacher") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/speakings`, {
        params:{page: 1, type: role, speaking_type: 'not_review'},
        headers:apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeacherStatsMidEndReview= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/essay-answers`, {
        params:{ type: 'NonReviewCeltEssay'},
        headers:apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getManuallyExamId= (exam_id, student_id, type) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_APIV2}/student-exam-manual`, {
        params: {exam_id: exam_id, student_id:student_id, type:type},
        headers:apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getTeacherDailyControll= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/teacher-daily-controle`, {
        headers:apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getDob= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-dob`, {
        headers:apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return []
    })
}


export const getStExamException= (student_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-ex-apply?student_id=${student_id}`, {
        headers:apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "examException", value: resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getCoachException= (student_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/coach-exception?student_id=${student_id}`, {
        headers:apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "coachException", value: resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getStudentNotes= (student_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-notes?student_id=${student_id}`, {
        headers:apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "studentNottes", value: resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return 'error'
    })
}





export const getExamDetails= (id, data) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    let datas = []
    for(let i = 0; i < id.length; i++){
        const requests = axios.get(`${MAIN_APIV2}/student-exam-result/part-detail/${id[i]}`,{
          params:data,
          headers: apiHeaders()
        })
        datas.push(requests)
    }
    return axios.all(datas).then(axios.spread((...responses)=>{
        dispatch(changeStateValue({name: "studentExamReviews", value:responses}));
    })).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}


export const getStudentExamReviews= (id, params) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_APIV2}/student-exam-result/parent-ids/${id}`, {
        params:params,
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(getExamDetails(resp?.data?.parent_ids, params))
       
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}
export const getAllTeacherCheck= (page, start_date, end_date, user_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-all-teacher-payment-events`, {
        params:{page:page, start: start_date, end: end_date, user_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "teacherAllChecks", value:resp.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return 'error'
    })
}
export const getStudentContracts= (student_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-contracts`, {
        params:{student_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return []
    })
}

export const getTeacherContracts= (teacher_id, date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/teacher-contracts`, {
        params:{teacher_id,date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return []
    })
}
export const getEnrollPayments= (page, search, type, company_id="", date="", teacher_id="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/enroll-payments`, {
        params:{page, type, keyword:search, company_id, date, teacher_id:teacher_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "enrollPayments", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return []
    })
}
export const getStudentPayments= (student_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/enroll-payment-by-student`, {
        params:{student_id, type:"student"},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return []
    })
}
export const getEnrollPaymentsExport= (page, search, type, company_id="", date="", teacher_id="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-enrollpays-export`, {
        params:{page, type, keyword:search, company_id, date, teacher_id:teacher_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return []
    })
}
export const getComapnyExamException= (status=1) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/company-exam-exception`, {
        params:{status},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return []
    })
}

export const getComapnyCoachException= (status=1) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/company-coach-exception`, {
        params:{status},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return []
    })
}

export const getExamSchedule= (date, user_id) => async dispatch => {
//    console.log(date, user_id)
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-schedule`, {
        params:{date, user_id},
        headers: apiHeaders()
    }).then(resp=>{
        // console.log(resp.data)
        dispatch(changeStateValue({name: "examSchedule", value:resp?.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return []
    })
}
export const getExamScheduleDays= (date, user_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-schedule-days`, {
        params:{date, user_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "examScheduleDays", value:resp?.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return []
    })
}
export const getExamKeys= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-auth-key`, {
     
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "examKeys", value:resp?.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return []
    })
}
export const getSpeakingByLesson= (lesson_id, student_id) => async dispatch => {
    // console.log(student_id)
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/getSpeakingByLesson/${lesson_id}`, {
        params:{student_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "speakingByLesson", value:resp?.data}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return []
    })
}
export const getSupportSchedule= (date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/support-exam-schedule`, {
        params:{date},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "supportExamSchedule", value:resp?.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return []
    })
}
export const getOficeSchedule= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/office-exam-schedule`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "oficeExamSchedule", value:resp?.data}));
    }).catch(err=>{
        // console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        return []
    })
}





export const getCompanyWeeklyCoach= (type) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/company-weekly-coach?query_type=${type}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "companyWeeklyCoach", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return []
    })
}
export const getLoginLogs= () => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/login-logs`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loginLogs", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return []
    })
}



export const getExamCategories= () => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-categories`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "examCategories", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return []
    })
}


export const getSubByCategory= (id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-sub-by-categories/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "examSubCategories", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return []
    })
}
export const getSectionBySubCategory= (id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-sections-by-subcategory/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "examSectionBySubCategories", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return []
    })
}

export const getSubCategoryInfo= (id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-sub-categories/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return []
    })
}
export const getExamSectionInfo= (id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exam-sections/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return []
    })
}


export const getPoolingExamQuestions= (section_id="", active=1, level="") => async dispatch => {
    // console.log(section_id, active, level)
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/pooling-exam-questions`, {
        params:{section_id, active, level},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "poolingExamQuestions", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return []
    })
}
export const getOneExamQuestion= (id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/pooling-exam-questions/${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "poolingExamParentData", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return ""
    })
}
export const getEndOfExamExceptions= (type="single", student_id="", status='0') => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/end-ofexam-exceptions?type=${type}&student_id=${student_id}&status=${status}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "endOfExamExceptions", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return ""
    })
}
export const getFailedStudents= (type="single", student_id="", status='0') => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/first-failed-exception?type=${type}&student_id=${student_id}&status=${status}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "failedStudentsData", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return ""
    })
}
export const getStudentAllCourses= (student_id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-all-courses?student_id=${student_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "studentAllCourses", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return ""
    })
}
export const getStudentCourses= (student_id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-courses?student_id=${student_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "studentCoursesAll", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return ""
    })
}
export const getAdminPriceCalculator = () => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/price-calculator`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminPriceCalculator", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return ""
    })
}
export const getStudentGroupData = (teacher_id, student_group_id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/check-student-group`, {
        params: {teacher_id, student_group_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "studentGroupParams", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return ""
    })
}
export const getGroupUsers = (teacher_id, student_group_id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-group-info`, {
        params: {teacher_id, student_group_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "studentGroupUsers", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return ""
    })
}
export const getEnrollData = (lesson_mode_id, study_mode, student_id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/check-student-enroll`, {
        params: {lesson_mode_id, study_mode, student_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "studentEnrollPrices", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return ""
    })
}
export const getOldestEnrollCount = (lesson_mode_id, student_id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/get-oldest-count`, {
        params: {lesson_mode_id, student_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "oldestEnrollCount", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return ""
    })
}
export const getOnlineApplies = (page=0) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/apply-by-company`, {
        params: {page},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "onlineAppliesData", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return ""
    })
}
export const getBlockedLogins = (type) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/login-block`, {
        params: {type},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loginBlocksData", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return ""
    })
}
export const getManagerCompanies = () => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/manager-companies`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "managerCompanies", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return ""
    })
}
export const getGroupsLink = () => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/teacher-group`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "groupsLinks", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return ""
    })
}


export const getSingleData = (url) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/${url}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return []
    })
}
export const checkNewApplies = () => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/new-online-applies`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "newApplyCheck", value:resp?.data}));
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        // console.log(err.response)
        return []
    })
}

export const recheckEssay=async (params)=>{
    return await axios.post(`${MAIN_API}/recheck-essay-auto`, params,{
        headers: apiHeaders()
    }).then(resp=>{
        // console.log(resp)
        return resp?.data
    }).catch(err=>{
        console.log(err.response);
        return "";
    })
}
export const recheckIALEssay=async (params)=>{
    return await axios.post(`${MAIN_API}/recheck-ialessay-auto`, params,{
        headers: apiHeaders()
    }).then(resp=>{
        // console.log(resp)
        return resp?.data
    }).catch(err=>{
        // console.log(err.response);
        return "";
    })
}
export const recheckSpeaking=async (id)=>{
    return await axios.post(`${MAIN_API}/recheck-speaking-auto`, {id},{
        headers: apiHeaders()
    }).then(resp=>{
        // console.log(resp)
        return resp?.data
    }).catch(err=>{
        console.log(err.response);
        return "";
    })
}




const getUncMsg=(arr)=>{
    let str = '';
    for(const data of arr){
      for(const property in data){
        str+= `${property}: ${data[property]} <br />\n\n`
      }
    }
    return str
  }

const getHwMsg=(arr)=>{
    let str = '';
    for(const data of arr){
        str+= `${data?.exam?.title} <br />\n\n`
    }
    return str
  }

export const unlockStudentExam = (url, formData) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_API}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        if(err.response.status == 422){
            Swal.fire(
              'Exam locked!',
              `To unlock the exam, you have to complete the following: <br /><br />
                ${getUncMsg(err.response?.data?.data)}
              `,
              ''
            )
          }
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        return 'error'
    })
}
export const unlockStudentHomwork = (url, formData) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_API}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        if(err.response.status == 422){
            Swal.fire(
                'PT Locked!',
                `The student has to finish the previous PTs first. <br /><br />
                  ${getHwMsg(err.response?.data?.data)}
                `,
                ''
              )
          }else{
            // dispatch(getErrors(err))
          }
        dispatch(changeStateValue({name: "loader", value:false}));
        // 
        return 'error'
    })
}




// dictionary

export const getDictionary= (search, type) => async dispatch => {
    return axios.get(`https://api.dictionaryapi.dev/api/v2/entries/en/${search}`, {
        // headers: {
        //     'X-RapidAPI-Key': 'b3b1f8c465msh59864290808585bp1cf573jsn68dec0af2711',
        //     'X-RapidAPI-Host': 'wordsapiv1.p.rapidapi.com'
        //   }
    }).then(resp=>{
        return resp?.data;
    }).catch(err=>{
        return []
    })
}


