import React, { Component } from 'react'

export class Video extends Component {
  render() {
    const {url} = this.props;
    return (
      <div className='mt-[20px]'>
        <video src={url} className='video_player' controls />
      </div>
    )
  }
}

export default Video
