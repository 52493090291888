import React, { Component } from 'react'
import Header from '../../components/header/Header'
import moment from 'moment'
import { connect } from 'react-redux'
import { getSupportSchedule } from '../../actions/StaffAction'
import Card4 from '../../components/cards/Card4'
import { getRole } from '../../actions/getRole'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { deleteData, insertNewData } from '../../actions/MainAction'

export class SupportSchedule extends Component {
    state={
        date: moment(new Date()).format("YYYY-MM-DD")
    }
    componentDidMount(){
        this.props.getSupportSchedule(this.state.date)
    }
    onChangeDate=(e)=>{
        this.setState({date: e.target.value});
        this.props.getSupportSchedule(e.target.value)
    }
    deleteUser=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`exam-schedule/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getSupportSchedule(this.state.date)
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }

    changeStatus=(id, status)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, change it")
        .then(alertResp=>{
            if(alertResp === "success"){
                let formData = new FormData();
                formData.append("call_status", status==0?'1':'0')
                formData.append("_method", "put")
            this.props.insertNewData(`schedule-status-update/${id}`, formData)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getSupportSchedule(this.state.date)
                    mixinAlert("success", "Data changed successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {date} = this.state;
    const {supportExamSchedule, user} = this.props;
    // console.log(supportExamSchedule)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header  title="Exam Schedule" />
            <div className='flex justify-start mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
                <input onChange={this.onChangeDate.bind(this)} className='p-[2px_10px] rounded-[6px]' type="date" value={date} />
            </div>
            <div className='mt-[20px] grid grid-full-250'>
                {
                    supportExamSchedule?.map((data, i)=>{
                        return(
                            <Card4
                                key={i}
                                name={data?.user?.full_name}
                                email={data?.user?.email}
                                phone={data?.user?.phone_number}
                                phone_number2={data?.user?.children_parent[0]?.phone_number}
                                image={data?.user?.avatar_full_url}
                                role={getRole(data?.user?.role, this.props.lang)}
                                date={moment(new Date(data?.date)).format("MMMM DD")+", "+data?.time}
                                course={data?.course?.title}
                                time={data?.time}
                                headerBtn={true}
                                lockUnlock={false}
                                deleteUserBtn={user?.role === "super_admin"}
                                onClickDelete={this.deleteUser.bind(this,data?.id)}
                                info={`/students/${data?.user?.id}/${data?.exam_id}?select_course`}
                                company={data?.user?.company?.name}
                                lesson={data?.exams?.title?data?.exams?.title:" "}
                                created_at={moment(data?.created_at).format("DD/MM HH:mm")}
                                callStatusBtn={true}
                                callStatus={data?.call_status}
                                onChangeStatus={this.changeStatus.bind(this, data?.id, data?.call_status)}
                                // onClickInfo={this.onClickInfo.bind(this,)}
                        />
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    supportExamSchedule: state.Data.supportExamSchedule,
    lang: state.Data.lang,
    user: state.Data.user,
})
const mapDispatchToProps = {getSupportSchedule, deleteData, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(SupportSchedule)
