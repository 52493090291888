import React, { Component } from 'react'

export class CertificateSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
      >
        <path
          fill="#585874"
          d="M6.75 4.125a.375.375 0 0 1 .375-.375h3.75a.375.375 0 0 1 0 .75h-3.75a.375.375 0 0 1-.375-.375ZM5.625 6a.375.375 0 1 0 0 .75h6.75a.375.375 0 0 0 0-.75h-6.75ZM5.25 7.875a.375.375 0 0 1 .375-.375h6.75a.375.375 0 0 1 0 .75h-6.75a.375.375 0 0 1-.375-.375ZM5.625 9a.375.375 0 1 0 0 .75h6.75a.375.375 0 0 0 0-.75h-6.75Z"
        />
        <path
          fill="#585874"
          fillRule="evenodd"
          d="M14.25 13.5a1.5 1.5 0 0 1-1.5 1.5h-1.125v1.5l-1.125-.563-1.125.563V15H5.25a1.5 1.5 0 0 1-1.5-1.5V3a1.5 1.5 0 0 1 1.5-1.5h7.5a1.5 1.5 0 0 1 1.5 1.5v10.5Zm-9-11.25A.75.75 0 0 0 4.5 3v10.5a.75.75 0 0 0 .75.75h4.125v-.883a1.5 1.5 0 1 1 2.25 0v.883h1.125a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75h-7.5Zm5.625 11.578a1.505 1.505 0 0 1-.75 0v1.459l.375-.188.375.188v-1.46Zm-.375-.703a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default CertificateSvg
