import React, { Component } from 'react'

export class Image extends Component {
  state={
    imgRef: React.createRef(),
    loadingImg:true
  }
  componentDidMount(){
    this.setState({loadingImg: this.state.imgRef?.current?.complete})
  }
  getLoading=(e)=>{
    this.setState({loadingImg: this.state.imgRef?.current?.complete})
  }
  render() {
    const {url} = this.props;
    const {imgRef, loadingImg} = this.state;
    return (
      <div className='mt-[20px] max-sm:max-w-[100%] max-w-[60%]'>
        <img src={url} onLoad={this.getLoading.bind(this)} ref={imgRef} alt="img"   className='w-full rounded-[12px]'/>
        {
          !loadingImg?
          <div className='border p-[30px] rounded-[10px]'>
            <h4>Please wait. Image is loading</h4>
          </div>:null
        }
        
      </div>
    )
  }
}

export default Image
