import React, { Component } from 'react'
import { changeStateValue } from '../../../../redux/MainReducer';
import { connect } from 'react-redux';
import { getEnrollData, getLessonModes, getOldestEnrollCount } from '../../../../actions/StaffAction';
import DefaulData from '../../../../components/modals/components/DefaulData';
import DropSearch from '../../../../components/DropSearch';
import { getDropValue } from '../../../../actions/getRole';
import { getCourses } from '../../../../actions/MainAction';
import { mixinAlert } from '../../../../actions/SweetAlerts';
import Input from '../../../../components/modals/components/Input';
import ManatSvg from '../../../../components/svg/ManatSvg';
import Header from '../../../../components/modals/Header';

export class EnrollParams extends Component {
    componentDidMount(){
      this.props.getLessonModes();
      this.props.getCourses();
    }

    getPriceValue(prices, lesson_mode_id, study_mode){
      return prices.filter(x=>x?.lesson_mode_id == lesson_mode_id && x?.study_mode == study_mode)[0]
    }
   
    addFee=(fee)=>{
      this.props.changeStateValue({name:"studentCalculatorModal.fee", value:fee})
    }

    changePage=(page)=>{
      // console.log(this.props.studentCalculatorModal)
      if(page === "pay_params"){
        if(
          this.props.studentCalculatorModal.lesson_mode_id == "" || 
          this.props.studentCalculatorModal.course_id == "" || 
          this.props.studentCalculatorModal.study_mode == "" || 
          this.props.studentCalculatorModal.lesson_hour == "" || 
          this.props.studentCalculatorModal.selected_count == "" || 
          this.props.studentCalculatorModal.fee == ""
        ){
         if( this.props.studentCalculatorModal.selected_count ==""){
          mixinAlert("warning", "Please select one of them");
          return
         }
          mixinAlert("warning", "Please, fill the all required fields");
          return
        }
        if(this.props.studentCalculatorModal.count<12){
          mixinAlert("warning", "The number of lessons should be at least 12");
          return
        }
      }
      this.props.changeStateValue({
          name:"studentCalculatorModal.page",
          value:page
      })
  }

  onSelectLessonMode=(lesson_mode_id)=>{
    if(this.props.studentCalculatorModal?.study_mode !==""){
      this.props.getEnrollData(lesson_mode_id,this.props.studentCalculatorModal?.study_mode, this.props.student?.id)
    }
    this.props.changeStateValue({name:"studentCalculatorModal.course_id", value:""})
    this.props.getOldestEnrollCount(lesson_mode_id, this.props.student?.id)
    .then(resp=>{
      this.props.changeStateValue({name:"studentCalculatorModal.selected_count", value:""})
      this.props.changeStateValue({name:"studentCalculatorModal.price_type", value:""})
      this.props.changeStateValue({name:"studentCalculatorModal.fee", value:""})
      if(!resp?.id || resp?.id == ""){
        this.props.changeStateValue({name:"studentCalculatorModal.selected_count", value: this.props.studentCalculatorModal.count})
        this.props.changeStateValue({name:"studentCalculatorModal.price_type", value:"new"})
      }
    })
  }
  onSelectStudyMode=(study_mode)=>{
    if(this.props.studentCalculatorModal?.lesson_mode_id !==""){
      this.props.getEnrollData(this.props.studentCalculatorModal?.lesson_mode_id, study_mode, this.props.student?.id)
    }
  }
  getFeeDistinc(exists, oldPrices, oldestFee=0, newPrice=0){
    let values = [];
    
    for(const x of oldPrices){
      if(exists !=x?.fee ){
        const check = values.find(y=>y === x?.fee)
        if(!check){
          values.push(x?.fee)
        }
      }
     
    }
    // console.log(values, oldestFee)
    if(oldestFee !=0){
      const check = values.includes(oldestFee);
      if(!check){
        values.push(oldestFee)
      }
    }
    if(newPrice !=0){
      const check = values.includes(newPrice);
      if(!check){
        values.push(newPrice)
      }
    }
    return values;
  }
  calculateFee(count, fee){
    return Math.round((count*fee?.fee)/12)
  }
  onSelectWeekDay=(data)=>{
    this.props.changeStateValue({name:'studentCalculatorModal.count', value: 12*data})
    if(!this.props.oldestEnrollCount?.id || this.props.oldestEnrollCount?.id ==""){
      this.props.changeStateValue({name:"studentCalculatorModal.selected_count", value:12*data})
    }
  }

  onSelectCount=(count, type)=>{
    this.props.changeStateValue({name:'studentCalculatorModal.selected_count', value: count})
    this.props.changeStateValue({name:'studentCalculatorModal.price_type', value: type})
    this.props.changeStateValue({name:'studentCalculatorModal.fee', value: ""})
    if(type == "new"){
      this.props.changeStateValue({name:"studentCalculatorModal.file", value:""})
      this.props.changeStateValue({name:"studentCalculatorModal.file_full_url", value:""})
      this.props.changeStateValue({name:"studentCalculatorModal.image", value:""})
      this.props.changeStateValue({name:"studentCalculatorModal.image_full_url", value:""})
    }else{
      if(this.props.oldestEnrollCount?.id && this.props.oldestEnrollCount?.id !=""){
        if(this.props.oldestEnrollCount?.file){
          this.props.changeStateValue({name:"studentCalculatorModal.file", value:this.props.oldestEnrollCount?.file})
          this.props.changeStateValue({name:"studentCalculatorModal.file_full_url", value:this.props.oldestEnrollCount?.file_full_url})
        }else if(this.props.oldestEnrollCount?.image){
          this.props.changeStateValue({name:"studentCalculatorModal.image", value:this.props.oldestEnrollCount?.image})
          this.props.changeStateValue({name:"studentCalculatorModal.image_full_url", value:this.props.oldestEnrollCount?.image_full_url})
        }
      }
    }
  }
  render() {
    const {studentCalculatorModal, lessonModes, adminPriceCalculator, studentEnrollPrices, lang, oldestEnrollCount} = this.props;
    let lessonModeOption = [];
    for(const data of lessonModes){
        if(data?.id !=23 && data?.id != 24 && data.courses.length !==0){
            lessonModeOption.push({
                label: data.title,
                value: data.id
            })
        }
        
    }
    // console.log(lessonModes)
    let coursesOption = [];
    const checkCourse = lessonModes.find(item=>item?.id == studentCalculatorModal?.lesson_mode_id);
    // console.log(lessonModes)
    if(checkCourse){
        for(const data of checkCourse?.courses){
            if(data.id!==36 && data?.id!==30 &&  data?.id!==35){
                coursesOption.push({
                    label: data?.title,
                    value: data?.id
                })
            }
        }
    }
    let studyMode =[
      {
          value: 'One to One',
          label:'One to One'
      },
      {
          value: 'Group Two',
          label: 'Group Two'
      },
      {
          value: 'Group Three',
          label: 'Group Three'
      },
     
      
    ];
    let classTime = [ 
    {
        label: "1 hour",
        value: 60
    },
    {
        label: "1.5 hours",
        value: 90
    },
    {
        label: "2 hours",
        value: 120
    },
    {
        label: "2.5 hours",
        value: 150
    },
    {
        label: "3 hours",
        value: 180
    },
    {
        label: "4 hours",
        value: 240
    }];
    let weekDay = [
      {
        label: "3 times a week",
        value: 1,
      },
      {
        label: "6 times a week",
        value: 2,
      },
    ]
    // console.log(studentCalculatorModal)
    // console.log(adminPriceCalculator)
    console.log(lessonModeOption)
    return (
        <div className='bg-white w-[700px] shadow rounded-[10px] max-[730px]:ml-[20px] max-[730px]:mr-[20px]'>
            {/* <h2 className='text-center txt-blue font-semibold text-[24px]'>Enrollment information</h2> */}
            <Header title="Enrollment information" closeBtn={()=>this.props.onClose()} />
            <div className='p-[20px]'>
              <div className='grid grid-cols-2 max-[600px]:grid-cols-1 gap-[20px] mt-[20px]'>
              {
                studentCalculatorModal?.group_type==="exists"?
                <DefaulData title="Lesson mode" value={studentCalculatorModal?.lesson_mode} />:
                <DropSearch selectData={this.onSelectLessonMode.bind(this)} required value={studentCalculatorModal.lesson_mode_id} placeholder="Lesson mode" name="studentCalculatorModal.lesson_mode_id"  options={lessonModeOption}  title="Lesson mode" /> 
              }
              {
                studentCalculatorModal?.group_type==="exists"?
                <DefaulData title="Course" value={coursesOption?.find(x=>x?.value ==studentCalculatorModal.course_id )?.label} />:
                <DropSearch required value={studentCalculatorModal.course_id} placeholder="Course" name="studentCalculatorModal.course_id"  options={coursesOption}  title="Course" /> 
              }
              {
                studentCalculatorModal?.group_type==="exists"?
                <DefaulData title="Study mode" value={studyMode?.find(item=>item?.value === studentCalculatorModal.study_mode)?.label} />:
                <DropSearch selectData={this.onSelectStudyMode.bind(this)} required value={studentCalculatorModal.study_mode} placeholder="Study mode" name="studentCalculatorModal.study_mode"  options={studyMode}  title="Study mode" /> 
              }
              {
                studentCalculatorModal?.group_type==="exists"?
                <DefaulData title="Lesson time" value={classTime?.find(item=>item?.value === studentCalculatorModal.lesson_hour)?.label} />:
                <DropSearch required value={studentCalculatorModal.lesson_hour} placeholder="Lesson time" name="studentCalculatorModal.lesson_hour"  options={classTime}  title="Lesson time" /> 
              }
               <DropSearch selectData={this.onSelectWeekDay.bind(this)} required value={studentCalculatorModal.week_day} placeholder="How often a week" name="studentCalculatorModal.week_day"  options={weekDay}  title="How often a week" /> 
            <div >
              {
                studentCalculatorModal?.count !=""?
                <div>
                 
                  <div className={`grid ${oldestEnrollCount?.count===0? "grid-cols-1":"grid-cols-2"} gap-[20px]`}>
                  {
                    studentCalculatorModal?.count !=""?
                    <div>
                       <span className='text-[14px] txt-light-black'>{lang?.num_lessons}</span>
                        <button className='h-[35px] w-full flex items-center gap-[10px]' onClick={this.onSelectCount.bind(this, studentCalculatorModal?.count, "new")}>
                          <input checked={studentCalculatorModal?.price_type == "new"} className='w-[20px] h-[20px]' type='checkbox' />
                          <span  className={`${studentCalculatorModal?.price_type == "new"?"border-blue-600":""} w-full bg-gray-100 text-gray-500 pl-[10px] h-[35px]  border-[1.5px] rounded-[6px] p-[5px] outline-none text-[15px] `}>{studentCalculatorModal?.count}</span>
                        
                      </button>
                    </div>
                   :null
                    // <DefaulData title={lang?.num_lessons} value={studentCalculatorModal?.count} />:null
                  }
                  {
                    studentCalculatorModal?.count !="" && oldestEnrollCount?.count !=0 ?
                   <div>
                    <span className='text-[14px] txt-light-black'>{lang?.remaining_count}</span>
                     <button className='h-[35px] w-full flex items-center gap-[10px]' onClick={this.onSelectCount.bind(this, oldestEnrollCount?.count, "old")}>
                     <input checked={studentCalculatorModal?.price_type == "old"} className='w-[20px] h-[20px]' type='checkbox' />
                     <span  className={`${studentCalculatorModal?.price_type == "old"?"border-blue-600":""}  w-full bg-gray-100 text-gray-500 pl-[10px] h-[35px] border-[1.5px] rounded-[6px] p-[5px] outline-none text-[15px] `}>{oldestEnrollCount?.count}</span>
                    </button>
                   </div>:null
                  }
                  
                </div>
                {
                  studentCalculatorModal.selected_count ==""?
                  <span className='text-red-700 mt-[4px] text-[12px] block w-full text-center'>Please select one of them</span>:null
                }
                </div>:null
              
              }
            
              
            </div>
              </div>
              <div className='mt-[20px] '>
                  <span className='text-[14px] font-regular txt-light-black'>Please, select a price</span>
                  
                  <div className='grid-full-100 '>
                    {
                      studentCalculatorModal?.price_type == "new"?
                      <>
                        {/* {
                        studentCalculatorModal.count && studentCalculatorModal.count !="" && studentCalculatorModal.count >=12 && studentCalculatorModal?.lesson_mode_id!="" && studentCalculatorModal?.study_mode !==""?
                        <button onClick={this.addFee.bind(this, this.calculateFee(studentCalculatorModal.count,  this.getPriceValue(adminPriceCalculator,studentCalculatorModal?.lesson_mode_id, studentCalculatorModal?.study_mode)))} className={`${this.calculateFee(studentCalculatorModal.count,  this.getPriceValue(adminPriceCalculator,studentCalculatorModal?.lesson_mode_id, studentCalculatorModal?.study_mode))==studentCalculatorModal?.fee ? "bg-blue text-white":"txt-blue "} h-[35px] p-[0px_10px] inline-flex items-center text-[14px] border border-[var(--blue)] rounded-[10px] font-semibold justify-center`}>
                          {this.calculateFee(studentCalculatorModal.count,  this.getPriceValue(adminPriceCalculator,studentCalculatorModal?.lesson_mode_id, studentCalculatorModal?.study_mode))}
                          <ManatSvg color={studentCalculatorModal?.fee ==this.calculateFee(studentCalculatorModal.count,  this.getPriceValue(adminPriceCalculator,studentCalculatorModal?.lesson_mode_id, studentCalculatorModal?.study_mode))?"white":"var(--blue)"} />
                          </button>:null
                        } */}
                        {
                        this.getFeeDistinc(this.calculateFee(studentCalculatorModal.count,  this.getPriceValue(adminPriceCalculator,studentCalculatorModal?.lesson_mode_id, studentCalculatorModal?.study_mode)),studentEnrollPrices, oldestEnrollCount?.fee, this.calculateFee(studentCalculatorModal.count,  this.getPriceValue(adminPriceCalculator,studentCalculatorModal?.lesson_mode_id, studentCalculatorModal?.study_mode)))
                          .map((data, i)=>{
                            return(
                              <button onClick={this.addFee.bind(this, data)} className={`${studentCalculatorModal?.fee ==data? "bg-blue text-white":"txt-blue "} h-[35px] p-[0px_10px] inline-flex items-center text-[14px] border border-[var(--blue)] rounded-[10px] font-semibold justify-center`} key={i}>{data} <ManatSvg color={studentCalculatorModal?.fee ==data?"white":"var(--blue)"} /> </button>
                            )
                          })
                        }
                      </>:null
                    }
                    {
                      studentCalculatorModal?.price_type == "old"?
                    
                        <button onClick={this.addFee.bind(this, oldestEnrollCount?.fee)}  className={`${studentCalculatorModal?.fee ==oldestEnrollCount?.fee? "bg-blue text-white":"txt-blue "} h-[35px] p-[0px_10px] inline-flex items-center text-[14px] border border-[var(--blue)] rounded-[10px] font-semibold justify-center`}>{oldestEnrollCount?.fee} <ManatSvg color={studentCalculatorModal?.fee ==oldestEnrollCount?.fee?"white":"var(--blue)"} /> </button>
                   :null
                    }
                    
                  </div>
                </div>
              
              <div className='flex mt-[20px] justify-between'>
                <button onClick={this.changePage.bind(this, "group")} className="h-[25px] bg-orange p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                Back
                </button>
                <button onClick={this.changePage.bind(this, "pay_params")} className="h-[25px] bg-blue p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                    Save & next
                </button>
            </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    studentCalculatorModal: state.Data.studentCalculatorModal,
    lessonModes: state.Data.lessonModes,
    adminPriceCalculator: state.Data.adminPriceCalculator,
    studentEnrollPrices: state.Data.studentEnrollPrices,
    lang: state.Data.lang,
    oldestEnrollCount: state.Data.oldestEnrollCount,
});
const mapDispatchToProps = {changeStateValue, getEnrollData, getLessonModes, getCourses, getOldestEnrollCount}
export default connect(mapStateToProps, mapDispatchToProps)(EnrollParams)
