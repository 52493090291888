import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getTeacherClass, getUserData, getTeacherClassCourse } from '../../actions/StaffAction';
import Header from './Header';
import DropSearch from '../DropSearch';
import { insertNewData } from '../../actions/MainAction';
import { bigAlert, mixinAlert } from '../../actions/SweetAlerts';
export class AddCheckinModal extends Component {
    state={
        btnDisabled:false,
        modes:[],
        lessonMode:'',
        teacher_name:'',
        course_id:''
    }
    componentDidMount(){
        this.props.getTeacherClass(this.props.data?.student_id, this.props.data?.date, this.props.data?.teacher_id)
       
        this.props.getUserData(this.props.data?.teacher_id)
        .then(resp=>{this.setState({teacher_name: resp?.full_name})});
        this.props.getTeacherClassCourse(this.props.data?.student_id, this.props.data?.date, this.props.data?.teacher_id)
        .then(resp=>this.setState({modes:resp}));
    }
    formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + '-' + minutes + ' ' + ampm;

        return strTime;
    }
    saveBtn=(data)=>{
        if(this.state.lessonMode !== "" && this.state?.course_id && this.state?.course_id !=""){
            this.setState({btnDisabled:true})
            const params = 
            {
                "event":`{\"name\":\"Class\",\"start\":1628168315358,\"timed\":true,\"color\":\"black\",\"details\":\"<table class=\\\"table table-borderless table-striped table-hover\\\"><tr><th>Teacher</th><td>${this.state.teacher_name}</td></tr><tr><th>Class</th><td>${this.state.lessonMode}</td></tr><tr><th>Start</th><td>${this.formatAMPM(new Date())}</td></tr></table>\"}`,
                "lesson_mode": `${this.state.lessonMode}`,
                "student_id": `${data?.student_id}`,
                "teacher_id": `${data?.teacher_id}`,
                "today":`${data?.date}`,
                "course_id":`${this.state?.course_id}`,
            }
            let url = "add-attendance-manually";
            if(this.props.user?.role == "teacher" || this.props.user?.role == "head_teacher"  || this.props.user?.role == "speaking_teacher"){
                url="attendances"
            }
            this.props.insertNewData(url, params, "checkin")
            .then(resp=>{
                this.setState({btnDisabled:false})
                this.props.closeBtn();
                this.props.getAfterChange()
                if(resp === "success"){
                    bigAlert(this.props.lang?.checkIn_submit, this.props.lang?.checkin_success, "success")
                }else{
                    // bigAlert("Oops...", this.props.lang?.already_checkin, "error")
                }
            })
        }else{
            mixinAlert("info", "Please select a lesson mode and course.")
        }
    }
    selectLessonMode=(mode)=>{
        this.setState({lessonMode:mode?.lesson_mode})
        this.setState({course_id:mode?.course_id})
    }
  render() {
    const {closeBtn=()=>{}, data, lang} = this.props;
    const {btnDisabled, modes} = this.state;
    let modeOption = [];
    for(const data of modes){
        modeOption.push({label:`${data?.lesson_mode} ${data?.course? '('+data?.course?.title+')':''}`, value:data})
    }
    
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[400px] max-sm:w-[90%] p- rounded-[10px]'>
                <Header closeBtn={closeBtn} title="Check-Ins" />
                <div className='p-[20px]'>
                    <div className='flex  items-start justify-center'>
                        <DropSearch selectData={this.selectLessonMode.bind(this)} options={modeOption} placeholder="Select a lesson mode" />
                    </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={()=>{closeBtn()}} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save}</button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {getTeacherClass, getUserData, insertNewData, getTeacherClassCourse}
export default connect(mapStateToProps, mapDispatchToProps)(AddCheckinModal)
