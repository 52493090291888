import React, { Component } from 'react'
import {withHooks} from "../../actions/withHooks";
import Countdown, {zeroPad} from 'react-countdown';
import { getToeflSpeakingExam, updateToeflExamSpeaking } from '../../actions/MainAction';
import HeadText from "../../components/toefl_exams/speakings/HeadText";
import SpeakingSection from "../../components/toefl_exams/speakings/SpeakingSection";
import Section2 from "../../components/toefl_exams/speakings/Section2";
import SpeakingParts from "../../components/toefl_exams/speakings/SpeakingParts";
import SectionPass from "../../components/toefl_exams/speakings/SectionPass";
import ReadingPart from "../../components/toefl_exams/speakings/ReadingPart";
import Listening from "../../components/toefl_exams/speakings/Listening";
import { connect } from 'react-redux';

export class ToeflSpeakingStart extends Component {
  state={
    continueBtn: false,
    onloadState: true,
    timerVisible: false,
    part: 0
}
componentDidMount(){
    this.props.getToeflSpeakingExam(this.props.params.id)
    .then(resp=>{
        setTimeout(()=>{
            this.setState({
                continueBtn: true
            })
        }, 3000)
    });
    window.scrollTo(0,0)
}

getMinute(part){
    if(+part == 0){
        return 0
    }else if(+part == 1){
        return 0
    }else if(+part == 2){
        return 0
    }else if(+part == 3){
        return  0
    }else if(+part == 4){
        return 0
    }else if(+part == 5){
        return 1
    }else if(+part == 6){
        return 0
    }else if(+part == 7){
        return 1
    }else if(+part == 12){
        return 1
    }else if(+part == 10){
        return 1
    }else if(+part == 15){
        return 1
    }else if(+part == 19){
        return 1
    }else {
        return 0
    }
}
continueBtn = (data,  e)=>{
    localStorage.removeItem('audio_current_duration')
    localStorage.removeItem('audio_duration')
    if(+data.part >= +this.props.studentToeflSpeakingExam.part){
        let answer = '';
        if(data.part == 4 || data.part == 9 || data.part == 14 || data.part == 18){
            answer = e
        }
        this.setState({
            part: data.part
        })
        this.props.updateToeflExamSpeaking(this.props.params.id, +e==2?3:+data.part+1, answer)
        .then(resp=>{
            this.setState({
                timerVisible: false
            })
            setTimeout(()=>{
                this.setState({
                    continueBtn: true,
                })
            }, 3000)
        })

        this.setState({
            continueBtn: false
        })
    }
    
   
    
}
getBeforeSection =( part) => {

    let answer = ''
    this.props.updateToeflExamSpeaking(this.props.params.id, part, answer)
    .then(resp=>{
        this.setState({
            timerVisible: false
        })
        setTimeout(()=>{
            
            this.setState({
                continueBtn: true
            })
        }, 3000)
    })

    this.setState({
        continueBtn: false
    })
}
renderer(data, { hours, minutes, seconds, completed}){

   
    if(completed){
        // window.location.reload();
        return <span>00:00:00</span>
    }else{
       
        return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
    
}
getExamTimer(data){
    
    if(data != ''){
       
        if(data.part == 1 || data.part == 5 || data.part == 10 ||  data.part == 15 || data.part == 19){
            setTimeout(()=>{
                this.setState({
                 timerVisible: true
                })
             }, 1000)
            return false
        }else if( data.part == 7 || data.part == 12 ){
            
            setTimeout(()=>{
               this.setState({
                timerVisible: true
               })
            }, 10000)
            return false
        }
    }else{
        return false
    }
}
getBtnVisible(data, btnVisible){
    if(btnVisible){
        if(data.part == 0 || data.part == 1 || data.part == 3 || data.part == 5 || data.part == 6 || data.part == 10 || data.part == 11 || data.part == 15 || data.part == 16 || data.part == 19 ){
            return true
        }
    }else{
        return false
    }
}
stopTimer(data, e){
    localStorage.removeItem('audio_current_duration')
    localStorage.removeItem('audio_duration')
    if(data.part == 5 || data.part == 7 ||  data.part == 12 ||data.part == 10 ||data.part == 15 ||data.part == 19){
        let answer = ''
        this.props.updateToeflExamSpeaking(this.props.params.id, +data.part+1, answer)
        .then(resp=>{
            this.setState({
                timerVisible: false
            })
            setTimeout(()=>{
                this.setState({
                    continueBtn: true,
                   
                })
            
            }, 3000)
        })

        this.setState({
            continueBtn: false,
            timerVisible: false
        })
    }
    
}
getPartVisible(data){

if(data.part == 4 || data.part == 5){
    return '1'
}else if(data.part == 6 || data.part == 7 || data.part == 8 || data.part == 9 || data.part == 10 ){
    return '2'
}else if(data.part == 11 || data.part == 12 || data.part == 13 || data.part == 14 || data.part == 15){
    return '3'
}else if(data.part == 16 || data.part == 17 || data.part == 18 || data.part == 19 ){
    return '4'
}else{
    return ''
}
}
componentWillUnmount(){
this.setState = (state,callback)=>{
    return;
};
}
  render() {
    const { studentToeflSpeakingExam } = this.props;
    const {continueBtn, timerVisible, part} = this.state;
    return (
      <div className='student_toefl_exam_container'>
            <div className='student_toefl_exam_timer'>
                <p></p>
                {
                   this.getExamTimer(studentToeflSpeakingExam) || timerVisible?
                    <span>
                        <Countdown date={new Date(studentToeflSpeakingExam.start_date).getTime() + this.getMinute(studentToeflSpeakingExam?.part)*60000} renderer={this.renderer.bind(this,studentToeflSpeakingExam)}
                            onComplete={this.stopTimer.bind(this, studentToeflSpeakingExam)}>
                        </Countdown>
                    </span>: null
                    }
                {
                    this.getBtnVisible(studentToeflSpeakingExam, continueBtn) ?
                    <button onClick={this.continueBtn.bind(this, studentToeflSpeakingExam, studentToeflSpeakingExam.part == 1?'2':'')} className='toefl_exam_continue_btn'>Continue</button>:<p></p>
                }
                
            </div>

            {
            studentToeflSpeakingExam != ''?
            <div className='student_toefl_exam_body'>
                {
                    this.getPartVisible(studentToeflSpeakingExam) != ''?
                    <h3 style={{ marginBottom: '20px' }} className='toefl_speaking_part'>Question {this.getPartVisible(studentToeflSpeakingExam)} of 4</h3>:null
                }
                
                {
                    studentToeflSpeakingExam.part == '0'?
                    <HeadText
                        text={"Please make sure your headset is on. Follow the instruction on each screen. Be sure that your microphone is properly positioned and adjusted to allow for the best possible recording. Speak directly into the microphone and in your normal speaking volume."} 
                    />:
                    studentToeflSpeakingExam.part == '1'?
                    <SpeakingSection 
                        title="Adjusting the Microphone"
                        p1="In order to adjust your microphone volume, please answer the practice question below, using your normal speaking tone and volume."
                        p2="You will have 15 seconds to speak. If you finish responding before 15 seconds are finished, you can click on Stop Recording. After you are done recording, click on Playback Response and listen to your recording. If you cannot hear your response clearly, make necessary adjustments and repeat the microphone check."
                        p3="We do not recommend the use of a microphone with a voice activation feature. If you use a microphone with a voice activation feature, the first few seconds of your responses may not be recorded."
                        section1Desc={true}
                        time="15"
                        continueBtn={this.continueBtn}
                        record={true}
                    />:
                    studentToeflSpeakingExam.part == '2'?
                    <Section2  
                        continueBtn={this.continueBtn} 
                        getBeforeSection={this.getBeforeSection}
                    />:
                    studentToeflSpeakingExam.part == '3'?
                    <SpeakingSection 
                        title="Speaking Section Directions"
                        p1="In this speaking practice test, you will be able to demonstrate your ability to speak about a variety of topics. You will answer 4 questions by speaking into a microphone. Answer each of the questions as completely as possible."
                        p2="In question 1, you will speak about familiar topics. Your response will be scored on your ability to speak clearly and coherently about the topics."
                        p3="In questions 2 and 3 you will first read a short text. The text will go away and you will then listen to a talk on the same topic. You will then be asked a question about what you have read and heard. You will need to combine appropriate information from the test and the talk to provide a complete answer to the question. Your response will be scored on your ability to speak clearly and coherently and on your ability to accurately convey information about what you have read and heard."
                        p4="In question 4, you will listen to part of a lecture. You will then be asked a question about what you have heard. Your response will be scored on your ability to speak clearly and coherently and on your ability to accurately convey information about what you heard."
                        p5="In questions 2 through 4, you may take notes while you read and while you listen to the conversations and lectures. You may use your notes to help prepare your response."
                        p6="Listen carefully to the directions for each question. The directions will not be written on the screen. For each question, you will be given a short time to prepare your responses (15 to 30 seconds, depending on the question.) A clock will show how much preparation time is remaining. When the preparation time is up, you will be told to begin your response. A clock will show how much response time is remaining. A message will appear on the screen when the response time has ended."
                        p7="In this practice test, you can click Stop Recording to stop the recording of your response."
                        p8="Please note that the Stop Recording button is available only for this practice test. It will NOT be available during your official TOEFL exam."
                        section1Desc={false}
                        time="15"
                        continueBtn={this.continueBtn}
                        record={false}
                    />:
                    studentToeflSpeakingExam.part == '4'?
                    <SpeakingParts 
                        title={studentToeflSpeakingExam.part_1_reading}
                        prepTime={15} 
                        recordTime={45} 
                        continueBtn={this.continueBtn}
                    />:
                    studentToeflSpeakingExam.part == '5'?
                    <SpeakingSection 
                        p1="Your response time has ended. Stop speaking now."
                        p2="This is end of independent Speaking 1. Integrated Speaking 2 will begin automatically in 60 seconds."
                        section1Desc={false}
                        continueBtn={this.continueBtn}
                        record={false}
                    />: studentToeflSpeakingExam.part == '6'?
                    <SectionPass  continueBtn={this.continueBtn} part="2" />:
                    studentToeflSpeakingExam.part == '7'?
                    <ReadingPart 
                        title={studentToeflSpeakingExam.part_2_reading_title}
                        desc={studentToeflSpeakingExam.part_2_reading_description }
                        time={50}
                    />:studentToeflSpeakingExam.part == '8'?
                    <Listening continueBtn={this.continueBtn} examData={studentToeflSpeakingExam} title={''} url={studentToeflSpeakingExam.part_2_listening_full_url} />:
                    studentToeflSpeakingExam.part == '9'?
                    <SpeakingParts 
                    title={studentToeflSpeakingExam.part_2_speaking_question}
                    prepTime={30} 
                    recordTime={60} 
                    continueBtn={this.continueBtn}
                />:studentToeflSpeakingExam.part == '10'?
                <SpeakingSection 
                        p1="Your response time has ended. Stop speaking now."
                        p2="This is end of independent Speaking 1. Integrated Speaking 2 will begin automatically in 60 seconds."
                        section1Desc={false}
                        continueBtn={this.continueBtn}
                        record={false}
                    />
                    :studentToeflSpeakingExam.part == '11'?
                <SectionPass  continueBtn={this.continueBtn} part="3" />:
                studentToeflSpeakingExam.part == '12'?
                <ReadingPart 
                    title={studentToeflSpeakingExam.part_3_reading_title}
                    desc={studentToeflSpeakingExam.part_3_reading_description }
                    time={50}
                />:studentToeflSpeakingExam.part == '13'?
                    <Listening continueBtn={this.continueBtn} examData={studentToeflSpeakingExam} title={''} url={studentToeflSpeakingExam.part_3_listening_full_url} />:
                    studentToeflSpeakingExam.part == '14'?
                    <SpeakingParts 
                    title={studentToeflSpeakingExam.part_3_speaking_question}
                    prepTime={30} 
                    recordTime={60} 
                    continueBtn={this.continueBtn}
                />:studentToeflSpeakingExam.part == '15'?
                <SpeakingSection 
                      
                    p1="Your response time has ended. Stop speaking now."
                    p2="This is end of independent Speaking 3. Integrated Speaking 4 will begin automatically in 60 seconds."
                    // p3="If you do not wish to wait, click Confirm Response"
                    
                    section1Desc={false}
                    continueBtn={this.continueBtn}
                    record={false}
                />
                :studentToeflSpeakingExam.part == '16'?
                <SectionPass  continueBtn={this.continueBtn} part="4" />:
                studentToeflSpeakingExam.part == '17'?
                <Listening continueBtn={this.continueBtn} examData={studentToeflSpeakingExam} title={''} url={studentToeflSpeakingExam.part_4_listening_full_url} />:
                studentToeflSpeakingExam.part == '18'?
                <SpeakingParts 
                title={studentToeflSpeakingExam.part_4_speaking_question}
                prepTime={20} 
                recordTime={60} 
                continueBtn={this.continueBtn}/>:
                studentToeflSpeakingExam.part == '19'?
                <SpeakingSection 
                      
                    p1="Your response time has ended. Stop speaking now."
                    p2="This is end of independent Speaking 4. This test will end automatically in 60 seconds"
                    // p3="If you do not wish to wait, click Confirm Response"
                    
                    section1Desc={false}
                    continueBtn={this.continueBtn}
                    record={false}
                />:null
                }
            </div>:null
            }

        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  studentToeflSpeakingExam: state.Data.studentToeflSpeakingExam
});
const mapDispatchToProps = {getToeflSpeakingExam, updateToeflExamSpeaking}
export default connect(mapStateToProps, mapDispatchToProps)( withHooks(ToeflSpeakingStart))
