import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import Header from '../../../components/header/Header';
import StudentEditData from './StudentEditData';
import { getUserData, getCoursesByStudent, getStExamException, getCoachException, getEndOfExamExceptions,getFailedStudents, getStudentAllCourses } from '../../../actions/StaffAction';
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer';
import StudentEnrolls from './StudentEnrolls';
import UserSettingSvg from '../../../components/svg/UserSettingSvg';
import CourseEnrollSvg from '../../../components/svg/CourseEnrollSvg';
import TeacherEnroll from '../../../components/svg/TeacherEnroll';
import TeacherEnrollPage from './TeacherEnrollPage';
import PlusSvg from '../../../components/svg/PlusSvg';
import CardEditSvg from '../../../components/svg/CardEditSvg';
import ExEceptionSvg from '../../../components/svg/ExEceptionSvg';
import ExamExceptionApplyModal from '../../../components/modals/ExamExceptionApplyModal';
import CoachExceptionModal from '../../../components/modals/CoachExceptionModal';
import ContractSvg from '../../../components/svg/ContractSvg';
import ContractModal from '../../../components/modals/ContractModal';
import { getStudentContracts } from '../../../actions/StaffAction';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { Link } from 'react-router-dom';
import { insertNewData } from '../../../actions/MainAction';
import StudentOnlineSvg from '../../../components/svg/StudentOnlineSvg';
import EndOfExceptionModal from '../../../components/modals/EndOfExceptionModal';
import StudentCalculatorModal from './student_calculator/StudentCalculatorModal';
import FailedStudentExcpModal from '../../../components/modals/FailedStudentExcpModal';
export class EditStudent extends Component {
    state={
        page: '',
        courses:[],
        teachers:[],
        student:'',
        teacherId:'',
        contractModal:false,
        contracts:[],
        endOfExceptionModal:false,
        calculatorModal:false,
        failedStudentModal:false
    }
    componentDidMount(){
        if(this.props.type === "edit" && this.props.params?.id){
            this.props.getStExamException(this.props.params?.id)
            this.props.getCoachException(this.props.params?.id)
            this.props.getStudentAllCourses(this.props.params?.id)
            this.props.getUserData(this.props.params.id)
            .then(resp=>{
                // console.log(resp)
                this.setState({teachers: resp?.teacher, student:resp})
                this.props.changeStateValue({name:'editStudentParams', value: resp})
            })
            this.props.getCoursesByStudent(this.props.params.id)
            .then(resp=>{this.setState({courses:resp})})
            this.props.getStudentContracts(this.props.params?.id)
            .then(resp=>{
                this.setState({contracts:resp})
            })
            this.props.getEndOfExamExceptions("single", this.props.params.id)
            this.props.getFailedStudents("single", this.props.params.id)
           
        }else{
            this.props.resetStateValue({name:'editStudentParams'})
            this.props.changeStateValue({name:'editStudentParams.company_id', value: this.props.user.company_id})
        }
    }
    
    getAfterChange=()=>{
        setTimeout(()=>{
            if(this.props.type === "edit" && this.props.params?.id){
                this.props.getUserData(this.props.params.id)
                .then(resp=>{
                    this.setState({teachers: resp?.teacher, student:resp})
                    this.props.changeStateValue({name:'editStudentParams', value: resp})
                })
                this.props.getCoursesByStudent(this.props.params.id)
                .then(resp=>{this.setState({courses:resp})})
               
            }else{
                this.props.resetStateValue({name:'editStudentParams'})
                this.props.changeStateValue({name:'editStudentParams.company_id', value: this.props.user.company_id})
            }
        }, 100)
    }
    examExceptionBtn=(data)=>{
        if(this.props.type === "edit" && this.props.params?.id){
            if(data==""){
                this.props.changeStateValue({
                    name: 'examExceptionModalData',
                    value: {
                        position:true,
                        image:null,
                        student_id: this.props.params.id,
                        status: '0',
                    }
                })

            }
        }
    }
    coachExceptionBtn=(data)=>{
        if(this.props.type === "edit" && this.props.params?.id){
            if(data==""){
                this.props.changeStateValue({
                    name: 'coachExceptionModal',
                    value: {
                        position:true,
                        image:null,
                        student_id: this.props.params.id,
                        status: '0',
                        count:''
                    }
                })

            }
        }
    }

    openStuntTeacher=(item, e)=>{
        e.preventDefault();
        this.setState({page:'teacher', teacherId:item?.teacher_id});
    //    this.setState({calculatorModal:true})
    }
   closeContractModal=()=>{
    this.setState({contractModal:false});
    this.props.getStudentContracts(this.props.params?.id)
    .then(resp=>{
        this.setState({contracts:resp})
    })
   }

   getCourse=(course_id, courses)=>{
    const check = courses?.find(x=> x?.id == course_id);
    if(check){
        return check?.title;
    }else{
        return '';
    }
   }
   changeTypeBtn=(user_id)=>{
    confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, change to online")
    .then(alertResp=>{
        if(alertResp === "success"){
        this.props.insertNewData(`student-online-type`, {user_id:user_id, status:'0' })
        .then(resp=>{
            if(resp == "success"){
                this.props.getUserData(this.props.params.id)
                .then(resp=>{
                    this.setState({teachers: resp?.teacher, student:resp})
                    this.props.changeStateValue({name:'editStudentParams', value: resp})
                })
                mixinAlert("success", "Changed student type to online")
            }else{
                mixinAlert("error", "Something went wrong")
            }
        })
        }
    })
   }

   endOfCourseBtn=()=>{
    this.setState({endOfExceptionModal:true})
   }
   failedStudentBtn=()=>{
    this.setState({failedStudentModal:true})
   }
  render() {
    const {type, lang, examExceptionModalData, examException, coachExceptionModal, coachException, user, endOfExamExceptions, studentAllCourses, failedStudentsData} = this.props;
    const {page, courses, teachers, student, teacherId, contractModal, contracts, endOfExceptionModal, calculatorModal, failedStudentModal} = this.state;
    // console.log(failedStudentsData)
    return (
        <div className='w-full pl-[27px] pr-[27px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
            <Header title={type === "edit"? lang?.edit_student: lang?.create_student} />
            {
                    student !="" && user?.role == "super_admin"?
                    <Link className='bg-blue text-white font-semibold text-[13px] p-[5px_8px] rounded-[5px] mb-[20px] inline-block' to={`/students/${student?.id}`} >Go Info</Link>:null
                }
            {
                page === "personal"?
                <StudentEditData  closeModal={()=>{this.setState({page:''}); this.getAfterChange()}} />:
                page === "enroll"?
                <StudentEnrolls  closeModal={()=>{this.setState({page:''}); this.getAfterChange()}} />:
                page === "teacher"?
                <TeacherEnrollPage teacherId={teacherId} student={student} closeModal={()=>{this.setState({page:''}); this.getAfterChange()}} />:null
            }
            <div className='grid grid-full-220 gap-[20px] mt-[42px] items-start'>
                <div onClick={()=>this.setState({page:'personal'})} className='bg-white rounded-[10px] p-[20px] cursor-pointer shadow relative'>
                    <span className='w-[30px] h-[30px] rounded-full bg-gray-100 inline-flex items-center justify-center absolute right-[20px]'>
                       {this.props.type === "edit"?<CardEditSvg />: <PlusSvg />} 
                    </span>
                    <div className='flex justify-center'>
                       <span className='bg-light-blue p-[10px] rounded-full w-[60px] h-[60px] inline-flex items-center justify-center'>
                        <UserSettingSvg />
                       </span>
                    </div>
                    <h3 className='text-center mt-[20px] text-[16px] font-semibold txt-light-black'>{student!==""?student?.full_name:lang?.student_per_info}</h3>
                    <div className='flex justify-center'>
                    {this.props.type === "edit"? <span  className='txt-blue font-regular text-[12px] mt-[7px] border border-[var(--blue)] p-[4px] rounded-[6px]'>{lang?.completed}</span>:null}
                    </div>
                    
                </div>
                <div onClick={this.props.type === "edit" ? ()=>this.setState({page:'enroll'}):()=>{}} className={`relative bg-white rounded-[10px] p-[20px] cursor-pointer shadow ${this.props.type !== "edit"?"border border-red-600":"" }`}>
                    <span className='w-[30px] h-[30px] rounded-full bg-gray-100 inline-flex items-center justify-center absolute right-[20px]'>
                        <PlusSvg />
                    </span>
                    <div className='flex justify-center'>
                       <span className='bg-light-blue p-[10px] rounded-full w-[60px] h-[60px] inline-flex items-center justify-center'>
                        <CourseEnrollSvg />
                       </span>
                    </div>
                    <h3 className='text-center mt-[20px] text-[16px] font-semibold txt-light-black'>{lang?.course_enroll}</h3>
                    <ul>
                        {
                            courses.filter(x=> !teachers?.find(y=>y?.course_id == x?.id))?.map((item, i)=>{
                                return(
                                    <li key={i} className='txt-blue font-regular text-[14px] mt-[7px]'>{i+1}. {item?.title}</li>
                                )  
                            })
                        }
                        
                    </ul>
                </div>
                <div  onClick={this.props.type === "edit" ? ()=> this.setState({contractModal:true}):()=>{}} className={`relative bg-white rounded-[10px] p-[20px] cursor-pointer shadow ${this.props.type !== "edit" || contracts.length==0 ?"border border-red-600":"" }`}>
                        <span className='w-[30px] h-[30px] rounded-full bg-gray-100 inline-flex items-center justify-center absolute right-[20px]'>
                            <PlusSvg />
                        </span>
                        <div  className='flex justify-center'>
                        <span className='bg-light-blue p-[10px] rounded-full w-[60px] h-[60px] inline-flex items-center justify-center'>
                            <ContractSvg />
                        </span>
                        </div>
                        <h3 className='text-center mt-[20px] text-[16px] font-semibold txt-light-black'>
                            {lang?.contracts}
                        </h3>
                        <div className='flex justify-center'>
                            {contracts.length!==0? <span  className='txt-blue font-regular text-[12px] mt-[7px] border border-[var(--blue)] p-[4px] rounded-[6px]'>{lang?.uploaded}</span>:null}
                        </div>
                        
                </div>
                <div  className={`relative bg-white rounded-[10px] p-[20px] cursor-pointer shadow ${this.props.type !== "edit" || contracts.length==0?"border border-red-600":"" }`}>
                    <span  onClick={this.props.type === "edit" && contracts.length!==0? ()=>this.setState({calculatorModal:true}): ()=>{mixinAlert("warning", "Please upload student contract first and then assign a teacher.")}} className='w-[30px] h-[30px] rounded-full bg-gray-100 inline-flex items-center justify-center absolute right-[20px]'>
                        <PlusSvg />
                    </span>
                    <div  onClick={this.props.type === "edit" && contracts.length!==0 ? ()=>this.setState({calculatorModal:true}):()=>{mixinAlert("warning", "Please upload student contract first and then assign a teacher.")}} className='flex justify-center'>
                       <span className='bg-light-blue p-[10px] rounded-full w-[60px] h-[60px] inline-flex items-center justify-center'>
                        <TeacherEnroll />
                       </span>
                    </div>
                    <h3  onClick={this.props.type === "edit" && contracts.length!==0? ()=>this.setState({calculatorModal:true}):()=>{mixinAlert("warning", "Please upload student contract first and then assign a teacher.")}} className='text-center mt-[20px] text-[16px] font-semibold txt-light-black'>{lang?.teacher_enroll}</h3>
                    <ul>
                        {
                            teachers.map((item, i)=>{
                                return(
                                    <li onClick={contracts.length!==0?()=> this.setState({calculatorModal:true}):()=>{mixinAlert("warning", "Please upload student contract first and then assign a teacher.")}} key={i} className='txt-blue font-regular text-[14px] mt-[7px] rounded-[4px] p-[3px] hover:bg-gray-100 flex flex-col bg-gray-100'>
                                        <span>{i+1}. {item?.first_name+" "+item?.last_name}</span>
                                        <span className='ml-[10px]'>{this.getCourse(item?.course_id, courses)}</span>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>
                </div>
            </div>

            <div className=' mt-[42px]'>
                <h1 className='text-[20px] txt-blue font-semibold'>{lang?.expections}</h1>
                <div className='grid grid-full-220 gap-[20px] items-start mt-[10px]'>
                    <div  onClick={this.props.type === "edit" ? this.examExceptionBtn.bind(this, examException):()=>{}} className={`relative bg-white rounded-[10px] p-[20px] cursor-pointer shadow ${this.props.type !== "edit"?"border border-red-600":"" }`}>
                        <span className='w-[30px] h-[30px] rounded-full bg-gray-100 inline-flex items-center justify-center absolute right-[20px]'>
                            <PlusSvg />
                        </span>
                        <div  className='flex justify-center'>
                        <span className='bg-light-blue p-[10px] rounded-full w-[60px] h-[60px] inline-flex items-center justify-center'>
                            <ExEceptionSvg />
                        </span>
                        </div>
                        <h3 className='text-center mt-[20px] text-[16px] font-semibold txt-light-black'>
                            {
                                examException===""?
                                lang?.appeal_exception:
                                examException?.status==0?
                                lang?.appeal_pending:
                                examException?.status==1?
                                lang?.applead_accepted:
                                examException?.status==2?
                                lang?.applead_rejected:
                                lang?.appeal_exception
                            }
                            
                        </h3>
                        
                    </div>
                    <div  onClick={this.props.type === "edit" ? this.coachExceptionBtn.bind(this, coachException):()=>{}} className={`relative bg-white rounded-[10px] p-[20px] cursor-pointer shadow ${this.props.type !== "edit"?"border border-red-600":"" }`}>
                        <span className='w-[30px] h-[30px] rounded-full bg-gray-100 inline-flex items-center justify-center absolute right-[20px]'>
                            <PlusSvg />
                        </span>
                        <div  className='flex justify-center'>
                        <span className='bg-light-blue p-[10px] rounded-full w-[60px] h-[60px] inline-flex items-center justify-center'>
                            <ExEceptionSvg />
                        </span>
                        </div>
                        <h3 className='text-center mt-[20px] text-[16px] font-semibold txt-light-black'>
                            {
                                coachException===""?
                                lang?.coach_exception:
                                coachException?.status==0?
                                lang?.coach_app_pending:
                                coachException?.status==1?
                                lang?.coach_app_accept:
                                coachException?.status==2?
                                lang?.coach_app_reject:
                                lang?.coach_exception
                            }
                            
                        </h3>
                        
                    </div>
                    <div  onClick={this.props.type === "edit" ? this.endOfCourseBtn.bind(this):()=>{}} className={`relative bg-white rounded-[10px] p-[20px] cursor-pointer shadow ${this.props.type !== "edit"?"border border-red-600":"" }`}>
                        <span className='w-[30px] h-[30px] rounded-full bg-gray-100 inline-flex items-center justify-center absolute right-[20px]'>
                            <PlusSvg />
                        </span>
                        <div  className='flex justify-center'>
                        <span className='bg-light-blue p-[10px] rounded-full w-[60px] h-[60px] inline-flex items-center justify-center'>
                            <ExEceptionSvg />
                        </span>
                        </div>
                        <ul className='mt-[20px] flex flex-col gap-[7px]'>
                            {
                                endOfExamExceptions?.length == 0?
                                <li className='text-[15px] font-semibold txt-light-black text-center'>End of Course Exception</li>:null
                            }
                            {
                                endOfExamExceptions?.map((data, i)=>{
                                    return(
                                        <li className='text-[15px] font-semibold txt-light-black' key={i}>{data?.lesson?.title} {data?.status==0?"Waiting": data?.status==1?"Accepted":"Rejected"}</li>
                                    )
                                })
                            }
                        </ul>
                        
                    </div>
                    <div  onClick={this.props.type === "edit"? this.failedStudentBtn.bind(this):()=>{}} className={`relative bg-white rounded-[10px] p-[20px] cursor-pointer shadow ${this.props.type !== "edit"?"border border-red-600":"" }`}>
                        <span className='w-[30px] h-[30px] rounded-full bg-gray-100 inline-flex items-center justify-center absolute right-[20px]'>
                            <PlusSvg />
                        </span>
                        <div  className='flex justify-center'>
                        <span className='bg-light-blue p-[10px] rounded-full w-[60px] h-[60px] inline-flex items-center justify-center'>
                            <ExEceptionSvg />
                        </span>
                        </div>
                        <ul className='mt-[20px] flex flex-col gap-[7px]'>
                            {
                                failedStudentsData?.length == 0?
                                <li className='text-[15px] font-semibold txt-light-black text-center'>Failed student Exception</li>:null
                            }
                            {
                                failedStudentsData?.map((data, i)=>{
                                    return(
                                        <li className='text-[15px] font-semibold txt-light-black' key={i}>{data?.course?.title} {data?.status==0?"Waiting": data?.status==1?"Accepted":"Rejected"}</li>
                                    )
                                })
                            }
                        </ul>
                        
                    </div>

                </div>
            </div>
            {
                examExceptionModalData?.position? <ExamExceptionApplyModal />:null
            }
            {
                coachExceptionModal?.position? <CoachExceptionModal />:null
            }
            {contractModal?<ContractModal closeBtn={this.closeContractModal.bind(this)} student={student} />:null}
            {endOfExceptionModal?<EndOfExceptionModal closeBtn={()=>{this.setState({endOfExceptionModal:false})}} student={student} courses={studentAllCourses} endOfExamExceptions={endOfExamExceptions} />:null}
            {failedStudentModal?<FailedStudentExcpModal closeBtn={()=>{this.setState({failedStudentModal:false})}} selecteds={failedStudentsData} student={student}  />:null}
            {calculatorModal?<StudentCalculatorModal onClose={()=>{this.setState({calculatorModal:false}); this.getAfterChange()}} student={student} />:null}
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    user: state.Data.user,
    lang: state.Data.lang,
    examExceptionModalData: state.Data.examExceptionModalData,
    examException: state.Data.examException,
    coachExceptionModal: state.Data.coachExceptionModal,
    coachException: state.Data.coachException,
    endOfExamExceptions: state.Data.endOfExamExceptions,
    studentAllCourses: state.Data.studentAllCourses,
    failedStudentsData: state.Data.failedStudentsData,
});
const mapDispatchToProps = {getUserData, changeStateValue, resetStateValue,getFailedStudents, getStudentContracts, getCoursesByStudent,getStudentAllCourses, getStExamException, getCoachException, insertNewData, getEndOfExamExceptions}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(EditStudent))
