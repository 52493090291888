import React, { Component } from 'react'

export class UploadExamSvg extends Component {
  render() {
    const {size=30} = this.props
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 19a4 4 0 0 0 0-8h-.023A5.5 5.5 0 1 0 6 10.514 4.287 4.287 0 0 0 6.858 19M12 19v-5m0 0 2 2m-2-2-2 2"
        />
      </svg>
    )
  }
}

export default UploadExamSvg
