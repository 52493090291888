import React, { Component } from 'react'
import PinSvg from '../svg/PinSvg'
import { connect } from 'react-redux';
import { getSupportPins } from '../../actions/MessageAction';
import MessagePinnedModal from '../modals/MessagePinnedModal';
export class BoxHeader extends Component {
  state={
    pins:[]
  }
  getPins=()=>{
    this.props.getSupportPins()
    .then(resp=>{this.setState({pins:resp})})
  }
  render() {
    const {pins} = this.state;
    // console.log(pins)
    return (
        <div className='flex items-center justify-between h-[65px]'>
            <div>
                <h3 className='text-[20px] font-bold txt-light-black'>Support Team Group</h3>
            </div>
           <div>
                <button onClick={this.getPins.bind(this,)} className='bg-[#F8FAFC] max-[500px]:w-[30px] max-[500px]:h-[25px] max-[500px]:min-h-[25px] w-[58px] min-h-[44px] h-[44px] mt-[-5px] inline-flex items-center justify-center rounded-[10px]'>
                    <PinSvg color="var(--blue)" size={20} />
                </button>
           </div>
           {pins.length !=0?<MessagePinnedModal messages={pins} closeModalBtn={()=>this.setState({pins:[]})} />:null}
        </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {getSupportPins}
export default connect(mapStateToProps, mapDispatchToProps)(BoxHeader)
