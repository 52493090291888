import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class TitleComplete extends Component {
  render() {
    const {title, titleLink=""} = this.props
    return (
      <div>
      {
        title?
        title.length <22?
         <Link target='_blank' to={titleLink} onClick={(e)=>{if(titleLink==""){e.preventDefault()}}} className='txt-light-black text-[12px] font-bold underline underline-offset-1'>{title}</Link>:
         <div className='relative completeText2'>
          <Link  target='_blank' to={titleLink}  onClick={(e)=>{if(titleLink==""){e.preventDefault()}}} className='txt-light-black text-[12px] font-bold underline underline-offset-1 minitext'>{title.substring(0, 22)}...</Link>
          <Link  target='_blank' to={titleLink}  onClick={(e)=>{if(titleLink==""){e.preventDefault()}}} className='txt-light-black text-[12px] font-bold underline underline-offset-1 longtext'>{title}</Link>
        </div>:null
      }
       
      </div>
    )
  }
}

export default TitleComplete
