import React, { Component } from 'react'
import Recorder from './Recorder'

export class SpeakingParts extends Component {
  render() {
    const {prepTime, recordTime, continueBtn, title} = this.props
    return (
      <div>
        
        <p className='toefl_speaking_part_text' dangerouslySetInnerHTML={{ __html: title}}></p>

        <div className='toefl_speaking_part_container'>
                <p className='prep_time'>Preperation Time: <span>{prepTime} Seconds</span></p>
                <p className='prep_time'>Response Time: <span>{recordTime} Seconds</span></p>
            <Recorder time={recordTime} continueBtn={continueBtn} prepTime={prepTime}/>
        </div>
      </div>
    )
  }
}

export default SpeakingParts