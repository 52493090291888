import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getExamsQuestions, insertNewData, getLevelCheckExam } from '../../../actions/PublicActions';
import SectionHeader from '../../components/SectionHeader';
import ParentDescription from '../../../components/exams/ParentDescription';
import Audio from '../../../components/exams/Audio';
import Video from '../../../components/exams/Video';
import Image from '../../../components/exams/Image';
import QuestionDescription from '../../../components/exams/QuestionDescription';
import { getMistralModel } from '../../../actions/robotActions';
import { getWritingPublickLevelCheck } from '../../../actions/getReviewGpt';
import { changeStateValue } from '../../../redux/MainReducer';
import { partExamsScore } from '../../components/getLevel';
import { bigAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { withHooks } from '../../../actions/withHooks';
import { Link } from 'react-router-dom';
import { getGrades } from '../../../actions/getRole';
export class WritingExam extends Component {
    state={
        level:1,
        level1Score:'',
        level2Score:'',
        level3Score:'',
        btnDisable:false,
        text:'',
    }
    componentDidMount(){
        this.props.getExamsQuestions('writing', this.state.level);
        window.scrollTo(0,0)
    }
    getWordCount(s){
        s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
        s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
        s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
        return s.split(' ').filter(function(str){return str!="";}).length;
    }
    nextBtn=(text)=>{
        this.setState({btnDisable:true});
        this.props.changeStateValue({name:"loader", value:true})
        this.props.getMistralModel(getWritingPublickLevelCheck(text, this.props.examQuestions?.questions?.last_prompt, this.props.examQuestions?.questions?.description))
        .then(resp=>{
            this.props.changeStateValue({name:"loader", value:false})
            this.setState({btnDisable:false})
            let score = 1;
            try{
                score = JSON.parse(resp)?.grade;
            }catch(er){
                score =  getGrades(resp)
                // mixinAlert("error", "Something went wrong. Please try again")
                // return
            }
            
            this.setState({text:''})
            const params = partExamsScore(this.state.level, score);
            if(params?.type === "contuniue"){
                this.setState({[`level${params?.level}Score`]: score, level: params?.level+1});
                this.props.getExamsQuestions('writing', params?.level+1);
                bigAlert("Done!", `You have passed previous level. Now you will take next level test`, "success");
            }else if(params.type === "complete"){
               
                let scores = {
                    beginner_writing_score: this.state.level1Score,
                    middle_writing_score: this.state.level2Score,
                    advanced_writing_score: this.state.level3Score,
                    writing_complete:'1',
                    [`${params?.level==1? 'beginner_writing_score':params?.level==2? 'middle_writing_score':'advanced_writing_score'}`]: score
                }
                let formData = new FormData();
                for(const property in scores){
                    if(scores[property])
                        formData.append(property, scores[property]);
                }
                this.props.insertNewData('set-exam-score',formData)
                .then(resp=>{
                    bigAlert("Completed!", `Exam Completed successfully`, "success");
                    this.props.getLevelCheckExam()
                    this.props.navigate("/level-check")
                })
            }
        })
    }
  render() {
    const {examQuestions} = this.props;
    const {text, btnDisable} = this.state;
    return (
        <div className='pt-[130px] container ml-auto mr-auto'>
             <SectionHeader  line={false} title={examQuestions?.title} />
            {
                examQuestions !==""?
                <div className='bg-white shadow-[0_0px_4px_1px_rgba(0,0,0,0.25)] p-[20px] rounded-[10px]'>
                    {
                        examQuestions?.description?
                        <ParentDescription text={ examQuestions?.description} />:null
                    }
                    {
                        examQuestions?.audio_full_url?
                        <Audio url={examQuestions?.audio_full_url} />:null
                    }
                    {
                        examQuestions?.video_full_url?
                        <Video url={examQuestions?.video_full_url} />:null
                    }
                    {
                        examQuestions?.image_full_url?
                        <Image url={examQuestions?.image_full_url} />:null
                    }
              
                    <div className='mt-[20px]'>
                        <h4 className='txt-blue font-bold text-[18px]'>{examQuestions?.questions?.title}</h4>
                        {
                            examQuestions?.questions?.description?
                            <QuestionDescription text={examQuestions?.questions?.description} />:null
                        }
                        {
                            examQuestions?.questions?.audio_full_url?
                            <Audio url={examQuestions?.questions?.audio_full_url} />:null
                        }
                        
                        {
                           examQuestions?.questions?.video_full_url?
                            <Video url={examQuestions?.questions?.video_full_url} />:null
                        }
                        {
                           examQuestions?.questions?.image_full_url?
                            <Image url={examQuestions?.questions?.image_full_url} />:null
                        }
                        <div className='flex justify-end'>
                            <span className='txt-blue font-semibold'>Word Count: {this.getWordCount(text)}</span>
                        </div>
                        <textarea value={text} onChange={(e)=>this.setState({text:e.target.value})} className='border w-full rounded-[12px] mt-[20px] h-[250px] outline-none p-[8px] text-[14px] text-gray-500' placeholder='Write'></textarea>
                    </div>
                    <div className='flex mt-[30px] justify-between'>
                        <Link className={`bg-red text-white pl-[8px] pr-[8px] h-[25px] rounded-[6px] text-[14px] font-semibold pt-[2px]`} to="/level-check">Back</Link>
                        <button disabled={this.getWordCount(text) < 50 || btnDisable} onClick={this.nextBtn.bind(this, text)} className={`${ this.getWordCount(text) < 50 || btnDisable?'bg-gray-500 cursor-not-allowed': 'bg-blue'} text-white pl-[8px] pr-[8px] h-[25px] rounded-[6px] text-[14px] font-semibold`}>Next</button>
                    </div>
                    </div>:null}
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    examQuestions: state.Data.examQuestions
});
const mapDispatchToProps = {getExamsQuestions, getMistralModel, changeStateValue, insertNewData, getLevelCheckExam}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(WritingExam))
