import React, { Component } from 'react'
import CheckSvg from './svg/CheckSvg'
import CheckWaitingSvg from './svg/CheckWaitingSvg'
import ProsessingSvg from './svg/ProsessingSvg';
import { connect } from 'react-redux';
import { mixinAlert } from '../../actions/SweetAlerts';

export class LevelCheckComplete extends Component {
  render() {
    const {level="", btnTxt="Check Your Level", type="waiting", onClick=()=>{}, disabled, prosessing=false, publicUser} = this.props;
    return (
        <div className='mt-[100px] ml-auto mr-auto w-[370px] shadow rounded-[10px] bg-white'>
            {/* <h3 className='w-full h-auto bg-blue p-[10px] rounded-t-[10px] text-white text-[16px] font-medium'>Ready to enhance your English? Click 'Enroll in Language Center' to take the next step in your learning journey. </h3> */}
            <div className='flex flex-col jsutify-center w-full items-center gap-[30px] pt-[30px] pb-[30px]'>
                {   !prosessing?
                    type === "waiting"?
                    <CheckWaitingSvg />:<CheckSvg />:null
                }
                {
                    prosessing?
                    <div>
                        <ProsessingSvg />
                        <h4 className='mt-[10px]'>Processing...</h4>
                    </div>
                    :null
                }
                {level !==""? <span className='text-medium'>Your Level: {level}</span>:null}
                {
                    !publicUser?.user_apply?
                    <button disabled={disabled} onClick={()=>onClick(type)} className='bg-blue rounded-[20px] pl-[10px] pr-[10px] h-[34px] text-[14px] font-regular text-white'>{btnTxt}</button>:
                    <button onClick={()=>mixinAlert("info", "Please wait, will be contact you.")} title="Please wait, will be contact you." className='bg-blue rounded-[20px] w-[147px] h-[34px] text-[14px] font-regular text-white'>{"Already Apply"}</button>
                }
                
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    publicUser: state.Data.publicUser,
})
export default connect(mapStateToProps)(LevelCheckComplete)
