import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getNotAssignedEnrolls } from '../../actions/AdminActions';
import Header from '../../components/header/Header';
import DropSearch from '../../components/DropSearch';
import { getDropValue } from '../../actions/getRole';
import { getCompanies } from '../../actions/AdminActions';
import { Link } from 'react-router-dom';
export class AdminNotAssignEnrolls extends Component {
    state={
        company_id:''
    }
    componentDidMount(){
    this.props.getCompanies(10, '');
        
    }

    selectCompany=(id)=>{
        this.setState({company_id:id})
        this.props.getNotAssignedEnrolls(id)
    }
  render() {
    const {notAssignedEnrolls, adminCompanies} = this.props;
    const {company_id} = this.state;

    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Not Assign Enrolls" />
            <div  className='flex white-search mt-[20px]'>
                <DropSearch title="Get Students by Company" selectData={this.selectCompany.bind(this)} value={company_id} placeholder="Get Students By Company" options={getDropValue(adminCompanies, "name","id")}  />
            </div>
            {   company_id!=""?
               <span className='mt-[30px] block'>Total Count: {notAssignedEnrolls.length}</span> :null
            }
            <div className='mt-[30px] grid-full-320'>
                {
                    notAssignedEnrolls?.map((data, i)=>{
                        return(
                            <div key={i} className='bg-white p-[10px] shadow rounded-[10px] flex flex-col gap-[7px]'>
                                <Link target='_blank' to={`/teacher/${data?.teacher?.id}/enroll`} className='text-[14px]'>Teacher: {data?.teacher?.full_name}</Link>
                                <Link  target='_blank' to={`/students/${data?.student?.id}/edit`} className='text-[14px]'>Student: {data?.student?.full_name}</Link>
                                <Link  target='_blank' to={`/students/${data?.student?.id}`}  className='text-[14px]'>Stud. Email: {data?.student?.email}</Link>
                                <span className='text-[14px]'>Les. Mode: {data?.lesson_mode}</span>
                                <span className='text-[14px]'>St. Mode: {data?.study_mode}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    notAssignedEnrolls: state.Data.notAssignedEnrolls,
    adminCompanies: state.Data.adminCompanies,
});
const mapDispatchToProps = {getNotAssignedEnrolls, getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(AdminNotAssignEnrolls)
