import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class TroubleStudentCard extends Component {
    state={
        visible:false
    }
  render() {
    const {data} = this.props;
    const {visible} = this.state;
    return (
        <div className='bg-white p-[20px] rounded-[10px] mt-[20px]' >
            <h2 onClick={()=>this.setState({visible: !visible})} className='cursor-pointer  h-[30px]'>{data?.name} ({data?.statistic?.length})</h2>
            {
                visible?
                <table className=' w-full bg-white rounded-[10px] border mt-[20px]'>
                    <thead >
                        <tr>
                            <th className='w-1/3 h-[50px]  border-b'>Student Name</th>
                            <th className='w-1/3 h-[50px] border-l border-b'>Failed exam</th>
                            <th className='w-1/3 h-[50px] border-l border-b'>Continue course</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.statistic?.map((item, j)=>{
                                return(
                                    <tr key={j}>
                                        <td className='w-1/3 h-[50px] text-center  border-b'><Link target='_blank' to={`/students/${item?.student?.id}`}>{item?.student?.full_name}</Link></td>
                                        <td className='w-1/3 h-[50px] text-center border-l border-b'>{item?.failed_lesson}</td>
                                        <td className='w-1/3 h-[50px] text-center border-l border-b'>{item?.active_enrolls}</td>
                                    </tr>
                                )
                            })
                        }
                        
                    </tbody>
                </table>:null
            }
           
        </div>
    )
  }
}

export default TroubleStudentCard
