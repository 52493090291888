import React, { Component } from 'react'
import { getRole } from '../../actions/getRole'
import ReviewGrade from './ReviewGrade'
import moment from 'moment'
import { connect } from 'react-redux'
import ReportWaitingSvg from '../svg/ReportWaitingSvg'
import ContractPdfSvg from '../svg/ContractPdfSvg'
import { insertNewData } from '../../actions/MainAction'
import { mixinAlert } from '../../actions/SweetAlerts'
import CheckSvg from '../svg/CheckSvg'

export class ReviewCard extends Component {
    state={
        btnDisable: false
    }
    onReadBtn=(id)=>{
        this.setState({btnDisable:true})
        this.props.insertNewData(`assessment-read/${id}`, {})
        .then(resp=>{
            this.setState({btnDisable:false})
            if(resp == "success"){
                mixinAlert("success", "Data saved successfully");
                this.props.getAfterChange()
            }else{
                mixinAlert("errpr", "Something went wrong");
            }
        })
    }
  render() {
    const {data, onClickDelete=()=>{}, onClickEdit=()=>{}, btn=true, studentName="", lang, user, onClickApprove=()=>{}, readBtn=false, readStatus} = this.props;
    const {btnDisable} = this.state;
    return (
      <div className='bg-white p-[20px] max-sm:p-[10px] rounded-[5px] '>
            <div className='flex justify-between items-start gap-[30px] max-2xl:flex-col'>
                <div className='w-[50%] max-2xl:w-full flex flex-col justify-between'>
                <div>
                    <h2 className='text-[28px] font-bold txt-blue flex items-center gap-[10px]'>{lang?.report_reviews} {data?.status==0?<ReportWaitingSvg />:null}</h2>
                    <span className='text-gray-400 text-[13px] font-[300]'>{moment(data?.date).format("DD/MM/YYYY")}</span>
                    <div className='mt-[30px] linkable' dangerouslySetInnerHTML={{ __html:data?.note }}></div>
                </div>
                    
                </div>
                <div className='w-[50%]  max-2xl:w-full flex flex-col gap-[10px]'>
                    { data?.home_work !== 0? <ReviewGrade title="Homework " subTitle="(Ev Tapşırığı)" point={data?.home_work }/>:null  }
                    { data?.participation !== 0? <ReviewGrade title="Vocabulary " subTitle="(Söz bazası)" point={data?.participation }/>:null  }
                    { data?.performance !== 0? <ReviewGrade title="Performance " subTitle="(Dərsdə Aktivlik)" point={data?.performance }/>:null  }
                    { data?.reading_comprehension !== 0? <ReviewGrade  title="Reading skills " subTitle="(Mətn anlama)" point={data?.reading_comprehension }/>:null  }
                    { data?.listening_skills !== 0? <ReviewGrade title="Listening skills " subTitle="(Dinləmə)" point={data?.listening_skills }/>:null  }
                    { data?.speaking_fluency !== 0? <ReviewGrade title="Speaking skills " subTitle="(Danışıq)" point={data?.speaking_fluency }/>:null  }
                    { data?.writing_skills !== 0? <ReviewGrade title="Writing/Essays " subTitle="(Yazı/Esse)" point={data?.writing_skills }/>:null  }
                </div>
                <div>
                        {
                            data?.file_full_url?
                                <a href={data?.file_full_url} download target='_blank'>
                                    <ContractPdfSvg />
                                </a>
                            :null
                        }
                </div>
            </div>
            <div className='mt-[20px]  max-2xl:mt-[20px] flex items-center justify-between max-[470px]:flex-col max-[470px]:items-start max-[470px]:gap-[20px]'>
                
                <div>
                    <div className='flex gap-[7px]'>
                        <span className='txt-blue font-semibold text-[16px]'>{getRole(data?.staff?.role, lang)}:</span>
                        <span  className='text-gray-500 font-regular text-[16px]'>{data?.staff?.full_name}</span>
                    </div>
                    {
                        studentName !== ""?
                        <div className='flex gap-[7px]'>
                            <span className='txt-blue font-semibold text-[16px]'>{lang?.role_student}:</span>
                            <span  className='text-gray-500 font-regular text-[16px]'>{studentName}</span>
                        </div>:null
                    }
                    {
                        data?.approved_user_name && data?.approved_user_name !=""?
                        <div className='flex gap-[7px]'>
                            <span className='txt-blue font-semibold text-[16px]'>{lang?.approved_by}</span>
                            <span  className='text-gray-500 font-regular text-[16px]'>{data?.approved_user_name}</span>
                        </div>:null
                    }
                </div>
                
                {
                    btn && user?.role ==="company_head"?
                    <div className='flex gap-[20px]'>
                       
                        <button onClick={()=>onClickDelete()} className='text-[12px] font-bold bg-red p-[4px] text-white rounded-[6px]'>
                            {lang?.delete}
                        </button>
                        <button onClick={()=>onClickEdit()} className='text-[12px] font-bold bg-blue p-[4px] text-white rounded-[6px]'>
                            {lang?.edit}
                        </button>
                        {
                            data?.status==0?
                            <button onClick={()=>onClickApprove()} className='text-[12px] font-bold bg-white border-[var(--blue)] border p-[4px] text-[var(--blue)] rounded-[6px]'>
                                {lang?.rp_approve}
                            </button>:null
                        }
                        
                    </div>:null
                }
               {
               data?.status==0 && (user?.role == "head_teacher" || user?.role == "teacher" || user?.role == "speaking_teacher")?
                <button onClick={()=>onClickEdit()} className='text-[12px] font-bold bg-blue p-[4px] text-white rounded-[6px]'>
                    {lang?.edit}
                </button>:null
               }
            </div>
                {
                    readBtn?
                    readStatus ==1?
                    <button disabled={btnDisable} onClick={this.onReadBtn.bind(this, data?.id)} className='text-[12px] bg-[#1E398F] text-white p-[5px_10px] mt-[20px] rounded-[7px]'>
                        {lang?.mark_as_read}
                    </button>:
                    <button disabled={btnDisable} className='text-[12px] bg-[none] border border-green-600 text-white p-[5px_10px] mt-[20px] rounded-[7px]'>
                       <CheckSvg />
                    </button>
                   :null
                }
      </div>
    )
  }
}
const mapStateToProps =  (state) =>({
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewCard)
