import React, { Component } from 'react'

export class AdminReviewLogModal extends Component {
    closeBackground(e){
        if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
          this.props.onClose();
        }
      }
  render() {
    const {grade, review} = this.props;
    return (
      <div onClick={this.closeBackground.bind(this)} className='background_shadow flex justify-center items-center'>
        <div className='bg-white w-[700px] max-h-[70vh] overflow-auto p-[20px] rounded-[10px]'>
            <pre className='text-[14px] whitespace-break-spaces'>{grade}</pre>
            <hr />
            <pre className='text-[14px] whitespace-break-spaces'>{review}</pre>
        </div>
      </div>
    )
  }
}

export default AdminReviewLogModal
