import React, { Component } from 'react'

export class CardCheatingSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
    >
      <g stroke="#1E398F" clipPath="url(#a)">
        <path
          strokeLinecap="round"
          d="M11 7.5c0 1.886 0 2.329-.586 2.914C9.829 11 8.886 11 7 11M5 1c-1.885 0-2.829 0-3.414.586C1 2.171 1 2.614 1 4.5m5-1c-1.463 0-2.46.781-3.054 1.47-.298.345-.446.518-.446 1.03 0 .513.148.685.446 1.03C3.54 7.719 4.536 8.5 6 8.5c1.463 0 2.46-.781 3.054-1.47.297-.345.446-.518.446-1.03 0-.513-.149-.685-.446-1.03A4.533 4.533 0 0 0 8 4.063"
        />
        <path d="M6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" />
        <path
          strokeLinecap="round"
          d="M5 11h-.5M1 7.5c0 1.886 0 2.329.586 2.914.327.327.764.472 1.414.535M7 1h.5M11 4.5c0-1.885 0-2.329-.586-2.914-.326-.327-.764-.472-1.414-.535"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
        
    )
  }
}

export default CardCheatingSvg
