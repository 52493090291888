import React, { Component } from 'react'
import CourseLessonDrop from './components/CourseLessonDrop'
import { connect } from 'react-redux'
import { withHooks } from '../../../../actions/withHooks'
import { insertNewData } from '../../../../actions/MainAction'
import Card1 from '../../../../components/cards/Card1'
import { bigAlert, mixinAlert } from '../../../../actions/SweetAlerts'
import EmptyPage from '../../../../components/EmptyPage'
import { getSpeakingByLesson } from '../../../../actions/StaffAction'
export class UnlockSpeaking extends Component {
    state={
        speakings:[],
        btnDisable:false,
        lessonId:''
    }
    getSpeakings=(data)=>{
        this.props.getSpeakingByLesson(data?.lessonId, this.props.params.id)
        .then(resp=>{this.setState({speakings: resp, lessonId:data?.lessonId})})
    }
    unlockSpeaking=(data)=>{
        if(!data.unlocks){
            this.setState({btnDisable:true});
            this.props.insertNewData('speaking-unlocks', {student_id: this.props.params.id, speaking_id:data?.id})
            .then(resp=>{
                this.setState({btnDisable:false});
                if(resp === "success"){
                    bigAlert("Unlocked!", this.props.lang?.sp_unlck_succ, "success");
                    this.props.getSpeakingByLesson(this.state?.lessonId, this.props.params.id)
                        .then(resp=>{this.setState({speakings: resp})})
                }else{
                    bigAlert("Error!", "Something went wrong.", "error")
                }
            })
        }else{
            if(data?.answare?.length ==0){
                this.setState({btnDisable:true});
                this.props.insertNewData('speaking-unlocks', {student_id: this.props.params.id, speaking_id:data?.id})
                .then(resp=>{
                    this.setState({btnDisable:false});
                    if(resp === "success"){
                        bigAlert("Unlocked!", this.props.lang?.sp_lck_succ, "success");
                        this.props.getSpeakingByLesson(this.state?.lessonId, this.props.params.id)
                            .then(resp=>{this.setState({speakings: resp})})
                    }else{
                        bigAlert("Error!", "Something went wrong.", "error")
                    }
                })
            }else{
                mixinAlert("warning", "The Speaking has already been taken!")
            }
            
        }
    }

    componentDidMount(){
        window.scrollTo(0,0)
    }
    getCoursePage(){
        const courseVisible = window.location.search;
        if(courseVisible==""){
            return true
        }else{
          return false;
        }
    }
  render() {
    const {speakings, btnDisable} = this.state;
    const {lang, studentData} = this.props;
    // console.log(speakings)
    return (
        <div className='w-full'>
            <CourseLessonDrop  studentType={studentData?.student_type} onSelectData={this.getSpeakings.bind(this)} onlyMidEnds={true} />
            {/* {speakings.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, select course and lesson" subTitle="" /></div>:null} */}
            <div className='w-full grid-full-220 mt-[20px]'>
                {this.getCoursePage()?
                    speakings?.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.title}
                                statusBisible={false}
                                btn={false}
                                btn2={true}
                                btn2Text={data.unlocks? lang?.lock:lang?.unlock}
                                btn2Color={data.unlocks? "bg-gray-400": 'bg-blue'}
                                cheating={data.unlocks? lang?.unlocked: lang?.locked}
                                onCLickBtn={this.unlockSpeaking.bind(this, data)}
                                disabled={btnDisable}
                            />
                        )
                    }):null
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang
});
const mapDispatchToProps = {getSpeakingByLesson, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UnlockSpeaking))


