import React, { Component } from 'react'

export class CheckSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={67}
        height={67}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#24d700"
          d="M16.03 10.03a.75.75 0 1 0-1.06-1.06l-4.47 4.47-1.47-1.47a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l5-5Z"
        />
        <path
          fill="#24d700"
          fillRule="evenodd"
          d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75 22.75 17.937 22.75 12 17.937 1.25 12 1.25ZM2.75 12a9.25 9.25 0 1 1 18.5 0 9.25 9.25 0 0 1-18.5 0Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default CheckSvg
