import React, { Component } from 'react'
import { connect } from 'react-redux';
import { registerPubUser } from '../actions/PublicActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { withHooks } from '../actions/withHooks';
import logo from "../images/logo.png"
import LoginEmailSvg from '../components/svg/LoginEmailSvg';
import LoginPasswordSvg from '../components/svg/LoginPasswordSvg';
import EyeCloseSvg from '../components/svg/EyeCloseSvg';
import EyeSvg from '../components/svg/EyeSvg';
import LoginUserSvg from '../components/svg/LoginUserSvg';
import InputPhone from '../components/InputPhone';
import PhoneInput from 'react-phone-input-2';
import LoginPhoneSvg from '../components/svg/LoginPhoneSvg';
import { mixinAlert } from '../actions/SweetAlerts';
export class RegisterPage extends Component {
  state={
    first_name:'',
    last_name:'',
    email:'',
    phone_number:'',
    password:'',
    loginError: false,
    type:'password',
    btnDisable: false
  }

  registerBtn=(data, e)=>{
    e.preventDefault();
    if(data?.first_name.trim() !=="" && data?.last_name.trim() !=="" && data?.email.trim() !==""  && data?.phone_number.trim() !=="" && data?.password.trim() !=="" ){
        if(data?.password?.length <8){
            mixinAlert("warning", "The password should be at least 8 character.");
            return
        }
        if(!data?.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            mixinAlert("warning", "The email should be valid email format.");
            return
        }
        this.setState({btnDisable:true})
        this.props.registerPubUser(data)
        .then(resp=>{
          this.setState({btnDisable:false})
            if(resp === "success"){
                this.props.navigate(`/confirm-code?email=${data?.email}`)
                mixinAlert("success", "Confirm code sent successfully. Please, check the email");

            }else{
                mixinAlert("error", "Email already exists.");
            }
        })
        
    }else{
        mixinAlert("warning", "Please fill the all fields.")
    }
  }

  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    const {password, loginError, type, first_name, last_name, email, phone_number, btnDisable} = this.state;
    return (
      <div className='flex bg-[#f5f5f5] items-center justify-center min-h-screen  w-full mb-[-120px] pb-[200px] pt-[200px]'>
        <div className='w-[510px] bg-white pt-[44px] pl-[54px] pr-[54px] pb-[50px]  max-[660px]:pl-[20px]  max-[660px]:pr-[20px] rounded-[12px] shadow  max-[520px]:ml-[20px] max-[520px]:mr-[20px] '>
          <div className='flex justify-center'>
            {/* <img className='w-[160px] h-[50px]' src={logo} /> */}
            <h2 className='text-[30px] font-semibold txt-blue'>Sign Up</h2>
          </div>
          <form className='mt-[35px]'>
              <div className='flex flex items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                <LoginUserSvg />
                <input className='bg-white w-[80%] outline-none h-[40px] rounded-full p-2 text-[14px]' type='text' id='regFName' placeholder='First Name' value={first_name} onChange={(e)=>this.setState({first_name: e.target.value})} />         
              </div>
              <div className='flex flex  mt-[15px] items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                <LoginUserSvg />
                <input className='bg-white w-[80%] outline-none h-[40px] rounded-full p-2 text-[14px]' type='text' id='regLName' placeholder='Last Name' value={last_name} onChange={(e)=>this.setState({last_name: e.target.value})} />         
              </div>
              <div className='flex flex register_num  mt-[15px] items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                <LoginPhoneSvg />
                <PhoneInput
                    country={"az"}
                    enableSearch={true}
                    value={phone_number}
                    placeholder='(00) 000 00 00'
                    onChange={(phone) =>{this.setState({phone_number:phone})}}
                    inputStyle={{ width:'100%'}}
                />
                {/* <input className='bg-white w-[80%] outline-none h-[40px] rounded-full p-2 text-[14px]' type='email' id='loginEmail' placeholder='Email' value={username} onChange={(e)=>this.setState({username: e.target.value})} />          */}
              </div>
              <div className='flex flex  mt-[15px] items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                <LoginEmailSvg />
                <input className='bg-white w-[80%] outline-none h-[40px] rounded-full p-2 text-[14px]' type='email' id='loginEmail' placeholder='Email' value={email} onChange={(e)=>this.setState({email: e.target.value})} />         
              </div>
              <div className='flex mt-[15px] justify-between flex items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                <LoginPasswordSvg />
                <input className='bg-white outline-none w-[80%] h-[40px] rounded-full p-2 text-[14px]' type={type} id='loginPassword' placeholder='Password' value={password} onChange={(e)=>this.setState({password: e.target.value})} />         
                <div className='cursor-pointer' onClick={(e)=>{e.preventDefault(); this.setState({type: type==="text"?"password":"text"})}}>
                  {type==="password"? <EyeSvg />: <EyeCloseSvg />}
                </div>
              </div>
            
              <button disabled={btnDisable} onClick={this.registerBtn.bind(this, this.state)} className='mt-[33px] bg-blue w-full h-[56px] font-bold text-[16px] rounded-[12px] text-white '>Register</button>
              
          </form>
          <div className='pt-[30px] flex justify-center'>
            <p>Already have an account? <Link className='txt-blue' to={'/login'}>Sign in</Link></p>
          </div>
        </div>
       
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {registerPubUser}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(RegisterPage))


