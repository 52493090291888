import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getExamSettings } from '../../actions/AdminActions';
import CardLine from '../../components/cards/CardLine';
import CardCourseSvg from '../../components/svg/CardCourseSvg';
import CardCheatingSvg from '../../components/svg/CardCheatingSvg';
import HeaderButtons from '../../components/HeaderButtons';
import AdminExamSttingsModal from '../../components/modals/AdminExamSttingsModal';
import { changeStateValue } from '../../redux/MainReducer';
import { getLessons, deleteData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
export class AdminExamSettings extends Component {
    state={
        page:1
    }

    componentDidMount(){
        this.props.getExamSettings(1)
    }

    deleteBtn=(id)=>{
        confirmAlert("warning",'Are you sure?', "You won't be able to revert this!")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`exam-requirements/${id}`)
                .then(resp=>{
                    if(resp=="success"){
                        mixinAlert("success", "Deleted successfully");
                        this.props.getExamSettings(1)
                    }else{
                        mixinAlert("error", "Something went wrong")
                    }
                })
            }
        })
    }
    editBtn=(params)=>{
        this.props.getLessons(params[0].course_id);
        this.props.changeStateValue({
            name:'examSettingModal',
            value:{
                position:true,
                params: params
            }
        })
    }
    addBtn=(data="")=>{
        this.props.changeStateValue({
            name:'examSettingModal',
            value:{
                position:true,
                params: []
            }
        });
        this.props.changeStateValue({name: "lessons", value:[]})
    }

    getMaxValue(params, property){
        let arr = [];
        for(const data of params){
            arr.push(data[property])
        }
        return Math.max(...arr)
    }
  render() {
    const {adminExamSettings, examSettingModal} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Exam Settings" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Add an exam setting", id:'0'}]} selected={0} />
            </div>
            <div className='mt-[30px] grid-full-250'>
                {
                    adminExamSettings?.map((data, i)=>{
                        return(
                            <div className='bg-white shadow p-[10px] rounded-[10px]' key={i}>
                                <ul className='flex flex-col gap-[10px]'>
                                    <CardLine svg={<CardCourseSvg />} title={data?.title} />
                                    <CardLine svg={<CardCheatingSvg />} title={`Speaking U 1: ${this.getMaxValue(data?.requirements, 'speaking_count')}`} />
                                    <CardLine svg={<CardCheatingSvg />} title={`Essay U 1: ${this.getMaxValue(data?.requirements, 'essay_count')}`} />
                                    <CardLine svg={<CardCheatingSvg />} title={`Sp. Pr U 1: ${this.getMaxValue(data?.requirements, 'speaking_practice_count')}`} />
                                    <CardLine svg={<CardCheatingSvg />} title={`PT U 1: ${this.getMaxValue(data?.requirements, 'homework_count')}`} />
                                </ul>
                                <div className='flex justify-between mt-[10px]'>
                                    <button onClick={this.deleteBtn.bind(this, data?.id)} className='bg-red h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Delete</button>
                                    <button onClick={this.editBtn.bind(this, data?.requirements)} className='bg-blue h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Edit</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {examSettingModal?.position?<AdminExamSttingsModal />:null }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminExamSettings: state.Data.adminExamSettings,
    examSettingModal: state.Data.examSettingModal,
});
const mapDispatchToProps = {getExamSettings, changeStateValue, getLessons, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminExamSettings)
