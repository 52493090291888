import React, { Component } from 'react'

export class SpeakingSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
      >
        <path
          fill="#585874"
          d="M11.29 5.977a5.335 5.335 0 0 0-10.623.69A5.31 5.31 0 0 0 1.837 10c.539.672.83 1.416.83 2.203v2.463h6v-2H10a1.334 1.334 0 0 0 1.333-1.334V9.381l1.307-.56c.228-.098.248-.33.15-.485l-1.5-2.36ZM2 6.667a4 4 0 0 1 7.967-.52l.038.295 1.028 1.617L10 8.5v2.832H7.335l-.002 2H4v-1.129c0-1.093-.394-2.128-1.123-3.037A3.977 3.977 0 0 1 2 6.667Zm12.103 5.401-1.11-.74A5.973 5.973 0 0 0 14 8a5.973 5.973 0 0 0-1.007-3.329l1.11-.74A7.3 7.3 0 0 1 15.333 8a7.299 7.299 0 0 1-1.23 4.068Z"
        />
      </svg>
    )
  }
}

export default SpeakingSvg
