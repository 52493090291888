import React, { Component } from 'react'

export class Card2Line extends Component {
  render() {
    const {title} = this.props
    return (
        <li className='flex justify-center'>
            {
                title?
                title.length <=16?
                <span className='txt-light-black text-[15px] font-bold'>{title}</span>:
                <div className='relative completeText'>
                <span className='txt-light-black text-[15px] font-bold minitext'> {title?.substring(0, 16)}...</span>
                <span className='txt-light-black text-[15px] bg-white z-20 font-bold longtext'> {title}</span>
              </div>:null
            }
            
        </li>
    )
  }
}

export default Card2Line
