import React, { Component } from 'react'

export class CardUnlockSvg2 extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={18}
        height={18}
        viewBox="0 0 398.3 398.3"
      >
        <path
          d="M341.063 207.271H115.828v-90.764c0-41.83 37.375-75.864 83.318-75.864 36.774 0 68.069 21.809 79.091 51.974 4.617 12.656 13.331 23.89 24.548 23.89 11.226 0 20.598-11.039 17.631-24.183C308.54 39.651 258.672 0 199.146 0 130.793 0 75.185 52.267 75.185 116.507v90.764H57.237c-9.917 0-17.948 10.917-17.948 24.386v142.258c0 13.469 10.917 24.386 24.386 24.386h270.95c13.469 0 24.386-10.917 24.386-24.386V231.656c0-13.469-8.031-24.385-17.948-24.385zM199.146 316.332c-10.104 0-18.289-8.194-18.289-18.297s8.194-18.297 18.289-18.297c10.104 0 18.297 8.194 18.297 18.297s-8.185 18.297-18.297 18.297z"
          style={{
            fill: "var(--blue)",
          }}
        />
      </svg>
    )
  }
}

export default CardUnlockSvg2
