import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux'
import { getOfficeStudents } from '../../actions/StaffAction'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { getRole } from '../../actions/getRole'
import Card4 from '../../components/cards/Card4'
import WeeklyStatisticsModal from '../../components/modals/WeeklyStatisticsModal'
import { withHooks } from '../../actions/withHooks'
import CheckExamReminderModal from '../../components/modals/CheckExamReminderModal'
import Search from '../../components/Search'
import EmptyPage from '../../components/EmptyPage'
import PublicExamResultsModal from '../../components/modals/PublicExamResultsModal'
import { changeStateValue } from '../../redux/MainReducer'
export class TeacherStudents extends Component {
    state={
        page:1,
        search:'',
        statModal:{
            position:false,
            student_id:''
        },
        reminderModal:{
            position:false,
            studentId:''
        }
    }
    searchStudents=(search)=>{
        this.setState({search:search})
        this.props.getOfficeStudents(1, search, this.props.user?.company_id, this.state.lockFilter, this.state.modeFilter, this.state.yearFilter, this.props.user?.role=="speaking_teacher"?'':this.props.user?.id); 
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps?.user !== this.props.user){
            this.props.getOfficeStudents(1, '', this.props.user?.company_id, '', '', '', this.props.user?.role=="speaking_teacher"?'':this.props.user?.id);  
        }
        
    }
    componentDidMount(){
        if(this.props.user !== ""){
           this.props.getOfficeStudents(1, '', this.props.user?.company_id, '', '', '', this.props.user?.role=="speaking_teacher"?'':this.props.user?.id);  
        }
        window.scrollTo(0,0)
    }
    loadMore=(page)=>{
        this.setState({page:page});
        this.props.getOfficeStudents(page, this.state.search, this.props.user?.company_id, this.state.lockFilter, this.state.modeFilter, this.state.yearFilter, this.props.user?.role=="speaking_teacher"?'':this.props.user?.id);
    }
   
    checkStudentExam=(data)=>{
        this.setState({
            reminderModal: {
                position: true,
                studentId: data?.id
            }
        })
    }
    addCertificateBtn=(data)=>{
        this.props.changeStateValue({
            name:'addExResultsData',
            value:{
                position: true,
                id: '',
                student_name:data?.full_name,
                exam_type_id:'',
                score:'',
                date:'',
                image: '',
                cert_image: '',
                image_full_url:'',
                cert_image_full_url: '',
                callAfterFunction: ()=>{},
                afterParams:[],
                modalTitle: `Add exam results`
            }
        })
    }
  render() {
    const {officeStudents,  user, addExResultsData} = this.props;
    const { reminderModal, statModal} = this.state;
  
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={"students"} />
            <div className='max-[1100px]:mt-[120px] flex justify-center max-[800px]:mt-[180px]'>
                <Search largWidth={true} onSearchData={(search)=>this.searchStudents(search)} />
            </div>
            {officeStudents?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}

            <div className='mt-[42px] grid-full-250'>
            {
                officeStudents?.map((data, i)=>{
                return(
                    <Card4
                        key={i}
                        name={data?.full_name}
                        email={data?.email}
                        // phone={data?.phone_number}
                        image={data?.avatar_full_url}
                        role={getRole(data?.role, this.props.lang)}
                        headerBtn={true}
                        lock={data?.attendance_lock_status === 0 && data?.manual_lock_status === 0? 1:0}
                        stat={true}
                        info={`/students/${data?.id}`}
                        course={data?.student_enroll_class.length !==0? data?.student_enroll_class[0]?.lesson_mode:null}
                        onClickStat={()=>this.setState({statModal: {position: true, student_id:data?.id}})}
                        checkExam={user?.role === "teacher" || user?.role === "head_teacher"?true:false}
                        onClickCheck={this.checkStudentExam.bind(this, data)}
                        cert={true}
                        addCertificate={this.addCertificateBtn.bind(this, data)}
                        widthPermission={false}
                    />
                )
                })
            }
            </div>
            {
            officeStudents?.length >=20?
            <LoadMoreBtn onLoadData={this.loadMore} count={officeStudents?.length} />:null
            }
             {statModal?.position? <WeeklyStatisticsModal student_id={statModal?.student_id} closeModal={()=>this.setState({statModal: {position: false, student_id:''}})} />:null}
            {reminderModal?.position? <CheckExamReminderModal studentId={reminderModal?.studentId} closeModal={()=>this.setState({reminderModal:{position:false, studentId:''}})} />:null}
            {addExResultsData?.position?  <PublicExamResultsModal />:null}
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    officeStudents: state.Data.officeStudents,
    addExResultsData: state.Data.addExResultsData,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getOfficeStudents, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(TeacherStudents))


