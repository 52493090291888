import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';

export class MultipleChoice extends Component {
    onselectAnswer=(value)=>{
        let given_ans = []
        if(this.props.examsData[this.props.index]?.questions[this.props.questionIndex]?.given_answer){
            given_ans = [...this.props.examsData[this.props.index]?.questions[this.props.questionIndex]?.given_answer]
        }
        const index = given_ans.findIndex(x=> x.id == value?.id);
        if(index !=-1){
            given_ans.splice(index, 1)
        }else{
            given_ans.push(value)
        }
        this.props.changeStateValue({
          name:`examsData.${this.props.index}.questions.${this.props.questionIndex}.given_answer`,
          value: given_ans
        })
      }
  render() {
    const {answers=[], id, item} = this.props;
    return (
        <ul className='border-b pb-[20px] mb-[20px]'>
        {
            answers?.map((data, i)=>{
                return (
                     <li className='flex items-center gap-[5px]' key={i}>
                       <input className='cursor-pointer' checked={item?.given_answer && item?.given_answer?.find(x=>x.id == data?.id)? true: false} onChange={this.onselectAnswer.bind(this, data, i)} name={`${id}`} id={data?.id} type='checkbox' />
                       <label className='text-[14px] font-[400] txt-light-black cursor-pointer w-full inline-block' htmlFor={data?.id}> {data?.title}</label>
                     </li>
                )
            })
        }
       
    </ul>
    )
  }
}

const mapStateToProps = (state)=>({
    examsData: state.Data.examsData
})
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps) (MultipleChoice)
