import React, { Component } from 'react'
import { changeStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';
import ReadAssessmentSvg from '../components/svg/ReadAssessmentSvg';
import { mixinAlert } from '../actions/SweetAlerts';
import { withHooks } from '../actions/withHooks';
import { insertNewData } from '../actions/MainAction';

export class ReadAssessmentsPage extends Component {
    componentDidMount(){
        this.props.changeStateValue({name:'sideBarVisible', value:false});
        try{
            const search = window.location.search;
            const params = search.split("?")[1];
            const item = atob(params)
            const id = item.split("_")[0];
            this.props.insertNewData(`read-assessment-public/${id}`, {})
            .then(resp=>{
                console.log(resp)
                setTimeout(()=>{
                    this.props.changeStateValue({name:'sideBarVisible', value:true});
                    this.props.navigate("/")
                }, 2000)
            })
        }catch(err){
            setTimeout(()=>{
                this.props.changeStateValue({name:'sideBarVisible', value:true});
                this.props.navigate("/")
            }, 500)
        }
        
    }
  render() {
    return (
      <div className='flex justify-center items-center h-screen'>
        <div className='w-[400px] p-[10px] shadow bg-white rounded-[10px] flex flex-col items-center gap-[20px]'>
            <ReadAssessmentSvg />
            <h2 className='txt-blue text-center text-[18px] font-semibold'>Assessment readed successfully</h2>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ReadAssessmentsPage))
