import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Calendar from '../../components/Calendar'
import { connect } from 'react-redux';
import { getCheckins } from '../../actions/MainAction';
import moment from 'moment';
export class CheckIn extends Component {

  getCheckinData=(date)=>{
    this.props.getCheckins({month: moment(date).format("MM"), year: moment(date).format("YYYY")})
  }
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    const {checkins} = this.props;
    
    return (
        <div className='w-full pl-[27px] pr-[27px] max-[420px]:pr-[10px] max-[420px]:pl-[10px] '>
            <Header title="check_ins" />
            <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
                <Calendar checkinDates={checkins} onChange={this.getCheckinData} />
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  checkins: state.Data.checkins
});
const mapDispatchToProps = {getCheckins}
export default connect(mapStateToProps, mapDispatchToProps)(CheckIn)
