import React, { Component } from 'react'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
export class InputPhone extends Component {
   
  render() {
    const {name="", value="", title="", required=false, lang} = this.props;
    return (
      <div>
          {title !== "" ? <label htmlFor={name} className='text-[14px] txt-light-black'>{title}</label>:null }
         <PhoneInput
            country={"az"}
            enableSearch={true}
            value={value}
            placeholder='(00) 000 00 00'
            onChange={(phone) => this.props.changeStateValue({name:name, value:phone})}
            inputStyle={{ width:'100%'}}
        />
        {
            required && value===""?
            <span className='text-[11px] text-red-700'>{lang?.field_required}</span>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(InputPhone)
