import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getTypeofStudents } from '../../actions/AdminActions';
import Header from '../../components/header/Header';
import StatisticCard from '../../components/cards/StatisticCard';
import { typeOfSt } from '../../actions/getGeneralStats';
export class AdminTypeOfStudents extends Component {
  state={
    typeOfStudents:[]
  }
  componentDidMount(){
    this.props.getTypeofStudents()
    .then(resp=>{
      this.setState({typeOfStudents:resp});
    })
  }
  render() {
    const {typeOfStudents} = this.state
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title={"Type of Students"} />
        <div className=' grid-full-320 mt-[42px] items-start max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
            {
              typeOfStudents?.map((data, i)=>{
                return(
                  <StatisticCard key={i} data={[{
                    bold:false,
                    data:["Students", data?.company_students]
                }, ...typeOfSt(data?.types)]} title={data?.name} />
                )
              })
            }
        </div>
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  
});
const mapDispatchToProps = {getTypeofStudents}
export default connect(mapStateToProps, mapDispatchToProps)(AdminTypeOfStudents)
