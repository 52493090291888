import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import Audio from './Audio';
import Video from './Video';
import Image from './Image';
import QuestionDescription from './QuestionDescription';
export class DropDown extends Component {
    shuffle(array) {
        return array.sort(() => Math.random() - 0.5);
    }

    giveAnswer(parentIndex, index, answerIndex, item, e){
      for(let i = 0; i < this.props.data?.student_exam_question_answers.length; i++){
        if( this.props.data?.student_exam_question_answers[i].given_answer === ""){
          this.props.changeStateValue({
            name:`examsData.${parentIndex}.student_exam_questions.${index}.student_exam_question_answers.${i}.given_answer`,
            value: 'N/A'
        })
        }
      }
        this.props.changeStateValue({
            name:`examsData.${parentIndex}.student_exam_questions.${index}.student_exam_question_answers.${answerIndex}.given_answer`,
            value: e.target.value
        })
    }
  render() {
    const {parentIndex, index, data} = this.props;
    return (
      <div className='mt-[40px]'>
         <h4 className='txt-blue font-bold text-[18px]'>{data?.title}</h4>
        {
          data?.description?
          <QuestionDescription text={data?.description} />:null
        }
        {
          data?.audio_full_url?
          <Audio url={ data?.audio_full_url} />:null
        }
        
        {
          data?.video_full_url?
          <Video url={data?.video_full_url} />:null
        }
        {
          data?.image_full_url?
          <Image url={data?.image_full_url} />:null
        }
        <div className='relative mt-[20px] '>

           
        {
                    data.student_exam_question_answers?
                    data.student_exam_question_answers.map((item, i) =>{
                       return(
                          
                        <span className='h-[30px] inline-block' key={i}>{item.title.split('[')[0] ? item.title.split('[')[0] :null}
                            <select className="border text-[12px] rounded relative border-dashed h-[20px] border-[var(--blue)]" id={item.id} onChange={this.giveAnswer.bind(this, parentIndex, index, i,item)} 
                            value={item.given_answer} >
                                <option className='text-[12px]' value="N/A" >N/A</option>
                                {
                                    this.shuffle(item.title.match(/\[[^\[\]]*\]/g)[0].replace(/[\[\]']+/g,'').split(',')).map((result, i) =>{
                                        return(
                                            <option className='text-[12px]' value={result} key={i} >{result}</option>
                                        )
                                    })
                                }
                            </select>
                            {item.title.split(']')[1]? item.title.split(']')[1]:null}
                        </span>
                       )
                       
                    }):null
                }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DropDown)
