import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import { connect } from 'react-redux'
import { getStaffExmResults, deleteData } from '../../actions/PublicActions'
import PublicCard2 from '../../public_pages/components/cards/PublicCard2'
import PublicExamResultsModal from '../../components/modals/PublicExamResultsModal';
import { changeStateValue } from '../../redux/MainReducer'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
export class PublicPages extends Component {
    state={
        page:1
    }
    addEditData=(data)=>{
        this.props.changeStateValue({
            name:'addExResultsData',
            value:{
                position: true,
                id: data?.id?data?.id:'',
                student_name:data?.student_name?data?.student_name:'',
                exam_type_id:data?.exam_type_id?data?.exam_type_id:'',
                score:data?.score?data?.score:'',
                date:data?.date?data?.date:'',
                image: data?.image?data?.image:'',
                cert_image: data?.cert_image?data?.cert_image:'',
                image_full_url: data?.image_full_url?data?.image_full_url:'',
                cert_image_full_url: data?.cert_image_full_url?data?.cert_image_full_url:'',
                callAfterFunction: this.props.getStaffExmResults,
                afterParams:[this.state.page, 'company'],
                modalTitle: `${data?.id?'Edit':'Add' } exam results`
            }
        })
    }

    componentDidMount(){
        this.props.getStaffExmResults(1, 'company');
    }
    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getStaffExmResults(page, 'company');
    }
    deleteData=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`company-student-exam/${data?.id}`)
                .then(resp=>{
                    if(resp === "success"){
                        this.props.getStaffExmResults(this.state.page, 'company');
                        mixinAlert("success", "Deleted successfully.");
                    }else{
                        mixinAlert("error", "Something went wrong");
                    }
                })
            }
        })
    }
  render() {
    const {staffExResults, addExResultsData} = this.props;
    return (
        <div className='w-full max-sm:pl-[10px] max-sm:pr-[10px] pl-[27px] pr-[27px]'>
            <Header title={"public_pages"} />
            <div className='mt-[42px] flex justify-end max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons onClick={this.addEditData.bind(this, '')} buttons={[{title: "Add a data", id:0}]} />
            </div>
            <div className='grid-full-320 mt-[30px]'>
                {
                  staffExResults?.map((data, i)=>{
                    return(
                      <PublicCard2 onClickDelete={this.deleteData.bind(this, data)} onClickEdit={this.addEditData.bind(this, data)} edit={true} key={i} data={data}/>
                    )
                  })
                }
            </div>
            {
                staffExResults?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={staffExResults?.length} />:null
            }
            {addExResultsData?.position?  <PublicExamResultsModal />:null}
           
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    staffExResults: state.Data.staffExResults,
    addExResultsData: state.Data.addExResultsData,
});
const mapDispatchToProps = {getStaffExmResults, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(PublicPages)
