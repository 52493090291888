import React, { Component } from 'react'
import Header from '../../components/header/Header'
import MiniCalendar from '../../components/MiniCalendar'
import { connect } from 'react-redux';
import { getCompanyWiseReports, getCompanyWaitingReports } from '../../actions/StaffAction';
import moment from 'moment';
import ReviewCard from '../../components/cards/ReviewCard';
import EmptyPage from '../../components/EmptyPage';
import { bigAlert, confirmAlert } from '../../actions/SweetAlerts';
import { deleteData, insertNewData } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer';
import ReportModal from '../../components/modals/ReportModal';
import HeaderButtons from '../../components/HeaderButtons';
export class Reports extends Component {
  state={
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    selected:0
  }
  componentDidMount(){
    
    if(this.props.user != ""){
      if(this.props.user?.role == "company_head" || this.props.user?.role == "office_manager"){
       
         this.props.getCompanyWaitingReports("all","all");
      }else{
        this.props.getCompanyWiseReports(this.state.startDate,this.state?.endDate);
      }
    }
    window.scrollTo(0,0)
  }
  componentDidUpdate(prevProps, prevState){
    // console.log(prevProps?.user, this.props.user)
    if(prevProps?.user !== this.props.user){
      
      if(this.props.user !=""){
        if(this.props.user?.role == "company_head" || this.props.user?.role == "office_manager"){
          this.props.getCompanyWaitingReports("all","all");
        }else{
          this.props.getCompanyWiseReports(this.state.startDate,this.state?.endDate);
        }
      }
    }
}
  onSelectDate=(date)=>{
    this.setState({
      startDate: date?.startDate,
      endDate: date?.endDate
    })
    if(this.state.selected==0){
      this.props.getCompanyWaitingReports("all", "all");
    }else{
      this.props.getCompanyWiseReports(this.state.startDate,this.state?.endDate);
    }
  }
  deleteData=(data)=>{
    confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
    .then(respAlert=>{
        if(respAlert === "success"){
            this.props.deleteData(`assessments/${data?.id}`)
            .then(resp=>{
                if(resp === "success"){
                    bigAlert("Done!", "Report deleted successfully", "success");
                    if(this.state.selected==0){
                      this.props.getCompanyWaitingReports("all", "all");
                    }else{
                      this.props.getCompanyWiseReports(this.state.startDate, this.state?.endDate);
                    }
                }else{
                    bigAlert("Error!", "Something went wrong!", "error")
                }
            })
        }
    })
}
getAfterChange=()=>{
  if(this.state.selected==0){
    this.props.getCompanyWaitingReports("all", "all");
  }else{
    this.props.getCompanyWiseReports(this.state.startDate,this.state?.endDate);
  }
}
openModal=(data)=>{
       
  this.props.changeStateValue({
      name: 'reviewModalData',
      value:{
          position: true,
          id: data?.id?data?.id:'',
          date: data?.date?data?.date:moment(new Date()).format("YYYY-MM-DD"),
          homework:data?.home_work?data?.home_work:'0',
          participation:data?.participation?data?.participation:'0',
          performance:data?.performance?data?.performance:'0',
          readingComprehension:data?.reading_comprehension?data?.reading_comprehension:'0',
          listening_skills:data?.listening_skills?data?.listening_skills:'0',
          speakingFluency:data?.speaking_fluency?data?.speaking_fluency:'0',
          writingSkills:data?.writing_skills?data?.writing_skills:'0',
          note: data?.note?data?.note:'',
          file: data?.file?data?.file:'',
          file_full_url: data?.file_full_url?data?.file_full_url:'',
          user_id: data?.user_id,
          status: data?.status || data?.status==0 ?data?.status:1

      }
  })
}
onClickApprove=(data)=>{
  
  confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, approve it!")
  .then(respAlert=>{
      if(respAlert === "success"){
        this.props.insertNewData(`approve-assessments/${data?.id}`, {user_id:data?.user_id})
        .then(resp=>{
          if(resp==="success"){
            bigAlert("Done!", "Report approved successfully", "success");
            if(this.state.selected==0){
              this.props.getCompanyWaitingReports("all", "all");
            }else{
              this.props.getCompanyWiseReports(this.state.startDate,this.state?.endDate);
            }
            
          }else{
            bigAlert("Error!", "Something went wrong!", "error")
          }
        })
      }
  })
  
}
getDataBtn=(data)=>{
  
  this.setState({selected:data?.id});
  if(data?.id==1){
    this.props.getCompanyWiseReports(this.state.startDate, this.state.endDate);
  }else if(data?.id==0){
    this.props.getCompanyWaitingReports("all", "all");
  }
}
  render() {
    const {companyWiseReports, reviewModalData, lang} = this.props;
    const {selected} = this.state;
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title="reports" />
        <div className='flex mt-[30px] max-sm:justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
          <MiniCalendar onSelectDate={this.onSelectDate.bind(this)} />
        </div>
        <div className='mt-[20px]'>
          <HeaderButtons selected={selected} onClick={this.getDataBtn.bind(this)} buttons={[{title:lang?.rp_waiting, id:0},{title:lang?.rp_all, id:1}]} />
        </div>
        {companyWiseReports.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No reports found. Please, change date" subTitle="" /></div>:null}
        
        <div className='w-full flex flex-col gap-[20px] mt-[20px]'>
            {
                companyWiseReports?.map((data, i)=>{
                    return(
                        <ReviewCard
                            key={i}
                            data={data}
                            studentName={data?.user?.full_name}
                            // btn={false}
                            onClickEdit={this.openModal.bind(this, data)}
                            onClickDelete={this.deleteData.bind(this, data)}
                            onClickApprove={this.onClickApprove.bind(this, data)}
                        />
                    )
                })
            }
        </div>
        {reviewModalData?.position? <ReportModal callAfterSubmit={()=>this.getAfterChange()} />:null}
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  companyWiseReports: state.Data.companyWiseReports,
  reviewModalData: state.Data.reviewModalData,
  user: state.Data.user,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getCompanyWiseReports, deleteData, changeStateValue, insertNewData, getCompanyWaitingReports}
export default connect(mapStateToProps, mapDispatchToProps)(Reports)
