import React, { Component } from 'react'
import Header from '../../../../components/modals/Header'
import { changeStateValue } from '../../../../redux/MainReducer';
import { insertNewData } from '../../../../actions/MainAction';
import { connect } from 'react-redux';
import Input from '../../../../components/modals/components/Input';
import { mixinAlert } from '../../../../actions/SweetAlerts';
import DropSearch from '../../../../components/DropSearch';

export class AddDynamicParent extends Component {
    state={
        btnDisabled: false
    }
    closeModal=()=>{
        this.props.changeStateValue({
            name:"dynamicExamParentModal",
            value:{
                position:false
            }
        })
    }

    saveBtn=(data)=>{
        if(data?.title?.trim() !=="" && data?.duration !=="" && data?.max_score !==""){
            this.setState({btnDisabled: true})
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property]);
            }
            let url = "dynamic-exam-parent";
            if(data?.id && data?.id !==""){
                url = `dynamic-exam-parent/${data?.id}`;
                formData.append("_method", "put")
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisabled: false})
                if(resp == "success"){
                    mixinAlert("success", "Data saved successfully.");
                    data?.afterCallFunction(...data?.afterCallParams);
                    this.closeModal()
                }else{
                    mixinAlert("error", "Something went wrong.");
                }
            })
        }
    }
  render() {
    const {btnDisabled} = this.state;
    const {dynamicExamParentModal} = this.props;

    return (
      <div className='background_shadow flex justify-center items-center'>
            <div className='bg-white w-[900px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={dynamicExamParentModal?.id ===""?"Add Parent":"Edit Parent"} />
                <div className='p-[10px] rounded-b-[10px] bg-white max-h-[70vh] max-[800px]:overflow-auto'>
                    <div className='grid grid-cols-2 max-[700px]:grid-cols-1 gap-[20px]  mt-[10px]'>
                        <Input
                            name={`dynamicExamParentModal.title`}
                            value={dynamicExamParentModal?.title}
                            title={"Title"}
                        />
                        <Input
                            name={`dynamicExamParentModal.duration`}
                            value={dynamicExamParentModal?.duration}
                            title={"Duration"}
                            type="number"
                        />
                        <Input
                            name={`dynamicExamParentModal.max_score`}
                            value={dynamicExamParentModal?.max_score}
                            title={"Max Score"}
                            type="number"
                        />
                         <DropSearch
                        options={[{label: "Reading", value:"reading"}, {label: "Listening", value: "listening"}, {label: "Speaking", value: "speaking"}, {label:"Writing", value: "writing"}]}
                        name={`dynamicExamParentModal.type`}
                        value={dynamicExamParentModal.type}
                        title={"Select a type"}
                        placeholder={"Select a type"}
                        // selectData={this.onSelectCategory.bind(this)}
                    />
                    </div>
                    <div className='flex justify-between items-center mt-[20px]'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Close</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,dynamicExamParentModal)} className='bg-orange pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Save</button>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    dynamicExamParentModal: state.Data.dynamicExamParentModal
});
const mapDispatchToProps = {changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AddDynamicParent)
