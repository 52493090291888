import React, { Component } from 'react'

export class TeacherSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={18}
        height={18}
        viewBox="0 0 512 512"
        fill="#585874"
      >
        <circle cx={256.763} cy={44.89} r={44.89} />
        <path d="m269.99 136.099-8.011-14.569 7.108-12.927c1.059-1.927-.337-4.293-2.539-4.293h-19.529c-2.199 0-3.599 2.364-2.538 4.293l7.123 12.954-8.025 14.597a8.223 8.223 0 0 0-.859 5.559l1.373 6.914 12.692 3.489 12.58-3.458 1.464-6.893a8.224 8.224 0 0 0-.839-5.666zM237.477 414.547v51.505c0 14.355 11.637 25.991 25.992 25.991s25.992-11.637 25.992-25.991V353.595l-51.984 60.952zM165.014 426.411a47.234 47.234 0 0 1-15.935-27.873v47.32c0 7.531 6.105 13.637 13.637 13.637s13.637-6.106 13.637-13.637V433.59a47.485 47.485 0 0 1-11.339-7.179zM176.353 301.556h-27.274v80.568a47.194 47.194 0 0 1 10.627-22.583l16.648-19.519v-38.466h-.001zM335.254 301.556v144.302c0 7.531 6.105 13.637 13.637 13.637s13.637-6.106 13.637-13.637V301.556h-27.274zM237.34 301.556l-61.321 71.898c-9.315 10.922-8.012 27.327 2.909 36.642 10.932 9.322 27.335 8.005 36.642-2.909l90.091-105.631H237.34zM218.382 192.209l-49.016.122 17.359-36.72c2.282-4.828 7.126-7.991 12.438-8.184v24.984l14.487-.037v-24.967c0-1.186-.159-3.192 1.849-4.723a4.7 4.7 0 0 1 4.093-.794l20.548 5.649-16.755-43.452h-23.711c-22.18 0-42.635 12.956-52.115 33.009l-32.014 67.721c-7.011 14.837 4.623 31.417 19.638 30.917l83.308-.207c11.963-.03 21.636-9.752 21.606-21.714-.032-12.678-10.827-21.944-21.715-21.604z" />
        <path d="M470.545 255.507H41.457c-7.254 0-13.135 5.881-13.135 13.135 0 7.255 5.881 13.135 13.135 13.135h14.139V491.84c0 11.133 9.026 20.159 20.159 20.159s20.159-9.026 20.159-20.159V281.777h321.36v210.064c0 11.133 9.026 20.159 20.159 20.159s20.159-9.026 20.159-20.159V281.777h12.953c7.254 0 13.135-5.881 13.135-13.135.001-7.254-5.88-13.135-13.135-13.135zM397.697 204.822l-32.014-67.721c-9.48-20.052-29.935-33.009-52.115-33.009H290.18l-16.752 43.45 20.548-5.649a4.7 4.7 0 0 1 4.093.794c2.008 1.53 1.849 3.537 1.849 4.723v25.164h13.972v-25.152c5.388.122 10.321 3.304 12.63 8.191l17.399 36.805h-49.111c-11.963 0-21.66 9.697-21.66 21.66s9.697 21.66 21.66 21.66h83.308c15.824 0 26.387-16.525 19.581-30.916z" />
      </svg>
    )
  }
}

export default TeacherSvg
