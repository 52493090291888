import React, { Component } from 'react'

export class SingleCenterPhoneSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={36}
        height={36}
        fill="none"
      >
        <path
          fill="url(#a)"
          d="M0 18C0 8.059 8.059 0 18 0s18 8.059 18 18-8.059 18-18 18S0 27.941 0 18Z"
        />
        <path
          fill="#fff"
          d="m14.987 10.066-.717.216a3.5 3.5 0 0 0-2.454 2.854c-.297 2.068.367 4.486 1.968 7.26 1.597 2.765 3.355 4.547 5.29 5.327a3.5 3.5 0 0 0 3.715-.705l.542-.514a2 2 0 0 0 .247-2.623l-1.356-1.88a1.5 1.5 0 0 0-1.655-.556l-2.051.627-.053.01c-.226.033-.748-.456-1.398-1.582-.68-1.178-.82-1.867-.633-2.045l1.043-.973a2.497 2.497 0 0 0 .575-2.85l-.662-1.47a2 2 0 0 0-2.401-1.096Zm1.491 1.505.66 1.471a1.497 1.497 0 0 1-.344 1.71l-1.046.974c-.67.634-.448 1.716.452 3.274.846 1.466 1.618 2.19 2.448 2.064l.124-.026 2.088-.637a.5.5 0 0 1 .552.185l1.356 1.88a1 1 0 0 1-.123 1.312l-.543.514a2.5 2.5 0 0 1-2.653.503c-1.698-.684-3.303-2.31-4.798-4.9-1.499-2.595-2.106-4.802-1.845-6.617a2.501 2.501 0 0 1 1.753-2.039l.717-.216a1 1 0 0 1 1.2.548"
        />
        <defs>
          <linearGradient
            id="a"
            x1={0}
            x2={36}
            y1={15.707}
            y2={15.817}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FED433" />
            <stop offset={1} stopColor="#FF8D38" />
          </linearGradient>
        </defs>
      </svg>
    )
  }
}

export default SingleCenterPhoneSvg
