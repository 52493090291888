import React, { Component } from 'react'
import SingleCenterPhoneSvg from '../svg/SingleCenterPhoneSvg'

export class PublicCard4 extends Component {
  render() {
    const {svg, title, url} = this.props;
    return (
      <a href={url} target='_blank' className='rounded-[5px] items-center flex gap-[14px] shadow pt-[18px] pb-[18px] pl-[10px] pr-[10px]'>
       {svg}
        <span className='h-[36px] w-[0.5px] bg-[#707070]'></span>
        <h4 className='text-[20px] font-bold'>{title}</h4>
      </a>
    )
  }
}

export default PublicCard4
