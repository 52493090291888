import React, { Component } from 'react'

export class HwResultsSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            fill="none"
        >
            <path
            fill="#585874"
            d="M16.706 10.856 15.02 9.17a.544.544 0 0 0-.788 0L9 14.4v2.475h2.475l5.231-5.231a.544.544 0 0 0 0-.788Zm-5.681 4.894h-.9v-.9l2.813-2.813.9.9-2.813 2.813Zm3.6-3.6-.9-.9.9-.9.9.9-.9.9Zm-9 .787H6.75v1.126H5.625v-1.126Zm2.25-2.812h2.25v1.125h-2.25v-1.125Zm-2.25 0H6.75v1.125H5.625v-1.125Zm2.25-2.813h4.5v1.125h-4.5V7.313Zm-2.25 0H6.75v1.125H5.625V7.313Z"
            />
            <path
            fill="#585874"
            d="M3.938 15.75V3.937h1.687v1.688h6.75V3.937h1.688v3.376h1.124V3.938c0-.62-.506-1.126-1.124-1.126h-1.688V2.25c0-.619-.506-1.125-1.125-1.125h-4.5c-.619 0-1.125.506-1.125 1.125v.563H3.937c-.618 0-1.124.506-1.124 1.124V15.75c0 .619.506 1.125 1.124 1.125H6.75V15.75H3.937ZM6.75 2.25h4.5V4.5h-4.5V2.25Z"
            />
        </svg>
    )
  }
}

export default HwResultsSvg
