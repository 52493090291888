import React, { Component } from 'react'

export class ToggleCloseSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 -3 20 20"
    >
        <title>{"toggle_button_round [#898]"}</title>
        <path
        fill="var(--red)"
        fillRule="evenodd"
        d="M10.292 7.068a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM13.116 12h-6c-2.757 0-5-2.243-5-5s2.243-5 5-5h6c2.757 0 5 2.243 5 5s-2.243 5-5 5ZM20 7a7 7 0 0 0-7-7H7a7 7 0 1 0 0 14h6a7 7 0 0 0 7-7Z"
        />
    </svg>
    )
  }
}

export default ToggleCloseSvg
