import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getTeacherStatsEssReview, getTeacherStatsSpReview, getTeacherStatsMidEndReview, getOfficeTeacherManagerData, getTeacherDailyControll } from '../../actions/StaffAction';
import StatisticCard from '../../components/cards/StatisticCard';
import moment from 'moment';
import MonthlyCalendar from '../../components/MonthlyCalendar';
import CheckInSvg from '../../components/svg/CheckInSvg';
import CheckSvg from '../../components/svg/CheckSvg';
export class TeacherGeneralStats extends Component {
  state={
    essReviews:[],
    spReviews: [],
    midEndReviews: [],
    dailyControll:[]
  }
  componentDidMount(){
    window.scrollTo(0,0)
    this.props.getTeacherStatsEssReview()
    .then(resp=>{this.setState({essReviews:resp})})

    this.props.getTeacherStatsSpReview(this.props.user?.role)
    .then(resp=>{this.setState({spReviews:resp})});


    this.props.getTeacherStatsMidEndReview()
    .then(resp=>{this.setState({midEndReviews:resp})});

    this.props.getOfficeTeacherManagerData(moment(new Date()).format("YYYY-MM"));

    this.props.getTeacherDailyControll()
    .then(resp=>{this.setState({dailyControll:resp})})
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps.user !== this.props.user){
      this.props.getTeacherStatsSpReview(this.props.user?.role)
       .then(resp=>{this.setState({spReviews:resp})})
    }
  }
  getStatisticsValue(data){
    if(data > 100){
        return 100;
    }else if(data === 'NaN'){
        return 0;
    }else{
        return data
    }
  }

  changeMonthBtn=(date)=>{
    this.props.getOfficeTeacherManagerData(date)
  }
  render() {
    const {essReviews, spReviews, midEndReviews, dailyControll} = this.state;
    const {user, officeTeacherManagerData, lang} = this.props
    
    let essReviewsData=[{bold:true, data:["Student", "Title"]}];
    let spReviewsData=[{bold:true, data:["Student", "Title"]}];
    let midEndReviewsData=[{bold:true, data:["Student", "Title"]}];


    let essay_id        = "";
    let final_data      = [];
    let essay_answer_id = "";
    if(essReviews && essReviews.length !== 0 && user){
      let data            = essReviews;
      data.filter(e => {
          essay_id = e.id;
          if (e.reviews.length > 0) {
              e.reviews.filter(review => {
                  if (review.user_id == user.id) {
                      essay_answer_id = review.essay_answer_id;
                  }
              });
          } else final_data.push(e);
      });
    }
    for(const data of final_data){
      essReviewsData.push({bold:false, data: [data?.user?.full_name, data?.essay?.title]})
    }
    for(const data of spReviews){
      if(data.review && data.review.length === 0 && data.is_closed === 0){
        spReviewsData.push({bold:false, data: [data?.user?.full_name, data?.speaking?.title]})
      }
      
    }
    for(const data of midEndReviews){
      midEndReviewsData.push({bold:false, data: [data?.user?.full_name, data?.essay?.title]})
    }


    let monthlyStatus = [];
    monthlyStatus.push({
      bold: false, data: ["All Students", officeTeacherManagerData?.studentCount, '']
    })
    monthlyStatus.push({
      bold: false, data: ["G. Eng. St.", officeTeacherManagerData?.general_english_students, '']
    })
    monthlyStatus.push({
      bold: false, data: ["SAT St.", officeTeacherManagerData?.sat_students, '']
    })
    monthlyStatus.push({
      bold: false, data: ["IELTS St.", officeTeacherManagerData?.ielts_students, '']
    })
    monthlyStatus.push({
      bold: false, data: ["Check-Ins", officeTeacherManagerData?.attendance, '']
    })
    monthlyStatus.push({
      bold: false, data: ["Reports", officeTeacherManagerData?.assessment, `${this.getStatisticsValue(((officeTeacherManagerData.assessment*100)/(officeTeacherManagerData.attendance)).toFixed(0))}%`]
    })
    monthlyStatus.push({
      bold: false, data: ["Essays", `${officeTeacherManagerData.essay} out of ${officeTeacherManagerData.general_english_students*8}`, `${this.getStatisticsValue((((officeTeacherManagerData.essay)*100)/(officeTeacherManagerData.general_english_students*8)).toFixed(0) )}%`]
    })
    monthlyStatus.push({
      bold: false, data: ["Speakings", `${officeTeacherManagerData.speaking} out of ${officeTeacherManagerData.general_english_students*4}`, `${this.getStatisticsValue((((officeTeacherManagerData.speaking)*100)/(officeTeacherManagerData.general_english_students*4)).toFixed(0) )}%`]
    })
    monthlyStatus.push({
      bold: false, data: ["HW G.Eng", `${officeTeacherManagerData.monthly_general_english_homework_taken} out of ${officeTeacherManagerData.general_english_students*15}`, `${this.getStatisticsValue((((officeTeacherManagerData.monthly_general_english_homework_taken)*100)/(officeTeacherManagerData.general_english_students*15)).toFixed(0) )}%`]
    })
    monthlyStatus.push({
      bold: false, data: ["G.Eng Exam", `${officeTeacherManagerData.monthly_general_english_exams_taken} out of ${officeTeacherManagerData.general_english_students*4}`, `${this.getStatisticsValue((((officeTeacherManagerData.monthly_general_english_exams_taken)*100)/(officeTeacherManagerData.general_english_students*4)).toFixed(0) )}%`]
    })
    monthlyStatus.push({
      bold: false, data: ["IELTS Exam", `${officeTeacherManagerData.monthly_ielts_exam_takens} out of ${officeTeacherManagerData.ielts_students*8}`, `${this.getStatisticsValue((((officeTeacherManagerData.monthly_ielts_exam_takens)*100)/(officeTeacherManagerData.ielts_students*8)).toFixed(0) )}%`]
    })
    monthlyStatus.push({
      bold: false, data: ["SAT Exam", `${officeTeacherManagerData.monthly_sat_exam_takens} out of ${officeTeacherManagerData.sat_students*4}`, `${this.getStatisticsValue((((officeTeacherManagerData.monthly_sat_exam_takens)*100)/(officeTeacherManagerData.sat_students*12)).toFixed(0) )}%`]
    })
    monthlyStatus.push({
      bold: false, data: ["HW SAT", `${officeTeacherManagerData.monthly_sat_homework_taken} out of ${officeTeacherManagerData.sat_students*3}`, `${this.getStatisticsValue((((officeTeacherManagerData.monthly_sat_homework_taken)*100)/(officeTeacherManagerData.sat_students*6)).toFixed(0) )}%`]
    });


    let dailyData = [];
    for(const data of dailyControll){
      dailyData.push(
        {
          title: data?.student_name,
          params: [
            {bold:true, data: ["Mode", "Format", "Check-In"]},
            {bold:false, data: [data?.lesson_mode, data?.format, <CheckSvg /> ]}
          ],
        }
      );
    }


    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="gen_stats" />
            <div className='flex mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <MonthlyCalendar onSelect={this.changeMonthBtn.bind(this)} />
            </div>
            <div className='w-full grid-full-320 mt-[42px] items-start'>
              {/* {
                essReviewsData.length !==0?
                <StatisticCard link="/celt-essays" data={essReviewsData} title={lang?.ess_to_review} />:null
              }
              {
                spReviewsData.length !==0?
                <StatisticCard link="/teacher-speakings"  data={spReviewsData} title={lang?.sp_to_review} />:null
              }
              {
               user?.role == "head_teacher" && midEndReviewsData.length !==0?
                <StatisticCard link="/teacher-all-essays" data={midEndReviewsData} title={lang?.mid_end_to_review} />:null
              } */}
              {
                monthlyStatus.length !=0?
                  <StatisticCard data={monthlyStatus} title={lang?.monthly_status} /> :null
              }
            </div>
            {
              dailyData.length !==0?
              <div>
                <h2 className='text-center font-semibold txt-blue mt-[40px] text-[32px]'>{lang?.dailyContrl}</h2>
                <div className='w-full grid-full-320 mt-[22px] items-start'>
                  {
                    dailyData.map((data, i)=>{
                      return(
                        <StatisticCard data={data?.params} key={i} title={data?.title} /> 
                      )
                    })
                  }
                </div>
              </div>:null
            }
           
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  officeTeacherManagerData: state.Data.officeTeacherManagerData,
  lang: state.Data.lang,

});
const mapDispatchToProps = {getTeacherStatsEssReview, getTeacherStatsSpReview, getTeacherStatsMidEndReview, getOfficeTeacherManagerData, getTeacherDailyControll}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherGeneralStats)
