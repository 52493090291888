import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getStudentAssesments } from '../../../../actions/StaffAction';
import { withHooks } from '../../../../actions/withHooks';
import ReviewCard from '../../../../components/cards/ReviewCard';
import LoadMoreBtn from '../../../../components/LoadMoreBtn';
import HeaderButtons from '../../../../components/HeaderButtons';
import { changeStateValue } from '../../../../redux/MainReducer';
import ReportModal from '../../../../components/modals/ReportModal';
import { deleteData } from '../../../../actions/MainAction';
import moment from 'moment';
import { bigAlert, confirmAlert } from '../../../../actions/SweetAlerts';
import EmptyPage from '../../../../components/EmptyPage';
import { insertNewData } from '../../../../actions/MainAction';
export class Reports extends Component {
    state={
        assesments:[]
    }
    componentDidMount(){
        this.props.getStudentAssesments(1, this.props.params.id, '', 'own')
        .then(resp=>{
            this.setState({assesments: resp})
        });
        window.scrollTo(0,0)
    }
    loadMore=(page)=>{
        let oldData= page ===1? []:[...this.state.assesments]
        this.props.getStudentAssesments(page, this.props.params.id, '', 'own')
        .then(resp=>{
            this.setState({assesments: [
                ...oldData,
                ...resp
            ]})
        })
    }
    openModal=(data)=>{
       
        this.props.changeStateValue({
            name: 'reviewModalData',
            value:{
                position: true,
                id: data?.id?data?.id:'',
                date: data?.date?data?.date:moment(new Date()).format("YYYY-MM-DD"),
                homework:data?.home_work?data?.home_work:'0',
                participation:data?.participation?data?.participation:'0',
                performance:data?.performance?data?.performance:'0',
                readingComprehension:data?.reading_comprehension?data?.reading_comprehension:'0',
                listening_skills:data?.listening_skills?data?.listening_skills:'0',
                speakingFluency:data?.speaking_fluency?data?.speaking_fluency:'0',
                writingSkills:data?.writing_skills?data?.writing_skills:'0',
                note: data?.note?data?.note:'',
                file: data?.file?data?.file:'',
                file_full_url: data?.file_full_url?data?.file_full_url:'',
                user_id: this.props.params.id,
                status: data?.status || data?.status==0 ?data?.status:1
            }
        })
    }
    deleteData=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`assessments/${data?.id}`)
                .then(resp=>{
                    if(resp === "success"){
                        bigAlert("Done!", "Report deleted successfully", "success");
                        this.loadMore(1)
                    }else{
                        bigAlert("Error!", "Something went wrong!", "error")
                    }
                })
            }
        })
    }
    onClickApprove=(data)=>{
  
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, approve it!")
        .then(respAlert=>{
            if(respAlert === "success"){
              this.props.insertNewData(`approve-assessments/${data?.id}`, {user_id:data?.user_id})
              .then(resp=>{
                if(resp==="success"){
                  bigAlert("Done!", "Report approved successfully", "success");
                  this.props.getStudentAssesments(1, this.props.params.id, '', 'own')
                    .then(resp=>{
                        this.setState({assesments: resp})
                    });
                  
                }else{
                  bigAlert("Error!", "Something went wrong!", "error")
                }
              })
            }
        })
        
      }
  render() {
    const {assesments} = this.state;
    const {reviewModalData,lang} = this.props;
    // console.log(assesments)
    return (
        <div className='w-full'>
            <div className='flex justify-end'>
                <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: lang?.add_review, id:0}]}  />
            </div>
            {assesments.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='w-full flex flex-col gap-[20px] mt-[20px]'>
            {
                assesments?.map((data, i)=>{
                    return(
                        <ReviewCard 
                            key={i}
                            data={data}
                            onClickEdit={this.openModal.bind(this, data)}
                            onClickDelete={this.deleteData.bind(this, data)}
                            onClickApprove={this.onClickApprove.bind(this, data)}
                        />
                    )
                })
            }
            </div>
            {
                assesments?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={assesments?.length} />:null
            }
            {reviewModalData?.position? <ReportModal callAfterSubmit={()=>this.loadMore(1)} />:null}
            
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    reviewModalData: state.Data.reviewModalData,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getStudentAssesments, changeStateValue, deleteData, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Reports))
