import React, { Component } from 'react'
import Header from './Header'
import HeaderButtons from '../HeaderButtons'
import { connect } from 'react-redux';
import { getStudentWeeklyStats } from '../../actions/StaffAction';
export class WeeklyStatisticsModal extends Component {
    state={
        selected:0,
        buttons:[],
        now: new Date(),
        stats:[]
    }
    getDateBetween(day){
        return new Date(
            this.state.now.getFullYear(),
            this.state.now.getMonth(),
            this.state.now.getDate() - day,
        );
    }
    componentDidMount(){
        this.setState({
            buttons:[
                {title: "This week", date:  {start: this.getDateBetween(this.state.now.getDay()-1), end: this.state.now}},
                {title: "LW 1", date:  {start:  this.getDateBetween(this.state.now.getDay()+6), end: this.getDateBetween(this.state.now.getDay())}},
                {title: "LW 2", date:  {start:  this.getDateBetween(this.state.now.getDay()+15), end: this.getDateBetween(this.state.now.getDay()+7)}},
                {title: "LW 3", date:  {start:  this.getDateBetween(this.state.now.getDay()+20), end: this.getDateBetween(this.state.now.getDay()+14)}},
            ]
        });
        this.props.getStudentWeeklyStats({student_id: this.props.student_id, from_date: this.getDateBetween(this.state.now.getDay()-1), to_date: this.state.now})
        .then(resp=>{
            this.setState({stats:resp})
        })
    }
    closeModal=()=>{
        this.props.closeModal()
    }
    getResultsByDate=(data, i)=>{
        this.setState({selected: i});
        this.props.getStudentWeeklyStats({student_id: this.props.student_id, from_date: data?.date?.start, to_date:  data?.date?.end})
        .then(resp=>{
            this.setState({stats:resp})
        })
    }
  render() {
    const {selected, buttons, stats} = this.state;
    const {lang} = this.props;
    return (
        <div  className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[600px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={"Weekly Statistics"} />
                <div className='p-[20px] max-h-[70vh] overflow-auto'>
                    <div>
                        <HeaderButtons onClick={(data, i)=>this.getResultsByDate(data, i)} buttons={buttons} selected={selected} />
                    </div>
                    <table className="table w-full mt-[20px]">
                        <thead>
                            <tr>
                                <th className='text-start'>{lang?.exam_type}</th>
                                <th  className='text-start'>{lang?.count}</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr className='h-[30px] text-[14px] border-b-[1px]' >
                                <td className='txt-blue font-semibold'>G. En Exam</td>
                                <td className='pl-[20px] text-gray-400 font-[300]'>{stats?.general_english_exam}</td>
                            </tr>
                            <tr className='h-[30px] text-[14px] border-b-[1px]' >
                                <td className='txt-blue font-semibold'>G. En PT</td>
                                <td className='pl-[20px] text-gray-400 font-[300]'>{stats?.general_english_hw}</td>
                            </tr>
                            <tr className='h-[30px] text-[14px] border-b-[1px]' >
                                <td className='txt-blue font-semibold'>IELTS Exam</td>
                                <td className='pl-[20px] text-gray-400 font-[300]'>{stats?.ielts_exam_submit}</td>
                            </tr>
                            <tr className='h-[30px] text-[14px] border-b-[1px]' >
                                <td className='txt-blue font-semibold'>IELTS PT</td>
                                <td className='pl-[20px] text-gray-400 font-[300]'>{stats?.ielts_hw_submit}</td>
                            </tr>
                            <tr className='h-[30px] text-[14px] border-b-[1px]' >
                                <td className='txt-blue font-semibold'>SAT Math Exam</td>
                                <td className='pl-[20px] text-gray-400 font-[300]'>{stats?.sat_exam_submit}</td>
                            </tr>
                            <tr className='h-[30px] text-[14px] border-b-[1px]' >
                                <td className='txt-blue font-semibold'>SAT Math PT</td>
                                <td className='pl-[20px] text-gray-400 font-[300]'>{stats?.sat_hw_submit}</td>
                            </tr>
                            <tr className='h-[30px] text-[14px] border-b-[1px]' >
                                <td className='txt-blue font-semibold'>SAT Verbal Exam</td>
                                <td className='pl-[20px] text-gray-400 font-[300]'>{stats?.sat_verbal_exam_submit}</td>
                            </tr>
                            <tr className='h-[30px] text-[14px] border-b-[1px]' >
                                <td className='txt-blue font-semibold'>SAT Verbal PT</td>
                                <td className='pl-[20px] text-gray-400 font-[300]'>{stats?.sat_verbal_hw_submit}</td>
                            </tr>
                                
                        </tbody>
                    </table>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button  onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang
});
const mapDispatchToProps = {getStudentWeeklyStats}
export default connect(mapStateToProps, mapDispatchToProps)(WeeklyStatisticsModal)
