import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getParentStudents } from '../actions/MainAction';
export class SelectStudent extends Component {
    state={
        name:this.props.lang?.select_student,
        visible:false
    }
    componentDidMount(){
        this.props.getParentStudents();
        window.addEventListener("click", (e)=>{
            if(typeof e.target.className === "string" && !e.target.className.includes("notClosePermission")){
                this.setState({visible:false})
            }
        })
    }
    onSelectStudent(data){
        this.setState({name: data?.first_name+" "+data?.last_name, visible:false});
        this.props.onSelectStudent(data?.id)
    }
  render() {
    const {parentStudents} = this.props;
    const {name, visible} = this.state;
    return (
        <div className='relative h-[61px] bg-white rounded-[30px] block p-[10px]'>
            <button onClick={()=>this.setState({visible: !visible})} className='bg-light-gray relative w-[214px] h-[41px]  max-md:w-[100%] rounded-[30px] flex justify-center items-center  gap-[5px] notClosePermission'>
                <span className='text-center notClosePermission'>{name}</span>
            </button>
            {
                visible?
                <div className='bg-white absolute w-full p-[10px] mt-[14px] left-0 shadow rounded-[20px] notClosePermission'>
                    <ul className='flex flex-col gap-[8px] notClosePermission'>
                        {
                            parentStudents?.map((data, i)=>{
                                return(
                                    <li onClick={this.onSelectStudent.bind(this, data)} key={i} className='bg-light-gray p-[5px] hover:bg-gray-200 text-[14px] pl-[10px] text-start rounded-[200px] cursor-pointer notClosePermission'>
                                        <span className='text-start notClosePermission'>{data?.first_name+" "+data?.last_name}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    parentStudents: state.Data.parentStudents,
    lang: state.Data.lang,
});
const mapDispatchToProps={getParentStudents}
export default connect(mapStateToProps, mapDispatchToProps)(SelectStudent)
