import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getExamPrompts } from '../../actions/AdminActions';
import HeaderButtons from '../../components/HeaderButtons';
import Header from '../../components/header/Header';
import { changeStateValue } from '../../redux/MainReducer';
import Card1 from '../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
export class AdminExamPromptsPage extends Component {
    state={
        activePage:0,
    }
    componentDidMount(){
        this.props.getExamPrompts()
    }
    promptType=()=>{
        const btns = [
            {
                label: "Speakings",
                value: "speaking"
            },
            {
                label: "Writing 1.",
                value: "writing_task_1"
            },
            {
                label: "Writing 2",
                value: "writing_task_2"
            },
        ]
        return btns
    }
    openModal=(data, e)=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
               
                type:data?.type? data?.type:"",
                // first_prompt:data?.first_prompt? data?.first_prompt:'',
                second_prompt:data?.second_prompt? data?.second_prompt:'',
               
                afterCallFunction:this.props.getExamPrompts,
                afterCallParams:[],
                typeOptions: this.promptType(),
                requireds:["course_id", "type", "prompt"],
                url: data?.id? `exam-prompt/${data?.id}`:'exam-prompt',
                modalTitle: data?.id? `Edit Prompt`:'Add Prompt',
            }
        });
    }

    onDeleteData=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`exam-prompt/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getExamPrompts(this.state.pageType)
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }

    getDataByType=(data, index)=>{
        this.setState({activePage:index, pageType: data?.id});
        this.props.getExamPrompts(data?.id)

    }
  render() {
    const {examPrompts, user} = this.props;
    const {activePage} = this.state;
    // console.log(examPrompts)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Prompts" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {/* <HeaderButtons onClick={(data, index)=>this.getDataByType(data, index)} buttons={btns} selected={activePage} /> */}
                <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Exam Prompt", id:0}]} selected={0} />
            </div>
            <div className='mt-[30px] grid-full-250'>
                {
                    examPrompts.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.type}
                                // cheating={btns.find(item=>item?.id==data?.type)?.title}
                                status={1}
                                statusBisible={false}
                                btn={false}
                                btn2={true}
                                btn2Text={"Edit"}
                                btn2Color={"bg-gray-400"}
                                onCLickBtn={this.openModal.bind(this, data)}
                                adminDelete={user?.role === "super_admin"}
                                onDeleteBtn={this.onDeleteData.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examPrompts: state.Data.examPrompts,
    user: state.Data.user,
});
const mapDispatchToProps = {getExamPrompts, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminExamPromptsPage)


