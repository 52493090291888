import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import HeaderButtons from '../../components/HeaderButtons';
import { changeStateValue } from '../../redux/MainReducer';
import TeacherCreateGroupModal from '../../components/modals/TeacherCreateGroupModal';
import { getGroupsLink } from '../../actions/StaffAction';
import CardEditSvg from '../../components/svg/CardEditSvg';
import CardDeleteSvg from '../../components/svg/CardDeleteSvg';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
import TeacherMessageModal from '../../components/modals/TeacherMessageModal';

export class LinkGroups extends Component {

    getStudentId(data){
        let arr = [];
        for(const x of data){
            arr.push(x?.user_id)
        }
        return arr;
    }
    onOpenModal=(data)=>{
        this.props.changeStateValue({
            name:"createGroupModal",
            value:{
                position: true,
                id: data?.id?data?.id:"",
                title: data?.title?data?.title:"",
                students: data?.id?this.getStudentId(data?.users):[]
            }
        })
    }

    componentDidMount(){
        this.props.getGroupsLink()
    }
    onDeleteBtn=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`teacher-group/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getGroupsLink()
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
    removeMsg=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`delete-message-teacher-group/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getGroupsLink()
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
    openMsgModal=(group_id, data)=>{
        this.props.changeStateValue({
            name:"createGroupMessageModal",
            value:{
                position:true,
                id: data?.id?data?.id:'',
                group_id: group_id,
                message: data?.message?data?.message:""
            }
        })
    }
  render() {
    const {createGroupModal, groupsLinks, createGroupMessageModal} = this.props;
    // console.log(groupsLinks)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Portal Link" />
            <div className=' max-[1100px]:mt-[120px] max-[800px]:mt-[180px] flex justify-end mt-[40px]'>
                <HeaderButtons onClick={this.onOpenModal.bind(this, "")} buttons={[{title:"Create new group"}]} />
            </div>
            <div className='mt-[42px] grid-full-320'>
                {
                    groupsLinks?.map((data, i)=>{
                        return(
                            <div key={i} className='bg-white rounded-[8px]'>
                                <div className=' p-[10px] flex justify-between'>
                                    <h3 className='txt-blue font-semibold'>{data?.title}</h3>
                                    <button onClick={this.openMsgModal.bind(this, data?.id)} className='bg-blue text-white p-[4px_7px] rounded-[5px] text-[12px]'>
                                        Add a link
                                    </button>
                                </div>
                                <div className='flex flex-col p-[10px] gap-[7px]'>
                                    {
                                        data?.messages?.map((item, j)=>{
                                            return (
                                               <div  key={j} className='flex justify-between items-center gap-[7px] border-b pb-[4px]'>
                                                    <a target='_blank' href={item?.message} className='cursor-pointer text-blue-600'>
                                                        {item?.message}
                                                    </a>
                                                    <div className='flex gap-[8px] items-center'>
                                                        <button onClick={this.openMsgModal.bind(this, data?.id, item)}>
                                                            <CardEditSvg />
                                                        </button>
                                                        <button onClick={this.removeMsg.bind(this, data?.id)}>
                                                            <CardDeleteSvg />
                                                        </button>
                                                    </div>
                                               </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className='flex justify-between items-center'>
                                    <button onClick={this.onDeleteBtn.bind(this, data?.id)} className='w-1/2 inline-flex justify-center p-[10px_0] border-r border-t '>
                                        <CardDeleteSvg />
                                    </button>
                                    <button onClick={this.onOpenModal.bind(this, data)} className='w-1/2 inline-flex justify-center p-[10px_0] border-t '>
                                        <CardEditSvg />
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            {
                createGroupModal?.position?
                <TeacherCreateGroupModal />:null
            }
            {
                createGroupMessageModal?.position?
                <TeacherMessageModal />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    createGroupModal: state.Data.createGroupModal,
    groupsLinks: state.Data.groupsLinks,
    createGroupMessageModal: state.Data.createGroupMessageModal,
});
const mapDispatchToProps = {changeStateValue, getGroupsLink, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(LinkGroups)
