import React, { Component } from 'react'
import { connect } from 'react-redux';
import { resetStateValue } from '../../redux/MainReducer';
import Header from './Header';
export class PartScoreModal extends Component {
    closeModal=()=>{
        this.props.resetStateValue({name: 'partScoreModal'})
    }
    closeModalShadow(e){
        if(typeof e.target.className == 'string' && e.target.className?.includes("background_shadow")) this.closeModal();
    }
  render() {
    const {partScoreModal, lang} = this.props;
    return (
      <div onClick={this.closeModalShadow.bind(this)} className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[600px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={partScoreModal?.title} />
                <div className='p-[20px]'>
                    <table className='w-full'>
                        <thead className='w-full'>
                            <tr className='w-full'>
                                <th className='w-[50%] text-start text-[16px] font-semibold txt-light-black border p-[7px]'>{lang?.part_title}</th>
                                <th className='w-[25%] text-[16px] font-semibold txt-light-black border p-[7px]'>{lang?.scored}</th>
                                <th className='w-[25%] text-[16px] font-semibold txt-light-black border p-[7px]'>{lang?.max_score}</th>
                            </tr>
                        </thead>
                        <tbody >
                            {
                                partScoreModal?.data?.map((data, i)=>{
                                    return(
                                        <tr key={i} className=' h-[40px]'>
                                            <td className='w-[50%] text-[14px] font-light txt-light-black pl-[7px] border'>{data?.title}</td>
                                            <td className='w-[25%] text-center text-[14px] font-light txt-light-black pl-[7px] border'>{data?.points}</td>
                                            <td className='w-[25%] text-center text-[14px] font-light txt-light-black pl-[7px] border'>{data?.out_of}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    partScoreModal: state.Data.partScoreModal,
    lang: state.Data.lang,
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(PartScoreModal)
