import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import { getPoolingExams } from '../../../actions/AdminActions';
import { withHooks } from '../../../actions/withHooks';
import AddStandartPoolingExam from './modals/AddStandartPoolingExam';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import { deleteData } from '../../../actions/MainAction';

export class PoolingExamParents extends Component {
    componentDidMount(){
        this.props.getPoolingExams(this.props.params.id)
    }

    openModal=(data)=>{
        this.props.changeStateValue({
            name:"examPoolingModaldata",
            value:{
                position:true,
                id: data?.id?data?.id:'',
                exam_id: this.props.params.id,
                category_id: data?.category_id?data?.category_id:'',
                sub_category_id: data?.sub_category_id?data?.sub_category_id:'',
                section_id: data?.section_id?data?.section_id:'',
                count: data?.count?data?.count:'',
            }
        })
    }

    onDeleteData=(id)=>{
        confirmAlert("warning", 'Are you sure?', "You cannot revert this")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`pooling-exam/${id}`)
                .then(resp=>{
                    if(resp === 'success'){
                        this.props.getPoolingExams(this.props.params.id)
                        mixinAlert("success", "Exam deleted successfully");
                    }else{
                        mixinAlert("error", "Something went wrong");
                    }
                })
            }
        })
    }
  render() {
    const {poolingExams, examPoolingModaldata} = this.props;
    // console.log(poolingExams)
    return (
        <div className='bg-white p-[20px] mt-[20px] rounded-[12px] shadow'>
            <div className='flex items-center justify-between'>
                <h2 className='text-[22px] font-semibold txt-blue cursor-pointer'>Pooling Exam Questions</h2>
            </div>
            <div>
                <div className='flex items-center gap-[20px] mt-[20px]'>
                    <button onClick={this.openModal.bind(this, "")} className='h-[35px] pl-[7px] pr-[7px] text-[14px] font-semibold bg-blue text-white rounded-[6px]'>Add Exam Setting</button>
                </div>
                <div className='flex flex-col gap-[20px] bg-gray-100 p-[10px] mt-[20px] rounded-[10px]'>
                    {
                       poolingExams?.map((data, i)=>{
                        return (
                            <div key={i} className='bg-white p-[10px] rounded-[10px] shadow flex justify-between items-center'>
                                <div>
                                    <h3 className='txt-blue text-[16px] font-semibold'>{data?.category?.title} / {data?.sub_category?.title} / {data?.section?.title} / {data?.count}</h3>
                                </div>
                                <div className='flex items-center gap-[10px]'>
                                    <button onClick={this.openModal.bind(this, data)} className='bg-orange text-[12px] text-white rounded-[5px] p-[4px_7px]'>
                                        Edit
                                    </button>
                                    <button onClick={this.onDeleteData.bind(this, data?.id)} className='bg-red text-[12px] text-white rounded-[5px] p-[4px_7px]'>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        )
                       }) 
                    }
                </div>
            </div>
            {
                examPoolingModaldata?.position?
                <AddStandartPoolingExam />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    poolingExams: state.Data.poolingExams,
    examPoolingModaldata: state.Data.examPoolingModaldata,
});
const mapDispatchToProps = {changeStateValue, getPoolingExams, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(PoolingExamParents))
