import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import Search from '../../components/Search'
import { connect } from 'react-redux'
import { getAdminLessons } from '../../actions/AdminActions'
import Card1 from '../../components/cards/Card1'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { deleteData } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer';
import { getCourses } from '../../actions/MainAction'
import DropSearch from '../../components/DropSearch'
import { getDropValue } from '../../actions/getRole'
export class AdminLessons extends Component {
    state={
        page:1,
        search:'',
        filter_id:''
    }
    searchData=(search)=>{
        this.setState({search});
        this.props.getAdminLessons(this.state.page,search, this.state.filter_id)
    }

    componentDidMount(){
        this.props.getAdminLessons(1,'')
        this.props.getCourses()
    }
    getType(){
        return [
            {
                label:"Unit",
                value:"unit",
            },
            {
                label:"End of course",
                value:"end",
            },
            {
                label:"Midterm",
                value:"mid",
            },
        ]
    }
    addBtn=(data="")=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                course_id:data?.course_id? data?.course_id:'',
                title: data?.title? data?.title:'',
                video_file: data?.video_file?data?.video_file:'',
                video_full_url: data?.video_full_url?data?.video_full_url:'',
                typeOptions:this.getType(),
                type: data?.type?data?.type:'unit',
                category: data?.category || data?.category==0?`${data?.category}`:'',
                static_content:null,
                afterCallFunction:this.props.getAdminLessons,
                afterCallParams:[this.state.page, this.state.search, this.state.filter_id],
                requireds:["course_id", "title",],
                url: data?.id? `lessons/${data?.id}`:'lessons',
                modalTitle: data?.id? `Edit Lesson`:'Add Lesson',
            }
        });
    }
    onDeleteBtn=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`lessons/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getAdminLessons(this.state.page,this.state.search, this.state.filter_id)
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
    selectData=(id)=>{
        // console.log(id)
        this.setState({filter_id:id})
        this.props.getAdminLessons(this.state.page, this.state.search, id)
    }
  render() {
    const {adminLessons, studentCourses} = this.props;
    // console.log(adminLessons)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Lessons" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Add a Lesson", id:'0'}]} selected={0} />
            </div>
            <div className='flex justify-center'>
                <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
            </div>
            <div className='flex white-search'>
                <DropSearch selectData={this.selectData.bind(this)} options={getDropValue(studentCourses, "title", "id")} title="Filter by course" placeholder="Filter by course" />
            </div>
            <div className='mt-[30px] grid-full-250'>
            {
              adminLessons?.map((data, i)=>{
                return (
                    <Card1
                      key={i}
                      statusBisible={false}
                      status={1}
                      btn={false}
                      title={data?.title}
                      exam={data?.course?.title}
                      btn2={true}
                      btn2Text={"Edit"}
                      btn2Color={"bg-blue"}
                      adminDelete={true}
                      onCLickBtn={this.addBtn.bind(this, data)}
                      onDeleteBtn={this.onDeleteBtn.bind(this, data?.id)}
                    />
                  )
              })
            }
          </div>
            
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminLessons: state.Data.adminLessons,
    studentCourses: state.Data.studentCourses,
});
const mapDispatchToProps = {getAdminLessons, deleteData, changeStateValue, getCourses}
export default connect(mapStateToProps, mapDispatchToProps)(AdminLessons)
