import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Card2 from '../../components/cards/Card2'
import { connect } from 'react-redux';
import {getHomeWorks} from "../../actions/MainAction";
import {jwtDecode} from "jwt-decode";
import EmptyPage from '../../components/EmptyPage';

export class Homework extends Component {
  componentDidMount(){
    if(localStorage.getItem('userToken')){
      const data = jwtDecode(localStorage.getItem('userToken'));
      this.props.getHomeWorks(data.sub);
    }
    window.scrollTo(0,0)
  }
  render() {
    const {homeworks} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="hw_exams" />
            {homeworks?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-205 max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
              {
                homeworks?.map((data, i)=>{
                  return(
                    <Card2 
                      key={i}
                      title={data?.exam?.title}
                      subTitle={data?.course?.title}
                      btnUrl={`/hw-start/${data?.exam?.id}`}
                    />
                  )
                })
              }
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) =>({
  homeworks: state.Data.homeworks,
});
const mapDispatchToProps = {getHomeWorks}

export default connect(mapStateToProps, mapDispatchToProps)(Homework)
