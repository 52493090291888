import React, { Component } from 'react'

export class ListeningSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        fill="none"
      >
        <g
          stroke="#585874"
          strokeLinecap="round"
          strokeLinejoin="round"
          clipPath="url(#a)"
        >
          <path d="M5.306 7.287A2.117 2.117 0 1 1 9.29 8.62c-.308.972-1.61 1.663-1.835 2.86-.225 1.199-2.443 1.26-2.311-.45" />
          <path d="M8.49 7.665s.16-1.128-.879-1.128c-1.04 0-.866 1.837-1.837 1.837" />
          <path d="M6.202 9.564s-.361.058-.361.625 1.22.516 1.22-.361-.99-1.324-.467-2.213m.895-3.553A3.438 3.438 0 0 1 10.927 7.5" />
          <path d="M7.489 2.376A5.124 5.124 0 0 1 12.613 7.5" />
          <path d="M7.5 14.219A6.719 6.719 0 1 0 7.5.78a6.719 6.719 0 0 0 0 13.438Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h15v15H0z" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default ListeningSvg
