import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateToeflExamWriting } from '../../actions/MainAction';
export class Listening extends Component {
  state={
    audioRef: React.createRef(),
    duration: 100,
    currentDuration: 0,
    buttonVisible: true
  }
  componentDidMount(){
    this.setState({
      duration: 100,
      currentDuration: 0,
    })
    window.addEventListener('beforeunload', (e)=>{
      e.preventDefault();
      localStorage.setItem('audio_duration', this.state.duration)
      localStorage.setItem('audio_current_duration', this.state.currentDuration)
      return ""
    })
  }

  playAudio(audio, e){
    this.setState({
      buttonVisible: false
    })
    if(audio){
      audio.current.play();
      if(localStorage.getItem('audio_duration') && localStorage.getItem('audio_current_duration')){
        this.setState({
          duration: +localStorage.getItem('audio_duration'),
          currentDuration: +localStorage.getItem('audio_current_duration')
        })
        audio.current.currentTime = +localStorage.getItem('audio_current_duration')
      }else{
        setTimeout(()=>{
   
          this.setState({
            duration:audio?.current?.duration
          })
        }, 1000)
      }
     
    }
    
  }

  getTime(audio, e){
    if(audio){
      this.setState({
        currentDuration: audio.current.currentTime
      })
    }
    
  }
  changePart(data, e){
    localStorage.removeItem('audio_duration') 
     localStorage.removeItem('audio_current_duration')
    this.props.updateToeflExamWriting(data.id, +data.part+1)
  }
  render() {
    const {title, url, examData} = this.props;
    const {audioRef, duration, currentDuration, buttonVisible} = this.state;

    return (
      <div>
        <p className='font-bold text-[20px]'>{title}</p>
        <div style={{ display: 'none' }}>
            <audio onPause={this.changePart.bind(this, examData)} onTimeUpdate={this.getTime.bind(this, audioRef)} ref={audioRef} controls src={url} />
        </div>
        <div className='flex flex-col items-center'>
          {
            !buttonVisible?
            <div className='bg-gray-200 w-[300px] rounded-full h-[40px] flex justify-center items-center'>
              <span className='bg-gray-400 inline-block w-[90%] h-[10px] rounded-[6px] relative'>
                <span className='bg-blue inline-block  h-[10px] rounded-[6px] absolute top-0 left-0' style={{ width: `${currentDuration*100/duration}%` }}></span>
              </span>
            </div>:null
          }
          
          {
            buttonVisible?
            <button className='bg-blue w-[80px] h-[30px] text-white rounded-[6px]' onClick={this.playAudio.bind(this, audioRef)}>Play</button>:null
          }
          
        </div>
        {/* ontimeupdate */}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {updateToeflExamWriting}
export default connect(mapStateToProps, mapDispatchToProps)(Listening)