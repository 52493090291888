import React, { Component } from 'react'
import Header from './Header'
import { getActiveCoursesByStudent, getSingleData, getUnlockExams } from '../../actions/StaffAction';
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import DropSearch from '../DropSearch';
import { getDropValue } from '../../actions/getRole';
import { changeStateValue } from '../../redux/MainReducer';
import { getLessons, insertNewData } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/SweetAlerts';
import { getExamSchedule } from '../../actions/StaffAction';
import Card1 from '../cards/Card1';
import { isValidPhoneNumber} from 'libphonenumber-js'
export class ScheduleCourseModal extends Component {
    state={
        courses:[],
        unlockExams:[],
        unlockPermissions:{
            exam_passed: true,
            phone_valid: true
        }
        // lessons:[]
    }
    componentDidMount(){
        this.props.getActiveCoursesByStudent(this.props.params.id)
        .then(resp=>{
            this.setState({courses:resp})
        })
    }
    checkPhoneValid(phone_number=""){
        let checkAzStr = phone_number?.replaceAll(" ", "")?.substring(0,3);
        let checkTrStr = phone_number?.replaceAll(" ", "")?.substring(0,2);
        let checkRuStr = phone_number?.replaceAll(" ", "")?.substring(0,1);
        if(checkAzStr == "994"){
            return isValidPhoneNumber(phone_number, "AZ");
        }else if(checkTrStr == "90"){
            return isValidPhoneNumber(phone_number, "TR");
        }else if(checkRuStr == "7"){
            return isValidPhoneNumber(phone_number, "RU");
        }else{
            return true;
        }
    }
    selectData=(lesson)=>{
        // console.log(lesson)
        // this.props.getLessons(id)
        // .then(resp=>{
        //     // console.log(resp)
        //     const end = resp.find(x=>x.title?.toLowerCase().includes("end"));
        //     if(end){
        //         this.props.getUnlockExams(id, end?.id, this.props.params.id, 0)
        //         .then(resp=>{
        //             this.setState({unlockExams:resp?.data})
        //         })
        //     }
        // })
        this.props.getUnlockExams(lesson?.course_id, lesson?.id, this.props.params.id, 0)
        .then(resp=>{
            this.setState({unlockExams:resp?.data})
        })
        this.props.changeStateValue({
            name:"selectSchedule.course_id",
            value:lesson?.course_id
        });
        this.props.getSingleData(`check-exam-passed?student_id=${this.props.studentData?.id}&unit_id=${lesson?.id}`)
        .then(resp=>{
            this.setState({
                unlockPermissions:{
                    exam_passed: resp?.permission,
                    phone_valid: this.checkPhoneValid(this.props.studentData?.phone_number)
                }
                
            })
            // console.log(resp)
        })
    }
    closeModal=()=>{
        this.props.changeStateValue({
            name:"selectSchedule.modalPosition",
            value:false
        })
    }

    saveBtn=(data, lesson_id, exam_id)=>{
        if(this.state.unlockPermissions?.exam_passed && this.state.unlockPermissions?.phone_valid){
            this.props.insertNewData("exam-schedule", {...data, user_id: this.props.params.id, lesson_id, exam_id})
            .then(resp=>{
                if(resp === "success"){
                    mixinAlert("success", "Exam scheduled successfully.");
                    this.props.getExamSchedule(this.props.selectSchedule.date, this.props.params.id)
                    this.props.changeStateValue({name:"selectSchedule", value:{
                        user_id:"",
                        date:"",
                        time:"",
                        course_id:"",
                        times:[],
                        modalPosition: false
                    }})
                    this.closeModal()
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else {
            if(!this.state.unlockPermissions?.exam_passed){
                mixinAlert("warning", this.props.lang?.valid_exam_pass)
            }else if(!this.state.unlockPermissions?.phone_valid){
                mixinAlert("warning", this.props.lang?.valid_phone_num)
            }
        }
        
    }
  render() {
    const {courses, btnDisabled, unlockExams, unlockPermissions} = this.state;
    const {selectSchedule, lang} = this.props
    const d1 = new Date(`${selectSchedule?.date} ${selectSchedule?.time}`)
    // console.log(unlockExams)
    // console.log(courses)
    let options = [];
    for(const data of courses){
        options.push({
            label: data?.lesson?.title,
            value: data?.lesson
        })
    }
    // console.log()
    return (
        <div className='background_shadow flex items-center justify-center '>
            <div className='bg-white w-[700px]  shadow bg-white rounded-[10px] '>
                <Header btn={false} title={'Select a course'} />
                <div className='p-[20px]'>
                   <DropSearch selectData={this.selectData.bind(this)} placeholder="Select a course" options={options}  />
                   {
                    unlockExams.length !=0?
                    <div className='grid  grid-full-220 mt-[20px] bg-gray-100 p-[10px] rounded-[10px]'>
                    {
                         unlockExams?.map((data, i)=>{

                            return(
                                <Card1
                                    key={i}
                                    title={data?.exam?.title}
                                    status={data.is_block === 1 || data.has_unlock_remaining_time || data.retake_count === data.exam.retake_time?1:2}
                                    statusText={data.is_block === 1 || data.has_unlock_remaining_time || data.retake_count === data.exam.retake_time?lang?.unlocked:lang?.locked}
                                    cheating={data.retake_count === data.exam.retake_time? lang?.status_closed: lang?.open}
                                    timer2={data.retake_count == data.exam.retake_time?"00:00:00":d1 > new Date(new Date(data?.timer_start).getTime() + (data?.exam?.retake_minutes)*60000)?"00:00:00": data.timer_start !== null  ?  data.timer_start: '00:00:00'}
                                    timer2Text={data.retake_count != data.exam.retake_time?lang?.available:lang?.ex_not_ex}
                                    minute={data.exam.retake_minutes}
                                    btn={false}
                                    btn2={true}
                                    canOpen={d1 > new Date(new Date(data?.timer_start).getTime() + (data?.exam?.retake_minutes)*60000) && data.retake_count != data.exam.retake_time}
                                    btn2Text={d1 > new Date(new Date(data?.timer_start).getTime() + (data?.exam?.retake_minutes)*60000) && data.retake_count != data.exam.retake_time?lang?.add_ex_sch:lang?.ex_not_ex}
                                    btn2Color={d1 > new Date(new Date(data?.timer_start).getTime() + (data?.exam?.retake_minutes)*60000) && data.retake_count != data.exam.retake_time? "bg-blue w-[auto_!important]": 'bg-red w-[auto_!important]'}
                                    onCLickBtn={d1 > new Date(new Date(data?.timer_start).getTime() + (data?.exam?.retake_minutes)*60000) && data.retake_count != data.exam.retake_time? this.saveBtn.bind(this,selectSchedule, data?.lesson_id, data?.exam_id):()=>{}}
                                />
                            )
                        })
                    }
                </div>:null
                   }
                   
                   <div className='flex justify-between items-center mt-[20px]'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        {/* <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,selectSchedule)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save}</button> */}
                   </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    selectSchedule: state.Data.selectSchedule,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getActiveCoursesByStudent, changeStateValue, insertNewData, getExamSchedule, getLessons, getUnlockExams, getSingleData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ScheduleCourseModal))
