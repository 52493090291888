import React, { Component } from 'react'

export class RightSvg extends Component {
  render() {
    const {color="#fff"} = this.props;
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      className="icon"
      viewBox="0 0 1024 1024"
      fill={color}
    >
      <path d="M256 120.768 306.432 64 768 512 306.432 960 256 903.232 659.072 512z" />
    </svg>
    )
  }
}

export default RightSvg
