import moment from 'moment';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getExamPartScore } from '../actions/MainAction';
import { changeStateValue } from '../redux/MainReducer';
export class CalendarCell extends Component {
    showExamPart=(data)=>{
        this.props.getExamPartScore({id:data?.id, type:'exam', exam_type:'current'})
        .then(resp=>{
          if(resp == 'success'){
            this.props.changeStateValue({  name:'partScoreModal.position', value:true });
            this.props.changeStateValue({  name:'partScoreModal.title', value:data?.title});
          }
        })
    }
  render() {
    const {type, date, day, checkinDates, paymentMode=false, addNotePayment=()=>{}, checkinMode=false, addCheckin=()=>{}, typeMini=false, selectCurrentDate=()=>{}, miniCell=false} = this.props;
    const today = new Date();
    return (
        <li className={`flex justify-between p-[10px] items-start  ${typeMini?'':'h-[120px] border'}  calendar-cell `}>
            <ul className={`flex flex-col gap-[7px] max-h-[100px]  overflow-auto `}>
                {
                    checkinDates?.map((data, i)=>{
                        if(!paymentMode){
                            if(data?.date == date){
                                return(
                                    <li onClick={()=>this.props.openModalBtn(data)} key={i} className={`${data?.paid === 1? 'bg-blue':data?.paid === 0 && data?.manually_add == 0? 'bg-blue': 'bg-blue'}  pt-[1px]  max-[420px]:pl-[3px]  max-[420px]:pr-[3px] max-[420px]:text-[8px] pb-[0px] pl-[7px] pr-[7px] rounded-[3px] text-white font-regular text-[11px] text-center cursor-pointer`}>
                                        <span>{moment(data?.created_at).format("HH:m")}</span>
                                    </li>
                                )
                            }
                        }else{
                            if(!data?.title){
                                if(data?.date == date){
                                    return(
                                        <li onClick={()=>this.props.openModalBtn(data)} key={i} className={`${data?.paid === 1? 'bg-blue':data?.paid === 0 && data?.manually_add == 0? 'bg-blue': 'bg-blue'}  pt-[1px]  max-[420px]:pl-[3px]  max-[420px]:pr-[3px] max-[420px]:text-[8px] pb-[0px] pl-[7px] pr-[7px] rounded-[3px] text-white font-regular text-[11px] text-center cursor-pointer`}>
                                            <span>{data?.teacher?.full_name}</span>
                                        </li>
                                    )
                                }
                            }else{
                                if(data?.date == date){
                                    if(data?.score){
                                        return(
                                            <li onClick={this.showExamPart.bind(this, data)} key={i} className={`${data?.status==1?"bg-green-700":"bg-[#FFCE20]"} pt-[1px]  max-[420px]:pl-[3px]  max-[420px]:pr-[3px] max-[420px]:text-[8px] pb-[0px] pl-[7px] pr-[7px] rounded-[3px] text-white font-regular text-[11px] text-center cursor-pointer`}>
                                                <span>{data?.title} ({data?.score})</span>
                                            </li>
                                        )
                                    }else{
                                        return(
                                            <li onClick={()=>this.props.openModalBtn(data)} key={i} className={`bg-gray-400  pt-[1px]  max-[420px]:pl-[3px]  max-[420px]:pr-[3px] max-[420px]:text-[8px] pb-[0px] pl-[7px] pr-[7px] rounded-[3px] text-white font-regular text-[11px] text-center cursor-pointer`}>
                                                <span>{data?.title}</span>
                                            </li>
                                        )
                                    }
                                    
                                }
                            }
                            
                        }
                        
                    })
                }
            </ul>
            <span onClick={paymentMode? ()=>addNotePayment(date):checkinMode?()=>addCheckin(date):typeMini?()=>selectCurrentDate(date):()=>{}} className={`font-regular text-[16px] cursor-pointer ${moment(today).format("YYYY-MM-DD") !== date? type == 'current'?'txt-light-black': 'text-gray-300':''} ${moment(today).format("YYYY-MM-DD") == date? `w-[30px] min-w-[30px] text-center rounded-full h-[30px] pt-[3px] text-white bg-blue ${typeMini?'ml-[-7px] mt-[-4px]':''}`: ''}`}>{day}</span>
        </li>
    )
  }
}

const mapStateToProps = (state)=>({});
const mapDispatchToProps = {getExamPartScore, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(CalendarCell)
