import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import { getCoursesByStudent } from '../../../actions/StaffAction';
import Card2 from '../../../components/cards/Card2';
import { insertNewData, getCourses } from '../../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';
import DropSearch from '../../../components/DropSearch';
import Header from '../../../components/modals/Header';
export class StudentEnrolls extends Component {
    state={
        courses:[],
        course_id:''
    }
    componentDidMount(){
        this.props.getCoursesByStudent(this.props.params.id)
        .then(resp=>{
            this.setState({courses: resp})
        })
        this.props.getCourses()
    }

    deleteBtn=(data, e) =>{
        e.preventDefault();
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(alertResp=>{
            if(alertResp === "success"){
                const url = `users/${data.pivot.user_id}/courses/${data.pivot.course_id}`;
                const params = {
                    type: 'detach',
                    course_id: ''
                }
                this.props.insertNewData(url, params)
                .then(resp=>{
                    if(resp === "success"){
                        this.props.getCoursesByStudent(this.props.params.id)
                        .then(resp=>{
                            this.setState({courses: resp})
                        })
                        mixinAlert("success", "Course deleted successfully.");
                    }else{
                        mixinAlert("error", "Something went wrong");
                    }
                })
            }
        })
    }
    selectCourse=(id)=>{
        this.setState({course_id:id})
    }
    enrollBtn=(course_id)=>{
        if(course_id !== ""){
            const url = `users/${this.props.params.id}/courses/${course_id}`;
            const params = {
                type: 'attach',
                course_id: course_id
            }
            this.props.insertNewData(url, params)
            .then(resp=>{
                if(resp === "success"){
                    this.props.closeModal()
                    this.props.getCoursesByStudent(this.props.params.id)
                    .then(resp=>{
                        this.setState({courses: resp})
                    })
                    mixinAlert("success", "Course enrolled successfully.");
                }else{
                    mixinAlert("error", "Something went wrong");
                }
            })
        }
        
    }
  render() {
    const {courses, course_id} = this.state;
    const {studentCourses, lang, user} = this.props;
    let options = [];
    for(const property of studentCourses){
        if(property.lesson_mode?.length==0){
            options.push({
                label: property.title,
                value: property.id
            })
        }
        
    }
    // console.log(courses)
    return (
      <div className='background_shadow flex items-center justify-center pl-[20px] pr-[20px]'>
        <div className='bg-white  max-h-[70vh] w-[966px] overflow-auto rounded-[10px] mt-[30px]'>
            <Header closeBtn={()=>this.props.closeModal()} title={lang?.course_enroll} />
            <div className='p-[20px] bg-white rounded-[10px] mt-[20px] flex gap-[20px] max-sm:flex-col items-center'>
                <DropSearch selectData={this.selectCourse.bind(this)} placeholder={lang?.select_course} options={options}/>
                <button onClick={this.enrollBtn.bind(this, course_id)} className='bg-blue p-[6px] text-[14px] text-white font-semibold rounded-[6px]'>
                   {lang?.enrol_course}
                </button>
            </div>
            <div className='mt-[40px]  grid-full-205 p-[20px]'>
                {
                    courses?.map((data, i)=>{
                        return(
                            <Card2
                                key={i}
                                title={data?.title}
                                btnTitle={lang?.delete}
                                btn={studentCourses.find(item=>item?.id==data?.id)?.lesson_mode?.length==0 || user?.role == "super_admin"?true:false}
                                status={0}
                                onClickBtn={this.deleteBtn.bind(this, data)}
                                shadow={true}
                            />
                        )
                    })
                }
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    studentCourses: state.Data.studentCourses,
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {getCoursesByStudent, insertNewData, getCourses}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentEnrolls))
