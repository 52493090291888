import React, { Component } from 'react'

import Search from './Search'
import PageButtons from './PageButtons'
import SideBarHeader from './SideBarHeader'
import { connect } from 'react-redux'
import { getChatUsers, getGroupUsers, getMessageAllUsers, getMsgBoxData, getGroupMsgBoxData } from '../../actions/MessageAction' 
import UserCard from './UserCard'
import moment from 'moment'
import { changeStateValue } from '../../redux/MainReducer'
import MsgCreateGroupModal from '../modals/MsgCreateGroupModal';
import { insertNewData, getUnreadMessageCount } from '../../actions/MainAction'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
export class SideBar extends Component {
  state={
    page:'chat',
    search:'',
    searchLast:'',
    loadPage:1,
    oldData:[],
    groupModal:{position:false}
  }
    componentDidMount(){
        this.props.getChatUsers();
        this.props.getGroupUsers();
        this.props.getMessageAllUsers(1, '');
    }
    openMsgBox=(data, type, e)=>{
    
      if(e === "delete"){
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(respAlert=>{
          if(respAlert === "success"){
            this.props.insertNewData(`delete-msg-group/${data?.id}`)
            .then(resp=>{
              if(resp === "success"){
                this.props.changeStateValue({name:'msgBoxInfo', value:''});
                this.props.getGroupUsers();
                mixinAlert("success", "Group Deleted successfully!");
              }else{
                mixinAlert("error", "Something went wrong!");
              }
            })
          }
        })
        return
      }
      this.props.changeStateValue({name:'msgBoxInfo', value:''})
      setTimeout(()=>{
        // this.props.getUnreadMessageCount()
        if(type === "chat"){
          this.props.getMsgBoxData(data?.id);
          this.props.getChatUsers();
          this.props.changeStateValue({name:'msgBoxInfo', value:data})
        }else if(type === "group"){
          this.props.getGroupMsgBoxData(data?.id, 1);
          this.props.getGroupUsers();
          this.props.changeStateValue({name:'msgBoxInfo', value:{...data, lesson_mode:data?.group_name, type: "group"}})
        }
      }, 100)
    }
    filterMessages(messages){
      let msg = [...messages]
      if(msg.length !== 0){
        return msg.sort((a, b) => b.last_msg.id - a.last_msg.id)
      }else{
        return[]
      }
    }
    onSearchData=(page,  search, e)=>{
      e.preventDefault();
      if(search.trim() !== ""){
        if(page === "chat"){
          this.setState({searchLast: search})
        }else{
          this.props.getMessageAllUsers(1, search);
        }
      }
    }
    onChangeSearch=(search, page)=>{
      this.setState({search:search})
      if(search === ""){
        if(page === "chat"){
          this.setState({searchLast: search})
        }else{
          this.props.getMessageAllUsers(1, search);
          this.setState({searchLast: search})

        }
      }
    }
    scrollSide=(e)=>{
      if(this.props.msgAllUsers.length % 10 === 0 && this.state.oldData !== this.props.msgAllUsers.length){
        if(e.target.scrollHeight - e.target.clientHeight  <= e.target.scrollTop){
          if(this.state.page === "contacts"){
            let page = this.state.loadPage;
            page++;
            this.props.getMessageAllUsers(page, this.state.searchLast);
            this.setState({loadPage: page, oldData: this.props.msgAllUsers.length})
          }
        }
      }
    }
   
  render() {
    const {chatUsers, groupUsers, msgAllUsers, visible} = this.props;
    const {page, search, searchLast, groupModal} = this.state;
    return (
      <div className={`bg-white rounded-[12px] ${visible? "min-w-[289px] max-w-[289px]":"w-full"}  pb-[22px] `}>
       <SideBarHeader  openModal={()=>this.setState({groupModal: {position:true}})} />
       <Search value={search} onChange={(value)=>{this.onChangeSearch(value, page)}} onSubmit={this.onSearchData.bind(this, page, search)} />
       <PageButtons onChangePage={(data)=>this.setState({page:data, search:'', searchLast:''})} />
        <div onScroll={this.scrollSide.bind(this)} className='mt-[35px] h-[60vh] overflow-auto'>
          {
            page === "chat"?
            <>
            {
              groupUsers.map((data, i)=>{
                if(  data?.group_name.toLowerCase().includes(searchLast.toLowerCase()))
                return(
                  <UserCard 
                    key={i}
                    title={data?.group_name}
                    subTitle={data?.last_msg?.message}
                    date={moment(data?.last_msg?.created_at).format("DD/MM/YYYY  HH:mm")}
                    count={data?.count}
                    type="group"
                    onClick={this.openMsgBox.bind(this, data, "group")}
                  />
                )
              })
            }
            {
              this.filterMessages(chatUsers).map((data, i)=>{
                if(
                  data?.group?.student?.full_name.toLowerCase().includes(searchLast.toLowerCase()) || 
                  data?.group?.teacher?.full_name.toLowerCase().includes(searchLast.toLowerCase()) || 
                  data?.group?.lesson_mode.toLowerCase().includes(searchLast.toLowerCase()) || 
                  data?.group?.student?.email.toLowerCase().includes(searchLast.toLowerCase()) || 
                  data?.group?.teacher?.email.toLowerCase().includes(searchLast.toLowerCase())
                )
                return(
                  <UserCard 
                    key={i}
                    title={data?.group?.student?.full_name}
                    subTitle2={data?.group?.teacher?.full_name}
                    subTitle={data?.group?.lesson_mode}
                    date={moment(data?.last_msg?.created_at).format("DD/MM/YYYY  HH:mm")}
                    count={data?.count}
                    type="chat"
                    onClick={this.openMsgBox.bind(this, data?.group, "chat")}
                  />
                )
              })
            }
            </>:
            <>
            {
              msgAllUsers.map((data, i)=>{
                return(
                  <UserCard 
                    key={i}
                    title={data?.student?.full_name}
                    subTitle2={data?.teacher?.full_name}
                    subTitle={data?.lesson_mode}
                    type="chat"
                    onClick={this.openMsgBox.bind(this, data, "chat")}
                  />
                )
              })
            }
            </>
          }
           
        </div>
        {groupModal?.position? <MsgCreateGroupModal onClose={()=>this.setState({groupModal: {position:false}})} />:null}
        
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    chatUsers: state.Data.chatUsers,
    groupUsers: state.Data.groupUsers,
    msgAllUsers: state.Data.msgAllUsers,
});
const mapDispatchToProps = {getChatUsers, getGroupUsers, getMessageAllUsers, getMsgBoxData,insertNewData,getUnreadMessageCount, changeStateValue, getGroupMsgBoxData}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
