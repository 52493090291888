import React, { Component } from 'react'

export class Part1Intro extends Component {
  render() {
    return (
        <div className='w-full flex flex-col gap-[10px] justify-center items-center'>
            <h3 className='text-center text-[28px] uppercase font-[700]'>Part 1 Introduction and Interview</h3>
            <div className='flex flex-col gap-[14px] mt-[20px] items-center'>
                <h3 className='text-center text-[28px] uppercase font-[700]'>Instruction</h3>
                <p  className='text-center'>In this first part, the examiner will ask you some questions about yourself. </p>
                <p  className='text-center'>DO NOT give out real personal information on your answers.</p>
                <button onClick={()=>this.props.onChangePage()} className='bg-blue p-[4px_10px] text-[14px] text-white rounded-[5px]'>Start now</button>
            </div>
            
            
        </div>
    )
  }
}

export default Part1Intro
