import React, { Component } from 'react'

export class CardPayModeSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={12}
        fill="none"
      >
        <path fill="#1E398F" d="M6 8a2 2 0 1 0 0-4v4Z" />
        <path
          fill="#1E398F"
          fillRule="evenodd"
          d="M6 1a5 5 0 1 0 0 10A5 5 0 0 0 6 1Zm0 1v2a2 2 0 1 0 0 4v2a4 4 0 0 0 0-8Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default CardPayModeSvg
