import React, { Component } from 'react'
import Recorder from './Recorder'

export class SpeakingSection extends Component {
    componentWillUnmount(){
        this.setState = (state,callback)=>{
            return;
        };
    }
  render() {
    const {title = '', p1 = '', p2 = '', p3 = '', p4 = '', p5 = '',p6='', p7='', p8='', section1Desc=false, time, continueBtn, record=false} = this.props
    return (
      <div>
        {
            title != ''?
            <h2 className='writin_direction_title'>{title}</h2>:null
        }
        
        <div className='writing_direction_body'>
            {
                p1 !=''?
                <p>{p1}</p>:null
            }
            {
                p2 !=''?
                <p>{p2}</p>:null
            }
            {
                p3 !=''?
                <p>{p3}</p>:null
            }
            {
                p4 !=''?
                <p>{p4}</p>:null
            }
            {
                p5 !=''?
                <p>{p5}</p>:null
            }
            
            {
                p6 !=''?
                <p>{p6}</p>:null
            }
            
            {
                p7 !=''?
                <p>{p7}</p>:null
            }
            
            {
                p8 !=''?
                <p>{p8}</p>:null
            }
            
            {
                section1Desc?
                <div className='sec1_desc_cont'>
                    <p>"Describe the city you live in"</p>
                </div>:null
            }
            {
              record?
              <Recorder time={time} continueBtn={continueBtn} section1={true}/>:null
            }
            
            
            <span>(Click on <b>Continue</b> at any time to dismiss these directions.)</span>
        </div>
      </div>
    )
  }
}

export default SpeakingSection