import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import ImageSvg from '../../../components/svg/ImageSvg';
import Input from '../../../components/modals/components/Input';
import DropSearch from '../../../components/DropSearch';
import { mixinAlert } from '../../../actions/SweetAlerts';
import { insertDataWithParams } from '../../../actions/MainAction';
import { withHooks } from '../../../actions/withHooks';
import Header from '../../../components/modals/Header';
import InputPhone from '../../../components/InputPhone';
import { getCompanies } from '../../../actions/AdminActions';
import { getDropValue } from '../../../actions/getRole';
import CheckSvg from '../../../components/svg/CheckSvg';
import SendVerifyBtn from '../../../components/SendVerifyBtn';
export class StudentEditData extends Component {
    componentDidMount(){
        this.props.getCompanies(10, '');

    }
    selectPayMode=(data)=>{
      
        this.props.changeStateValue({
            name: 'editStudentParams.payment_Reminder_max_value',
            value: data
       })
       this.props.changeStateValue({
            name: 'editStudentParams.payment_Reminder_min_value',
            value: data==="4"?"3":data==="8"?"6":data==="12"?"9":data==="24"?"21":""
       })
    }
    changeStudentCheck=(e)=>{
        this.props.changeStateValue({
            name: 'editStudentParams.send_email_status',
            value: e.target.checked? 'true':'false'
       })
    }

    saveBtn(data){
        if(data?.first_name !== '' && data?.last_name !== "" && data?.email !== "" && data?.date_of_birth !== "" && data?.phone_number !== "" && data?.payment_Reminder_max_value !== "" && data?.where_hear_about && data?.where_hear_about !=""){
            if(data?.id === ""){
                if(!this.props.passwordPermission){
                  mixinAlert("info",this.props.lang?.min_8_words);
                    return  
                }
            }else if(data?.id !== "" && data?.password !==""){
                if( !this.props.passwordPermission){
                    mixinAlert("info",this.props.lang?.min_8_words);
                      return  
                  }
            }
            if(data["email"] && !data?.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                mixinAlert("warning", 'Email should be valid format')
                return
            }
            
            let formData = new FormData();
            for(const property in data){
                if(data[property]){
                    formData.append(property, data[property])
                }
                
            }
         
            let url = "users"
            if(data?.id !== ""){
                formData.append("_method", "put");
                url = `users/${data?.id}`
            }
            this.props.insertDataWithParams(url, formData)
            .then(resp=>{
                if(resp?.msg === "success"){
                    mixinAlert("success", `Student ${data?.id !== ""? "edited":"added"} successfully.`);
                    if(resp?.data?.data){
                        this.props.navigate(`/students/${resp?.data?.data?.id}/edit`);
                      
                    }
                    this.props.closeModal()
                }else{
                    mixinAlert("error", `Email already exists`)
                }
            })
        }else{
            mixinAlert("info", "Please fill the all required inputs");
        }
    }
    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = () => {
                this.props.changeStateValue({name: "editStudentParams.avatar_full_url", value: reader.result})
                res("success");
            }
            reader.readAsDataURL(file)
        })
    }
    setImg(e){
        this.getBase64(e.target.files[0])
        .then(resp=>{
            if(resp === "success"){
                this.props.changeStateValue({name: "editStudentParams.avatar_url", value: e.target.files[0]})
            }
        })
    }
  render() {
    const {editStudentParams, lang, user, adminCompanies} = this.props;
    const payMode = [
        { label: "Standart (12)", value: "12" },
        { label: "Intensive (24)", value: "24" },
        { label: "Businnes II (8)", value: "8" },
        { label: "Businnes I (4)", value: "4" },
    ]
    const whereHear = [
        { label: "Onlayn Axtarış (məs., Google, Bing)", value: 1 },
        { label: "Sosial Media (məs., Facebook, Instagram, Twitter)", value: 2 },
        { label: "CELT veb saytı", value: 3 },
        { label: "Dost və ya Ailə Üzvü", value: 4 },
        { label: "Onlayn Reklam", value: 5 },
        { label: "Çap Reklamı", value: 6 },
        { label: "Televiziya və ya Radio Reklamı", value: 7 },
        { label: "Təhsil sərgisi və ya seminar", value: 8 },
        { label: "Broşur və ya Flyerlar", value: 9 },
        { label: "Tövsiyə vasitəsilə", value: 10 },
        { label: "Digər", value: 11 },
    ]
    let optionSchoolYearArr = [];
    for(let i =1; i <=12; i ++){
          const params = {
               label: i,
               value: `${i}`
          };
          optionSchoolYearArr.push(params)
    }
// console.log(editStudentParams)
    return (
     <div className='background_shadow flex items-center justify-center pl-[20px] pr-[20px]'>
        <div className='bg-white  max-h-[70vh] w-[966px] overflow-auto rounded-[10px] mt-[30px]'>
          <Header closeBtn={()=>this.props.closeModal()} title={lang?.student_per_info} />
            <div className='p-[20px]'>
                
                <div className='flex justify-center'>
                    <label htmlFor='profileImgId' className='cursor-pointer inline-flex items-center flex-col gap-[5px]'>
                        {
                            editStudentParams?.avatar_full_url?
                            <img alt={editStudentParams?.full_name} src={editStudentParams?.avatar_full_url} className='w-[99px] h-[99px] rounded-full' />:
                            <div className='w-[99px] h-[99px] bg-gray-300 rounded-full flex items-center justify-center'>
                                <ImageSvg />
                            </div>
                        }
                        <span className='txt-blue font-bold text-[14px]'>{lang?.upload_img}</span>
                    </label>
                </div>
                <input onChange={this.setImg.bind(this)} accept='image/*' id='profileImgId' type='file' style={{ display:'none' }} />
                <div className='grid grid-cols-4 mt-[30px] gap-[30px] max-lg:grid-cols-2 max-sm:grid-cols-1'>
                    <Input required title={lang?.first_name} value={editStudentParams?.first_name} name="editStudentParams.first_name" />
                    <Input required title={lang?.last_name} value={editStudentParams?.last_name} name="editStudentParams.last_name" />
                    <InputPhone required title={lang?.phone_number} value={editStudentParams?.phone_number} name="editStudentParams.phone_number" type="tel" />
                    <InputPhone  title={lang?.phone_number+" 2"} value={editStudentParams?.parent_number} name="editStudentParams.parent_number" type="tel" />
                    <Input required title={lang?.email} value={editStudentParams?.email} name="editStudentParams.email" type="email" />
                    <Input required title={lang?.date_of_birth} value={editStudentParams?.date_of_birth} name="editStudentParams.date_of_birth" type="date" />
                    <Input title={lang?.password} required={editStudentParams?.id ===""} value={editStudentParams?.password} name="editStudentParams.password" type="password" />
                    {
                        user?.role == "super_admin"?
                        <DropSearch required value={editStudentParams?.company_id} name="editStudentParams.company_id" placeholder={"Select a company"} options={getDropValue(adminCompanies, "name","id")}  title={"Select a company"} />:null
                    }
                   
                    <DropSearch required value={editStudentParams?.payment_Reminder_max_value} placeholder={lang?.payy_mode} options={payMode} selectData={this.selectPayMode.bind(this)} title={lang?.payy_mode} />
                    <DropSearch value={editStudentParams?.school_year} placeholder={lang?.sch_year} name="editStudentParams.school_year" options={optionSchoolYearArr} title={lang?.sch_year} />
                  
                </div>
                <div className='mt-[15px] grid grid-cols-2 max-[640px]:grid-cols-1 pr-[20px] max-[640px]:pr-[0px]'>
                    <DropSearch required value={editStudentParams?.where_hear_about?editStudentParams?.where_hear_about:''} placeholder={"How did you hear about us?"} name="editStudentParams.where_hear_about" options={whereHear} title={"How did you hear about us?"} />
                </div>
                <div className='flex justify-between items-center mt-[30px] '>
                    <div className='flex items-center gap-[10px]'>
                        <input checked={editStudentParams?.send_email_status === 0 || editStudentParams?.send_email_status === "false"? false:true} onChange={this.changeStudentCheck.bind(this)} className='cursor-pointer' id='emailSendStatId' type='checkbox' />
                        <label htmlFor='emailSendStatId' className='cursor-pointer'>{lang?.send_email_student}</label>
                    </div>
                    <SendVerifyBtn data={editStudentParams} />
                </div>
                <div className='mt-[20px] flex justify-between items-center'>
                    <button onClick={()=>this.props.closeModal()} className='bg-red w-[120px] h-[25px] text-[12px] text-white rounded-[5px] font-semibold'>
                        {lang?.close}
                    </button>
                    <button onClick={this.saveBtn.bind(this, editStudentParams)} className='bg-blue w-[120px] h-[25px] text-[12px] text-white rounded-[5px] font-semibold'>
                       {editStudentParams?.id !==""?lang?.update:lang?.save} 
                    </button>
                </div>
            </div>
        </div>
     </div>
    )
  }
}
const mapStateToProps = (state) =>({
    editStudentParams: state.Data.editStudentParams,
    lang: state.Data.lang,
    user: state.Data.user,
    adminCompanies: state.Data.adminCompanies,
    passwordPermission: state.Data.passwordPermission,
});
const mapDispatchToProps = {changeStateValue, insertDataWithParams, getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentEditData))
