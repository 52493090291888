import React, { Component } from 'react';
import logo from "../../images/logo.png";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOutPublic } from '../../actions/PublicActions';
import { withHooks } from '../../actions/withHooks';
import MenuBarSvg from '../../components/svg/MenuBarSvg';
import MenuCloseSvg from '../../components/svg/MenuCloseSvg';
export class Header extends Component {
    state={
        navBtn:true
    }
    closeNav(){
        const navCheck = document.querySelector('#publicNav');
        if(navCheck){
            navCheck.checked = false;
            this.setState({navBtn:true})
        }
    }
  render() {
    const {publicUser} = this.props;
    const {navBtn} = this.state;
    return (
        <div className='fixed w-full h-[89px] bg-[#fff] flex z-50 shadow items-center justify-center'>
            <div className='container flex items-center justify-between max-[660px]:pl-[20px] max-[660px]:pr-[20px] max-[660px]:justify-center'>
               <Link to="/">
               <img className='w-[185px] h-[58px]' src={logo} alt="Logo"/>
               </Link>
               <input type='checkbox' id='publicNav' className='hidden' />
                <div className='public_nav' onClick={this.closeNav.bind(this)}>
                    <ul className='flex gap-[30px] items-center'>
                        {/* <li>
                            <Link className={'font-regular'} to="/exam-results">Exam Results</Link>
                        </li> */}
                        <li>
                            <Link className={'font-regular'} to="/accepted-students">Students</Link>
                        </li>
                        <li>
                            <a className={'font-regular'} target='_blank' href="https://celt.az/contactus/">Centers</a>
                        </li>
                        {
                            publicUser!==""?
                            <li>
                                <Link className={'font-regular'} to="/profile">Profile</Link>
                            </li>:null
                        }
                        <li>
                            {
                                publicUser === ""?
                                <Link className='w-[121px] h-[35px] bg-blue rounded-[218px] inline-flex justify-center items-center text-white font-regular text-[16px]' to="/login">Sign in</Link>:
                                <button  onClick={()=>this.props.logOutPublic().then(resp=>{this.props.navigate("/")})}  className='w-[121px] h-[35px] bg-blue rounded-[218px] inline-flex justify-center items-center text-white font-regular text-[16px]'>Log out</button>
                            }
                            
                        </li>
                    </ul>
                </div>
                <label onClick={()=>this.setState({navBtn:!navBtn})} htmlFor='publicNav' className='hidden absolute right-[20px] max-[1100px]:inline-block'>
                    {navBtn?<MenuBarSvg />: <MenuCloseSvg/>}
                    
                </label>
            </div>
        </div>
      
    )
  }
}
const mapStateToProps = (state) =>({
    publicUser: state.Data.publicUser
});
const mapDispatchToProps = {logOutPublic}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Header))
