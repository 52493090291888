import React, { Component } from 'react'
import BottomSvg from '../../../components/svg/BottomSvg'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import DropSearch from '../../../components/DropSearch';
import Input from '../../../components/modals/components/Input';
import FileInput from '../../../components/FileInput';
import TextEditor from '../../../components/TextEditor';
import { getExamCategories, getSubByCategory } from '../../../actions/StaffAction';
import { getDropValue } from '../../../actions/getRole';
import { insertDataWithParams } from '../../../actions/MainAction';
import { withHooks } from '../../../actions/withHooks';
import { mixinAlert } from '../../../actions/SweetAlerts';

export class ExamParentContainer extends Component {
    state={
        dropVisible:false,
        btnDisabled:false,
        filesVisible:false
    }
    levelOptions=()=>{
        return [
            {
                label:"Easy",
                value:"easy"
            },
            {
                label:"Medium",
                value:"medium"
            },
            {
                label:"Hard",
                value:"hard"
            },
        ]
    }

    typeOptions=()=>{
        return [
            {
                label:"Writing",
                value:"writing"
            },
            {
                label:"Reading",
                value:"reading"
            },
            {
                label:"Listening",
                value:"listening"
            },
            {
                label:"Speaking",
                value:"speaking"
            },
        ]
    }
    componentDidMount(){
        this.props.getExamCategories()
    }
    closeModal=()=>{

    }
    addParent=(data)=>{
        if(data?.title !=""){
            this.setState({btnDisabled:true});
            let formData = new FormData();
            // console.log(data)
            for(const property in data){
                if(data[property]){
                    formData.append(property, data[property]);
                }
            }
            let url = "pooling-exam-questions";
            if(this.props.params.id !== 'create'){
                url=`pooling-exam-questions/${this.props.params.id}`;
                formData.append("_method", "put")
            }
            this.props.insertDataWithParams(url, formData)
            .then(resp=>{
                if(resp?.msg == "success"){
                    this.props.navigate(`/exam-pooling-questions/${resp?.data?.data?.id}`);
                    mixinAlert("success", "Exam created successfully")
                }else{
                    mixinAlert("error", 'Something went wrong')
                }
                // console.log(resp)
            })
        }
        // console.log(data)
    }

    onSelectCategory=(id)=>{
        this.props.getSubByCategory(id)
    }
  render() {
    const {poolingExamParentData, examCategories, examSubCategories} = this.props;
    const {dropVisible, btnDisabled, filesVisible} = this.state;
    // console.log(poolingExamParentData)
    return (
        <div className='bg-white p-[20px] shadow rounded-[10px]'>
            <div onClick={()=>this.setState({dropVisible: !dropVisible})} className='flex w-full justify-between items-center cursor-pointer'>
                <h3 className='text-[20px] font-semibold'>{poolingExamParentData?.title && poolingExamParentData?.title !=""? poolingExamParentData?.title:"Create a parent"}</h3>
                <button>
                    <BottomSvg />
                </button>
            </div>
            {
                dropVisible?
                <div>
                    <div className='grid grid-full-320 mt-[30px]'>
                        <Input name="poolingExamParentData.title" value={poolingExamParentData?.title} title="Title" />
                        {/* <Input name="poolingExamParentData.instruction" value={poolingExamParentData?.instruction} title="Instruction" /> */}
                        <DropSearch options={getDropValue(examCategories, "title", "id")} selectData={this.onSelectCategory.bind(this)} name="poolingExamParentData.category_id" placeholder="Select a category" value={poolingExamParentData?.category_id} title="Select a category" />
                        <DropSearch options={getDropValue(examSubCategories, "title", "id")} name="poolingExamParentData.sub_category_id" placeholder="Select a sub category" value={poolingExamParentData?.sub_category_id} title="Select a sub category" />
                        <DropSearch options={this.levelOptions()} name="poolingExamParentData.level" placeholder="Select a level" value={poolingExamParentData?.level}  title="Select a level" />
                        <DropSearch options={this.typeOptions()} name="poolingExamParentData.type" placeholder="Select a type" value={poolingExamParentData?.type}  title="Select a type" />
                       
                    </div>
                    {/* <div className='mt-[20px]'>
                        <TextEditor value={poolingExamParentData?.instruction} name="poolingExamParentData.instruction" title="Instruction" />
                    </div> */}
                    <div className='mt-[20px]'>
                        <TextEditor value={poolingExamParentData?.description} name="poolingExamParentData.description" title="Description" />
                    </div>
                    <button onClick={()=>this.setState({filesVisible: !filesVisible})}>
                        Show files
                    </button>
                    {
                        filesVisible?
                        <div className='grid grid-cols-3 mt-[20px] gap-[20px]'>
                            <FileInput type="image" name="poolingExamParentData.image" placeholder={'Choose a image'} value={poolingExamParentData?.image_full_url} valueName="poolingExamParentData.image_full_url" title="Choose a image"/>
                            <FileInput type="video" name="poolingExamParentData.video" placeholder={'Choose a video'} value={poolingExamParentData?.video_full_url} valueName="poolingExamParentData.video_full_url" title="Choose a video"/>
                            <FileInput type="audio" name="poolingExamParentData.audio" placeholder={'Choose a audio'} value={poolingExamParentData?.audio_full_url} valueName="poolingExamParentData.audio_full_url" title="Choose a audio"/>
                        </div>:null
                    }
                    
                    <div className='flex justify-between items-center mt-[30px]'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)}  className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Close</button>
                        <button onClick={this.addParent.bind(this, poolingExamParentData)} disabled={btnDisabled} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Save</button>
                    </div>
                </div>:null
            }
           
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    poolingExamParentData: state.Data.poolingExamParentData,
    examCategories: state.Data.examCategories,
    examSubCategories: state.Data.examSubCategories,
});
const mapDispatchToProps = {changeStateValue, getExamCategories, getSubByCategory, insertDataWithParams}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamParentContainer))
