import React, { Component } from 'react'
import Header from '../components/header/Header'
import img from "../images/notFound.png"
import { Link } from 'react-router-dom'
export class NotFoundPage extends Component {
  render() {
    return (
        <div>
            <Header />
            <div className='w-full h-screen overflow-auto pb-[50px] flex items-center gap-[20px] flex-col'>
               <img src={img} className='w-[700px] max-[1100px]:mt-[100px]' alt='Not Found' />
               <p className='text-center'>THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST.</p>
               <Link className='bg-blue p-[9px] rounded-full text-white ' to="/">Return to Home page</Link>
            </div>
        </div>
        
    )
  }
}

export default NotFoundPage
