import React, { Component } from 'react'
import Header from '../../../components/header/Header'
import Search from '../../../components/Search'
import ExamParentContainer from './ExamParentContainer'
import { withHooks } from '../../../actions/withHooks'
import { getOneExamQuestion, getSubByCategory } from '../../../actions/StaffAction'
import { connect } from 'react-redux'
import ExamQuestionContainer from './ExamQuestionContainer'
import ExamTypeButton from './ExamTypeButton'
import ExamQuestionModal from './ExamQuestionModal'
import { Link } from 'react-router-dom'

export class ExamPoolAddEdit extends Component {
    componentDidMount(){
      if(this.props.params.id !=="create"){
        this.props.getOneExamQuestion(this.props.params.id)
        .then(resp=>{
          if(resp !==""){
            this.props.getSubByCategory(resp?.category_id)
          }
        })
      }
    }
  render() {
    const {poolingExamQuestionData} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Pooling exam questions" />
            <div className='flex justify-center '>
            </div>
            <div className='mt-[50px]'>
                <ExamParentContainer />
                {
                  this.props.params.id !=="create"?
                  <>
                  <ExamTypeButton />
                  <ExamQuestionContainer />
                  </>:null
                }
            </div>
            {
              poolingExamQuestionData?.position?
              <ExamQuestionModal />:null
            }
            <div className='flex justify-center items-center mt-[30px]'>
              <Link to={'/exam-pooling-questions'} className='bg-blue text-[14px] text-white p-[5px_9px] rounded-[6px]'>Go back</Link>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  poolingExamQuestionData: state.Data.poolingExamQuestionData
});
const mapDispatchToProps = {getOneExamQuestion, getSubByCategory}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamPoolAddEdit))
