import React, { Component } from 'react'
import { connect } from 'react-redux';
import {insertNewDataV2, getExamStart} from "../../actions/MainAction";
import {jwtDecode} from "jwt-decode";
import { withHooks } from '../../actions/withHooks';
import { mixinAlert } from '../../actions/SweetAlerts';

export class Footer extends Component {
    state={
        btnDisable:false
    }
    save(answer){
      
        this.props.insertNewDataV2("student-exam-save", {...answer, type: this.props.type})
        .then(resp=>{
            this.setState({btnDisable:false})
           if(resp === "success"){
            mixinAlert("success", "Exam saved successfully");
           }else{
            mixinAlert("error", "Something went wrong.");
           }
            this.callAfterSave()
        })
    }
    callAfterSave(){
        if(localStorage.getItem('userToken')){
            const data = jwtDecode(localStorage.getItem('userToken'));
            this.props.getExamStart(this.props.params.id, data.sub);
            this.props.closeBody()
        }
    }
    saveAnswer(freetextExamAnswers, data){
        this.setState({btnDisable:true})
        if(data?.student_exam_questions[0]?.question_type === 'dropdown_question_type'){
            let answer = {
                ...data,
                status: data.student_exam_questions.find(item =>item.given_answer == '' || item.matching_type_drag_list && item.matching_type_drag_list.find(exam => exam.length !== 0))?"Partially Answered": "Answered"
            };
            this.save(answer);
        }else if(data?.student_exam_questions[0]?.question_type === 'free_text'){
            let answer = {
                ...data,
                status: data.student_exam_questions.find(item =>item.given_answer == '' || item.matching_type_drag_list && item.matching_type_drag_list.find(exam => exam.length !== 0))?"Partially Answered": "Answered",
            };
            let correctedAns = [];
            for(const x of this.props.freetextExamAnswers){
                correctedAns.push({
                    answer_full_url: x.answer_full_url,
                    correct_full_url: x.correct_full_url,
                    given_answer: x.given_answer === ""? "N/A": x.given_answer,
                    id: x.id,
                    title: x.title,
                })
            }
            let newAns = {};
            for(const property in answer){
                newAns[property] = answer[property];
                if(property === "student_exam_questions"){
                    let seq = [];
                    for(const q of answer[property]){
                        let newItem = {};
                        for(const i in q){
                            newItem[i] = q[i];
                            if(i === "student_exam_question_answers"){
                                newItem[i] = correctedAns;
                            }
                        }
                        seq.push(newItem)
                    }
                    newAns[property] = seq;
                }
            }
            this.save(newAns)
        }else{
            let answer = {
                ...data,
                status: data.student_exam_questions.find(item =>item.given_answer == '' || item.matching_type_drag_list && item.matching_type_drag_list.find(exam => exam.length !== 0))?"Partially Answered": "Answered"
            };
            this.save(answer);
        }
     
    }
  render() {
    const {freetextExamAnswers, data, lang} = this.props;
    const {btnDisable} = this.state;
    return (
      <div className='flex mt-[20px] justify-end max-[800px]:justify-center'>
        <button disabled={btnDisable} className='bg-blue h-[25px] pl-[10px] text-[14px] pr-[10px] text-white font-semibold rounded-[6px]' onClick={this.saveAnswer.bind(this, freetextExamAnswers, data)}>{lang?.save_close}</button>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examsData: state.Data.examsData,
    freetextExamAnswers: state.Data.freetextExamAnswers,
    lang: state.Data.lang,
});
const mapDispatchToProps = {insertNewDataV2, getExamStart}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Footer))
