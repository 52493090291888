import React, { Component } from 'react'

export class CardCertSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={15}
        fill="none"
        viewBox="-0.5 0 25 25"
      >
        <path
          stroke="var(--blue)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M12.005 15.185c2.402 0 4.35-2 4.35-4.47 0-2.468-1.947-4.47-4.35-4.47-2.402 0-4.35 2.002-4.35 4.47 0 2.47 1.948 4.47 4.35 4.47Z"
        />
        <path
          stroke="var(--blue)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          d="M12.875 3.525c.51-.93 1.89-.55 1.87.52-.01.81.84 1.31 1.51.9.89-.55 1.91.49 1.37 1.41-.4.69.09 1.57.87 1.55 1.04-.02 1.41 1.4.5 1.92-.69.39-.69 1.4 0 1.79.91.52.54 1.94-.5 1.92-.78-.01-1.28.86-.87 1.55.53.92-.48 1.96-1.37 1.41-.67-.41-1.53.09-1.51.9.02 1.07-1.37 1.45-1.87.52a.992.992 0 0 0-1.75 0c-.51.93-1.89.55-1.87-.52.01-.81-.84-1.31-1.51-.9-.89.55-1.91-.49-1.37-1.41.4-.69-.09-1.57-.87-1.55-1.04.02-1.41-1.4-.5-1.92.69-.39.69-1.4 0-1.79-.91-.52-.54-1.94.5-1.92.78.01 1.28-.86.87-1.55-.53-.92.48-1.96 1.37-1.41.67.41 1.53-.09 1.51-.9-.02-1.07 1.37-1.45 1.87-.52.39.7 1.37.7 1.75 0ZM5.865 18.325l-.53.95c-.26.46.24.97.71.71l1.71-.95c.35-.18.77.06.78.46l.04 1.99c.03.54.73.71 1 .25l.71-1.28M18.145 18.335l.53.94c.26.46-.24.97-.72.71l-1.7-.95a.533.533 0 0 0-.78.46l-.04 1.99c-.03.54-.73.71-1 .25l-.7-1.28"
        />
      </svg>
    )
  }
}

export default CardCertSvg
