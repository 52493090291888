import React, { Component } from 'react'
import img from '../../../images/headset.webp'
export class HeadText extends Component {
  render() {
    const {text} = this.props;

    return (
      <div className='toefl_speaking_text'>
        <p>{text}</p>
        <img src={img} alt="image" />
        <p style={{ marginTop: '20px' }}>Click on <b>Continue</b> when you are ready to go on.</p>
      </div>
    )
  }
}

export default HeadText