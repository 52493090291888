import React, { Component } from 'react'
import CourseLessonDrop from './components/CourseLessonDrop'
import { connect } from 'react-redux'
import { getUnlockExams, unlockStudentExam } from '../../../../actions/StaffAction'
import { withHooks } from '../../../../actions/withHooks'
import Card1 from '../../../../components/cards/Card1'
import { bigAlert, confirmAlert, mixinAlert } from '../../../../actions/SweetAlerts'
import EmptyPage from '../../../../components/EmptyPage'
import { insertNewData } from '../../../../actions/MainAction'
export class UnlockExams extends Component {
    state={
        unlockExams:[],
        btnDisabled:false
    }
    getExams=(data)=>{
        this.props.getUnlockExams(data?.courseId, data?.lessonId, this.props.params.id, 0)
        .then(resp=>{
            this.setState({unlockExams:resp?.data})
        })
    }
    unlockExamSupp=(data)=>{
        if(!(data.is_block === 1 || data.has_unlock_remaining_time || data.retake_count === data.exam.retake_time)){
            confirmAlert("warning", "Are you sure to unlock"+" \n"+data?.exam?.title+" ?", "You won't be able to revert this!", "Yes, Unlock it!")
            .then(resp=>{
                if(resp==="success"){
                    this.unlockExamBtn(data)
                }
            })
        }else{
            mixinAlert( "warning","The exam already has been taken.")
        }
    }
    unlockExamBtn=(data)=>{
        if(!(data.is_block === 1 || data.has_unlock_remaining_time || data.retake_count === data.exam.retake_time)){
            this.setState({btnDisabled:true});
            const params = {
                is_block    : 1,
                id          : data.id,
                retake_count: data?.retake_count + 1,
                calling_manual: "start"
            }
            let formData = new FormData();
            for (const property in params) {
                if (params[property])
                    formData.append(property, params[property]);
            }
            formData.append("_method", "put");
            this.props.unlockStudentExam(`examLockUnlock/${data?.id}`, formData)
            .then(resp=>{
                this.setState({btnDisabled:false});
                if(resp === "success"){
                    this.getExams({courseId: data?.course_id, lessonId: data?.lesson_id})
                    bigAlert("Unlocked!", this.props.lang?.exam_unlck_succ, "success");

                }
            })
        }else{
            mixinAlert( "warning","The exam already has been taken.")
        }
    }

    componentDidMount(){
        window.scrollTo(0,0);
        const courseVisible = window.location.search;
        
    }
    getCoursePage(){
        const courseVisible = window.location.search;
        if(courseVisible==""){
            return true
        }else{
          return false;
        }
    }
    getExamId(unlockExams){
        if(this.props.user?.role == "support"){
            if(this.props.params?.exam_id){
                const check = unlockExams.filter(x=>x.exam_id == this.props.params?.exam_id);
                if(check.length === 0){
                    return []
                }else{
                    return check
                }
            }else{
                return unlockExams
            }
        }else{
            return unlockExams
        }
        
    }
    onGenerateBtn=(data)=>{
        this.props.insertNewData(`exam-new-code/${data?.id}`,{})
        .then(resp=>{
            if(resp == "success"){
                mixinAlert("success", "Exam key regenereted.");
                this.getExams({courseId: data?.course_id, lessonId: data?.lesson_id})
            }else{
                mixinAlert("error", "Something went wrong")
            }
        })
    }
  render() {
    const {unlockExams, btnDisabled} = this.state;
    const {lang,studentData, user} = this.props;
    // console.log(unlockExams)
    return (
      <div className='w-full'>
        <CourseLessonDrop studentType={studentData?.student_type} onSelectData={this.getExams.bind(this)} />
        {/* {unlockExams.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, select course and lesson" subTitle="" /></div>:null} */}
        <div className='w-full grid-full-220 mt-[20px]'>
            {this.getCoursePage()?
                this.getExamId(unlockExams)?.map((data, i)=>{
                    
                    return(
                        <Card1
                            key={i}
                            title={data?.exam?.title}
                            status={data.is_block === 1 || data.has_unlock_remaining_time || data.retake_count === data.exam.retake_time?1:2}
                            statusText={data.is_block === 1 || data.has_unlock_remaining_time || data.retake_count === data.exam.retake_time?lang?.unlocked:lang?.locked}
                            cheating={data.retake_count === data.exam.retake_time? lang?.status_closed: lang?.open}
                            timer={ data.timer_start !== null?  data.timer_start: '00:00:00'}
                            minute={data.exam.retake_minutes}
                            btn={false}
                            btn2={true}
                            btn2Text={ data.is_block === 1 || data.has_unlock_remaining_time || data.retake_count === data.exam.retake_time?lang?.lock:lang?.unlock}
                            btn2Color={data.is_block === 1 || data.has_unlock_remaining_time || data.retake_count === data.exam.retake_time? "bg-gray-400": 'bg-blue'}
                            onCLickBtn={user?.role =="support"?this.unlockExamSupp.bind(this, data):this.unlockExamBtn.bind(this, data)}
                            disabled={btnDisabled}
                            cheating2={data?.check_exists=='1'?lang?.already_passed:data?.check_exists=='2'?lang?.have_failed:lang?.not_taken_yet}
                            cheatingColor2={data?.check_exists=='1'?"txt-blue":data?.check_exists=='2'?"txt-red": "txt-dark-gray"}
                            authCode={ data.is_block === 1?data?.auth_code:null}
                            onGenerate={this.onGenerateBtn.bind(this, data)}
                        />
                    )
                }):null
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps  = {getUnlockExams, unlockStudentExam, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UnlockExams))
