import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux';
import { getGroupCreatedUsers, getGroupUsers, getGroupsUsers } from '../../actions/MessageAction';
import { getRole } from '../../actions/getRole';
import { mixinAlert } from '../../actions/SweetAlerts';
import { insertNewData } from '../../actions/MainAction';
export class MsgCreateGroupModal extends Component {
    state={
        usersList:[],
        search: '',
        checkedUsers:[],
        group_name:this.props.name?this.props?.name:'',
        btnDisabled:false,
        existsUsers:[]
    }
    closeModal=()=>{
        this.props.onClose()
    }
    componentDidMount(){
        this.props.getGroupCreatedUsers('')
        .then(resp=>{ this.setState({usersList:resp}) });
        if(this.props.type == "edit"){
            this.props.getGroupsUsers(this.props.id)
            .then(resp=>{
                let arr = []
                for(const params of resp){
                    arr.push(params.id)
                }
                this.setState({existsUsers:arr})
            })
        }
    }

    getSearchValue(e){
        this.setState({search:e.target.value});
        if(e.target.value === ""){
            this.props.getGroupCreatedUsers('')
            .then(resp=>{ this.setState({usersList:resp}) })
        }
    }
    searchBtn=(search, e)=>{
        e.preventDefault();
        if(search.trim() !== ""){
            this.props.getGroupCreatedUsers(search)
            .then(resp=>{ this.setState({usersList:resp}) })
        }
    }
    selectUser=(id, checkedUsers)=>{
        let newArr = [...checkedUsers]
        const check = newArr.findIndex(x=>x === id);
        if(check === -1){
            newArr.push(id)
        }else{
            newArr.splice(check, 1)
        }
        this.setState({checkedUsers: newArr})
    }
    saveBtn=(checkedUsers, name)=>{
        if(name === ""){
            mixinAlert("info", "Group name field is required");
            return
        }
        if(checkedUsers.length === 0){
            mixinAlert("info", "Please select user to create group");
            return
        }
        this.setState({btnDisabled:true});
        let formdata = new FormData();
        formdata.append('users', JSON.stringify(checkedUsers))
        formdata.append('group_name', name);
        let url = 'group-messages';
        if(this.props.type === "edit"){
            url = `group-messages/${this.props.id}`;
            formdata.append("_method", "put")
        }
        this.props.insertNewData(url, formdata)
        .then(resp=>{
            if(resp==="success"){
                mixinAlert("success", "Group created successfully!");
                this.props.getGroupUsers();
                this.closeModal()
            }else{
                mixinAlert("error", "Something went wrong!")
            }
            this.setState({btnDisabled:false})
        })
       
    }
  render() {
    const {search, usersList, checkedUsers, group_name, btnDisabled, existsUsers} = this.state;
    const {type = "", lang} = this.props
    return (
      <div className='background_shadow flex items-center justify-center'>
         <div className='bg-white w-[400px]  shadow bg-white rounded-[10px] '>
            <Header closeBtn={this.closeModal.bind(this)} title={lang?.createGroup} />
            <div className='bg-white p-[20px] rounded-b-[10px] max-h-[60vh] overflow-auto flex flex-col gap-[10px]'>
                {
                    type !== "edit"?
                    <div>
                        <input value={group_name} onChange={(e)=>this.setState({group_name:e.target.value})} type='text' className='bg-gray-100 w-full h-[35px] rounded-[6px] pl-[5px] text-[14px] outline-none text-gray-600' placeholder='Group Name' />
                        {group_name === ""?<span className='text-[12px] text-red-700'>{lang?.field_required}</span>:null}
                    </div>:null
                }
                
                <form onSubmit={this.searchBtn.bind(this, search)}>
                    <input type='text' value={search} onChange={this.getSearchValue.bind(this)} className='bg-gray-100 w-full h-[35px] rounded-[6px] pl-[5px] text-[14px] outline-none text-gray-600' placeholder='Search' />
                </form>
                <ul className='flex flex-col gap-[10px] max-h-[40vh] overflow-auto'>
                    {
                        usersList.map((data, i)=>{
                            if(!existsUsers.includes(data?.id)){
                                return(
                                    <li key={i}>
                                        <label htmlFor={`${data?.id}`} className='bg-gray-100 p-[10px] rounded-[6px] flex flex-col cursor-pointer'>
                                            <div className='flex gap-[6px]'>
                                                <input checked={checkedUsers.includes(data?.id)} onChange={this.selectUser.bind(this, data?.id, checkedUsers)} id={`${data?.id}`} type='checkbox' className='cursor-pointer' />
                                                <span className='text-[14px] txt-blue'>{data?.full_name}</span>
                                                <span className='text-[14px] txt-blue'>({getRole(data?.role, lang)})</span>
                                            </div>
                                            <span className='text-[13px]'>{data?.email}</span>
                                        </label>
                                    </li>
                                )
                            }
                            
                        })
                    }
                </ul>
                <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,checkedUsers, group_name)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save}</button>
                </div>
            </div>
         </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {getGroupCreatedUsers, insertNewData, getGroupUsers, getGroupsUsers}
export default connect(mapStateToProps, mapDispatchToProps)(MsgCreateGroupModal)
