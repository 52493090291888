import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { getSingleData } from '../../../actions/StaffAction';
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import { getMocExamDetails, getMockParentsDetail, insertNewData } from '../../../actions/MainAction';
import ExamHeader from './ExamHeader';
import SpeakingAnswerParent from './SpeakingAnswerParent';
import MultiExamParent from './MultiExamParent';
import LoadingPage from './LoadingPage';
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts';

export class MocExamDetails extends Component {
    state={
        currentPage:0,
        title:"",
        examId:'',
        btnDisable:false,
        timer:'',
        minute:'',
        loadPage:true,
        parentData:""
      }
    getSectionIds(sections){
        let arr = [];
        for(const data of sections){
            arr.push(data?.section_id)
        }
        return arr;
    }

    getExams(){
        this.props.getMockParentsDetail(this.props.params.id)
        .then(resp=>{
          if(resp !=="error"){
            this.setState({examId: resp?.exam_id, timer: resp?.updated_at, minute: resp?.duration, parentData:resp})
            this.props.getMocExamDetails(resp?.exam_id, JSON.stringify(this.getSectionIds(resp?.settings)))
            .then(resp2=>{this.setState({loadPage:false})})
          }else{
            this.props.navigate(`/start-mock-exams/${this.props.params?.examId}`)
          }
            // console.log(resp)
            
        })
    }
    componentDidMount(){
        this.props.changeStateValue({name:"sideBarVisible", value:false})
        this.getExams()
    }
    getFilterData(){
        return this.props.examsData?.filter(x=>x.full_answer_status == 0)
    }
    getFilterIndex(){
        return this.props.examsData?.filter(x=>x.full_answer_status == 1)?.length
    }
    checkSpeaking(exams){
        let check = exams[0]?.questions?.filter(x=>x.question_type == "speaking_type_1" || x.question_type == "speaking_type_2" || x.question_type == "speaking_type_3");
       
        if(check?.length !=0){
          return true;
        }else{
          return false;
        }
    }
    exitExamBtn=()=>{
        confirmAlert("warning", "Are you sure?", "You will be exit the exam.","Yes, Exit.", "", "No, Go Back.")
        .then(resp=>{
          if(resp == "success"){
            this.props.changeStateValue({name:"sideBarVisible", value:true})
            this.props.navigate(`/start-mock-exams/${this.props.params?.examId}`);
          }
        })
    }

    onClickRight=(status, finish_status="")=>{
        const questions = this.getFilterData()[0]?.questions?this.getFilterData()[0]?.questions:[];
        let total_count = 0;
        let answer_count = 0;
        questions.forEach(question=>{
            if(question?.question_type == "single_choice"){
              total_count+=1;
              if(question?.given_answer){
                answer_count+=1;
              }
            }else if(question?.question_type == "multiple_choice"){
              total_count+=1;
              if(question?.given_answer && question?.given_answer?.length !=0){
                answer_count+=1;
              }
            }else if(question?.question_type == "free_text" || question?.question_type == "drop_down"){
              question?.answers?.forEach(answer=>{
                total_count+=1;
                if(answer?.given_answer){
                  answer_count+=1;
                }
              })
            }
        })

        if(total_count == answer_count || status == "submit"){
            if(finish_status == ""){
              confirmAlert("warning", "Are you sure?", "You won’t be able to come back to this section if you move on. Do you want to continue?", "Yes, Continue", "","No, Go Back")
              .then(respAlert=>{
                if(respAlert == "success"){
                  this.setState({loadPage:true, btnDisable:true})
                  let questions = JSON.stringify(this.getFilterData()[0]?.questions?this.getFilterData()[0]?.questions:[]);
                  this.props.insertNewData("save-pooling-exam-moc", {parent_id:this.props.params.id, sections: JSON.stringify(this.getSectionIds(this.state.parentData?.settings)), questions: questions, submit_status:status, studentExam_id: this.state.examId, exam_id: this.props.params.id})
                  .then(resp=>{
                    this.setState({btnDisable:false})
                    if(resp == "success"){
                      if(status == "submit"){
                        this.props.changeStateValue({name:"sideBarVisible", value:true})
                        this.props.navigate(`/start-mock-exams/${this.props.params?.examId}`)
                       
                      }else{
                        this.getExams();
  
                      }
                      
                    }else{
                      mixinAlert("error", "Something went wrong");
                    }
                  })
                }
              })
            }else{
              this.setState({loadPage:true, btnDisable:true})
              let questions = JSON.stringify(this.getFilterData()[0]?.questions?this.getFilterData()[0]?.questions:[]);
              this.props.insertNewData("save-pooling-exam-moc", {parent_id:this.props.params.id, sections: JSON.stringify(this.getSectionIds(this.state.parentData?.settings)), questions: questions, submit_status:status, studentExam_id: this.state.examId, exam_id: this.props.params.id})
              .then(resp=>{
                this.setState({btnDisable:false})
                if(resp == "success"){
                  if(status == "submit"){
                    this.props.changeStateValue({name:"sideBarVisible", value:true})
                    // this.props.navigate("/exam-results")
                    this.props.navigate(`/start-mock-exams/${this.props.params?.examId}`)
                  }else{
                    this.getExams();
  
                  }
                  
                }else{
                  mixinAlert("error", "Something went wrong");
                }
              })
            }
            
          }else{
            mixinAlert("info", "Please answer all the questions to continue");
          }
    }
  render() {
    const {examsData} = this.props;
    const {examId, timer, minute, title, loadPage, btnDisable} = this.state;
    // console.log(examId)
    return (
        <div className='w-full pl-[27px] pr-[27px]  relative w-full pt-[1px] exam_parent'>
            <ExamHeader nextbtnVisible={!this.checkSpeaking(examsData)} btnDisable={btnDisable} title={title} timer={timer} minute={minute} btnNext={this.getFilterIndex()+1 == examsData.length} onClickRight={this.onClickRight.bind(this)} />
            <div className='mt-[100px]'>
            {
            !loadPage?
            this.checkSpeaking(examsData)?
            <SpeakingAnswerParent parent_id={this.props.params.id} url="save-pooling-exam-moc-sp" path={`/start-mock-exams/${this.props.params?.examId}`} studentExam_id={this.state.examId} exam_id={this.props.params.id} data={examsData} />
            :
            this.getFilterData()?.map((data, i)=>{
                if(i==0){
                return(
                    <MultiExamParent key={i} data={data} index={this.getFilterIndex()} />
                )
                }
            }):<LoadingPage />
            }
            <button onClick={this.exitExamBtn.bind(this)} className='absolute mt-[20px] bg-red  text-white text-[14px] font-semibold p-[4px_9px] rounded-[8px]'>Exit exam</button>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examsData: state.Data.examsData
});
const mapDispatchToProps = {changeStateValue, getSingleData, getMocExamDetails, insertNewData, getMockParentsDetail}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(MocExamDetails))
