import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getCourses } from '../../actions/MainAction';
import Card2 from '../../components/cards/Card2';
import EmptyPage from '../../components/EmptyPage';
export class Courses extends Component {

    componentDidMount(){
        this.props.getCourses();
        window.scrollTo(0,0)
    }
  render() {
    const {studentCourses, lang} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="courses" />
            {studentCourses?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}

            <div className='mt-[42px] grid-full-205 max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
                {
                    studentCourses?.map((data, i)=>{
                        return(
                            <Card2 
                                key={i}
                                title={data?.title}
                                btnTitle={lang?.view}
                                btnUrl={`/units/${data?.id}`}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    studentCourses: state.Data.studentCourses,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getCourses}
export default connect(mapStateToProps, mapDispatchToProps)(Courses)
