import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TodoSvg from '../svg/TodoSvg'
import { connect } from 'react-redux';

export class TodoCards extends Component {
  // w-[340px]
  render() {
    const {array, title, path, openModal=()=>{}, lang} = this.props;
    return (
      <div className='bg-white  rounded-[20px] p-[40px] pb-[20px] flex flex-col justify-between'>
        <div className='flex justify-between'>
          <h3 className='txt-light-black text-[15px] font-bold'>{title}</h3>
        </div>
        <ul className='mt-[34px] h-[270px] flex flex-col gap-[18px]'>
          {
            array.map((data, i)=>{
              return(
                <li onClick={()=>openModal(data)} key={i} className='flex items-center gap-[9px] cursor-pointer'>
                  <TodoSvg />
                  <span className='text-[13px] txt-dark-gray font-semibold'>{data?.title}</span>
                </li>
              )
            })
          }
        </ul>
       <div className='flex justify-end mt-[10px]'>
         <Link className='txt-dark-gray font-semibold text-[13px]' to={path}>{lang?.view_all}</Link>
       </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(TodoCards)
