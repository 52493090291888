import React, { Component } from 'react'

export class CloseSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="-0.5 0 25 25"
  
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m3 21.32 18-18M3 3.32l18 18"
        />
      </svg>
    )
  }
}

export default CloseSvg
