import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import PublicCard3 from '../components/cards/PublicCard3'
import { connect } from 'react-redux';
import { getCompanies } from '../../actions/PublicActions';
export class PublicCenters extends Component {
  state={
    companies:[],
  }
  componentDidMount(){
    this.props.getCompanies()
    .then(resp=>this.setState({companies: resp}))
   
    window.scrollTo(0,0)

  }
  render() {
    const {companies} = this.state;
    return (
      <section className='pt-[110px]'>
        <SectionHeader title="Centers" />
        <div className='grid grid-cols-3 container ml-auto mr-auto gap-[30px]  max-[1000px]:grid-cols-2  max-[685px]:grid-cols-1  max-[685px]:pl-[20px]  max-[685px]:pr-[20px]'>
            {
              companies?.map((data, i)=>{
                return(
                   <PublicCard3 
                    key={i}
                    data={data}
                   />
                )
              })
            }
        </div>
      </section>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(PublicCenters)
