import React, { Component } from 'react'

export class ReadingPart extends Component {
  render() {
    const {title, desc, time} = this.props
    return (
      <div>
        <i >Reading Time: {time} seconds</i>
        <p className='student_toefl_exam_title' style={{ marginTop: '30px' }}>{title}</p>
         {
          desc != ''?
          <div dangerouslySetInnerHTML={{ __html: desc}}></div>:null
         }
      </div>
    )
  }
}

export default ReadingPart