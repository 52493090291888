import React, { Component } from 'react'
import ModelAnswer from '../ModelAnswer';
import { connect } from 'react-redux';

export class Review extends Component {
    state={
        modelAnswer: ''
    }
  render() {
    const {role, grade, name, review, model_answer, lang} = this.props;
    const {modelAnswer} = this.state;
    return (
      <div className='border-t mt-[20px]'>
        <div className='flex items-center justify-between mt-[15px] max-sm:flex-col-reverse max-sm:items-start max-sm:gap-[10px]'>
            <div className='flex items-center gap-[10px]'>
                <span className='text-[17px] txt-blue font-semibold'>{role}:</span>
                <span className='text-[15px] txt-light-black'>{name}</span>
            </div>
            {
                model_answer?
                <button onClick={()=>this.setState({modelAnswer: model_answer})} className='bg-blue text-white text-[13px] pl-[12px] pr-[12px] font-semibold p-[3px] rounded-[6px]  max-sm:w-[100%]'>
                     {lang?.model_essay}
                </button>:null
            }
        </div>
        <div className='flex items-center gap-[10px] mt-[5px]'>
            <span className='text-[17px] txt-blue font-semibold'>{lang?.grade}:</span>
            <span className='text-[15px] txt-light-black'>{grade}</span>
        </div>
        
        <div dangerouslySetInnerHTML={{ __html: `<div class="mt-[5px] modal_review_cont"> <span class='text-[17px] txt-blue font-semibold'>${lang?.review}: </span>  ${review}</div>` }}></div>
        {
            modelAnswer != ''?
            <ModelAnswer closeModalBtn={()=>this.setState({modelAnswer: ''})} data={modelAnswer} />:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(Review)
