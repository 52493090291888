import React, { Component } from 'react'
import Trash2Svg from '../svg/Trash2Svg'
import MsgSendSvg from '../svg/MsgSendSvg';
import { endRecording, startRecording } from '../../actions/ReorAudio';
import { connect } from 'react-redux';
import { insertNewData } from '../../actions/MainAction';
import { getGroupMsgBoxData, getMsgBoxData, getChatUsers, getGroupUsers, sendNotification } from '../../actions/MessageAction';
let second = 0;
let minute = 0;
let interval;
export class MsgRecordAudio extends Component {
    state={
        timer:'00:00',
        disable:false
    }
    removerecord=()=>{
        clearInterval(interval);
        this.props.onClickRecord();
        endRecording();
    }
    componentWillUnmount(){
        this.removerecord()
    }
    componentDidMount(){
        this.startTimer()
        startRecording();
    }
    getTimerFormst=(time)=>{
        if(time < 10){
            return `0${time}`;
        }else{
            return time;
        }
    }
    startTimer =  ()=>{
        this.setState({timer: `00:00`})
        interval = setInterval(()=>{
           
            if(second<60){
                second++;
            }else{
                minute++;
                second = 0;
            }
            this.setState({timer: `${this.getTimerFormst(minute)}:${this.getTimerFormst(second)}`})
        }, 1000);
       
    }
    sendBtn=(data, reply)=>{
        clearInterval(interval);
        this.setState({disable:true})
        const result = endRecording();
        let formData = new FormData();
        formData.append('message_group_id', data.id);
        if(reply != ''){
          formData.append('replied_id', reply?.id);
        }else{
          formData.append('replied_id', '');
        }
        formData.append("audio", result?.audioName);
        formData.append('message', result?.audioName.name);
        let url = "messages";
        let notUrl = "send-general-notification"

        if(data?.type === "group"){
            url="send-group-msg"
            notUrl = "send-group-notification"
        }
        this.props.sendNotification(notUrl, formData)
        this.props.insertNewData(url, formData)
        .then(resp=>{ 
            this.setState({disable:false})

            if(data?.type === "group"){
                this.props.getGroupMsgBoxData(data?.id, 1);
            }else{
                this.props.getMsgBoxData(data?.id);
            }
            this.props.getChatUsers();
            this.props.getGroupUsers();
            this.removerecord()
        })
    }
  render() {
    const {timer, disable} = this.state;
    const {data, reply} = this.props;
    return (
        <div className='h-[64px] flex items-center '>
            <div className='flex bg-[#F5F9FF] w-full mt-[20px] rounded-[6px] flex items-center justify-between pl-[20px] pr-[20px] mb-[20px] h-[40px]'>
                <button onClick={this.removerecord.bind(this)}>
                    <Trash2Svg />
                </button>
                <span className='font-semibold text-[14px]'>{timer}</span>
                <button disabled={disable} onClick={this.sendBtn.bind(this, data, reply)} className='bg-blue w-[25px] h-[25px] inline-flex items-center justify-center rounded-full pr-[3px] pt-[2px]'>
                    <MsgSendSvg />
                </button>
            </div>
        </div>
      
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {insertNewData, getGroupMsgBoxData, getMsgBoxData, getChatUsers, getGroupUsers, sendNotification }
export default connect(mapStateToProps, mapDispatchToProps)(MsgRecordAudio)
