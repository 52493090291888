export const MAIN_API = 'https://api-lms.celt.vip/api/v1';
export const MAIN_APIV2 = 'https://api-lms.celt.vip/api/v2';

// export const MAIN_API = 'http://10.0.0.129:8000/api/v1';
// export const MAIN_APIV2 = 'http://10.0.0.129:8000/api/v2';
// export const MAIN_API = 'http://127.0.0.1:8000/api/v1';
// export const MAIN_APIV2 = 'http://127.0.0.1:8000/api/v2';
// export const MAIN_API = 'https://phplaravel-1197107-4222269.cloudwaysapps.com/api/v1'; //stages
// export const MAIN_APIV2 = 'https://phplaravel-1197107-4222269.cloudwaysapps.com/api/v2'; //stage


// export const PUBLIC_API = 'http://10.0.0.128:8002/api/v1';
// export const PUBLIC_API = 'http://127.0.0.1:8000/api/v1';
// export const PUBLIC_API = 'https://phplaravel-956339-3877143.cloudwaysapps.com/api/v1';
export const PUBLIC_API = 'https://game.celt.vip/api/v1';
// export const CELTGO_API = 'http://127.0.0.1:8000/api/v1';
export const CELTGO_API = 'https://goapi.celt.vip/api/v1';

// export const SOCKET_API = 'http://192.168.0.198:8080'
export const SOCKET_API = 'https://whale-app-y7eoh.ondigitalocean.app'