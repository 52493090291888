import React, { Component } from 'react'

export class CardDateSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="#1E398F"
        d="M3.404 0a.42.42 0 0 1 .42.42v.785h4.51v-.78a.42.42 0 1 1 .84 0v.78H10.8a1.2 1.2 0 0 1 1.2 1.2V10.8A1.2 1.2 0 0 1 10.8 12H1.2A1.2 1.2 0 0 1 0 10.8V2.406a1.2 1.2 0 0 1 1.2-1.2h1.784V.42A.42.42 0 0 1 3.404 0ZM.84 4.645v6.156a.36.36 0 0 0 .36.36h9.6a.36.36 0 0 0 .36-.36V4.654L.84 4.645ZM4 8.771v1H3v-1h1Zm2.5 0v1h-1v-1h1Zm2.5 0v1H8v-1h1ZM4 6.385v1H3v-1h1Zm2.5 0v1h-1v-1h1Zm2.5 0v1H8v-1h1Zm-6.016-4.34H1.2a.36.36 0 0 0-.36.36v1.4l10.32.01v-1.41a.36.36 0 0 0-.36-.36H9.174v.557a.42.42 0 0 1-.84 0v-.557h-4.51v.552a.42.42 0 1 1-.84 0v-.552Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
    )
  }
}

export default CardDateSvg
