import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAuditCoach } from '../../actions/CoachAction';
import { Link } from 'react-router-dom';
import { getCompanies } from '../../actions/AdminActions';
import { getCoachStatus } from '../../actions/getRole';
export class AuditorDashboard extends Component {
    state={
        type:'all'
    }
    componentDidMount(){
        this.props.getCompanies(10)
       
    }
    getCoachStatsAll(companyCoach){
        let arr = [];
        companyCoach.forEach((item=>{
            for(const x of item?.statistics){
                let stats = [];
                if(x?.exam !=""){
                    stats.push({
                        ...x.exam,
                        title:"Exam",
                        type:'exam'
                    })
                }
                if(x?.assessments !=""){
                    stats.push({
                        ...x.assessments,
                        title:"RP.",
                        type:'assessments'
                    })
                }
                if(x?.essay !=""){
                    stats.push({
                        ...x.essay,
                        title:"Es.",
                        type:'essay'
                    })
                }
                if(x?.speaking !=""){
                    stats.push({
                        ...x.speaking,
                        title:"Sp.",
                        type:'speaking'
                    })
                }
                if(x?.homework !=""){
                    stats.push({
                        ...x.homework,
                        title:"PT",
                        type:'pt'
                    })
                }
                if(x?.speaking_practice !=""){
                    stats.push({
                        ...x.speaking_practice,
                        title:"Sp. P",
                        type:'sp_practice'
                    })
                }
                let info={
                    course_title: x.course_title,
                    lesson_mode: x.lesson_mode,
                    student_name: item?.full_name,
                    teacher_name: x?.teacher?.full_name,
                    student_id:item?.id,
                    statistics: stats,
                    // status:x?.exam?.status?.status
                    status: getCoachStatus(x),

                }

                arr.push(info)
            }
        }))
        return arr
    }
  
    getPercentage(companyCoach){
        let errors =  this.getCoachStatsAll(companyCoach).filter(a=>a.status == 2).length;
        let warnings = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1).length;
        let success = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0).length;
        let total = errors + warnings + success;
        return {
            total: total,
            errors: Math.round((errors*100)/total),
            warnings: Math.round((warnings*100)/total),
            success: Math.round((success*100)/total),
        }
    } 
  render() {
    const {adminCompanies} = this.props;
   
    return (
      <div className=' max-[1100px]:mt-[150px] max-[800px]:mt-[180px] items-start p-[20px] rounded-[20px] mt-[40px]'>
        <div className='grid-full-250 '>
            {
                adminCompanies.map((data, i)=>{
                    if(data?.id !== 32 && 
                        data?.id !== 29 &&
                        data?.id !== 28 &&
                        data?.id !== 27 &&
                        data?.id !== 26 &&
                        data?.id !== 22 &&
                        data?.id !== 25 &&
                        data?.id !== 24 &&
                        data?.id !== 19 &&
                        data?.id !== 31 &&
                        data?.id !== 34 &&
                        data?.id !== 35 &&
                        data?.id !== 18 &&
                        data?.id !== 17 
                    ){
                        return(
                            <Link target='_blank' to={`/audit-company/${data?.id}/${data?.name}`} key={i} className='shadow p-[20px] rounded-[10px] bg-white'>
                                <h3>{data?.name}</h3>
                                <div className='flex mt-[10px] justify-center'>
                                    <button className='pl-[5px] pr-[5px] bg-blue rounded-[5px] pt-[2px] pb-[2px] text-white text-[12px]'>View</button>
                                </div>
                               
                            </Link>
                        )
                    }
                    
                })
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    adminCompanies: state.Data.adminCompanies
});
const mapDispatchToProps = {getAuditCoach, getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(AuditorDashboard)
