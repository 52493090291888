import React, { Component } from 'react'

export class CoachWarningSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        stroke="#ff5f24"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        style={{ minWidth:'24px' }}
        className="feather feather-bell"
      >
        <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0" />
      </svg>
    )
  }
}

export default CoachWarningSvg
