import React, { Component } from 'react'

export class CardEmailSvg extends Component {
  render() {
    return (
        <svg width="14" height="14" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.75 1.875H1.25C1.08424 1.875 0.925268 1.94085 0.808058 2.05806C0.690848 2.17527 0.625 2.33424 0.625 2.5V7.5C0.625 7.66576 0.690848 7.82473 0.808058 7.94194C0.925268 8.05915 1.08424 8.125 1.25 8.125H8.75C8.91576 8.125 9.07473 8.05915 9.19194 7.94194C9.30915 7.82473 9.375 7.66576 9.375 7.5V2.5C9.375 2.33424 9.30915 2.17527 9.19194 2.05806C9.07473 1.94085 8.91576 1.875 8.75 1.875ZM8.0625 2.5L5 4.61875L1.9375 2.5H8.0625ZM1.25 7.5V2.78437L4.82188 5.25625C4.87419 5.29254 4.93633 5.31199 5 5.31199C5.06367 5.31199 5.12581 5.29254 5.17812 5.25625L8.75 2.78437V7.5H1.25Z" fill="#1E398F"/>
        </svg>        
    )
  }
}

export default CardEmailSvg
