import React, { Component } from 'react'

export class ReNewSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#1E398F"
          fillRule="evenodd"
          d="M12 6c-3.31 0-6 2.69-6 6h3l-4 4-4-4h3c0-4.42 3.58-8 8-8 1.57 0 3.03.46 4.26 1.24L14.8 6.7A5.87 5.87 0 0 0 12 6Zm3 6 4-4 4 4h-3c0 4.42-3.58 8-8 8a7.93 7.93 0 0 1-4.26-1.24L9.2 17.3c.83.45 1.79.7 2.8.7 3.31 0 6-2.69 6-6h-3Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default ReNewSvg
