import React, { Component } from 'react'
import { connect } from 'react-redux';
import { confirmCode } from '../actions/PublicActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { withHooks } from '../actions/withHooks';
import logo from "../images/logo.png"
import LoginPenSvg from '../components/svg/LoginPenSvg';
import { mixinAlert } from '../actions/SweetAlerts';
export class ConfirmPage extends Component {
  state={
    code:'',
    email:'',
    btnDisable:false
  }
  componentDidMount(){
    window.scrollTo(0,0)
    const checkSearch = window.location.search.includes('email');
    if(checkSearch){
        const checkEmailExists = window.location.search.split("=")[1];
        if(checkEmailExists){
            if(checkEmailExists.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
                this.setState({email: checkEmailExists})
            }else{
                this.props.navigate("/")
            }
        }else{
            this.props.navigate("/")
        }
    }else{
        this.props.navigate("/")
    }
  }
  confirmBtn=(data, e)=>{
    e.preventDefault();
    if(data?.code?.trim() !==""){
        if(data?.code.length !== 6){
            mixinAlert("warning", "Code should be 6 characters!");
            return
        }
        this.setState({btnDisable:true});
        this.props.confirmCode(data)
        .then(resp=>{
            this.setState({btnDisable:false});
            if(resp?.msg === "incorrect_code"){
                mixinAlert("error", "Code is not valid");
            }else if(resp?.msg === "email_not_exists"){
                mixinAlert("error", "Email address was changed");
                this.props.navigate("/register");
            }else if(resp?.msg === "success"){
                this.props.navigate("/level-check");
                mixinAlert("success", "Email address confirmed successfully");
            }else{
                mixinAlert("warning", "Something went wrong.");
            }
        })
    }
  }
  render() {
    const {code, btnDisable} = this.state;
    return (
      <div className='flex bg-[#f5f5f5] items-center justify-center min-h-screen w-full mb-[-120px]'>
        <div className='w-[510px] bg-white pt-[44px] pl-[54px]  max-[660px]:pl-[20px]  max-[660px]:pr-[20px] pr-[54px] pb-[50px] rounded-[12px] shadow max-[520px]:ml-[20px] max-[520px]:mr-[20px]'>
          <div className='flex justify-center'>
            {/* <img className='w-[160px] h-[50px]' src={logo} /> */}
            <h2 className='text-[30px] font-semibold txt-blue'>Check your email</h2>
          </div>
          <div className='mt-[20px]'>
            <h4 className='txt-light-black text-[24px] font-bold'>Enter code</h4>
            <p className='text-[14px] text-[#64748B] font-regular'>Enter the code we sent you on your email address</p>
          </div>
          <form className='mt-[35px]'>
              <div className='flex flex items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                <LoginPenSvg />
                <input className='bg-white w-[80%] outline-none h-[40px] rounded-full p-2 text-[14px]' type='number' id='confirmCode' placeholder='Code' value={code} onChange={(e)=>this.setState({code: e.target.value})} />         
              </div>
             
              <button disabled={btnDisable} onClick={this.confirmBtn.bind(this,this.state)} className='mt-[33px] bg-blue w-full h-[56px] font-bold text-[16px] rounded-[12px] text-white '>Continue</button>
             
          </form>
          <div className='pt-[30px] flex justify-center'>
            <p><Link className='txt-blue' to={'/login'}>Back to Sign In</Link></p>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {confirmCode}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ConfirmPage))


