import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCoursesData } from '../../actions/MainAction';
import { withHooks } from '../../actions/withHooks';
import Header from '../../components/header/Header';
import Card3 from '../../components/cards/Card3'
import EmptyPage from '../../components/EmptyPage';
export class ListeningData extends Component {
    componentDidMount(){
        this.props.getCoursesData(this.props.params.id);
        window.scrollTo(0,0)
    }
  render() {
    const {coursesData} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={coursesData?.title} />
            {coursesData?.listenings?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-320 max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {
                    coursesData?.listenings?.map((data, i)=>{
                        return(
                            <Card3 
                                key={i}
                                title={data?.title}
                                file_url={data?.audio_file_real_path}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    coursesData: state.Data.coursesData
});
const mapDispatchToProps = {getCoursesData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ListeningData))
