import React, { Component } from 'react'
import Card1 from '../../components/cards/Card1'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getSpeakingReview, getSpeakingAnswer } from '../../actions/MainAction';
import {jwtDecode} from "jwt-decode";
import moment from 'moment';
import { getGradeAll, getGrades, getRole } from '../../actions/getRole';
import { changeStateValue } from '../../redux/MainReducer';
import LoadMoreBtn from "../../components/LoadMoreBtn"
import Search from '../../components/Search';
import EmptyPage from '../../components/EmptyPage';
import { getMistralModel } from '../../actions/robotActions';
import { getSpeakingGradeMistral, getSpeakingMistral } from '../../actions/getReviewGpt';
import { insertNewData } from '../../actions/MainAction';
import { recheckSpeaking } from '../../actions/StaffAction';
export class SpeakingReview extends Component {
    state={
        search:'',
        studentId:'',
        page:1
    }
    componentDidMount(){
        this.props.getSpeakingReview(1);
        window.scrollTo(0,0)
    }
    getGrade(reviews, autoGrade, grade, text){
        if(!text){
            return grade
        }else{
            const check = text.split(' ');
            if(check.length<30){
                return grade
            }
        }
        if(reviews.length == 0){
            return grade
        }else{
            let grades = 0;
            for(const data of reviews){
                grades+=data.grade;
            }
            if(autoGrade){
                return  ((grades+autoGrade.grade)/(reviews.length+1)).toFixed(0)
             
            }else{
                return grade;
            }
        }
    }
    openModal=(data)=>{
       
        this.props.getSpeakingAnswer(data?.speaking?.id, data?.user_id, data?.id)
        .then(resp=>{
            let check = 0;
                if(resp?.answare[0]?.text){
                    check = resp?.answare[0]?.text.split(' ')?.length;
                }
            if(resp != 'error'){
                const params = {
                    position:true,
                    type:'speaking',
                    modalType: 'review',
                    title: resp?.title,
                    question: resp?.question,
                    speaking: resp?.answare[0]?.image_full_url,
                    reviews: resp?.reviews[0]?.review,
                    teacher: resp?.reviews[0]?.user?.full_name,
                    role: getRole(resp?.reviews[0]?.user?.role, this.props.lang),
                    stuent: data?.user?.full_name,
                    robot_review: check>=0? data?.robot_review:null,
                    grade: resp?.reviews[0]?.grade,
                    speaking_robo_waiting: check>=0 && !data?.robot_review? true:false,
                    not_check_robo_text:''
                }
              
                if(check>=0 && !data?.robot_review){
                    recheckSpeaking(data?.id)
                    .then(resp=>{
                        if(resp !==""){
                            this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade:resp?.grade, review: resp?.review}});
                            this.props.changeStateValue({name: 'mainModal.not_check_robo_text', value: ''});
                            this.props.getSpeakingReview(this.state.page, this.state.search)
                        }else{
                            this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade:resp?.grade, review: resp?.review}});
                            this.props.changeStateValue({name: 'mainModal.not_check_robo_text', value: ''});
                        }
                    })
                }
                this.props.changeStateValue({name: 'mainModal', value: params});
            }
        })
    }

    loadMore=(page)=>{
        this.setState({page})
        this.props.getSpeakingReview(page, this.state.search);
    }

    onSearchData=(search)=>{
        this.setState({search:search})
        this.props.getSpeakingReview(1, search);
    }
  render() {
    const {speakingReviews, lang} = this.props;
    // console.log(speakingReviews)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="sp_rec_rws" />
            <div className='flex justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            {speakingReviews?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
                {
                    speakingReviews?.map((data, i)=>{
                        return(
                            <Card1 
                                key={i}
                                title={data?.speaking?.title}
                                date={moment(data?.created_at).format("DD/MM/YYYY")}
                                grade={getGradeAll(data.review,  data.robot_review, data.grade, data.text)}
                                status={getGradeAll(data.review,  data.robot_review, data.grade, data.text) >=data?.speaking?.course_prompt?.grade?1:0}
                                statusText={getGradeAll(data.review,  data.robot_review, data.grade, data.text) >=data?.speaking?.course_prompt?.grade?lang?.pass: lang?.fail}
                                onCLickBtn={this.openModal.bind(this, data)}
                            />
                        )
                    })
                }
                
            </div>
            {
                speakingReviews?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={speakingReviews?.length} />:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    speakingReviews: state.Data.speakingReviews,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getSpeakingReview, getSpeakingAnswer, changeStateValue, getMistralModel, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(SpeakingReview)



