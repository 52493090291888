import React, { Component } from 'react'

export class RightBigSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            className="icon"
            viewBox="0 0 1024 1024"
        >
            <path d="M256 120.768 306.432 64 768 512 306.432 960 256 903.232 659.072 512z" />
        </svg>
    )
  }
}

export default RightBigSvg
