import React, { Component } from 'react'
import CardUnLockSvg from '../svg/CardUnLockSvg'
import CardBonusSvg from '../svg/CardBonusSvg'
import defuser from "../../images/defaultUser.jpeg"
import CardLine from './CardLine'
import CardEmailSvg from '../svg/CardEmailSvg'
import CardPhoneSvg from '../svg/CardPhoneSvg'
import CardEditSvg from '../svg/CardEditSvg'
import CardMoreSvg from '../svg/CardMoreSvg'
import CardLockSvg from '../svg/CardLockSvg'
import CardChildSvg from '../svg/CardChildSvg'
import CardDeleteUserSvg from '../svg/CardDeleteUserSvg'
import CardPlusSvg from '../svg/CardPlusSvg'
import CardStatisticSvg from '../svg/CardStatisticSvg'
import CardCourseSvg from '../svg/CardCourseSvg'
import CardPayModeSvg from '../svg/CardPayModeSvg'
import CardDateSvg from '../svg/CardDateSvg'
import CardSchYearSvg from '../svg/CardSchYearSvg'
import CardTeacherSvg from '../svg/CardTeacherSvg'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import CardExamCheck from '../svg/CardExamCheck'
import ChildSvg from '../svg/ChildSvg'
import CardCertSvg from '../svg/CardCertSvg'
import CardEventSvg from '../svg/CardEventSvg'
import TeacherContractsSvg from '../svg/TeacherContractsSvg'
import CardLessonModeSvg from '../svg/CardLessonModeSvg'
import CardTitleSvg from '../svg/CardTitleSvg'
import CardDeleteSvg from '../svg/CardDeleteSvg'
import CardLineBtn from './CardLineBtn'
import CardScheduleSvg from '../svg/CardScheduleSvg'
import CardTelSvg from '../svg/CardTelSvg'
import CardLocationSvg from '../svg/CardLocationSvg'
import CardCompanySvg from '../svg/CardCompanySvg'
import CardExamSvg from '../svg/CardExamSvg'
import CardKeySvg from '../svg/CardKeySvg'
import CardPdfSvg from '../svg/CardPdfSvg'
import CheckSvg from '../svg/CheckSvg'
import Check2Svg from '../svg/Check2Svg'
import SeniorStatusSvg from '../svg/SeniorStatusSvg'
export class Card4 extends Component {
  render() {
    const {name, email="", phone="", 
      image, role,bonus=false, lock=1,
      onClickEdit=()=>{},stat=false,course="", 
      onClickDelete=()=>{},student="",
      showStudents=()=>{},moreStudent=false, 
      headerBtn=true, childs=false, info=false, 
      deleteBtn=false, onClickLockUnlock=()=>{},
      payMode="", schYear="", teacher="", onClickStat=()=>{},
      lockUnlock=true, user,child="", onClickCheck=()=>{},blank=false, 
      checkExam=false, more=false, onClickMore=()=>{},onClickInfo=()=>{},
      editBtnPermisiion=true, shadow=false, cert=false, addCertificate=()=>{}, events="",
      widthPermission=true, editLink="", contract=false, 
      onClickContract=()=>{}, lesson_mode="", addPaymentBtn=false, paymentBtnWarning=false, 
      onClickPayment=()=>{}, titleRemaining="", namePath="", lang, headerVisible=true, title="", 
      date="", deleteItem=false, payModeBtn="", onLineClick=()=>{}, schedule="", tel="", location="",
       locationPath="", deleteUserBtn=false, company="", lesson="", examKey="", pdf=false, addPdfBtn=()=>{}, created_at="",
       callStatusBtn=false, callStatus=0, onChangeStatus=()=>{}, seniorBtn=false, onChangeSenior=()=>{}, seniorStatus=0, phone_number2="", onGenerate=()=>{}} = this.props;
    return (
      <div className={`bg-white rounded-[20px] ${shadow? "shadow border border-gray-200":""}`}>
        
        {
          headerBtn?
          <div className='flex justify-between mt-[16px] mr-[20px] ml-[20px] gap-[2px]'>
           <div className='flex justify-between   gap-[2px]'>
           {
              contract?
              <button onClick={()=>onClickContract()} className='bg-light-blue w-[18px] h-[18px] rounded-[3px] inline-flex items-center justify-center'>
                <TeacherContractsSvg />
              </button>:<span></span>
            }
           {
              seniorBtn?
              <button onClick={()=>onChangeSenior()} className='bg-light-blue w-[18px] h-[18px] rounded-[3px] inline-flex items-center justify-center'>
                <SeniorStatusSvg color={seniorStatus==1?"green":"gray"} />
              </button>:<span></span>
            }
            {
              schedule !=""?
              <Link onClick={()=>localStorage.setItem("dash", "schedule")} target='_blank' className='bg-light-blue w-[18px] h-[18px] rounded-[3px] inline-flex items-center justify-center' to={schedule}>
                <CardScheduleSvg />
              </Link>:null
            }
           </div>
            <div className='flex justify-between   gap-[2px]'>
   
            
            {
              events !=""?
              <Link target='_blank' className='bg-light-blue w-[18px] h-[18px] rounded-[3px] inline-flex items-center justify-center' to={events}>
                <CardEventSvg />
              </Link>:null
            }
            {
              cert?
              <button onClick={()=>addCertificate()} className='bg-light-blue w-[18px] h-[18px] rounded-[3px] inline-flex items-center justify-center'>
              <CardCertSvg />
            </button>:null
            }
            {
              pdf?
              <button onClick={()=>addPdfBtn()} className='bg-light-blue w-[18px] h-[18px] rounded-[3px] inline-flex items-center justify-center'>
              <CardPdfSvg />
            </button>:null
            }
            
            {
              lockUnlock?
              <button onClick={()=>onClickLockUnlock(lock)} className={`${lock === 1? 'bg-light-blue':'bg-red-300'}  w-[18px] h-[18px] rounded-[3px] inline-flex items-center justify-center`}>
              {
                lock===1?
                <CardUnLockSvg />:<CardLockSvg />
              }
              
            </button>:null
            }
            
            {
              bonus?
              <Link to={bonus} className='bg-light-blue w-[18px] h-[18px] rounded-[3px] inline-flex items-center justify-center'>
                <CardBonusSvg />
              </Link>:null
            }
            {
              stat?
              <button onClick={()=>onClickStat()} className='bg-light-blue w-[18px] h-[18px] rounded-[3px] inline-flex items-center justify-center'>
                <CardStatisticSvg />
              </button>:null
            }
            </div>
           
          </div>:<div className='h-[20px]'></div>
        }
          
          {
            headerVisible?
            <div className='flex flex-col justify-center items-center'>
            
            <img className='w-[61px] h-[61px] rounded-full' src={!image || image === ""? defuser:image} alt={name} />
            <Link to={namePath} onClick={(e)=>{if(namePath===""){e.preventDefault()}}} target='_blank' className='font-semibold text-[14px] mt-[10px] txt-light-black'>{name}</Link>
            <span className='font-regular text-[12px] txt-dark-gray'>{role}</span>
            {
              titleRemaining !="" || titleRemaining ===0?
               <span className={`font-semibold text-[14px] ${titleRemaining == 0?'txt-red': titleRemaining <=3? 'txt-orange': "txt-dark-gray"}`}>{titleRemaining}</span>:null
            }
           
          </div>:null
          }
          <div className='flex ml-[20px] flex-col mt-[14px] gap-[9px] pr-[20px]'>
            {
              title !== ""?
              <CardLine rounded={true} svg={<CardTitleSvg />} title={title} />:null
            }
            {
              email !== ""?
              <CardLine rounded={true} svg={<CardEmailSvg />} title={email} />:null
            }
            {
              phone !== ""?
              <CardLine rounded={true} svg={<CardPhoneSvg />} title={phone} />:null
            }
            {
              phone_number2 !== ""?
              <CardLine rounded={true} svg={<CardPhoneSvg />} title={phone_number2} />:null
            }
            
            {
              student !== ""?
              <CardLine rounded={true} svg={<CardChildSvg />} title={student} />:null
            }
            {
              child !== ""?
              <CardLine rounded={true} svg={<ChildSvg />} title={child} />:null
            }
            {
              lesson_mode !== ""?
              <CardLine rounded={true} svg={<CardLessonModeSvg />} title={lesson_mode} />:null
            }
            {
              course !== ""?
              <CardLine rounded={true} svg={<CardCourseSvg />} title={course} />:null
            }
            {
              lesson !== ""?
              <CardLine rounded={true} svg={<CardExamSvg />} title={lesson} />:null
            }
            {
              teacher !== ""?
              <CardLine rounded={true} svg={<CardTeacherSvg />} title={teacher} />:null
            }
            
            {
              payMode !== ""?
              <CardLine rounded={true} svg={<CardPayModeSvg />} title={payMode} />:null
            }
            {
              payModeBtn !== ""?
              <CardLineBtn onLineClick={()=>onLineClick()} rounded={true} svg={<CardPayModeSvg />} editSvg={true} title={payModeBtn} />:null
            }
            {
              schYear !== ""?
              <CardLine rounded={true} svg={<CardSchYearSvg />} title={schYear} />:null
            }
            {
              date !== ""?
              <CardLine rounded={true} svg={<CardDateSvg />} title={date} />:null
            }
            {
              company !== ""?
              <CardLine rounded={true} svg={<CardCompanySvg />} title={company} />:null
            }
            {
              examKey !== ""?
              <CardLine rounded={true} svg={<CardKeySvg />} title={examKey} onGenerate={()=>onGenerate()} generate={true} />:null
            }
            {
              created_at !== ""?
              <CardLine rounded={true} svg={<CardDateSvg />} title={created_at} />:null
            }
             {
              location !== ""?
              <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
              <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] rounded-full  inline-flex items-center justify-center`}>
                 <CardLocationSvg />
              </div>
              <a href={locationPath} target='_blank' className='text-[14px]  font-semibold txt-dark-gray'>CELT Knightsbridge</a>
            </li>:null
            }
            {
              tel !== ""?
              <li className='flex items-center gap-[10px] border-b border-dashed pb-[10px] border-b-[rgba(0,0,0,0.5)]'>
              <div className={`bg-light-blue min-w-[22px] w-[22px] h-[22px] rounded-full  inline-flex items-center justify-center`}>
                 <CardTelSvg />
              </div>
              <a href={`tel:${tel?.replaceAll(" ", "")}`}  className='text-[14px]  font-semibold txt-dark-gray'>{tel}</a>
            </li>:null
            }
           
          </div>
          <div className='h-[1px] w-full bg-gray-200 mt-[27px]'></div>
          
          {
            addPaymentBtn?
            <div className='flex justify-center items-center h-[45px]'>
              <button onClick={()=>onClickPayment()} className={`${paymentBtnWarning? 'bg-red':'bg-blue'} text-[12px] font-regular text-white rounded-[4px] p-[2px_4px]`}>{paymentBtnWarning? lang?.pay_unlck: lang?.add_pay}</button>
            </div>:null
          }
          <div className='flex'>
          {
              callStatusBtn?
              <button onClick={()=>onChangeStatus()} className='w-[100%] h-[38px]  inline-flex items-center justify-center border-r'><Check2Svg color={callStatus==0?"gray":"green"} /></button>:null
            }
            {
              deleteItem && ( user?.role === "office_manager" || user?.role === "company_head" || user?.role === "super_admin")?
              <button onClick={()=>onClickDelete()} className='w-[50%] h-[38px]  inline-flex items-center justify-center border-r'><CardDeleteSvg /></button>:null
            }
            {
             editBtnPermisiion && ( user?.role === "office_manager" || user?.role === "company_head" || user?.role === "super_admin")?
             editLink==""?
              <button onClick={()=>onClickEdit()} className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'>
                <CardEditSvg />
              </button>:
              <Link  target={blank?"_blank":""} to={editLink} className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'>
                <CardEditSvg />
              </Link>:widthPermission?<p className='w-[50%] '></p>:null
            }
            {
              checkExam?
              <button onClick={()=>onClickCheck()} className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'>
                <CardExamCheck />
              </button>:null
            }
            
            {
              childs?
              <button onClick={moreStudent?()=>showStudents():()=>{}} className='w-[50%] h-[38px]  inline-flex items-center justify-center'>
                <span className={`inline-block ${moreStudent?"bg-blue":"bg-light-blue" }  p-[3px] rounded-[3px]`}>
                  <CardPlusSvg color={moreStudent?"#fff": "#1E398F" } />
                </span>
              </button>:null
            }
            {
              more?
              <button onClick={()=>onClickMore()} className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'>
                <span className={`inline-block bg-blue p-[3px] rounded-[3px]`}>
                  <CardPlusSvg color={"#fff" } />
                </span>
              </button>:null
            }
            {
              info?
              <Link target={blank?"_blank":""} to={info} onClick={e=>onClickInfo(e)} className='w-[50%] h-[38px]  inline-flex items-center justify-center'><CardMoreSvg /> </Link>:null
            }
            {
              deleteBtn?
              <button onClick={()=>onClickDelete()} className='w-[50%] h-[38px]  inline-flex items-center justify-center'><CardDeleteSvg /></button>:null
            }
           
            {
              deleteUserBtn?
              <button onClick={()=>onClickDelete()} className='w-[50%] h-[38px]  inline-flex items-center justify-center border-l'><CardDeleteSvg /></button>:null
            }
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  lang: state.Data.lang,
})
export default connect(mapStateToProps)(Card4)
