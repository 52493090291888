import React, { Component } from 'react'

export class LoginPenSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
      >
        <path
          fill="#545454"
          d="M13.748.947a2 2 0 0 1 2.828 0l2.475 2.475a2 2 0 0 1 0 2.829L7.158 18.144.778 19.22l1.077-6.38L13.748.947Zm-.229 3.057 2.475 2.475 1.643-1.643-2.475-2.474-1.643 1.642Zm1.06 3.89-2.474-2.475-8.384 8.384-.503 2.977 2.977-.502 8.385-8.385h-.001Z"
        />
      </svg>
    )
  }
}

export default LoginPenSvg
