import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getCertificates } from '../../actions/MainAction';
import {jwtDecode} from "jwt-decode";
import Card2 from '../../components/cards/Card2';
import Search from '../../components/Search';
import EmptyPage from '../../components/EmptyPage';

export class Certificate extends Component {
  state={
    studentId: ''
  }
  getData(studentId){
    this.props.getCertificates(studentId);
  }
  componentDidMount(){
    if(localStorage.getItem('userToken')){
      const data = jwtDecode(localStorage.getItem('userToken'));
      this.setState({studentId:data?.sub});
      this.getData(data?.sub)
    }
    window.scrollTo(0,0)
  }

  onSelectStudent=(id)=>{
    this.setState({studentId:id})
    this.props.getCertificates(id);
  }
  render() {
    const {certificates, user} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
          <Header title="certificate" />
          <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
            <Search search={false} onSelectStudent={this.onSelectStudent} student={user?.role == "parent"} onSearchData={this.onSearchData} />
            {certificates?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-205'>
              {
                certificates?.map((data, i)=>{
                  return(
                    <Card2 
                      key={i}
                      title={data?.course_type}
                      download={true}
                      downloadUrl={data?.certificate_full_url}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  certificates: state.Data.certificates,
  user: state.Data.user,
});
const mapDispatchToProps = {getCertificates}
export default connect(mapStateToProps, mapDispatchToProps)(Certificate)
