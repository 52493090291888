import React, { Component } from 'react'
import ParentDescription from './ParentDescription'
import Audio from './Audio'
import Video from './Video'
import SingleChoice from './SingleChoice'
import Image from './Image'
import MultiChoice from './MultiChoice'
import DropDown from './DropDown'
import FreeText from './FreeText'

export class Body extends Component {
  render() {
    const {data, parentIndex} = this.props;
    return (
      <div className='mt-[20px]'>
        {
          data?.question_description?
          <ParentDescription text={data?.question_description} />:null
        }
        {
          data?.audio_full_url?
          <Audio url={data?.audio_full_url} />:null
        }
        {
          data?.video_full_url?
          <Video url={data?.video_full_url} />:null
        }
        {
          data?.image_full_url?
          <Image url={data?.image_full_url} />:null
        }
        {
          data?.student_exam_questions?.map((item, i)=>{
            if(item?.question_type !== "free_text" && item?.question_type !== "dropdown_question_type" && item?.question_type !== "multiple_choice"){
              return(
                <div key={i}>
                <SingleChoice  parentIndex={parentIndex} index={i} data={item} />
                <span className='inline-block w-[40%] border-b border-dashed mt-[50px]'></span>
                </div>
              )
            }else if(item?.question_type === "multiple_choice" ){
              return(
                <div key={i}>
                <MultiChoice parentIndex={parentIndex} index={i}  data={item}  />
                <span className='inline-block w-[40%] border-b border-dashed mt-[50px]'></span>
                </div>
                
              )
            }else if(item?.question_type === "dropdown_question_type"){
              return(
                <DropDown parentIndex={parentIndex} index={i} key={i} data={item} />
              )
            }else if(item?.question_type === "free_text"){
              return(
                <FreeText parentIndex={parentIndex} index={i} key={i} answers={item} />
              )
            }
          })
        }
      </div>
    )
  }
}

export default Body
