import React, { Component } from 'react'
import Header from './Header'
import EyeSvg from '../svg/EyeSvg'
import PaymentModalBody from './components/PaymentModalBody'
import { connect } from 'react-redux'
import { insertNewData } from '../../actions/MainAction'
import { mixinAlert } from '../../actions/SweetAlerts'
export class StudentPayModal extends Component {
    state={
        btnDisabled:false,
        selecteds:[]
    }
    closeModal=()=>{
        this.props.closeModalBtn()
    }
    saveBtn=(selecteds)=>{
        if(selecteds.length !== 0){
            this.setState({btnDisabled:true})
            this.props.insertNewData("student-payment-set", {teachers: JSON.stringify(selecteds)})
            .then(resp=>{
                if(resp === "success"){
                    this.closeModal();
                    this.props.getAfterChange()
                    mixinAlert("success", this.props.lang?.st_unlocked_succ)
                }else{
                    mixinAlert("error", this.props.lang?.popup_went_wrong)
                }
            })
        }else{
            mixinAlert("info", "Please select a lesson mode");
        }
        
    }
    selectPayment=(selecteds, item)=>{
        const check = selecteds.findIndex(a=>a.lesson_mode === item?.lesson_mode);
        let newData= [...selecteds];
        if(check === -1){
            newData.push({
                student_id: item?.student_id,
                teacher_id: item?.teacher_id,
                lesson_mode: item?.lesson_mode,
            });

        }else{
            newData.splice(check, 1)
        }
        this.setState({selecteds:newData})
    }
  render() {
    const {btnDisabled, selecteds} = this.state;
    const {data, lang} = this.props;
    let items = [];
    for(const item of data){
        const check = items.find(x=>x.teacher_id==item?.teacher_id && x.student_id==item?.student_id  && x.lesson_mode==item?.lesson_mode)
        if(!check){
            items.push(item)
        }
    }
    return (
        <div  className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[600px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={lang?.make_pay} />
                <div className='p-[20px] max-h-[70vh] overflow-auto'>
                    <div className='flex flex-col gap-[10px]'>
                        {
                            items?.map((item, i)=>{
                                return(
                                    <PaymentModalBody selecteds={selecteds} selectPayment={(array, item)=>this.selectPayment(array, item)} key={i} i={i} item={item} />
                                )
                            })
                        }
                    </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this, selecteds)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save}</button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang
});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(StudentPayModal)
