import React, { Component } from 'react'
import ErrorSvg from './svg/ErrorSvg'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { resetStateValue } from '../redux/MainReducer';
export class ErrorPage extends Component {
    closeData=()=>{
        window.location.reload()
        this.props.resetStateValue({ name:'unAuthPopUp' })
    }
  render() {
    const {errorPageData} = this.props;
  
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white p-[20px] rounded-[12px] shadow max-w-[400px] flex flex-col items-center gap-[10px]'>
                <ErrorSvg />
                <h1 className='text-gray-500 font-semibold text-[26px]'>{errorPageData?.code}</h1>
                <p className='text-center'>Something went wrong. Please,  reload page.</p>
                <button onClick={this.closeData.bind(this)}  className='bg-blue text-[14px] rounded-[6px] h-[25px] w-[60px] text-white font-semibold text-center pt-[2px]'>OK</button>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    errorPageData: state.Data.errorPageData
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage)


