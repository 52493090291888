import React, { Component } from 'react'
import Header from '../../components/header/Header'
import SectionHeader from '../../components/SectionHeader'
import { connect } from 'react-redux'
import { getOfficeStudents, getLessonModes, getStudentPaymentsEnroll } from '../../actions/StaffAction'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import { getRole } from '../../actions/getRole'
import Card4 from '../../components/cards/Card4'
import HeaderButtons from '../../components/HeaderButtons';
import { insertNewData } from '../../actions/MainAction'
import { mixinAlert } from '../../actions/SweetAlerts'
import StudentPayModal from '../../components/modals/StudentPayModal'
import WeeklyStatisticsModal from '../../components/modals/WeeklyStatisticsModal'
import { withHooks } from '../../actions/withHooks'
import CheckExamReminderModal from '../../components/modals/CheckExamReminderModal'
import StudentMoreModal from '../../components/modals/StudentMoreModal'
import EmptyPage from '../../components/EmptyPage'
import { changeStateValue } from '../../redux/MainReducer'
import PublicExamResultsModal from '../../components/modals/PublicExamResultsModal'
import PayyStudentEnrollModal from '../../components/modals/PayyStudentEnrollModal'
export class CeltStudents extends Component {
    state={
        buttons: [
            
            {
              title: this.props.lang?.unlocked,
              value: "Unlocked"
            },
            {
              title: this.props.lang?.locked,
              value: "Locked"
            },
            {
                title: this.props.lang?.all,
                value:''
              },
          ],
          selected:0,
          page:1,
          search:'',
          lockFilter:'Unlocked',
          modeFilter:'',
          yearFilter:'',
          payments:[],
          statModal:{
            position:false,
            student_id:''
        },
        reminderModal:{
            position:false,
            studentId:''
        },
        moreDataModal:{
            position: false
        }
    }
    searchStudents=(search)=>{
        this.setState({search:search})
        this.props.getOfficeStudents(1, search, this.props.user?.company_id, this.state.lockFilter, this.state.modeFilter, this.state.yearFilter); 
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps?.user !== this.props.user){
            this.props.getOfficeStudents(1, '', this.props.user?.company_id, 'Unlocked', '', '');  
        }
    }
    componentDidMount(){
        if(this.props.user !== ""){
           this.props.getOfficeStudents(1, '', this.props.user?.company_id, 'Unlocked', '', '');  
        }
        this.props.getLessonModes();
        window.scrollTo(0,0)
    }
  
    loadMore=(page)=>{
        this.setState({page:page});
        this.props.getOfficeStudents(page, this.state.search, this.props.user?.company_id, this.state.lockFilter, this.state.modeFilter, this.state.yearFilter);
    }
    addUser=(data)=>{
        this.props.navigate(`/students/${data?.id}/edit`)
    }

    getResultsByType=(data, i)=>{
        this.setState({selected:i, lockFilter:data.value})
        this.props.getOfficeStudents(this.state.page, this.state.search, this.props.user?.company_id, data.value, this.state.modeFilter, this.state.yearFilter);  
    }

    onSelectDrop=(data, type)=>{
        if(type === "drop1"){
            this.setState({modeFilter:data});
            this.props.getOfficeStudents(this.state.page, this.state.search, this.props.user?.company_id, this.state.lockFilter, data, this.state.yearFilter);
        }else if(type === "drop2"){
            this.setState({yearFilter:data});
            this.props.getOfficeStudents(this.state.page, this.state.search, this.props.user?.company_id, this.state.lockFilter, this.state.modeFilter, data);
        }
    }

    studentLockUnlock=(data, lock)=>{
        if(this.props.user?.role === "company_head" || this.props.user?.role === "office_manager" || this.props.user?.role === "super_admin"){
            if(data.attendance_lock_status === 1){
                // this.props.getStudentPaymentsEnroll(data?.id)
                // .then(resp=>{ this.setState({payments: resp}) })
                mixinAlert("warning", this.props.lang?.go_pay)
            }else{
                if(this.props.user?.role === "super_admin"){
                    this.props.insertNewData(`users-manual-lock/${data?.id}`, {type: lock===1? "lock": "unlock"})
                    .then(resp=>{
                        if(resp === "success"){
                            mixinAlert("success", `Student ${lock===1? "locked": "unlocked"} successfully.`)
                            this.props.getOfficeStudents(this.state.page, this.state.search, this.props.user?.company_id, this.state.lockFilter, this.state.modeFilter, this.state.yearFilter);
                        }else{
                            mixinAlert("error", "Something went wrong!")
                        }
                    })
                }else if(this.props.user?.role == "company_head" || this.props.user?.role == "office_manager"){
                    if(lock !== 1){
                        this.props.insertNewData(`users-manual-lock/${data?.id}`, {type: "unlock"})
                        .then(resp=>{
                            if(resp === "success"){
                                mixinAlert("success", `Student ${lock===1? "locked": "unlocked"} successfully.`)
                                this.props.getOfficeStudents(this.state.page, this.state.search, this.props.user?.company_id, this.state.lockFilter, this.state.modeFilter, this.state.yearFilter);
                            }else{
                                mixinAlert("error", "Something went wrong!")
                            }
                        })
                    }
                }
                
            }
        }
        
    }
    getAfterChange=()=>{
        this.props.getOfficeStudents(this.state.page, this.state.search, this.props.user?.company_id, this.state.lockFilter, this.state.modeFilter, this.state.yearFilter);
    }
    createStudents=()=>{
        if(this.props.user?.role === "office_manager" || this.props.user?.role === "company_head"){
            this.props.navigate("/students/create")
        }
    }
    checkStudentExam=(data)=>{
        this.setState({
            reminderModal: {
                position: true,
                studentId: data?.id
            }
        })
    }

    showMoreData=(data)=>{
        this.setState({
            moreDataModal: {position:true, ...data}
        })
    }
    addCertificateBtn=(data)=>{
        this.props.changeStateValue({
            name:'addExResultsData',
            value:{
                position: true,
                id: '',
                student_name:data?.full_name,
                exam_type_id:'',
                score:'',
                date:'',
                image: '',
                cert_image: '',
                image_full_url:'',
                cert_image_full_url: '',
                callAfterFunction: ()=>{},
                afterParams:[],
                modalTitle: `Add exam results`
            }
        })
    }
  render() {
    const {officeStudents, lessonModes, user, lang, addExResultsData, enrollPaymentsModal} = this.props;
    const {buttons, selected, payments, statModal, reminderModal, moreDataModal} = this.state;
    let lessonModeOptions=[{label: 'Clear', value: ""}];
    for(const data of lessonModes){
        lessonModeOptions.push({
            label: data?.title,
            value: data?.title
        })
    }
    let schYearOption=[{label: 'Clear', value: ""}];
    for(let i = 1; i <=12; i++){
        schYearOption.push({label:i, value:i})
    }
    // console.log(officeStudents)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={user?.role === "head_teacher"?"all_students":"students"} />
            <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                  <SectionHeader  
                    onSearchData={(search)=>this.searchStudents(search)} 
                    lockUnlock={false} calendar={false} btnTitle={lang?.add_a_student} 
                    drop1={true}
                    drop2={true}
                    drop1Option={lessonModeOptions}
                    drop2Option={schYearOption}
                    drop1Title="Select a mode"
                    drop2Title="School Year"
                    onSelectDrop={this.onSelectDrop.bind(this)}
                    onClickAdd={this.createStudents.bind(this)}
                    rightLine={true}
                />
            </div>
          
            <div className='mt-[28px]'>
              <HeaderButtons onClick={(data, i)=>this.getResultsByType(data, i)} buttons={buttons} selected={selected} />
            </div>
            {officeStudents.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
            {
                officeStudents?.map((data, i)=>{
                return(
                    <Card4
                        key={i}
                        name={data?.full_name}
                        addCertificate={this.addCertificateBtn.bind(this, data)}
                        cert={true}
                        // email={data?.email}
                        phone={user?.role == "teacher" || user?.role == "head_teacher" || user?.role == "speaking_teacher"?'':data?.phone_number}
                        phone_number2={user?.role == "teacher" || user?.role == "head_teacher" || user?.role == "speaking_teacher"?'':data?.parent_number}
                        image={data?.avatar_full_url}
                        role={getRole(data?.role, lang)}
                        headerBtn={true}
                        onClickEdit={this.addUser.bind(this,data)}
                        lock={data?.attendance_lock_status === 0 && data?.manual_lock_status === 0 ? 1:0}
                        stat={true}
                        info={`/students/${data?.id}`}
                        course={data?.student_enroll_class.length !==0? data?.student_enroll_class[0]?.lesson_mode:null}
                        // payMode={data.payment_Reminder_max_value === '12' ? "Standart":  "Intensive"}
                        // schYear={data.school_year}
                        teacher={data.teacher.length !== 0? data?.teacher[0]?.first_name+" "+data?.teacher[0]?.last_name:null}
                        onClickLockUnlock={this.studentLockUnlock.bind(this, data)}
                        onClickStat={()=>this.setState({statModal: {position: true, student_id:data?.id}})}
                        checkExam={user?.role === "teacher" || user?.role === "head_teacher"?true:false}
                        onClickCheck={this.checkStudentExam.bind(this, data)}
                        more={user?.role == "teacher" || user?.role == "head_teacher" || user?.role == "speaking_teacher"?false: true}
                        onClickMore={this.showMoreData.bind(this, data)}
                        widthPermission={false}
                        blank={true}
                        editLink={`/students/${data?.id}/edit`}
                        schedule={`/students/${data?.id}`}
                    />
                )
                })
            }
            </div>
            {
            officeStudents?.length >=20?
            <LoadMoreBtn onLoadData={this.loadMore} count={officeStudents?.length} />:null
            }
            {/* {payments.length !== 0? <StudentPayModal closeModalBtn={()=>this.setState({payments:[]})} getAfterChange={this.getAfterChange.bind(this)} data={payments} />:null} */}
            {statModal?.position? <WeeklyStatisticsModal student_id={statModal?.student_id} closeModal={()=>this.setState({statModal: {position: false, student_id:''}})} />:null}
            {reminderModal?.position? <CheckExamReminderModal studentId={reminderModal?.studentId} closeModal={()=>this.setState({reminderModal:{position:false, studentId:''}})} />:null}
            {moreDataModal?.position? <StudentMoreModal data={moreDataModal} closeBtn={()=>this.setState({moreDataModal:{position:false}})} />:null}
            {addExResultsData?.position?  <PublicExamResultsModal />:null}
            {enrollPaymentsModal?.length !=0? <PayyStudentEnrollModal />:null}
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    officeStudents: state.Data.officeStudents,
    lessonModes: state.Data.lessonModes,
    lang: state.Data.lang,
    addExResultsData: state.Data.addExResultsData,
    enrollPaymentsModal: state.Data.enrollPaymentsModal,
});
const mapDispatchToProps = {getOfficeStudents, getLessonModes, insertNewData, getStudentPaymentsEnroll, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(CeltStudents))
