import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getTeacherSeniorRequests } from '../../actions/AdminActions';
import CardLine from '../../components/cards/CardLine';
import CardUserSvg from '../../components/svg/CardUserSvg';
import CardCheatingSvg from '../../components/svg/CardCheatingSvg';
import CardEmailSvg from '../../components/svg/CardEmailSvg';
import CardDateSvg from '../../components/svg/CardDateSvg';
import moment from 'moment';
import HeaderButtons from '../../components/HeaderButtons';
import { insertNewData, deleteData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import pdfImg from "../../images/defPdf.png"
export class AdminSeniorRequests extends Component {
    state={
        status: '0',
        selected:0
    }
    componentDidMount(){
        this.props.getTeacherSeniorRequests("0")
    }
    changeDataBtn=(data, index)=>{
        this.setState({selected:index, status:data?.id});
        this.props.getTeacherSeniorRequests(data?.id)
    }

    changeStatus=(id, type)=>{
        confirmAlert("warning",'Are you sure?', "You won't be able to revert this!",'Yes, change it!')
        .then(respAlert=>{
            if(respAlert == "success"){
                let formData = new FormData();
                formData.append("status", type);
                formData.append("_method", "put");
                this.props.insertNewData(`senior-status/${id}`, formData)
                .then(resp=>{
                    if(resp == "success"){
                       mixinAlert('success', `Your file has been ${type == '1'? 'accepted':'rejected'}`)
                       
                    }else{
                      mixinAlert("error", "Something went wrong")
                    }
                    this.props.getTeacherSeniorRequests(this.state.status)
                })
            }
        })
    }

    deleteBtn=(id)=>{
        confirmAlert("warning",'Are you sure?', "You won't be able to revert this!",'Yes, delete it!')
        .then(respAlert=>{
            if(respAlert == "success"){
                this.props.deleteData(`senior-status/${id}`)
                .then(resp=>{
                    if(resp == "success"){
                       mixinAlert('success', `Your file has been deleted.`)
                       
                    }else{
                      mixinAlert("error", "Something went wrong")
                    }
                    this.props.getTeacherSeniorRequests(this.state.status)
                })
            }
        })
    }
  render() {
    const {adminTeacherSeniorRequests} = this.props;
    const {selected} = this.state;
    const btns = [
        {
            title: "Waiting",
            id: "0"
        },
        {
            title: "Accepted",
            id: "1"
        },
        {
            title: "Rejected",
            id: "2"
        },
    ]
    // console.log(adminTeacherSeniorRequests)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Senior teacher requests" />
            <div className='mt-[30px] flex justify-between items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={(data, index)=>this.changeDataBtn(data, index)} buttons={btns} selected={selected} />
            </div>
            <div className='mt-[30px] grid-full-250'>
                {
                    adminTeacherSeniorRequests?.map((data, i)=>{
                        return(
                            <div key={i} className='bg-white shadow p-[10px] rounded-[10px]'>
                                <a href={data?.file_full_url} target='_blank'>
                                    {
                                        data?.file_type == "application/pdf"?
                                        <img src={pdfImg} className='w-full rounded-[10px] h-[150px]' />:
                                        <img src={data?.file_full_url} className='w-full rounded-[10px] h-[150px]' />
                                    }
                                   
                                </a>
                                <ul className='flex flex-col gap-[10px] mt-[10px]'>
                                    <CardLine svg={<CardUserSvg />} title={data?.teacher?.full_name} />
                                    <CardLine svg={<CardEmailSvg />} title={data?.teacher?.email} />
                                    <CardLine svg={<CardDateSvg />} title={moment(data?.created_at).format("DD/MM/YYYY")} />
                                    <CardLine svg={<CardCheatingSvg />} title={data?.teacher?.company?.name} />
                                </ul>
                                <div className='flex justify-between mt-[10px]'>
                                    {
                                        data?.status==0?
                                        <button  onClick={this.changeStatus.bind(this, data?.id, "2")} className='bg-red h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Reject</button>:null
                                    }
                                    {
                                        data?.status!=0?
                                        <button onClick={this.deleteBtn.bind(this, data?.id)} className='bg-red h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Delete</button>:null
                                    }
                                    
                                    {
                                         data?.status==0 ||  data?.status==2?
                                         <button onClick={this.changeStatus.bind(this, data?.id, "1")} className='bg-blue h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Accept</button>:null
                                    }
                                    
                                </div>
                            </div>
                        )
                    })
                }
           </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminTeacherSeniorRequests: state.Data.adminTeacherSeniorRequests
});
const mapDispatchToProps = {getTeacherSeniorRequests,insertNewData, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSeniorRequests)


