import React, { Component } from 'react'
import Header from '../../components/header/Header'
import MonthlyCalendar from '../../components/MonthlyCalendar'
import { connect } from 'react-redux'
import { getTeacherOwnSalary } from '../../actions/StaffAction'
import moment from 'moment';
import {SalaryCounting} from '../../actions/salaryCounting';
import { getLessonModes } from '../../actions/StaffAction'
import Card5 from '../../components/cards/Card5'
import EnrollCheckInModal from '../../components/modals/EnrollCheckInModal'
import HeaderButtons from '../../components/HeaderButtons'
export class TeacherOwnCounting extends Component {
    state={
        salaryCounting: new SalaryCounting(),
        checkinsModal:{
            position: false
        },
        selected:0
    }
   
    componentDidMount(){
        this.props.getTeacherOwnSalary(moment(new Date()).format("YYYY-MM"));
        this.props.getLessonModes()
        .then(resp=>{
            this.setState({salaryCounting: new SalaryCounting(this.props.user?.teacher_status, resp)})
        });
        window.scrollTo(0,0)
    }
    showCheckins=(data)=>{
        this.setState({checkinsModal:{position:true, ...data}})
    }
    onCangeMonth=(data, i)=>{
        this.setState({selected:i})
        if(data.id === 'current'){
            const date = moment(new Date()).format("YYYY-MM")
            this.props.getTeacherOwnSalary(date);
        }else{
          if(new Date().getMonth() == 0){
            const date = moment(new Date(`${new Date().getFullYear()-1}-12`)).format("YYYY-MM")
            this.props.getTeacherOwnSalary(date);
          }else{
            const date = moment(new Date(`${new Date().getFullYear()}-${new Date().getMonth()}`)).format("YYYY-MM")
            this.props.getTeacherOwnSalary(date);
          }
        }
    }
  render() {
    const {teacherOwnSalary, lang} = this.props;
    const {salaryCounting, checkinsModal, selected} = this.state;
    let result = [];
    if(teacherOwnSalary.check_ins){
       result = teacherOwnSalary.check_ins.reduce(function (r, a) {
     
        r[a.group_name] = r[a.group_name] || [];
        r[a.group_name].push(a);
        return r;
      
     
      }, {});
    }

    // console.log(teacherOwnSalary)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="t_counting" />
            <div className='flex gap-[20px] items-center white-search mt-[20px] max-[450px]:flex-col max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
            {
                new Date().getDate() < 15? 
                    <HeaderButtons onClick={(data, i)=>this.onCangeMonth(data, i)} buttons={[{title:"Current Month", id:"current"}, {title: "Last Motnh", id:'last'}]}  selected={selected} />
                :null
            }
           </div>
            <div className='flex mt-[20px]'>
                <div className='bg-white  rounded-[10px] w-[300px]'>
                    <h3 className='text-white text-center font-semobold text-[16px] bg-blue h-[35px] pt-[5px] rounded-t-[10px]'>{lang?.summary}</h3>
                    <ul>
                        <li className='flex justify-between border-b'>
                            <span className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.sub_total}</span>
                            <span className='txt-light-black p-[6px] w-1/2 font-regular text-[14px]'>{(salaryCounting.getGroupAllValue(Object.values(result))+salaryCounting.getIndividualAllValue(teacherOwnSalary.check_ins)).toFixed(2)}</span>
                        </li>
                        <li className='flex justify-between border-b'>
                            <span  className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.bonus}</span>
                            <span  className='txt-light-black p-[6px] w-1/2 font-regular text-[14px]'> {teacherOwnSalary.bonus}</span>
                        </li>
                        <li className='flex justify-between'>
                            <span  className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_this_month}</span>
                            <span  className='txt-light-black p-[6px] w-1/2 font-regular text-[14px]'>{(salaryCounting.getGroupAllValue(Object.values(result))+salaryCounting.getIndividualAllValue(teacherOwnSalary.check_ins)+teacherOwnSalary.bonus).toFixed(2)}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-[42px] items-start grid-full-320'>
                {
                     Object.values(result).map((data, i)=>{
                        if(data[0]?.group_name !== "One to One" && (!data[0]?.group_name.includes("Locked") || data[0]?.group_name.includes("Locked") && data.find(result => result.check_in.length !== 0))){
                            return(
                                <Card5
                                    key={i}
                                    group={data[0]?.group_name.replace("Locked", "")} 
                                    time={salaryCounting.getLessonHour(data[0]?.lesson_houre)} 
                                    subject={data[0]?.lesson_mode}
                                    type={data[0]?.study_mode}
                                    students={salaryCounting.getGroupStudents(data)}
                                    money={salaryCounting.getTotalAmounts(data)}
                                    onShowCheckins={this.showCheckins.bind(this)}
                                    groupLockStatus={data[0]?.group_name.includes("Locked")?true:false}
                                    course={data[0]?.course?.title}
                                />
                            )
                        }else if(data[0]?.group_name === "One to One"){
                            return(
                                data.map((item, j)=>{
                                    if(item?.status === "1" || (item?.status === "0" && item?.check_in.length !== 0)){
                                       return(
                                            <Card5 
                                                key={i+""+j}
                                                group={item?.group_name.replace("Locked", "")} 
                                                time={salaryCounting.getLessonHour(item?.lesson_houre)} 
                                                subject={item?.lesson_mode}
                                                type={item?.study_mode}
                                                students={salaryCounting.getIndividualStudents(item)}
                                                money={salaryCounting.getTotalAmounts([item])}
                                                onShowCheckins={this.showCheckins.bind(this)}
                                                groupLockStatus={item?.status ==="1"? false:true}
                                                course={item?.course?.title}
                                            />
                                        ) 
                                    }
                                    
                                })
                               
                            )
                        }
                     })
                }
                
            </div>
            {checkinsModal?.position? <EnrollCheckInModal data={checkinsModal} closeModalBtn={()=>{this.setState({checkinsModal:{position:false}})}} />:null}
       </div>
    )
  }
}
const mapStateToProps = (state)=>({
    teacherOwnSalary: state.Data.teacherOwnSalary,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getTeacherOwnSalary, getLessonModes}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherOwnCounting)
