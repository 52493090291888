import React, { Component } from 'react'

export class UserSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={21}
        fill="none"
      >
        <path
          fill="#A3AED0"
          d="M14.056 11.18a5.75 5.75 0 1 0-7.111 0 9.584 9.584 0 0 0-5.961 7.84.964.964 0 1 0 1.916.21 7.667 7.667 0 0 1 15.238 0 .958.958 0 0 0 .958.853h.106a.958.958 0 0 0 .843-1.054 9.584 9.584 0 0 0-5.99-7.849ZM10.5 10.5a3.833 3.833 0 1 1 0-7.666 3.833 3.833 0 0 1 0 7.666Z"
        />
      </svg>
    )
  }
}

export default UserSvg
