import React, { Component } from 'react'
import img from "../../images/profileBgMini.png";
import defUser from "../../images/defaultUser.jpeg"
import { connect } from 'react-redux';
import { getRole } from '../../actions/getRole';
import { getCourses, getStudentGPA } from '../../actions/MainAction';
import { getGroupsLink } from '../../actions/StaffAction';
export class Profile extends Component {
  state={
    averages:[]
  }
  componentDidMount(){
    this.props.getCourses();
    this.props.getStudentGPA()
    .then(resp=>{
      this.setState({averages:resp})
    })
    this.props.getGroupsLink()
  }
  checkMidEnd(data){
    let total = 0;
    if(data?.end_of_course?.length !=0){
      total+=15
    }
    if(data?.midterms?.length !=0){
      total+=5
    }
    return total;
  }
  getTotalValue(data){
    let total =Math.round((data?.student_completed*80)/data?.total_amount);
    if(total<=80){
      return total
    }else{
      return 80
    }
  }

  getGPA(courseId, courses){
    if(courses.length ==0){
      return false
    }else{
      const check = courses.find(x=>x.id == courseId);
      if(check){
        return check;
      }else{
        return false;
      }
    }
  }
  
  render() {
    const {user, lang, studentCoach, groupsLinks} = this.props;
    const {averages} = this.state;
    // console.log(groupsLinks)
    return (
      <div className='bg-white rounded-[20px] p-[30px]'>
        <div className='flex flex-col relative'>
            <img className='w-full h-[108px]' src={img} />
            <div className='w-[87px] h-[87px] p-[3px] bg-white rounded-full flex justify-center items-center ml-auto mr-auto mt-[-40px]'>
                <img className='w-full h-full rounded-full' src={user?.avatar_full_url==''? defUser: user?.avatar_full_url} />
            </div>
            <h3 className='text-center mt-[15px] txt-dark-blue text-[20px] font-bold'>{user?.full_name}</h3>
            <span className='inline-block ml-auto mr-auto txt-dark-gray font-medium text-[14px]'>{getRole(user?.role,lang)}</span>
        </div>
        <div className='mt-[20px] ml-[34px] mr-[34px] flex flex-col gap-[20px] max-h-[95px] overflow-auto'>
          {/* <h3 className='text-center mt-[20px] txt-blue font-semibold text-[20px]'>Coming soon!</h3> */}
         
          {
            studentCoach?.map((data, i)=>{
              return(
                <div key={i}>
                    <span className='text-[10px] txt-dark-blue'>{data?.course_title}</span>
                    <div className='bg-light-blue h-[10px] rounded-full relative'>
                        <span style={{ width: `${this.getTotalValue(data) + this.checkMidEnd(data)}%` }} className='h-[10px] inline-flex items-center justify-end pr-[4px] text-[10px] absolute bg-dark-blue rounded-full text-white pt-[1px]'>{this.getTotalValue(data) + this.checkMidEnd(data)}%</span>
                    </div>
                    {
                      this.getGPA(data?.course_id, averages)?
                      <>
                      <span className='text-[10px] txt-dark-blue'>{lang?.current_exam_gpa}</span>
                      <div className='bg-light-blue h-[10px] rounded-full relative'>
                          <span style={{ width: `${Math.round(this.getGPA(data?.course_id, averages)?.average)}%` }} className='h-[10px] inline-flex items-center justify-end pr-[4px] text-[10px] absolute bg-dark-blue rounded-full text-white pt-[1px]'>{Math.round(this.getGPA(data?.course_id, averages)?.average)}%</span>
                      </div>
                      </>:null
                    }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  studentCourses: state.Data.studentCourses,
  lang: state.Data.lang,
  groupsLinks: state.Data.groupsLinks,
});
const mapDispatchToProps = {getCourses, getStudentGPA, getGroupsLink}
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
