import React, { Component } from 'react'

export class CardPlusSvg extends Component {
  render() {
    const {color} = this.props
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={13}
        height={13}
        fill="none"
      >
        <path
          fill={color}
          d="M6.5 10.92A4.416 4.416 0 0 1 2.08 6.5 4.416 4.416 0 0 1 6.5 2.08a4.416 4.416 0 0 1 4.42 4.42 4.416 4.416 0 0 1-4.42 4.42Zm0-8.32a3.895 3.895 0 0 0-3.9 3.9c0 2.158 1.742 3.9 3.9 3.9s3.9-1.742 3.9-3.9-1.742-3.9-3.9-3.9Z"
        />
        <path fill={color} d="M4.16 6.24h4.68v.52H4.16v-.52Z" />
        <path fill={color} d="M6.24 4.16h.52v4.68h-.52V4.16Z" />
      </svg>
    )
  }
}

export default CardPlusSvg
