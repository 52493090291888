import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';

export class ExamTypeButton extends Component {
    addSingleBtn=()=>{
        let data = {
            ...this.props.poolingExamQuestionData,
            position:true,
            parent_id:this.props.params.id,
            category_id: this.props.poolingExamParentData?.category_id,
            sub_category_id: this.props.poolingExamParentData?.sub_category_id,
            question_type:'single_choice',
            answer_options:[
                {
                    id:'',
                    title:'',
                    is_correct:0,
                    question_id:'',
                    type:'single_choice',
                    deleted:false
                },
                {
                    id:'',
                    title:'',
                    is_correct:0,
                    question_id:'',
                    type:'single_choice',
                    deleted:false
                },
                {
                    id:'',
                    title:'',
                    is_correct:0,
                    question_id:'',
                    type:'single_choice',
                    deleted:false
                },
            ]
        }
        this.props.changeStateValue({
            name:'poolingExamQuestionData',
            value: data
        })
    }
    addFreeTextBtn=(type)=>{
        let data = {
            ...this.props.poolingExamQuestionData,
            position:true,
            parent_id:this.props.params.id,
            category_id: this.props.poolingExamParentData?.category_id,
            sub_category_id: this.props.poolingExamParentData?.sub_category_id,
            question_type:type,
            answer_options:[]
        }
        this.props.changeStateValue({
            name:'poolingExamQuestionData',
            value: data
        })
    }
  render() {
    const {poolingExamParentData} = this.props;
    // console.log(poolingExamParentData)
    return (
      <div className='flex items-center gap-[20px] mt-[20px]'>
            <button onClick={this.addSingleBtn.bind(this)} className='p-[10px_10px] bg-white shadow rounded-[10px] text-[15px] font-semibold'>
                Single choice
            </button>
            <button onClick={this.addFreeTextBtn.bind(this, "free_text")} className='p-[10px_10px] bg-white shadow rounded-[10px] text-[15px] font-semibold'>
                Free text
            </button>
            <button onClick={this.addFreeTextBtn.bind(this, "drop_down")} className='p-[10px_10px] bg-white shadow rounded-[10px] text-[15px] font-semibold'>
                Dropdown
            </button>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    poolingExamQuestionData: state.Data.poolingExamQuestionData,
    poolingExamParentData: state.Data.poolingExamParentData,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamTypeButton))
