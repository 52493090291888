import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getTeacherClass, getUserData } from '../../actions/StaffAction';
import Header from './Header';
import DropSearch from '../DropSearch';
import { insertNewData } from '../../actions/MainAction';
import { bigAlert, mixinAlert } from '../../actions/SweetAlerts';
import DefaulData from './components/DefaulData';
import Input from './components/Input';
import FileInput from '../FileInput';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
export class AddStudentToExistsGroupModal extends Component {
    state={
        btnDisabled:false,
        student_id:'',
        fileType:'image'
    }
   
    selectStudent=(id)=>{
        this.setState({student_id:id})
    }
    saveBtn=(data)=>{
        if(this.state.student_id !== "" || data?.exists){
            let addExistsGroup = this.props.addExistsGroup;
            if(addExistsGroup?.count =="" || addExistsGroup?.count <= 0 || (!addExistsGroup?.image && !addExistsGroup?.file)){
                mixinAlert("warning", "Please add count or upload file");
                return
            }
            if(addExistsGroup?.fee ===""){
                mixinAlert("warning", "Please fill the all required fileds");
                return;
            }
            this.setState({btnDisabled:true});
            let formData = new FormData();
            let url = 'teacherEnrolls';
            for(const property in data){
                formData.append(property, data[property]);
            }
            formData.append("count", addExistsGroup?.count);
            formData.append("fee", addExistsGroup?.fee);
            if(addExistsGroup?.note !=""){
                formData.append("note", addExistsGroup?.note);
            }
            if(addExistsGroup?.image){
                formData.append('image', addExistsGroup?.image);
            }
            if(addExistsGroup?.file){
                formData.append('file', addExistsGroup?.file);
            }
            if(!data?.exists){
                formData.append("student_id", this.state.student_id)
            }

            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisabled:false});
                this.props.closeBtn();
                if(resp === "success"){
                    bigAlert("Done!", "Student added successfully", "success");
                    this.props.resetStateValue({name:'addExistsGroup'})
                    this.props.callAfterChange()
                }else{
                    bigAlert("Error!", "Something went wrong", "error")
                }
            })
        }else{
            mixinAlert("info", "Please select a student")
        }
    }
    changeFileType=(type)=>{
        this.setState({
            fileType:""
        });
        this.props.changeStateValue({ name:"addExistsGroup.image", value:null })
        this.props.changeStateValue({ name:"addExistsGroup.image_full_url", value:null })
        this.props.changeStateValue({ name:"addExistsGroup.file", value:null })
        this.props.changeStateValue({ name:"addExistsGroup.file_full_url", value:null })
        setTimeout(()=>{
            this.setState({
                fileType:type
            });
        },100)
      }
  render() {
    const {closeBtn=()=>{}, data, officeAllStudents, lang, addExistsGroup} = this.props;
    const {btnDisabled, fileType} = this.state;
    let studentsOption = [];
    for(const student of officeAllStudents){
        if(+student.attendance_lock_status !== 1 && +student.manual_lock_status === 0 && !data.current_student.find(data => +data.id === student.id)){
            const params = {
                label: student.full_name,
                value: student.id
            }
            studentsOption.push(params)
        }
    }
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[650px] max-sm:w-[90%] p- rounded-[10px]'>
                <Header closeBtn={closeBtn} title="Add a student" />
                <div className='p-[20px]'>
                    <div className='grid grid-cols-2 gap-[20px] max-[500px]:grid-cols-1'>
                        <div>
                            <span className='text-[14px] txt-light-black'>Select a Student</span>
                        {
                            data?.exists?
                            <div className='w-[250px]'>
                                <DefaulData  value={data?.student_name} />
                            </div>:
                            <DropSearch selectData={this.selectStudent.bind(this)} options={studentsOption} placeholder="" />
                        }
                        </div>
                        <Input  required name="addExistsGroup.fee" type="number" value={addExistsGroup?.fee}  title={"Amount of payment"} />
                        { addExistsGroup?.count || addExistsGroup?.count ===""? <Input  required name="addExistsGroup.count" type="number" value={addExistsGroup?.count}  title={lang?.num_lessons} />:null}
                        { addExistsGroup?.note || addExistsGroup?.note ===""? <Input   name="addExistsGroup.note" type="text" value={addExistsGroup?.note}  title={lang?.pay_note} />:null}
                        <div className='flex items-start gap-[10px] justify-between mt-[-5px]'>
                           
                            {
                            fileType=="image"?
                            <FileInput placeholder={'Payment receipt only.'} required type="image" name="addExistsGroup.image" title="Upload Image" value={addExistsGroup?.image_ful_url} valueName="addExistsGroup.image_full_url"  />:
                            fileType == "pdf"?
                            <FileInput placeholder={'Payment receipt only.'} required  type="application/pdf" name="addExistsGroup.file" value={addExistsGroup?.file} title="Upload file" valueName="addExistsGroup.file_full_url" />:null
                            }
                             <div className='flex items-center gap-[10px] mt-[30px]'>
                                <button onClick={this.changeFileType.bind(this, 'image')} className={`text-[12px] ${fileType=="image"? ' bg-blue text-white':'border txt-blue'} rounded-[4px] font-regular h-[20px] p-[0_5px] `}>Image</button>
                                <button onClick={this.changeFileType.bind(this, 'pdf')} className={`text-[12px] ${fileType=="pdf"? ' bg-blue text-white':'border txt-blue'} rounded-[4px] font-regular h-[20px] p-[0_5px] `}>PDF</button>
                            </div>
                        </div>
                    </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={()=>{closeBtn("single")}} className='bg-red h-[25px] text-[12px] pl-[10px] pr-[10px]   rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className='bg-blue h-[25px] text-[12px] pl-[10px] pr-[10px] rounded-[5px] text-white font-semibold'>{lang?.upl_enr}</button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    officeAllStudents: state.Data.officeAllStudents,
    lang: state.Data.lang,
    addExistsGroup: state.Data.addExistsGroup,
});
const mapDispatchToProps = {insertNewData, changeStateValue, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AddStudentToExistsGroupModal)



