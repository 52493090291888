import React, { Component } from 'react'
import Select from 'react-select';
import { changeStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';
export class MultiSelect extends Component {

    onSelectData=(data)=>{
        this.props.changeStateValue({
            name: this.props.name,
            value: data
        })
    }
  render() {
    const {options=[], selected=[],title="", placeholder=""} = this.props;;
    return (
       <div>
        {title !== ""? <label className='text-[14px] txt-light-black'>{title}</label>:null}
        
        <Select
            isMulti={true}
            value={selected}
            onChange={this.onSelectData}
            options={options}
            placeholder={placeholder}
            className="rounded-2xl"
            maxMenuHeight={200}
        />
       </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MultiSelect)
