import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getOfficeBonuses } from '../../actions/StaffAction';
import moment from 'moment';
import Card1 from '../../components/cards/Card1';
import MonthlyCalendar from '../../components/MonthlyCalendar';
import { changeStateValue } from '../../redux/MainReducer';
import HeaderButtons from '../../components/HeaderButtons';
import EmptyPage from '../../components/EmptyPage';
export class CeltBonuses extends Component {
    state={
        date: moment(new Date()).format("YYYY-MM")
    }
    componentDidMount(){
        this.props.getOfficeBonuses(this.state.date);
        window.scrollTo(0,0)
    }
    matchDate(created_at){
        const date = moment(created_at).format('YYYY/MM/DD');
        const newDate = new Date(`${new Date(date).getFullYear()}/${new Date(date).getMonth()+1}/${new Date(date).getDate()+7}`);
        const now = new Date();
       return newDate >= now;
    }
    addEditData=(data)=>{
        this.props.changeStateValue({
            name: "userModalData",
            value:{
                position: true,
                id: data?.id?data?.id:"",
                title_input: data?.title? data?.title:"",
                full_name: data?.full_name? data?.full_name:"",
                bonus: data?.bonus? data?.bonus:"",
                monthly_date: data?.date? data?.date:"",
                title: data?.id?"Edit Bonus":"Add Bonus",
                unRequireds: ["id",  "date_of_birth","password", "unRequireds", "afterCallFunction", "funcParams"],
                afterCallFunction: this.props.getOfficeBonuses,
                teacher_id: this.state.date,
                funcParams: [this.state.date],
                url: 'bonuses'
            }
        })
    }
    changeDate=(date)=>{
        this.setState({date:date})
        this.props.getOfficeBonuses(date)
    }
  render() {
    const {bonuses, user, lang} = this.props;
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title="bonuses" />
        <div className='flex items-center bg-white rounded-[12px] max-[450px]:pt-[10px] max-[450px]:pr-[0px] pr-[20px] justify-between mt-[20px] max-[450px]:flex-col-reverse max-[450px]:gap-[10px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
            <MonthlyCalendar onSelect={this.changeDate.bind(this)} />
            <HeaderButtons onClick={this.addEditData.bind(this, '')} buttons={[{title: lang?.add_bonus, id:0}]} />
        </div>
        {bonuses.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, change date to show bonuses" subTitle="" /></div>:null}
        <div className='mt-[20px] grid-full-250'>
            {
                bonuses?.map((data, i)=>{
                    return(
                        <Card1
                            key={i}
                            title={data?.date}
                            name={data?.full_name}
                            close={data?.title}
                            cheating={data?.bonus}
                            date={moment(data?.created_at).format("DD/MM/YYYY")}
                            status={user?.role === "super_admin" || (user?.role === "company_head" && this.matchDate(data?.created_at))?1:0}
                            statusText={user?.role === "super_admin" || (user?.role === "company_head" && this.matchDate(data?.created_at))? 'Unlock': 'Lock'}
                            onCLickBtn={user?.role === "super_admin" || (user?.role === "company_head" && this.matchDate(data?.created_at))?this.addEditData.bind(this, data):()=>{}}
                            btn={false}
                            btn2={true}
                            btn2Text="Edit"
                            btn2Color={user?.role === "super_admin" || (user?.role === "company_head" && this.matchDate(data?.created_at))?"bg-blue":"bg-gray-500 cursor-not-allowed"}
                        />
                    )
                })
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    bonuses: state.Data.bonuses,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getOfficeBonuses, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(CeltBonuses)
