import React, { Component } from 'react'

export class ExResSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
      >
        <path
          fill="#585874"
          d="M15.188 2.813H2.812a1.125 1.125 0 0 0-1.124 1.124v11.25a.563.563 0 0 0 .814.504l1.998-1 1.998 1a.563.563 0 0 0 .504 0l1.998-1 1.998 1a.562.562 0 0 0 .504 0l1.998-1 1.998 1a.562.562 0 0 0 .815-.504V3.938a1.125 1.125 0 0 0-1.125-1.124Zm0 11.465-1.436-.719a.562.562 0 0 0-.504 0l-1.998 1-1.998-1a.563.563 0 0 0-.504 0l-1.998 1-1.998-1a.563.563 0 0 0-.504 0l-1.436.719V3.938h12.376v10.34Zm-10.94-2.525a.563.563 0 0 0 .755-.251l.407-.815h2.68l.407.815a.563.563 0 1 0 1.006-.504l-2.25-4.5a.563.563 0 0 0-1.006 0l-2.25 4.5a.563.563 0 0 0 .251.755ZM6.75 8.008l.778 1.555H5.972l.778-1.555ZM9.563 9a.563.563 0 0 1 .562-.563h1.125V7.313a.563.563 0 0 1 1.125 0v1.125H13.5a.562.562 0 1 1 0 1.124h-1.125v1.126a.562.562 0 1 1-1.125 0V9.562h-1.125A.563.563 0 0 1 9.562 9Z"
        />
      </svg>
    )
  }
}

export default ExResSvg
