import React, { Component } from 'react'

export class CardCourseSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={14}
            height={14}
            fill="none"
        >
            <path
            fill="#1E398F"
            d="M12.28 9.188 9.626 6.562l2.656-2.625.625.617-2.03 2.008 2.03 2.009-.625.617Zm-2.655 3.937H8.75v-2.188A2.187 2.187 0 0 0 6.562 8.75H3.938a2.188 2.188 0 0 0-2.187 2.188v2.187H.875v-2.188a3.063 3.063 0 0 1 3.063-3.062h2.624a3.062 3.062 0 0 1 3.063 3.063v2.187ZM5.25 1.75a2.187 2.187 0 1 1 0 4.375 2.187 2.187 0 0 1 0-4.375Zm0-.875A3.062 3.062 0 1 0 5.25 7a3.062 3.062 0 0 0 0-6.125Z"
            />
        </svg>
    )
  }
}

export default CardCourseSvg
