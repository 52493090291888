import React, { Component } from 'react'
import PlusSvg from '../../../components/svg/PlusSvg';

export class PageButtons extends Component {
   state={
    moreVisible:false,
    visibleBtnIndex:6,
    openIndex:999
   }
   getButtonsView(){
    if(window.innerWidth < 630){
        this.setState({moreVisible:true})
    }else{
        this.setState({moreVisible:false})

    }
   }
   componentDidMount(){
        window.addEventListener("click", (e)=>{
            if(typeof e.target.className === "string" && !e.target.className.includes("notClosePermission")){
                this.setState({openIndex:111})
            }
        })
        this.getButtonsView()
       
        window.addEventListener("resize", ()=>{
            this.getButtonsView()
        })
    }
  render() {
    const {buttons, selected, onClick=()=>{}} = this.props;
    const {moreVisible, visibleBtnIndex, openIndex} = this.state;
   
    return (
      <div className='flex items-center gap-[10px]'>
        {
            buttons?.map((item, i)=>{
                if(!moreVisible && item?.part !== "other" || moreVisible){
                    return(
                        <div key={i} className='relative'>
                            <button onClick={item?.id ==="default"?()=>onClick(item, i):()=>this.setState({openIndex:i})} className={`h-[44px] bg-white pl-[10px] pr-[10px] shadow rounded-[10px] notClosePermission`} key={i}>{item?.part !== "other"? item?.part: <PlusSvg />}</button>
                            {
                                openIndex === i && item?.id !=="default"?

                               <div className='background_shadow flex items-center justify-center'>
                                 <div  className='bg-[var(--body)]  z-50 p-[10px]  w-[250px] shadow mt-[10px]  rounded-[10px] flex flex-col gap-[10px] max-h-[400px] overflow-auto'>
                                    {
                                        item?.pages.map((item, i)=>{
                                           
                                            return(
                                                <button onClick={()=>onClick(item, i)} className={`bg-white pt-[6px] pb-[6px] rounded-[6px]  ${selected == item?.id? "border border-blue-700":""}`} key={i}>
                                                    {item?.title}
                                                </button>
                                            )
                                            
                                        })
                                    }
                                </div>
                               </div>:null
                            }
                           
                        </div>
                    )
                }else if(!moreVisible){
                   return(
                    item?.pages?.map((otherBtn, j)=>{
                        return(
                            <button onClick={()=>onClick(otherBtn, j)} className={`h-[44px] bg-white pl-[10px] pr-[10px] shadow rounded-[10px] notClosePermission  ${selected == otherBtn.id?"border border-[var(--blue)]":""}`} key={j}>{otherBtn?.title}</button>
                        )
                    })
                   )
                }
                
            })
        }
        
      </div>
    )
  }
}

export default PageButtons
