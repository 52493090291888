import React, { Component } from 'react'

export class ReadAssessmentSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={90}
        height={90}
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          cx={12}
          cy={12}
          r={10}
          stroke="#29e642"
          strokeWidth={1.5}
          opacity={0.5}
        />
        <path
          stroke="#29e642"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m8.5 12.5 2 2 5-5"
        />
      </svg>
    )
  }
}

export default ReadAssessmentSvg
