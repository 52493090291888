import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getTeacherStats } from '../../actions/StaffAction';
import moment from 'moment';
import StatCard from '../../components/cards/StatCard';
import MonthlyCalendar from '../../components/MonthlyCalendar';
export class TeacherStats extends Component {
    componentDidMount(){
        this.props.getTeacherStats(moment(new Date()).format("YYYY-MM"));
        window.scrollTo(0,0)
    }

    selectMonth=(date)=>{
        this.props.getTeacherStats(date);
    }
  render() {
    const {teacherStats} = this.props;
    return (
        <div className='w-full max-sm:pl-[10px] max-sm:pr-[10px] pl-[27px] pr-[27px]'>
            <Header title="teacher_stats" />
            <div className='flex mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <MonthlyCalendar
                    onSelect={this.selectMonth.bind(this)}
                />
            </div>
            <div className='mt-[42px] grid-full-320'>
                {
                    teacherStats.map((data, i)=>{
                        if(data.staff && data.staff.teacher_lock === null || data.staff.teacher_lock !== null && data.staff.teacher_lock.lock_status !== 1){
                            return(
                                <StatCard 
                                    key={i}
                                    data={data}
                                />
                            )
                        }
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    teacherStats: state.Data.teacherStats
});
const mapDispatchToProps = {getTeacherStats}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherStats)
