import React, { Component } from 'react'
import ExamCheckSvg from '../../../components/svg/ExamCheckSvg';
import CloseSvg from '../../../components/svg/CloseSvg';
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';

export class AnswerBox extends Component {

    getInpValue=(row, e)=>{
        this.props.changeStateValue({
            name:`poolingExamQuestionData.answer_options.${row}.title`,
            value: e.target.value
        })
    }
    setCorrectAnser=(row, question_type)=>{
        if(question_type == "single_choice"){
            for(let i = 0; i < this.props.poolingExamQuestionData?.answer_options?.length; i++){
                this.props.changeStateValue({
                    name:`poolingExamQuestionData.answer_options.${i}.is_correct`,
                    value: 0
                })
            }
            this.props.changeStateValue({
                name:`poolingExamQuestionData.answer_options.${row}.is_correct`,
                value: 1
            })
        }else if(question_type == "multiple_choice"){
            this.props.changeStateValue({
                name:`poolingExamQuestionData.answer_options.${row}.is_correct`,
                value: this.props.poolingExamQuestionData.answer_options[row].is_correct==1?0:1
            })
        }
       
    }

    deleteAnswer=(row, data)=>{
        let answers = [...this.props.poolingExamQuestionData?.answer_options];
        if(data?.id ===''){
            answers.splice(row, 1);
            this.props.changeStateValue({
                name:`poolingExamQuestionData.answer_options`,
                value: answers
            })
        }else{
            this.props.changeStateValue({
                name:`poolingExamQuestionData.answer_options.${row}.deleted`,
                value: true
            })
        }

    }
  render() {
    const { correct = false, row, data, question_type} = this.props;
    return (
        <div className={`border rounded-[8px] border-[2px] p-[10px] ${correct? "border-[#06991f]": "border-gray-400 "} hover:border-[#06991f] cursor-pointer`}>
            <div className='flex items-center justify-between'>
                <span onClick={this.setCorrectAnser.bind(this, row, question_type)} className={`text-[15px] ${correct? "text-[#06991f]":"txt-blue "} inline-flex items-center gap-[5px]`}>{correct? <ExamCheckSvg />:null} {correct?"Correct Answer":"Mark as correct"}</span>
                {
                    row !==0?
                    <button onClick={this.deleteAnswer.bind(this, row, data)} >
                        <CloseSvg color="var(--red)" />
                    </button>:null
                }
               
            </div>
            <div className='border-b border-b-[2px] mt-[10px]'>
                <input onChange={this.getInpValue.bind(this, row)} value={data?.title}  placeholder='Answer here' className='w-full outline-none txt-blue' />
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    poolingExamQuestionData: state.Data.poolingExamQuestionData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AnswerBox)
