import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux';
import { resetStateValue } from '../../redux/MainReducer';
import Questions from './components/Questions';
import Essay from './components/Essay';
import Review from './components/Review';
import Footer from './components/Footer';
import Speaking from './components/Speaking';
import TextEditor from '../TextEditor';
import SpeakingRecord from './components/SpeakingRecord';
import Input from './components/Input';
import DropSearch from '../DropSearch';
import EssayPlagiat from '../EssayPlagiat';
import EssayEditor from '../EssayEditor';
export class MainModal extends Component {
    closeModal=()=>{
        this.props.resetStateValue({name:'mainModal'})
    }
    componentDidMount(){
        this.props.resetStateValue({  name:'essayPlagiarism' })
    }
    getWordCount(text){
        var parser = new DOMParser();
	    var doc = parser.parseFromString(text, 'text/html');
        return doc.children[0].children[1].textContent.match(/(\w+)/g) ? doc.children[0].children[1].textContent.match(/(\w+)/g).length: 0
    }
  render() {
    const {mainModal, lang, essayPlagiarism} = this.props;
    let gradeOption = [
        {
            label: 'A*',
            value: "A*"
        },
        {
            label: 'A',
            value: "A"
        },
        {
            label: 'B',
            value: "B"
        },
        {
            label: 'C',
            value: "C"
        },
        {
            label: 'D',
            value: "D"
        },
        {
            label: 'E',
            value: "E"
        },
        {
            label: 'F',
            value: "F"
        },
    ]
    return (
      <div className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
         <div className='bg-white w-[700px]  shadow bg-white rounded-[10px] max-h-[70vh]'>
            <Header closeBtn={this.closeModal.bind(this)} title={mainModal?.title} />
            <div style={{ height:'auto', maxHeight: 'calc(70vh - 50px)' }} className='p-[20px] overflow-auto '>
                <Questions mainModal={mainModal} />
               
                {
                    mainModal?.essay && mainModal?.essay != ''?
                    <Essay  essay={mainModal?.essay} />:null
                }
                
                {
                    mainModal?.type == "speaking_write"?
                    <SpeakingRecord />:null
                }
                {
                    mainModal?.speaking && mainModal?.speaking !== ''?
                    <Speaking audioUrl={mainModal?.speaking} />:null

                }
                {
                    essayPlagiarism?.position?
                    <EssayPlagiat essayPlagiarism={essayPlagiarism} />:null
                }
                {
                    mainModal?.robot_review && mainModal?.robot_review != ''?
                    <Review role={lang?.role_teacher} name="Selty" grade={mainModal?.robot_review?.grade} review={`<pre class="break-words whitespace-break-spaces">${mainModal?.robot_review?.review}</pre>`} model_answer={mainModal?.model_answer} />:null
                }
                {
                    !mainModal?.robot_review && mainModal?.robo_waiting?
                    <div className="flex items-center mt-[20px]  border-t pt-[10px]">
                        <p style={{ display: 'flex', alignItems: 'center', fontWeight: "700", fontSize: "17px" }}>{lang?.robo_waiting}</p>
                        <div className="loaderGPT">Loading...</div>
                    </div>:null
                }
                {
                    !mainModal?.robot_review && mainModal?.speaking_robo_waiting?
                    <div className="flex items-center mt-[20px]  border-t pt-[10px]">
                        <p style={{ display: 'flex', alignItems: 'center', fontWeight: "700", fontSize: "17px" }}>{lang?.robo_speaking}</p>
                        <div className="loaderGPT">Loading...</div>
                    </div>:null
                }
                {
                    mainModal?.not_check_robo_text && mainModal?.not_check_robo_text !==""?
                    <div className="flex items-center mt-[20px]  border-t pt-[10px]">
                        <p className="text-[16px] font-semibold ">{mainModal?.not_check_robo_text}</p>
                    </div>:null
                }
                {
                    mainModal?.reviews && mainModal?.reviews != ''?
                    <Review role={mainModal?.role} name={mainModal?.teacher} grade={mainModal?.grade} review={mainModal?.reviews} />:null
                }
                {
                    mainModal?.reviews2 && mainModal?.reviews2 != ''?
                    <Review role={mainModal?.role2} name={mainModal?.teacher2} grade={mainModal?.grade2} review={mainModal?.reviews2} />:null
                }
                {
                    mainModal?.editorName  && mainModal?.editorName != ''?
                   <div className='mt-[20px]'>
                    <div className='flex justify-end'>
                        <span className='txt-light-black text-[14px]'>{lang?.word_count}: {this.getWordCount(mainModal?.answer)}</span>
                    </div>
                    {
                        mainModal?.type == "essay_write" && mainModal?.modalType == "answer"?
                        <EssayEditor name={mainModal?.editorName} value={mainModal?.answer} />:
                        <TextEditor name={mainModal?.editorName} value={mainModal?.answer} />
                    }
                     
                   </div>:null
                }
                {   mainModal?.gradeInp?
                    <div className='flex mt-[20px]'>
                        <Input type="number" title="Grade" placeholder="Grade" name="mainModal.grade_inp" value={mainModal.grade_inp} required />
                    </div>:null
                }
                {   mainModal?.gradeDrop?
                    <div className='flex mt-[20px]'>
                        <DropSearch title="Grade" options={gradeOption} placeholder="Grade" name="mainModal.grade_inp" value={mainModal.grade_inp} required />
                    </div>:null
                }
                {   mainModal?.teacherEditor?
                    <div className='mt-[20px]'>
                        <TextEditor required name={"mainModal.review_input"} value={mainModal?.review_input} />
                    </div>:null
                }
                 <Footer data={mainModal} closeBtn={this.closeModal} />
            </div>
           
         </div>
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    mainModal: state.Data.mainModal,
    lang: state.Data.lang,
    essayPlagiarism: state.Data.essayPlagiarism,
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(MainModal)
