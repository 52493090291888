import React, { Component } from 'react'
import Search from '../../../../components/Search'
import { connect } from 'react-redux'
import { getHwPartResults } from '../../../../actions/MainAction';
import {withHooks} from "../../../../actions/withHooks"
import Card1 from "../../../../components/cards/Card1"
import moment from 'moment';
import LoadMoreBtn from '../../../../components/LoadMoreBtn';
import EmptyPage from '../../../../components/EmptyPage';
export class HwResults extends Component {
    state={
        results:[],
        search:'',
    }
   
    async getData(studentId, page, keyword){
        return await this.props.getHwPartResults(studentId, page, keyword, this.props.examType)
    }

    componentDidMount(){
        this.getData(this.props.params.id, 1, '')
        .then(resp=>{
          this.setState({results: resp});
        });
        window.scrollTo(0,0)
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps?.examType !== this.props.examType){
            this.setState({search:'', results:[]})
            this.getData(this.props.params.id, 1, '')
            .then(resp=>{
                this.setState({results: resp});
            })
        }
        
    }
    showResultBtn=(data)=>{

    }
    loadMore=(page)=>{
        this.getData(this.props.params.id, page, this.state.search)
        .then(resp=>{
            let oldData= page ===1? []:[...this.state.results]
          this.setState({results:[
            ...oldData,
            ...resp
          ]})
        })
    }
    onSearchData=(search)=>{
        this.setState({search: search})
        this.getData(this.props.params.id, 1, search)
        .then(resp=>{
            this.setState({results:[
            ...resp
            ]})
        })
    }
  render() {
    const {results} = this.state;
    const {lang} = this.props;
   
    return (
        <div className='w-full '>
        <div className='flex justify-center '>
            <Search largWidth={true} onSearchData={this.onSearchData} />
         </div>
         {results.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
        <div className='w-full grid-full-250 mt-[20px]'>
            {
                results?.map((data, i)=>{
                return(
                    <Card1 
                        key={i}
                        title={data?.exam?.course?.title}
                        exam={data?.exam_title}
                        grade={data?.score}
                        date={moment(data?.updated_at).format("HH:mm, DD/MM/YY")}
                        status={data?.status}
                        statusText={data?.status==1? lang?.pass: lang?.fail}
                        // onCLickBtn={this.showResultBtn.bind(this, data)}
                        btn={false}
                        url={`/hw-review/${data?.id}`}
                        // time={moment(data?.updated_at).format("HH:mm")}
                        course={data?.exam?.course?.title}
                    />
                    )
                })
            }
        </div>
            {
            results?.length >=20?
            <LoadMoreBtn onLoadData={this.loadMore} count={results?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {getHwPartResults}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(HwResults))
