import React, { Component } from 'react'
import { getExamKeys } from '../../actions/StaffAction';
import { connect } from 'react-redux';
import Card4 from '../../components/cards/Card4';
import { getRole } from '../../actions/getRole';
import Header from '../../components/header/Header';
import { insertNewData } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/SweetAlerts';

export class SupportExamKeys extends Component {
    componentDidMount(){
        this.props.getExamKeys()
    }
    onGenerateBtn=(data)=>{
        this.props.insertNewData(`exam-new-code/${data?.id}`,{})
        .then(resp=>{
            if(resp == "success"){
                mixinAlert("success", "Exam key regenereted.");
                this.props.getExamKeys()
            }else{
                mixinAlert("error", "Something went wrong")
            }
        })
    }
    // exam-auth-key
  render() {
    const {examKeys} = this.props;
    // console.log(examKeys)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
        <Header  title="Exam Keys" />
        <div className='flex justify-start mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
           
        </div>
        <div className='mt-[20px] grid grid-full-250'>
            {
                examKeys?.map((data, i)=>{
                    return(
                        <Card4
                            key={i}
                            name={data?.user?.full_name}
                            email={data?.user?.email}
                            phone={data?.user?.phone_number}
                            image={data?.user?.avatar_full_url}
                            role={getRole(data?.user?.role, this.props.lang)}
                          
                            course={data?.course?.title}
                            time={data?.time}
                            headerBtn={true}
                            lockUnlock={false}
                            info={`/students/${data?.user?.id}?select_course`}
                            company={data?.user?.company?.name}
                            lesson={data?.exam?.title?data?.exam?.title:" "}
                            examKey={data?.auth_code}
                            onGenerate={this.onGenerateBtn.bind(this, data)}
                    />
                    )
                })
            }
        </div>
  </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examKeys: state.Data.examKeys
});
const mapDispatchToProps = {getExamKeys, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(SupportExamKeys)
