import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import img from "../../images/headerimg.png"
// import img from "../../images/headerImg2.png"
import img from "../../images/headerImg3.png"
import { connect } from 'react-redux'
export class HeaderSection extends Component {
  render() {
    const {publicUser} = this.props;
    return (
      <section className='flex justify-center items-center header_section pt-[40px] max-[1279px]:pt-[100px] max-[767px]:pt-[150px]'>
       <div className='container flex justify-between items-center'>
            <div className=' max-w-[693px] max-[767px]:flex max-[767px]:flex-col max-[767px]:items-center '>
                <h1 className='text-[64px] font-bold max-[1023px]:text-[38px] max-[767px]:text-center'>Your Personalized  <span className='txt-blue'> Pathway</span>  to  <span className='txt-blue'> Fluency</span> 
                </h1>
                <p className='font-medium text-[20px] mt-[23px] text-[#8A8A8A] max-w-[586px] max-[1023px]:text-[18px] max-[767px]:text-center'>CELT combines cutting-edge technology with personalized teacher sessions, ensuring a successful and efficient English learning experience.</p>
                <h3 className='mt-[20px] font-semibold text-[24px] txt-blue max-[350px]:text-center max-[1023px]:text-[20px]'>Find Out Your English Level</h3>
                {
                  publicUser !==""?
                  <Link className='bg-blue text-white font-semibold text-[14px] rounded-[100px] p-[8px]  pl-[14px] pr-[14px] mt-[20px] inline-block ' to="/level-check">Click Here to Check</Link>:
                  <Link className='bg-blue text-white font-semibold text-[14px] rounded-[100px] p-[8px]  pl-[14px] pr-[14px] mt-[20px] inline-block ' to="/register">Click Here to Check</Link>
                }
                
            </div>
            <div className='max-[767px]:hidden'>
                <img className='w-[400px]' src={img} />
            </div>
       </div>
      </section>
    )
  }
}
const mapStateToProps = (state) =>({
  publicUser: state.Data.publicUser
})
export default connect(mapStateToProps)(HeaderSection)
