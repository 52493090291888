import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux'
import { insertNewData } from '../../actions/MainAction'
import { mixinAlert } from '../../actions/SweetAlerts'
export class PaymentModal extends Component {
    state={
        btnDisabled:false
    }
    saveBtn=(data)=>{
        this.setState({btnDisabled:true})
        this.props.insertNewData("single-Payment", data)
        .then(resp=>{
            this.setState({btnDisabled:false})
            mixinAlert("success","Check in paid successfully");
            this.props.closeBtn();
            this.props.getAfterChange();
        })
    }
  render() {
    const {closeBtn=()=>{}, data, lang} = this.props;
    const {btnDisabled} = this.state;
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[500px] max-sm:w-[90%] p- rounded-[10px]'>
                <Header closeBtn={closeBtn} title="Check-Ins" />
                <div className='p-[20px]'>
                    <p className='text-center font-bold text-[18px] mt-[10px]'>{lang?.payment_popup_text}</p>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={()=>{closeBtn()}} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.no}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.yes}</button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal)
