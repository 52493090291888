import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCompanyTeachersContracts } from '../../actions/AdminActions';
import { withHooks } from '../../actions/withHooks';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import moment from 'moment';
import { getRole } from '../../actions/getRole';
import ContractPdfSvg from '../../components/svg/ContractPdfSvg';
export class AccountantContracts extends Component {
    state={
        teachers:[],
        date:moment(new Date()).subtract(1, 'months').startOf('month').format("YYYY-MM")
    }
    componentDidMount(){
        this.props.getCompanyTeachersContracts(this.props.params.id, moment(new Date()).subtract(1, 'months').startOf('month').format("YYYY-MM"))
        .then(resp=>{
            this.setState({teachers:resp})
        })
    }

    changeDate=(e)=>{
        this.props.getCompanyTeachersContracts(this.props.params.id, e.target.value)
        .then(resp=>{
            this.setState({teachers:resp})
        });
        this.setState({date: e.target.value})
    }
  render() {
    const {teachers, date} = this.state;
    const {lang} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={this.props.params.company_name?.replaceAll("%20", " ")} />
            <div className='flex  gap-[20px] mt-[10px]'>
                <Link  to={`/accountant-teachers-contracts/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center  bg-blue text-white text-[22px] rounded-[6px]'>{lang?.salary_docs}</Link>
                <Link  to={`/accountant-payments/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center txt-blue bg-white shadow text-[22px] rounded-[6px]'>{lang?.pays_acc}</Link>
                <Link  to={`/accountant-teachers/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center txt-blue bg-white shadow text-[22px] rounded-[6px]'>{lang?.t_count_acc}</Link>
                <Link  to={`/accountant-celtgo/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center   txt-blue bg-white shadow text-[22px] rounded-[6px]'>{lang?.celtgo_acc}</Link>
            </div>
            <div className='mt-[20px]'>
                <input onChange={this.changeDate.bind(this)} type='month' value={date} />
            </div>
            <div className='grid-full-250 mt-[42px] items-start'>
                {
                    teachers.map((data, i)=>{
                        return(
                            <div key={i} className={`bg-white rounded-[12px] flex flex-col  justify-between p-[20px] shadow shadow-lg border border-gray-300`}>
                                <ul className='flex flex-col gap-[10px]'>
                                    <li>
                                        <h3>{data?.full_name}</h3>
                                        <span className='text-[12px]'>Role: {getRole(data?.role)}</span>
                                    </li>
                                </ul>
                            <div className='flex justify-start flex-wrap mt-[10px] gap-[10px]'>
                                 {
                                    data?.teacher_contracts?.map((item, j)=>{
                                        if(item?.image_full_url){
                                            return(
                                                <a href={item?.image_full_url} target='_blank'>
                                                    <img src={item?.image_full_url} className='w-[40px] h-[40px] rounded-[5px]' />
                                                </a>
                                            )
                                        }else if(item?.file_full_url){
                                            return(
                                                <a href={item?.file_full_url} download target='_blank'>
                                                   <ContractPdfSvg />
                                                </a>
                                            )
                                        }
                                    })
                                 }
                            </div>
                            </div>
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {getCompanyTeachersContracts}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AccountantContracts))


