import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import { connect } from 'react-redux'
import { getStudentAssesments } from '../../actions/StaffAction'
import ReviewCard from '../../components/cards/ReviewCard'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import EmptyPage from '../../components/EmptyPage'
export class TeacherReviews extends Component {
    state={
        assesments:[],
        studentId:''
    }

    loadMore=(page)=>{
        let oldData= page ===1? []:[...this.state.assesments]
        this.props.getStudentAssesments(page, this.state.studentId)
        .then(resp=>{
            this.setState({assesments: [
                ...oldData,
                ...resp
            ]})
        })
    }
    onSelectStudent=(data)=>{
        this.setState({studentId: data})
        this.props.getStudentAssesments(1, data)
        .then(resp=>{
            this.setState({assesments: resp})
        })
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }
  render() {
    const {assesments} = this.state
    // console.log(assesments)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="teacher_reviews" />
            <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <Search onSelectStudent={this.onSelectStudent} student={true} searchVisible={false}/>
            </div>
            {assesments?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, select a student" subTitle="" /></div>:null}
            <div className='w-full flex flex-col gap-[20px] mt-[20px]'>
            {
                assesments?.map((data, i)=>{
                    return(
                        <ReviewCard
                            key={i}
                            data={data}
                            btn={false}
                        />
                    )
                })
            }
            </div>
            {
                assesments?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={assesments?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state)=>({

});
const mapDispatchToProps = {getStudentAssesments}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherReviews)
