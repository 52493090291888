import React, { Component } from 'react'
import ScheduleCalendar from './components/ScheduleCalendar'
import ScheduleTimeList from './components/ScheduleTimeList'
import { getExamSchedule, getExamScheduleDays } from '../../../../actions/StaffAction';
import { connect } from 'react-redux';
import { withHooks } from '../../../../actions/withHooks';
import moment from 'moment';
import ScheduleCourseModal from '../../../../components/modals/ScheduleCourseModal';
import Card1 from '../../../../components/cards/Card1';
import CardDateSvg from '../../../../components/svg/CardDateSvg';
import CardCourseSvg from '../../../../components/svg/CardCourseSvg';
import CardDeleteSvg from '../../../../components/svg/CardDeleteSvg';
import CardEditSvg from '../../../../components/svg/CardEditSvg';
import { deleteData } from '../../../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../../../actions/SweetAlerts';
import Card4 from '../../../../components/cards/Card4';
import { getRole } from '../../../../actions/getRole';
import { changeStateValue } from '../../../../redux/MainReducer';

export class ExamSchedule extends Component {
  state={
    date: moment(new Date()).format("YYYY-MM-DD")
  }
  componentDidMount(){
    this.props.getExamSchedule(this.state.date, this.props.params.id)
    this.props.getExamScheduleDays(this.state.date, this.props.params.id)
  }

  onClickDelete=(id)=>{
    confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
    .then(alertResp=>{
        if(alertResp === "success"){
        this.props.deleteData(`exam-schedule/${id}`)
        .then(resp=>{
            if(resp == "success"){
              this.props.getExamSchedule(this.state.date, this.props.params.id)
                mixinAlert("success", "Data deleted successfully")
            }else{
                mixinAlert("error", "Something went wrong")
            }
        })
        }
    })
  }
  addPdfBtn=(data)=>{
    this.props.changeStateValue({
      name:"randevuPdfModal",
      value:{
          position:true,
          name:data?.user?.full_name,
          center:data?.user?.company?.name,
          date:moment(data?.date).format("DD MMMM, YYYY"),
          time:data?.time
      }
    })
  }
  render() {
    const {examSchedule, selectSchedule, lang, studentData} = this.props;
    // console.log(examSchedule)
    return (
      <div className='w-full'>
         <button className='bg-blue text-white text-[12px] p-[0_5px] font-[500] h-[25px] rounded-[5px] mb-[20px]' onClick={()=>this.props.changeStateValue({name:"examSchedule", value:[]})}>{lang?.add_ex_sch}</button>
        <div className={`w-full ${ examSchedule.length !=0?"":"bg-white"} items-start p-[20px] rounded-[10px] min-h-screen  flex gap-[30px]`}>
       {
        examSchedule.length !=0?
         <div className='grid grid-full-320 w-full'>
         {
           examSchedule?.map((data, i)=>{
             return(
               <Card4
                  key={i}
                  name={data?.user?.full_name}
                  phone={data?.user?.phone_number}
                  image={data?.user?.avatar_full_url}
                  role={getRole(data?.user?.role, lang)}
                  course={data?.course?.title}
                  date={moment(data?.date)?.format("DD MMMM")+", "+data?.time}
                  tel={"+99450 415 04 02"}
                  location={"Location"}
                  editBtnPermisiion={false}
                  pdf={true}
                  addPdfBtn={this.addPdfBtn.bind(this, data)}
                  deleteUserBtn={false}
                  lockUnlock={false}
                  onClickDelete={this.onClickDelete.bind(this, data?.id)}
                  locationPath="https://maps.app.goo.gl/qELXzJBnJqHBfquB8"
                  lesson={data?.exams?.title?data?.exams?.title:" "}
              />
             )
           })
         }
                     
         </div>:
         <>
        <div className='flex w-full max-[570px]:flex-col max-[570px]:justify-center '>
        <ScheduleCalendar />
         {
           selectSchedule?.date !=""?
           <ScheduleTimeList />:null
         }
        </div>
         {
           selectSchedule?.modalPosition?
           <ScheduleCourseModal studentData={studentData} />:null
         }
         </>
       }
     </div>
      </div>
     
    )
  }
}
const mapStateToProps = (state) =>({
  examSchedule: state.Data.examSchedule,
  selectSchedule: state.Data.selectSchedule,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getExamSchedule, deleteData, changeStateValue, getExamScheduleDays}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamSchedule))
