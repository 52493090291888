import React, { Component } from 'react'
import Header from '../../../../components/modals/Header'
import { changeStateValue, resetStateValue } from '../../../../redux/MainReducer';
import { connect } from 'react-redux';
import { getExamCategories, getSingleData } from '../../../../actions/StaffAction';
import DropSearch from '../../../../components/DropSearch';
import Input from '../../../../components/modals/components/Input';
import { getDropValue } from '../../../../actions/getRole';
import { mixinAlert } from '../../../../actions/SweetAlerts';
import { insertNewData } from '../../../../actions/MainAction';
import { getPoolingExams } from '../../../../actions/AdminActions';

export class AddDynamicSetting extends Component {
    state={
        btnDisabled:false,
        subCategories:[],
        sections:[],
    }
    componentDidMount(){
        this.props.getExamCategories();
        if(this.props.dynamicExamQuestionModal?.category_id && this.props.dynamicExamQuestionModal?.category_id !=""){
            this.onSelectCategory(this.props.dynamicExamQuestionModal?.category_id)
        }
        if(this.props.dynamicExamQuestionModal?.sub_category_id && this.props.dynamicExamQuestionModal?.sub_category_id !=""){
            this.onSelectSubCategory(this.props.dynamicExamQuestionModal?.sub_category_id)
        }
    }
    onSelectCategory=(id)=>{
        this.props.getSingleData(`exam-sub-by-categories/${id}`)
        .then(resp=>{
            this.setState({subCategories: resp})
        })
    }
    onSelectSubCategory=(id)=>{
        this.props.getSingleData(`exam-sections-by-subcategory/${id}`)
        .then(resp=>{
            this.setState({sections: resp})
        })
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"dynamicExamQuestionModal"})
    }
    saveBtn=(data)=>{
        if(data?.category_id !="" && data?.sub_category_id !="" && data?.section_id != '' && data?.count !="" && data?.count !=0){
            this.setState({btnDisabled:true})
            let formData = new FormData();
            for(const property in data){
                if(data[property]){
                    formData.append(property, data[property])
                }
            }
            let url = "dynamic-exam-settings";
            if(data?.id && data?.id !=""){
                url = `dynamic-exam-settings/${data?.id}`;
                formData.append("_method", "put")
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                if(resp=="success"){
                    this.setState({btnDisabled:true});
                    mixinAlert("success", "Data added successfully");
                    data?.afterCallFunction(...data?.afterCallParams)
                    this.closeModal();
                }else{
                    mixinAlert("error", "Something wemt wrong")
                }
            })
        }else{
            mixinAlert("warning", "Please, fill the al fields")
        }
    }
  render() {
    const {dynamicExamQuestionModal, examCategories} = this.props;
    const {btnDisabled, subCategories, sections,} = this.state;
    let sectionOptions = [];
    for(const data of sections){
        sectionOptions.push({
            label: `${data?.title} (${data?.questions_count})`,
            value: data?.id
        })
    }
    // console.log(sections)
    return (
        <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white w-[900px]  shadow bg-white rounded-[10px] '>
            <Header closeBtn={this.closeModal.bind(this)} title={dynamicExamQuestionModal?.id ===""?"Add Question":"Edit Question"} />
            <div className='p-[10px] rounded-b-[10px] bg-white max-h-[70vh] max-[500px]:overflow-auto'>
                <div className='grid grid-cols-2 max-[700px]:grid-cols-1 gap-[20px]  mt-[10px]'>
                    <DropSearch
                        options={getDropValue(examCategories, "title", "id")}
                        name={`dynamicExamQuestionModal.category_id`}
                        value={dynamicExamQuestionModal.category_id}
                        title={"Select a category"}
                        placeholder={"Select a category"}
                        selectData={this.onSelectCategory.bind(this)}
                    />
                    <DropSearch 
                        options={getDropValue(subCategories, "title", "id")}
                        name={`dynamicExamQuestionModal.sub_category_id`}
                        value={dynamicExamQuestionModal.sub_category_id}
                        title={"Select a sub category"}
                        placeholder={"Select a sub category"}
                        selectData={this.onSelectSubCategory.bind(this)}
                    />
                    <DropSearch 
                        options={sectionOptions}
                        name={`dynamicExamQuestionModal.section_id`}
                        value={dynamicExamQuestionModal.section_id}
                        title={"Select a section"}
                        placeholder={"Select a section"}
                    />
                   
                </div>
                <div className='flex justify-between items-center mt-[20px]'>
                    <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Close</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,dynamicExamQuestionModal)} className='bg-orange pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Save</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    dynamicExamQuestionModal: state.Data.dynamicExamQuestionModal,
    examCategories: state.Data.examCategories,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, getExamCategories, getSingleData, insertNewData, getPoolingExams}
export default connect(mapStateToProps, mapDispatchToProps)(AddDynamicSetting)


