import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getImages } from '../../actions/AdminActions';
import Search from '../../components/Search';
import HeaderButtons from '../../components/HeaderButtons';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Card8 from '../../components/cards/Card8';
import { changeStateValue } from '../../redux/MainReducer';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
export class AdminImages extends Component {
    state={
        page:1,
        search:''
    }
    componentDidMount(){
        this.props.getImages(1, '')
    }
    openModal=(data="")=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                title:data?.title? data?.title:'',
                image_url:data?.image_url? data?.image_url:'',
                image_full_url:data?.image_full_url? data?.image_full_url:'',
           
                afterCallFunction:this.props.getImages,
                afterCallParams:[this.state.page, this.state.search],
                requireds:["title", "image_url"],
                url: data?.id? `upload-image/${data?.id}`:'upload-image',
                modalTitle: data?.id? `Edit Images`:'Add Images',
            }
        });
    }

    searchData=(search)=>{
        this.setState({search});
        this.props.getImages(this.state.page, search)
    }

    loadMore=(page)=>{
        this.setState({page});
        this.props.getImages(page, this.state.search)
    }
    onDelete=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`upload-image/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getImages(this.state.page, this.state.search)
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {adminImages} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Images" />
            <div className='mt-[30px] flex justify-between items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <Search onSearchData={this.searchData.bind(this)} />
                <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Image", id:0}]} selected={0} />
            </div>
           <div className='mt-[30px] grid-full-250'>
                {
                    adminImages?.map((data, i)=>{
                        return(
                            <Card8 
                                key={i}
                                data={data}
                                onDelete={this.onDelete.bind(this, data)}
                            />
                        )
                    })
                }
           </div>

           {
                adminImages?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={adminImages?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminImages: state.Data.adminImages
});
const mapDispatchToProps = {getImages, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminImages)
