import React, { Component } from 'react'
import ExamCheckSvg from '../../../../components/svg/ExamCheckSvg'
import CloseSvg from '../../../../components/svg/CloseSvg'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
import { mixinAlert } from '../../../../actions/SweetAlerts';
export class AnswerBox extends Component {
  getInpValue(row, e){
   
    let answer={};
    for(const property in this.props.adminExamModalData?.question_option[row]){
      answer[property] = this.props.adminExamModalData?.question_option[row][property]
    }
    let allAnswer = [];
    for(const item of this.props.adminExamModalData?.question_option){
      let x= {}
      for(const property in item){
        x[property] = item[property]
      }
      allAnswer.push(x)
    }
    answer.title = e.target.value;
    allAnswer[row]=answer;
    this.props.changeStateValue({
        name: 'adminExamModalData.question_option',
        value: allAnswer
    })
}
checkCorrectBtn(row,data, e){
    let answer={};
    for(const property in this.props.adminExamModalData?.question_option[row]){
      answer[property] = this.props.adminExamModalData?.question_option[row][property]
    }
  
    let allAnswer = [];
    for(const item of this.props.adminExamModalData?.question_option){
      let x= {}
      for(const property in item){
        x[property] = item[property]
      }
      allAnswer.push(x)
    }
    if(data.type != 'multiple_choice'){
        for(const data of allAnswer){
            if(data){
                data.check =false;
                data.points = 0;
            }
        }
    }
    if(data.type == 'multiple_choice'){
        const checked = allAnswer.filter(ans=> ans.check == true);
        if(checked.length >=2 && !answer.check){
            mixinAlert('error', "Only 2 answer")
            return;
        }
        if(answer.check){
            answer.points = 0
        }
    }
  
   
    answer.check = !answer.check;
    allAnswer[row]=answer;
    
    this.props.changeStateValue({
        name: 'adminExamModalData.question_option',
        value: allAnswer
    })
}
removeAnswer(row){
  let allAnswer = [];
  for(const item of this.props.adminExamModalData?.question_option){
    let x= {}
    for(const property in item){
      x[property] = item[property]
    }
    allAnswer.push(x)
  }
  allAnswer.splice(row, 1);

  this.props.changeStateValue({
      name: 'adminExamModalData.question_option',
      value: allAnswer
  })

}
  render() {
    const { correct = false, row, data} = this.props;
    return (
      <div className={`border rounded-[8px] border-[2px] p-[10px] ${correct? "border-[#06991f]": "border-gray-400 "} hover:border-[#06991f] cursor-pointer`}>
        {
          data.type != 'dropdown_question_type'?
          <div className='flex items-center justify-between'>
            <span onClick={this.checkCorrectBtn.bind(this, row,data)} className={`text-[15px] ${correct? "text-[#06991f]":"txt-blue "} inline-flex items-center gap-[5px]`}>{correct? <ExamCheckSvg />:null} {correct?"Correct Answer":"Mark as correct"}</span>
            {
               data.type !== 'single_boolean_type' && row != 0?
               <button  onClick={this.removeAnswer.bind(this, row)}>
                  <CloseSvg color="var(--red)" />
              </button>:null
            }
           
        </div>:<span className={`text-[15px] txt-blue  inline-flex items-center gap-[5px]`}>Exampel: [a,b,c]</span>
        }
        
        <div className='border-b border-b-[2px] mt-[10px]'>
            <input value={data.title} onChange={data.type == 'single_boolean_type'?()=>{}: this.getInpValue.bind(this, row)} placeholder='Answer here' className='w-full outline-none txt-blue' />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  adminExamModalData: state.Data.adminExamModalData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AnswerBox)
