import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./css/style.css";
import "./css/responsive.css";
import { Provider } from 'react-redux'; 
import { setupStore } from './redux/store'; 
import { BrowserRouter } from 'react-router-dom';
const store = setupStore()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}> 
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
