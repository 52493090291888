import React, { Component } from 'react'
import LeftSvg from '../../../../../components/svg/LeftSvg'
import RightSvg from '../../../../../components/svg/RightSvg'
import { connect } from 'react-redux';
import { renderCalendar } from '../../../../../actions/calendar';
import moment from 'moment';
import { getExamScheduleDays } from '../../../../../actions/StaffAction';
import { withHooks } from '../../../../../actions/withHooks';
import { changeStateValue } from '../../../../../redux/MainReducer';

export class ScheduleCalendar extends Component {
    state={
        days:[],
        date: new Date()
    }
    componentDidMount(){
        this.setState({days: renderCalendar()})
    }

    checkExistsDay=(bookingDates, day)=>{
        const check = bookingDates.find(x=>x.day == day);
        if(check){
            return true;
        }else{
            return false
        }
    }

    prevMonth=(date)=>{
        let month = date.getMonth() - 1;
        date.setMonth(month);
        this.setState({days: renderCalendar(date)})
        this.props.getExamScheduleDays(moment(date).format("YYYY-MM-DD"), this.props.params.id)
    }
    nextMonth=(date)=>{
        let month = date.getMonth() + 1;
        date.setMonth(month);
        this.setState({days: renderCalendar(date)})
        this.props.getExamScheduleDays(moment(date).format("YYYY-MM-DD"), this.props.params.id)
    }

    selectDate=(date, examScheduleDays)=>{
        const times = examScheduleDays.find(x=>x.day == date)?.times;
        if(times.length !=0){
            this.props.changeStateValue({
                name:"selectSchedule.date",
                value:date
            })
            this.props.changeStateValue({
                name:"selectSchedule.time",
                value:""
            })
            this.props.changeStateValue({
                name:"selectSchedule.times",
                value:times
            })
        }
        
    }
  render() {
   const {days, date} = this.state;
   const {examScheduleDays} = this.props;
//    console.log(examScheduleDays)
    return (
      <div className='flex flex-col gap-[20px] w-full '>
        <div className='flex items-center gap-[10px] w-full justify-center'>
            <button onClick={moment(new Date()).format("YYYY-MM-DD") != moment(date).format("YYYY-MM-DD")? this.prevMonth.bind(this, date):()=>{}}>
                <LeftSvg color="#000" />
            </button>
            <span className='text-[14px]'>{moment(date).format("MMMM YYYY")}</span>
            <button onClick={this.nextMonth.bind(this, date)}>
                <RightSvg color="#000" />
            </button>
        </div>
        <div className='grid grid-cols-7 mt-[10px]'>
            <span className='text-[13px] text-center'>SUN</span>
            <span className='text-[13px] text-center'>MON</span>
            <span className='text-[13px] text-center'>TUE</span>
            <span className='text-[13px] text-center'>WED</span>
            <span className='text-[13px] text-center'>THU</span>
            <span className='text-[13px] text-center'>FRI</span>
            <span className='text-[13px] text-center'>SAT</span>
        </div>
        <ul className='grid grid-cols-7 gap-[10px]'>
            {
                days.map((day, i)=>{
                    return(
                        <li key={i} className='flex items-center justify-center'>
                            <button onClick={this.checkExistsDay(examScheduleDays, day?.date)?this.selectDate.bind(this, day?.date, examScheduleDays):()=>{}} className={`${this.checkExistsDay(examScheduleDays, day?.date)? 'bg-[#F2F6FF] text-[#0060E6] cursor-pointer':"text-gray-500 cursor-default"}  w-[45px] h-[45px] rounded-full  text-[16px] `}>
                                {day?.day}
                            </button>
                        </li>
                    )
                })
            }
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    examScheduleDays: state.Data.examScheduleDays
});
const mapDispatchToProps = {getExamScheduleDays, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ScheduleCalendar))
