import { CELTGO_API, MAIN_API, MAIN_APIV2, PUBLIC_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";
import axios from "axios";
import { bigAlert } from "./SweetAlerts";
import Swal from "sweetalert2";
import { getErrors } from "./MainAction";
const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("vipPublicToken")}`,
        "Accept": "application/json",
        // "Content-Type": "application/json"
    }
}
export const loginToPublicStaff = (params)=>async dispatch => {
    return await axios.post(`${PUBLIC_API}/login-vip-staff`, params)
    .then(resp=>{
      localStorage.setItem('celtPublicToken', resp.data?.token)
    }).catch(err=>{
    })
  }
const registerCheck=(params)=>async dispatch =>{
    return new Promise((res, rej)=>{
        axios.post(`${PUBLIC_API}/register-public-user`, params)
        .then(resp=>{
            dispatch(changeStateValue({name:"loader", value:false}))
            res("success")
        }).catch(err=>{
            dispatch(changeStateValue({name:"loader", value:false}))
            res("error");
        })
    })
}
export const registerPubUser=(params)=> async dispatch => {
   
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.post(`${MAIN_API}/check-public-user`, params)
    .then(resp=>{ 
        if(resp?.data =="exists"){
            dispatch(changeStateValue({name:"loader", value:false}));  
            return "error"; 
        }else{
            return new Promise((res, rej)=>{
                return dispatch(registerCheck(params))
                .then(resp=>{
                   res(resp)
                })
            })
        } 
    }).catch(err=>{ 
        dispatch(changeStateValue({name:"loader", value:false})); 
        return "error";  
    })

   
    
}

export const logOutPublic = ()=> async dispatch => {
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.post(`${PUBLIC_API}/logOut`,{}, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        localStorage.removeItem("vipPublicToken");
        dispatch(changeStateValue({name:'publicUser', value: ''}))
        return "success";
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        localStorage.removeItem("vipPublicToken");
        dispatch(changeStateValue({name:'publicUser', value: ''}))
        return "error"
    })
}
export const confirmCode=(data)=>async dispatch => {
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.post(`${PUBLIC_API}/check-confirm-code`, data)
    .then(resp=>{
        localStorage.setItem("vipPublicToken", resp?.data?.token);
        dispatch(changeStateValue({name:'publicUser', value: resp?.data?.user}))
        dispatch(changeStateValue({name:"loader", value:false}))
        return {msg: "success"}
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return {msg: err.response?.data?.msg, data:''}
    })
}
export const forgotPsw=(data)=>async dispatch => {
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.post(`${PUBLIC_API}/forgot-password`, data)
    .then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return "error"
    })
}
export const forgotPswVip=(data)=>async dispatch => {
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.post(`${MAIN_API}/passwordReset`, data)
    .then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return "success"
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:"loader", value:false}))
        return "error"
    })
}


export const checkPublicUser=()=>async dispatch => {
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${PUBLIC_API}/public-user`, {
        headers: apiHeaders()
    })
    .then(resp=>{
        dispatch(changeStateValue({name:'publicUser', value: resp?.data}))
        return "success"
    }).catch(err=>{
        localStorage.removeItem("vipPublicToken");
        dispatch(changeStateValue({name:'publicUser', value: ''}))
        dispatch(changeStateValue({name:"loader", value:false}))
        return "errorr"
    })
}
export const getAccStudent=(page=1, companyId="", countryId="", degreeId="", disciplineId="", sch="0")=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${CELTGO_API}/accepted-students`,{
        params: {page, lang_id:1,companyId, countryId, degreeId, disciplineId, sch}
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        dispatch(changeStateValue({name:'acceptedStudents', value: resp?.data}));
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}
export const getExamResults=(page, type, exam_type_id, companyId="")=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${PUBLIC_API}/public-company-student-exam`,{
        params:{page:page, type:type, exam_type_id:exam_type_id, companyId:companyId},
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
      return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return []
    })
}
export const getCompanies=()=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${PUBLIC_API}/public-companies`)
    .then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
      return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return []
    })
}
export const getSingleCompany=(id)=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${PUBLIC_API}/public-companies/${id}`)
    .then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return []
    })
}
export const getLevelCheckExam=()=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${PUBLIC_API}/exam-unlock`,{
        headers: apiHeaders()
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"levelCheckExam", value:resp?.data}))
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return []
    })
}
export const getExamsQuestions=(type, level)=>async dispatch=>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${PUBLIC_API}/get-exam-questions`,{
        params:{type, level},
        headers: apiHeaders()
    })
    .then(resp=>{
        dispatch(changeStateValue({name:"examQuestions", value:resp?.data}))
        return resp?.data;
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return []
    })
}


export const insertNewData = (url, formData) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${PUBLIC_API}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}


export const getStaffExmResults = (page, type, exam_type_id, companyId)=> async dispatch => {
    return await axios.get(`${PUBLIC_API}/company-student-exam`, {
        params:{page:page, type:type, exam_type_id:exam_type_id, companyId:companyId},
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("celtPublicToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name: 'staffExResults', value: resp?.data}));
    }).catch(err=>{

    })
}
export const getAllCountries = ()=> async dispatch => {
    return await axios.get(`${CELTGO_API}/public-countries`, {
        params:{page:5, lang_id:1, type:'only_accepted'},
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("celtPublicToken")}`
        }
    }).then(resp=>{
        // console.log(resp.data)
        dispatch(changeStateValue({name: 'allCountries', value: resp?.data}));
    }).catch(err=>{
        console.log(err.response)
    })
}
export const getEduDegree = ()=> async dispatch => {
    return await axios.get(`${CELTGO_API}/degree-accepted`, {
        params:{page:5, lang_id:1},
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("celtPublicToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name: 'eduDegree', value: resp?.data}));
    }).catch(err=>{

    })
}
export const getDisciplines = ()=> async dispatch => {
    return await axios.get(`${CELTGO_API}/public-disciplines`, {
        params:{page:10, lang_id:1, query_type:"accepted"},
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("celtPublicToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name: 'disciplines', value: resp?.data}));
    }).catch(err=>{

    })
}


export const getExamTypes = ()=> async dispatch => {
    return await axios.get(`${PUBLIC_API}/exam-type`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("celtPublicToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name: 'publicExamTypes', value: resp?.data}));
    }).catch(err=>{

    })
}

export const insertNewDataStaff = (url, formData) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${PUBLIC_API}/${url}`, formData, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("celtPublicToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
        console.log(err.response)
        return 'error'
    })
}

export const deleteData = (url, data='') => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.delete(`${PUBLIC_API}/${url}?data=${data}`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("celtPublicToken")}`
        }
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
        return 'error'
    })
}