import React, { Component } from 'react'

export class MsgSendSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m10 14 2.273 5.303c.313.73 1.286.807 1.676.116A54.175 54.175 0 0 0 17 13c2-5 3-9 3-9M10 14l-5.303-2.273c-.73-.313-.807-1.286-.116-1.676A54.175 54.175 0 0 1 11 7c5-2 9-3 9-3M10 14 20 4"
        />
      </svg>
    )
  }
}

export default MsgSendSvg
