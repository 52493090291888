import React, { Component } from 'react'
import { connect } from 'react-redux'
import CoachPartCard from './dashboard/CoachPartCard';

export class CompanyFailedStudentsCoach extends Component {
  render() {
    const {lang, firstFailedCoach, margin=true} = this.props;
    // ${margin? "ml-[20px] mr-[20px] mt-[60px]":""}
    return (
        <div className={` p-[20px] rounded-[20px]   bg-white `}>
            <h2 className=''>{lang?.failed_groupStudents}</h2>
            <div className='grid-full-320 '>
            {
                firstFailedCoach?.map((data, i)=>{
                    return(
                        <CoachPartCard
                        data={[]} 
                        teacher_id={data?.enroll?.teacher_id} 
                        key={i} 
                        student_id={data?.enroll?.student_id} 
                        student_name={data?.enroll?.student?.full_name} 
                        teacher_name={data?.enroll?.teacher?.full_name} 
                        course_title={data?.course?.title} 
                        status={2}  />
                    )
                })
            }
        </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    firstFailedCoach: state.Data.firstFailedCoach,
    lang: state.Data.lang,
})
export default connect(mapStateToProps)(CompanyFailedStudentsCoach)
