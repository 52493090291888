import React, { Component } from 'react'
import { getStudentFailedExams } from '../actions/CoachAction';
import { connect } from 'react-redux';
import CoachPartCard from './dashboard/CoachPartCard';
import moment from 'moment';
import SkeletonCard from './cards/SkeletonCard';

export class FailedExamStudents extends Component {
    state={
        btn:'today',
        loader:true,
        viewMore: false,
        btnSort:'all'
    }
    componentDidMount(){
        let date = new Date();
        let start_date = moment(date).format("YYYY-MM-DD");
        this.props.getStudentFailedExams(start_date, this.props.company_id)
        .then(resp=>{
            this.setState({loader:false})
        })
    }

    getData=(type)=>{
        this.setState({loader:true})
        this.setState({btn:type});
        let date = new Date();
        let start_date = moment(date).format("YYYY-MM-DD");
        if(type == "week"){
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7);
            start_date = moment(lastWeek).format("YYYY-MM-DD");
        }else if(type == "month"){
            const today = new Date();
            const lastMonth = new Date(today);
            lastMonth.setMonth(today.getMonth() - 1);
            start_date = moment(lastMonth).format("YYYY-MM-DD");
        }else{
            start_date =  moment(date).format("YYYY-MM-DD");
        }
        this.props.getStudentFailedExams(start_date, this.props.company_id)
        .then(resp=>{
            this.setState({loader:false})
        })
    }

    sortingBtn=(type)=>{
        this.setState({btnSort: type})
        // console.log(type)
    }
  render() {
    const {studentFailedExamsCoach, lang} = this.props;
    const {btn, loader, viewMore, btnSort} = this.state;
    let result = [];
    let newData = []
    if(btnSort == "math"){
        newData = studentFailedExamsCoach.filter(x=>x.exam?.course_id == 90)
    }else if(btnSort == "eng"){
        newData = studentFailedExamsCoach.filter(x=>x.exam?.course_id !== 90)
    }else{
        newData = studentFailedExamsCoach
    }
    if(newData){
           
      result = newData.reduce(function (r, a) {
      
          r[a.student_id] = r[a.student_id] || [];
          r[a.student_id].push(a);
          return r;
      }, {});
    }
    // console.log(newData)
    return (
        <div className=' mb-[20px]s p-[20px] rounded-[20px] bg-white'>
            <h2 className='mb-[20px]s'>{lang?.fes_title}</h2>
            <div className='flex items-center gap-[20px] mt-[10px]'>
                <button onClick={this.getData.bind(this, "today")} className={`${btn == "today"?"bg-blue text-white":"txt-blue shadow bg-white border border-[var(--blue)]"} p-[4px_10px] rounded-[6px]`}>{lang?.fes_today}</button>
                <button onClick={this.getData.bind(this, "week")} className={`${btn == "week"?"bg-blue text-white":"txt-blue shadow bg-white border border-[var(--blue)]"} p-[4px_10px] rounded-[6px]`}>{lang?.fes_last_week}</button>
                <button onClick={this.getData.bind(this, "month")} className={`${btn == "month"?"bg-blue text-white":"txt-blue shadow bg-white border border-[var(--blue)]"} p-[4px_10px] rounded-[6px]`}>{lang?.fes_last_month}</button>
            </div>
            <div className='flex items-center gap-[20px] mt-[10px]'>
                <button onClick={this.sortingBtn.bind(this, "all")} className={`${btnSort == "all"?"bg-blue text-white":"txt-blue shadow bg-white border border-[var(--blue)]"} p-[4px_10px] text-[12px] rounded-[6px]`}>All</button>
                <button onClick={this.sortingBtn.bind(this, "math")} className={`${btnSort == "math"?"bg-blue text-white":"txt-blue shadow bg-white border border-[var(--blue)]"} p-[4px_10px] text-[12px] rounded-[6px]`}>Math</button>
                <button onClick={this.sortingBtn.bind(this, "eng")} className={`${btnSort == "eng"?"bg-blue text-white":"txt-blue shadow bg-white border border-[var(--blue)]"} p-[4px_10px] text-[12px] rounded-[6px]`}>English</button>
            </div>
            {
                !loader && newData.length ==0?
                <div className='mt-[10px] flex justify-center'>
                    <h3 className='text-[18px] txt-blue font-[600]'>{lang?.fes_no_found}</h3>
                </div>:null
            }
            {
                loader?
                <div className='grid-full-320 mt-[10px]'>
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                    <SkeletonCard />
                </div>:
                <div >
                    <div className={`grid-full-320 mt-[10px] ${viewMore?"":' max-h-[240px] overflow-scroll'}`}>
                    {
                        Object.values(result)?.sort((a, b)=>b.length - a.length)?.map((data, i)=>{
                            return(
                                <CoachPartCard
                                data={[]} 
                                key={i} 
                                student_id={data[0]?.student?.id} 
                                student_name={data[0]?.student?.full_name} 
                                course_title={`Failed exam count: ${data?.length}`} 
                                status={2}  />
                            )
                        })
                    }
                    </div>
                    {
                        newData.length !==0?
                        <div className='flex justify-center mt-[5px]'>
                            <button onClick={()=>this.setState({viewMore: !viewMore})} className='bg-blue text-[12px] p-[5px_10px] text-white rounded-[6px]'>{viewMore?lang?.fes_show_less:lang?.fes_view_more}</button>
                        </div>:null
                    }
                    
                </div>
            }
            
            
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    studentFailedExamsCoach: state.Data.studentFailedExamsCoach,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getStudentFailedExams}
export default connect(mapStateToProps, mapDispatchToProps)(FailedExamStudents)
