import React, { Component } from 'react'
import { connect } from 'react-redux';
import {changeStateValue} from "../../../redux/MainReducer";
import EyeSvg from '../../svg/EyeSvg';
import EyeCloseSvg from '../../svg/EyeCloseSvg';
import PasswordStrength from '../../PasswordStrength';
import { checkPasswordStrength } from '../../../actions/getRole';
export class Input extends Component {
  state={
    inpType:'password'
  }
    changeInpValue(onChangeParent, e){
        this.props.changeStateValue({
            name:e.target.name, 
            value: e.target.value
        })
        onChangeParent(e.target.value)
    }
  render() {
    const {type = "text", name="", value="", title="", bg="bg-gray-100", placeholder=this.props.title, required=false, lang, onChangeParent=()=>{}, errorMsg= this.props.lang?.field_required} = this.props;
    const {inpType} = this.state;

    return (
      <div className='flex flex-col items-start'>
        {title !== "" ? <label htmlFor={name} className='text-[14px] txt-light-black'>{title}</label>:null }
        {
          type !=="password"?
          <input  onChange={this.changeInpValue.bind(this, onChangeParent)} placeholder={placeholder} id={name} name={name} value={value} type={type} className={` ${bg} w-full h-[35px] rounded-[6px] p-[5px] pl-[12px] outline-none text-[15px]`} />
          :<div className='bg-gray-100 w-full h-[35px] rounded-[6px] flex items-center gap-[10px] pr-[7px]'>
            <input  onChange={this.changeInpValue.bind(this, onChangeParent)} placeholder={placeholder} id={name} name={name} value={value} type={inpType} className='bg-gray-100  w-full h-[35px] rounded-[6px] p-[5px] pl-[12px] outline-none text-[15px]' />
            <button onClick={()=>this.setState({inpType: inpType==="password"? "text":"password"})}>
             { inpType==="password"?<EyeSvg />:<EyeCloseSvg /> } 
            </button>
          </div>
        }
          {
             type === "password"?
             <PasswordStrength strength={checkPasswordStrength(value)} />:null
           }
        {
            required && value===""?
            <span className='text-[11px] text-red-700'>{errorMsg}</span>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Input)
