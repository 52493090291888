import React, { Component } from 'react'
import { connect } from 'react-redux';
import { insertNewData, insertDataWithParams, getSpeakingAnswer } from '../../../actions/MainAction';
import { bigAlert, mixinAlert } from '../../../actions/SweetAlerts';
import {jwtDecode} from "jwt-decode";
import {getEssayGradeWritingGpt, getEssayGradeWritingIELTSGpt, getEssayGradeWritingIELTSImageGpt, getEssayWritingGpt, getEssayWritingImageGpt, getSpeakingGradeMistral, getSpeakingMistral} from "../../../actions/getReviewGpt"
import { getTurboModel, getMistralModel} from '../../../actions/robotActions';
import { changeStateValue } from '../../../redux/MainReducer';
import { withHooks } from '../../../actions/withHooks';
import { resetStateValue } from '../../../redux/MainReducer';
import { getGrades } from '../../../actions/getRole';
export class Footer extends Component {
  state={
    btnDisabled:false
  }
  getWordCount(text){
    var parser = new DOMParser();
    var doc = parser.parseFromString(text, 'text/html');
      return doc.children[0].children[1].textContent.match(/(\w+)/g) ? doc.children[0].children[1].textContent.match(/(\w+)/g).length: 0
  }
  saveBtn(data){
    if(data?.type == "essay_write"){
      if(this.getWordCount(data?.answer) >= 5){
        this.setState({btnDisabled:true})
        let formData = new FormData();
        formData.append('essay_id', data?.id);
        formData.append('is_submitted', 0);
        formData.append('answer', data?.answer);
        formData.append('robo_review',"error");
        if(data?.outline && data?.outline != ''){
          formData.append('question', data?.question);
          formData.append('outline', data?.outline);
        }
        this.props.insertNewData('essay-answers', formData)
        .then(resp=>{
          this.setState({btnDisabled:false})
          this.props.data?.afterCallFunction(...this.props.data?.funcParams);
          if(resp == 'success'){
            bigAlert(this.props.lang?.popupSaveTitle, this.props.lang?.ess_save_popup, "success");
          }else{
            bigAlert("Error", this.props.lang?.popup_went_wrong, "error");
          }
        })
      }else{
        mixinAlert("info", this.props.lang?.last_5);
      }
    }else if(data?.type == 'speaking_write'){
      if(data?.recorder_file != '' && data?.recorder_file){
        // return
        let formData = new FormData();
        const id = jwtDecode(localStorage.getItem('userToken'));
        formData.append("speaking_id", data.id)
        formData.append("user_id", id.sub)
        formData.append("status", 1)
        formData.append("answer",data?.recorder_file, data?.recorder_file.name);
        this.setState({btnDisabled:true})
        this.props.changeStateValue({
          name:'progressLoading',
          value:{
            position:true,
            text:"Please wait. I'm reviewing your speaking. Don't close the window.",
            complete:false
          }
        })
        this.props.insertDataWithParams('speakingsAnswer', formData)
        .then(resp=>{
          // console.log(resp)
          setTimeout(()=>{
            this.props.resetStateValue({name:'progressLoading'})
          }, 2000)
          this.props.navigate('/speaking-reviews')
          this.props.closeBtn();
        })
      }else{
        mixinAlert("info", this.props.lang?.record_warning);
      }
     
    }
  }

  submitEssay(data){
    if(data?.type == "essay_write"){
     
      if(this.getWordCount(data?.answer) >= data?.submit_word_count){
        this.setState({btnDisabled:true})
        let formData = new FormData();
        formData.append('is_submitted', 1);
        formData.append('essay_id', data?.id);
        formData.append('answer',  `<pre class='generatedEssayStyle'>${data?.answer}</pre>`);
        let question = data?.question
        if(data?.outline && data?.outline != ''){
          formData.append('question', data?.question);
          formData.append('outline', data?.outline);
          question = `${data?.question} ${ data?.outline}`
        }
        this.props.changeStateValue({name:"loader", value:true});
        this.props.changeStateValue({
          name:'progressLoading',
          value:{
            position:true,
            text:"Please wait. I'm reviewing your essay. Don't close the window.",
            complete:false
          }
        })
        formData.append("essay_type", data?.essay_type);
        this.props.insertNewData('essay-answers', formData)
        .then(resp=>{
          this.setState({btnDisabled:false})
          this.props.data?.afterCallFunction(...this.props.data?.funcParams);
          if(resp == 'success'){
            // bigAlert(this.props.lang?.popupSubmitTitle,this.props.lang?.ess_submit_popup, "success");
            this.props.changeStateValue({
              name:'progressLoading',
              value:{
                position:true,
                text:"Your essay is ready. Please check your grade.",
                complete:true
              }
            })
            setTimeout(()=>{
              this.props.resetStateValue({name:'progressLoading'})
            }, 2000)
            this.props.navigate("/essay-reviews");
            this.props.closeBtn();
          }else{
            bigAlert("Error", this.props.lang?.popup_went_wrong, "error");
          }
        })
      }else{
        mixinAlert("info",this.props.lang?.last_50?.replace("50", data?.submit_word_count));
      }
    }
  }
  teacherSaveBtn=(data)=>{
    if(data?.type === "essay"){
      if(data?.grade_inp.trim() !== "" && data?.review_input !== ""){
        let formData2 = new FormData();
       
        let params = {
          essay_answer_id: data?.essay_answer_id,
          grade: data?.grade_inp,
          head_teacher_confirm: data?.head_teacher_confirm,
          review: data?.review_input
        }
        this.props.insertNewData(`essay-reviews`, params)
        .then(resp=>{
          if(resp=== "success"){
            if(data?.is_closed==0){
              if((data?.essay_type === "midterm_end_course" || data?.essay_type === "unit") && this.props.user?.role === "head_teacher"){
                formData2.append("_method", "put")
                this.props.insertNewData(`essay-answers/${data?.essay_answer_id}`, formData2)
                .then(resp=>{
                  this.props.data?.afterCallFunction(...this.props.data?.funcParams);
                })
              }else{
                if(data?.essay_type === "unit"){
                  formData2.append("_method", "put")
                  this.props.insertNewData(`essay-answers/${data?.essay_answer_id}`, formData2)
                  .then(resp=>{
                    this.props.data?.afterCallFunction(...this.props.data?.funcParams);
                  })
                }else{
                  this.props.data?.afterCallFunction(...this.props.data?.funcParams);
                }
              }
            }else{
              this.props.data?.afterCallFunction(...this.props.data?.funcParams);
            }
            
            
            this.props.closeBtn();
            bigAlert(this.props.lang?.teacher_ess_review_title, this.props.lang?.teacher_ess_review_text, "success")
          }else{
            bigAlert("Error", this.props.lang?.popup_went_wrong, "error")
          }
          
        })
      }else{
        mixinAlert("info", this.props.lang?.fill_grade_review);
      }
      
    }else if(data?.type === "speaking"){
      if(data?.grade_inp.trim() !== "" && data?.review_input !== ""){
        let params = {
          speaking_answer_id: data?.speaking_answer_id,
          grade: data?.grade_inp,
          is_student: '0',
          review: data?.review_input,
          user_id: data?.user_id
        }
        this.props.insertNewData(`speakingsAnswerReview`, params)
        .then(resp=>{
         
          if(resp=== "success"){
            if(data?.is_closed==0){
              let formData = new FormData();
              formData.append("_method", "put")
              this.props.insertNewData(`speakingsAnswerUpdate/${data?.speaking_answer_id}`, formData)
              .then(resp=>{
                this.props.data?.afterCallFunction(...this.props.data?.funcParams);
              })
            }else{
              this.props.data?.afterCallFunction(...this.props.data?.funcParams);
            }
           
            this.props.closeBtn();
            bigAlert(this.props.lang?.teacher_sp_review_title, this.props.lang?.teacher_sp_review_text, "success")
          }else{
            bigAlert("Error",  this.props.lang?.popup_went_wrong, "error")
          }
          
        })
      }else{
        mixinAlert("info", this.props.lang?.fill_grade_review);
      }
    }else if(data?.type === "ial_essay"){
      if(data?.grade_inp.trim() !== "" && data?.review_input !== ""){
        const review = {
          review:data?.review_input,
          grade:data?.grade_inp,
          essay_answer_id:data?.essay_answer_id
        }
        this.props.insertNewData("teacher-review-new-essay", review)
        .then(resp=>{

            if(resp=== "success"){
            
              this.props.data?.afterCallFunction(...this.props.data?.funcParams);
              this.props.closeBtn();
              bigAlert( this.props.lang?.teacher_ess_review_title,this.props.lang?.teacher_ess_review_text, "success")
            }else{
              bigAlert("Error", this.props.lang?.popup_went_wrong, "error")
            }
        })
      }else{
        mixinAlert("info", this.props.lang?.fill_grade_review);
      }
    }
  }
  render() {
    const {closeBtn, data, user, lang} = this.props;
    const {btnDisabled} = this.state;
    return (
      <div className='flex mt-[20px] items-center justify-between'>
        <button disabled={btnDisabled} onClick={()=>closeBtn()} className='bg-red h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
        {user?.role === "student" && data?.type == "essay_write" ?
        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className=' h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] txt-blue border-[1px] border-[var(--blue)] font-semibold'>{ data?.type == "essay_write"?lang?.save:lang?.submit}</button>:null}
        {user?.role === "student" && data?.type === "speaking_write"?
        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,data)} className=' bg-blue h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{ data?.type == "essay_write"?lang?.save:lang?.submit}</button>:null}
        {
          data?.type == "essay_write"?
          <button disabled={btnDisabled} onClick={this.submitEssay.bind(this, data)} className='bg-blue h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.submit}</button>:null
        }
        {
          data?.modalType === "teacher_review" && (user?.role === "teacher" || user?.role === "head_teacher" || user?.role === "speaking_teacher") && data?.teacherEditor?
          <button disabled={btnDisabled} onClick={this.teacherSaveBtn.bind(this,data)} className='bg-blue h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save_close}</button>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user,
  lang: state.Data.lang,
});
const mapDispatchToProps = {insertNewData, getTurboModel, changeStateValue, getMistralModel, resetStateValue, insertDataWithParams, getSpeakingAnswer}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Footer))
