import React, { Component } from 'react'

export class SingleCenterWhatsappSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 455.731 455.731"
        width={36}
        height={36}
        style={{ borderRadius:'10px' }}
      >
        <path
          d="M0 0h455.731v455.731H0z"
          style={{
            fill: "#1bd741",
          }}
        />
        <path
          d="m68.494 387.41 22.323-79.284c-14.355-24.387-21.913-52.134-21.913-80.638 0-87.765 71.402-159.167 159.167-159.167s159.166 71.402 159.166 159.167-71.401 159.167-159.166 159.167c-27.347 0-54.125-7-77.814-20.292L68.494 387.41zm85.943-50.004 4.872 2.975c20.654 12.609 44.432 19.274 68.762 19.274 72.877 0 132.166-59.29 132.166-132.167S300.948 95.321 228.071 95.321 95.904 154.611 95.904 227.488c0 25.393 7.217 50.052 20.869 71.311l3.281 5.109-12.855 45.658 47.238-12.16z"
          style={{
            fill: "#fff",
          }}
        />
        <path
          d="m183.359 153.407-10.328-.563a12.49 12.49 0 0 0-8.878 3.037c-5.007 4.348-13.013 12.754-15.472 23.708-3.667 16.333 2 36.333 16.667 56.333 14.667 20 42 52 90.333 65.667 15.575 4.404 27.827 1.435 37.28-4.612 7.487-4.789 12.648-12.476 14.508-21.166l1.649-7.702a5.35 5.35 0 0 0-2.993-5.98L271.22 246.04a5.352 5.352 0 0 0-6.477 1.591l-13.703 17.764a3.921 3.921 0 0 1-4.407 1.312c-9.384-3.298-40.818-16.463-58.066-49.687a3.96 3.96 0 0 1 .499-4.419l13.096-15.15a5.35 5.35 0 0 0 .872-5.602l-15.046-35.201a5.352 5.352 0 0 0-4.629-3.241z"
          style={{
            fill: "#fff",
          }}
        />
      </svg>
    )
  }
}

export default SingleCenterWhatsappSvg
