import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import { getCompanyInOuts, getWorkerInOuts } from '../../actions/CELTGOActions';
import DropSearch from '../../components/DropSearch';
import PdfSvg from '../../components/svg/PdfSvg';
import FinanceDetailModal from '../../components/modals/FinanceDetailModal';
import ContractPdfSvg from '../../components/svg/ContractPdfSvg';
import EmptyPage from '../../components/EmptyPage';
import GOExcell from '../../components/GOExcell';
export class AccountantGOPages extends Component {
    state={
        year:'2023-2024',
        page:'student',
        modalData:[],
        studentName:''
    }
    componentDidMount(){
        this.props.getCompanyInOuts(this.props.params.id, this.state.year)
        this.props.getWorkerInOuts(this.props.params.id, this.state.year)
    }

    onSelectData=(data)=>{
        this.props.getCompanyInOuts(this.props.params.id, data)
        this.props.getWorkerInOuts(this.props.params.id, data)
    }
    getTotals(arr, type){

        let total = 0;
        for(let i = 0; i < arr.length; i++){
            if(arr[i]?.type == type){
                total+= +arr[i]?.amount
            }
        }
        return Math.round(total);
    }

    getAllTotal(arr, type){
        let total = 0;
        arr.forEach(item=>{
            item?.worker_students?.map(ws=>{
                ws.student.student_finance.map(sf=>{
                    if(sf.type === type){
                        total+= +sf.amount;
                    }
                })
            })
        });
        return Math.round(total);
    }
    getWorkerTotal(arr){
        let total = 0;
        arr.forEach(item=>{
            item?.company_stats?.map(cs=>{
               total += cs.amount
            })
        });
        return Math.round(total);
    }
    getFiles(data){
        let x = '';
        for(const file of data){
            if(file?.file){
                x+= `${file?.file?.file_full_url}`
            }
        }
        return x;
    }

    getTotalsIns(arr){
        let total = 0;
        for(const x of arr){
            total+= +x?.amount
        }
        return total;
    }
    getTotalsInsCont(arr){
        let y = '';
        for(const x of arr){
            if(x?.file){
                y+= `${x?.file_full_url}`
            }
            
        }
        return y;
    }
    getInOuts=(data)=>{
        let finalData = [];
        for(const xtm of data){
            for(const st of xtm?.worker_students){
                finalData.push({
                    [`${this.props.lang?.acc_student}`]: st?.student?.full_name,
                    [`${this.props.lang?.educon}`]: xtm?.full_name,
                    "Müq": this.getTotalsIns(st.student.total_ins),
                    ["Müq " + this.props.lang?.acc_continue]: { t: 's', v:this.getTotalsInsCont(st.student.total_ins)!=""? this.props.lang?.acc_continue :'', l: { Target:this.getTotalsInsCont(st.student.total_ins) } }, 
                    [`${this.props.lang?.ins}`]: this.getTotals(st?.student?.student_finance, 'incomes'),
                    [`${this.props.lang?.outss}`]: this.getTotals(st?.student?.student_finance, 'outs'),
                    [`${this.props.lang?.total_net}`]: this.getTotals(st?.student?.student_finance, 'incomes') - this.getTotals(st?.student?.student_finance, 'outs'),
                    [`${this.props.lang?.acc_continue}`]: { t: 's', v:this.props.lang?.acc_continue , l: { Target: this.getFiles( st?.student?.student_finance) } } 
                })
            }
        }
        return finalData
    }
    getXtmExpences=(data)=>{
        let finalData = [];

        for(const xtm of data){
            for(const item of xtm?.company_stats){
                finalData.push({
                    [`${this.props.lang?.educon}`]:xtm?.full_name,
                    [`${this.props.lang?.title}`]:item?.title,
                    [`${this.props.lang?.amount}`]:item?.amount,
                })
            }
        }
        return finalData
    }
  render() {
    const {lang, celtgoInOuts, celtgoWorkerInOuts} = this.props;
    const {page, year, modalData, studentName} = this.state;
    // console.log(celtgoInOuts)
    let option = [
        {
            label: "2020-2021",
            value: "2020-2021"
        },
        {
            label: "2021-2022",
            value: "2021-2022"
        },
        {
            label: "2022-2023",
            value: "2022-2023"
        },
        {
            label: "2023-2024",
            value: "2023-2024"
        },
        {
            label: "2024-2025",
            value: "2024-2025"
        },
    ];
    
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={this.props.params.company_name?.replaceAll("%20", " ")} />
            <div className='flex  gap-[20px] mt-[10px]'>
                <Link  to={`/accountant-teachers-contracts/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center  txt-blue bg-white text-[22px] rounded-[6px]'>{lang?.salary_docs}</Link>
                <Link  to={`/accountant-payments/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center txt-blue bg-white shadow text-[22px] rounded-[6px]'>{lang?.pays_acc}</Link>
                <Link  to={`/accountant-teachers/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center txt-blue bg-white shadow text-[22px] rounded-[6px]'>{lang?.t_count_acc}</Link>
                <Link  to={`/accountant-celtgo/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center   bg-blue text-white shadow text-[22px] rounded-[6px]'>{lang?.celtgo_acc}</Link>
            </div>

            {
                 this.props.params.id == 31?
                <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, change date to show check-ins" subTitle="" /></div>:
                <>
                    <div className='flex items-center gap-[20px] mt-[20px]'>
                <button onClick={()=>this.setState({page:"student"})} className={`text-[12px] ${page === "student"? "bg-blue text-white":"bg-white txt-blue"} font-semibold  p-[4px_10px] rounded-[6px]`}>
                    {lang?.student_finance}
                </button>
                <button onClick={()=>this.setState({page:"educon"})}  className={`text-[12px] ${page === "educon"? "bg-blue text-white":"bg-white txt-blue"} font-semibold  p-[4px_10px] rounded-[6px]`}>
                    {lang?.edu_expences}
                </button>
            </div>
            <div className='flex white-search mt-[20px]'>
                <DropSearch options={option} name="" value={year} placeholder={year} selectData={this.onSelectData.bind(this)} />
            </div>
            <div className='flex mt-[20px]'>
                    <div className='bg-white rounded-[10px] w-[300px]'>
                        <h3 className='text-white bg-blue rounded-t-[10px] text-center h-[35px] pt-[5px] font-semobold text-[16px]'>{lang?.summary}</h3>
                        <ul>
                            <li className='flex justify-between border-b'>
                                <span className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_ins}</span>
                                <span className='txt-light-black p-[6px] w-1/2 font-regular text-[14px]'>{this.getAllTotal(celtgoInOuts, "incomes")}</span>
                            </li>
                            <li className='flex justify-between border-b'>
                                <span className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_outs_student}</span>
                                <span className='txt-light-black p-[6px] inline-flex justify-between w-1/2 font-regular text-[14px]'>{this.getAllTotal(celtgoInOuts, "outs")}</span>
                            </li>
                            <li className='flex justify-between border-b'>
                                <span className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_outs_worker}</span>
                                <span className='txt-light-black p-[6px] inline-flex justify-between w-1/2 font-regular text-[14px]'>{this.getWorkerTotal(celtgoWorkerInOuts)}</span>
                            </li>
                            <li className='flex justify-between border-b'>
                                <span className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_net}</span>
                                <span className='txt-light-black p-[6px] w-1/2 font-regular text-[14px]'>
                                    {this.getAllTotal(celtgoInOuts, "incomes") -(this.getAllTotal(celtgoInOuts, "outs") + this.getWorkerTotal(celtgoWorkerInOuts))}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            <div>
                {
                     page==="student"?
                    <GOExcell fileName={lang?.student_finance+" "+this.props.params.company_name?.replaceAll("%20", " ")} excellData={this.getInOuts(celtgoInOuts)} />:
                    <GOExcell fileName={lang?.edu_expences+" "+this.props.params.company_name?.replaceAll("%20", " ")} excellData={this.getXtmExpences(celtgoWorkerInOuts)} />
                }
                
            </div>
            {
                page==="student"?
                <div className='bg-white mt-[30px] p-[20px] rounded-[12px]'>
                   <div className='flex items-center justify-between border '>
                        <span style={{ minWidth: '120px' }} className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.acc_student}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.educon}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.ins}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.outss}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.total_net}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.acc_continue}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.acc_actions}</span>
                    </div> 
                    <div>
                        {
                            celtgoInOuts?.map((data, i)=>{
                                return(
                                    data?.worker_students?.map((item, j)=>{
                                        return(
                                          <div key={j} className='flex'>
                                              <span className='border w-1/5 text-center p-2 text-base '>{item?.student?.full_name}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>{data?.full_name}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>{this.getTotals(item?.student?.student_finance, 'incomes')}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>{this.getTotals(item?.student?.student_finance, 'outs')}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>{this.getTotals(item?.student?.student_finance, 'incomes') - this.getTotals(item?.student?.student_finance, 'outs')}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>
                                              {
                                                item?.student?.student_finance?.map((file,k)=>{
                                                    if(file?.file){
                                                        return(
                                                            <a download={true} key={k} target='_blank' href={file?.file?.file_full_url}>
                                                                <ContractPdfSvg className='w-6 cursor-pointer' />
                                                            </a>
                                                        )
                                                    }
                                                })
                                            }
                                              </span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>
                                                <button onClick={()=>this.setState({modalData:item?.student?.student_finance, studentName:item?.student?.full_name})} className='bg-blue text-[12px] p-[3px_5px] text-white font-semibold rounded-[5px]'>
                                                    {lang?.view}
                                                </button>
                                              </span>
                                          </div>
                                          )  
                                      })
                                )
                                
                            })
                        }
                    </div>
                </div>:
                <div className='bg-white mt-[30px] p-[20px] rounded-[12px]'>
                   <div className='flex items-center justify-between border '>
                        <span className='border w-1/4 text-center p-2 text-base font-bold'>{lang?.educon}</span>
                        <span className='border w-1/4 text-center p-2 text-base font-bold'>{lang?.title}</span>
                        <span className='border w-1/4 text-center p-2 text-base font-bold'>{lang?.amount}</span>
                        <span className='border w-1/4 text-center p-2 text-base font-bold'>{lang?.acc_continue}</span>
                    </div> 
                    <div>
                        {
                            celtgoWorkerInOuts?.map((data, i)=>{
                                return(
                                    data?.company_stats?.map((item, j)=>{
                                        return(
                                          <div key={j} className='flex'>
                                              <span className='border w-1/4 text-center p-2 text-base '>{data?.full_name}</span> 
                                              <span className='border w-1/4 text-center p-2 text-base '>{item?.title}</span> 
                                              <span className='border w-1/4 text-center p-2 text-base '>{item?.amount}</span> 
                                              <span className='border w-1/4 text-center p-2 text-base '>
                                              {
                                                item?.file?
                                                <a download={true} target='_blank' href={item?.file_full_url}>
                                                    <ContractPdfSvg className='w-6 cursor-pointer' />
                                                </a>:null
                                            }
                                              </span> 
                                          </div>
                                          )  
                                      })
                                )
                                
                            })
                        }
                    </div>
                </div>
            }
                </>
            }
            
           {
            modalData?.length !==0?
            <FinanceDetailModal studentName={studentName} closeBtn={()=>this.setState({modalData:[], studentName:''})} data={modalData} lang={lang} />:null
           }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang,
    celtgoInOuts: state.Data.celtgoInOuts,
    celtgoWorkerInOuts: state.Data.celtgoWorkerInOuts,
});
const mapDispatchToProps = {getCompanyInOuts, getWorkerInOuts}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AccountantGOPages))
