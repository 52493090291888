import React, { Component } from 'react'
import Logo from './Logo'
import Navigation from './Navigation'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
export class SideBar extends Component {
  closeNav=(e)=>{
    if(e.target.className === "sideBrBackground"){
      const nav  =document.querySelector('#navBtnCheckox');
      if(nav){
        nav.checked = false;
        this.props.changeStateValue({name:'navBtn', value:true})
      }
    }
  }
  render() {
    const {user, searchNav} = this.props;
    return (
    <>
    <input type='checkbox' className='hidden'  id='navBtnCheckox' />
     <div onClick={this.closeNav.bind(this)} className='sideBrBackground'>
       <div className='bg-white w-[251px] min-w-[251px] h-screen sidebar'>
        <Logo />
        {
          user?.role == "super_admin"?
          <div className='flex justify-center w-full '>
            <input value={searchNav} onChange={(e)=>this.props.changeStateValue({name:"searchNav", value:e.target.value})} className='border outline-none rounded-[5px] mt-[4px] w-[90%] h-[35px] text-[14[x]' placeholder='Search...' type='text' />
          </div>:null
        }
        
        <Navigation />
      </div>
     </div>
    </>
    )
  }
}
const mapStateToProps = (state) =>({
  user: state.Data.user,
  searchNav: state.Data.searchNav,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
