import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getStudentsCheckins } from '../../actions/MainAction';
import { getTeachers } from '../../actions/StaffAction';
import DropSearch from '../../components/DropSearch';
import moment from 'moment';
import MonthlyCalendar from '../../components/MonthlyCalendar';
import Card1 from '../../components/cards/Card1';
import EmptyPage from '../../components/EmptyPage';
import Search from '../../components/Search';
import { changeStateValue } from '../../redux/MainReducer';
import { withHooks } from '../../actions/withHooks';
import { getUserData } from '../../actions/StaffAction';
export class ManagerCheckins extends Component {
  state={
    date: moment(new Date()).format("YYYY-MM"),
    teacher_id:'',
    search:'',
    teacherData:''
  }
  componentDidMount(){
    this.props.changeStateValue({
      name:'studentCheckIns',
      value: []
    })
    if(this.props.user !== ""){
      this.setState({teacher_id:this.props.params.id})
      this.props.getStudentsCheckins(this.state.date, '', this.props.params.id, this.props.user?.company_id);  
    }
    this.props.getUserData(this.props.params.id)
    .then(resp=>{this.setState({teacherData:resp})})
    window.scrollTo(0,0)
  }
  componentDidUpdate(prevProps, prevState){
      if(prevProps?.user !== this.props.user){
        this.setState({teacher_id:this.props.params.id})
        this.props.getStudentsCheckins(this.state.date, '', this.props.params.id, this.props.user?.company_id);  
      }
  }
  getCheckinsByTeacher=(id)=>{
    this.setState({teacher_id:id})
    this.props.getStudentsCheckins(this.state.date, '', id, this.props.user?.company_id);  
  } 
  selectMonth=(date)=>{
    this.setState({date:date});
    if(this.state.teacher_id !== ""){
      this.props.getStudentsCheckins(date, '', this.state.teacher_id , this.props.user?.company_id);
    }
  }
  searchData=(search)=>{
    this.setState({search})
  }
  render() {
    const {studentCheckIns, teachers, lang} = this.props;
    const {search, teacherData} = this.state;
    let teacherOption = [];
    for(const data of teachers){
        teacherOption.push({
            label: data?.full_name,
            value: data?.id
        })
    }
    let checkinData = [];
    if(studentCheckIns.students){
      checkinData = [...studentCheckIns.students];
    }
    if(search !== ""){
      checkinData = checkinData.filter(x=>x.full_name?.toLowerCase().includes(search.toLowerCase()) || x.email?.toLowerCase().includes(search.toLowerCase()) || x.lesson_mode?.toLowerCase()?.includes(search.toLowerCase()) || x?.title?.toLowerCase()?.includes(search.toLowerCase()))
    }
    let result = [];
    if(studentCheckIns.students){
           
      result = checkinData.reduce(function (r, a) {
      
          r[a.title] = r[a.title] || [];
          r[a.title].push(a);
          return r;
      }, {});
    }
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title={teacherData?.full_name} />
        <div className='flex gap-[20px] items-center white-search mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px] max-[450px]:flex-col '>
            {/* <DropSearch options={teacherOption} placeholder={lang?.select_teacher} selectData={this.getCheckinsByTeacher.bind(this)} /> */}
            <MonthlyCalendar wFull={false} bgCont="bg-none" rounded="5px" bg="bg-white" onSelect={this.selectMonth.bind(this)} />
        </div>
        {studentCheckIns?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
        {
          studentCheckIns?.length !== 0?
          <div className='flex justify-center'>
            <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
          </div>:null
        }
        
        <div>
            {
                 Object.values(result).map((item, j)=>{
                    return(
                        <div key={j}>
                            <h2 className='mt-[20px] font-semibold text-[25px]'>{item[0]?.title?item[0]?.title:"One to one"}</h2>
                            <div className='mt-[12px] grid-full-250'>
                                {

                                    item?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map((data, i)=>{
                                      if(data?.status=='1' || data?.status=='0' && data.attendances_count!=0){
                                        return(
                                          <Card1
                                            key={i}
                                            title={data?.lesson_mode}
                                            name={data?.full_name}
                                            time={data.total_lesson? `${data.total_lesson.split(' ')[0].substring(0,5)} ${data.total_lesson.split(' ')[1]}`:''}
                                            status={1}
                                            statusBisible={false}
                                            statusText={data?.status==1? lang?.pass: lang?.fail}
                                            group={data.title?data.title:'One to One'}
                                            btn={false}
                                            close={`${lang?.total_time}: ${data.attendances_count}`}
                                            url={`/check-in/${data?.student_id}/${data?.teacher_id}`}
                                            blank={true}
                                        />
                                        )
                                      }
                                        
                                    })
                                }
                            
                            </div>
                        </div>
                    )
                 })
            }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  studentCheckIns: state.Data.studentCheckIns,
  user: state.Data.user,
  teachers: state.Data.teachers,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getStudentsCheckins, getTeachers, changeStateValue, getUserData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ManagerCheckins))
