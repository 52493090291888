import React, { Component } from 'react'
import Header from './Header'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import { insertNewData } from '../../actions/MainAction'
import { connect } from 'react-redux'
import Input from './components/Input'
import { mixinAlert } from '../../actions/SweetAlerts'
import { getGroupsLink } from '../../actions/StaffAction'

export class TeacherMessageModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"createGroupMessageModal"})
    }
    saveBtn=(data)=>{
        if(data?.message !=""){
            this.setState({btnDisabled:true})
            let formData = new FormData();
            formData.append("group_id", data?.group_id);
            formData.append("message", data?.message);
            let url = "add-message-teacher-group";
            if(data?.id && data?.id !=""){
                url = `update-message-teacher-group/${data?.id}`;
                formData.append("_method", "PUT")
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisabled:false})
                if(resp == "success"){
                    mixinAlert("success", 'Data saved successfully');
                    this.closeModal()
                    this.props.getGroupsLink()
                }else{
                    mixinAlert("error", 'Something went wrong')
                }
            })
        }
    }
  render() {
    const {btnDisabled} = this.state;
    const {createGroupMessageModal, lang} = this.props;
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='bg-white w-[500px] rounded-[10px]'>
            <Header closeBtn={this.closeModal.bind(this)} title="Add a note" />
            <div className='p-[20px]'>
                <Input required type="text" value={createGroupMessageModal?.message} name="createGroupMessageModal.message" title='Note' />
                
                <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this, createGroupMessageModal)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save}</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    createGroupMessageModal: state.Data.createGroupMessageModal,
    lang: state.Data.lang,
});
const mapDispatchToProps = {resetStateValue, changeStateValue, insertNewData, getGroupsLink}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherMessageModal)
