import React, { Component } from 'react'
import { changeStateValue } from '../../../../../redux/MainReducer';
import { connect } from 'react-redux';

export class TimeButton extends Component {
    state={
        selected:false
    }

    selectTime=(time)=>{
        this.props.changeStateValue({
            name:"selectSchedule.time",
            value:time
        });
        this.props.changeStateValue({
            name:"selectSchedule.modalPosition",
            value:true
        });
    }
  render() {
    const {selected} = this.state;
    const {time} = this.props;
    return (
       <div>
        {
            !selected?
            <button onClick={()=>this.setState({selected:true})} className='w-[140px] border border-[var(--blue)] h-[45px] rounded-[6px] text-[14px] txt-blue hover:border-[2px] hover:font-[600]'>
            {time}
            </button>:
            <div className='w-[140px] grid grid-cols-2 gap-[5px] '>
                <button className='h-[45px] bg-gray-500 rounded-[6px] text-white text-[14px]'>
                    {time}
                </button>
                <button onClick={this.selectTime.bind(this, time)} className='h-[45px] bg-blue rounded-[6px] text-white text-[14px]'>
                    Next
                </button>
            </div>
        }
            
       </div>
    )
  }
}
const mapStateToProps = (state) =>({

})
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(TimeButton)
