import React, { Component } from 'react'
import BottomSvg from '../svg/BottomSvg'
import { imageAlert } from '../../actions/SweetAlerts';
import { connect } from 'react-redux';

export class ExamPreviewParentCard extends Component {
  state={
    visible:false
  }
  render() {
    const {data, lang} = this.props;
    const {visible} = this.state;
    return (
      <div className='bg-white rounded-[12px] p-[20px]'>
        <div onClick={()=>this.setState({visible: !visible})} className='flex items-center justify-between pr-[20px] pl-[20px] cursor-pointer'>
          <div>
                <h3 className=' text-[20px] font-bold txt-light-black'>{data?.data?.data?.title}</h3>
                <span className='mt-[10px] text-gray-600 font-[300]'>{lang?.points}: {data?.data?.data?.points}</span>
          </div>
            <BottomSvg />
        </div>
        {
          visible?
          <div >
          {
            data?.data?.data?.student_exam_questions?.map((item, j)=>{
              if(item?.question_type === "single_choice" || 
                item?.question_type === "single_image_choice" || 
                item?.question_type === "single_image_type"||
                item?.question_type === "single_audio_type"||
                item?.question_type === "single_audio_with_image"||
                item?.question_type === "single_boolean_type"
              ){
                return (
                  <div key={j} className='p-[20px] bg-gray-100 rounded-[12px] mt-[12px]'>
                   
                    <div className='flex items-center justify-between max-[650px]:flex-col-reverse max-[650px]:gap-[10px]'>
                         <div>
                          <h4 className='txt-blue font-semibold text-[18px]'>{item?.title}</h4>
                          <span className='block text-[14px]'>{lang?.points}: {item?.given_answer?.score}</span>
                         </div>
                         
                    </div>
                    {
                      item?.image_full_url?
                      <img  onClick={()=>{imageAlert(item?.image_full_url)}} src={item?.image_full_url} className='w-[400px] cursor-pointer mt-[10px]  max-[650px]:w-[100%] rounded-[6px]' />:null
                    }
                    
                      <div className='flex gap-[0px] mt-[10px] flex-col max-[650px]:flex-col max-[650px]:gap-[10px]'>
                          <div className='flex items-center gap-[10px] mt-[10px]'>
                              <span className='text-[14px] font-semibold'>{lang?.answer}:</span>
                              {
                                 item?.question_type === "single_image_choice" || item?.question_type === "single_audio_with_image"?
                                 <img className='w-[90px] h-[90px] rounded-[6px] cursor-pointer'  onClick={()=>{imageAlert(item?.given_answer?.answer_full_url)}} src={item?.given_answer?.answer_full_url} />:
                                 <span className='text-[14px] txt-blue '>{item?.given_answer?.title}</span>
                              }
                              
                          </div>
                          <div className='flex items-center gap-[10px] mt-[5px]'>
                              <span className='text-[14px] font-semibold'>{lang?.correct}:</span>
                              {
                                 item?.question_type === "single_image_choice"  || item?.question_type === "single_audio_with_image"?
                                 <img className='w-[90px] h-[90px] rounded-[6px] cursor-pointer' onClick={()=>imageAlert(item?.student_exam_question_answers[0]?.answer_full_url)} src={item?.student_exam_question_answers[0]?.answer_full_url} />:
                                 <span className='text-[14px] txt-blue'>{item?.student_exam_question_answers[0]?.title}</span>
                              }
                          </div>
                          <div className='flex items-center gap-[5px]'>
                              <span className='text-[14px] font-semibold'>{lang?.result}:</span>
                              {
                                item?.given_answer?.is_correct == "1"?
                                <span className='text-[14px] p-[4px] txt-blue font-semibold  rounded-[6px]'>{lang?.correct}</span>:
                                <span className='text-[14px] p-[4px] txt-red font-semibold  rounded-[6px]'>{lang?.wrong}</span>
                              }
                              
                          </div>
                      </div>
                  </div>
                )
              }else if( item?.question_type === "multiple_choice"){
                return(
                  <div key={j} className='p-[20px] bg-gray-100 rounded-[12px] mt-[12px]'>
                   
                    <div className='flex items-center justify-between max-[650px]:flex-col-reverse max-[650px]:gap-[10px]'>
                        <div>
                          <h4 className='txt-blue font-semibold text-[18px]'>{item?.title}</h4>
                          <span className='block text-[14px]'>{lang?.points}: { item?.given_answer[0]?.score}</span>
                          <span className='block text-[14px]'>{lang?.points}: { item?.given_answer[1]?.score}</span>
                         </div>
                          <div className='flex items-center gap-[5px]'>
                              <span>{lang?.result}:</span>
                              {
                                item?.given_answer[0]?.is_correct == "1" && item?.given_answer[1]?.is_correct == "1"?
                                <span className='text-[14px] p-[4px] text-white font-semibold bg-blue rounded-[6px]'>{lang?.correct}</span>:
                                item?.given_answer[0]?.is_correct == "0" && item?.given_answer[1]?.is_correct == "0"?
                                <span className='text-[14px] p-[4px] text-white font-semibold bg-red rounded-[6px]'>{lang?.wrong}</span>:
                                <span className='text-[14px] p-[4px] txt-blue font-semibold border border-[var(--blue)] rounded-[6px]'>{lang?.partially_correct}</span>
                              }
                              
                          </div>
                    </div>
                    {
                      item?.image_full_url?
                      <img src={item?.image_full_url} className='w-[400px] mt-[10px]  max-[650px]:w-[100%] rounded-[6px]' />:null
                    }
                      
                      <div className='flex gap-[20px] mt-[10px]  max-[650px]:flex-col max-[650px]:gap-[10px]'>
                          <div className='flex items-center gap-[10px] mt-[10px]'>
                              <span className='text-[14px] font-semibold'>{lang?.answer}:</span>
                              {
                                item?.question_type === "single_image_choice"?
                                <img className='w-[90px] h-[90px] rounded-[6px] cursor-pointer' src={item?.given_answer[0]?.answer_full_url} />:
                                <span className='text-[14px] txt-blue '>{item?.given_answer[1]?.title}</span>
                              }
                              
                          </div>
                          <div className='flex items-center gap-[10px] mt-[10px]'>
                              <span className='text-[14px] font-semibold'>{lang?.correct}:</span>
                              {
                                item?.question_type === "single_image_choice"?
                                <img className='w-[90px] h-[90px] rounded-[6px] cursor-pointer' src={item?.student_exam_question_answers[0]?.answer_full_url} />:
                                <span className='text-[14px] txt-blue'>{item?.student_exam_question_answers[0]?.title}</span>
                              }
                          </div>
                      </div>
                      <div className='flex gap-[20px] mt-[10px]  max-[650px]:flex-col max-[650px]:gap-[10px]'>
                          <div className='flex items-center gap-[10px] mt-[10px]'>
                              <span className='text-[14px] font-semibold'>{lang?.answer}:</span>
                              {
                                item?.question_type === "single_image_choice"?
                                <img className='w-[90px] h-[90px] rounded-[6px] cursor-pointer' src={item?.given_answer[1]?.answer_full_url} />:
                                <span className='text-[14px] txt-blue '>{item?.given_answer[1]?.title}</span>
                              }
                              
                          </div>
                          <div className='flex items-center gap-[10px] mt-[10px]'>
                              <span className='text-[14px] font-semibold'>{lang?.correct}:</span>
                              {
                                item?.question_type === "single_image_choice"?
                                <img className='w-[90px] h-[90px] rounded-[6px] cursor-pointer' src={item?.student_exam_question_answers[1]?.answer_full_url} />:
                                <span className='text-[14px] txt-blue'>{item?.student_exam_question_answers[1]?.title}</span>
                              }
                          </div>
                      </div>
                  </div>
                )
              }else if( item?.question_type === "dropdown_question_type"){
                return(
                  item?.student_exam_question_answers?.map((ans, k)=>{
                    return(
                      <div key={k} className='p-[20px] bg-gray-100 rounded-[12px] mt-[12px]'>
                     
                      <div className='flex items-center justify-between max-[650px]:flex-col-reverse max-[650px]:gap-[10px]'>
                            <h4 className='txt-blue font-semibold text-[18px]'>{ans?.title}</h4>
                            <div className='flex items-center gap-[5px]'>
                                <span>{lang?.result}:</span>
                                {
                                  ans?.score !== 0?
                                  <span className='text-[14px] p-[4px] text-white font-semibold bg-blue rounded-[6px]'>{lang?.correct}</span>:
                                  <span className='text-[14px] p-[4px] text-white font-semibold bg-red rounded-[6px]'>{lang?.wrong}</span>
                                }
                                
                            </div>
                      </div>
                     
                      
                        <div className='flex gap-[20px] mt-[10px]  max-[650px]:flex-col max-[650px]:gap-[10px]'>
                            <div className='flex items-center gap-[10px] mt-[10px]'>
                                <span className='text-[14px] font-semibold'>{lang?.correct}:</span>
                                <span className='text-[14px] txt-blue '>{ans?.is_correct}</span>
                                
                            </div>
                            <div className='flex items-center gap-[10px] mt-[10px]'>
                                <span className='text-[14px] font-semibold'>{lang?.answer}:</span>
                                <span className='text-[14px] txt-blue'>{ans?.given_answer}</span>
                            </div>
                        </div>
                    </div>
                    )
                  })
                )
              }
              
            })
          }
        </div>:null
        }
        
    </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(ExamPreviewParentCard)
