import React, { Component } from 'react'

export class CardTelSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    width={16}
    height={16}
    data-name="Layer 1"
    viewBox="0 0 24 24"
    fill="var(--blue)"
  >
    <defs>
      <style>
        {
          ".cls-1{fill:none;stroke:#020202;stroke-miterlimit:10;stroke-width:1.91px}"
        }
      </style>
    </defs>
    <path
      d="M9.14 4.39h11.45A1.91 1.91 0 0 1 22.5 6.3v11.45a1.91 1.91 0 0 1-1.91 1.91H9.14V4.39Z"
      className="cls-1"
    />
    <rect
      width={7.64}
      height={20.05}
      x={1.5}
      y={2.48}
      className="cls-1"
      rx={1.96}
    />
    <path
      d="M12 12.02h1.91M14.86 12.02h1.91M17.73 12.02h1.91M12 15.84h1.91M14.86 15.84h1.91M17.73 15.84h1.91M12 8.2h7.64"
      className="cls-1"
    />
  </svg>
    )
  }
}

export default CardTelSvg
