import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getIALExams } from '../../actions/AdminActions';
import Card1 from '../../components/cards/Card1';
import { getType } from '../../actions/getRole';
import HeaderButtons from '../../components/HeaderButtons';
import { changeStateValue } from '../../redux/MainReducer';
import { getLessons } from '../../actions/MainAction';
import EmptyPage from '../../components/EmptyPage';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Search from '../../components/Search';
import {insertNewData, deleteData} from "../../actions/MainAction"
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
export class AdminIalExams extends Component {
    state={
        page:1,
        search:'',
        active_type:'',
        activePage:0
    }
    componentDidMount(){
        this.props.getIALExams(1, '', '');
        window.scrollTo(0,0)
    }
    openModal=(data="")=>{
 
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                course_id:data?.course_id? data?.course_id:'',
                essay_type:data?.essay_type? data?.essay_type:'',
                lesson_id:data?.lesson_id? data?.lesson_id:'',
                prompt:data?.prompt? data?.prompt:'',
                question:data?.question? data?.question:'',
                title:data?.title? data?.title:'',
                grade_prompt:data?.grade_prompt? data?.grade_prompt:'',
                afterCallFunction:this.props.getIALExams,
                afterCallParams:[this.state.page, this.state.search, this.state.active_type],
                typeOptions: [{label: "Unit", value: "unit"}, {label: "Mid, End of Course", value: "midterm_end_course"}],
                requireds:["course_id", "essay_type", "lesson_id", "title", "question"],
                url: data?.id? `new-essays/${data?.id}`:'new-essays',
                modalTitle: data?.id? `Edit IAL Exams`:'Add IAL Exams',
                essay_image: data?.essay_image?data?.essay_image:'',
                image_full_url: data?.image_full_url?data?.image_full_url:'',
            }
        });
        if(data?.course_id){
            this.props.getLessons(data?.course_id)
        }
        // this.props.getLessons(49)
    }
    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getIALExams(page, this.state.search, this.state.active_type)
    }
    onSearchData=(search)=>{
        this.setState({search:search});
        this.props.getIALExams(this.state.page, search, this.state.active_type)
    }
    changeStatus=(data, status)=>{
        confirmAlert("warning", "Are you sure?", "Status will change", "Yes, change it")
        .then(alertResp=>{
          if(alertResp === "success"){
            let formData = new FormData();
            formData.append("active", status==1?'0':'1');
            formData.append("_method", "put");
            this.props.insertNewData(`new-essays/${data?.id}`, formData)
            .then(resp=>{
              if(resp=="success"){
                mixinAlert("success", "Status change successfully")
                this.props.getIALExams(this.state.page, this.state.search, '', '', '', this.state.active_type)
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }
        })
      }
      onDeleteData=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
          if(alertResp === "success"){
            this.props.deleteData(`new-essays/${data?.id}`)
            .then(resp=>{
              if(resp == "success"){
                this.props.getIALExams(this.state.page, this.state.search, '', '', '', this.state.active_type);
                mixinAlert("success", "Data deleted successfully")
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }
        })
      }
      getDataByStatus=(data, index)=>{
        this.setState({activePage:index, active_type:data?.id});
        this.props.getIALExams(this.state.page, this.state.search, '', '', '', data?.id)
      }
  render() {
    const {adminIalExamsData, user} = this.props;
    const {activePage} = this.state;
    // console.log(adminIalExamsData)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="IAL Exams" />
            <div className='mt-[30px] flex justify-between max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {
                    user?.role === "super_admin"?
                    <HeaderButtons onClick={(data, index)=>this.getDataByStatus(data, index)} buttons={[{title: "All", id:''},{title: "Inactives", id:'0'}]} selected={activePage} />:<div></div>
                }
                <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add IAL Exam", id:0}]} selected={0} />
            </div>
            <div className='flex justify-center '>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            {adminIalExamsData.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No results found" subTitle="" /></div>:null}

            <div className='mt-[30px] grid-full-250'>
                {
                    adminIalExamsData.map((data, i)=>{
                        return(
                            <Card1 
                                key={i}
                                title={data?.title}
                                cheating={data?.course?.title}
                                status={1}
                                statusBisible={false}
                                close={getType(data?.essay_type)}
                                btn={false}
                                btn2={true}
                                btn2Text={"Edit"}
                                btn2Color={"bg-gray-400"}
                                onCLickBtn={this.openModal.bind(this, data)}
                                activeStatus={data?.active}
                                adminActiveVisible={user?.role === "super_admin"}
                                onClickChangeStatus={this.changeStatus.bind(this, data)}
                                adminDelete={user?.role === "super_admin"}
                                onDeleteBtn={this.onDeleteData.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
            {
                adminIalExamsData?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={adminIalExamsData?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminIalExamsData: state.Data.adminIalExamsData,
    user: state.Data.user,
});
const mapDispatchToProps = {getIALExams, changeStateValue, getLessons, insertNewData, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminIalExams)
