import React, { Component } from 'react'

export class CardTimeSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
      >
        <path
          fill="#1E398F"
          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14ZM8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2Z"
        />
        <path
          fill="#1E398F"
          d="M10.295 11 7.5 8.205V3.5h1v4.29l2.5 2.505-.705.705Z"
        />
      </svg>
    )
  }
}

export default CardTimeSvg
