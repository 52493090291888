import React, { Component } from 'react'
// import img from "../../../images/center.png"
import img from "../../../images/center2.jpeg"
// import img from "../../../images/centerImg2.png"
import LocationSvg from '../svg/LocationSvg'
import FbSvg from '../svg/FbSvg'
import InstSvg from '../svg/InstSvg'
import PhoneSvg from '../svg/PhoneSvg'
import TelSvg from '../svg/TelSvg'
import WpSvg from '../svg/WpSvg'
import { Link } from 'react-router-dom'
export class PublicCard3 extends Component {
  render() {
    const {data} = this.props
    return (
      <div className='shadow rounded-[10px] pb-[36px]'>
        <img src={img} alt="center" className='w-full h-[206px] rounded-t-[10px]' />
        <div className='flex flex-col items-center'>
            <div>
                <h3 className='mt-[33px] text-[24px] font-bold'>{data?.title}</h3>
                <div className='flex justify-center mt-[7px] gap-[6px]'>
                    <a href={data?.location}>
                        <LocationSvg />
                    </a>
                    <a href={data?.facebook}>
                        <FbSvg />
                    </a>
                    <a href={data?.instagram}>
                        <InstSvg />
                    </a>
                </div>
            </div>
            <div className='flex gap-[8px] mt-[24px] w-full justify-center pl-[20px] pr-[20px]'>
                <a href={`tel:${data?.phone_number}`} className='inline-flex gap-[6px] p-[7px] rounded-[12px] border border-[var(--blue)] w-1/2 justify-center'>
                    <span className='bg-blue inline-flex w-[16px] h-[16px] rounded-full justify-center items-center'>
                        <PhoneSvg />
                    </span>
                    <span className='text-[#3E3B3B] font-medium text-[12px]'>Call Now</span>
                </a>
                {/* <a  href={`tel:${data?.office_number}`} className='inline-flex gap-[6px] p-[7px] rounded-[12px] border border-[var(--blue)]'>
                    <span  className='bg-[#A30D11] inline-flex w-[16px] h-[16px] rounded-full justify-center items-center'>
                        <TelSvg />
                    </span>
                    <span className='text-[#3E3B3B] font-medium text-[12px]'>Call Now</span>
                </a> */}
                <a  href={`https://wa.me/${data?.phone_number?.replace('(', '')?.replace(')', '')?.replace('0', '+994').replaceAll(" ", "")}`} className='inline-flex gap-[6px] p-[7px] rounded-[12px]  w-1/2 border justify-center border-[var(--blue)]'>
                    <span  className='bg-[#4DDA81] inline-flex w-[16px] h-[16px] rounded-full justify-center items-center'> 
                        <WpSvg />
                    </span>
                    <span className='text-[#3E3B3B] font-medium text-[12px]'>Write a message</span>
                </a>
            </div>
            <div className=' pl-[20px] pr-[20px] w-full'>
                <Link className='bg-blue mt-[20px] w-full block text-center h-[30px] inline-flex justify-center items-center text-white font-medium text-[12px] rounded-[12px]' to={`/centers/${data?.id}`}>View Center page</Link>
            </div>
        </div>
      </div>
    )
  }
}

export default PublicCard3
