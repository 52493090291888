import React, { Component } from 'react'
import ToCard from './ToCard'
import FromCard from './FromCard'
import { connect } from 'react-redux'
import BoxHeader from './BoxHeader'
import BoxFooter from './BoxFooter'
import moment from 'moment'
import { getColor, getRole } from '../../actions/getRole'
import ReplyContainer from './ReplyContainer'
import MsgFilePreview from './MsgFilePreview'
import MsgRecordAudio from './MsgRecordAudio'
import RecycleSvg from '../svg/RecycleSvg'
import { getGroupMsgBoxData, getMsgBoxData} from '../../actions/MessageAction'
export class MessageBody extends Component {
    state={
        reply:'',
        deletedMsg:[],
        recording:false,
        page:1,
        lineHeight:1
    }
    replyBtn=(data)=>{
        this.setState({reply:data})
    }
    loadMoreMsg=(page)=>{
        let inc = page;
        inc++;
        this.setState({page:inc})
        if(this.props.msgBoxInfo.type === "group"){
            this.props.getGroupMsgBoxData(this.props.msgBoxInfo?.id, inc);
        }else{
            this.props.getMsgBoxData(this.props.msgBoxInfo?.id, inc);
        }
    }
    
    componentDidUpdate(prevProps, prevState){
        if(prevProps.msgBoxInfo !== this.props.msgBoxInfo){
            this.setState({reply:'', page:1});
        }
    }

    getHeight(reply, lineHeight){
        // reply === "" && lineHeight ===1?'68vh': `calc(68vh - ${lineHeight==1?'0px':lineHeight==2? '25px': '50px'})`, overflow:'auto'
        if(reply == '' && lineHeight ==1){
            return '68vh'
        }else if(reply =="" && lineHeight !=1){
            if(lineHeight==2){
                return 'calc(68vh - 30px)'
            }else  if(lineHeight > 2){
                return 'calc(68vh - 60px)'
            }
        }else if(reply !="" && lineHeight ==1){
            return 'calc(68vh - 70px)'
        }else if(reply !="" && lineHeight !=1){
            if(lineHeight==2){
                return 'calc(68vh - 100px)'
            }else  if(lineHeight > 2){
                return 'calc(68vh - 130px)'
            }
        }
    }
  render() {
    const {msgBoxData, user, msgBoxInfo, messageFileSend, visible, lang} = this.props;
    const {reply, recording, page, lineHeight} = this.state;
   
    return (
      <div className='bg-white rounded-[12px] w-full p-[16px] pb-[0px] flex flex-col'>
        {
            msgBoxInfo===""?
            <div className='w-full h-[90vh] flex items-center justify-center'>
                <h2 className='txt-blue text-[20px] font-semibold'>{lang?.please_select_group}</h2>
            </div>:
            <>
            <BoxHeader visible={visible} data={msgBoxInfo} />
            <div style={{ height: this.getHeight(reply, lineHeight), overflow:'auto'}} className='bg-[#F5F5F5] rounded-[12px] w-full p-[16px] mt-[10px] flex flex-col-reverse'>
                <div className='flex gap-[25px] flex-col-reverse'>
                  
                    {
                        msgBoxData?.messages?.map((data, i)=>{
                            if(data?.user_id == this.props.user?.id){
                                return(
                                    <FromCard 
                                        key={i}
                                        userImg={user?.avatar_full_url}
                                        msg={data?.message}
                                        date={moment(data?.created_at).format("DD/MM/YYYY HH:mm")}
                                        forward={data?.replied !== null}
                                        forwardUser={data?.replied?.replied_message?.user?.full_name}
                                        forwardMsg={data?.replied?.replied_message?.message}
                                        forwardColor={getColor(data?.replied?.replied_message?.user?.role)}
                                        msgImage={data?.image_full_url}
                                        msgAudio={data?.audio_full_url}
                                        msgPdf={data?.file_full_url}
                                        onReply={this.replyBtn.bind(this, data)}
                                        data={data}
                                        readStatus={data?.student_status==0 && data?.teacher_status==0}
                                    />
                                ) 
                            }else{
                                return(
                                    <ToCard 
                                        key={i}
                                        userImg={data?.user?.avatar_full_url}
                                        msg={data?.message}
                                        date={moment(data?.created_at).format("DD/MM/YYYY HH:mm")}
                                        forward={data?.replied !== null}
                                        userName={data?.user?.full_name}
                                        role={getRole(data?.user?.role,lang)}
                                        forwardUser={data?.replied?.replied_message?.user?.full_name}
                                        forwardMsg={data?.replied?.replied_message?.message}
                                        color={getColor(data?.user?.role)}
                                        forwardColor={getColor(data?.replied?.replied_message?.user?.role)}
                                        msgImage={data?.image_full_url}
                                        msgAudio={data?.audio_full_url}
                                        msgPdf={data?.file_full_url}
                                        onReply={this.replyBtn.bind(this, data)}
                                        data={data}
                                    />
                                ) 
                            }
                            
                        })
                    }
                    {
                        msgBoxData.count >msgBoxData.messages.length?
                        <div className='flex justify-center'>
                            <button onClick={this.loadMoreMsg.bind(this, page)}>
                                <RecycleSvg />
                            </button>
                        </div>:null
                    }
                      
                </div>
            
            </div>
            {
                reply !==""?
                <ReplyContainer color={getColor(reply?.user?.role)} name={reply?.user?.id === user?.id? "You":reply?.user?.full_name} msg={reply?.message} onClose={()=>this.setState({reply:''})} />:null
            }
            {
                !recording?
                <BoxFooter setLineHeight={(count)=>this.setState({lineHeight:count})} lineHeight={lineHeight} onClickRecord={()=>{this.setState({recording:true});this.setState({reply:''})} } onCloseReply={()=>this.setState({reply:''})} reply={reply} />:
                <MsgRecordAudio reply={reply} data={msgBoxInfo} onClickRecord={()=>this.setState({recording:false})} />
            }
        
            {messageFileSend?.position?<MsgFilePreview onClose={()=>{this.setState({reply:''})}} />:null }

        </>
        }
       
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    msgBoxData: state.Data.msgBoxData,
    user: state.Data.user,
    msgBoxInfo: state.Data.msgBoxInfo,
    messageFileSend: state.Data.messageFileSend,
    lang: state.Data.lang,

})
const mapDispatchToProps = {getMsgBoxData,getGroupMsgBoxData }
export default connect(mapStateToProps, mapDispatchToProps)(MessageBody)
