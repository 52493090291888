import React, { Component } from 'react'

export class PdfSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={13}
        height={15}
        className='min-w-[13px]'
        fill="none"
      >
        <path
          fill="#1E398F"
          d="M7.915.417H2.25A1.417 1.417 0 0 0 .832 1.834v11.333a1.417 1.417 0 0 0 1.417 1.417h8.5a1.416 1.416 0 0 0 1.416-1.417v-8.5L7.915.417ZM4.726 10.468c-.218.206-.541.298-.918.298-.072 0-.145-.004-.218-.013v1.01h-.633V8.975c.286-.043.575-.062.863-.058.395 0 .676.075.865.226.18.143.301.377.301.654s-.092.512-.26.671Zm2.697.96c-.297.247-.75.365-1.303.365-.332 0-.566-.021-.726-.043V8.976a5.63 5.63 0 0 1 .864-.059c.536 0 .885.096 1.157.302.294.218.478.566.478 1.065 0 .54-.198.914-.47 1.144Zm2.617-1.966H8.955v.646H9.97v.52H8.955v1.136h-.642V8.938h1.727v.524ZM7.915 5.375h-.708V1.834l3.542 3.541H7.915Z"
        />
      </svg>
    )
  }
}

export default PdfSvg
