import React, { Component } from 'react'
import { connect } from 'react-redux'

export class SectionPass extends Component {
  render() {
    const {part, studentToeflSpeakingExam, continueBtn} = this.props
    return (
      <div className='toefl_speaking_pass_section'> 
            <h2>Click to initialize <b>Speaking Task {part}</b> automated testing</h2>
            <button onClick={()=>continueBtn(studentToeflSpeakingExam)}>BEGIN TEST</button>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentToeflSpeakingExam: state.Data.studentToeflSpeakingExam
})
export default connect(mapStateToProps)(SectionPass)