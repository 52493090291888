import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getSpPracticeReviews } from '../../actions/MainAction';
import Card1 from '../../components/cards/Card1';
import moment from 'moment';
import { changeStateValue } from '../../redux/MainReducer';
import { getRole } from '../../actions/getRole';
import EmptyPage from '../../components/EmptyPage';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Search from '../../components/Search';
export class SpPracticeReviews extends Component {
    state={
        page:1,
        keyword: ''
    }
    componentDidMount(){
        this.props.getSpPracticeReviews('', 1, '');
        window.scrollTo(0,0)
    }
    openModal=(data)=>{
      
        const params = {
            position:true,
            type:'sp_practice',
            modalType: 'review',
            title: data?.speaking?.title,
            question: data?.speaking?.question,
            reviews: `<pre class="break-words whitespace-break-spaces">${data?.robo_review}</pre>`,
            teacher:'Selty',
            role: 'Teacher',
            stuent: data?.user?.full_name,
            grade: data?.robo_grade?data?.robot_grade:data?.teacher_grade,
        }
        this.props.changeStateValue({name: 'mainModal', value: params});

    }
    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getSpPracticeReviews('', page, this.state.search);
    }
    onSearchData=(search)=>{
        this.setState({search:search})
        this.props.getSpPracticeReviews('', this.state.page, search);
    }

   
  render() {
    const {speakingPracticeReviews, lang} = this.props;
    // console.log(speakingPracticeReviews)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="sp_prc_rws" />
            <div className='flex justify-center  max-[1100px]:mt-[120px] max-[800px]:mt-[170px] '>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            {speakingPracticeReviews?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250 '>
                {
                    speakingPracticeReviews?.map((data, i)=>{
                       
                        return(
                            <Card1
                                key={i}
                                title={data?.speaking?.title}
                                date={moment(data?.created_at).format("DD/MM/YYYY")}
                                grade={data?.robo_grade?data?.robo_grade:data?.teacher_grade}
                                status={data?.speaking?.course_id==36?1: +data?.robo_grade >=data?.speaking?.course_prompt?.grade?1:0}
                                onCLickBtn={this.openModal.bind(this, data)}
                                statusText={+data?.robo_grade >=data?.speaking?.course_prompt?.grade ?lang?.pass: lang?.fail}
                                exam={data?.speaking?.course?.title}
                                statusBisible={data?.speaking?.course_id==36?false:true}
                            />
                        )
                    })
                }
            </div>
            {
                speakingPracticeReviews?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={speakingPracticeReviews?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    speakingPracticeReviews: state.Data.speakingPracticeReviews,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getSpPracticeReviews, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SpPracticeReviews)
