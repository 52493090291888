import React, { Component } from 'react'
import Header from './Header';
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import Audio from '../exams/Audio';
import ToeflModalParts from './components/ToeflModalParts';
export class ToelfModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.changeStateValue({
            name:'toeflModalData',
            value:{position:false}
        })
    }
    saveBtn=(data)=>{

    }
  render() {
    const {toeflModalData, user} = this.props;
    const {btnDisabled} = this.state;
    return (
        <div className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[900px]  shadow bg-white rounded-[10px] max-h-[70vh]'>
                <Header closeBtn={this.closeModal.bind(this)} title={toeflModalData?.exam?.title} />
                <div style={{ height:'auto', maxHeight: 'calc(70vh - 50px)' }} className='p-[20px] overflow-auto '>
                    
                    <div className='flex flex-col gap-[30px]'>
                        <ToeflModalParts 
                            title={toeflModalData?.part_1_reading_title}
                            description={toeflModalData?.part_1_reading} 
                            listening_title={toeflModalData?.part_1_listening_title}
                            listening_url={toeflModalData?.part_1_listening_full_url}
                            score={toeflModalData?.part_1_score}
                            modalType={toeflModalData?.modalType}
                            answer={toeflModalData?.part_1_answer}
                            answer_url={toeflModalData?.part_1_answer_full_url}
                            part="Part 1"
                        />
                        <ToeflModalParts 
                            title={toeflModalData?.part_2_reading_title}
                            description={toeflModalData?.part_2_reading_description} 
                            listening_title={toeflModalData?.part_2_listening_title}
                            listening_url={toeflModalData?.part_2_listening_full_url}
                            score={toeflModalData?.part_2_score}
                            modalType={toeflModalData?.modalType}
                            answer={toeflModalData?.part_2_answer}
                            answer_url={toeflModalData?.part_2_answer_full_url}
                            speaking_question={toeflModalData?.part_2_speaking_question}
                            part="Part 2"
                        />
                        {
                            toeflModalData?.modalType==="speaking"?
                            <ToeflModalParts 
                                title={toeflModalData?.part_3_reading_title}
                                description={toeflModalData?.part_3_reading_description} 
                                listening_title={toeflModalData?.part_3_listening_title}
                                listening_url={toeflModalData?.part_3_listening_full_url}
                                score={toeflModalData?.part_3_score}
                                modalType={toeflModalData?.modalType}
                                answer={toeflModalData?.part_3_answer}
                                answer_url={toeflModalData?.part_3_answer_full_url}
                                speaking_question={toeflModalData?.part_3_speaking_question}
                                part="Part 3"
                            />:null
                        }
                        {
                            toeflModalData?.modalType==="speaking"?
                            <ToeflModalParts 
                                listening_url={toeflModalData?.part_4_listening_full_url}
                                score={toeflModalData?.part_4_score}
                                modalType={toeflModalData?.modalType}
                                answer={toeflModalData?.part_4_answer}
                                answer_url={toeflModalData?.part_4_answer_full_url}
                                speaking_question={toeflModalData?.part_4_speaking_question}
                                part="Part 4"
                            />:null
                        }
                        
                    </div>
                    
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Close</button>
                        {
                            user?.role !== "company_head" && user?.role !== "office_manager"?
                            <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,toeflModalData)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Save</button>:null
                        }
                    </div>
                </div>
            
            </div>
        
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    toeflModalData: state.Data.toeflModalData,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ToelfModal)
