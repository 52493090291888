
export const avatarAppConfig = {
    cogSvcRegion : "westeurope",
    cogSvcSubKey : "5abfc42f16854c88812aa53e27a79fee",
    voiceName : "en-GB-SoniaNeural",
    avatarCharacter : "meg",
    avatarStyle : "business",
    avatarBackgroundColor : "#FFFFFFFF",
    iceUrl : "stun:relay.communication.microsoft.com:3478",
    iceUsername : "YOUR_ICE_USERNAME",
    iceCredential : "YOUR_ICE_CREDENTIAL"
    }