import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Parent from '../../components/exams/Parent'
import { connect } from 'react-redux';
import { getExamStart, getHWId, insertNewDataV2 } from '../../actions/MainAction';
import {jwtDecode} from "jwt-decode";
import { withHooks } from '../../actions/withHooks';
import { bigAlert, mixinAlert } from '../../actions/SweetAlerts';
import { changeStateValue } from '../../redux/MainReducer';
export class StartHomework extends Component {
  state={
    title:"",
    hwId:'',
    btnDisable:false,
    openIndex:111
  }
  componentDidMount(){
    this.props.changeStateValue({name:'examsData', value:[]})
    if(localStorage.getItem('userToken')){
      const data = jwtDecode(localStorage.getItem('userToken'));
      this.props.getExamStart(this.props.params.id, data.sub);
      this.props.getHWId(this.props.params.id, data.sub)
      .then(resp=>{
        // console.log(resp)
        if(resp == "error"){
          this.props.navigate("/")
        }else{
           this.setState({title: resp?.exam_title, hwId: resp?.id})
        }
       
      })
    }
    window.scrollTo(0,0)
  }
  componentWillUnmount(){
    this.props.changeStateValue({name:'examsData', value:[]})
  }
  submitExamData(data, hwId){
   
    if(localStorage.getItem('userToken')){
      this.setState({btnDisable:true});
      const jwt = jwtDecode(localStorage.getItem('userToken'));
      let submitExam = {
        studentExam_id: hwId,
        exam_id: `${this.props.params.id}`,
        student_id: jwt.sub,
        student_exam_parent_questions: data
      }

      this.props.insertNewDataV2("student-homework-submit", submitExam)
      .then(resp=>{
      this.setState({btnDisable:false});

        if(resp === "success"){
          bigAlert(
            "Done!",
            "PT submitted successfully",
            "success"
          )
          this.props.navigate("/homework-results")
        }else{
          mixinAlert("error", "Something went wrong");
        }
      })
    }
   
  }
  render() {
    const {examsData, lang} = this.props;
    const {title, btnDisable, hwId, openIndex} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Start now" />
            <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
               <div className='flex items-center justify-center'>
                    <h3 className='font-bold txt-light-black text-[28px] text-center bg-white pl-[57px] inline-flex items-center pr-[57px] h-[83px] rounded-[12px]'>{title}</h3>
               </div>
               <div className='flex flex-col gap-[10px] mt-[20px]'>
                {
                  examsData?.map((data, i)=>{
                    return(
                      <Parent openPosition={openIndex===i} index={i} onClickOpen={(index)=>this.setState({openIndex:index})} key={i} parentIndex={i} data={data} status={data?.status} />
                    )
                  })
                }
               </div>
               {
                 examsData.length !== 0 && !(examsData?.find(data=> data.status === "Unanswered") || examsData?.find(data=> data.status === "Partially Answered"))?
                 <div className='mt-[20px] flex justify-center items-center'>
                    <button disabled={btnDisable} onClick={this.submitExamData.bind(this, examsData, hwId)} className='bg-blue w-[140px] h-[30px] text-[14px] rounded-[6px] text-white font-semibold'>{lang?.submit}</button>
                </div>:
                 null
              
               }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  examsData: state.Data.examsData,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getExamStart, getHWId, insertNewDataV2, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StartHomework))
