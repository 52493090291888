import React, { Component } from 'react'
import DropSearch from '../../../../../components/DropSearch'
import { connect } from 'react-redux';
import { getCoursesByStudent } from '../../../../../actions/StaffAction';
import { withHooks } from '../../../../../actions/withHooks';
import { getLessons } from '../../../../../actions/MainAction';
import Card2 from '../../../../../components/cards/Card2';
export class CourseLessonDrop extends Component {
    state={
        courses:[],
        lessons:[],
        courseId:'',
    }
    componentDidMount(){
        this.props.getCoursesByStudent(this.props.params.id)
        .then(resp=>{
            this.setState({courses:resp})
        })
        const check = window.location.search?.includes("course_id");
        if(check){
            const course_id = window.location.search?.split("=")[1];
            this.setState({courseId: course_id})
            this.props.getLessons(course_id)
            .then(resp=>{
                this.setState({lessons: resp})
            })
        }
    }
    onSelectCourse=(data)=>{
        this.setState({courseId: data?.value})
        this.props.getLessons(data?.value)
        .then(resp=>{
            this.setState({lessons: resp})
        })
    }
    onSelectLesson=(data)=>{
        this.props.onSelectData({lessonId:data?.value, courseId:this.state.courseId})
    }
    getCoursePage(){
        const courseVisible = window.location.search?.includes("select_course");
        if(courseVisible){
            return true
        }else{
          return false;
        }
    }
    getLessonPage(){
        const check = window.location.search?.includes("course_id");
        if(check){
            return true;
        }else{
            return false;
        }
    }
  render() {
    
    const {courses, lessons, courseId} = this.state;
    const {onlyMidEnds=false, lang, user, studentType=0} = this.props;
    let courseOption = [];
    let lessonOption = [];
    for(const data of courses){
        courseOption.push({
            label: data?.title,
            value: data?.id
        })
    }
    // console.log(lessons)
    for(const data of lessons){
        if(onlyMidEnds){
            if(user?.role =="support"){
                if(data.type == "end"){
                    lessonOption.push({
                        label: data?.title,
                        value: data?.id
                    })}
                
            }else{
                
                // && (courseId == 2 || courseId == 5 || courseId == 6 || courseId == 7 || courseId == 8 || courseId == 9)
                if(studentType!=0 ){
                    if(data.type == "mid"){
                        lessonOption.push({
                            label: data?.title,
                            value: data?.id
                        })
                    }
                }else{
                    if(data.type == "mid" || data.type == "end"){
                        lessonOption.push({
                            label: data?.title,
                            value: data?.id
                        })
                    }
                }
            }
            
            
        }else{
           
            if(user?.role =="support"){
                if(data.type == "end"){
                    lessonOption.push({
                        label: data?.title,
                        value: data?.id
                    })}
                
            }else{
               
                // && (courseId == 2 || courseId == 5 || courseId == 6 || courseId == 7 || courseId == 8 || courseId == 9)
                if(studentType!=0 ){
                    if(data.type !== "end"){
                        lessonOption.push({
                            label: data?.title,
                            value: data?.id
                        })
                    }
                }else{
                    lessonOption.push({
                        label: data?.title,
                        value: data?.id
                    })
                }
                
            }
            
        }
           
       
    }
// console.log(lessonOption)
    return (
        <div className='grid grid-full-205'>
            { this.getCoursePage()?
                courseOption?.map((data, i)=>{
                    return(
                        <Card2 
                            key={i}
                            title={data?.label}
                            btnTitle={"View"}
                            onClickBtn={this.onSelectCourse.bind(this, data)}
                            btnUrl={`${window.location.pathname}?course_id=${data?.value}`}
                        />
                    )
                }):null
            }
            {
                this.getLessonPage()?
                lessonOption?.map((data, i)=>{
                    return(
                        <Card2 
                            key={i}
                            title={data?.label}
                            btnTitle={"View"}
                            onClickBtn={this.onSelectLesson.bind(this, data)}
                            // btnUrl={`${window.location.pathname}?course_id=${courseId}&lesson_id=${data?.value}`}
                        />
                    )
                }):null
            }
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {getCoursesByStudent, getLessons}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(CourseLessonDrop))
