import React, { Component } from 'react'

export class EssaySvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
      >
        <path
          fill="#585874"
          d="M6.188 6.75a.563.563 0 0 1 .562-.563h4.5a.562.562 0 1 1 0 1.125h-4.5a.563.563 0 0 1-.563-.562Zm.562 2.813h4.5a.562.562 0 1 0 0-1.126h-4.5a.563.563 0 1 0 0 1.126ZM9 10.687H6.75a.563.563 0 0 0 0 1.126H9a.563.563 0 0 0 0-1.126Zm6.75-7.312v7.642a1.115 1.115 0 0 1-.33.796l-3.607 3.607a1.116 1.116 0 0 1-.796.33H3.375a1.125 1.125 0 0 1-1.125-1.125V3.375A1.125 1.125 0 0 1 3.375 2.25h11.25a1.125 1.125 0 0 1 1.125 1.125ZM3.375 14.625h7.313V11.25a.562.562 0 0 1 .562-.563h3.375V3.375H3.375v11.25Zm8.438-2.813v2.019l2.017-2.018h-2.018Z"
        />
      </svg>
    )
  }
}

export default EssaySvg
