import React, { Component } from 'react'

export class ImgSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={12}
            height={10}
            fill="none"
        >
            <path
            fill="#fff"
            d="M10.668.333H1.335C.69.333.168.857.168 1.5v7c0 .643.523 1.167 1.167 1.167h9.333c.643 0 1.167-.524 1.167-1.167v-7c0-.643-.524-1.167-1.167-1.167Zm-7.875 1.75a.875.875 0 1 1 0 1.75.875.875 0 0 1 0-1.75Zm3.208 5.834H1.918L4.251 5l.875 1.167 1.75-2.334 3.209 4.084H6Z"
            />
        </svg>
    )
  }
}

export default ImgSvg
