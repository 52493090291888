export const checkLevel = (section1, section2, section3) => {

    if(section1 && !section2 && !section3){
        if(section1 >=50){
            return {title: `${section1}`, grade: section1}
        }else{
            return {title: `${section1}`, grade: section1}
        }
    }else if(section1 && section2 && !section3){
        if(section2 >=50){
            return {title: `${Math.round((section1 + section2)/2)}`, grade: section1 + section2 }
        }else{
            return {title: `${Math.round((section1 + section2)/2)}`, grade: section1 + section2 }
        }
    }else if(section1 && section2 && section3){
        if(section3 >=70){
            return {title: `${Math.round((section1 + section2 + section3)/3)}`, grade: section1 + section2 + section3}
        }else if(section3 <70 && section3 >=50){
            return {title: `${Math.round((section1 + section2 + section3)/3)}`, grade: section1 + section2 + section3}
        }else if(section3 <50){
            return {title: `${Math.round((section1 + section2 + section3)/3)}`, grade: section1 + section2 + section3}
        }
    }
}

export const getOverall = (reading, listening, speaking, writing) => {
    let total = reading + listening + speaking + writing;
    if(total >=1000){
        return 'C1'
    }else if(total <1000 && total >=800){
        return 'B2'
    }else if(total <800 && total >=600){
        return 'B1+'
    }else if(total <600 && total >=400){
        return 'B1'
    }else if(total <400 && total >=200){
        return 'A2'
    }else if(total <200 && total >=0){
        return 'A1'
    }
}

export const partExamsScore = (level, score) => {
    if(level ==1){
        if(score <80){
            return {
                type: "complete",
                level:1
            }
        }else if(score >=80){
            return {
                type: "contuniue",
                level: 1
            }
        }
       
    }else if(level ==2){
        if(score <80){
            return {
                type: "complete",
                level:2
            }
           
        }else if(score >=80){
            return {
                type: "contuniue",
                level: 2
            }
        }
    }else if(level == 3){
       
        return {
            type: "complete",
            level:3
        }
    }
}