import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import Header from '../../../components/modals/Header';
import Input from '../../../components/modals/components/Input';
import ExamSettings from './ExamSettings';
import { insertNewData } from '../../../actions/MainAction';
import { mixinAlert } from '../../../actions/SweetAlerts';

export class ExamModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"examPoolingModaldata"})
    }

    examSaveBtn=(data)=>{
        console.log(data)
        if(data?.title !=""){
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }

            formData.append("standart_exam_settings", JSON.stringify(data?.standart_exam_settings))
            let url = "pooling-exam";
            if(data?.id && data?.id !=""){
                url = `pooling-exam/${data?.id}`
                formData.append("_method", "put")
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                if(resp == "success"){
                    mixinAlert("success", "Data added successfully");
                    this.closeModal();
                    data?.getAfterFunction(...data?.afterParams)
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }
    }

    addItem=(arr)=>{
        let new_arr = [...arr];
        new_arr.push({
            id:'',
            category_id:'',
            sub_category_id:'',
            section_id:'',
            count:''
        });
        this.props.changeStateValue({
            name:"examPoolingModaldata.standart_exam_settings",
            value:new_arr
        })
    }
  render() {
    const {examPoolingModaldata, lang} = this.props;
    const {btnDisabled}= this.state;

    console.log(examPoolingModaldata)
    return (
      <div className='background_shadow flex justify-center items-center'>
         <div className='bg-white w-[700px]  shadow bg-white rounded-[10px] max-h-[90vh]'>
            <Header closeBtn={this.closeModal.bind(this)} title={examPoolingModaldata?.modalTitle} />
            <div style={{ height:'auto', maxHeight: 'calc(90vh - 50px)' }} className='p-[20px] overflow-auto '>
                <div className='grid grid-cols-2 max-[700px]:grid-cols-1 gap-[20px]'>
                    <Input  
                        name="examPoolingModaldata.title"
                        value={examPoolingModaldata?.title}
                        title={"Title"}
                        required
                    />
                    <Input  
                        name="examPoolingModaldata.duration_minutes"
                        value={examPoolingModaldata?.duration_minutes}
                        title={"Duration minutes"}
                        type="number"
                        required
                    />
                    <Input  
                        name="examPoolingModaldata.retake_minutes"
                        value={examPoolingModaldata?.retake_minutes}
                        title={"Retake minutes"}
                        type="number"
                        required
                    />
                    <Input  
                        name="examPoolingModaldata.retake_time"
                        value={examPoolingModaldata?.retake_time}
                        title={"Retake time"}
                        type="number"
                        required
                    />
                    <Input  
                        name="examPoolingModaldata.points"
                        value={examPoolingModaldata?.points}
                        title={"Points"}
                        type="number"
                        required
                    />
                </div>
                <div>
                    <div className='mt-[20px] flex flex-col gap-[20px] bg-gray-100 p-[10px] rounded-[10px] max-h-[40vh] overflow-auto'>
                        {
                            examPoolingModaldata?.standart_exam_settings?.map((data, i)=>{
                                if(!data?.deleted){
                                    return (
                                        <ExamSettings 
                                            category_id={data?.category_id}
                                            sub_category_id={data?.sub_category_id}
                                            section_id={data?.section_id}
                                            count={data?.count}
                                            index={i}
                                            key={i}
                                            data={data}
                                            allData={examPoolingModaldata?.standart_exam_settings}
                                        />
                                    )
                                }
                            })
                        }
                    </div>
                    <div className='flex justify-center mt-[20px]'>
                        <button onClick={this.addItem.bind(this,examPoolingModaldata?.standart_exam_settings )} className='bg-blue text-white text-[14px] p-[5px_7px]  rounded-[7px]'>Add Setting +</button>
                    </div>
                </div>
                <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                    <button disabled={btnDisabled} onClick={this.examSaveBtn.bind(this,examPoolingModaldata)} className='bg-blue h-[23px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save_close}</button>
                </div>
            </div>

        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examPoolingModaldata: state.Data.examPoolingModaldata,
    lang: state.Data.lang,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(ExamModal)
