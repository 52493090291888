import React, { Component } from 'react'

export class CardPointSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
  >
    <path
      fill="#1E398F"
      fillRule="evenodd"
      d="M9.22 8.248c-.444-.462-.515-1.2-.448-1.819l.081-.69c.028-.229.056-.458.082-.688.082-.68.27-1.407.666-1.962a2.155 2.155 0 0 1 1.471-.882c.558-.073 1.174.05 1.57.481.43.464.548 1.178.452 1.814l-.051.411c-.04.323-.08.647-.116.968-.064.692-.281 1.4-.667 1.966-.345.501-.892.806-1.47.882-.557.078-1.162-.06-1.57-.481Zm1.287-2.64c-.04.323-.078.645-.112.97-.039.382.09.555.366.519.28-.037.429-.248.467-.63.035-.352.079-.704.122-1.057.043-.351.086-.704.12-1.057.04-.381-.089-.555-.37-.518-.276.037-.424.247-.463.628-.038.383-.084.764-.13 1.145ZM4.178 7.304c-.067.618.005 1.357.448 1.819.408.42 1.013.56 1.57.481a2.114 2.114 0 0 0 1.47-.882c.386-.567.603-1.274.667-1.966.036-.321.076-.645.116-.968l.05-.411c.097-.636-.02-1.35-.451-1.814-.396-.431-1.012-.554-1.57-.481a2.155 2.155 0 0 0-1.47.882c-.396.555-.585 1.281-.667 1.961l-.081.69c-.028.23-.056.46-.082.69Zm1.735-.82c.046-.382.092-.763.13-1.146.04-.381.188-.591.464-.628.28-.037.409.137.37.518-.035.353-.078.706-.121 1.057-.043.353-.086.705-.122 1.058-.038.381-.186.592-.467.629-.276.036-.405-.137-.366-.52.034-.323.073-.646.112-.969Zm-2.979 4.238.785-5.69s.219-1.095-.656-1.095c-.553 0-1.598 1.257-2.19 2.032a.664.664 0 0 0 .197.973.69.69 0 0 0 .842-.104l.057-.057-.533 3.73a.756.756 0 1 0 1.498.21Zm9.306-1.41a.438.438 0 0 1-.334.522l-7 1.53a.438.438 0 0 1-.187-.854l7-1.531a.438.438 0 0 1 .52.333Zm-1.094 1.748a.437.437 0 0 1-.33.523l-6.782 1.53a.437.437 0 1 1-.193-.852l6.782-1.531a.437.437 0 0 1 .523.33Z"
      clipRule="evenodd"
    />
  </svg>
    )
  }
}

export default CardPointSvg
