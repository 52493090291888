import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import PayModalBody from './components/PayModalBody';
export class PayyStudentEnrollModal extends Component {
    state={
        openCard:999
    }
    closeModal=()=>{

    }
  render() {
    const {lang, enrollPaymentsModal,} = this.props;
    const {openCard} = this.state
    return (
        <div  className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[600px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={lang?.make_pay} />
                <div className=' p-[20px] max-h-[70vh] overflow-auto flex flex-col gap-[20px]'>
                    {
                        enrollPaymentsModal?.map((data, i)=>{
                            return(
                                <div key={i} className='bg-gray-100 p-[6px] rounded-[10px]'>
                                    
                                        <PayModalBody openCardBtn={(index)=>this.setState({openCard:index})} index={i} openCard={openCard} data={data} enroll_id={data?.id} student_id={data?.student_id} />
                                   
                                </div>
                            )
                        })
                    }
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang,
    enrollPaymentsModal: state.Data.enrollPaymentsModal,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(PayyStudentEnrollModal)
