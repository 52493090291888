import React, { Component } from 'react'
import CalendarCell from './CalendarCell'
import LeftSvg from './svg/LeftSvg'
import RightSvg from './svg/RightSvg'
import CalendarSvg from './svg/CalendarSvg'
import moment from 'moment'
import CheckInModal from './modals/CheckInModal'
import PaymentModal from './modals/PaymentModal'
import { connect } from 'react-redux'
import PaymentNoteModal from './modals/PaymentNoteModal'
import AddCheckinModal from './modals/AddCheckinModal'
import { Link } from 'react-router-dom'

export class Calendar extends Component {
  state={
    days:[],
    date: new Date(),
    week_days:[
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    modal: {
      position:false,
      teacher:'',
      class: '',
      time: ''
    },
    paymentModal:{
      position:false,
      amount:1,
      company_id:'',
      event_id:'',
      student_id:'',
      teacher_id:undefined
    },
    noteModal:{
      position: false,
      date:'',
      student_id:'',
      note:''
    },
    addCheckinModal:{
      position:false,
      teacher_id:'',
      student_id:'',
      date:''
    }
  }

  renderCalendar(date){
    this.props.onChange(date)
    let days = [];
    const weeks = this.state.week_days;
    const startDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
    )
    const week = weeks.indexOf(String(startDay).split(" ")[0]);
    const prevMonthDays = new Date(
        date.getFullYear(),
        date.getMonth(),
        0
    ).getDate()
   

    for(let x = prevMonthDays - week+1; x<= prevMonthDays; x++){
        const prevDate = new Date(
            date.getFullYear(),
            date.getMonth()-1,
            x
        )
        days.push({
            type: 'prev',
            day:`${x}`,
            date: moment(prevDate).format("YYYY-MM-DD")
        })
    }


    const allDays = new Date(
        date.getFullYear(),
        date.getMonth()+1,
        0
    ).getDate()
   

    for(let i = 1; i<=allDays; i++){
        const allDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            i
        );
        days.push({
            type: 'current',
            day: `${i}`,
            date:moment(allDate).format("YYYY-MM-DD")
        })
    }

    // return next month days
    const nexDays = new Date(
        date.getFullYear(),
        date.getMonth()+1,
        0
    ).getDay()
    

    for(let j = 1; j<=(7 -nexDays -1); j++){
        const nexDate = new Date(
            date.getFullYear(),
            date.getMonth()+1,
            j
        )
        days.push({
            type:'next',
            day: `${j}`,
            date: moment(nexDate).format("YYYY-MM-DD")
        })
    }

    this.setState({days:days})
    return days;
  }
  componentDidMount(){
    this.renderCalendar(this.state.date)
  }
  nextMonth(date, onNextPrevBtn){
      let month = date.getMonth() + 1;
      date.setMonth(month);
      this.renderCalendar(date);
      onNextPrevBtn(date)
  }
  prevMonth(date, onNextPrevBtn){
      let month = date.getMonth() - 1;
      date.setMonth(month);
      this.renderCalendar(date);
      onNextPrevBtn(date)
  }
  
  openModalBtn=(data)=>{

    if(data?.event){
      this.setState({
        modal: {
          position:true,
          teacher:JSON.parse(data?.event)?.details?.split("</th><td>")[1].split("</td></tr><tr><th>")[0],
          class: data?.lesson_mode,
          time: moment(data?.created_at).format("HH:m"),
          course_title: data?.course?.title
        }
      })
    }
   
    // if(!this.props.paymentMode){
    //   this.setState({
    //     modal: {
    //       position:true,
    //       teacher:JSON.parse(data?.event)?.details?.split("</th><td>")[1].split("</td></tr><tr><th>")[0],
    //       class: data?.lesson_mode,
    //       time: moment(data?.created_at).format("HH:m")
    //     }
    //   })
    // }else{
    //   if(!data?.title && data?.paid === 0){
    //     this.setState({paymentModal:{
    //       position:true,
    //       amount:1,
    //       company_id:this.props.user?.company_id,
    //       event_id:data?.id,
    //       student_id:this.props.studentId,
    //       teacher_id:undefined
    //     }})
    //   }
    // }
    
  }
  addNotePayment=(date)=>{
    if(this.props.user?.role =="company_head" || this.props.user?.role =="office_manager" || this.props.user?.role =="super_admin"){
      this.setState({noteModal:{
        position: true,
        date:`${date} ${moment().format("HH:mm:ss")}`,
        student_id:this.props.studentId,
        note:''
      }})
    }
    
  }

  addCheckin=(date)=>{
    if(this.props.user?.role === "teacher" || this.props.user?.role === "head_teacher"){
      if(date !== moment(new Date()).format("YYYY-MM-DD")){
        return
      }
    }
    this.setState({ addCheckinModal:{
      position:true,
      teacher_id: this.props.teacherId,
      student_id:this.props.studentId,
      date:date
    }})
  }
  render() {
    const {days, date, modal, paymentModal, noteModal, addCheckinModal} = this.state;
    const {paymentMode=false, checkinMode=false, getAfterChange=()=>{}, typeMini=false, selectCurrentDate=()=>{}, lang, studentName="", miniCell=false, onNextPrevBtn=()=>{}} = this.props;
    return (
      <div className={`w-full ${typeMini? '':'p-[30px]'}  bg-white rounded-[20px] calendar`}>
        <div className={`w-full bg-blue h-[46px] flex ${typeMini?'rounded-t-[5px]':''} items-center justify-between`}>
            <h3 className='font-semibold text-[16px] text-white ml-[25px]'>{moment(date).format("MMMM YYYY")}</h3>
            <Link target='_blank' to={`/students/${this.props.studentId}`} className='text-white text-[17px] font-semibold'>{studentName}</Link>
            <div className='flex items-center gap-[10px] mr-[25px]'>
              <button onClick={this.prevMonth.bind(this, date, onNextPrevBtn)}>
                <LeftSvg />
              </button>
              <span>
                <CalendarSvg />
              </span>
              <button onClick={this.nextMonth.bind(this, date, onNextPrevBtn)}>
                <RightSvg />
              </button>
            </div>
        </div>
        <div>
            <ul className={`flex f-full ${typeMini?'h-[30px]':'h-[49px]'} bg-light-gray-2 items-center`}>
                <li className={`w-[14.28%] text-center border h-full flex items-center justify-center ${typeMini?'text-[14px]':'text-[18px] max-[420px]:text-[14px]'} font-regular txt-light-black`}>{lang?.sun}</li>
                <li className={`w-[14.28%] text-center border h-full flex items-center justify-center ${typeMini?'text-[14px]':'text-[18px] max-[420px]:text-[14px]'} font-regular txt-light-black`}>{lang?.mon}</li>
                <li className={`w-[14.28%] text-center border h-full flex items-center justify-center ${typeMini?'text-[14px]':'text-[18px] max-[420px]:text-[14px]'} font-regular txt-light-black`}>{lang?.tue}</li>
                <li className={`w-[14.28%] text-center border h-full flex items-center justify-center ${typeMini?'text-[14px]':'text-[18px] max-[420px]:text-[14px]'} font-regular txt-light-black`}>{lang?.wed}</li>
                <li className={`w-[14.28%] text-center border h-full flex items-center justify-center ${typeMini?'text-[14px]':'text-[18px] max-[420px]:text-[14px]'} font-regular txt-light-black`}>{lang?.thu}</li>
                <li className={`w-[14.28%] text-center border h-full flex items-center justify-center ${typeMini?'text-[14px]':'text-[18px] max-[420px]:text-[14px]'} font-regular txt-light-black`}>{lang?.fri}</li>
                <li className={`w-[14.28%] text-center border h-full flex items-center justify-center ${typeMini?'text-[14px]':'text-[18px] max-[420px]:text-[14px]'} font-regular txt-light-black`}>{lang?.sat}</li>
            </ul>
        </div>
        <div>
            <ul className='grid grid-cols-7'>
              {
                days.map((data, i)=>{
                  return(
                    <CalendarCell 
                      key={i}
                      type={data?.type}
                      day={data?.day}
                      date={data?.date}
                      checkinDates={this.props.checkinDates}
                      paymentMode={paymentMode}
                      openModalBtn={this.openModalBtn}
                      addNotePayment={this.addNotePayment.bind(this)}
                      checkinMode={checkinMode}
                      addCheckin={this.addCheckin.bind(this)}
                      selectCurrentDate={(date)=>selectCurrentDate(date)}
                      typeMini={typeMini}
                      miniCell={miniCell}
                    />
                  )
                })
              }
            </ul>
        </div>
        {  modal?.position? <CheckInModal closeBtn={()=>{this.setState({ modal: { position:false,  teacher:'',  class: '',  time: ''  }})}} data={modal} /> :null }
        {paymentModal?.position? <PaymentModal getAfterChange={()=>getAfterChange(date)} data={paymentModal} closeBtn={()=>{this.setState({paymentModal: { position:false,  amount:1, company_id:'',  event_id:'', student_id:'',  teacher_id:undefined}})}} />:null}
        {noteModal?.position? <PaymentNoteModal  getAfterChange={()=>getAfterChange(date)} data={noteModal} closeBtn={()=>{this.setState({noteModal: { position:false,  note:'', date:'', student_id:''}})}}  />:null}
        {addCheckinModal?.position ?<AddCheckinModal getAfterChange={()=>getAfterChange(date)} data={addCheckinModal} closeBtn={()=>{this.setState({addCheckinModal: { position:false,  teacher_id:'', date:'', student_id:''}})}} />:null }
     </div>
    )
  }
}
const mapStateToProps  = (state) =>({
  user: state.Data.user,
  lang: state.Data.lang,
})
export default connect(mapStateToProps)(Calendar)
