import React, { Component } from 'react'
import AcceptedStudents from '../sections/AcceptedStudents'

export class PublicAcceptedStudents extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
      <div className='pt-[110px]'>
         <AcceptedStudents page="1" loadPage={20} defCount={20} />
      </div>
    )
  }
}

export default PublicAcceptedStudents
