import React, { Component } from 'react'

export class PhoneSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={8}
        height={8}
        fill="none"
      >
        <g fill="#fff" clipPath="url(#a)">
          <path d="M5.5.5A.5.5 0 0 1 6 1v6a.5.5 0 0 1-.5.5h-3A.5.5 0 0 1 2 7V1a.5.5 0 0 1 .5-.5h3Zm-3-.5a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-3Z" />
          <path d="M4 7a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h8v8H0z" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}

export default PhoneSvg
