import React, { Component } from 'react'

export class TCountingSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={18}
        className="si-glyph si-glyph-abacus"
        viewBox="0 0 16 16"
      >
        <title>{"642"}</title>
        <g
          fill="#585874"
          fillRule="evenodd"
          className="si-glyph-fill"
          transform="translate(0 1)"
        >
          <path
            d="M14.194 13.958H1.765c-1.449 0-1.729-1.15-1.729-2.564V2.607C.036 1.193.315.043 1.765.043h12.429c1.448 0 1.728 1.15 1.728 2.564v8.787c0 1.414-.279 2.564-1.728 2.564ZM1.923 1A.937.937 0 0 0 1 1.948v10.104c0 .522.414.948.923.948h12.154a.937.937 0 0 0 .923-.948V1.948A.937.937 0 0 0 14.077 1H1.923Z"
            className="si-glyph-fill"
          />
          <path
            d="M3 0h.948v13.068H3zM8 0h.948v13.068H8zM12 0h.948v13.068H12z"
            className="si-glyph-fill"
          />
          <ellipse
            cx={3.438}
            cy={4.976}
            className="si-glyph-fill"
            rx={1.438}
            ry={0.976}
          />
          <ellipse
            cx={3.438}
            cy={7.976}
            className="si-glyph-fill"
            rx={1.438}
            ry={0.976}
          />
          <ellipse
            cx={3.438}
            cy={10.976}
            className="si-glyph-fill"
            rx={1.438}
            ry={0.976}
          />
          <ellipse
            cx={8.477}
            cy={2.976}
            className="si-glyph-fill"
            rx={1.477}
            ry={0.976}
          />
          <ellipse
            cx={8.477}
            cy={5.976}
            className="si-glyph-fill"
            rx={1.477}
            ry={0.976}
          />
          <ellipse
            cx={8.477}
            cy={10.976}
            className="si-glyph-fill"
            rx={1.477}
            ry={0.976}
          />
          <ellipse
            cx={12.435}
            cy={7.977}
            className="si-glyph-fill"
            rx={1.435}
            ry={0.977}
          />
          <ellipse
            cx={12.435}
            cy={10.977}
            className="si-glyph-fill"
            rx={1.435}
            ry={0.977}
          />
        </g>
      </svg>
    )
  }
}

export default TCountingSvg
