import moment from "moment";
const  week_days =[
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat"
  ];
export function renderCalendar(date = new Date()){
    let days = [];
    const weeks = week_days;
    const startDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
    )
    const week = weeks.indexOf(String(startDay).split(" ")[0]);
    const prevMonthDays = new Date(
        date.getFullYear(),
        date.getMonth(),
        0
    ).getDate()
   

    for(let x = prevMonthDays - week+1; x<= prevMonthDays; x++){
        const prevDate = new Date(
            date.getFullYear(),
            date.getMonth()-1,
            x
        )
        days.push({
            type: 'prev',
            day:`${x}`,
            date: moment(prevDate).format("YYYY-MM-DD")
        })
    }


    const allDays = new Date(
        date.getFullYear(),
        date.getMonth()+1,
        0
    ).getDate()
   

    for(let i = 1; i<=allDays; i++){
        const allDate = new Date(
            date.getFullYear(),
            date.getMonth(),
            i
        );
        days.push({
            type: 'current',
            day: `${i}`,
            date:moment(allDate).format("YYYY-MM-DD")
        })
    }

    // return next month days
    const nexDays = new Date(
        date.getFullYear(),
        date.getMonth()+1,
        0
    ).getDay()
    

    for(let j = 1; j<=(7 -nexDays -1); j++){
        const nexDate = new Date(
            date.getFullYear(),
            date.getMonth()+1,
            j
        )
        days.push({
            type:'next',
            day: `${j}`,
            date: moment(nexDate).format("YYYY-MM-DD")
        })
    }

    return days;
}