import React, { Component } from 'react'
import Header from './Header';
import UserSvg from '../svg/UserSvg';

export class StudentsModal extends Component {
    closeBackground(e){
        if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
          this.props.closeBtn();
        }
    }
  render() {
    const {closeBtn, data} = this.props
    return (
        <div onClick={this.closeBackground.bind(this)} className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[300px]  rounded-[10px]'>
                <Header closeBtn={closeBtn} title="Students" />
                <ul className='mt-[20px] mb-[20px] ml-[24px] flex flex-col gap-[16px]'>
                    {
                        data?.map((item, i)=>{
                            return(
                                <li key={i} className='flex items-center gap-[10px]'>
                                   <UserSvg/>
                                    <span className='txt-light-black text-[20px]'>{item?.first_name+" "+item?.last_name}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
  }
}

export default StudentsModal
