import { CELTGO_API, MAIN_API, MAIN_APIV2, PUBLIC_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";
import axios from "axios";

const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${atob(localStorage.getItem("FAToken"))}`,
        "Accept": "application/json",
        // "Content-Type": "application/json"
    }
}

export const check2FAAuth=()=> async dispatch => {
    return await axios.get(`${MAIN_API}/check-2fa`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            "Accept": "application/json",
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return {success:false}
    })
}

export const check2FAAuth2=()=> async dispatch => {
    return await axios.get(`${MAIN_API}/check-2fa`, {
        headers: {
            "Authorization": `Bearer ${atob(localStorage.getItem("FAToken"))}`,
            "Accept": "application/json",
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return {success:false}
    })
}

export const generate2FAAuth=()=> async dispatch => {
    return await axios.get(`${MAIN_API}/generate-2fa`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
            "Accept": "application/json",
        }
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return ""
    })
}
export const get2FAAuthSecret=()=> async dispatch => {
    return await axios.get(`${MAIN_API}/get-2fa-secret`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return ""
    })
}
export const get2FAUser=()=> async dispatch => {
    return await axios.get(`${MAIN_API}/user`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return ""
    })
}

export const insertNewDataFA=(url, data)=> async dispatch => {
    return await axios.post(`${MAIN_API}/${url}`, data, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
        return ""
    })
}

