import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import Card5 from '../cards/Card5';
import { SalaryCounting } from '../../actions/salaryCounting';

export class GroupUsers extends Component {
    state={
        salaryCounting: new SalaryCounting(),
    }
    closeBackground(e){
        if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
            this.props.changeStateValue({ name:"studentGroupUsers",  value:[] })
        }
      }
  render() {
    const {studentGroupUsers} = this.props;
    const {salaryCounting} = this.state;
    return (
      <div onClick={this.closeBackground.bind(this)} className='background_shadow flex justify-center items-center '>
        <div className='grid grid-full-320'>
            <Card5
                time={salaryCounting.getLessonHour(studentGroupUsers[0]?.lesson_houre)} 
                subject={studentGroupUsers[0]?.lesson_mode}
                type={studentGroupUsers[0]?.study_mode}
                students={salaryCounting.getGroupStudents(studentGroupUsers)}
                // course={studentGroupUsers?.filter(x=>x.status==1)[0]?.course?.title}
                btn={true}
                // groupLockStatus={studentGroupUsers[0]?.group_name.includes("Locked")?true:false}
                modeType="group"
                
            />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentGroupUsers: state.Data.studentGroupUsers
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(GroupUsers)
