import React, { Component } from 'react'
import CloseSvg from '../svg/CloseSvg';
import { getCompanies } from '../../actions/AdminActions';
import { connect } from 'react-redux';
import {getDropValue} from "../../actions/getRole" 
import { getOfficeAllStudents } from '../../actions/StaffAction';
import DropSearch from '../DropSearch';
import Header from './Header';
import { changeStateValue } from '../../redux/MainReducer';
export class AdminSelectStudent extends Component {
    state={
        selected: this.props.selected,
        company_id:'',
        search:'',
        modalPosition:false
    }
    deleteItem=(selected, index)=>{
        let arr = [...selected]
        arr.splice(index, 1);
        this.setState({selected:arr})
        this.props.changeStateValue({
            name:'userModalData.childs',
            value: arr
        })
    }
    componentDidMount(){
        this.props.getCompanies(10, '');
    }
    selectCompany=(data)=>{
        this.setState({company_id:data})
        this.props.getOfficeAllStudents(data)
    }

    closeBtn=()=>{
        this.setState({modalPosition:false});
        this.setState({search:''})
    }
    openModal=(e)=>{
        this.setState({search:''})
        if(typeof e.target.className === "string" &&!e.target.className.includes("not-allow")){
            this.setState({modalPosition:true})
        }
    }
    selectStudent(data){
        let arr = [...this.state.selected];
        arr.push({
            label: data?.full_name,
            value: data?.id
        });
        this.props.changeStateValue({
            name:'userModalData.childs',
            value: arr
        })
        this.setState({selected:arr})
        this.closeBtn()
    }
  render() {
    const {placeholder, options, name, adminCompanies, officeAllStudents} = this.props;
    const {selected, company_id, search, modalPosition} = this.state;
    return (
      <div>
        <div>
            <label className='text-[14px] txt-light-black'>{"Select Student"}</label>
            <div onClick={this.openModal.bind(this)} className='border min-h-[32px] rounded-[5px] flex gap-[5px] flex-wrap items-start p-[4px] cursor-pointer '>
                {
                    selected?.map((item, i)=>{
                        return(
                            <span className='bg-gray-100 text-[12px] p-[3px] rounded-[3px] inline-flex gap-[3px] items-center not-allow' key={i}>{item?.label} <button onClick={this.deleteItem.bind(this,selected, i)}><CloseSvg size={'12'} color="#000" /></button></span>
                        )
                    })
                }
                {
                    selected.length ==0? <span className='text-[14px] mt-[2px]'>{"Select Student"}</span>:null
                }
            </div>
        </div>
        {
            modalPosition?
            <div className='background_shadow flex items-center justify-center'>
                <div className='bg-white w-[300px]  rounded-[10px]'>
                <Header title="Select student" closeBtn={this.closeBtn.bind(this)} />
                <div className='p-[20px]'>
                    <div>
                        <DropSearch title="Get Students by Company" selectData={this.selectCompany.bind(this)} value={company_id} placeholder="Get Students By Company" options={getDropValue(adminCompanies, "name","id")} />
                    </div>
                    <input onChange={(e)=>this.setState({search: e.target.value})} className='bg-gray-100 text-[12px] h-[30px] w-full rounded-[5px] mt-[10px] outline-none pl-[3px]' placeholder='Search' type='text' />
                    <ul className='flex flex-col max-h-[50vh] overflow-auto gap-[10px] mt-[20px]'>
                        {
                            officeAllStudents?.map((data, i)=>{
                                if(!selected.find(item=>item?.value == data?.id)){
                                    if(data?.full_name?.toLowerCase().includes(search.toLowerCase()) || data?.email?.toLowerCase().includes(search.toLowerCase()) ){
                                        return(
                                            <li key={i} onClick={this.selectStudent.bind(this, data)} className='flex flex-col bg-gray-100 rounded-[10px] p-[5px] cursor-pointer'>
                                                <span className='text-[13px]'>{data?.full_name}</span>
                                                <span  className='text-[13px]'>{data?.email}</span>
                                            </li>
                                        )
                                    }
                                }
                                
                                
                            })
                        }
                    </ul>
                </div>
            </div>
            </div>:null
        }
       
        
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    adminCompanies: state.Data.adminCompanies,
    officeAllStudents: state.Data.officeAllStudents,
});
const mapDispatchToProps = {getCompanies, getOfficeAllStudents, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSelectStudent)
