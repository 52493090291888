import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getOfficeApplicant } from '../../actions/StaffAction';
import Card7 from '../../components/cards/Card7';
import { insertNewData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData, insertNewDataStaff } from '../../actions/PublicActions';
export class OfficeApplicants extends Component {
    componentDidMount(){
        this.props.getOfficeApplicant();
        window.scrollTo(0,0)
    }
    acceptStudent=(data)=>{
      
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, Accept student")
        .then(respAlert=>{
            if(respAlert==="success"){
                let formData = new FormData();
                for(const property in data?.student){
                    if(data?.student[property])
                       formData.append(property, data?.student[property]) 
                }
                formData.append("company_id", this.props.user?.company_id)
                formData.append("user_accept_type", "accept");
                formData.append("role", "student");
                formData.delete("id");
                this.props.insertNewData("users", formData)
                .then(resp=>{
                    if(resp === "success"){
                        mixinAlert("success", "Applicant accepted successfully");
                        this.props.deleteData(`student-apply/${data?.student?.id}`)
                        .then(resp=>{
                            this.props.getOfficeApplicant();
                        })
                    }else{
                        mixinAlert("error", "Something went wrong");
                    }
                })
            }
        })
    }

    rejectStudent=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, Reject student")
        .then(respAlert=>{
            if(respAlert==="success"){
                let formData = new FormData();
                formData.append("_method", "put");
                formData.append("status", "1");
                this.props.insertNewDataStaff(`student-apply/${data?.id}`, formData)
                .then(resp=>{
                    if(resp === "success"){
                        mixinAlert("success", "Applicant rejected successfully");
                        this.props.getOfficeApplicant();
                    }else{
                        mixinAlert("error", "Something went wrong");
                    }
                })
            }
        })
       
    }
  render() {
    const {officePublicApplicants} = this.props;
    return (
        <div className='w-full  pl-[27px] pr-[27px]'>
            <Header title="Applicants" />
            <div className='grid-full-250 max-[1100px]:mt-[120px] max-[800px]:mt-[180px] mt-[40px]'>
                {
                    officePublicApplicants?.map((data, i)=>{
                        return(
                            <Card7 rejectStudent={this.rejectStudent.bind(this)} acceptStudent={this.acceptStudent.bind(this)} key={i} data={data} />
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    officePublicApplicants: state.Data.officePublicApplicants,
    user: state.Data.user,
});
const mapDispatchToProps = { getOfficeApplicant, insertNewData, deleteData, insertNewDataStaff }
export default connect(mapStateToProps, mapDispatchToProps)(OfficeApplicants)
