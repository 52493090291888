import React, { Component } from 'react'
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts'
import { changeStateValue } from '../../../redux/MainReducer'
import { deleteData } from '../../../actions/MainAction'
import { connect } from 'react-redux'
import { withHooks } from '../../../actions/withHooks'
import { getMocExamParents } from '../../../actions/AdminActions'
import AddDynamicSetting from './modals/AddDynamicSetting'

export class DynamicParentCard extends Component {
    state={
        visible: false
    }
    onDeleteParent=(id)=>{
        confirmAlert("warning", 'Are you sure?', "You cannot revert this")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`dynamic-exam-parent/${id}`)
                .then(resp=>{
                    if(resp === 'success'){
                        this.props.getMocExamParents(this.props.params.id)
                        mixinAlert("success", "Parent deleted successfully");
                    }else{
                        mixinAlert("error", "Something went wrong");
                    }
                })
            }
        })
    }
    onDeleteQuestion=(id)=>{
        confirmAlert("warning", 'Are you sure?', "You cannot revert this")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`dynamic-exam-settings/${id}`)
                .then(resp=>{
                    if(resp === 'success'){
                        this.props.getMocExamParents(this.props.params.id)
                        mixinAlert("success", "Question deleted successfully");
                    }else{
                        mixinAlert("error", "Something went wrong");
                    }
                })
            }
        })
    }

    addSettingBtn=(data, parent_id)=>{
        this.props.changeStateValue({
            name:"dynamicExamQuestionModal",
            value:{
                position: true,
                id: data?.id?data?.id:'',
                parent_id: data?.parent_id?data?.parent_id:parent_id,
                category_id: data?.category_id?data?.category_id:'',
                sub_category_id: data?.sub_category_id?data?.sub_category_id:'',
                section_id: data?.section_id?data?.section_id:'',
                afterCallFunction: this.props.getMocExamParents,
                afterCallParams: [this.props.params.id]
            }
        })
    }
  render() {

    const {data, onEdit=()=>{}, dynamicExamQuestionModal} = this.props;
    const {visible} = this.state;
    return (
      <div className='bg-white shadow p-[10px] rounded-[10px]'>
            <div className='flex items-center justify-between '>
                <h3 onClick={()=>this.setState({visible: !visible})} className='txt-blue text-[20px] font-[600] cursor-pointer w-[90%]'>{data?.title} / {data?.duration} min</h3>

                <div className='flex gap-[10px] items-center'>
                    <button onClick={()=>onEdit(data)} className='text-white text-[12px] font-[600] bg-orange rounded-[5px] p-[6px_10px]'>Edit</button>
                    <button onClick={this.onDeleteParent.bind(this, data?.id)} className='text-white text-[12px] font-[600] bg-red rounded-[5px] p-[6px_10px]'>Delete</button>
                </div>
            </div>
            {
                visible?
                <div className='bg-gray-100 p-[10px] rounded-[10px] mt-[20px]'>
                    <button onClick={this.addSettingBtn.bind(this, "", data?.id)} className='bg-white shadow txt-blue text-[14px] font-[600] p-[5px_8px] rounded-[6px]'>Add Setting</button>
                
                    <div className='flex flex-col gap-[10px] mt-[20px]'>
                        {
                            data?.questions?.map((item, j)=>{
                                return(
                                    <div key={j} className='bg-white flex items-center justify-between p-[10px] shadow rounded-[10px]'>
                                        <h3 className='text-[16px] txt-blue font-[600]'>{item?.category?.title} / {item?.sub_category?.title} / {item?.section?.title} </h3>
                                        <div className='flex gap-[10px] items-center'>
                                            <button onClick={this.addSettingBtn.bind(this, item)} className='text-white text-[12px] font-[600] bg-orange rounded-[5px] p-[6px_10px]'>Edit</button>
                                            <button onClick={this.onDeleteQuestion.bind(this, item?.id)} className='text-white text-[12px] font-[600] bg-red rounded-[5px] p-[6px_10px]'>Delete</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>:null

            }
            
            {dynamicExamQuestionModal?.position? <AddDynamicSetting />:null}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    dynamicExamQuestionModal: state.Data.dynamicExamQuestionModal
});
const mapDispatchToProps = {changeStateValue, deleteData, getMocExamParents}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(DynamicParentCard))
