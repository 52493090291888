import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../../actions/withHooks';
import { getStudentNotes } from '../../../../actions/StaffAction';
import HeaderButtons from '../../../../components/HeaderButtons';
import EmptyPage from '../../../../components/EmptyPage';
import { changeStateValue } from '../../../../redux/MainReducer';
import moment from 'moment';
import Card4 from '../../../../components/cards/Card4';
import { confirmAlert, mixinAlert } from '../../../../actions/SweetAlerts';
import { deleteData } from '../../../../actions/MainAction';
export class Notes extends Component {
    componentDidMount(){
        this.props.getStudentNotes(this.props.params.id)
    }

    openModal=(data)=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                student_id:this.props.params.id,
                title:data?.title? data?.title:'',
                description:data?.description? data?.description:'',
                afterCallFunction:this.props.getStudentNotes,
                afterCallParams:[this.props.params.id],
                requireds:["title"],
                url: data?.id? `student-notes/${data?.id}`:'student-notes',
                modalTitle: data?.id? `Edit Note`:'Add Note',
            }
        });
    }

    onClickDelete=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
          if(alertResp === "success"){
            this.props.deleteData(`student-notes/${id}`)
            .then(resp=>{
              if(resp == "success"){
                this.props.getStudentNotes(this.props.params.id)
                mixinAlert("success", "Note deleted successfully")
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }
        })
    }

    onClickInfo=(data, e)=>{
        e.preventDefault();
        const params = {
            position:true,
            type:'note',
            modalType: 'note',
            title: data?.title,
            question: `<pre class='generatedEssayStyle'>${data?.description}</pre>`, 
       
        }
        this.props.changeStateValue({name: 'mainModal', value: params});
    }
  render() {
    const {studentNottes, lang, user} = this.props;
    return (
        <div className='w-full'>
          {
            user?.role == "company_head" || user?.role == "office_manager" || user?.role == "super_admin"?
            <div className='flex justify-end'>
              <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: lang?.add_note, id:0}]}  />
            </div>:null
          }
          
            {studentNottes.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='w-full grid-full-250 mt-[20px]'>
            {
                studentNottes?.map((data, i)=>{
                return(
                    <Card4
                        key={i}
                        title={data?.title}
                        student={data?.user?.full_name}
                        date={moment(data?.created_at).format("DD/MM/YY")}
                        headerVisible={false}
                        lockUnlock={false}
                        deleteItem={true}
                        info={true}
                        onClickEdit={this.openModal.bind(this, data)}
                        onClickDelete={this.onClickDelete.bind(this, data?.id)}
                        onClickInfo={this.onClickInfo.bind(this, data)}
                    />
                    )
                })
            }
        </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    studentNottes: state.Data.studentNottes,
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {getStudentNotes, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Notes))
