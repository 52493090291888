import React, { Component } from 'react'

export class BottomSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={9}
        height={6}
        fill="none"
      >
        <path fill="#545454" d="M4.5 5.2 0 .4h9L4.5 5.2Z" />
      </svg>
    )
  }
}

export default BottomSvg
