import React, { Component } from 'react'

export class LoginBlockSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={19}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#585874"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M18.364 5.636A9 9 0 1 1 5.636 18.364M18.364 5.636A9 9 0 1 0 5.636 18.364M18.364 5.636 5.636 18.364"
        />
      </svg>
    )
  }
}

export default LoginBlockSvg
