import React, { Component } from 'react'

export class LocationSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <circle cx={12} cy={12} r={12} fill="#E5F0FF" />
        <path
          stroke="#1E398F"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.93 5.947c-2.267 0-4.106 1.74-4.106 3.882 0 2.465 2.737 6.371 3.742 7.72a.45.45 0 0 0 .727 0c1.005-1.348 3.742-5.253 3.742-7.72 0-2.143-1.839-3.882-4.105-3.882Z"
        />
        <path
          stroke="#1E398F"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.93 11.386c.756 0 1.368-.608 1.368-1.36 0-.75-.612-1.36-1.368-1.36-.756 0-1.368.61-1.368 1.36 0 .752.612 1.36 1.368 1.36Z"
        />
      </svg>
    )
  }
}

export default LocationSvg
