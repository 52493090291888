import React, { Component } from 'react'
import MicSvg from '../../svg/MicSvg'
import UploadSvg from '../../svg/UploadSvg'
import Recorder from './Recorder'
import MsgDeleteSvg from '../../svg/MsgDeleteSvg'
import { changeStateValue } from '../../../redux/MainReducer'
import { connect } from 'react-redux'
import TrashSvg from '../../svg/TrashSvg'
export class SpeakingRecord extends Component {
    state={
        page:'record',
        audioBase64:''
    }

    chanegPage(page){
        this.setState({page:page})
    }

    getBase64(file){
      return new Promise((res, rej)=>{
        let reader = new FileReader();
        reader.onload = () => {
          res(reader.result)
        }
        reader.readAsDataURL(file)
      })
    }
    uploadAudio(e){
      this.getBase64(e.target.files[0])
      .then(resp=>{
        this.setState({audioBase64: resp});
        this.props.changeStateValue({name:'mainModal.recorder_file', value: e.target.files[0]})
      })
    }
    deleteAudio(){
      this.props.changeStateValue({name:'mainModal.recorder_file', value: ''})
      this.setState({audioBase64: ''})
  }
  render() {
    const { page, audioBase64 } = this.state;
    const {mainModal} = this.props
    return (
      <div className='mt-[20px]'>
        {
          mainModal?.upload_permission?
          <div className='flex items-center gap-[5px]'>
            <button onClick={this.chanegPage.bind(this, 'record')} className={`pl-[7px] pr-[7px] pt-[5px] pb-[5px] text-[12px]  inline-flex items-center gap-[4px] rounded-[5px] ${page === 'record'? 'bg-blue  text-white': 'bg-white txt-blue border border-blue-600'}`}>
                <MicSvg color={page === 'record'? '#fff': '#1E398F'} />
                <span>Record</span>
            </button>
            <button onClick={this.chanegPage.bind(this, 'upload')} className={`pl-[7px] pr-[7px] pt-[5px] pb-[5px] text-[12px]  inline-flex items-center gap-[4px] rounded-[5px] ${page === 'upload'? 'bg-blue  text-white': 'bg-white txt-blue border border-blue-600'}`}>
                <UploadSvg color={page === 'upload'? '#fff': '#1E398F'} />
                <span>Upload</span>
            </button>
        </div>:null
        }
       
        {
            page === "record"?
            <Recorder />:
            <>
              <label htmlFor='spRecUpAudio' className='border cursor-pointer border-[var(--blue)] p-[14px] items-center gap-[10px] justify-between flex rounded-full mt-[20px] w-[200px] ml-auto mr-auto'>
              <div className='flex items-center gap-[10px]'>
                <UploadSvg color={'#1E398F'} />
                <span className='txt-blue font-regular text-[12px]'>Upload Audio</span>
              </div>
            </label>
            <input onChange={this.uploadAudio.bind(this)} type='file' accept='audio/*' id='spRecUpAudio' className='hidden' />
            </>
        }
        {
          audioBase64 !==""?
          <div className='flex items-center gap-[10px] justify-center mt-[20px]'>
            <audio className='max-sm:w-[200px] w-[220px] h-[40px] max-[320px]:w-[150px]' controls src={audioBase64} />
            <button onClick={this.deleteAudio.bind(this)} className='bg-red w-[35px] h-[35px] rounded-full inline-flex items-center justify-center'>
              <TrashSvg />
            </button>
          </div>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  mainModal: state.Data.mainModal
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SpeakingRecord)
