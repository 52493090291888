import React, { Component } from 'react'
import Header from '../../components/header/Header'
import MonthlyCalendar from '../../components/MonthlyCalendar'
import { connect } from 'react-redux'
import { getStudentsCheckins } from '../../actions/MainAction'
import moment from 'moment'
import Card1 from '../../components/cards/Card1'
import Search from '../../components/Search'
export class TeacherCheckins extends Component {
    state={
        search:''
    }
    selectMonth=(date)=>{
        this.props.getStudentsCheckins(date, '',this.props.user?.id)
    }
    componentDidMount(){
        if(this.props.user !== ""){
            this.props.getStudentsCheckins(moment(new Date()).format("YYYY-MM"), '',this.props.user?.id)
        }
        window.scrollTo(0,0);
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps?.user !== this.props.user){
            this.props.getStudentsCheckins(moment(new Date()).format("YYYY-MM"), '',this.props.user?.id)
        }
    }
    getTotalCheckIn(data){
        let total = 0;
        for(const check_in of data.check_in){
            if(check_in.lesson_mode === data.lesson_mode && +check_in.student_group_id === +data.student_group_id && check_in?.status == data?.status){
                total = total+1;
            }
        }
        return total;
    }

    searchData=(search)=>{
        this.setState({search:search})
    }
  render() {
    const {studentCheckIns, lang} = this.props;
    const {search} = this.state;
    let checkinData = [];
    if(studentCheckIns?.length){
         checkinData = studentCheckIns
    }
    if(search !== ""){
        checkinData = checkinData.filter(x=>x.full_name?.toLowerCase().includes(search.toLowerCase()) || x.email?.toLowerCase().includes(search.toLowerCase()) || x.lesson_mode?.toLowerCase()?.includes(search.toLowerCase()) || x?.title?.toLowerCase()?.includes(search.toLowerCase()))
    }
    let result = checkinData.reduce(function (r, a) {
        r[a.title] = r[a.title] || [];
        r[a.title].push(a);
        return r;
    }, {});
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="check_ins" />
            <div className='flex gap-[20px] items-center white-search mt-[20px] max-[450px]:flex-col  max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <MonthlyCalendar bgCont="bg-none" rounded="5px" bg="bg-white" onSelect={this.selectMonth.bind(this)} />
            </div>
            <div className='flex justify-center'>
                <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
            </div>
            {
                 Object.values(result).map((item, j)=>{
                    return(
                        <div key={j}>
                            {
                                 item.find(check => check.status === '0' && this.getTotalCheckIn(check) !== 0)?
                                 <h2 className='mt-[20px] font-semibold text-[25px]'>{item.find(check => check.status === '0' && this.getTotalCheckIn(check) !== 0).title === null ? 'One to One' : item.find(check => check.status === '0' && this.getTotalCheckIn(check) !== 0).title}</h2>:
                                 item.find(check => check.status === '1')?
                                 <h2 className='mt-[20px] font-semibold text-[25px]'>{item[0].title === null ? 'One to One' : item[0].title }</h2>:null
                            }
                            
                            <div className='mt-[12px] grid-full-220'>
                                {

                                    item?.map((data, i)=>{
                                        if(data.status === '1' || data.status === '0' && this.getTotalCheckIn(data) !== 0){
                                            return(
                                                <Card1
                                                    key={i}
                                                    title={data?.lesson_mode}
                                                    name={data?.full_name}
                                                    time={data.total_lesson? `${data.total_lesson.split(' ')[0].substring(0,5)} ${data.total_lesson.split(' ')[1]}`:''}
                                                    status={1}
                                                    statusBisible={false}
                                                    statusText={data?.status==1? lang?.pass: lang?.fail}
                                                    group={data.title?data.title:'One to One'}
                                                    btn={false}
                                                    close={`${lang?.total_time}: ${ this.getTotalCheckIn(data)}`}
                                                    url={`/check-in/${data?.student_id}/${data?.teacher_id}`}
                                                />
                                                )
                                        }
                                       
                                    })
                                }
                            
                            </div>
                        </div>
                    )
                 })
            }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    studentCheckIns: state.Data.studentCheckIns,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getStudentsCheckins}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherCheckins)
