import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAssesmentsCoach, getCompanyDataCoach, getEndOfCourseData, getFirstFailedCoach } from '../../actions/CoachAction';
import CoachPartCard from '../../components/dashboard/CoachPartCard';
import EmptySvg from '../../components/svg/EmptySvg';
import SkeletonCard from '../../components/cards/SkeletonCard';
import Header from '../../components/header/Header';
import { withHooks } from '../../actions/withHooks';
import moment from 'moment';
import { getCoachStatus, getTotalStudentCount } from '../../actions/getRole';
import AuditOneWeeklyCoach from './AuditOneWeeklyCoach';
import CoachEndOfExam from '../../components/CoachEndOfExam';
import CompanyFailedStudentsCoach from '../../components/CompanyFailedStudentsCoach';
import AssesmentCoach from '../../components/AssesmentCoach';
import ExceptionCoach from '../../components/ExceptionCoach';
import FailedExamStudents from '../../components/FailedExamStudents';
export class AuditorCompanyData extends Component {
    state={
        loader:true,
        page:1
    }
    componentDidMount(){
        this.props.getCompanyDataCoach(this.props.params.id)
        .then(resp=>{this.setState({loader:false})})
        this.props.getEndOfCourseData(this.props.params.id, "single");
        this.props.getFirstFailedCoach(this.props.params.id);
        

        // this.setState({loader:false})
    }
    getCoachStatsAll(companyCoach){
        let arr = [];
        companyCoach.forEach((item=>{
            for(const x of item?.statistics){
                let stats = [];
                if(x?.exam !=""){
                    stats.push({
                        ...x.exam,
                        title:"Exam",
                        type:'exam'
                    })
                }
                if(x?.assessments !=""){
                    stats.push({
                        ...x.assessments,
                        title:"RP.",
                        type:'assessments'
                    })
                }
                if(x?.essay !=""){
                    stats.push({
                        ...x.essay,
                        title:"Es.",
                        type:'essay'
                    })
                }
                if(x?.speaking !=""){
                    stats.push({
                        ...x.speaking,
                        title:"Sp.",
                        type:'speaking'
                    })
                }
                if(x?.homework !=""){
                    stats.push({
                        ...x.homework,
                        title:"PT",
                        type:'pt'
                    })
                }
                if(x?.speaking_practice !=""){
                    stats.push({
                        ...x.speaking_practice,
                        title:"Sp. P",
                        type:'sp_practice'
                    })
                }
               
                let info={
                    course_title: x.course_title,
                    lesson_mode: x.lesson_mode,
                    student_name: item?.full_name,
                    teacher_name: x?.teacher?.full_name,
                    student_id:item?.id,
                    statistics: stats,
                    // status:x?.exam?.status?.status,
                    status: getCoachStatus(x),
                    start_date: x?.start_date,
                    end_date: x?.end_date
                }
                arr.push(info)
            }
        }))
        return arr
    }
  
    getPercentage(companyCoach, endOfCourseData=[]){
        let errors =  this.getCoachStatsAll(companyCoach).filter(a=>a.status == 2).length;
        let warnings = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1).length;
        let success = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0).length;
        let total = errors + warnings + success + endOfCourseData.length + this.props.exceptionCoach?.stats;
        // console.log("rrrrr",errors+endOfCourseData.length, total)
        return {
            total: total,
            errors: Math.round(((errors+endOfCourseData.length+this.props.exceptionCoach?.stats)*100)/total),
            warnings: Math.round((warnings*100)/total),
            success: Math.round((success*100)/total),
        }
    }        

    getOtherPages=(page)=>{
        this.setState({page, loader:true})
        this.props.getCompanyDataCoach(this.props.params.id, page)
        .then(resp=>{this.setState({loader:false})})
    }
  render() {
    const {companyCoach, lang, endOfCourseData, firstFailedCoach} = this.props;
    const {loader, page} = this.state;
    // console.log("dwdwdw", getTotalStudentCount(companyCoach))
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={this.props.params.company_name?.replaceAll("%20", " ")} />
            
           <div className='flex flex-col gap-[20px]'>
            {
                this.getCoachStatsAll(companyCoach).length !==0?
                <div className='bg-white p-[20px] rounded-[20px] ml-[20px] mr-[20px] mt-[50px] mb-[-40px]'>
                        <div className='flex gap-[20px] flex-wrap'>
                                <h2>{lang?.coach_tracker}</h2>
                                <div className='flex gap-[20px]'>
                                    <button onClick={this.getOtherPages.bind(this,1)} className={`${page==1?'bg-blue text-white  ':'border border-[var(--blue)] bg-white txt-blue'} pl-[4px] pr-[4px] pt-[2px] pb-[2px] text-[12px] rounded-[6px]`}>Current</button>
                                    <button onClick={this.getOtherPages.bind(this,page+1)} className={`${page!==1?'bg-blue text-white  ':'border border-[var(--blue)] bg-white txt-blue'} pl-[4px] pr-[4px] pt-[2px] pb-[2px] text-[12px] rounded-[6px]`}>Previous {page}</button>
                                </div>
                        </div>
                            <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center mt-[10px]'>
                                <div style={{ width: `${this.getPercentage(companyCoach, endOfCourseData)?.errors}%`}} className='h-[30px] rounded-l-[10px] w-[20%] bg-[#fd2e63] flex items-center justify-end pr-[10px]'>
                                    <span className='text-[14px] text-white'>{this.getPercentage(companyCoach, endOfCourseData)?.errors}%</span>
                                </div>
                                <div style={{ width: `${this.getPercentage(companyCoach, endOfCourseData)?.warnings}%`}} className='h-[30px]  w-[20%] bg-[#ff5f24] flex items-center justify-end pr-[10px]'>
                                    <span className='text-[14px] text-white'>{this.getPercentage(companyCoach, endOfCourseData)?.warnings}%</span>
                                </div>
                                <div style={{ width: `${this.getPercentage(companyCoach, endOfCourseData)?.success}%`}} className='h-[30px] rounded-r-[10px] w-[20%] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                                    <span className='text-[14px] text-white'>{this.getPercentage(companyCoach, endOfCourseData)?.success}%</span>
                                </div>
                            </div>  
                    </div>:null
            }
                <AuditOneWeeklyCoach />
                <div className='mt-[40px] pl-[20px] pr-[20px]'>
                <AssesmentCoach />
                </div>
                <div className='mt-[0px] pl-[20px] pr-[20px]'>
                <ExceptionCoach />
                </div>
                
                {   loader?
                    <div className=' gap-[30px]  max-[1100px]:mt-[150px] max-[800px]:mt-[180px] items-start p-[20px] rounded-[20px] '>
                        <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                            <div className='grid-full-320 '>
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                                <SkeletonCard />
                            </div>
                        </div>
                    </div>:
                    this.getCoachStatsAll(companyCoach).length !==0?
                    <div className=' gap-[30px]  max-[1100px]:mt-[120px] max-[800px]:mt-[150px] items-start p-[20px] rounded-[20px] mt-[40px]'>
                    
                    
                    {firstFailedCoach?.length != 0? <CompanyFailedStudentsCoach  margin={false}/>:null}
                    <br />
                    {endOfCourseData?.length !=0?  <CoachEndOfExam />:null}
                    <br />
                    <FailedExamStudents company_id={this.props.params.id} />
                    <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px] mt-[20px]'>
                            <h2>{lang?.coach_mustbe_done}</h2>
                            {
                                this.getCoachStatsAll(companyCoach).filter(a=>a.status== 2).length!=0?
                                <div className='grid-full-320 '>
                                    {
                                            this.getCoachStatsAll(companyCoach).filter(a=>a.status== 2)?.map((data, i)=>{
                                                return(
                                                
                                                    <CoachPartCard start_date={moment(data?.start_date)?.format("DD/MM/YYYY")} end_date={moment(data?.end_date)?.format("DD/MM/YYYY")} data={data?.statistics} teacher_id={data?.teacher_id} key={i} student_id={data?.student_id} student_name={data?.student_name} teacher_name={data?.teacher_name} course_title={data?.course_title} status={data?.status}  />
                                                )
                                            })
                                        }
                                </div>:
                                <p>{lang?.coach_outstanding}</p>
                            }
                            
                    </div>
                    <div className=' bg-white p-[20px] mt-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                            <h2>{lang?.coach_need_to_be_done}</h2>
                            {
                                this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1).length!=0?
                                <div className='grid-full-320'>
                                {
                                    this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1)?.map((data, i)=>{
                                        return(
                                            <CoachPartCard key={i} start_date={moment(data?.start_date)?.format("DD/MM/YYYY")} end_date={moment(data?.end_date)?.format("DD/MM/YYYY")} data={data?.statistics} teacher_id={data?.teacher_id} student_id={data?.student_id}  teacher_name={data?.teacher_name}  student_name={data?.student_name} course_title={data?.course_title} status={data?.status}  />
                                        )
                                    })
                                }
                                </div>:
                                <p>{lang?.coach_outstanding}</p>
                            }
                        
                    </div>
                    <div className=' bg-white p-[20px]  mt-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                            <h2>{lang?.coach_already_done}</h2>
                            {
                                this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0).length!=0?
                                <div className='grid-full-320'>
                                {
                                    this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0)?.map((data, i)=>{
                                        return(
                                            <CoachPartCard key={i} start_date={moment(data?.start_date)?.format("DD/MM/YYYY")} end_date={moment(data?.end_date)?.format("DD/MM/YYYY")} data={data?.statistics} teacher_id={data?.teacher_id} student_id={data?.student_id}  teacher_name={data?.teacher_name}  student_name={data?.student_name} course_title={data?.course_title}  status={data?.status} />
                                        )
                                    })
                                }
                                </div>:
                                <p>{lang?.coach_outstanding}</p>
                            }
                            
                    </div>
                        {/* {
                            this.getCoachStats(companyCoach)?.map((data, i)=>{
                                return(
                                    <CoachPartCard key={i} student_name={data?.student_name} course_title={data?.course_title} title={`${data?.title}`} status={data?.status?.status} msg={data?.status?.msg} />
                                )
                            })
                        } */}
                    </div>:
                    <div className='w-full h-[623px] bg-white rounded-[12px] flex justify-center items-center pl-[20px] pr-[20px]'>
                        <div className='flex justify-center items-center flex-col'>
                        
                            <EmptySvg />
                            <h3 className='text-[24px] txt-blue font-bold mt-[15px] text-center'>No data available</h3>
                        
                        </div>
                    </div>
                }
           </div>
        </div>
      
    )
  }
}
const mapStateToProps = (state) =>({
    companyCoach: state.Data.companyCoach,
    lang: state.Data.lang,
    endOfCourseData: state.Data.endOfCourseData,
    firstFailedCoach: state.Data.firstFailedCoach,
    exceptionCoach: state.Data.exceptionCoach,
});
const mapDispatchToProps = {getCompanyDataCoach, getEndOfCourseData, getFirstFailedCoach, getAssesmentsCoach}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AuditorCompanyData))




