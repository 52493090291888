import React, { Component } from 'react'

export class CoachSuccessSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
        stroke="#51bb25"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        style={{ minWidth:'24px' }}
        className="feather feather-thumbs-up"
      >
        <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" />
      </svg>
    )
  }
}

export default CoachSuccessSvg
