import React, { Component } from 'react'

export class SingleCenterLocationSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      viewBox="-5.07 0 43.012 43.012"
    >
      <path
        fill="#2d5be2"
        d="M16.283 43.012C-1.745 29.519.05 14.44.05 14.44h11.184a4.7 4.7 0 0 0-.142 1.1 5.378 5.378 0 0 0 .466 2.1 7.353 7.353 0 0 0 2.622 2.615 5 5 0 0 0 4.218 0 7.316 7.316 0 0 0 2.619-2.615 5.4 5.4 0 0 0 .465-2.105 4.728 4.728 0 0 0-.141-1.1h11.5s1.474 14.842-16.558 28.577Zm4.731-29.576a7.353 7.353 0 0 0-2.619-2.618 4.977 4.977 0 0 0-4.211 0 7.389 7.389 0 0 0-2.622 2.618 6.468 6.468 0 0 0-.326 1H.064C.064 6.464 7.399.001 16.447.001S32.83 6.464 32.83 14.436H21.34a6.523 6.523 0 0 0-.327-1Z"
      />
    </svg>
    )
  }
}

export default SingleCenterLocationSvg
