import React, { useState } from 'react'
import XLSX from "sheetjs-style"
import * as FileSaver from "file-saver"
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getEnrollPaymentsExport } from '../actions/StaffAction';
import { mixinAlert } from '../actions/SweetAlerts';
import { getExcelFormat } from '../actions/getRole';
function ExcelExportButton({excelData=[], fileName="StudentPayments", companyId, date}) {

  const [disable, setDisable] = useState(false)
  const fileType = "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dispatch = useDispatch();
    const countRemaining=(payments)=>{
      let total = 0;
      for(const x of payments){
        total+= x?.count
      }
      return total;
    }
  
    function getAllData(array){
      let newArr = [];
      for(const data of array){
        newArr.push({
          ...data,
          count: countRemaining(data?.payments)
        })
      }
      return newArr;
    }
    const exportToExcel = async () => {
      setDisable(true)
      
      dispatch(getEnrollPaymentsExport('', '','', companyId, date))
      .then(resp=>{
        // console.log(getExcelFormat(getAllData(resp)))
        // return
        setDisable(false)
        if(resp.length !==0){
          const ws = XLSX.utils.json_to_sheet(getExcelFormat(getAllData(resp)));
          const wb = {Sheets:{'data':ws}, SheetNames: ['data']}
          const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type:'array'});
          const data = new Blob([excelBuffer], {type: fileType});
          FileSaver.saveAs(data, `${fileName}-${moment(new Date()).format("DD-MM-YYYY")}`+fileExtension)
        }else{
          mixinAlert("warning", "No data to export")
        }
      })

      
    }
  return (
    <div>
       <button disabled={disable} onClick={()=>exportToExcel()} className='bg-blue text-white text-[14px] rounded-[5px] p-[4px_7px]'>Export Excel</button>
    </div>
  )
}

export default ExcelExportButton
