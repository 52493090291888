import React, { Component } from 'react'
import { connect } from 'react-redux';

export class Speaking extends Component {
  render() {
    const {audioUrl, lang} = this.props;
    return (
      <div className='flex mt-[20px] pt-[20px] border-t items-center gap-[10px]'>
       <h3 className='txt-blue font-semibold text-[17px]'>{lang?.answer}</h3>
        <audio className='h-[36px] listening-audio' controls src={audioUrl} />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(Speaking)
