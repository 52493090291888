import React, { Component } from 'react'

export class HeaderButtons extends Component {
  render() {
    const {buttons=[], selected=0, onClick=()=>{}, padding=false} = this.props;
    return (
      <div className='flex items-center gap-[24px] max-sm:flex-wrap'>
        {
            buttons?.map((data, i)=>{
                return(
                    <button onClick={()=>onClick(data,i)} className={`h-[25px] ${!padding?"w-[110px]": "pl-[7px] pr-[7px]" }  ${i === selected? "bg-blue text-white": "bg-white txt-blue border-[1px] border-blue-700"} rounded-[3px] font-bold text-[10px]`} key={i}>{data?.title}</button>
                )
            })
        }
      </div>
    )
  }
}

export default HeaderButtons
