import React, { Component } from 'react'
import face from "../../../images/faceToFace.webp";
import online from "../../../images/online.jpg"
export class SelectType extends Component {
    state={
        types:[
            {
                title: "Face to Face",
                img: face,
                id: 'face_to_face'
            },
            {
                title: "Online",
                img: online,
                id: 'online'
            },
        ]
    }
  render() {
    const {selected, onSelectType=()=>{}} = this.props;
    const {types} = this.state;
    return (
        <div className='mt-[20px] max-h-[60vh] overflow-auto'>
            <div className='flex justify-center'>
                <h3 className='txt-blue font-semibold text-[24px]'>Select a type</h3>
            </div>
            <div className='grid-cols-2 grid gap-[30px] p-[4px] max-[550px]:grid-cols-1'>
                {
                    types?.map((data, i)=>{
                        return(
                            <div onClick={()=>onSelectType(data?.id)} key={i} className={`border ${selected===data?.id? 'border-blue-700':'border-gray-300'}  p-[20px] rounded-[12px] cursor-pointer hover:shadow-lg flex flex-col items-center gap-[7px] hover:border-blue-700`}>
                                <img className='w-[130px] h-[130px] rounded-full' src={data?.img} alt={data?.title} />
                                <h4 className='txt-blue font-semibold text-[18px]'>{data?.title}</h4>
                            </div>
                        )
                    })
                }
            
            </div>
    </div>
    )
  }
}

export default SelectType
