import React, { Component } from 'react'

export class Title extends Component {
  render() {
    const {text} = this.props;
    return (
      <div>
        <h3 className='txt-blue text-[22px]'>{text}</h3>
      </div>
    )
  }
}

export default Title
