export class SalaryCounting{
    constructor(teacher_status=0, lessonModes=[]){
        this.teacher_status=teacher_status;
        this.lessonModes = lessonModes;
    }
    getIndividualCheckIn(data){
        let check_ins = [];
        for(const check_in of data.check_in){
          if(check_in.lesson_mode === data.lesson_mode){
            check_ins.push(check_in)
          }
        }
        let result = check_ins.reduce(function (r, a) {
         
          r[a.lesson_houre] = r[a.lesson_houre] || [];
          r[a.lesson_houre].push(a);
          return r;
      }, {});
       return Object.values(result);
    }
    getTotalSalaryAmount(lesson_mode, lesson_hour, check_in){
       
        let time = +lesson_hour/60
        let findLM = this.lessonModes.find(data=>data.title == lesson_mode);
        if(findLM){
          if(this.teacher_status == 0){
            return (findLM.price*time*check_in).toFixed(2)
          }else{
            return (findLM.senior_price*time*check_in).toFixed(2)
          }
        }else if(lesson_mode === 'Other Exams'){  ///Deleted
          return 'N/C'
        }else{
          return 'N/C'
        }
      }
    seperateCheckIns(check_ins){
        let result = check_ins.reduce(function (r, a) {
            r[a.lesson_houre] = r[a.lesson_houre] || [];
            r[a.lesson_houre].push(a);
            return r;
        }, {});
        return Object.values(result);
    }
    getMaxCheckIn (data){
      // console.log(data)
        let count = []
        for(let i = 0; i<data.length; i++){
            count.push(data[i].check_in.length);
        }
        let max = Math.max(...count)
        for(const check_in of data){
          if(check_in.check_in.length === max){
            this.seperateCheckIns(check_in.check_in)
            return {max_value: max, check_ins: this.seperateCheckIns(check_in.check_in)};
          }
        }
      }
    getTotalAmounts(data){
        let total = 0;
        this.getMaxCheckIn(data)?.check_ins?.map((check_in, k) => {
      
            if(check_in[0].lesson_houre !== '' && this.getTotalSalaryAmount(data[0].lesson_mode, check_in[0].lesson_houre, check_in.length) !== 'N/C'){
            total = total+ +this.getTotalSalaryAmount(data[0].lesson_mode, check_in[0].lesson_houre, check_in.length);
            
            }else if(check_in[0].lesson_houre === ''  && this.getTotalSalaryAmount(data[0].lesson_mode, data[0].lesson_houre, check_in.length) !== 'N/C') {
            total = total + +this.getTotalSalaryAmount(data[0].lesson_mode, data[0].lesson_houre, check_in.length);
            
            }
        });
        return total.toFixed(2);
    }
    getGroupAllValue(result){
        let total = 0;
        result.map((data) => {
          if(data[0].group_name !== 'One to One' && !data[0].group_name.split(' ').includes('Locked')){
            total = total+ +this.getTotalAmounts(data)
          }else if(data[0].group_name !== 'One to One' && data[0].group_name.split(' ').includes('Locked') && data.find(result => result.check_in.length !== 0)){
            total = total+ +this.getTotalAmounts(data)
          }
        })
        
        return total;
    }
    getIndividualAllValue(result){
        let total = 0;
        result.map(data => {
          if(data.group_name === 'One to One' && +data.status === 1){
            total= total + +this.getIndividualTotalAmounts(data)
          }else if(data.group_name === 'One to One' && +data.status === 0 && data.check_in.length !== 0){
            total= total + +this.getIndividualTotalAmounts(data)
          }
        })
        
        return total;
    }
    getIndividualTotalAmounts(data){
        let total = 0;
          this.getIndividualCheckIn(data).map((check_in, k) => {
            
              if(check_in[0].lesson_houre!== '' && this.getTotalSalaryAmount(data.lesson_mode, check_in[0].lesson_houre, check_in.length) !== 'N/C'){
                total = total+ +this.getTotalSalaryAmount(data.lesson_mode, check_in[0].lesson_houre, check_in.length)
              }else if(check_in[0].lesson_hour === '' && this.getTotalSalaryAmount(data.lesson_mode, data.lesson_houre, check_in.length) !== 'N/C') {
                total= total + +this.getTotalSalaryAmount(data.lesson_mode, data.lesson_houre, check_in.length);
              }
         })
         
        return total.toFixed();
    }

    getGroupStudents(groups){
      
        let data = [];
        for(const item of groups){
            data.push({
                id: item?.student?.id,
                name: item?.student?.full_name,
                total: item?.check_in?.length,
                status: item?.status,
                btn:false,
                checkins: item?.check_in,
            })
        }
        return data;
    }
    getIndividualStudents(item){
        let data = [];
       
        data.push({
            id: item?.student?.id,
            name: item?.student?.full_name,
            total: item?.check_in?.length,
            status: item?.status,
            btn:false,
            checkins: item?.check_in,
        })
        return data;
    }
    getLessonHour(hour){
        if(+hour/60 === 0.75){
          return '45 min';
        }else if(+hour/60 === 1){
          return '1 hour';
        }else if(+hour/60 === 1.5){
          return '1.5 hours';
        }else if(+hour/60 === 2){
          return '2 hours';
        }else if(+hour/60 === 3){
          return '3 hours';
        }else if(+hour/60 === 4){
          return '4 hours';
        }
    }
    
}

