import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Card4 from '../../components/cards/Card4'
import { connect } from 'react-redux';
import { getTeachers } from '../../actions/StaffAction';
import { getDropValue, getRole } from '../../actions/getRole';
import SectionHeader from '../../components/SectionHeader';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import { changeStateValue } from '../../redux/MainReducer';
import { insertNewData } from '../../actions/MainAction';
import EmptyPage from '../../components/EmptyPage';
import DropSearch from '../../components/DropSearch';
import { getCompanies } from '../../actions/AdminActions';
export class AdminTeachers extends Component {
  state={
    url: "users",
    search:'',
    page:1,
    company_id:''
  }
  componentDidMount(){
    if(this.props.user !== ""){
       this.props.getTeachers(1, '', this.state.company_id);  
    }
    window.scrollTo(0,0);
    this.props.getCompanies(10, '');

  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps?.user !== this.props.user){
       this.props.getTeachers(1, '', this.state.company_id);  
    }
  }
  getTeachersByType=(type)=>{
    this.setState({url: type==="unlock"? "users":'company-head-lock-teacher'})
    this.props.getTeachers(1, this.state.search, this.state.company_id, type==="unlock"? "users":'company-head-lock-teacher');  
  }
  searchTeachers=(search)=>{
    this.setState({search:search})
    this.props.getTeachers(1, search, this.state.company_id, this.state.url);  
  }
  loadMore=(page)=>{
    this.setState({page:page})
    this.props.getTeachers(page, this.state.search, this.state.company_id, this.state.url);  
  }

  addUser=(data)=>{
    this.props.changeStateValue({
        name: "userModalData",
        value:{
            position: true,
            id: data?.id?data?.id:"",
            first_name: data?.first_name? data?.first_name:"",
            last_name: data?.last_name? data?.last_name:"",
            email: data?.email? data?.email:"",
            phone_number: data?.phone_number? data?.phone_number:"",
            date_of_birth: data?.date_of_birth? data?.date_of_birth:"",
            password: data?.password? data?.password:"",
            teacher_status: data?.teacher_status? data?.teacher_status:"0",
            role: data?.role? data?.role:"",
            role_options: [{label: "Head Teacher", value: "head_teacher"}, {label:"Teacher", value: "teacher"}, {label:"Speaking Teacher", value:"speaking_teacher"}],
            title: data?.id?"Edit Teacher":"Add Teacher",
            unRequireds: ["id",  "date_of_birth","password","role_options", "unRequireds", "afterCallFunction", "funcParams"],
            afterCallFunction:  this.props.getTeachers,
            funcParams: [this.state.page, this.state.search, this.state.company_id, this.state.url],
            company_id: this.state.company_id,
            url:'users',
            errorMsg: "Email already exists",
        }
    })
  }

  onClickLockUnlock(data, status){
    let formData = new FormData();
    formData.append("user_id", data?.id);
    formData.append("lock_status", status);
    this.props.insertNewData("lock-unlock-teacher", formData)
    .then(resp=>{
      this.props.getTeachers(this.state.page, this.state.search, this.state.company_id, this.state.url)
    })
  }
  selectCompany=(company_id)=>{
    this.setState({company_id});
    this.props.getTeachers(this.state.page, this.state.search, company_id, this.state.url);  
  }
  render() {
    const {teachers, lang, adminCompanies} = this.props;
    const {url, company_id} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="teachers" />
            <div className='mt-[30px]  white-search flex justify-start items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <DropSearch selectData={this.selectCompany.bind(this)} value={company_id} placeholder="Select a Company" options={getDropValue(adminCompanies, "name","id")}  />
              </div>
            <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
              <SectionHeader right={false}  onSearchData={search=>this.searchTeachers(search)} onSelectType={(type)=>this.getTeachersByType(type)} calendar={false} btnTitle={lang?.add_teacher} />
            </div>
            {teachers?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
              {
                teachers?.map((data, i)=>{
                  return(
                    <Card4 
                      key={i}
                      name={data?.full_name}
                      email={data?.email}
                      phone={data?.phone_number}
                      image={data?.avatar_full_url}
                      role={getRole(data?.role, this.props.lang)}
                      lock={url === "users"? 1:0}
                      info={`/teacher/${data?.id}/enroll`}
                      onClickLockUnlock={this.onClickLockUnlock.bind(this, data)}
                      bonus={`/teachers/${data?.id}/bonus`}
                      onClickEdit={this.addUser.bind(this,data)}
                      events={`/teachers/${data?.id}/events`}
                    />
                  )
                })
              }
            </div>
            {
                teachers?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={teachers?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  teachers: state.Data.teachers,
  user: state.Data.user,
  lang: state.Data.lang,
  adminCompanies: state.Data.adminCompanies,
});
const mapDispatchToProps = {getTeachers, changeStateValue, insertNewData, getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(AdminTeachers)


