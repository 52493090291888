import React, { Component } from 'react'
import CoachPartCard from './CoachPartCard';

export class StudentCoachContainer extends Component {
    getTasks=(data)=>{
        let total = [];
        if(data?.essay !=""){
            total.push({ title: 'Essay',  ...data?.essay?.status });
        }
        if(data?.speaking !=""){
            total.push({ title: 'Speaking',  ...data?.speaking?.status });
        }
        if(data?.homework !=""){
            total.push({ title: 'PT',  ...data?.homework?.status });
        }
        if(data?.exam !=""){
            total.push({ title: 'Unit Exam',  ...data?.exam?.status });
        }
        if(data?.speaking_practice !=""){
            total.push({ title: 'Sp. P',  ...data?.speaking_practice?.status });
        }
        return [...total].sort((x, y)=> {  return +y?.status - +x?.status})
    }
  render() {
    const {data, title=""} = this.props
    return (
        <div className='bg-white p-[25px] rounded-[20px] '>
            <div className='flex justify-between items-center'>
                <h2 className='text-[#0F172A] font-bold text-[16px] '>{data?.course_title}</h2>
                {
                    title !=""?
                    <span className='text-[14px] txt-light-black'>{title}</span>:null
                }
                
            </div>
            
            <div className='flex flex-col gap-[20px] mt-[20px]'>
            {
                this.getTasks(data)?.map((item, j)=>{
                    return(
                        <CoachPartCard key={j} title={item?.title} status={item?.status} msg={item?.msg} />
                    )
                })
            }
            </div>
        </div>
    )
  }
}

export default StudentCoachContainer
