import React, { Component } from 'react'
import logo from "../../images/logo.png";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOutPublic } from '../../actions/PublicActions';
import { withHooks } from '../../actions/withHooks';
export class Footer extends Component {
  render() {
    const {publicUser} = this.props;
    return (
      <footer className='bg-[#F1F5F9] flex justify-center mt-[120px] pt-[40px] pb-[40px]'>
        <div className='container '>
            <div className='flex max-[588px]:gap-[20px] items-center justify-between border-b pb-[27px] border-b-[rgba(0,0,0,0.5)] max-[1023px]:flex-col'>
                <div className='flex items-center gap-[90px] max-[588px]:flex-col max-[588px]:gap-[20px]'>
                    <Link to={'/'}>
                        <img src={logo} alt="Logo" className='w-[185px] h-[58px]' />
                    </Link>

                    
                </div>
                <ul className='flex items-center gap-[30px]'>
                        {/* <li><Link className='font-regular text-[16px]' to={'/exam-results'}>Exam Results</Link></li> */}
                        <li><Link className='font-regular text-[16px]' to={'/accepted-students'}>Students </Link></li>
                        <li><Link className='font-regular text-[16px]' to={'/centers'}>Centers</Link></li>
                    </ul>
                {/* <div className='flex items-center gap-[25px]'>
                    <div className='flex items-center gap-[6px]'>
                        <span>Let’s do it </span>
                        <span className='inline-block bg-black h-[2px] w-[28px]'></span>
                    </div>
                    {
                      publicUser == ""?
                      <Link className='w-[121px] h-[35px] bg-blue rounded-[218px] inline-flex justify-center items-center text-white font-regular text-[16px]' to="/login">Sign in</Link>:
                      <button onClick={()=>this.props.logOutPublic().then(resp=>{this.props.navigate("/")})} className='w-[121px] h-[35px] bg-blue rounded-[218px] inline-flex justify-center items-center text-white font-regular text-[16px]'>Log out</button>
                    }
                    
                </div> */}
            </div>
            <div className='flex justify-center items-center mt-[27px]'>
                <p className='font-regular text-[16px]'>© 2023 All Rights Reserved</p>
            </div>
        </div>
      </footer>
    )
  }
}
const mapStateToProps = (state) =>({
  publicUser: state.Data.publicUser
});
const mapDispatchToProps = {logOutPublic}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Footer))
