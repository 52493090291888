import axios from "axios";
import { MAIN_API } from "../MAIN_API";
const saveTokens = async (count) => {
    await axios.post(`${MAIN_API}/token-count`,{count}, {
        headers:{  "Authorization": `Bearer ${localStorage.getItem("userToken")}`,  }
    }).then(resp=>{ {/*console.log(resp?.data) */}   }).catch(err=>{ {/*console.log(err.response)*/}    })
}
// const API1 = 'https://api.mistral.ai/v1/chat/completions';
// const API2 = 'https://api.mistral.ai/v1/chat/completions';
// const API1 = 'https://text.octoai.run/v1/chat/completions';
// const API2 = 'https://text.octoai.run/v1/chat/completions';
const API1 = 'https://api.openai.com/v1/completions';
const API2 = 'https://api.openai.com/v1/chat/completions';

// const token = "MImIJH9ATfyeHDFjwTBXWoj5R4pBKdf7"; //mistral
// const token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjNkMjMzOTQ5In0.eyJzdWIiOiJhNDQ3OTk1Mi1jZjQ4LTQ5NzQtYTllMC02YzkxNDFlMWViNTEiLCJ0eXBlIjoidXNlckFjY2Vzc1Rva2VuIiwidGVuYW50SWQiOiJjNGYwNTVkNC0zM2E3LTQ4MDctOWIwNS0wZTVjYWI0YWI4MzUiLCJ1c2VySWQiOiIzNGE4ZjE4OC1mNDk2LTRkYzktOTY3Yi0wNGI0ODg2YTA3ODciLCJyb2xlcyI6WyJGRVRDSC1ST0xFUy1CWS1BUEkiXSwicGVybWlzc2lvbnMiOlsiRkVUQ0gtUEVSTUlTU0lPTlMtQlktQVBJIl0sImF1ZCI6IjNkMjMzOTQ5LWEyZmItNGFiMC1iN2VjLTQ2ZjYyNTVjNTEwZSIsImlzcyI6Imh0dHBzOi8vaWRlbnRpdHkub2N0b21sLmFpIiwiaWF0IjoxNzAzNDkwNzMzfQ.fRyvBY-J1TCLpu8S1C5GP1rkRf_Rq06QQFArQYjMESdOndD9lfaKcUJXkKOM0hDmKKgmNznLM-F4Xo5eBVQjR_ZXyBDndtK6jlNOKozOhJxFxofF1cgQgSvyh37KwM9DdBx-xj4x4BXugS4ikGXpG3-3pUcwqpOEy_4UcY87jJxkuFY8GlC8E-xBWC7riWDD3pGhZCi_QzE4QAgI-YOsBNBjybVpDosW1oN-jLVUk_7znt-2T4CYugvk3I1a8hbFl8dtYO3qG8-kqsA-lLWW31r5K00usac3Sq9Ae0S8kP_hcuwaGjVtKRgaucE9eBh0V5TAM8S3_9RKfr1I4SpsfQ"; //octo
const token = "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"; //openai

export const getAnthropicModel =  (obj) => async dispatch =>{
    const apiKey = "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"; // Ensure you have this set in your .env file
    const apiUrl = 'https://api.anthropic.com/v1/complete';

    const requestData = {
      model: 'claude-2.1',
      max_tokens_to_sample: 1024,
      prompt: '\n\nHuman: Hello, Claude\n\nAssistant:'
    };

    try {
      const result = await axios.post(apiUrl, requestData, {
        headers: {
          'x-api-key': apiKey,
          'anthropic-version': '2023-06-01',
          'Content-Type': 'application/json'
        }
      });

    //   setResponse(result.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    //   setResponse('Error fetching data.');
    }
    // console.log(obj)
    // return await axios.post(`https://api.anthropic.com/v1/complete`, obj,{
    //     headers: {
    //         "x-api-key": `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`,
    //         "anthropic-version": "2023-06-01",
    //         "Content-Type": "application/json",
           
    //       }
    // }).then(resp=>{
    //     saveTokens(resp.data?.usage?.total_tokens)
    //     return resp.data?.content[0]?.text;
    // }).catch(err=>{
    //     console.log(err.response)
    //     return "error";
    // })
}
export const getDavinchiModel =  (obj) => async dispatch =>{
    return await axios.post(`${API1}`, obj,{
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    }).then(resp=>{
        saveTokens(resp.data?.usage?.total_tokens)
        return resp.data?.choices[0]?.text;
    }).catch(err=>{
     
        return "error";
    })
}
export const getTurboModel =  (obj) => async dispatch =>{
    // console.log(obj)
    return await axios.post(`${API2}`, obj,{
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    }).then(resp=>{
        // console.log(resp.data)
        saveTokens(resp.data?.usage?.total_tokens)
        return resp.data.choices[0].message.content;
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}
const test= async (response)=>{
    // const blob = await response.blob();
    const url = window.URL.createObjectURL(response);
    console.log(url)
}
export const getTextToSpeech =  (obj) => async dispatch =>{
    // console.log(obj)
    return await axios.post(`https://api.openai.com/v1/audio/speech`, obj,{
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    }).then(resp=>{
        // test(resp?.data)
       
        // Create the audio element
        // const audio = new Audio(`data:audio/mp3;base64,${resp.data}`); 
        // console.log(audio)
        // audio.play()
        // const newBlob =  new Blob(resp?.data, { 'type' : 'audio/mp3'})
        // const audio_url = (window.URL || window.webkitURL).createObjectURL(newBlob);
        // console.log(newBlob)
        // const byteCharacters = atob(resp.data);
        // console.log(byteCharacters)
        // saveTokens(resp.data?.usage?.total_tokens)
        // return resp.data.choices[0].message.content;
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}

export const getMistralModel =  (obj) => async dispatch =>{

    return await axios.post(`https://api.mistral.ai/v1/chat/completions`, obj,{
        headers: {
            "Authorization": `Bearer toswwjpjLoQiXiq6CZoAmDKka99CuNhJ`,
            "Content-Type": "application/json"
          }
    }).then(resp=>{
        saveTokens(resp.data?.usage?.total_tokens)
        return resp.data.choices[0].message.content;
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}
export const getAnthropicModelMain =  (obj) => async dispatch =>{

    return await axios.post(`https://api.anthropic.com/v1/messages`, obj,{
        headers: {
            "x-api-key": `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`,
            "Content-Type": "application/json",
            'anthropic-version': '2023-06-01'
          }
    }).then(resp=>{
        // saveTokens(resp.data?.usage?.total_tokens)
        return resp.data.content[0]?.text;
    }).catch(err=>{
        console.log(err.response)
        return "error";
    })
}

