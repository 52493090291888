import React, { Component } from 'react'
import ProfileInp from './ProfileInp'
import { mixinAlert } from '../actions/SweetAlerts'
import { connect } from 'react-redux'
import { insertNewData, checkUser } from '../actions/MainAction'
export class EditProfile extends Component {
    state={
        first_name:this.props.data?.first_name?this.props.data?.first_name:'',
        last_name:this.props.data?.last_name?this.props.data?.last_name:'',
        password:this.props.data?.password?this.props.data?.password:'',
        email:this.props.data?.email?this.props.data?.email:'',
        date_of_birth:this.props.data?.date_of_birth?this.props.data?.date_of_birth:'',
        btnDisable:false,
        base64:'',
        avatar_url:''
    }
    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload  =()=>{
                res(reader.result)
            }
            reader.readAsDataURL(file)
        })
    }
    changeImgValue(e){
        this.getBase64(e.target.files[0])
        .then(resp=>{
            this.setState({base64: resp, avatar_url:e.target.files[0]})
        })
    }
    updateBtn=(data)=>{
        if(data?.first_name !== "" && data?.last_name !== "" && data?.email !== ""){
            if(data?.password !== "" && !this.props.passwordPermission){
                mixinAlert("info", this.props.lang?.min_8_words)
                return;
            }
            this.setState({btnDisable:true})
            let formData = new FormData();
            for(const property in this.props.data){
                if(this.props.data[property])
                    formData.append(property, this.props.data[property]);
            }
            for(const property in data){
                if(data[property])
                    formData.append(property, data[property]);
            }
            formData.append("_method", "put");
            this.props.insertNewData(`users/${this.props.data?.id}`, formData)
            .then(resp=>{
                this.setState({btnDisable:false})
                if(resp === "success"){
                    mixinAlert("success", "Profile updated successfully!");
                    this.props.checkUser();
                    this.props.closeEdit()
                }else{
                    mixinAlert("error", this.props.lang?.profile_email_error);
                }
            })
        }else{
            mixinAlert("info", "Please, fill the required inputs!")
        }
    }
  render() {
    const {first_name, last_name, email, password, date_of_birth, btnDisable, base64} = this.state;
    const {data, lang} = this.props;
    return (
      <div>
        <div className='bg-white mt-[24px] rounded-[12px] p-[24px]'>
            <span className='txt-blue text-[24px] font-bold'>{lang?.edit}</span>
        </div>
        <div className="w-[150px] h-[150px] bg-white rounded-full items-center justify-center flex text-center mt-[30px] cursor-pointer">
            {
                base64 !== ""?
                <label className='h-full w-full' htmlFor='profImgIdInp'>
                     <img className='w-full h-full rounded-full cursor-pointer' src={base64} />
                </label>:
                data?.avatar_full_url?
                <label className='h-full w-full' htmlFor='profImgIdInp'>
                    <img className='w-full h-full rounded-full cursor-pointer' src={data?.avatar_full_url} />
                </label>:
                <label className='h-full w-full text-center inline-flex items-center' htmlFor='profImgIdInp'>
                    <span className='txt-blue font-semibold cursor-pointer'>{lang?.upl_pr_img}</span>
                </label>
            }
            <input id='profImgIdInp' type='file' onChange={this.changeImgValue.bind(this)} className='hidden' accept='image/png, image/jpg, image/jpeg, image/webp' />
            
        </div>
        <div className='mt-[22px] grid grid-cols-5 gap-[28px] items-start max-[1700px]:grid-cols-4 max-[1400px]:grid-cols-3 max-[800px]:grid-cols-2 max-[500px]:grid-cols-1'>
           <ProfileInp 
            title={lang?.first_name}
            onChange={(e)=>this.setState({first_name:e.target.value})}
            value={first_name}
            required={true}
           />
           <ProfileInp 
            title={lang?.last_name}
            onChange={(e)=>this.setState({last_name:e.target.value})}
            value={last_name}
            required={true}
           />
           <ProfileInp 
            title={lang?.email}
            type='email'
            onChange={(e)=>this.setState({email:e.target.value})}
            value={email}
            required={true}
           />
           <ProfileInp 
            title={lang?.date_of_birth}
            type='date'
            onChange={(e)=>this.setState({date_of_birth:e.target.value})}
            value={date_of_birth}
           />
           <ProfileInp 
            title={lang?.password}
            type='password'
            onChange={(e)=>this.setState({password:e.target.value})}
            value={password}
           />
        </div>
        <div className='flex justify-end mt-[20px] '>
            <button disabled={btnDisable} onClick={this.updateBtn.bind(this, this.state)} className='w-[141px] h-[35px] rounded-[6px] text-white bg-blue text-[14px] font-bold'>{lang?.update}</button>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang,
    passwordPermission: state.Data.passwordPermission,
});
const mapDispatchToProps = {insertNewData, checkUser}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile)
