import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import HeaderButtons from '../../components/HeaderButtons';
import { getOfficeToeflExams } from '../../actions/StaffAction';
import Card1 from '../../components/cards/Card1';
import moment from 'moment';
import { changeStateValue } from '../../redux/MainReducer';
import ToelfModal from '../../components/modals/ToelfModal';
import EmptyPage from '../../components/EmptyPage';
export class ToeflExams extends Component {
  state={
    selected:0,
  }
  componentDidMount(){
    this.props.getOfficeToeflExams('toefl-exam-writing');
    window.scrollTo(0,0)
  }
  changePage=(data)=>{
    this.props.getOfficeToeflExams(data?.id);
    this.setState({selected: data?.title === "Writing"?0:1})
  }
  getScore(part1, part2, part3, part4, page){
    if(page == 0){
        if(part1 && part2){
            return +part1 + +part2
        }else{
            return ' '
        }
    }else  if(page == 1){
        if(part1 && part2 && part3 && part4){
            return +part1 + +part2 + +part3 + +part4
        }else{
            return ' '
        }
    }
  }
  showResultBtn=(data)=>{
    let obj = {};
    if(this.state.selected == 0){
      obj={
        part_1_reading_title: data?.reading_title,
        part_1_reading: data?.reading_description,
        part_1_reading_answer: data?.reading_answer,
        part_1_answer: data?.reading_answer,
        part_1_answer: data?.reading_answer,
        part_1_listening_title: data?.listening_title,
        part_1_listening: data?.listening,
        part_1_listening_full_url: data?.listening_full_url,
        part_2_answer: data?.part_2_reading_answer,
      }
    }
    this.props.changeStateValue({
      name:'toeflModalData',
      value:{position:true, ...data, ...obj, modalType: this.state.selected == 0?"writing":"speaking"}
    })
  }
  render() {
    const { officeToeflExams, toeflModalData } = this.props;
    const {selected} = this.state;
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title="TOEFL Exams" />
        <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
          <HeaderButtons selected={selected} onClick={this.changePage.bind(this)} buttons={[{title: "Writing", id:'toefl-exam-writing'}, {title: "Speaking", id:'toefl-exam-speaking'}]} />
        </div>
        {officeToeflExams?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
        <div className='mt-[22px] grid-full-220'>
            {
              officeToeflExams?.map((data, i)=>{
                return(
                  <Card1
                    key={i}
                    title={data?.student?.full_name}
                    exam={data?.exam?.title}
                    status={data?.status}
                    time={data?.spend_time}
                    grade={this.getScore(data.part_1_score,data.part_2_score,data.part_3_score, data.part_4_score, selected)}
                    statusText={data?.score === 1? "Passed": "Failed"}
                    date={moment(data?.updated_at).format("DD/MM/YYYY")}
                    onCLickBtn={this.showResultBtn.bind(this, data)}
                  />
                )
              })
            }
        </div>
        {toeflModalData?.position? <ToelfModal />:null}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  officeToeflExams: state.Data.officeToeflExams,
  toeflModalData: state.Data.toeflModalData,
});
const mapDispatchToProps = {getOfficeToeflExams, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ToeflExams)
