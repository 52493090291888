import React, { Component } from 'react'
import { NavLink as Nav } from 'react-router-dom'
export class NavLink extends Component {
  render() {
    const {svg, title, path="/"} = this.props;
    return (
        <li className='flex '>
            <Nav to={path} className='flex gap-[8px] items-center w-full p-[13px] rounded-[10px]'>
                {svg}
                <span className='txt-dark-gray text-[14px] font-regular'>{title}</span>
            </Nav>
        </li>
    )
  }
}

export default NavLink
