import React, { Component } from 'react'
import Instructions from './speaking/Instructions'
import TestMic from './speaking/TestMic'
import Part1Intro from './speaking/Part1Intro'
import Part1Questions from './speaking/Part1Questions'
import Part2Intro from './speaking/Part2Intro'
import Part2Questions from './speaking/Part2Questions'
import Part3Intro from './speaking/Part3Intro'
import Part3Questions from './speaking/Part3Questions'
import { confirmAlert, mixinAlert } from '../../../actions/SweetAlerts'
import { insertNewData } from '../../../actions/MainAction'
import { changeStateValue } from '../../../redux/MainReducer'
import { connect } from 'react-redux'
import { withHooks } from '../../../actions/withHooks'
import { getHTML } from '../../../actions/getRole'

export class SpeakingAnswerParent extends Component {
    state={
        page:0,
        btnDisable:false
    }
    getExamsQuestions(questions){
      let text = "";
      for(const data of questions){
        text+=`${getHTML(data?.description)} \n`
      }
      return text;
    }
    subMitExam=(examsData)=>{
      confirmAlert("warning", "Are you sure?", "You won’t be able to come back to this section if you move on. Do you want to submit?", "Yes, Submit", "","No, Go Back")
      .then(respAlert=>{
        if(respAlert === "success"){
          this.setState({btnDisable:true})
          let formData = new FormData();
          formData.append("question_1_id", examsData[0]?.questions[0]?.id);
          formData.append("question_2_id", examsData[1]?.questions[0]?.id);
          formData.append("question_3_id", examsData[1]?.questions[1]?.id);
          formData.append("part_1_questions", this.getExamsQuestions(examsData[0]?.questions));
          formData.append("part_2_questions", getHTML(examsData[1]?.questions[0].description));
          formData.append("part_3_questions", this.getExamsQuestions(examsData[1]?.questions?.filter(x=> x?.question_type === "speaking_type_3")));
          formData.append("answer_1", examsData[0]?.given_answer,examsData[0]?.given_answer?.name);
          formData.append("answer_2", examsData[1]?.given_answer1,examsData[1]?.given_answer1?.name);
          formData.append("answer_3", examsData[1]?.given_answer2,examsData[1]?.given_answer2?.name);
          formData.append("studentExam_id", this.props.studentExam_id);
          formData.append("exam_id", this.props.exam_id);
          if(this.props.parent_id){
            formData.append("parent_id", this.props.parent_id)
          }
          this.props.insertNewData(this.props.url, formData)
          .then(resp=>{
            this.setState({btnDisable:false})
            if(resp == "success"){
                this.props.changeStateValue({name:"sideBarVisible", value:true})
                this.props.navigate(this.props.path)
              
            }else{
              mixinAlert("error", "Something went wrong");
            }
          })
        }
      })
      
      // console.log(examsData)
    }
  render() {
    const {data, examsData} = this.props;
    const {page, btnDisable} = this.state;
    // console.log(examsData)
    return (
      <div className='bg-white p-[20px] shadow border grid grid-cols-1 h-[calc(100vh_-_170px)] '>
        <div className='flex w-full h-[calc(100vh_-_200px)] overflow-auto pr-[20px] flex-col gap-[20px] border-r border-r-[2px] scroll-r'>
            {
                page === 0?
                <Instructions onChangePage={()=>this.setState({page:1})} />:
                page === 1?
                <TestMic onChangePage={()=>this.setState({page:2})} />:
                page === 2?
                <Part1Intro  onChangePage={()=>this.setState({page:3})} />:
                page === 3?
                <Part1Questions onReset={()=>this.setState({page:2})} onChangePage={()=>this.setState({page:4})}  data={data[0]} />:
                page === 4?
                <Part2Intro onChangePage={()=>this.setState({page:5})} />:
                page === 5?
                <Part2Questions onReset={()=>this.setState({page:4})} onChangePage={()=>this.setState({page:6})}  data={data[1]} />:
                page === 6?
                <Part3Intro onChangePage={()=>this.setState({page:7})}  />:
                <Part3Questions btnDisable={btnDisable} onReset={()=>this.setState({page:6})} onSubmitExam={this.subMitExam.bind(this, examsData)}  data={data[1]} />
            }
        </div>
        
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  examsData: state.Data.examsData
});
const mapDispatchToProps = {insertNewData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SpeakingAnswerParent))
