import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getSpeakingTeacherCheckins } from '../../actions/StaffAction';
import Card1 from '../../components/cards/Card1';
import { insertNewData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import EmptyPage from '../../components/EmptyPage';
import { changeStateValue } from '../../redux/MainReducer';
import Search from '../../components/Search';
export class SpeakingTeacherCheckins extends Component {
    state={
        page:1,
        search:''
    }
    componentDidMount(){
        this.props.getSpeakingTeacherCheckins({page:1, keyword:''});
        window.scrollTo(0,0)
        this.props.changeStateValue({
            name:'speakingTeacherCheckins',
            value:[]
        })
    }
    onClickCheckin=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, Check-In")
        .then(respAlert=>{
            if(respAlert === "success"){
                const params = {
                    company_id: this.props.user.company_id,
                    teacher_id: this.props.user.id,
                    student_id: data?.id
                }
                let formData = new FormData();
                for (const property in params) {
                    if (params[property])
                        formData.append(property, params[property]);
                }
                this.props.insertNewData("speaking-teacher-attendance", formData)
                .then(resp=>{
                    if(resp === "success"){
                        this.props.getSpeakingTeacherCheckins({page:this.state.page});
                        mixinAlert("success", "Check-In successfully!")
                    }else{
                        mixinAlert("error", "Something went wrong!")
                    }
                })
            }
        })
       
    }
    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getSpeakingTeacherCheckins({page:page, search: this.state.search});
    }
    searchData=(search)=>{
        this.setState({search:search})
        this.props.getSpeakingTeacherCheckins({page:this.state.page, keyword: search});
    }
  render() {
    const {speakingTeacherCheckins} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="sp_check_ins" />
            <div className='flex justify-center'>
                <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
            </div>
            {speakingTeacherCheckins?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}

            <div className='mt-[42px] grid-full-250  max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {
                    speakingTeacherCheckins.map((data, i)=>{
                        return(
                            <Card1 
                                key={i}
                                title={data?.full_name}
                                email={data?.email}
                                phone={data?.phone_number}
                                status={1}
                                statusBisible={false}
                                btn={false}
                                btn2={true}
                                btn2Text={"Check In"}
                                btn2Color="bg-blue"
                                cheating={data?.student_enroll_class[0]?.lesson_mode}
                                onCLickBtn={this.onClickCheckin.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
            {
                speakingTeacherCheckins?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={speakingTeacherCheckins?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    speakingTeacherCheckins: state.Data.speakingTeacherCheckins,
    user: state.Data.user,
});
const mapDispatchToProps = {getSpeakingTeacherCheckins, insertNewData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SpeakingTeacherCheckins)
