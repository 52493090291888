import React, { Component } from 'react'
import EyeSvg from './svg/EyeSvg';
import EyeCloseSvg from './svg/EyeCloseSvg';
import { connect } from 'react-redux';
import { checkPasswordStrength } from '../actions/getRole';
import PasswordStrength from './PasswordStrength';

export class ProfileInp extends Component {
    state={
        typeInp:"password",
    }

    onChangeInp=(e)=>{
        this.props.onChange(e);
    }
  render() {
    const {value, title, type, required=false, onChange=()=>{}, lang} = this.props;
    const {typeInp} = this.state
    return (
        <div className='flex flex-col gap-[20px] p-[15px] bg-white rounded-[12px] min-h-[148px]'>
            <span className='font-semibold text-[17px] txt-light-black'>{title}</span>
            {
                type !== "password"?
                <input onChange={(e)=>onChange(e)} value={value} className='bg-[#F4F7FE] h-[35px] rounded-[6px] txt-blue outline-none pl-[4px]' placeholder={title} type={type} />:
                <div className='bg-[#F4F7FE] h-[35px] rounded-[6px] txt-blue outline-none pl-[4px] inline-flex justify-between'> 
                    <input onChange={this.onChangeInp.bind(this)} value={value} className='bg-[#F4F7FE] h-[35px] rounded-[6px] txt-blue outline-none pl-[4px] w-full' placeholder={title} type={typeInp} />
                    <button onClick={()=>this.setState({typeInp: typeInp==="password"?"text":"password"})}>
                        {
                            typeInp === "password"?
                            <EyeSvg color="var(--blue)" />:<EyeCloseSvg color="var(--blue)" />
                        }
                    </button>
                </div>
            }
           {
             type === "password"?
             <PasswordStrength strength={checkPasswordStrength(value)} />:null
           }
            {
                required && value ===""?
                <span className='text-[12px] txt-red mt-[-15px]'>{lang?.field_required}</span>:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
})
export default connect(mapStateToProps)(ProfileInp)
