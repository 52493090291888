import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { changeStateValue } from '../../redux/MainReducer';
import { getBlockedLogins } from '../../actions/StaffAction';
import { connect } from 'react-redux';
import Card4 from '../../components/cards/Card4';
import { getRole } from '../../actions/getRole';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
import HeaderButtons from '../../components/HeaderButtons';

export class LoginBlocks extends Component {
    state={
        type:"own",
        selected:0
    }
    componentDidMount(){
        this.props.getBlockedLogins("own")
    }

    onClickDelete=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
          if(alertResp === "success"){
            this.props.deleteData(`login-block/${id}`)
            .then(resp=>{
              if(resp == "success"){
                this.props.getBlockedLogins(this.state.type)
                mixinAlert("success", "Data deleted successfully")
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }
        })
    }
    onClicktype=(data)=>{
        this.setState({
            type:data?.value,
            selected:data?.value=="own"?0:1
        })
        this.props.getBlockedLogins(data?.value)
        // console.log(data)
    }
  render() {
    const {loginBlocksData, user} = this.props;
    const {selected} = this.state;
    // console.log(loginBlocksData)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header  title="Login Block" />
            <div className='flex justify-start mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
                {
                    user?.role == "support"?
                    <HeaderButtons buttons={[{title:"Own", value:"own"}, {title:"Others", value:"others"}]} selected={selected} onClick={this.onClicktype.bind(this)} />:null
                }
            </div>
            <div className='mt-[20px] grid grid-full-250'>
                {
                    loginBlocksData?.map((data, i)=>{
                        return(
                            <Card4
                                key={i}
                                name={data?.student?.full_name}
                                email={data?.student?.email}
                                phone={data?.student?.phone_number}
                                image={data?.student?.avatar_full_url}
                                role={getRole(data?.student?.role, this.props.lang)}
                                company={data?.student?.company?.name}
                                deleteBtn={true}
                                onClickDelete={this.onClickDelete.bind(this, data?.id)}
                                editBtnPermisiion={false}
                            />
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    loginBlocksData: state.Data.loginBlocksData,
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getBlockedLogins, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(LoginBlocks)
