import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCompanies } from '../../../actions/PublicActions';
import img from "../../../images/celtLogo.png"
import { Link } from 'react-router-dom';
export class SelectCompany extends Component {
    state={
        companies:[]
    }
    componentDidMount(){
        this.props.getCompanies()
        .then(resp=>{this.setState({companies:resp})})
    }
    selectCompany(data, e){
        if(!e.target.className.includes("link-btn")){
            this.props.onSelectCompany(data?.id)
        }
        
    }
  render() {
    const {companies} = this.state;
    const {selected, onSelectCompany=()=>{}} = this.props;
    return (
      <div className='mt-[20px] max-h-[60vh] overflow-auto'>
            <div className='flex justify-center'>
                <h3 className='txt-blue font-semibold text-[24px]'>Select a center</h3>
            </div>
            <div className='grid-full-250 p-[4px]'>
                {
                    companies?.map((data, i)=>{
                        return(
                            <div onClick={this.selectCompany.bind(this, data)} key={i} className={`border ${selected===data?.id? 'border-blue-700':'border-gray-300'}  p-[20px] rounded-[12px] cursor-pointer hover:shadow-lg flex flex-col items-center gap-[7px] hover:border-blue-700`}>
                                <img className='w-[100px]' src={img} alt={data?.title} />
                                <h4 className='txt-blue font-semibold text-[18px]'>{data?.title}</h4>
                                <a target='_blank' href={data?.location} className='link-btn bg-blue pt-[5px] text-white text-[14px] h-[30px] font-semibold pl-[8px] pr-[8px] rounded-[12px]'>View on map</a>
                            </div>
                        )
                    })
                }
               
            </div>
      </div>
    )
  } 
}
const mapStateToProps = (state) =>({ });
const mapDispatchToProps = {getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(SelectCompany)
