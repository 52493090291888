import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { insertNewData } from '../../actions/MainAction';
import FileInput from '../FileInput';
import Input from './components/Input';
import { mixinAlert } from '../../actions/SweetAlerts';
export class AddPaymentModal extends Component {
    state={
        fileType:'image',
        btnDisabled:false
    }
    closeBtn=()=>{
        this.props.resetStateValue({name:'addPaymentModal'});

    }
    changeFileType=(type)=>{
        this.setState({
            fileType:""
        });
        this.props.changeStateValue({ name:"addPaymentModal.image", value:null })
        this.props.changeStateValue({ name:"addPaymentModal.image_full_url", value:null })
        this.props.changeStateValue({ name:"addPaymentModal.file", value:null })
        this.props.changeStateValue({ name:"addPaymentModal.file_full_url", value:null })
        setTimeout(()=>{
            this.setState({
                fileType:type
            });
        },100)
    }
    saveBtn=(data)=>{
        if(data?.count =="" || data?.count <= 0 || (!data?.image && !data?.file)){
            mixinAlert("warning", "Please add count or upload file");
            return
        }
        this.setState({btnDisabled:true})
        let formData = new FormData();
        for(const property in data){
            if(data[property]){
                formData.append(property, data[property]);
            }
        }
        let url = 'enroll-payments';
        this.props.insertNewData(url, formData)
        .then(resp=>{
            this.setState({btnDisabled:false})
            if(resp == "success"){
                mixinAlert("success", "Payment added successfully");
                this.closeBtn();
                data.aftertCallFunction(...data.afterCallParams)
            }else{
                mixinAlert("error", "Something went wrong");
            }
        })
    }
  render() {
    const {addPaymentModal, lang} = this.props;
    const {fileType, btnDisabled} = this.state
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[400px] max-h-[80vh] overflow-auto max-sm:w-[90%] p- rounded-[10px]'>
                <Header closeBtn={()=>this.closeBtn()} title={lang?.lesson_payment} />
                <div className='p-[20px]'>
                    <div className='flex flex-col gap-[20px]'>
                        <div>
                            <div className='flex items-center gap-[10px] mb-[10px]'>
                                <button onClick={this.changeFileType.bind(this, 'image')} className={`text-[12px] ${fileType=="image"? ' bg-blue text-white':'border txt-blue'} rounded-[4px] font-regular h-[20px] p-[0_5px] `}>Image</button>
                                <button onClick={this.changeFileType.bind(this, 'pdf')} className={`text-[12px] ${fileType=="pdf"? ' bg-blue text-white':'border txt-blue'} rounded-[4px] font-regular h-[20px] p-[0_5px] `}>PDF</button>
                            </div>
                            {
                            fileType=="image"?
                            <FileInput required type="image" name="addPaymentModal.image" placeholder={'Payment receipt only.'} title="Upload Image" value={addPaymentModal?.image_ful_url} valueName="addPaymentModal.image_full_url"  />:
                            fileType == "pdf"?
                            <FileInput required  type="application/pdf" name="addPaymentModal.file" placeholder={'Payment receipt only.'} value={addPaymentModal?.file} title="Upload file" valueName="addPaymentModal.file_full_url" />:null
                            }
                        </div>
                        { addPaymentModal?.count || addPaymentModal?.count ===""? <Input  required name="addPaymentModal.count" type="number" value={addPaymentModal?.count}  title={lang?.num_lessons} />:null}
                        { addPaymentModal?.note || addPaymentModal?.note ===""? <Input  name="addPaymentModal.note" type="text" value={addPaymentModal?.note}  title={lang?.pay_note} />:null}
                       
                    </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={this.closeBtn.bind(this)} className='bg-red h-[25px] text-[12px] pl-[10px] pr-[10px]   rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,addPaymentModal)} className='bg-blue h-[25px] text-[12px] pl-[10px] pr-[10px] rounded-[5px] text-white font-semibold'>{lang?.upl_save}</button>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    addPaymentModal: state.Data.addPaymentModal,
    lang: state.Data.lang,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentModal)
