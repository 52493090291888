import React, { Component } from 'react'
import Header from '../../../../components/modals/Header'
import PrevQuestion from './PrevQuestion'
import { connect } from 'react-redux'
import { mixinAlert } from '../../../../actions/SweetAlerts';
import { resetStateValue } from '../../../../redux/MainReducer';
import { getExamQuestions, insertNewData } from '../../../../actions/AdminActions'
export class RoboPreviewModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
      this.props.resetStateValue({name: "modalRoboPrev"})
    }
    getAnswers(answers, correctAns, score){
        let ans = [];
        for(let i = 0; i < answers.length; i++){
          ans.push({
            title: answers[i],
            is_correct: correctAns == answers[i]? '1':'0',
            score: correctAns == answers[i]?score:0,
            points: correctAns == answers[i]?score:0,
          })
        }
        return ans;
    }
    getRnd=(index, sum, score)=> {
        if(score%sum == 0){
          return score/sum
        }else{
          let x = Math.floor(score / sum);
          let y = score -  (x*sum)
      
          if((index+1) <= y){
            return x+1;
          }else{
            return x
          }
        }
    }
    saveBtn=(item)=>{
        this.setState({btnDisabled:true})
        let data = item.questions
      
        this.setState({btnVisible: true})
        let questions = [];
        for(let i = 0; i < data.length; i ++){
          questions.push({
            exam_id: this.props.modalRoboPrev.exam_id,
            parent_id: this.props.modalRoboPrev?.parent_id,
            sort_id: i+1,
            title: data[i]?.question,
            description: data[i]?.description,
            type: "single_choice",
            score: this.getRnd(i, data.length, this.props.modalRoboPrev.score),
            answers: this.getAnswers(data[i]?.answers, data[i]?.correct_answer,  this.getRnd(i, data.length,this.props.modalRoboPrev.score))
          })
        }
        this.props.insertNewData(`exam-robo-create`, JSON.stringify({params: questions}))
        .then(resp=>{
            this.setState({btnDisabled:false})
            if(resp === "success"){
                this.props.resetStateValue({name: "modalRoboPrev"})
                this.props.resetStateValue({name: "modalRoboQuestion"})
                mixinAlert("success", "Question created successfully");
                this.props.getExamQuestions(this.props.modalRoboPrev.exam_id)
            }else{
                mixinAlert("error", "Something went wrong");
            }
        })
    }
  render() {
    const {modalRoboPrev} = this.props;
    const {btnDisabled} = this.state;
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[900px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={"Generated Question"} />
                <div className='bg-white p-[10px] rounded-b-[10px] max-h-[70vh] overflow-auto'>
                  <div className='flex flex-col gap-[10px]'>
                    {
                        modalRoboPrev?.questions?.map((data, i)=>{
                            return(
                                <PrevQuestion 
                                    key={i}
                                    data={data}
                                    parentIndex={i}
                                />
                            )
                        })
                    }
                  </div>
                  <div className='flex justify-between items-center mt-[20px]'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Close</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,modalRoboPrev)} className='bg-orange pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Save</button>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    modalRoboPrev: state.Data.modalRoboPrev
});
const mapDispatchToProps = {insertNewData, resetStateValue, getExamQuestions}
export default connect(mapStateToProps, mapDispatchToProps)(RoboPreviewModal)
