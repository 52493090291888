import React, { Component } from 'react'

export class CardEventSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 24 24"
        width={15}
        height={15}
        fill="var(--blue)"
      >
        <path d="M6 2v2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V2h-2v2H8V2H6zM5 9h14v11H5V9z" />
        <path
          d="M0 0h24v24H0z"
          style={{
            fill: "none",
          }}
        />
        <path d="m16.4 12-4 4.2-1.9-1.8-1.2 1.2 2.5 2.4.6.7.6-.7 4.6-4.8z" />
      </svg>
    )
  }
}

export default CardEventSvg
