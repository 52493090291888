import React, { Component } from 'react'
import SupportMessage from '../SupportMessage'

export class SupportMain extends Component {
  render() {
    return (
      <div>
        <SupportMessage />
      </div>
    )
  }
}

export default SupportMain
