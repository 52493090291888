import React, { Component } from 'react'

export class WritingSectionDirection extends Component {
  render() {
    const {p1='', p2='', p3='', p4='', p5='', title=''} = this.props
    return (
      <div>
        <h2 className='text-center txt-blue text-[22px] font-bold'>{title}</h2>
        <div className='text-[15px] flex flex-col gap-[15px]'>
            {
                p1 !=''?
                <p>{p1}</p>:null
            }
            {
                p2 !=''?
                <p>{p2}</p>:null
            }
            {
                p3 !=''?
                <p>{p3}</p>:null
            }
            {
                p4 !=''?
                <p>{p4}</p>:null
            }
            {
                p5 !=''?
                <p>{p5}</p>:null
            }
            
           
            <span className='text-center'>(Click on <b>Continue</b> at any time to dismiss these directions.)</span>
        </div>
      </div>
    )
  }
}

export default WritingSectionDirection