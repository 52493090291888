import moment from 'moment';
import React, { Component } from 'react'
import defaultImg from "../../images/defaultUser.jpeg"
import { getRole } from '../../actions/getRole';
export class DateOfBirthCard extends Component {
  getYears(dob){
    let date = new Date(dob)
    return date.getFullYear()
  }
  render() {
    const {dobs} = this.props;
    // console.log( dobs.filter(a=>moment(a?.date_of_birth).format("DD-MM") != moment(new Date()).format("DD-MM")))
    return (
      <div className=' rounded-b-[10px]  rounded-t-[10px]'>
        <div className='bg-blue rounded-t-[10px] h-[43px] flex items-center justify-center'>
            <h3 className='text-center text-white  font-semibold text-[16px]'>{"Birthdays"}</h3>
        </div>
        <ul className='bg-white rounded-b-[10px] max-h-[550px] overflow-auto'>
          {
            dobs.map((data, i)=>{
              if(moment(data?.date_of_birth).format("DD-MM") == moment(new Date()).format("DD-MM")){
                return (
                  <li key={i} className='p-[10px]'>
                    <span className='text-[12px] txt-light-black]'>Today's Birthdays</span>
                    <div className='border rounded-[3px] p-[10px] flex items-center gap-[10px]'>
                      <img className='w-[40px] h-[40px] rounded-full' src={ data?.avatar_full_url?  data?.avatar_full_url:defaultImg } />
                      <div className='flex flex-col'>
                        <h3 className='text-[14px] txt-light-black]'>{data?.full_name}</h3>
                        <span className='text-[12px] txt-light-black]'>{getRole(data?.role)}</span>
                        <span className='text-[12px] txt-light-black]'>{this.getYears(new Date()) - this.getYears(data?.date_of_birth)} years old</span>
                      </div>
                    </div>
                  </li>
                )
              }
            })
          }
          {
             dobs.filter(a=>moment(a?.date_of_birth).format("DD-MM") != moment(new Date()).format("DD-MM")).length !=0?
             <li  className='pl-[10px]'>
              <span className='text-[12px] txt-light-black]'>Upcoming Birthdays</span>
            </li>:null
          }
         {
          dobs.filter(a=>moment(a?.date_of_birth).format("DD-MM") != moment(new Date()).format("DD-MM"))
          .map((data, i)=>{
            return (
              <li key={i} className='pl-[10px] pr-[10px] pb-[10px]'>
                <div className='border rounded-[3px] p-[10px] flex items-center gap-[10px]'>
                  <img className='w-[40px] h-[40px] rounded-full' src={ data?.avatar_full_url?  data?.avatar_full_url:defaultImg } />
                  <div className='flex flex-col'>
                    <h3 className='text-[14px] txt-light-black]'>{data?.full_name}</h3>
                    <span className='text-[12px] txt-light-black]'>{getRole(data?.role)}</span>
                    <span className='text-[12px] txt-light-black]'>{moment(data?.date_of_birth).format("MMMM DD")}</span>
                  </div>
                </div>
              </li>
            )
          })
         }
        </ul>
      </div>
    )
  }
}

export default DateOfBirthCard
