import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux';

export class BonusDataModal extends Component {
    closeModal=()=>{
        this.props.onClose()
    }
    closeModalShadow(e){
        if(typeof e.target.className == 'string' && e.target.className?.includes("background_shadow")) this.closeModal();
    }
  render() {
    const {bonus_data, lang} = this.props;
    // console.log(bonus_data)
    return (
        <div onClick={this.closeModalShadow.bind(this)} className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[400px]  shadow bg-white rounded-[10px] pb-[20px]'>
                <Header closeBtn={this.closeModal.bind(this)} title={""} />
              
                <ul className='p-[0px] pt-[0px] pb-[0px]'>
                    <li className='flex justify-between'>
                        <span className='w-1/2 text-center text-[18px] font-semibold  border-l border-t p-[4px]'>{lang?.amount}</span>
                        <span className='w-1/2 text-center text-[18px] font-semibold  border-l border-t p-[4px]'>{lang?.note}</span>
                    </li>
                </ul>
                <ul className='p-[0px] pt-[0px] max-h-[60vh] overflow-auto'>
                    {
                        bonus_data?.map((item, i)=>{
                            return(
                                <li key={i} className='flex justify-between'>
                                    <span className='w-1/2 text-center text-[15px] border-l border-t p-[4px] font-[300]'>{item?.bonus}</span>
                                    <span className='w-1/2 text-center text-[15px] border-l border-t p-[4px] font-[300]'>{item?.title}</span>
                                </li>
                            )
                        })

                    }
                </ul>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
})
export default connect(mapStateToProps)(BonusDataModal)
