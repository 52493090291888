import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getSpPracticeReviews } from '../../actions/StaffAction';
import Search from '../../components/Search';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Card1 from '../../components/cards/Card1';
import moment from 'moment';
import { changeStateValue } from '../../redux/MainReducer';
import EmptyPage from '../../components/EmptyPage';
import { getSpPracticeGrade, getSpPracticeReview } from '../../actions/getReviewGpt';
import { getTurboModel } from '../../actions/robotActions';
import { insertNewData } from '../../actions/MainAction';
import { getGrades, getStringGrade } from '../../actions/getRole';
import AdminReviewLogModal from '../../components/modals/AdminReviewLogModal';
export class CeltSpeakingPractice extends Component {
  state={
    page:1,
    search:'',
    logModal:{
      position:false,
      review:'',
      grade:''
  }
  }
  componentDidMount(){
    this.props.getSpPracticeReviews(1, '');
    window.scrollTo(0,0)
  }
  onSearchData=(search)=>{
    this.setState({search: search})
    this.props.getSpPracticeReviews(this.state.page, search)
  }
  loadMore=(page)=>{
    this.setState({page: page})
    this.props.getSpPracticeReviews(page, this.state.search)
  }
  openModal=(data, btnType="")=>{
      
    const params = {
        position:true,
        type:'sp_practice',
        modalType: 'review',
        title: data?.speaking?.title,
        question: data?.speaking?.question,
        reviews: btnType=="recheck"?null: `<pre class="break-words whitespace-break-spaces">${data?.robo_review}</pre>`,
        teacher:'Selty',
        role: 'Teacher',
        stuent: data?.user?.full_name,
        grade:btnType=="recheck"?null: data?.robo_grade?data?.robot_grade:data?.teacher_grade,
    }
    this.props.changeStateValue({name: 'mainModal', value: params});
    if(btnType=="recheck"){
      setTimeout(()=>{
        this.props.changeStateValue({  name:'mainModal.speaking_robo_waiting',  value:true })
       
      }, 200)
      this.props.getTurboModel(getSpPracticeGrade(data))
      .then(gradeResp=>{
        let grade = 1;
        try{
          if(data?.speaking?.course_id==36){
            grade =  getStringGrade(gradeResp)
          }else{
            grade =  getGrades(gradeResp)
          }
          
        }catch(err){ grade = 0}
        // console.log(grade)
        this.props.getTurboModel(getSpPracticeReview(data))
        .then(respReview=>{
          let review_text = respReview?.replaceAll("#", "")?.replaceAll("*", "");
          if(grade == 0){
            review_text = `Dear Student,\n\nI looked at your speaking practice. It is scored lower. Because of this, I can't fully review it right now. I'm sorry for this.\n\nCould you try speaking again? Make sure your pronunciation is clear, ideas are well structured. Think of IELTS speaking rules. Thanks for your hard work. I'm eager to see your next Sp. Practice.\n\nBest regards,\nSelty Teacher`
          }
          let review = {
            id:data?.id,
            robo_review: review_text,
            grade_log: gradeResp,
            review_log: respReview
          }
          if(data?.speaking?.course_id==36){
            review.teacher_grade = grade
          }else{
            review.robo_grade = grade;
          }
          this.props.insertNewData('speaking-practice-recheck', review)
          .then(resp=>{
            this.props.changeStateValue({  name:'mainModal.speaking_robo_waiting',  value:false })
            this.props.changeStateValue({  name:'mainModal.reviews',  value:`<pre class="break-words whitespace-break-spaces">${review_text}</pre>` })
            this.props.changeStateValue({  name:'mainModal.grade',  value:grade })
            this.props.getSpPracticeReviews(this.state.page, this.state.search)
          })
        })
      })
    }

}
openLog=(data)=>{
  this.setState({
      logModal:{
          position:true,
          review:data?.review_log,
          grade:data?.grade_log
      }
  })
}
  render() {
    const {officeSpPractice, user, lang} = this.props;
    const {logModal} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="sp_practice" />
            <div className='flex justify-center  max-[1100px]:mt-[120px] max-[800px]:mt-[180px] '>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            {officeSpPractice.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250 '>
                  {
                    officeSpPractice?.map((data, i)=>{
                            return(
                                <Card1
                                    key={i}
                                    title={data?.speaking?.title}
                                    name={data?.student?.full_name}
                                    userLink={`/students/${data?.student?.id}`}
                                    date={moment(data?.created_at).format("DD/MM/YYYY")}
                                    grade={data?.robo_grade?data?.robo_grade:data?.teacher_grade}
                                    status={data?.speaking?.course_id==36?1:+data?.robo_grade >=data?.speaking?.course_prompt?.grade?1:0}
                                    onCLickBtn={this.openModal.bind(this, data, "")}
                                    exam={data?.speaking?.course?.title}
                                    reCheckBtn={user?.role === "super_admin"?true: false}
                                    onReCheck={this.openModal.bind(this, data, "recheck")}
                                    statusText={+data?.robo_grade >=data?.speaking?.course_prompt?.grade ?lang?.pass: lang?.fail}
                                    adminLogBtn={user?.role == "super_admin"}
                                    adminLogOnclick={this.openLog.bind(this, data)}
                                    statusBisible={data?.speaking?.course_id==36?false:true}
                                />
                            )
                        })
                    }
                </div>
                {
                    officeSpPractice?.length >=20?
                    <LoadMoreBtn onLoadData={this.loadMore} count={officeSpPractice?.length} />:null
                }
                 {
                logModal?.position?
                <AdminReviewLogModal grade={logModal?.grade} review={logModal?.review} onClose={()=>this.setState({logModal:{position:false}})} />:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  officeSpPractice: state.Data.officeSpPractice,
  user: state.Data.user,
  lang: state.Data.lang,

});
const mapDispatchToProps = {getSpPracticeReviews, changeStateValue, getTurboModel, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(CeltSpeakingPractice)
