import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import StartExamCircleSvg from '../svg/StartExamCircleSvg'
import MoreSvg from '../svg/MoreSvg';

export class PublicCard5 extends Component {
  render() {
    const {svg, title, status, percentage="", url} = this.props;
    return (
        <Link to={url} className='flex shadow-[0_0px_4px_1px_rgba(0,0,0,0.25)] p-[21px] rounded-[5px] items-center gap-[56px]  max-[1023px]:flex-col  max-[1023px]:gap-[20px]'>
            <div className='relative flex justify-center items-center w-[146px] h-[146px]'>
                <div className='relative top-0 left-0'><StartExamCircleSvg /></div>
                <div className='absolute'>{svg}</div>
            </div>
            <span className='w-[3px] h-[113px] bg-[#FFAF36] inline-block max-[1023px]:w-[113px] max-[1023px]:h-[3px]'></span>
            <div className='w-full'>
                <h3 className='font-bold text-[40px]'>{title}</h3>
                <div className='flex items-center justify-between'>
                    <div>
                        {percentage !==""? <h4 className='font-bold text-[30px]'>{percentage} %</h4>:null}
                        <span className='font-medium text-[16px] text-[#555555]'>{status}</span>
                    </div>
                    <MoreSvg />
                </div>
            </div>
        </Link>
    )
  }
}

export default PublicCard5
