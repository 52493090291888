import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { withHooks } from '../../actions/withHooks';
import { getStudentExamReviews } from '../../actions/StaffAction';
import BottomSvg from '../../components/svg/BottomSvg';
import ExamPreviewParentCard from '../../components/cards/ExamPreviewParentCard';
export class ExamResultsReview extends Component {
    state={
        examDetails:''
    }
    componentDidMount(){
        this.props.getStudentExamReviews(this.props.params.id, {exam_type:"exam", type:"current"})
        .then(resp=>{ this.setState({examDetails:resp})  });
        window.scrollTo(0,0)
    }
  render() {
    const {studentExamReviews} = this.props;
    const {examDetails} = this.state;
    // console.log(studentExamReviews)
    // console.log(examDetails)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="exams_graded" />
            <div className='bg-white rounded-[12px] pl-[21px] pt-[11px] pb-[11px] mt-[36px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <h2 className='font-bold text-[28px] txt-blue'>{examDetails?.student_exam?.exam_title}</h2>
            </div>
            <div className=' mt-[20px] flex flex-col gap-[10px]'>
                {
                    studentExamReviews?.map((data, i)=>{
                        return(
                            <ExamPreviewParentCard 
                                key={i}
                                data={data}
                            />
                           
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentExamReviews: state.Data.studentExamReviews
});
const mapDispatchToProps = {getStudentExamReviews}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamResultsReview))



