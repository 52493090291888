import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';

export class TextArea extends Component {

    onChangeInp=(e)=>{
        this.props.changeStateValue({
            name:`examsData.${this.props.index}.questions.0.given_answer`,
            value: e.target.value
        })
    }
  render() {
    const {index, value} = this.props;
    return (
      <div className='border mt-[10px] rounded-[6px] flex flex-col'>
        <div className='bg-[#F9F9F9] w-full h-[30px] rounded-[6px_6px_0_0] border-b'></div>
        <textarea value={value?value:''} onChange={this.onChangeInp.bind(this)} className='w-full border h-[60vh] border-[1px] outline-none p-[5px] text-[14px]' placeholder='Write answer...' />
        <div className='bg-[#F9F9F9] w-full h-[15px] rounded-[0_0_6px_6px] border-t'></div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(TextArea)
