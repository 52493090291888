import React, { Component } from 'react'

export class LoadingPage extends Component {
  render() {
    return (
        <div className='bg-white p-[20px] shadow border grid grid-cols-2 h-[calc(100vh_-_170px)] '>
         <div className='flex w-full h-[calc(100vh_-_200px)] overflow-auto pr-[20px] flex-col items-center justify-center gap-[20px] border-r border-r-[2px] scroll-r relative'>
             <div className="loader"></div>
         </div>
         <div className='flex w-full h-[calc(100vh_-_200px)] overflow-auto pr-[20px] flex-col items-center justify-center gap-[20px] border-r border-r-[2px] scroll-r relative'>
            <div className="loader"></div>
         </div>
      </div>
    )
  }
}

export default LoadingPage
