import React, { Component } from 'react'
import CoachErrorSvg from '../svg/CoachErrorSvg';
import CoachWarningSvg from '../svg/CoachWarningSvg';
import CoachSuccessSvg from '../svg/CoachSuccessSvg';
import { Link } from 'react-router-dom';

export class CoachPartCard extends Component {
  render() {
    const {title="", status, msg="", student_name="",teacher_id="", course_title, teacher_name="", student_id="", data=[], start_date="", end_date="", lesson_title=""} = this.props;
    
    return (
        <div className={`bg-[#fff] p-[10px] rounded-[4px] flex gap-[20px] items-center border border-[2px] ${status==2?"border-[#fd2e63]":status==1?"border-[#ff5f24]":status==0?"border-[#51bb25]":''}`}>
            {status==2?<CoachErrorSvg />:status==1?<CoachWarningSvg />:status==0?<CoachSuccessSvg />:''}
            <div className='w-full'>
                <h4 className={`font-semibold text-[13px] ${status==2?"text-[#fd2e63]":status==1?"text-[#ff5f24]":status==0?"text-[#51bb25]":''}`}> {student_name !=""? <> <span className='text-[--light-black]'><Link target='_blank' onClick={()=>{localStorage.setItem("goPayment", "true")}} to={`/students/${student_id}`}>{student_name}</Link> <br /> {teacher_name !=""? <Link target='_blank' to={`/teachers/${teacher_id}/events`}>{teacher_name} <br /> </Link>:null} </span>{course_title}  <br /></>:null} {title!=""?title:''}</h4>
                {
                  lesson_title !==""?
                  <h4 className={`font-semibold text-[13px] ${status==2?"text-[#fd2e63]":status==1?"text-[#ff5f24]":status==0?"text-[#51bb25]":''}`}>{lesson_title}</h4>:null
                }
                
                {
                  msg !=""?
                  <p  className={`font-regular text-[12px] ${status==2?"text-[#fd2e63]":status==1?"text-[#ff5f24]":status==0?"text-[#51bb25]":''}`}>{msg}</p>:null
                }
                {
                  data.length !=0?
                  <div className='flex justify-between border w-full mt-[4px] rounded-[3px]'>
                    {
                      data?.map((item, j)=>{
                        return(
                          <div key={j} className={`flex flex-col items-center p-[4px] ${j!=0?'border-l':''} w-full`}>
                            <span className={`text-[14px] font-regular ${item?.status?.status==2?"text-[#fd2e63]":item?.status?.status==1?"text-[#ff5f24]":item?.status?.status==0?"text-[#51bb25]":''}`}>{item?.title}</span>
                            <div className='border-b w-full'></div>
                            <span  className={`text-[14px] font-regular ${item?.status?.status==2?"text-[#fd2e63]":item?.status?.status==1?"text-[#ff5f24]":item?.status?.status==0?"text-[#51bb25]":''}`}>{item?.status?.should_complete}</span>
                          </div>
                        )
                      })
                    }
                  </div>:null
                }
                {
                  start_date !="" && end_date !=""?
                  <div className='flex justify-between mt-[10px]'>
                    <span className='text-[12px]'>From: {start_date}</span>
                    <span className='text-[12px]'>to: {end_date}</span>
                  </div>:null
                }
            </div>
        </div>
    )
  }
}

export default CoachPartCard
