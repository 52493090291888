import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import SpeakingSectionSvg from '../components/svg/SpeakingSectionSvg'
import PublicCard5 from '../components/cards/PublicCard5'
import ear from "../../images/ear.png";
import book from "../../images/book.png";
import pen from "../../images/pen.png";
import { connect } from 'react-redux';
import { getLevelCheckExam, insertNewData, checkPublicUser } from '../../actions/PublicActions'
import { checkLevel, getOverall } from '../components/getLevel'
import LevelCheckComplete from '../components/LevelCheckComplete'
import { mixinAlert } from '../../actions/SweetAlerts';
import { changeStateValue } from '../../redux/MainReducer';
import ApplyModal from '../components/apply_modal/ApplyModal';
export class PublicLevelCheck extends Component {
    state={
        prosessing:false,
        modalPosition:false
    }
    componentDidMount(){
        this.props.getLevelCheckExam();
        this.props.checkPublicUser();
        window.scrollTo(0,0)
        this.props.changeStateValue({name:'examQuestions', value:[]})
    }

    setOverall=(grade, level)=>{
       
        this.props.changeStateValue({name:'loader', value:true})
        this.setState({prosessing:true});
        this.props.insertNewData('set-exam-score', {  overall:grade,  student_level:level})
        .then(resp=>{
        
            setTimeout(()=>{
                this.props.getLevelCheckExam();
                this.setState({prosessing:false});
                this.props.changeStateValue({name:'loader', value:false})
                mixinAlert("success", "Your overall grade calculated successfully!")
            }, 3000)
        })
    }

    applyToCompany=()=>{
        this.setState({modalPosition:true})
    }
  render() {
    const {levelCheckExam} = this.props;
    const {prosessing, modalPosition} = this.state;
   
    return (
        <div  className='pt-[110px]'>
            <SectionHeader title={ (levelCheckExam?.listening_complete ==1 &&  levelCheckExam?.reading_complete ==1 &&  levelCheckExam?.speaking_complete ==1 &&  levelCheckExam?.writing_complete ==1 )?"Congratulations on Completing the Test!":'Discover Your English Level - Fast and Free!'} line={false} />
            <div className='bg-white pl-[20px] pr-[20px]'>
               <div className='container ml-auto mr-auto'>
                    {/* <h4 className='text-center text-[24px] font-bold '>Discover Your English Level - Fast and Free!</h4> */}
                    {
                        (levelCheckExam?.listening_complete ==1 &&  levelCheckExam?.reading_complete ==1 &&  levelCheckExam?.speaking_complete ==1 &&  levelCheckExam?.writing_complete ==1 )?
                        <p className='text-center text-[20px] font-regular mt-[20px] max-w-[600px] ml-auto mr-auto'>Ready to enhance your English? Click 'Enroll in Language Center' to take the next step in your learning journey.</p>:
                        <p className='text-center text-[20px] font-regular mt-[20px] max-w-[600px] ml-auto mr-auto'>Take our quick online English test to find out your proficiency level. It's absolutely free and there's no commitment required afterwards. Know where you stand and explore your options for learning, all at no cost!</p>
                    }
                    
               </div>
               {
                (levelCheckExam?.listening_complete ==1 &&  levelCheckExam?.reading_complete ==1 &&  levelCheckExam?.speaking_complete ==1 &&  levelCheckExam?.writing_complete ==1 )?
                <LevelCheckComplete 
                    level={levelCheckExam?.student_level?levelCheckExam?.student_level:''} 
                    btnTxt={levelCheckExam?.student_level?"Enroll in Language Center":'Reveal My English Level'} 
                    type={levelCheckExam?.student_level?"completed":'waiting'}
                    prosessing={prosessing}
                    onClick={levelCheckExam?.student_level?this.applyToCompany.bind(this): this.setOverall.bind(this, 
                        checkLevel(levelCheckExam?.beginner_reading_score, levelCheckExam?.middle_reading_score, levelCheckExam?.advanced_reading_score)?.grade+
                        checkLevel(levelCheckExam?.beginner_listening_score, levelCheckExam?.middle_listening_score, levelCheckExam?.advanced_listening_score)?.grade+
                        checkLevel(levelCheckExam?.beginner_speaking_score, levelCheckExam?.middle_speaking_score, levelCheckExam?.advanced_speaking_score)?.grade+
                        checkLevel(levelCheckExam?.beginner_writing_score, levelCheckExam?.middle_writing_score, levelCheckExam?.advanced_writing_score)?.grade,
                        getOverall(
                            checkLevel(levelCheckExam?.beginner_reading_score, levelCheckExam?.middle_reading_score, levelCheckExam?.advanced_reading_score)?.grade,
                            checkLevel(levelCheckExam?.beginner_listening_score, levelCheckExam?.middle_listening_score, levelCheckExam?.advanced_listening_score)?.grade,
                            checkLevel(levelCheckExam?.beginner_speaking_score, levelCheckExam?.middle_speaking_score, levelCheckExam?.advanced_speaking_score)?.grade,
                            checkLevel(levelCheckExam?.beginner_writing_score, levelCheckExam?.middle_writing_score, levelCheckExam?.advanced_writing_score)?.grade
                        ))}
                    disabled={prosessing}
                />:null
               }
               {
                levelCheckExam !==""?
                    <div className='container ml-auto mr-auto grid grid-cols-2 gap-[45px] mt-[100px] max-[816px]:grid-cols-1'>
                        <PublicCard5 url={levelCheckExam?.speaking_complete==1?"/level-check":'/speaking-exam'} svg={<SpeakingSectionSvg />} title="Speaking" percentage={levelCheckExam?.speaking_complete ==1?checkLevel(levelCheckExam?.beginner_speaking_score, levelCheckExam?.middle_speaking_score, levelCheckExam?.advanced_speaking_score)?.title:''} status={levelCheckExam?.speaking_complete ==1?"Completed":"Start Test"} />
                        <PublicCard5 url={levelCheckExam?.listening_complete==1?"/level-check":'/listening-exam'} svg={<img src={ear} className='w-[76px] h-[76px]'/>} title="Listening" percentage={levelCheckExam?.listening_complete ==1?  checkLevel(levelCheckExam?.beginner_listening_score, levelCheckExam?.middle_listening_score, levelCheckExam?.advanced_listening_score)?.title:''} status={levelCheckExam?.listening_complete ==1?"Completed":"Start Test"} />
                        <PublicCard5 url={levelCheckExam?.reading_complete==1?"/level-check":'/reading-exam'} svg={<img src={book} className='w-[76px] h-[76px]'/>} title="Reading" percentage={levelCheckExam?.reading_complete ==1?  checkLevel(levelCheckExam?.beginner_reading_score, levelCheckExam?.middle_reading_score, levelCheckExam?.advanced_reading_score)?.title:''} status={levelCheckExam?.reading_complete ==1?"Completed":"Start Test"} />
                        <PublicCard5 url={levelCheckExam?.writing_complete==1?"/level-check":'/writing-exam'} svg={<img src={pen} className='w-[76px] h-[76px]'/>} title="Writing" percentage={levelCheckExam?.writing_complete ==1?  checkLevel(levelCheckExam?.beginner_writing_score, levelCheckExam?.middle_writing_score, levelCheckExam?.advanced_writing_score)?.title:''} status={levelCheckExam?.writing_complete ==1?"Completed":"Start Test"} />
                    </div>:null
               }
              
              
            </div>
            {modalPosition? <ApplyModal onClose={()=>this.setState({modalPosition:false})} /> :null}
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    levelCheckExam: state.Data.levelCheckExam,
   
});
const mapDispatchToProps = {getLevelCheckExam, insertNewData, changeStateValue, checkPublicUser}
export default connect(mapStateToProps, mapDispatchToProps)(PublicLevelCheck)
