import React, { Component } from 'react'
import CourseLessonDrop from './components/CourseLessonDrop'
import { connect } from 'react-redux';
import { withHooks } from '../../../../actions/withHooks';
import { getIALExams, insertNewData } from '../../../../actions/MainAction';
import Card1 from '../../../../components/cards/Card1';
import { bigAlert, mixinAlert } from '../../../../actions/SweetAlerts';
import EmptyPage from '../../../../components/EmptyPage';
export class UnlockIalEx extends Component {
    state={
        exams:[],
        btnDisable:false
    }
    getEssays=(data)=>{
        this.props.getIALExams(1, '',"lock_unlock", data?.lessonId, this.props.params.id)
        .then(resp=>this.setState({exams:resp}))
    }
    unlockEssayBtn=(data)=>{
        if( data?.answers?.length === 0){
            this.setState({btnDisable:true})
            this.props.insertNewData("lock-unlock-new-essays", {user_id: this.props.params.id, essay_id: data?.id})
            .then(resp=>{
                this.setState({btnDisable:false})
                if(resp === "success"){
                    this.getEssays({lessonId:data?.lesson_id});
                    bigAlert(`${data.unlock && data?.unlock?.status == 0?'Locked!':'Unlocked!'}`, `IAL Ex ${data.unlock && data?.unlock?.status == 0?'Locked':'Unlocked'} successfully.`, "success")
                }else{
                    bigAlert("Error!", "Something went wrong.", "error")
                }
            }) 
        }else{
            mixinAlert( "warning","The A Level already has been taken.")
        }
        
    }

    componentDidMount(){
        window.scrollTo(0,0)
    }
    getCoursePage(){
        const courseVisible = window.location.search;
        if(courseVisible==""){
            return true
        }else{
          return false;
        }
    }
  render() {
    const {exams, btnDisable} = this.state;
    const {lang} = this.props;
    return (
        <div className='w-full'>
            <CourseLessonDrop studentType={0} onSelectData={this.getEssays.bind(this)}  />
            {/* {exams.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, select course and lesson" subTitle="" /></div>:null} */}
            <div className='w-full grid-full-220 mt-[20px]'>
                {this.getCoursePage()?
                    exams?.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.title}
                                statusBisible={false}
                                btn={false}
                                btn2={true}
                                btn2Text={data?.answers?.length == 0?  data.unlock && data?.unlock?.status == 0?lang?.lock: lang?.unlock:lang?.locked}
                                btn2Color={data?.answers?.length == 0?  data.unlock && data?.unlock?.status == 0? "bg-gray-400": 'bg-blue':'bg-gray-400'}
                                cheating={ data?.answers?.length == 0?  data.unlock && data?.unlock?.status == 0? lang?.unlocked:lang?.locked:lang?.done}
                                onCLickBtn={this.unlockEssayBtn.bind(this, data)}
                                disabled={btnDisable}
                            />
                        )
                    }):null
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    lang: state.Data.lang
});
const mapDispatchToProps = {getIALExams, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UnlockIalEx))
