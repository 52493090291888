import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCompanyTeachers } from '../../actions/AdminActions';
import { withHooks } from '../../actions/withHooks';
import { Link } from 'react-router-dom';
import Header from '../../components/header/Header';
import { getRole } from '../../actions/getRole';
import Search from '../../components/Search';
import { getTeachers } from '../../actions/StaffAction';
import LoadMoreBtn from '../../components/LoadMoreBtn';
export class AccountantTeachers extends Component {
    state={
        teachers:[],
        search:'',
        page:1,
        url: "users",
    }
    componentDidMount(){
        this.props.getTeachers(1, '', this.props.params.id);  
        // this.props.getCompanyTeachers(this.props.params.id)
        // .then(resp=>{
        //     this.setState({teachers:resp})
        // })
    }

    getByType=(url)=>{
        this.setState({url:url})
        this.props.getTeachers(this.state.page, this.state.search, this.props.params.id, url);  
    }

    onSearchData=(search)=>{
        this.setState({search})
        this.props.getTeachers(this.state.page, search, this.props.params.id, this.state.url); 
    }
    loadMore=(page)=>{
        this.setState({page})
        this.props.getTeachers(page, this.state.search, this.props.params.id, this.state.url); 
    }
  render() {
    const {url} = this.state;
    const {lang, teachers, } = this.props;

    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={this.props.params.company_name?.replaceAll("%20", " ")} />
            <div className='flex  gap-[20px] mt-[10px]'>
                <Link  to={`/accountant-teachers-contracts/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center  txt-blue bg-white   text-[22px] rounded-[6px]'>{lang?.salary_docs}</Link>
                <Link  to={`/accountant-payments/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center shadow text-[22px] txt-blue bg-white rounded-[6px]'>{lang?.pays_acc}</Link>
                <Link  to={`/accountant-teachers/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center   bg-blue text-white shadow text-[22px] rounded-[6px]'>{lang?.t_count_acc}</Link>
                <Link  to={`/accountant-celtgo/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center   txt-blue bg-white shadow text-[22px] rounded-[6px]'>{lang?.celtgo_acc}</Link>
            </div>

            <div className='flex justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[160px]'>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            <div className='flex items-center gap-[20px]'>
                <button onClick={this.getByType.bind(this, "users")} className={`${url=="users"?"bg-blue text-white":""}  text-[13px] rounded-[5px] p-[4px_6px]`}>{lang?.unlocked}</button>
                <button onClick={this.getByType.bind(this, "company-head-lock-teacher")} className={`${url=="company-head-lock-teacher"?"bg-blue text-white":""}  text-[13px] rounded-[5px] p-[4px_6px]`}>{lang?.locked}</button>
            </div>
         <div className='grid-full-250 mt-[42px]'>
            {
                teachers.map((data, i)=>{
                    return(
                        <Link target={"_blank"} to={`/accountant-teachers-counting/${data?.id}`} key={i} className={`bg-white rounded-[12px] flex flex-col  justify-between p-[20px] shadow shadow-lg border border-gray-300`}>
                            <ul className='flex flex-col gap-[10px]'>
                                <li>
                                    <h3>{data?.full_name}</h3>
                                    <span className='text-[12px]'>Role: {getRole(data?.role)}</span>
                                </li>
                                {
                                    data?.teacher_status===1?
                                    <li>{lang?.senior_t}</li>:null
                                }
                                
                            </ul>
                           <div className='flex justify-center mt-[10px]'>
                                <button className='p-[5px] pt-[2px] pb-[2px] bg-blue text-white text-[12px] rounded-[6px]'>View</button>
                           </div>
                        </Link>
                    )
                })
            }
        </div>
        {
                teachers?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={teachers?.length} />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang,
    teachers: state.Data.teachers,
});
const mapDispatchToProps = {getCompanyTeachers, getTeachers}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AccountantTeachers))
