import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getCompanyInOuts, getWorkerInOuts } from '../../actions/CELTGOActions';
import DropSearch from '../../components/DropSearch';
import FinanceDetailModal from '../../components/modals/FinanceDetailModal';
import ContractPdfSvg from '../../components/svg/ContractPdfSvg';
import EmptyPage from '../../components/EmptyPage';
export class SAbroadPage extends Component {
  state={
    year:'2023-2024',
    page:'student',
    modalData:[],
    studentName:''
  }
  componentDidMount(){
    if(this.props.user !==""){
        this.props.getCompanyInOuts(this.props.user?.company_id, this.state.year)
        this.props.getWorkerInOuts(this.props.user?.company_id, this.state.year)
    }
  }
  componentDidMount(prevProps, prevState){
    if(this.props.user !==prevProps?.user){
        this.props.getCompanyInOuts(this.props.user?.company_id, this.state.year)
        this.props.getWorkerInOuts(this.props.user?.company_id, this.state.year)
    }
  }

  onSelectData=(data)=>{
      this.props.getCompanyInOuts(this.props.user?.company_id, data)
      this.props.getWorkerInOuts(this.props.user?.company_id, data)
  }
  getTotals(arr, type){

      let total = 0;
      for(let i = 0; i < arr.length; i++){
          if(arr[i]?.type == type){
              total+= +arr[i]?.amount
          }
      }
      return Math.round(total);
  }

  getAllTotal(arr, type){
      let total = 0;
      arr.forEach(item=>{
          item?.worker_students?.map(ws=>{
              ws.student.student_finance.map(sf=>{
                  if(sf.type === type){
                      total+= +sf.amount;
                  }
              })
          })
      });
      return Math.round(total);
  }
  getWorkerTotal(arr){
      let total = 0;
      arr.forEach(item=>{
          item?.company_stats?.map(cs=>{
            total += cs.amount
          })
      });
      return Math.round(total);
  }
  render() {
    const {lang, celtgoWorkerInOuts, celtgoInOuts, user} = this.props;
    const {page, year, modalData, studentName} = this.state;
    let option = [
      {
          label: "2020-2021",
          value: "2020-2021"
      },
      {
          label: "2021-2022",
          value: "2021-2022"
      },
      {
          label: "2022-2023",
          value: "2022-2023"
      },
      {
          label: "2023-2024",
          value: "2023-2024"
      },
      {
          label: "2024-2025",
          value: "2024-2025"
      },
  ];
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title={lang?.s_abroad} />
        {
            user?.company_id === 5 ||  user?.company_id === 31?
            <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, change date to show check-ins" subTitle="" /></div>:
            <>
             <div className='flex items-center gap-[20px] mt-[20px] max-sm:justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
          <button onClick={()=>this.setState({page:"student"})} className={`text-[12px] ${page === "student"? "bg-blue text-white":"bg-white txt-blue"} font-semibold  p-[4px_10px] rounded-[6px]`}>
              {lang?.student_finance}
          </button>
          <button onClick={()=>this.setState({page:"educon"})}  className={`text-[12px] ${page === "educon"? "bg-blue text-white":"bg-white txt-blue"} font-semibold  p-[4px_10px] rounded-[6px]`}>
              {lang?.edu_expences}
          </button>
        </div>
        <div className='flex white-search mt-[20px]'>
          <DropSearch options={option} name="" value={year} placeholder={year} selectData={this.onSelectData.bind(this)} />
        </div>
        <div className='flex mt-[20px]'>
            <div className='bg-white rounded-[10px] w-[300px]'>
                <h3 className='text-white bg-blue rounded-t-[10px] text-center h-[35px] pt-[5px] font-semobold text-[16px]'>{lang?.summary}</h3>
                <ul>
                    <li className='flex justify-between border-b'>
                        <span  className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_ins}</span>
                        <span className='txt-light-black p-[6px] w-1/2 font-regular text-[14px]'>{this.getAllTotal(celtgoInOuts, "incomes")}</span>
                    </li>
                    <li className='flex justify-between border-b'>
                        <span   className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_outs_student}</span>
                        <span  className='txt-light-black p-[6px] inline-flex justify-between w-1/2 font-regular text-[14px]'>{this.getAllTotal(celtgoInOuts, "outs")}</span>
                    </li>
                    <li className='flex justify-between border-b'>
                        <span   className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_outs_worker}</span>
                        <span  className='txt-light-black p-[6px] inline-flex justify-between w-1/2 font-regular text-[14px]'>{this.getWorkerTotal(celtgoWorkerInOuts)}</span>
                    </li>
                    <li className='flex justify-between border-b'>
                        <span   className='txt-light-black p-[6px] w-1/2 border-r font-regular text-[14px]'>{lang?.total_net}</span>
                        <span  className='txt-light-black p-[6px] w-1/2 font-regular text-[14px]'>
                            {this.getAllTotal(celtgoInOuts, "incomes") -(this.getAllTotal(celtgoInOuts, "outs") + this.getWorkerTotal(celtgoWorkerInOuts))}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        {
                page==="student"?
                <div className='bg-white mt-[30px] p-[20px] rounded-[12px]'>
                   <div className='flex items-center justify-between border '>
                        <span style={{ minWidth: '120px' }} className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.acc_student}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.educon}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.ins}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.outss}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.total_net}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.acc_continue}</span>
                        <span className='border w-1/5 text-center p-2 text-base font-bold'>{lang?.acc_actions}</span>
                    </div> 
                    <div>
                        {
                            celtgoInOuts?.map((data, i)=>{
                                return(
                                    data?.worker_students?.map((item, j)=>{
                                        return(
                                          <div key={j} className='flex'>
                                              <span className='border w-1/5 text-center p-2 text-base '>{item?.student?.full_name}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>{data?.full_name}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>{this.getTotals(item?.student?.student_finance, 'incomes')}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>{this.getTotals(item?.student?.student_finance, 'outs')}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>{this.getTotals(item?.student?.student_finance, 'incomes') - this.getTotals(item?.student?.student_finance, 'outs')}</span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>
                                              {
                                                item?.student?.student_finance?.map((file,k)=>{
                                                    if(file?.file){
                                                        return(
                                                            <a download={true} key={k} target='_blank' href={file?.file?.file_full_url}>
                                                                <ContractPdfSvg className='w-6 cursor-pointer' />
                                                            </a>
                                                        )
                                                    }
                                                })
                                            }
                                              </span> 
                                              <span className='border w-1/5 text-center p-2 text-base '>
                                                <button onClick={()=>this.setState({modalData:item?.student?.student_finance, studentName:item?.student?.full_name})} className='bg-blue text-[12px] p-[3px_5px] text-white font-semibold rounded-[5px]'>
                                                    {lang?.view}
                                                </button>
                                              </span>
                                          </div>
                                          )  
                                      })
                                )
                                
                            })
                        }
                    </div>
                </div>:
                <div className='bg-white mt-[30px] p-[20px] rounded-[12px]'>
                   <div className='flex items-center justify-between border '>
                        <span className='border w-1/4 text-center p-2 text-base font-bold'>{lang?.educon}</span>
                        <span className='border w-1/4 text-center p-2 text-base font-bold'>{lang?.title}</span>
                        <span className='border w-1/4 text-center p-2 text-base font-bold'>{lang?.amount}</span>
                        <span className='border w-1/4 text-center p-2 text-base font-bold'>{lang?.acc_continue}</span>
                    </div> 
                    <div>
                        {
                            celtgoWorkerInOuts?.map((data, i)=>{
                                return(
                                    data?.company_stats?.map((item, j)=>{
                                        return(
                                          <div key={j} className='flex'>
                                              <span className='border w-1/4 text-center p-2 text-base '>{data?.full_name}</span> 
                                              <span className='border w-1/4 text-center p-2 text-base '>{item?.title}</span> 
                                              <span className='border w-1/4 text-center p-2 text-base '>{item?.amount}</span> 
                                              <span className='border w-1/4 text-center p-2 text-base '>
                                              {
                                                item?.file?
                                                <a download={true} target='_blank' href={item?.file_full_url}>
                                                    <ContractPdfSvg className='w-6 cursor-pointer' />
                                                </a>:null
                                            }
                                              </span> 
                                          </div>
                                          )  
                                      })
                                )
                                
                            })
                        }
                    </div>
                </div>
            }
           {
            modalData?.length !==0?
            <FinanceDetailModal studentName={studentName} closeBtn={()=>this.setState({modalData:[], studentName:''})} data={modalData} lang={lang} />:null
           }
            </>
        }
       
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  lang: state.Data.lang,
  user: state.Data.user,
  celtgoInOuts: state.Data.celtgoInOuts,
  celtgoWorkerInOuts: state.Data.celtgoWorkerInOuts,
});
const mapDispatchToProps = {getCompanyInOuts, getWorkerInOuts}
export default connect(mapStateToProps, mapDispatchToProps)(SAbroadPage)
