import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Card2 from '../../components/cards/Card2'
import { connect } from 'react-redux';
import { getCourses } from '../../actions/MainAction';
import EmptyPage from '../../components/EmptyPage';
export class Listenings extends Component {

  componentDidMount(){
    this.props.getCourses();
    window.scrollTo(0,0)
  }
  render() {
    const {studentCourses} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="stud_audio" />
            {studentCourses?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-205 max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
              {
                studentCourses?.map((data, i)=>{
                  if(data?.listenings_count !=0){
                    return(
                      <Card2
                        key={i}
                        title={data?.title}
                        btnTitle={'Listen'}
                        btnUrl={`/listenings/${data?.id}`}
                      />
                    )
                  }
                  
                })
              }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  studentCourses: state.Data.studentCourses
});
const mapDispatchToProps = {getCourses}
export default connect(mapStateToProps, mapDispatchToProps)(Listenings)
