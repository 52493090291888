import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getOfficePaymentDue, getStudentPaymentsTeachers } from '../../actions/StaffAction';
import Card5 from '../../components/cards/Card5';
import StudentPayModal from '../../components/modals/StudentPayModal';
import EmptyPage from '../../components/EmptyPage';
export class PaymentDue extends Component {
    state={
        payments:[],
    }
    componentDidMount(){
        if(this.props.user !== ""){
           this.props.getOfficePaymentDue(this.props.user?.company_id);  
        }
        window.scrollTo(0,0)
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps?.user !== this.props.user){
            this.props.getOfficePaymentDue(this.props.user?.company_id);  
        }
    }

    getTeachers=(data, maxValue)=>{
        let newData = [];
        for(const item of data?.info){
            newData.push({
                name: item?.full_name,
                status: data?.attend[item?.id] >= maxValue? '0':'1',
                total: data?.attend[item?.id] >= maxValue?0:maxValue - data?.attend[item?.id]
            })
        }
        return newData
    }
    onClockUnlock=(studentId,data)=>{
        this.props.getStudentPaymentsTeachers(studentId)
        .then(resp=>{ this.setState({payments: resp}) })
    }
    getAfterChange=()=>{
        this.props.getOfficePaymentDue(this.props.user?.company_id);  
    }
  render() {
    const {officePaymentDue} = this.props;
    const {payments} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="payment_due" />
            {officePaymentDue.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found" subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-320 items-start max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {
                    officePaymentDue?.map((data, i)=>{
                        return(
                            <Card5
                                key={i}
                                name={data?.student?.full_name}
                                students={this.getTeachers(data?.teachers, +data?.student?.payment_Reminder_max_value)}
                                txt="Remaining"
                                eye={false}
                                lockUnlockBtn={true}
                                onClockUnlock={this.onClockUnlock.bind(this,data?.student?.id)}
                            />
                        )
                    })
                }
            </div>
            {payments.length !== 0? <StudentPayModal closeModalBtn={()=>this.setState({payments:[]})} getAfterChange={this.getAfterChange.bind(this)} data={payments} />:null}
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    officePaymentDue: state.Data.officePaymentDue,
    user: state.Data.user,
});
const mapDispatchToProps = { getOfficePaymentDue, getStudentPaymentsTeachers }
export default connect(mapStateToProps, mapDispatchToProps)(PaymentDue)
