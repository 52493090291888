import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getCoachExceptions } from '../../actions/AdminActions';
import CardLine from '../../components/cards/CardLine';
import CardUserSvg from '../../components/svg/CardUserSvg';
import CardCheatingSvg from '../../components/svg/CardCheatingSvg';
import CardEmailSvg from '../../components/svg/CardEmailSvg';
import CardDateSvg from '../../components/svg/CardDateSvg';
import moment from 'moment';
import { Link } from 'react-router-dom';
import HeaderButtons from '../../components/HeaderButtons';
import { insertNewData, deleteData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import CardCourseSvg from '../../components/svg/CardCourseSvg';
export class AdminCoachExceptions extends Component {
    state={
        status: '0',
        selected:0,
        countModal:false,
        modalParams:{
            id:'',
            type:'',
            count:''
        }
    }
    componentDidMount(){
        this.props.getCoachExceptions("0")
    }
    changeDataBtn=(data, index)=>{
        this.setState({selected:index, status:data?.id});
        this.props.getCoachExceptions(data?.id)
    }

    changeStatus=(id, type, count="")=>{
        confirmAlert("warning",'Are you sure?', "You won't be able to revert this!",'Yes, change it!')
        .then(respAlert=>{
            if(respAlert == "success"){
                let formData = new FormData();
                formData.append("status", type);
                if(count && count !=""){ 
                    formData.append("count", count); 
                }else{
                    formData.append("count", '1')
                }
                formData.append("_method", "put");
              
                this.props.insertNewData(`coach-exception/${id}`, formData)
                .then(resp=>{
                    if(resp == "success"){
                       mixinAlert('success', `Your file has been ${type == '1'? 'accepted':'rejected'}`);
                       this.setState({changeStatus:{id:'', type:'', count:''}, countModal:false})
                       
                    }else{
                      mixinAlert("error", "Something went wrong")
                    }
                    this.props.getCoachExceptions(this.state.status)
                })
            }
        })
    }
    changeStatusAccept=(id, type, count)=>{
        this.setState({modalParams:{id, type, count}, countModal:true})
    }

    deleteBtn=(id)=>{
        confirmAlert("warning",'Are you sure?', "You won't be able to revert this!",'Yes, delete it!')
        .then(respAlert=>{
            if(respAlert == "success"){
                this.props.deleteData(`coach-exception/${id}`)
                .then(resp=>{
                    if(resp == "success"){
                       mixinAlert('success', `Your file has been deleted.`)
                       
                    }else{
                      mixinAlert("error", "Something went wrong")
                    }
                    this.props.getCoachExceptions(this.state.status)
                })
            }
        })
    }
  render() {
    const {adminCoachExceptions} = this.props;
    const {selected, countModal, modalParams} = this.state;
    const btns = [
        {
            title: "Waiting",
            id: "0"
        },
        {
            title: "Accepted",
            id: "1"
        },
        {
            title: "Rejected",
            id: "2"
        },
    ];
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Coach Exceptions" />
            <div className='mt-[30px] flex justify-between items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={(data, index)=>this.changeDataBtn(data, index)} buttons={btns} selected={selected} />
            </div>
            <div className='mt-[30px] grid-full-250'>
                {
                    adminCoachExceptions?.map((data, i)=>{
                        return(
                            <div key={i} className='bg-white shadow p-[10px] rounded-[10px]'>
                                <a target='_blank' href={data?.image_full_url}>
                                    <img src={data?.image_full_url} className='w-full rounded-[10px] h-[150px]' />
                                </a>
                                <ul className='flex flex-col gap-[10px] mt-[10px]'>
                                    <CardLine svg={<CardUserSvg />} title={data?.user?.full_name} />
                                    <CardLine svg={<CardEmailSvg />} title={data?.user?.email} />
                                    <CardLine svg={<CardDateSvg />} title={moment(data?.created_at).format("DD/MM/YYYY")} />
                                    <CardLine svg={<CardCheatingSvg />} title={data?.user?.company?.name} />
                                    <CardLine svg={<CardCourseSvg />} title={data?.count} />
                                </ul>
                                <div className='flex justify-between mt-[10px]'>
                                    {
                                        data?.status==0?
                                        <button  onClick={this.changeStatus.bind(this, data?.id, "2", data?.count)} className='bg-red h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Reject</button>:null
                                    }
                                    {
                                        data?.status!=0?
                                        <button onClick={this.deleteBtn.bind(this, data?.id)} className='bg-red h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Delete</button>:null
                                    }
                                    
                                    <Link target='_blank' to={`/students/${data?.user?.id}/edit`} className='bg-gray-400 pt-[3px] h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>View</Link>
                                    {
                                         data?.status==0 ||  data?.status==2?
                                         <button onClick={this.changeStatusAccept.bind(this, data?.id, "1", data?.count)} className='bg-blue h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Accept</button>:null
                                    }
                                    
                                </div>
                            </div>
                        )
                    })
                }
           </div>
           {
            countModal?
            <div className='background_shadow flex items-center justify-center'>
                <div className='bg-white rounded-[10px] shadow w-[300px] p-[20px] flex flex-col'>
                    <label className='text-[14px]'>Count</label>
                    <input value={modalParams?.count} onChange={(e)=>this.setState({modalParams:{...modalParams, count:e.target.value}})} className='border h-[30px] p-[2px] rounded-[5px] text-[14px] outline-none' placeholder='Count' />
                    <div className='flex justify-between items-center'>
                        <button onClick={()=>{this.setState({changeStatus:{id:'', type:'', count:''}, countModal:false})}} className='bg-[var(--red)] mt-[10px] p-[4px_8px] text-white text-[14px] rounded-[6px]'>Close</button>
                        <button onClick={this.changeStatus.bind(this, modalParams?.id, modalParams?.type, modalParams?.count)} className='bg-[var(--blue)] mt-[10px] p-[4px_8px] text-white text-[14px] rounded-[6px]'>Save</button>
                    </div>
                </div>
            </div>:null
           }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminCoachExceptions: state.Data.adminCoachExceptions
});
const mapDispatchToProps = {getCoachExceptions,insertNewData, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCoachExceptions)


