import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getEnrollPayments } from '../../actions/StaffAction';
import EmptyPage from '../../components/EmptyPage';
import Card4 from '../../components/cards/Card4';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import { changeStateValue } from '../../redux/MainReducer';
import AddPaymentModal from '../../components/modals/AddPaymentModal';
import Search from '../../components/Search';
import DropSearch from '../../components/DropSearch';
import { getDropValue, getExcelFormat } from '../../actions/getRole';
import { getCompanyTeachers } from '../../actions/AdminActions';
import SkeletonCard from '../../components/cards/SkeletonCard';
import CardEditSvg from '../../components/svg/CardEditSvg';
import { insertNewData } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/SweetAlerts';
import CloseSvg from '../../components/svg/CloseSvg';
import ExcelExportButton from '../../components/ExcelExportButton';
export class EnrollPaymentsPage extends Component {
  state={
    page:1,
    search:'',
    teachers:[],
    teacher_id:'',
    loading:true,
    feeModal:{
      position:false
    }
  }
  componentDidMount(){
    this.props.getEnrollPayments(1,'','all', "", "", "")
    .then(resp=>{
      this.setState({loading:false})
    })
    if(this.props.user !== ""){
      this.props.getCompanyTeachers(this.props.user?.company_id)
        .then(resp=>{
            this.setState({teachers:resp})
        })
   }
  }
  componentDidUpdate(prevProps, prevState){
    if(prevProps?.user !== this.props.user){
        this.props.getCompanyTeachers(this.props.user?.company_id)
        .then(resp=>{
          this.setState({loading:false})
        })
    }
}
  countRemaining=(payments)=>{
    let total = 0;
    for(const x of payments){
      total+= x?.count
    }
    return total;
  }

  onClickPayment=(data)=>{
    this.props.changeStateValue({
      name:'addPaymentModal',
      value:{
        position:true,
        enroll_id:data?.id,
        count:'',
        note:'',
        file:null,
        file_full_url:null,
        image:null,
        image_full_url:null,
        aftertCallFunction: this.props.getEnrollPayments,
        afterCallParams: [this.state.page,this.state.search,'all', "", "", this.state.teacher_id]
      }
    })
  }

  loadMore=(page)=>{
    this.setState({page:page});
    this.props.getEnrollPayments(page,this.state.search, 'all', "", "", this.state.teacher_id)
  }
  onSearchData=(search)=>{
    this.setState({search:search});
    this.props.getEnrollPayments(this.state.page,search, 'all', "", "", this.state.teacher_id)
  }
  onSelectTeacher=(teacher_id)=>{
    this.setState({teacher_id:teacher_id});
    this.props.getEnrollPayments(this.state.page,this.state.search, 'all', "", "", teacher_id)
  }

  getAllData(array){
    let newArr = [];
    for(const data of array){
      newArr.push({
        ...data,
        count: this.countRemaining(data?.payments)
      })
    }
    return newArr;
  }

  onLineClick=(data)=>{
  //   this.setState({
  //     feeModal:{
  //         position:true,
  //         ...data
  //     }
  // })
  }
  saveEditFeeBtn=(data)=>{
    if(data?.fee !=''){
        let formData = new FormData();
        for(const property in data){
            if(data[property])
            formData.append(property, data[property]);    
        }
        
        formData.append("_method", "put");
        let url = `teacherEnrolls/${data?.id}`;
        this.props.insertNewData(url, formData)
        .then(resp=>{
            if(resp=="success"){
               mixinAlert("success", "Fee updated successfully");
                this.setState({
                    feeModal:{
                        position:false
                    }
                }) 
                this.props.getEnrollPayments(this.state.page,this.state.search, 'all', "", "", this.state.teacher_id)
            }
            
        })
    }
    
}
  render() {
    const {enrollPayments, addPaymentModal, lang} = this.props;
    const {teachers, loading, feeModal} = this.state;
    // console.log(enrollPayments)
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="payment_due" />
            <div className='flex justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[160px]'>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            <div className='flex mt-[20px] white-search items-center gap-[20px]'>
                <DropSearch selectData={this.onSelectTeacher.bind(this)} options={getDropValue(teachers, "full_name", "id")} placeholder="Select a teacher" />
                {/* <ExcelExportButton excelData={getExcelFormat([...this.getAllData(enrollPayments)].sort((x, y)=> { return x.count - y.count}))} /> */}
            </div>
            {/* {enrollPayments.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null} */}
            {
              loading?
              <div className='grid-full-250 mt-[42px] '>
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
                  <SkeletonCard height={true} />
              </div>:null
            }
                     
            <div className='mt-[42px] grid-full-250'>
            {
                [...this.getAllData(enrollPayments)].sort((x, y)=> { return x.count - y.count})?.map((data, i)=>{
                return(
                    <Card4
                        key={i}
                        name={data?.student?.full_name}
                        image={data?.student?.avatar_full_url}
                        course={data?.course?.title}
                        lesson_mode={data?.lesson_mode}
                        teacher={data?.teacher?.full_name}
                        editBtnPermisiion={false}
                        addPaymentBtn={true}
                        payModeBtn={`${lang?.fee}: ${data?.fee}` }
                        onLineClick={this.onLineClick.bind(this, data)}
                        titleRemaining={this.countRemaining(data?.payments)}
                        paymentBtnWarning={this.countRemaining(data?.payments)==0?true:false}
                        onClickPayment={this.onClickPayment.bind(this, data)}
                        lock={this.countRemaining(data?.payments)==0?0:1}
                        namePath={`/students/${data?.student?.id}`}
                    />
                )
                })
            }
            </div>
            {/* {
              enrollPayments?.length >=20?
              <LoadMoreBtn onLoadData={this.loadMore} count={enrollPayments?.length} />:null
            } */}
            {
              addPaymentModal?.position?
              <AddPaymentModal />:null
            }
             {
            feeModal?.position?
            <div className='background_shadow flex items-center justify-center'>
                <div className='w-[300px]'>
                    {/* <Header closeBtn={()=>{this.setState({ feeModal:{position:false}})}} title="Edit Fee" /> */}
                    <div className='flex h-[48px] rounded-t-[9px] bg-blue items-center justify-between'>
                      <h3 className='ml-[24px] text-white font-regular text-[24px] max-[600px]:text-[18px]'>{"Edit Fee"}</h3>
                      <button onClick={()=>this.setState({feeModal:{position:false}})} className='mr-[24px]'>
                          <CloseSvg />
                      </button>
                    </div>
                    <div className='bg-white p-[10px] rounded-b-[10px] flex flex-col gap-[10px]'>
                        <span className='text-[14px]'>Fee</span>
                        <input type='number' className='border w-full p-[6px] rounded-[6px] outline-none' value={feeModal?.fee} onChange={(e)=>this.setState({feeModal:{ ...feeModal,  fee:e.target.value  }})} />
                        <button onClick={this.saveEditFeeBtn.bind(this, feeModal)} className='border border-[var(--blue)] p-[5px] txt-blue text-[14px] font-semibold rounded-[5px]'>
                            Update
                        </button>
                    </div>
                </div>
            </div>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  enrollPayments: state.Data.enrollPayments,
  addPaymentModal: state.Data.addPaymentModal,
  user: state.Data.user,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getEnrollPayments, changeStateValue, getCompanyTeachers, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(EnrollPaymentsPage)
