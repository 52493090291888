import React, { useState } from 'react'
import XLSX from "sheetjs-style"
import * as FileSaver from "file-saver"
import moment from 'moment';
import { mixinAlert } from '../actions/SweetAlerts';
function GOExcell({excellData=[], fileName}) {
    const [disable, setDisable] = useState(false);
    const fileType = "application/vnd.openxmlformats-officedocuments.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToExcel = () => {
        // setDisable(true);
        if(excellData.length !=0){
            const ws = XLSX.utils.json_to_sheet(excellData);
            const wb = {Sheets:{'data':ws}, SheetNames: ['data']}
            const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type:'array'});
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, `${fileName}-${moment(new Date()).format("DD-MM-YYYY")}`+fileExtension)
        }else{
            mixinAlert("warning", "No data to export")
        }
    }
  return (
    <div className='mt-[20px]'>
        <button disabled={disable} onClick={()=>exportToExcel()} className='bg-blue text-white text-[14px] rounded-[5px] p-[4px_7px]'>Export Excel</button>
    </div>
  )
}

export default GOExcell
