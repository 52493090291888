import React, { Component } from 'react'
import PdfWhiteSvg from '../svg/PdfWhiteSvg'
import MusicSvg from '../svg/MusicSvg'
import ImgSvg from '../svg/ImgSvg'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer'
import VideoSvg from '../svg/VideoSvg'
export class AttachContainer extends Component {
    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = ()=>{
                res(reader.result)
            }
            reader.readAsDataURL(file)
        })
    }
    getMsgFile(type,e){
        this.getBase64(e.target.files[0])
        .then(resp=>{
            this.props.changeStateValue({
                name: "supportMsgFile",
                value: {
                    position:true,
                    file:e.target.files[0],
                    message: e.target.files[0]?.name,
                    preview:resp,
                    type:type,
                    reply_id:this.props.reply?.id?this.props.reply?.id:'',
                }
            })
        })
        this.props.onClose()
    }
  render() {
    return (
        <div className='absolute z-50 h-[130px] w-[50px]  bottom-[40px] left-[-15px] flex flex-col items-center gap-[10px]'>
        <label htmlFor='msgPdfInpSp' className='bg-blue inline-flex w-[27px] h-[27px] items-center justify-center rounded-full cursor-pointer'>
            <PdfWhiteSvg />
        </label>
        <label htmlFor='msgAudioInpSp' className='bg-blue inline-flex w-[27px] h-[27px] items-center justify-center rounded-full cursor-pointer'>
            <MusicSvg />
        </label>
        <label htmlFor='msgImgInpSp' className='bg-blue inline-flex w-[27px] h-[27px] items-center justify-center rounded-full cursor-pointer'>
            <ImgSvg />
        </label>
        <label htmlFor='msgVideoInpSp' className='bg-blue inline-flex w-[27px] h-[27px] items-center justify-center rounded-full cursor-pointer'>
            <VideoSvg />
        </label>

        <input type='file' onChange={this.getMsgFile.bind(this, "pdf")} id='msgPdfInpSp' className='hidden' accept='application/pdf' />
        <input type='file' onChange={this.getMsgFile.bind(this, "audio")} id='msgAudioInpSp' className='hidden' accept='audio/mp3, audio/mp4' />
        <input type='file' onChange={this.getMsgFile.bind(this, "video")} id='msgVideoInpSp' className='hidden' accept='video/*' />
        <input onChange={this.getMsgFile.bind(this, "image")} type='file' id='msgImgInpSp' className='hidden' accept='image/png, image/jpg, image/jpeg' />
    </div>
    )
  }
}

const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AttachContainer)