import React, { Component } from 'react'

export class MsgMicSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={22}
            fill="none"
        >
            <path
            stroke="#94A3B8"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M12.5 4.5a3.5 3.5 0 1 0-7 0V11a3.5 3.5 0 1 0 7 0V4.5Z"
            />
            <path
            stroke="#94A3B8"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M1.5 10.5A7.5 7.5 0 0 0 9 18m0 0a7.5 7.5 0 0 0 7.5-7.5M9 18v3"
            />
        </svg>
    )
  }
}

export default MsgMicSvg
