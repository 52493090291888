import React, { Component } from 'react'

export class FbSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <circle cx={12} cy={12} r={12} fill="#E5F0FF" />
        <path
          fill="#1E398F"
          d="M15.494 5.93a16.322 16.322 0 0 0-1.771-.09c-1.962 0-3.181 1.25-3.181 3.26v1.366H8.894a.292.292 0 0 0-.292.292v2.246a.292.292 0 0 0 .292.292h1.648v4.503a.292.292 0 0 0 .291.291h2.32a.291.291 0 0 0 .292-.291v-4.503h1.643a.292.292 0 0 0 .29-.254l.29-2.246a.293.293 0 0 0-.29-.33h-1.933V9.324c0-.566.114-.803.822-.803h1.19a.292.292 0 0 0 .29-.291V6.22a.292.292 0 0 0-.253-.29Zm-.33 2.008h-.898c-1.258 0-1.404.791-1.404 1.386v1.435a.292.292 0 0 0 .291.291h1.894l-.216 1.663h-1.677a.291.291 0 0 0-.292.291v4.503h-1.737v-4.503a.292.292 0 0 0-.292-.291H9.186V11.05h1.647a.292.292 0 0 0 .292-.291V9.1c0-1.677.971-2.678 2.598-2.678.588 0 1.12.032 1.441.06v1.455Z"
        />
      </svg>
    )
  }
}

export default FbSvg
