import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import { withHooks } from '../../actions/withHooks';
import { getToeflWritingExam, updateToeflExamWriting, getExams } from '../../actions/MainAction';
import Countdown, {zeroPad} from 'react-countdown';
import { mixinAlert } from '../../actions/SweetAlerts';
import WritingSectionDirection from '../../components/toefl_exams/WritingSectionDirection';
import Reading from '../../components/toefl_exams/Reading';
import Listening from '../../components/toefl_exams/Listening';
import {jwtDecode} from "jwt-decode";

export class ToeflWritingStart extends Component {
    state={
        continueBtn: false,
        onloadState: true
    }
    componentDidMount(){
        this.props.changeStateValue({name:'sideBarVisible', value:false});
        this.props.getToeflWritingExam(this.props.params.id)
        .then(resp=>{
           
            if(resp?.is_submit == "1"){
                this.props.changeStateValue({name:'sideBarVisible', value:true});
                if(localStorage.getItem('userToken')){
                    const data = jwtDecode(localStorage.getItem('userToken'));
                    this.props.getExams(data.sub);
                  }
                this.props.navigate("/exam-results");
            }
            setTimeout(()=>{
                this.setState({
                    continueBtn: true
                })
            }, 3000)
        });
        window.scrollTo(0,0)
    }
    renderer(data, { hours, minutes, seconds, completed}){
        if(completed){
            return <span>00:00:00</span>
        }else{
            return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        }
    }
    stopTimer(data, e){
        if(
            data.part == 0 || 
            data.part == 1 || 
            data.part == 2 || 
            data.part == 3 || 
            data.part == 4 || 
            data.part == 5 || 
            data.part == 6 
            
        ){
            let answer = '';
            if(+data.part == 4){
                answer = data.reading_answer
            }else if(+data.part == 6){
                answer = data.part_2_reading_answer
            }
            localStorage.removeItem('audio_duration')
            localStorage.removeItem('audio_current_duration')
            this.props.updateToeflExamWriting(this.props.params.id, +data.part+1, answer)
        }
    }
    continueBtn(data, e){
       
        let answer = '';
        if(+data.part == 4){
            answer = data.reading_answer
        }else if(+data.part == 6){
            answer = data.part_2_reading_answer
        }
        if(+data.part == 4  || +data.part == 6  ){
        
            if(answer.trim() != ''){
                this.props.updateToeflExamWriting(this.props.params.id, +data.part+1, answer)
                .then(resp=>{
                    if(resp?.is_submit == "1"){
                        this.props.changeStateValue({name:'sideBarVisible', value:true});
                        if(localStorage.getItem('userToken')){
                            const jwt = jwtDecode(localStorage.getItem('userToken'));
                            this.props.getExams(jwt.sub);
                        }
                        this.props.navigate("/exam-results");
                    }
                    setTimeout(()=>{
                        this.setState({
                            continueBtn: true
                        })
                    }, 3000)
                })

                this.setState({
                    continueBtn: false
                })
            }else{
                  mixinAlert("error", 'You should write something before submitting.')
            }
            
        }else if(+data.part != 4 && +data.part != 6){
            this.props.updateToeflExamWriting(this.props.params.id, +data.part+1, answer)
            .then(resp=>{
                if(resp?.is_submit == "1"){
                    this.props.changeStateValue({name:'sideBarVisible', value:true});
                    if(localStorage.getItem('userToken')){
                        const jwt = jwtDecode(localStorage.getItem('userToken'));
                        this.props.getExams(jwt.sub);
                    }
                    this.props.navigate("/exam-results");
                   
                }
                setTimeout(()=>{
                    this.setState({
                        continueBtn: true
                    })
                }, 3000)
            })

            this.setState({
                continueBtn: false
            })
        }
        
    }
    getMinute(part){
        if(part ==0){
            return 0
        }else if(part == 1){
            return 0
        }else if(part == 2){
            return 3
        }else if(part == 3){
            return  0
        }else if(part == 4){
            return 20
        }else if(part == 5){
            return 0
        }else if(part == 6){
            return 30
        }else {
            return 0
        }
    }
  render() {
    const {toeflWritingExam} = this.props;
    const {continueBtn, onloadState} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="TOEFL Writing Start" />
            <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <div className='flex justify-between items-center'>
                    <p></p>
                    <span className='bg-blue text-white p-[3px] rounded-[6px]'>
                        {
                            toeflWritingExam != '' && toeflWritingExam.part != 0 &&  toeflWritingExam.part != 1 &&  toeflWritingExam.part != 3 && toeflWritingExam.part != 5?
                            <Countdown date={new Date(toeflWritingExam.start_date).getTime() + this.getMinute(toeflWritingExam?.part)*60000} renderer={this.renderer.bind(this,toeflWritingExam)}
                                onComplete={this.stopTimer.bind(this, toeflWritingExam)}>
                            </Countdown>: '00:00:00'
                        }
                
                    </span>
                    {
                        +toeflWritingExam.part == 0 && continueBtn || +toeflWritingExam.part == 1 && continueBtn 
                        || +toeflWritingExam.part == 5 && continueBtn ?
                        <button onClick={this.continueBtn.bind(this, toeflWritingExam)} className='bg-blue text-white w-[100px] h-[30px] rounded-[6px]'>Continue</button>:<p className='text-white w-[100px] h-[30px] rounded-[6px]'></p>
                    }
                </div>
            </div>
            {
                toeflWritingExam !== ''?
                <div className='bg-white p-[20px] shadow rounded-[12px] mt-[20px]'>
    {
                    toeflWritingExam.part == 0?
                    <WritingSectionDirection
                        title="Writing Section Directions"
                        p1="This section measures your ability to use writing to communicate in an academic environment. There will be two writing tasks."
                        p2="For the first writing task, you will read a passage, listen to a lecture, and then answer a question based on what you have read and heard. For the second writing task, you will answer a question based on your own knowledge and experience."
                        p3="Now listen to the directions for the first writing task."
                    
                    />:
                    toeflWritingExam.part == 1?
                    <WritingSectionDirection
                        title="Writing Based on Reading and Listening Directions"
                        p1="For this task, you will read a passage about an academic topic. A clock at the top of the screen will show how much time you have to read. You may take notes on the passage while you read. The passage will then be removed and you will listen to a lecture about the same topic. While you listen, you may also take notes. You will be able to see the reading passage again when it is time for you to write. You may use your notes to help you answer the question."
                        p2="You will have 20 minutes to write a response to a question that asks you about the relationship between the lecture you heard and the reading passage. Try to answer the question as completely as possible using information from the reading passage and the lecture. The question does not ask you to express your personal opinion. Typically, an effective response will be 150 to 225 words."
                        p3="Your response will be judged on the quality of your writing and on the completeness and accuracy of the content."
                        p4="Now you will see the reading passage for 3 minutes. Remember that it will be available to you again when you write. Immediately after the reading time ends, the lecture will begin, so keep your headset on until the lecture is over."
                    
                    />:
                    toeflWritingExam.part == 2?
                    <Reading title={toeflWritingExam?.reading_title} desc={toeflWritingExam?.reading_description} />:
                    toeflWritingExam.part == 3?
                    <Listening examData={toeflWritingExam} title={toeflWritingExam.listening_title} url={toeflWritingExam.listening_full_url} />:
                    toeflWritingExam.part == 4?
                    <Reading name="toeflWritingExam.reading_answer" value={toeflWritingExam.reading_answer} writing={true} title={toeflWritingExam?.reading_title} desc={toeflWritingExam?.reading_description} />:
                    toeflWritingExam.part == 5?
                    <WritingSectionDirection 
                        title="Writing Based on Knowledge and Experience Directions"
                        p1="For this task, you will write an essay in response to a question that asks you to state, explain, and support your opinion on an issue. In an actual test, you will have 30 minutes to write your essay."
                    
                    />:
                    toeflWritingExam.part == 6?
                    <Reading  name="toeflWritingExam.part_2_reading_answer" value={toeflWritingExam.part_2_reading_answer} title={toeflWritingExam?.part_2_reading_title} desc={toeflWritingExam?.part_2_reading_description} writing={true} />:null

                }
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                {
                        +toeflWritingExam.part == 4 && continueBtn || +toeflWritingExam.part == 6 && continueBtn?
                    <button  onClick={this.continueBtn.bind(this, toeflWritingExam)} className='bg-orange mt-[20px] p-[5px] rounded-[6px] text-white'>Submit & Continue</button>:null
                }
                </div>
                </div>:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    toeflWritingExam: state.Data.toeflWritingExam
});
const mapDispatchToProps = {changeStateValue, getToeflWritingExam, updateToeflExamWriting, getExams}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ToeflWritingStart))
