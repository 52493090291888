import React, { Component } from 'react'
import TimeButton from './TimeButton'
import { connect } from 'react-redux';
import moment from 'moment';

export class ScheduleTimeList extends Component {
  render() {
    const {selectSchedule} = this.props;
    // console.log(selectSchedule)
    return (
      <div className='max-[570px]:w-full flex flex-col mt-[20px]  items-center'>
        <span className='text-[14px]'>{moment(new Date(selectSchedule?.date)).format("DD MMMM, YYYY")}</span>
        <div className='mt-[30px] flex flex-col gap-[10px]'>
            {
                selectSchedule?.times?.map((time, i)=>{
                    return(
                        <TimeButton 
                            key={i}
                            time={time}
                        />
                    )
                })
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    selectSchedule: state.Data.selectSchedule
});
export default connect(mapStateToProps)(ScheduleTimeList)
