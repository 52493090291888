import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getExams } from '../../actions/AdminActions';
import Card1 from '../../components/cards/Card1';
import HeaderButtons from '../../components/HeaderButtons';
import { withHooks } from '../../actions/withHooks';
import EmptyPage from '../../components/EmptyPage';
import Header from '../../components/header/Header';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Search from '../../components/Search';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { insertNewData, deleteData } from '../../actions/MainAction';
export class AdminExams extends Component {
    state={
      active_type: '1',
      page:1,
      search:'',
      activePage:0
    }
    componentDidMount(){
        this.props.getExams(1, '', '1');
        window.scrollTo(0,0)
    }
    openModal=(data)=>{
      this.props.navigate("/admin-exams/create")
    }

    changeStatus=(data, status)=>{
      confirmAlert("warning", "Are you sure?", "Status will change", "Yes, change it")
      .then(alertResp=>{
        if(alertResp === "success"){
          this.props.insertNewData(`exam-active/${data?.id}`, {type: status==1?'0':'1'})
          .then(resp=>{
            if(resp=="success"){
              mixinAlert("success", "Status change successfully")
              this.props.getExams(this.state.page, this.state.search?.replaceAll(" ", "%"),this.state.active_type);
            }else{
              mixinAlert("error", "Something went wrong")
            }
          })
        }
      })
    }
    loadMore=(page)=>{
      this.setState({page});
      this.props.getExams(page, this.state.search?.replaceAll(" ", "%"),this.state.active_type);
    }
    onSearchData=(search)=>{
      this.setState({search});
      this.props.getExams( this.state.page, search?.replaceAll(" ", "%"),this.state.active_type);
    }

    getDataByStatus=(data, index)=>{
      this.setState({activePage:index, active_type:data?.id});
      this.props.getExams( this.state.page, this.state.search?.replaceAll(" ", "%"),data?.id);
    }

    onDeleteData=(data)=>{
      confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
      .then(alertResp=>{
        if(alertResp === "success"){
          this.props.deleteData(`exams/${data?.id}`)
          .then(resp=>{
            if(resp == "success"){
              this.props.getExams( this.state.page, this.state.search?.replaceAll(" ", "%"),this.state.active_type);
              mixinAlert("success", "Data deleted successfully")
            }else{
              mixinAlert("error", "Something went wrong")
            }
          })
        }
      })
    }
  render() {
    const {adminExams, pageType, user} = this.props;
    const {activePage} = this.state;
    // console.log(adminExams)
    return (
      <div className={`${pageType=="main"? "w-full pl-[27px] pr-[27px]":''}`}>
        {
          pageType == "main"?
          <Header title="Exams" />:null
        }
       
        <div className='flex justify-between mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
          {
            user?.role === "super_admin"?
            <HeaderButtons onClick={(data, index)=>this.getDataByStatus(data, index)} buttons={[{title: "Actives", id:'1'},{title: "All", id:''},{title: "Inactives", id:'0'}]} selected={activePage} />:<div></div>
          }
          <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Exam", id:0}]} selected={0} />
        </div>
        <div className='flex justify-center '>
            <Search largWidth={true} onSearchData={this.onSearchData} />
        </div>
        {adminExams.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No results found" subTitle="" /></div>:null}
        <div className='grid-full-250 mt-[20px]'>
            {
                adminExams.map((data,i)=>{
                    return(
                        <Card1 
                            key={i}
                            title={data?.course?.title}
                            exam={data?.title}
                            close={data?.lesson?.title}
                            status={1}
                            statusBisible={false}
                            btn={false}
                            url={`/admin-exams/${data?.id}`}
                            blank={true}
                            activeStatus={data?.active_status}
                            adminActiveVisible={user?.role === "super_admin"}
                            onClickChangeStatus={this.changeStatus.bind(this, data)}
                            adminDelete={user?.role === "super_admin"}
                            onDeleteBtn={this.onDeleteData.bind(this, data)}
                            user={data?.user?.full_name}
                        />
                    )
                })
            }
        </div>
          {
              adminExams?.length >=20?
              <LoadMoreBtn onLoadData={this.loadMore} count={adminExams?.length} />:null
          }

      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminExams: state.Data.adminExams,
    user: state.Data.user,
});
const mapDispatchToProps = { getExams, insertNewData, deleteData }
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminExams))
