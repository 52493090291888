import React, { Component } from 'react'

export class MsgSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={19}
        fill="none"
      >
        <path
          fill="#585874"
          d="M15.75 5.55H13.5a2.257 2.257 0 0 0-2.25-2.25h-9A2.257 2.257 0 0 0 0 5.55v5.25a2.257 2.257 0 0 0 2.25 2.25v2.25l2.25-2.25a2.257 2.257 0 0 0 2.25 2.25h6L15 17.55V15.3h.75A2.257 2.257 0 0 0 18 13.05V7.8a2.257 2.257 0 0 0-2.25-2.25Zm-13.5 6a.76.76 0 0 1-.75-.75V5.55a.76.76 0 0 1 .75-.75h9a.76.76 0 0 1 .75.75v.75H7.125A1.877 1.877 0 0 0 5.25 8.177v3.375h-3Zm14.25 1.5a.76.76 0 0 1-.75.75h-9a.76.76 0 0 1-.75-.75V8.177c0-.62.505-1.125 1.125-1.125h8.625a.76.76 0 0 1 .75.75v5.25Z"
        />
      </svg>
    )
  }
}

export default MsgSvg
