import React, { Component } from 'react'

export class CardScoreSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={16}
      aria-hidden="true"
      className="iconify iconify--emojione-monotone"
      viewBox="0 0 64 64"
      fill='#545454'
    >
      <path d="M10.921 3.28S4.789 18.509 2.009 41.369c-.249 2.044 4.458.215 6.082-.404 3.67-22.515 8.91-37.943 8.91-37.943-1.53-.262-6.08.258-6.08.258M37.28 4.95c2.162 3.503 1.217 9.056-.092 14.565-2.16 8.904-7.253 18.109-15.494 18.109-7.792 0-8.602-8.456-6.529-17.115 1.259-5.247 3.332-10.6 6.484-14.153C24.082 3.648 27.415 2 30.747 2c3.242 0 5.451.648 6.533 2.95M21.424 20.562c-1.756 7.26-.944 10.909 1.94 10.909 3.014 0 5.852-4.604 7.702-12.158 1.71-7.205.899-10.512-1.984-10.512-2.749 0-5.764 4.004-7.658 11.761M60.717 4.953c2.162 3.5 1.216 9.053-.09 14.561-2.163 8.904-7.251 18.111-15.494 18.111-7.792 0-8.604-8.458-6.531-17.115 1.263-5.249 3.333-10.602 6.487-14.155C47.521 3.653 50.853 2 54.187 2c3.244 0 5.449.648 6.53 2.953m-15.854 15.61c-1.756 7.258-.946 10.909 1.936 10.909 3.02 0 5.857-4.607 7.702-12.156 1.711-7.211.902-10.516-1.982-10.516-2.747.001-5.766 4.005-7.656 11.763M47.562 48.58c-5.365 0-33.341 2.201-44.36 3.107-.751-2.854.23-5.391 1.5-6.623 5.536-1.042 42.34-3.886 45.112-2.263-.462 2.011-1.27 4.549-2.252 5.779M42.86 59.779c-5.479 0-22.928 1.216-34.181 2.221-.766-3.179.236-5.987 1.532-7.354 5.654-1.157 32.118-3.087 34.947-1.287-.47 2.234-1.296 5.05-2.298 6.42" />
    </svg>
    )
  }
}

export default CardScoreSvg
