import React, { Component } from 'react'
import ImageSvg from '../../svg/ImageSvg'
import { connect } from 'react-redux'
import { changeStateValue } from '../../../redux/MainReducer'
export class InpImage extends Component {
    getBase64(file){
        return new Promise((res, rej)=>{
            let reader = new FileReader();
            reader.onload = () =>{
                res(reader.result)
            }
            reader.readAsDataURL(file)
        })
    }
    getImgValue=(valueName, e)=>{
        this.getBase64(e.target.files[0])
        .then(resp=>{
            this.props.changeStateValue({name:e.target.name, value: e.target.files[0]})
            this.props.changeStateValue({name:valueName, value: resp})
        })
    }
  render() {
    const {name, value, valueName, lang} = this.props
    return (
        <div className='flex justify-center mb-[20px]'>
            <label htmlFor={name} className='cursor-pointer inline-flex items-center flex-col gap-[5px]'>
                {
                    value?
                    <img alt={name} src={value} className='w-[99px] h-[99px] rounded-full' />:
                    <div className='w-[99px] h-[99px] bg-gray-300 rounded-full flex items-center justify-center'>
                        <ImageSvg />
                    </div>
                }
                <span className='txt-blue font-bold text-[14px]'>{lang?.upload_img}</span>
            </label>
            <input type='file' name={name} accept='image/*' className='hidden' id={name} onChange={this.getImgValue.bind(this, valueName)} />
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(InpImage)
