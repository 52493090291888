import React, { Component } from 'react'
export class DropDown extends Component {
    state={
        name:this.props.placeholder?this.props.placeholder:'Select a Data',
        visible:false
    }
    componentDidMount(){
        window.addEventListener("click", (e)=>{
            if(typeof e.target.className === "string" && !e.target.className.includes("notClosePermission")){
                this.setState({visible:false})
            }
        })
    }
    onSelect(data){
        this.setState({name: data?.title, visible:false});
        this.props.onSelect(data?.id)
    }
  render() {
    const {options} = this.props;
    const {name, visible} = this.state;
    return (
        <div className='relative  bg-white rounded-[30px] block p-[10px]'>
            <button onClick={()=>this.setState({visible: !visible})} className='bg-light-gray relative w-[214px] h-[35px]  max-md:w-[100%] rounded-[30px] flex justify-center items-center  gap-[5px] notClosePermission'>
                <span className='text-center notClosePermission'>{name}</span>
            </button>
            {
                visible?
                <div className='bg-white absolute w-full p-[10px] mt-[14px] left-0 shadow rounded-[20px] notClosePermission'>
                    <ul className='flex flex-col gap-[8px] notClosePermission'>
                        {
                            options?.map((data, i)=>{
                                return(
                                    <li onClick={this.onSelect.bind(this, data)} key={i} className='bg-light-gray p-[5px] hover:bg-gray-200 text-[14px] pl-[10px] text-start rounded-[200px] cursor-pointer notClosePermission'>
                                        <span className='text-start notClosePermission'>{data?.title}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>:null
            }
            
        </div>
    )
  }
}

export default DropDown
