import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getExamPartResults, getExamPartScore, insertNewDataV2 } from '../../../../actions/MainAction';
import { withHooks } from '../../../../actions/withHooks';
import Card1 from '../../../../components/cards/Card1';
import moment from 'moment';
import { changeStateValue } from '../../../../redux/MainReducer';
import Search from '../../../../components/Search';
import LoadMoreBtn from '../../../../components/LoadMoreBtn';
import EmptyPage from '../../../../components/EmptyPage';
import { getManuallyExamId } from '../../../../actions/StaffAction';
import { confirmAlert, mixinAlert } from '../../../../actions/SweetAlerts';
import { getSATScore, ieltsScoreCalculator } from '../../../../actions/getRole';
export class ExamResults extends Component {
  state={
    results:[],
    page:1,
    search:''
  }
  componentDidMount(){
    this.props.getExamPartResults(this.props.params.id, 1, '',this.props.examType)
    .then(resp=>{
      this.setState({results:resp})
    });
    window.scrollTo(0,0)
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps?.examType !== this.props.examType){
      this.props.getExamPartResults(this.props.params.id, 1, '',this.props.examType)
      .then(resp=>{
        this.setState({results:resp})
      })
      this.setState({ results:[],  page:1,  search:''})
    }
  }
  showResultBtn=(data)=>{
    if(this.props.user?.role == "company_head" || this.props.user?.role == "super_admin"){
      if(data?.exam_title?.toLowerCase().includes("digital sat")){
          this.props.navigate(`/admin-exam-results/${data?.id}/reviews`);
          return
      }
    }
    if(data?.exam?.exam_type === "pooling_standart"){
      this.props.changeStateValue({  name:'partScoreModal.position', value:true });
      this.props.changeStateValue({  name:'partScoreModal.title', value:data?.exam_title});
      this.props.changeStateValue({  name:'partScoreModal.data', value:[{
        title:data?.exam_title,
        points: ieltsScoreCalculator(data?.score),
        out_of: 9
      }]});
      return
    }
    this.props.getExamPartScore({id:data?.id, type:data?.exam?.exam_type =="pooling_dynamic"?"dynamic":'exam', exam_type:'current'})
    .then(resp=>{
      if(resp == 'success'){
        this.props.changeStateValue({  name:'partScoreModal.position', value:true });
        this.props.changeStateValue({  name:'partScoreModal.title', value:data?.exam_title});
      }
    })
  }
 
  getPlagiarism(data){
    let cases = [];
    if(data){
        data.map((pl, i)=>{
            cases.push(+pl.case)
        })
        if(cases.length !== 0){
            if(Math.max(...cases) == 3){
                return '90%';
            }else if(Math.max(...cases) == 1){
                return '40%';
            }else if(Math.max(...cases) == 0){
                return '0%';
            }
        }else{
            return '0%';
        } 
    }else{
        return '0%';
    } 
  }
  loadMore=(page)=>{
    this.props.getExamPartResults(this.props.params.id, page, this.state.search, this.props.examType)
    .then(resp=>{
        let oldData= page ===1? []:[...this.state.results]
      this.setState({results:[
        ...oldData,
        ...resp
      ]})
    })
  }

  onSearchData=(search)=>{
    this.setState({search:search})
    this.props.getExamPartResults(this.props.params.id, 1, search, this.props.examType)
    .then(resp=>{
      this.setState({results:[
        ...resp
      ]})
    })
  }

  generateCertBtn=(data)=>{
    if(!data?.exam_title?.toLowerCase().includes("end")){
      return
    }
    if(this.props.user?.role == "head_teacher" || this.props.user?.role == "company_head" || this.props.user?.role == "office_manager" || this.props.user?.role == "super_admin"){
      confirmAlert("warning", "Are you sure?", "Generate Certificate Manually ?", "Yes, do it")
      .then(respAlert=>{
        if(respAlert=="success"){
          const submitExam = {
            "studentExam_id": data.id,
            "exam_id": `${data.exam_id}`,
            "student_id": data.student_id,
            "date": data.updated_at,
            "score": data.score,
            "type": "current"
          }
          this.props.insertNewDataV2('student-exam-submit-manual', submitExam)
          .then(resp=>{
            if(resp==="success"){
              mixinAlert("success", 'Certificate generated successfully.')
            }else{
              mixinAlert("error", "Something went wrong")
            }
          })
        }
      })
    }
  }
  render() {
    const {results} = this.state;
    const {lang} = this.props;
    
    return (
      <div className='w-full '>
         <div className='flex justify-center '>
            <Search largWidth={true} onSearchData={this.onSearchData} />
         </div>
         {results.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
        <div className='w-full grid-full-250 mt-[20px]'>
            {
              results?.map((data, i)=>{
                return(
                  <Card1
                    key={i}
                    title={data?.exam?.course?.title}
                    exam={data?.exam_title}
                    time={data?.spend_time}
                    grade={getSATScore(data.exam_title, +data?.score)}
                    status={data?.status}
                    statusText={data?.status==1? lang?.pass:lang?.fail}
                    date={moment(data?.updated_at).format("HH:mm, DD/MM/YY")}
                    onCLickBtn={this.showResultBtn.bind(this, data)}
                    cheatingExam={this.getPlagiarism(data.plagiarism)}
                    generateCert={data?.status==1?this.generateCertBtn.bind(this, data):()=>{}}
                    btn={this.props.user?.role === "super_admin"? false:true}
                    url={this.props.user?.role === "super_admin"?`/admin-exam-results/${data?.id}/reviews`:""}
                    blank={true}
                />
                )
              })
            }
        </div>
          {
            results?.length >=20?
            <LoadMoreBtn onLoadData={this.loadMore} count={results?.length} />:null
            }
      </div>
      
    )
  }
}
const mapStateToProps = (state)=>({
  examResults: state.Data.examResults,
  lang: state.Data.lang,
  user: state.Data.user,
});
const mapDispatchToProps = {getExamPartResults, getExamPartScore, changeStateValue, getManuallyExamId, insertNewDataV2}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamResults))
