import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { getExamCategories } from '../../actions/StaffAction';
import { connect } from 'react-redux';
import Card1 from '../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer';
import HeaderButtons from '../../components/HeaderButtons';

export class ExamCategories extends Component {
    componentDidMount(){
        this.props.getExamCategories()
    }
    onDeleteData=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
          if(alertResp === "success"){
            this.props.deleteData(`exam-categories/${id}`)
            .then(resp=>{
              if(resp == "success"){
                this.props.getExamCategories()
                mixinAlert("success", "Data deleted successfully")
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }
        })
    }

    onEditAddBtn=(data="")=>{
        this.props.changeStateValue({
            name: "adminModalData",
            value:{
                position: true,
                id: data?.id?data?.id:"",
                title: data?.title? data?.title:"",
                modalTitle: data?.id?"Edit Category":"Add Category",
                requireds: ["title"],
                afterCallFunction: this.props.getExamCategories,
                afterCallParams: [],
                url: data?.id?`exam-categories/${data?.id}`:"exam-categories",
                // errorMsg: "Email already exists"
            }
        })
    }
  render() {
    const {examCategories, user} = this.props;
  
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Exam categories" />
            <div className='flex justify-end mt-[40px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons onClick={this.onEditAddBtn.bind(this, '')} buttons={[{title: "Add Category", id:0}]} selected={0} />
            </div>
            <div className='grid grid-full-250 mt-[40px]'>
                {
                    examCategories?.map((data, i)=>{
                        return(
                            <Card1 
                                key={i}
                                course={data?.title}
                                statusBisible={false}
                                status={1}
                                btn2Text={"Edit"}
                                btn={false}
                                btn2={true}
                                btn2Color={"bg-gray-500"}
                                url={`/exam-categories/${data?.id}`}
                                adminDelete={user?.role === "super_admin"}
                                onDeleteBtn={this.onDeleteData.bind(this, data?.id)}
                                onCLickBtn={this.onEditAddBtn.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examCategories: state.Data.examCategories,
    user: state.Data.user,
});
const mapDispatchToProps = {getExamCategories, deleteData, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ExamCategories)
