import React, { Component } from 'react'

export class QuestionDescription extends Component {
  render() {
    const {text} = this.props;
    return (
        <div className='text-[14px] mb-[15px]'>
            <p dangerouslySetInnerHTML={{ __html:text }}></p>
        </div>
    )
  }
}

export default QuestionDescription
