import React, { Component } from 'react'
import { withHooks } from '../actions/withHooks'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { MAIN_API } from '../MAIN_API';
export class UnsubCribeEmail extends Component {
    componentDidMount(){
        this.props.changeStateValue({
            name:'user',
            value:''
        });
        try{
            const email = atob(this.props.params.email);
            axios.post(`${MAIN_API}/unsibcribe-email`, {email:email})
            .then(resp=>{
            }).catch(err=>{
                this.props.navigate("/")
            })
        }catch(e){

        }
    }
  render() {
    return (
        <div className='fixed h-screen w-full bg-[var(--body)] top-0 left-0 z-[999] flex items-center justify-center'>
            <div className='shadow bg-white w-[300px] p-[20px] rounded-[10px] flex flex-col items-center gap-[20px]'>
                <div >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={80}
                        height={80}
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                        stroke="#15d122"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="m8.5 12.5 2 2 5-5"
                        />
                        <path
                        stroke="#15d122"
                        strokeLinecap="round"
                        strokeWidth={1.5}
                        d="M7 3.338A9.954 9.954 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12c0-1.821.487-3.53 1.338-5"
                        />
                    </svg>
                </div>
                <h3 className='txt-blue text-[18px] font-semibold text-center'>Email unsubscribed successfully.</h3>
                <Link className='text-white bg-blue p-[5px_10px] rounded-[5px] text-[13px]' to="/">Back to CELT.VIP</Link>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UnsubCribeEmail))
