import React, { Component } from 'react'

export class CheckWaitingSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={74}
        height={54}
        fill="none"
      >
        <path
          fill="#EE7100"
          d="m31.417 34.083-4.667-4.666c-.667-.667-1.445-1-2.333-1-.89 0-1.667.333-2.334 1-.666.666-1 1.458-1 2.376 0 .918.334 1.71 1 2.374l7.084 7.166c.666.667 1.444 1 2.333 1 .889 0 1.667-.333 2.333-1L47.917 27.25c.666-.667 1-1.472 1-2.417 0-.944-.334-1.75-1-2.416a3.292 3.292 0 0 0-2.417-1c-.944 0-1.75.333-2.417 1L31.417 34.083Zm-12.75 19.584c-5.056 0-9.375-1.75-12.957-5.25-3.582-3.5-5.375-7.778-5.377-12.834C.333 31.25 1.64 27.39 4.25 24c2.611-3.389 6.028-5.556 10.25-6.5 1.389-5.111 4.167-9.25 8.333-12.417C27 1.917 31.723.333 37 .333c6.5 0 12.014 2.265 16.543 6.794 4.53 4.529 6.793 10.042 6.79 16.54 3.834.444 7.015 2.097 9.544 4.96 2.529 2.862 3.792 6.209 3.79 10.04 0 4.166-1.458 7.709-4.374 10.626-2.915 2.918-6.458 4.376-10.626 4.374h-40Z"
        />
      </svg>
    )
  }
}

export default CheckWaitingSvg
