import React, { Component } from 'react'

export class ManagerSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle cx={12} cy={6} r={4} stroke="#585874" strokeWidth={1.5} />
        <path
          stroke="#585874"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M19.997 18c.003-.164.003-.331.003-.5 0-2.485-3.582-4.5-8-4.5s-8 2.015-8 4.5S4 22 12 22c2.231 0 3.84-.157 5-.437"
        />
      </svg>
    )
  }
}

export default ManagerSvg
