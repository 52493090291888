import React, { Component } from 'react'

export class CardUserSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
      >
        <path
          stroke="#1E398F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M3.333 13.333v-.667A4.667 4.667 0 0 1 8 8m0 0a4.667 4.667 0 0 1 4.667 4.666v.667M8 8a2.667 2.667 0 1 0 0-5.333A2.667 2.667 0 0 0 8 8Z"
        />
      </svg>
    )
  }
}

export default CardUserSvg
