import React, { Component } from 'react'

export class MsgGroupSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
    
      fill="none"
      viewBox="0 0 24 24"
      className='w-[24px] h-[24px] max-[500px]:w-[15px] max-[500px]:h-[15px]'

    >
      <path
        stroke="var(--blue)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
    )
  }
}

export default MsgGroupSvg
