import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getSpPracticeReviews } from '../../../../actions/MainAction';
import { withHooks } from '../../../../actions/withHooks';
import Card1 from '../../../../components/cards/Card1';
import moment from 'moment';
import { changeStateValue } from '../../../../redux/MainReducer';
import EmptyPage from '../../../../components/EmptyPage';
import Search from '../../../../components/Search';
import LoadMoreBtn from '../../../../components/LoadMoreBtn';
import { getSpPracticeGrade, getSpPracticeReview } from '../../../../actions/getReviewGpt';
import { getMistralModel } from '../../../../actions/robotActions';
import { insertNewData } from '../../../../actions/MainAction';
import { getGrades, getStringGrade } from '../../../../actions/getRole';
export class SpPractice extends Component {
    state={
        reviews:[],
        page:1,
        search:''
    }
    componentDidMount(){
        this.props.getSpPracticeReviews(this.props.params.id, 1, '')
        .then(resp=>{this.setState({reviews:resp})});
        window.scrollTo(0,0)
    }
    openModal=(data,  btnType="")=>{
        const params = {
            position:true,
            type:'sp_practice',
            modalType: 'review',
            title: data?.speaking?.title,
            question: data?.speaking?.question,
            reviews:  btnType=="recheck"?null: `<pre class="break-words whitespace-break-spaces">${data?.robo_review}</pre>`,
            teacher:'Selty',
            role: 'Teacher',
            stuent: data?.user?.full_name,
            grade:btnType=="recheck"?null: data?.robo_grade?data?.robot_grade:data?.teacher_grade,
        }
        this.props.changeStateValue({name: 'mainModal', value: params});
        if(btnType=="recheck"){
            setTimeout(()=>{
              this.props.changeStateValue({  name:'mainModal.speaking_robo_waiting',  value:true })
             
            }, 200)
            this.props.getMistralModel(getSpPracticeGrade(data))
            .then(gradeResp=>{
              let grade = 1;
              try{
                if(data?.speaking?.course_id==36){
                  grade =  getStringGrade(gradeResp)
                }else{
                  grade =  getGrades(gradeResp)
                }
              }catch(err){  grade =  getGrades(gradeResp)}
              this.props.getMistralModel(getSpPracticeReview(data))
              .then(respReview=>{
                let review = {
                  id:data?.id,
                  robo_review: respReview
                }
                if(data?.speaking?.course_id==36){
                  review.teacher_grade = grade
                }else{
                  review.robo_grade = grade;
                }
                this.props.insertNewData('speaking-practice-recheck', review)
                .then(resp=>{
                  this.props.changeStateValue({  name:'mainModal.speaking_robo_waiting',  value:false })
                  this.props.changeStateValue({  name:'mainModal.reviews',  value:`<pre class="break-words whitespace-break-spaces">${respReview}</pre>`  })
                  this.props.changeStateValue({  name:'mainModal.grade',  value:grade })
                  this.props.getSpPracticeReviews(this.props.params.id, this.state.page, this.state.search)
                  .then(resp=>{this.setState({reviews:resp})})
                })
              })
            })
          }
    }
    onSearchData=(search)=>{
        this.setState({search:search})
        this.props.getSpPracticeReviews(this.props.params.id, this.state.page, search)
        .then(resp=>{this.setState({reviews:resp})})
    }
    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getSpPracticeReviews(this.props.params.id, page, this.state.search)
        .then(resp=>{this.setState({reviews:resp})})
    }
  render() {
    const {reviews} = this.state;
    const {lang} = this.props;
    return (
      <div className='w-full'>
         <div className='flex justify-center'>
            <Search largWidth={true} onSearchData={this.onSearchData} />
        </div>
        {reviews.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
         <div className='mt-[42px] grid-full-250'>
                {
                    reviews?.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.speaking?.title}
                                date={moment(data?.created_at).format("DD/MM/YYYY")}
                                grade={data?.robo_grade}
                                onCLickBtn={this.openModal.bind(this, data, "")}
                                exam={data?.speaking?.course?.title}
                                // reCheckBtn={!data?.robo_grade || data?.robo_grade == 0}
                                // onReCheck={this.openModal.bind(this, data, "recheck")}
                                status={data?.speaking?.course_id==36?1:+data?.robo_grade >=data?.speaking?.course_prompt?.grade?1:0}
                                statusText={+data?.robo_grade >=data?.speaking?.course_prompt?.grade ?lang?.pass: lang?.fail}
                                statusBisible={data?.speaking?.course_id==36?false:true}
                                />
                        )
                    })
                }
            </div>
            {
                reviews?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={reviews?.length} />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  lang: state.Data.lang
});
const mapDispatchToProps = {getSpPracticeReviews, changeStateValue, getMistralModel, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SpPractice))
