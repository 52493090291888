import React, { Component } from 'react'

export class InstSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <circle cx={12} cy={12} r={12} fill="#E5F0FF" />
        <path
          fill="#1E398F"
          fillRule="evenodd"
          d="M12 6.167c-1.584 0-1.783.006-2.405.035-.62.028-1.045.127-1.416.27-.39.147-.742.377-1.033.673a2.86 2.86 0 0 0-.673 1.034c-.144.371-.243.795-.271 1.416-.029.622-.035.82-.035 2.405 0 1.584.006 1.783.035 2.405.028.62.127 1.045.271 1.416.146.389.376.742.673 1.033a2.86 2.86 0 0 0 1.033.673c.371.144.795.243 1.416.271.622.028.82.035 2.405.035 1.584 0 1.783-.007 2.405-.035.62-.028 1.045-.127 1.416-.271a2.86 2.86 0 0 0 1.034-.673 2.86 2.86 0 0 0 .672-1.033c.144-.371.243-.795.271-1.416.028-.622.035-.82.035-2.405s-.007-1.783-.035-2.405c-.028-.62-.127-1.045-.27-1.416a2.86 2.86 0 0 0-.673-1.034 2.859 2.859 0 0 0-1.034-.672c-.371-.144-.795-.243-1.416-.271-.622-.029-.82-.035-2.405-.035Zm0 1.05c1.558 0 1.742.006 2.357.035.57.026.878.12 1.084.2.271.106.466.233.67.436.205.205.33.4.437.671.08.206.175.515.2 1.084.028.615.034.799.034 2.357 0 1.557-.006 1.742-.034 2.357-.026.569-.12.877-.2 1.083a1.808 1.808 0 0 1-.437.671c-.204.204-.399.33-.67.436-.206.08-.515.175-1.084.201-.614.028-.799.034-2.357.034-1.557 0-1.742-.006-2.357-.034-.569-.026-.878-.121-1.083-.2a1.808 1.808 0 0 1-.671-.437 1.808 1.808 0 0 1-.437-.67c-.08-.207-.175-.515-.2-1.084-.028-.615-.034-.8-.034-2.357 0-1.558.006-1.742.034-2.357.026-.57.12-.878.2-1.084.107-.271.233-.466.437-.67.204-.205.399-.33.67-.437.207-.08.515-.175 1.084-.2.615-.029.799-.034 2.357-.034Zm0 6.727a1.944 1.944 0 1 1 0-3.888 1.944 1.944 0 0 1 0 3.888Zm0-4.94a2.995 2.995 0 1 0 0 5.991 2.995 2.995 0 0 0 0-5.99Zm3.814-.118a.7.7 0 1 1-1.4 0 .7.7 0 0 1 1.4 0Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default InstSvg
