import React, { Component } from 'react'

export class UserAddSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
            stroke="#1E398F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19c0-2.21-2.686-4-6-4s-6 1.79-6 4m16-3v-3m0 0v-3m0 3h-3m3 0h3M9 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8Z"
            />
        </svg>
    )
  }
}

export default UserAddSvg
