import React, { Component } from 'react'
import CardLine from './CardLine'
import CardGroupSvg from '../svg/CardGroupSvg'
import CardTimeSvg from '../svg/CardTimeSvg';
import CardSubjectSvg from '../svg/CardSubjectSvg';
import CardCloseSvg from '../svg/CardCloseSvg';
import CardUserSvg from '../svg/CardUserSvg';
import CardTotalSvg from '../svg/CardTotalSvg';
import ToggleOpenSvg from '../svg/ToggleOpenSvg';
import ToggleCloseSvg from '../svg/ToggleCloseSvg';
import EyeSvg from '../svg/EyeSvg';
import CardEditSvg from '../svg/CardEditSvg';
import CardDeleteUserSvg from '../svg/CardDeleteUserSvg';
import UserAddSvg from '../svg/UserAddSvg';
import MoneySvg from '../svg/MoneySvg';
import CardLockSvg2 from '../svg/CardLockSvg2';
import CardUnlockSvg2 from '../svg/CardUnlockSvg2';
import { connect } from 'react-redux';
import CardCourseSvg from '../svg/CardCourseSvg';

export class Card5 extends Component {
  render() {
    const {group = "", time="", subject="", type="", money="",onClickEdit=()=>{}, students=[], btn=false, groupLockStatus=false,
    modeType="", onClickLockUnlock=()=>{}, onClickLockUnlockStudent=()=>{}, onClickAddStudent=()=>{}, 
    onShowCheckins=()=>{}, name="", txt=this.props.lang?.total, eye=true, lockUnlockBtn=false, onClockUnlock=()=>{}, shadow=false,course=""} = this.props;
    return (
      <div className={`bg-white rounded-[12px] ${groupLockStatus? "border border-[1px] border-red-600":""} ${shadow?"shadow border border-gray-100":""}`}>
        <ul className='flex flex-col gap-[10px] p-[10px] '>
            { name!== ''?  <CardLine svg={<CardUserSvg />} title={name} />:null }
            { group !== ''?  <CardLine svg={<CardGroupSvg />} title={group} />:null  }
            { time!== ''?  <CardLine svg={<CardTimeSvg />} title={time} />:null }
            { subject!== ''?  <CardLine svg={<CardSubjectSvg />} title={subject} />:null }
            { type !== ''?  <CardLine svg={<CardCloseSvg />} title={type} />:null }
            { money !== ''?  <CardLine svg={<MoneySvg />} title={money} />:null }
            { course !== ''?  <CardLine svg={<CardCourseSvg />} title={course} />:null }
            
            {
                students.map((data, i)=>{
                    return(
                        <div key={i} className={`p-[10px] rounded-[12px] bg-gray-100 ${data?.status === "1"? "":"border border-[1px] border-red-600"}`}>
                            <div className='flex justify-end gap-[10px]'>
                                {
                                    eye?
                                    <button onClick={()=>onShowCheckins(data)}>
                                        <EyeSvg  color="var(--blue)" />
                                    </button>:null
                                }
                                
                                {
                                    btn?
                                    data?.status === "1"?
                                    <button onClick={()=>onClickLockUnlockStudent(i,"0")}>
                                        <ToggleOpenSvg />
                                    </button>:
                                    <button onClick={modeType==="group" && groupLockStatus? ()=>{}:()=>onClickLockUnlockStudent(i,"1")}>
                                        <ToggleCloseSvg />
                                    </button>:null
                                }
                                {
                                    lockUnlockBtn?
                                    <div className='mb-[-20px] relative'>
                                        {
                                            data?.status ==="1"?
                                            <CardUnlockSvg2 />:
                                            <button onClick={()=>onClockUnlock(data)}>
                                                <CardLockSvg2 />
                                            </button>
                                        }
                                    </div>:null
                                }

                            </div>
                            <ul className='flex flex-col gap-[10px]'>
                                <CardLine svg={<CardUserSvg />} title={data?.name} />
                                <CardLine svg={<CardTotalSvg />} title={`${txt}: ${data?.total}`} />
                            </ul>
                        </div>
                    )
                })
            }
            
        </ul>
        {
            btn?
            <>
                <div className='h-[1px] w-full bg-gray-200 mt-[0px]'></div>
                <div className='flex'>
                    <button onClick={()=>onClickEdit()} className='w-[50%] h-[38px] border-r inline-flex items-center justify-center'> <CardEditSvg /> </button>
                    {
                        modeType==="group"?
                        <button onClick={groupLockStatus?()=>{}:()=>onClickAddStudent()}  className='w-[50%] h-[38px]  border-r  inline-flex items-center justify-center'><UserAddSvg /></button>:null
                    }
                    
                    {
                        groupLockStatus?
                        <button onClick={modeType==="group"? ()=>{}:()=>onClickLockUnlock("1")} className='w-[50%] h-[38px] inline-flex items-center justify-center'> <ToggleCloseSvg /> </button>:
                        <button onClick={()=>onClickLockUnlock("0")} className='w-[50%] h-[38px] inline-flex items-center justify-center'> <ToggleOpenSvg /> </button>
                    }
                    
                </div>
            </>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
})
export default connect(mapStateToProps)(Card5)
