import React, { Component } from 'react'
import { connect } from 'react-redux'

export class LinksCard extends Component {
  render() {
    const {groupsLinks} = this.props;
    return (
        <div className='bg-white rounded-[20px] p-[30px]'>
             <div className='flex justify-between'>
                <h3 className='txt-light-black text-[15px] font-bold'>{"Portal links"}</h3>
            </div>
         <div className='mt-[25px] max-h-[380px] overflow-auto'>
         {
            groupsLinks?.map((data, i)=>{
              return (
                <div className='border-b'>
                  <p className='text-[15px]'>{data?.group?.teacher?.full_name}</p>
                  <a target='_blank' className='text-[14px] text-blue-600' href={data?.message}>{data?.message}</a>
                </div>
              )
            })
          }
         </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    groupsLinks: state.Data.groupsLinks
})
export default connect(mapStateToProps)(LinksCard)
