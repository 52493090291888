import React, { Component } from 'react'

export class MsgAttachSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={19}
        fill="none"
      >
        <path
          stroke="#94A3B8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M7.102 11.764a3.5 3.5 0 0 0 5 0l4-4a3.536 3.536 0 0 0-5-5l-.5.5"
        />
        <path
          stroke="#94A3B8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M11.102 7.764a3.499 3.499 0 0 0-5 0l-4 4a3.535 3.535 0 1 0 5 5l.5-.5"
        />
      </svg>
    )
  }
}

export default MsgAttachSvg
