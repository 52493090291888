import React, { Component } from 'react'
import CloseSvg from '../svg/CloseSvg';

export class Header extends Component {
  render() {
    const {title, btn=true} = this.props;
    return (
      <div className='flex h-[48px] rounded-t-[9px] bg-blue items-center justify-between'>
        <h3 className='ml-[24px] text-white font-regular text-[24px] max-[600px]:text-[18px]'>{title}</h3>
        {
          btn?
          <button onClick={()=>this.props.closeBtn()} className='mr-[24px]'>
            <CloseSvg />
          </button>:null
        }
       
      </div>
    )
  }
}

export default Header
