import React, { Component } from 'react';
import Header from '../../components/header/Header';
import { connect } from 'react-redux';
import { getCourses, getLessons, getSpPracticeByCourse } from '../../actions/MainAction';
import Card2 from '../../components/cards/Card2';
import DropSearch from '../../components/DropSearch';
import SpeakingPracticeModal from "../../components/modals/SpeakingPracticeModal"
import { changeStateValue } from '../../redux/MainReducer';
import EmptyPage from '../../components/EmptyPage';
import { withHooks } from '../../actions/withHooks';
import SpeakingPracticeModalMistral from '../../components/modals/SpeakingPracticeModalMistral';
import SpPracticeModal from '../../components/modals/SpPracticeModal';
import SpeakingPrLevelCheckModal from '../../components/modals/SpeakingPrLevelCheckModal';
export class SpeakingPracticeLevelCheck extends Component {
  state={
    modalPosition:false,
    modalData: '',
  }
  componentDidMount(){
    this.props.getCourses();
    this.props.changeStateValue({name: 'studentCourses', value:[]})
    this.props.changeStateValue({name: 'lessons', value:[]});
    window.scrollTo(0,0);
    const checkCourse =this.props.params?.courseId;
    const checkLesson =this.props.params?.lessonId;
    if(checkCourse && !checkLesson){
   
      this.props.getLessons(checkCourse)
    }else if(checkLesson){
     
      this.props.getSpPracticeByCourse(checkLesson)
    }
  
  }
 
  onSelectCourse=(data)=>{
    this.props.getLessons(data?.value)
  }
  getSpPractice=(data)=>{
    this.props.getSpPracticeByCourse(data?.value)
  }
  openModal=(data)=>{
    this.setState({
      modalPosition:true,
      modalData: data,
    })
  }

  getCourseVisible(){
    if(!this.props.params?.courseId &&  !this.props.params?.lessonId){
      return true
    }else{
      return false
    }
  }
  getUnitVisible(){
    if(this.props.params?.courseId &&  !this.props.params?.lessonId){
      return true
    }else{
      return false
    }
  }
  getDataVisible(){
    if(this.props.params?.courseId && this.props.params?.lessonId){
      return true
    }else{
      return false
    }
  }

  getPermissions(reviews, grade){
    let permission = 0;
    for(let i = 0; i < reviews.length; i++){
      if(+reviews[i].robo_grade >= grade){
        permission+=1;
      }
    }
    if(permission>0){
      return true
    }else{
      return false
    }
  }

  render() {
    const {studentCourses, lessons, spPractice, lang} = this.props;
    const {modalPosition, modalData} = this.state;
    const courses = [];
    const lessonsAr = [];
    for(const data of studentCourses){
      courses.push({
        label: data?.title,
        value: data?.id
      })
    }
    for(const data of lessons){
      lessonsAr.push({
        label: data?.title,
        value: data?.id
      })
    }
    // console.log(spPractice)
    // console.log(spPractice)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="sp_practice_st" />
            <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
           
                {courses?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please select course and lesson" subTitle="" /></div>:null}
              <div className='w-full grid-full-205 mt-[20px]'>
                {this.getCourseVisible()?
                  courses?.map((data, i)=>{
                    return(
                      <Card2 
                          key={i}
                          title={data?.label}
                          btnTitle={"View"}
                          onClickBtn={this.onSelectCourse.bind(this, data)}
                          btnUrl={`/sp-practice-level-check/${data?.value}`}
                      />
                    )
                  }):null
                }
                {this.getUnitVisible()?
                  lessonsAr?.map((data, i)=>{
                    return(
                      <Card2 
                          key={i}
                          title={data?.label}
                          btnTitle={"View"}
                          onClickBtn={this.getSpPractice.bind(this, data)}
                          btnUrl={`/sp-practice-level-check/${this.props.params?.courseId}/${data?.value}`}
                      />
                    )
                  }):null
                }
                
              </div>
               {
                this.getDataVisible()?
                <div className='w-full grid-full-205 mt-[20px]'>
                {
                  spPractice?.map((data, i)=>{
                    return(
                      <Card2
                         bigLine={true}
                        key={i}
                        title={data?.title}
                        btnTitle={this.getPermissions(data?.reviews, data?.course_prompt?.grade)? lang?.completed: lang?.speak_now}
                        status={this.getPermissions(data?.reviews, data?.course_prompt?.grade)?0:1}
                        onClickBtn={!this.getPermissions(data?.reviews, data?.course_prompt?.grade)? this.openModal.bind(this, data):()=>{}}
                      />
                    )
                  })
                }
              </div>:null
               }
            </div>
            {modalPosition? <SpeakingPrLevelCheckModal modalData={modalData} />:null}
            {/* {modalPosition? <SpPracticeModal modalData={modalData} />:null} */}
            {/* {modalPosition? <SpeakingPracticeModalMistral modalData={modalData} />:null} */}
            {/*  */}
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
  studentCourses: state.Data.studentCourses,
  lessons: state.Data.lessons,
  spPractice: state.Data.spPractice,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getCourses, getLessons, getSpPracticeByCourse, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SpeakingPracticeLevelCheck))

