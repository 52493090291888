import React, { Component } from 'react'

export class EssayPlagiat extends Component {
  render() {
    const {essayPlagiarism} = this.props;
    return (
      <div className=' border-t mt-[10px] pt-[20px]'>
        {
            essayPlagiarism?.value==""?
            <button className='text-orange-500 ml-auto block mr-auto'>Checking for copy...</button>:
            essayPlagiarism?.value !=="" &&  essayPlagiarism?.value.length !== 0 &&  essayPlagiarism?.value[0].type === 'not_plagiat'?
            <button className='text-green-500 ml-auto block mr-auto'>No copy found</button>:
            essayPlagiarism?.value !=="" &&  essayPlagiarism?.value.length !== 0 && essayPlagiarism?.value[0].type === 'plagiat'?
            <table className="w-full bg-gray-100 rounded-[10px]">
                <thead>
                    <tr>
                        <th className='text-[15px] h-[30px]'>Student</th>
                        <th className='text-[15px] h-[30px]'>Unit</th>
                        <th className='text-[15px] h-[30px]'>Copy (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        essayPlagiarism?.value[0].type === 'plagiat'?
                        essayPlagiarism?.value[0].plagiat.map((data, i) => {
                            return (
                                <tr key={i} >
                                    <td className='text-center text-[14px] h-[30px]'>{data.essay.user.full_name}</td>
                                    <td className='text-center text-[14px] h-[30px]'>{data.essay.essay.title}</td>
                                    <td className='text-center text-[14px] h-[30px]'>{Math.round(+data.percentage)}%</td>
                                </tr>
                            )
                        }):null
                    }
                    
                </tbody>
            
            </table>
            :null
        }
       
      </div>
    )
  }
}

export default EssayPlagiat
