import React, { Component } from 'react'

export class CardCloseSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width={12}
            height={12}
            viewBox="0 0 24 24"
            fill={'#1E398F'}
        >
            <path d="M12 0C5.37 0 0 5.37 0 12s5.37 12 12 12 12-5.37 12-12S18.63 0 12 0zm1.5 18h-3v-8h3v8zm0-10h-3V5h3v3z" />
        </svg>
    )
  }
}

export default CardCloseSvg
