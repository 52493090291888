import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
import { insertNewData } from '../../../../actions/MainAction';
import { mixinAlert } from '../../../../actions/SweetAlerts';
import { getExamQuestions } from '../../../../actions/AdminActions';
export class FooterBtn extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.changeStateValue({
            name: 'adminExamModalData',
            value: {
                position: false,
                id: '',
                title: '',
                audio_file: null,
                description: '<p></p>',
                question_image: null,
                question_description: '',
                score: '',
                sub_type: '',
                type: '',
                question_option: '[]',
                video_file: null,
                video_link: null,
                exam_id: '',
                questionPositon: false
            }
        })
    }
    saveBtn=(item)=>{
       
        let data= {...item}
        if(data.title != ''){
            if(data?.score === ""){
                mixinAlert("error", "Please fill the score field");
                return
            }
            let answer = data.question_option;
            if(data.type != 'free_text' && data.type != 'dropdown_question_type'){
                let x = data.question_option.find(answer=> answer.check == true)
                const checkCorrect = x? {...x}:undefined;
                const checkFill = data.question_option.find(answer=> answer.title == '');
                if(!checkCorrect){
                    mixinAlert('error', 'Please check answer as correct');
                    return;
                }
                if(checkFill){
                    mixinAlert('error', 'Please fill the all answer fields');
                    return;
                }
                if(data.type != 'multiple_choice'){
                    checkCorrect.points = data.score;
                    let x=[ ...data.question_option];
                    let y = [];
                  
                    for(let i=0; i<x.length;i++){
                        // x[i]?.is_correct=='1' ||
                        if( x[i]?.check){
                        //    console.log("isss")
                            y.push({
                                ...x[i],
                                points: data.score,
                                score: data.score,
                                is_correct:'1'
                            })
                        }else{
                            y.push({
                                ...x[i],
                                check:false,
                                is_correct: '0',
                                points:0,
                                score:0
                            })
                        }
                    }
                    data.question_option = y;
                   
                }else{
                    let checkMulti =[]
                    for(const x of data.question_option.filter(ans=> ans.check == true)){
                        checkMulti.push({...x})
                    }
                 
                   if(checkMulti.length<2){
                    mixinAlert('error', 'Please check 2 answers as correct');
                    return
                   }else{
                    let allAns = [];
                    for(let x of data.question_option){
                        if(x.check){
                            allAns.push({
                                ...x,
                                points: data.score/2,
                                score: data.score/2,
                                is_correct:'1'
                            })
                        }else{
                            allAns.push({
                                ...x,
                                score:0,
                                points:0,
                                is_correct:'0'
                            })
                        }
                    }
             
                    data.question_option = allAns
                   }
                }
                data.question_option = JSON.stringify(data.question_option);

            }else if(data.type == 'dropdown_question_type'){

               
                let t = []
                for(const ans of data.question_option){
                    t.push({
                        ...ans,
                        points : data.score/data.question_option.length
                    })
                }
                data.question_option = JSON.stringify(t);
              
            }else{
                if(data.description == ''){
                    mixinAlert('error', 'Please fill the description fields');
                    return;
                }else{
                    var parser = new DOMParser();
                    var doc = parser.parseFromString(data.description, 'text/html');
                    let freeTextAnswers = [];
                    if(data.id === ''){
                        for(const x of doc.children[0].children[1].textContent.split('}}}')){
                            
                            if(x.includes('{{{')){
                                const ans = x.split('{{{')[1].trim().replaceAll('&nbsp;', ' ').split('|');
                                
                                let trimAns = [];
                                for(const anss of ans){
                                    trimAns.push(anss.replace(/^\s+|\s+$|\s+(?=\s)/g, "").replaceAll(',','').
                                    replaceAll('.','').replaceAll('?','').replaceAll('!','').replaceAll('+','').
                                    replaceAll('-','').replaceAll('{','').replaceAll('}','').replaceAll('(','').
                                    replaceAll(')','').replaceAll('[','').replaceAll(']','').replaceAll('İ', 'I').replaceAll('i', 'i').toLowerCase().trim())
                                }
    
                                const params = {
                                    type: 'free_text',
                                    title: String(trimAns).replaceAll(',', '|'),
                                    check: true,
                                    points: +data.score / (doc.children[0].children[1].textContent.split('}}}').length - 1),
                                    correct: 1
                                }
                                freeTextAnswers.push(params)
                            }
                          
                        }
                        data.question_option = JSON.stringify(freeTextAnswers);
                    }else{
                        var parser = new DOMParser();
                        var doc = parser.parseFromString(data.description, 'text/html');
                        for(const x of doc.children[0].children[1].textContent.split('}}}')){
                            if(x.includes('{{{')){
                                const ans = x.split('{{{')[1].trim().replaceAll('&nbsp;', ' ').split('|');
                                let trimAns = [];
                                for(const anss of ans){
                                    trimAns.push(anss.replace(/^\s+|\s+$|\s+(?=\s)/g, "").replaceAll(',','').
                                    replaceAll('.','').replaceAll('?','').replaceAll('!','').replaceAll('+','').
                                    replaceAll('-','').replaceAll('{','').replaceAll('}','').replaceAll('(','').
                                    replaceAll(')','').replaceAll('[','').replaceAll(']','').replaceAll(':','').
                                    replaceAll(';','').replaceAll('"','').replaceAll("'",'').replaceAll('|','').
                                    replaceAll('/','').replaceAll('_','').replaceAll('İ', 'I').replaceAll('i', 'i').toLowerCase().trim())
                                }
    
                                const params = {
                                    type: 'free_text',
                                    title: String(trimAns).replaceAll(',', '|'),
                                    check: true,
                                    points: +data.score / (doc.children[0].children[1].textContent.split('}}}').length - 1),
                                    correct: 1
                                }
                                freeTextAnswers.push(params)
                            }
                          
                        }
                       
                        let newAnswers = [];
                        if(freeTextAnswers.length === data.answers.length){
                           
                            for(let i = 0; i < data.answers.length; i++){
                                const params = {
                                    id: data.answers[i].id,
                                    type: data.type,
                                    title: freeTextAnswers[i].title,
                                    check:  true,
                                    points: +data.score /freeTextAnswers.length,
                                    checkImage: '',
                                    subrows:[{answer: ''}]
                                }
                                newAnswers.push(params)
                            }

                           
                        }
                        else if(freeTextAnswers.length < data.answers.length){
                         
                            for(let i = 0; i < freeTextAnswers.length; i++){
                                const params = {
                                    id: data.answers[i].id,
                                    type: data.type,
                                    title: freeTextAnswers[i].title,
                                    check:  true,
                                    points: +data.score /freeTextAnswers.length,
                                    checkImage: '',
                                    subrows:[{answer: ''}]
                                }
                                newAnswers.push(params)
                               
                            }
                            data.answers.splice(freeTextAnswers.length,data.answers.length -  freeTextAnswers.length);
                           
                        }
                        else if(freeTextAnswers.length > data.answers.length){
                            
                            for(let i = 0; i < data.answers.length; i++){
                                const params = {
                                    id: data.answers[i].id,
                                    type: data.type,
                                    title: freeTextAnswers[i].title,
                                    check:  true,
                                    points: +data.score /freeTextAnswers.length,
                                    checkImage: '',
                                    subrows:[{answer: ''}]
                                }
                                newAnswers.push(params)
                              
                            }

                            for(let i = data.answers.length; i < freeTextAnswers.length; i++){
                                const params = {
                                    type: 'free_text',
                                    title: String(freeTextAnswers[i].title).replaceAll(',', '|'),
                                    check: true,
                                    points: +data.score / freeTextAnswers.length,
                                    correct: 1
                                }
                                newAnswers.push(params)
                            }
                        }
                         data.question_option = JSON.stringify(newAnswers);
                    }
                }
            }
          
            if(!data.sort_id){
                data.sort_id = this.props.answerCount;
            }
            
          
            let formData = new FormData();
            if (data.type === "single_image_choice") {
                
                for (var i = 0; i < answer.length; i++) {
                    formData.append(
                        "single_image_choice[" + i + "]",
                        answer[i].title
                    );
                }
            }

            if (data.type === "single_audio_with_image") {
                for (var i = 0; i < answer.length; i++) {
                    formData.append(
                        "single_audio_with_image[" + i + "]",
                        answer[i].title
                    );
                }
            }
            let url = 'questions'
            for (const property in data) {
                if (data[property])
                    formData.append(property, data[property]);
            }
            if(data.id !== ''){
                formData.append("_method", "put");
                url = `questions/${data.id}`;  
            }
            // console.log(data)
          this.closeModal()
            this.props.insertNewData(url,formData)
            .then(resp=>{
                this.props.getExamQuestions( data.exam_id)
                this.setState({
                    btnDisabled: false
                })
                if(resp == 'success'){
                    let count = this.props.answerCount +1;
                    this.props.changeStateValue({
                        name: 'answerCount',
                        value: count
                    })
                    this.props.changeStateValue({
                        name: 'adminExamModalData',
                        value: this.props.previousAnswers
                    })
                    mixinAlert('success', `Answer ${data.id == ''? 'added': 'edited'} successfully.`)

                }else{
                    mixinAlert('error', "Something went wrong. Please try again later.")
                }
            })
        }else{
            mixinAlert('error', 'Please fill the title field')
        }
    }
  render() {
    const {adminExamModalData} = this.props;
    const {btnDisabled} = this.state;
    return (
        <div className='flex justify-between items-center mt-[20px]'>
            <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Close</button>
            <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,adminExamModalData)} className='bg-orange pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Save</button>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    adminExamModalData: state.Data.adminExamModalData,
    previousAnswers: state.Data.previousAnswers,
    answerCount: state.Data.answerCount,
});
const mapDispatchToProps = {changeStateValue,insertNewData, getExamQuestions}
export default connect(mapStateToProps, mapDispatchToProps)(FooterBtn)
