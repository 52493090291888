import React, { Component } from 'react'

export class DashSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={21}
            height={20}
            fill="none"
        >
            <path
            fill={'var(--dark-gray)'}
            d="M8.61 2.812H5.171A1.563 1.563 0 0 0 3.61 4.375v3.437a1.563 1.563 0 0 0 1.562 1.563H8.61a1.562 1.562 0 0 0 1.562-1.563V4.375a1.562 1.562 0 0 0-1.563-1.563ZM8.296 7.5H5.484V4.687h2.813V7.5Zm8.125-4.688h-3.438a1.563 1.563 0 0 0-1.562 1.563v3.437a1.563 1.563 0 0 0 1.563 1.563h3.437a1.563 1.563 0 0 0 1.563-1.563V4.375a1.563 1.563 0 0 0-1.563-1.563ZM16.109 7.5h-2.812V4.687h2.813V7.5Zm-7.5 3.124H5.173a1.562 1.562 0 0 0-1.562 1.563v3.438a1.563 1.563 0 0 0 1.562 1.562H8.61a1.563 1.563 0 0 0 1.562-1.563v-3.437a1.562 1.562 0 0 0-1.563-1.563Zm-.312 4.688H5.484v-2.813h2.813v2.813Zm8.125-4.688h-3.438a1.562 1.562 0 0 0-1.562 1.563v3.438a1.563 1.563 0 0 0 1.563 1.562h3.437a1.563 1.563 0 0 0 1.563-1.563v-3.437a1.562 1.562 0 0 0-1.563-1.563Zm-.313 4.688h-2.812v-2.813h2.813v2.813Z"
            />
        </svg>
    )
  }
}

export default DashSvg
