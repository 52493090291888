import React, { Component } from 'react'
import Header from '../../components/header/Header'
import SectionHeader from '../../components/SectionHeader'
import { connect } from 'react-redux';
import { getParents, getOfficeAllStudents } from '../../actions/StaffAction';
import Card4 from '../../components/cards/Card4';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import { getDropValue, getRole } from '../../actions/getRole';
import StudentsModal from '../../components/modals/StudentsModal';
import { changeStateValue } from '../../redux/MainReducer';
import EmptyPage from '../../components/EmptyPage';
import DropSearch from '../../components/DropSearch';
import { getCompanies } from '../../actions/AdminActions';
import { mixinAlert } from '../../actions/SweetAlerts';
export class AdminParents extends Component {
  state={
    search:'',
    students:[],
    page:1,
    company_id:''
  }
//   componentDidUpdate(prevProps, prevState){
//     if(prevProps?.user !== this.props.user){
//        this.props.getParents(1, '', this.props.user?.company_id);  
//     }
//   }
  componentDidMount(){
    this.props.getCompanies(10, '');

    if(this.props.user !== ""){
       this.props.getParents(1, '', this.props.user?.company_id);  
    }
    window.scrollTo(0,0)
  }

  loadMore=(page)=>{
    this.setState({page:page})
    this.props.getParents(page, this.state.search, this.props.user?.company_id);  
  }

  searchParents=(search)=>{
    this.setState({search:search});
    this.props.getParents(1, search, this.props.user?.company_id);  
  }
  showStudents=(students)=>{
    this.setState({students:students})
  }
  getChilds(childs){
    let arr = [];
    for(const data of childs){
      arr.push({
        label: data?.first_name+" "+data?.last_name,
        value: data?.id
      })
    }
    return arr;
  }
  addUser=(data)=>{
   
    this.props.changeStateValue({
        name: "userModalData",
        value:{
            position: true,
            id: data?.id?data?.id:"",
            first_name: data?.first_name? data?.first_name:"",
            last_name: data?.last_name? data?.last_name:"",
            email: data?.email? data?.email:"",
            phone_number: data?.phone_number? data?.phone_number:"",
            date_of_birth: data?.date_of_birth? data?.date_of_birth:"",
            password: data?.password? data?.password:"",
            role: "parent",
            title: data?.id?this.props.lang?.edit_parent:this.props.lang?.add_parent,
            unRequireds: ["id",  "date_of_birth","password", "unRequireds", "afterCallFunction", "funcParams","childs", "avatar_full_url", "img", "errorMsg", "url", "avatar_url"],
            afterCallFunction: this.props.getParents,
            funcParams: [this.state.page, this.state.search, this.props.user?.company_id],
            company_id: this.props.user?.company_id,
            childs: data?.parentChildren? this.getChilds(data?.parentChildren):[],
            url:'users',
            errorMsg: "Email already exists",
            img:true,
            avatar_full_url: data?.avatar_full_url?data?.avatar_full_url:''
        }
    })
}

selectCompany=(id)=>{
    this.setState({company_id:id})
    this.props.getOfficeAllStudents(id)
}
  render() {
    const {parents, lang, adminCompanies} = this.props;
    const {students, company_id} = this.state;
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
          <Header title="parents" />

          <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
            <SectionHeader right={false} onClickAdd={this.addUser.bind(this, "")} onSearchData={(search)=>this.searchParents(search)} lockUnlock={false} calendar={false} btnTitle={lang?.add_parent} />
          </div>
          {/* <div  className='flex white-search mt-[20px]'>
          <DropSearch title="Get Students by Company" selectData={this.selectCompany.bind(this)} value={company_id} placeholder="Get Students By Company" options={getDropValue(adminCompanies, "name","id")}  />
          </div> */}
          {parents?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
          <div className='mt-[42px] grid-full-250'>
            {
              parents?.map((data, i)=>{
                return(
                  <Card4
                    key={i}
                    name={data?.full_name}
                    email={data?.email}
                    phone={data?.phone_number}
                    image={data?.avatar_full_url}
                    role={getRole(data?.role, this.props.lang)}
                    headerBtn={false}
                    childs={true}
                    child={data.parentChildren.length !== 0 ? data.parentChildren[0]?.first_name+" "+data.parentChildren[0]?.last_name:null}
                    moreStudent={data.parentChildren.length >1}
                    showStudents={this.showStudents.bind(this, data.parentChildren)}
                    onClickEdit={this.addUser.bind(this,data)}
                  />
                )
              })
            }
          </div>
          {
            parents?.length >=20?
            <LoadMoreBtn onLoadData={this.loadMore} count={parents?.length} />:null
          }
          {students.length !== 0? <StudentsModal data={students} closeBtn={()=>{this.setState({students:[]})}} />:null}
          
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  parents: state.Data.parents,
  user: state.Data.user,
  lang: state.Data.lang,
  adminCompanies: state.Data.adminCompanies,
});
const mapDispatchToProps = {getParents, changeStateValue, getOfficeAllStudents, getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(AdminParents)


