import React, { Component } from 'react'

export class CardHorisontal extends Component {
  render() {
    return (
      <div style={{ clipPath:'polygon(0 0, 100% 90%, 100% 100%, 0 100%)', width:'100%',height:'60px',position:'relative', backgroundColor:'white', marginTop:'-56px'}}>
      </div>
    )
  }
}

export default CardHorisontal
