import React, { Component } from 'react'
import { endRecording, startRecording } from '../../../../actions/ReorAudio';
import {changeStateValue} from "../../../../redux/MainReducer";
import { connect } from 'react-redux';
import { ReactMic } from 'react-mic';
import wave from "../../../../images/recordGif.gif"
let interval;
export class Part2Questions extends Component {

    state={
        timeThink: "01:00",
        micPosition: 'start',
        base64:'',
        index:0,
        startTimer:60,
        startedConv: false,
        isRecording:false
    }
    zeroPad(num){
        if(num>=10){
            return num;
        }else{
            return `0${num}`
        }
    }
    timeStart(){
        this.setState({
            timeThink: "01:00",
            micPosition: 'start',
            startTimer:60
        })
        interval = setInterval(()=>{
            let timer = this.state.startTimer;
            timer--;
            this.setState({
                startTimer: timer,
                timeThink:`00:${this.zeroPad(timer)}`
            })
            if(timer === 0){
                clearInterval(interval);
                this.startMic();
            }
        }, 1000)
    }
    componentDidMount(){
        this.timeStart();
    }
    startMic(){
        this.setState({startedConv:true, micPosition:'pause'})
        clearInterval(interval);
        startRecording();
        this.setState({isRecording: true})
    }

    pauseMic=()=>{
        const result = endRecording();
        this.setState({base64: result?.audio_url});
        this.props.changeStateValue({name:`examsData.1.given_answer1`, value:result.audioName})
        this.setState({isRecording: false})
    }

    resetPart=()=>{
        this.setState({
            timeThink: "01:00",
            micPosition: 'start',
            base64:'',
            index:0,
            startTimer:60,
            startedConv: false
        });
        clearInterval(interval);
        this.props.changeStateValue({name:`examsData.1.given_answer1`, value:''});
        this.props.onReset()
        this.setState({isRecording: false})
    }
    nextPage=()=>{
        this.setState({
            timeThink: "01:00",
            micPosition: 'start',
            base64:'',
            index:0,
            startTimer:60,
            startedConv: false
        });
        clearInterval(interval); 
        this.props.onChangePage();
        this.setState({isRecording: false})
    }
  render() {
    const {data} = this.props;
    const {timeThink, micPosition, base64, isRecording} = this.state;
    // console.log(base64)
    return (
        <div className='w-full flex flex-col gap-[10px] justify-center items-center'>
            {/* <h4  className='text-[22px] font-[600] txt-orange'>{timeThink}</h4> */}
            {
                base64 ==""?
                <>
                    <h2 className='text-[22px] font-[600] txt-orange'>Question</h2>
                    <h2 className='text-[22px] font-[600] list-style-add' dangerouslySetInnerHTML={{__html: data?.questions[0]?.description}}></h2>
                    {/* <ReactMic
                            record={isRecording}
                            className={`w-[150px] h-[60px] ${isRecording?"":'hidden'}`}
                            onStop={()=> console.log("stop")}
                            onData={()=> console.log("data")}
                            strokeColor="#000000"
                            backgroundColor="white"
                        /> */}
                         {isRecording?  <img className='h-[30px] max-sm:w-[50%] w-[120px]' alt='recording' src={wave} />:null}
                    {
                        micPosition == "start"?
                        <button onClick={this.startMic.bind(this)} className='text-[14px] text-white bg-blue p-[5px_10px] rounded-[5px]'>Time to think: {timeThink}</button>:null
                    }
                    {
                        micPosition == "pause"?
                        <button onClick={this.pauseMic.bind(this)} className='text-[14px] text-white bg-blue p-[5px_10px] rounded-[5px]'>Save & Next</button>:null
                    }
                </>:
                <>
                  <h2 className='text-[22px] font-[600] txt-orange'>It’s the end of Part 2</h2>
                  <p  className='text-center'>You can review your part 2 recording by clicking the Play button below</p>
                  <audio className='max-sm:w-[200px] w-[220px] h-[40px] max-[320px]:w-[150px]' controls src={base64} />
                  <p className='text-center text-[14px]'>You can click Next Part to continue Part 3
                        <br />
                        Or Reset this part to record again</p>
                    <div className='flex justify-center gap-[30px]'>
                        <button onClick={this.resetPart.bind(this)} className='text-white bg-orange p-[4px_10px] rounded-[5px] text-[14px]'>Reset This Part</button>
                        <button onClick={this.nextPage.bind(this)} className='text-white bg-blue p-[4px_10px] rounded-[5px] text-[14px]'>Next Part</button>
                    </div>
                </>
            }
            
      </div>
    )
  }
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Part2Questions)
