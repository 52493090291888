import React, { Component } from 'react'
import Header from './Header';
import { connect } from 'react-redux';
import { getStudentGPA } from '../../actions/MainAction';
export class StudentMoreModal extends Component {
    state={
        averages:[]
    }
    componentDidMount(){
        this.props.getStudentGPA(this.props.data?.id)
        .then(resp=>{
            this.setState({averages:resp})
        })
    }
    closeBackground(e){
        if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
          this.props.closeBtn();
        }
    }
  render() {
    const {data, closeBtn, lang} = this.props;
    const {averages} = this.state;
    return (
        <div onClick={this.closeBackground.bind(this)} className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[338px] rounded-[10px]'>
                <Header closeBtn={closeBtn} title={data?.full_name} />
                <div className='bg-white p-[10px] rounded-b-[10px] gap-[6px] flex flex-col gap-[5px] max-h-[70vh] overflow-auto'>
                    {
                        data?.children_parent?.length !== 0?
                        <div className='bg-gray-100 p-[5px] rounded-[5px] flex flex-col'>
                            <span className='text-[14px] font-semibold txt-blue'>{lang?.role_parent}</span>
                            <div className='p-[2px] pl-[5px] flex flex-col gap-[5px]'>
                                {
                                     data?.children_parent.map((item, i)=>{
                                        return(
                                            <span key={i} className='text text-[13px]'>{item?.first_name+" "+item?.last_name}</span>
                                        )
                                     })
                                }
                            </div>
                        </div>:null
                    }
                    {
                        data?.teacher.length !== 0?
                        <div className='bg-gray-100 p-[5px] rounded-[5px] flex flex-col'>
                            <span className='text-[14px] font-semibold txt-blue'>{lang?.teacher}</span>
                            <div className='p-[2px] pl-[5px] flex flex-col gap-[5px]'>
                                {
                                     data?.teacher.map((item, i)=>{
                                        return(
                                            <span key={i} className='text text-[13px]'>{item?.first_name+" "+item?.last_name}</span>
                                        )
                                     })
                                }
                            </div>
                        </div>:null
                    }
                    {
                        averages.length !== 0?
                        <div className='bg-gray-100 p-[5px] rounded-[5px] flex flex-col'>
                            <span className='text-[14px] font-semibold txt-blue'>{'Exam GPA'}</span>
                            <div className='p-[2px] pl-[5px] flex flex-col gap-[5px]'>
                                {
                                     averages.map((item, i)=>{
                                        return(
                                            <span key={i} className='text text-[13px]'>{item?.title}: {Math.round(item?.average)}</span>
                                        )
                                     })
                                }
                            </div>
                        </div>:null
                    }
                    {
                        data?.student_enroll_class.length !== 0?
                        <div className='bg-gray-100 p-[5px] rounded-[5px] flex flex-col'>
                            <span className='text-[14px] font-semibold txt-blue'>{lang?.class}</span>
                            <div className='p-[2px] pl-[5px] flex flex-col gap-[5px]'>
                                {
                                     data?.student_enroll_class.map((item, i)=>{
                                        return(
                                            <span key={i} className='text text-[13px]'>{item?.lesson_mode}</span>
                                        )
                                     })
                                }
                            </div>
                        </div>:null
                    }
                  
             
                    <div className='bg-gray-100 p-[5px] rounded-[5px] flex flex-col'>
                        <span className='text-[14px] font-semibold txt-blue'>{lang?.others}</span>
                        <div className='p-[2px] pl-[5px] flex flex-col gap-[5px]'>
                            <span className='text text-[13px]'>{lang?.payy_mode}:  {data?.payment_Reminder_max_value === '12' ? lang?.standart:  lang?.intensive}</span>
                            <span className='text text-[13px]'>{lang?.sch_year}: {data?.school_year}</span>
                            <span className='text text-[13px]'>{lang?.email}: {data?.email}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps= (state) =>({
    lang: state.Data.lang
})
const mapDispatchToProps = {getStudentGPA}
export default connect(mapStateToProps, mapDispatchToProps)(StudentMoreModal)
