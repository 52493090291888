import React, { Component } from 'react'

export class HWSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
      >
        <path
          stroke="#585874"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M16.5 16.5h-15m0-8.25 6.095-4.875a2.25 2.25 0 0 1 2.81 0L16.5 8.25m-4.875-4.125v-1.5A.375.375 0 0 1 12 2.25h1.875a.375.375 0 0 1 .375.375v3.75M3 16.5V7.125M15 16.5V7.125"
        />
        <path
          stroke="#585874"
          strokeWidth={1.5}
          d="M11.25 16.5v-3.75c0-1.06 0-1.59-.33-1.92-.329-.33-.859-.33-1.92-.33-1.06 0-1.59 0-1.92.33-.33.329-.33.859-.33 1.92v3.75m3.75-9.375a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
        />
      </svg>
    )
  }
}

export default HWSvg
