import React, { Component } from 'react'
import { withHooks } from '../../../actions/withHooks'
import Header from '../../../components/header/Header'
import { connect } from 'react-redux'
import { getUserData } from '../../../actions/StaffAction';
import { getTeacherStudentPayment, getStudentPayment } from '../../../actions/StaffAction';
import moment from 'moment';
import Calendar from '../../../components/Calendar';
import Search from '../../../components/Search';
import { changeStateValue } from '../../../redux/MainReducer';
import EmptyPage from '../../../components/EmptyPage';
import TeacherEventCalendar from './TeacherEventCalendar';
import MenuBarSvg from '../../../components/svg/MenuBarSvg';
import MenuCloseSvg from '../../../components/svg/MenuCloseSvg';
export class TeacherEvents extends Component {
    state={
        teacherData:'',
        studentData:[],
        search:'',
        date:new Date()
    }
    componentDidMount(){
        this.props.changeStateValue({name:'sideBarVisible', value:false})
        let date = new Date()
        const allDays = new Date(
            date.getFullYear(),
            date.getMonth()+1,
            0
        ).getDate()
        this.props.changeStateValue({name:"loaderLong", value:true})
        this.props.getTeacherStudentPayment('',  moment(new Date()).format('YYYY-MM-01'),  moment(new Date()).format(`YYYY-MM-${allDays}`), this.props.params.id)
        .then(resp=>{
            this.setState({studentData:resp});
            this.props.changeStateValue({name:"loaderLong", value:false})
        })

        this.props.getUserData(this.props.params.id)
        .then(resp=>{
            this.setState({teacherData:resp})
           
        })
    }
    getCheckinData=(index, studentData, date)=>{
        const allDays = new Date(
            date.getFullYear(),
            date.getMonth()+1,
            0
        ).getDate()
        this.props.getStudentPayment(studentData[index]?.student?.id, moment(date).format('YYYY-MM-01'),  moment(date).format(`YYYY-MM-${allDays}`, false))
        .then(resp=>{
            let oldData = [...studentData];
            oldData[index].payment = resp;
            this.setState({studentData: oldData})
        })
    }
    onSearchData=(search)=>{
        this.setState({search:search})
    }
    onChangeDate=(date)=>{
        this.setState({date:date})
        const allDays = new Date(
            date.getFullYear(),
            date.getMonth()+1,
            0
        ).getDate()
        this.props.changeStateValue({name:"loaderLong", value:true})
        this.props.getTeacherStudentPayment('',  moment(date).format('YYYY-MM-01'),  moment(date).format(`YYYY-MM-${allDays}`), this.props.params.id)
        .then(resp=>{
            this.setState({studentData:resp});
            this.props.changeStateValue({name:"loaderLong", value:false})
        })
    }

    changeMenuBar=(position)=>{
        this.props.changeStateValue({name:'sideBarVisible', value:position})
    }
    componentWillUnmount(){
        this.props.changeStateValue({name:'sideBarVisible', value:true})
    }
    getAfterChange=()=>{
        const allDays = new Date(
            this.state.date.getFullYear(),
            this.state.date.getMonth()+1,
            0
        ).getDate()
        this.props.changeStateValue({name:"loaderLong", value:true})
        this.props.getTeacherStudentPayment('',  moment(this.state.date).format('YYYY-MM-01'),  moment(this.state.date).format(`YYYY-MM-${allDays}`), this.props.params.id)
        .then(resp=>{
            this.setState({studentData:resp});
            this.props.changeStateValue({name:"loaderLong", value:false})
        })
    }
  render() {
    const {teacherData, studentData, search} = this.state;
    const {sideBarVisible} = this.props;
    // console.log(studentData)
    return (
        <div className='w-full max-sm:pl-[10px] max-sm:pr-[10px] pl-[27px] pr-[27px]'>
            <Header title={teacherData?.full_name} />
            <div className='flex  max-[1100px]:mt-[120px] max-[800px]:mt-[160px]'>
                {/* <Search largWidth={true} onSearchData={this.onSearchData} /> */}
                <button onClick={this.changeMenuBar.bind(this, !sideBarVisible)}>
                    {!sideBarVisible? <MenuBarSvg />: <MenuCloseSvg /> }
                </button>
            </div>
            {studentData.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            {
                studentData.length !=0?
                <div className='flex flex-col gap-[50px] mt-[40px]'>
                    <TeacherEventCalendar getAfterChange={this.getAfterChange.bind(this)} teacher={teacherData} onChangeDate={this.onChangeDate.bind(this)} teacherId={this.props.params.id} studentData={studentData}  />
                </div>:null
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    sideBarVisible: state.Data.sideBarVisible
});
const mapDispatchToProps = {getUserData, getTeacherStudentPayment, getStudentPayment, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(TeacherEvents))
