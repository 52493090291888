import React, { Component } from 'react'
import { withHooks } from '../../actions/withHooks';
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import Search from '../../components/Search';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import { getEnrollPayments } from '../../actions/StaffAction';
import Card4 from '../../components/cards/Card4';
import EmptyPage from '../../components/EmptyPage';
import moment from 'moment';
import ContractPdfSvg from '../../components/svg/ContractPdfSvg';
import { getCompanyTeachers } from '../../actions/AdminActions';
import DropSearch from '../../components/DropSearch';
import { getDropValue } from '../../actions/getRole';
import NoteSvg from '../../components/svg/NoteSvg';
import { bigAlert, mixinAlert } from '../../actions/SweetAlerts';
import { Link } from 'react-router-dom';
import ExcelExportButton from '../../components/ExcelExportButton';
export class AccountantPayments extends Component {
    state={
        page:1,
        search:'',
        date: moment(new Date()).format("YYYY-MM"),
        teachers:[],
        teacher_id:''
      }
      componentDidMount(){
        this.props.getEnrollPayments(1,'','all', this.props.params.id, moment(new Date()).format("YYYY-MM"), this.state.teacher_id)
        this.props.getCompanyTeachers(this.props.params.id)
        .then(resp=>{
            this.setState({teachers:resp})
        })
      }
    
      countRemaining=(payments)=>{
        let total = 0;
        for(const x of payments){
          total+= x?.count
        }
        return total;
      }
    
    loadMore=(page)=>{
        this.setState({page:page});
        this.props.getEnrollPayments(page,this.state.search, 'all',  this.props.params.id, this.state.date, this.state.teacher_id)
      }
    onSearchData=(search)=>{
        this.setState({search:search});
        this.props.getEnrollPayments(this.state.page,search, 'all',  this.props.params.id, this.state.date, this.state.teacher_id)
    }
    changeDate=(e)=>{
        this.setState({date: e.target.value})
        this.props.getEnrollPayments(this.state.page, this.state.search, 'all',  this.props.params.id, e.target.value, this.state.teacher_id)
    }
    onSelectTeacher=(teacherId)=>{
        this.setState({teacher_id:teacherId});
        this.props.getEnrollPayments(this.state.page, this.state.search, 'all',  this.props.params.id, this.state.date, teacherId)
    }

    onShowBtn=(note)=>{
        bigAlert("", note)
    }
  render() {
    const {enrollPayments, lang} = this.props;
    const {date, teachers} = this.state
    // console.log(enrollPayments)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={this.props.params.company_name?.replaceAll("%20", " ")} />
            <div className='flex  gap-[20px] mt-[10px]'>
                <Link  to={`/accountant-teachers-contracts/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center  txt-blue bg-white   text-[22px] rounded-[6px]'>{lang?.salary_docs}</Link>
                <Link  to={`/accountant-payments/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center shadow text-[22px] bg-blue text-white rounded-[6px]'>{lang?.pays_acc}</Link>
                <Link  to={`/accountant-teachers/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center txt-blue bg-white shadow text-[22px] rounded-[6px]'>{lang?.t_count_acc}</Link>
                <Link  to={`/accountant-celtgo/${this.props.params?.id}/${this.props.params?.company_name}`} className='p-[5px] w-[200px] h-[60px] inline-flex justify-center items-center   txt-blue bg-white shadow text-[22px] rounded-[6px]'>{lang?.celtgo_acc}</Link>
            </div>
            <div className='flex justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[160px]'>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            <div className='mt-[20px] flex items-center white-search gap-[20px]'>
                <DropSearch selectData={this.onSelectTeacher.bind(this)} options={getDropValue(teachers, "full_name", "id")} placeholder="Select a teacher" />
                <input className='p-[3px]' onChange={this.changeDate.bind(this)} type='month' value={moment(new Date(date)).format("YYYY-MM")} />
                <ExcelExportButton companyId={this.props.params.id} fileName={this.props.params.company_name?.replaceAll("%20", " ")} date={date} />
            </div>
            {enrollPayments.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
            {
                enrollPayments?.map((data, i)=>{
                return(
                    <div className='bg-white rounded-[10px]'>
                        <Card4
                            key={i}
                            name={data?.student?.full_name}
                            image={data?.student?.avatar_full_url}
                            course={data?.course?.title}
                            lesson_mode={`${data?.status==0?'Locked:':''} ${data?.lesson_mode}`}
                            teacher={data?.teacher?.full_name}
                            editBtnPermisiion={false}
                            titleRemaining={this.countRemaining(data?.payments)}
                            lock={this.countRemaining(data?.payments)==0?0:1}
                            namePath={`/students/${data?.student?.id}`}
                        />
                        <p className='text-center text-[13px] font-semibold mt-[5px] mb-[5px]'>{lang?.fee}: {data?.fee}</p>
                        {
                            data?.payments?.length !=0?
                            <>
                           <div className='flex justify-center'>
                            <span className='text-[12px]'>{lang?.pay_receipts_accountant}</span>
                           </div>
                            <div className='flex gap-[10px] p-[0_20px]'>
                                {
                                    data?.payments?.map((item, j)=>{
                                        return(
                                            <div className='flex flex-col items-center border-r p-[3px] justify-end'>
                                                {
                                                    item?.image_full_url?
                                                    <a key={j} href={item?.image_full_url} target='_blank'>
                                                        <img src={item?.image_full_url} className='w-[20px] h-[20px] rounded-[5px]' />
                                                    </a>:
                                                    item?.file_full_url?
                                                    <a key={j} href={item?.file_full_url} download target='_blank'>
                                                        <ContractPdfSvg size={20} />
                                                    </a>:null
                                                }
                                            <div className='flex items-center gap-[4px]'>
                                                {
                                                    item?.note?
                                                    <button onClick={this.onShowBtn.bind(this, item?.note)}>
                                                        <NoteSvg />
                                                    </button>:null
                                                }
                                                    
                                                    <span className='text-[12px]'>{item?.count} / {item?.static_count}</span>
                                            </div>
                                            </div>
                                        )
                                        
                                    })
                                }
                            </div>
                            </>:null
                        }
                        {
                            data?.student?.student_contracts?.length !=0?
                            <div className='border-t'>
                           <div className='flex justify-center'>
                            <span className='text-[12px]'>{lang?.contracts_accountant}</span>
                           </div>
                            <div className='flex gap-[10px] p-[0_20px]'>
                                {
                                    data?.student?.student_contracts?.map((item, j)=>{
                                        return(
                                            <div className='flex flex-col items-center border-r p-[3px] justify-end'>
                                                {
                                                    item?.image_full_url?
                                                    <a key={j} href={item?.image_full_url} target='_blank'>
                                                        <img src={item?.image_full_url} className='w-[20px] h-[20px] rounded-[5px]' />
                                                    </a>:
                                                    item?.file_full_url?
                                                    <a key={j} href={item?.file_full_url} download target='_blank'>
                                                        <ContractPdfSvg size={20} />
                                                    </a>:null
                                                }
                                            </div>
                                        )
                                        
                                    })
                                }
                            </div>
                            </div>:null
                        }
                        
                    </div>
                )
                })
            }
            </div>
            {
              enrollPayments?.length >=20?
              <LoadMoreBtn onLoadData={this.loadMore} count={enrollPayments?.length} />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  enrollPayments: state.Data.enrollPayments,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getEnrollPayments, getCompanyTeachers}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AccountantPayments))
