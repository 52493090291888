import React, { Component } from 'react'
import { getStudentPayments } from '../../../../actions/StaffAction';
import { connect } from 'react-redux';
import { withHooks } from '../../../../actions/withHooks';
import Card4 from '../../../../components/cards/Card4';
import ContractPdfSvg from '../../../../components/svg/ContractPdfSvg';
import NoteSvg from '../../../../components/svg/NoteSvg';
import { bigAlert } from '../../../../actions/SweetAlerts';

export class StudentEnroll extends Component {
    state={
        payments:[]
    }
    componentDidMount(){
        this.props.getStudentPayments(this.props.params.id)
        .then(resp=>{
            this.setState({payments: resp})
        })
    }
    countRemaining=(payments)=>{
        let total = 0;
        for(const x of payments){
          total+= x?.count
        }
        return total;
      }
      onShowBtn=(note)=>{
        bigAlert("", note)
    }
  render() {
    const {payments} = this.state;
    const {lang} = this.props
    // console.log(payments)
    return (
      <div>
          <div className='mt-[42px] grid grid-full-320'>
            {
                payments.map((data, i)=>{
                    return(
                        <div className='bg-white rounded-[10px]'>
                        <Card4
                            key={i}
                            name={data?.student?.full_name}
                            image={data?.student?.avatar_full_url}
                            course={data?.course?.title}
                            lesson_mode={`${data?.status==0?'Locked:':''} ${data?.lesson_mode}`}
                            teacher={data?.teacher?.full_name}
                            editBtnPermisiion={false}
                            titleRemaining={this.countRemaining(data?.payments)}
                            lock={this.countRemaining(data?.payments)==0?0:1}
                            namePath={`/students/${data?.student?.id}`}
                        />
                        <p className='text-center text-[13px] font-semibold mt-[5px] mb-[5px]'>{lang?.fee}: {data?.fee}</p>
                        {
                            data?.payments?.length !=0?
                            <>
                           <div className='flex justify-center'>
                            <span className='text-[12px]'>{lang?.pay_receipts_accountant}</span>
                           </div>
                            <div className='flex gap-[10px] p-[0_20px]'>
                                {
                                    data?.payments?.map((item, j)=>{
                                        return(
                                            <div className='flex flex-col items-center border-r p-[3px] justify-end'>
                                                {
                                                    item?.image_full_url?
                                                    <a key={j} href={item?.image_full_url} target='_blank'>
                                                        <img src={item?.image_full_url} className='w-[20px] h-[20px] rounded-[5px]' />
                                                    </a>:
                                                    item?.file_full_url?
                                                    <a key={j} href={item?.file_full_url} download target='_blank'>
                                                        <ContractPdfSvg size={20} />
                                                    </a>:null
                                                }
                                            <div className='flex items-center gap-[4px]'>
                                                {
                                                    item?.note?
                                                    <button onClick={this.onShowBtn.bind(this, item?.note)}>
                                                        <NoteSvg />
                                                    </button>:null
                                                }
                                                    
                                                    <span className='text-[12px]'>{item?.count} / {item?.static_count}</span>
                                            </div>
                                            </div>
                                        )
                                        
                                    })
                                }
                            </div>
                            </>:null
                        }
                        {
                            data?.student?.student_contracts?.length !=0?
                            <div className='border-t'>
                           <div className='flex justify-center'>
                            <span className='text-[12px]'>{lang?.contracts_accountant}</span>
                           </div>
                            <div className='flex gap-[10px] p-[0_20px]'>
                                {
                                    data?.student?.student_contracts?.map((item, j)=>{
                                        return(
                                            <div className='flex flex-col items-center border-r p-[3px] justify-end'>
                                                {
                                                    item?.image_full_url?
                                                    <a key={j} href={item?.image_full_url} target='_blank'>
                                                        <img src={item?.image_full_url} className='w-[20px] h-[20px] rounded-[5px]' />
                                                    </a>:
                                                    item?.file_full_url?
                                                    <a key={j} href={item?.file_full_url} download target='_blank'>
                                                        <ContractPdfSvg size={20} />
                                                    </a>:null
                                                }
                                            </div>
                                        )
                                        
                                    })
                                }
                            </div>
                            </div>:null
                        }
                        
                    </div>
                    )
                })
            }
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {getStudentPayments}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(StudentEnroll))
