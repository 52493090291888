import React, { Component } from 'react'
import pdfImg from "../../images/defPdf.png"
import { connect } from 'react-redux';
import { insertNewData,  } from '../../actions/MainAction';
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { getMsgBoxData,getGroupMsgBoxData, getChatUsers, getGroupUsers, sendNotification } from '../../actions/MessageAction';
export class MsgFilePreview extends Component {
  state={
    disable:false
  }
  closebtn=()=>{
    this.props.resetStateValue({ name:'messageFileSend' })
  }
  sendBtn=(data)=>{
    if(data?.file !== ''){
      this.setState({disable:true})
      let formData = new FormData();
      formData.append('message_group_id', data.id);
      if(data.replied_id != ''){
        formData.append('replied_id', data.replied_id);
      }else{
        formData.append('replied_id', '');
      }
      formData.append(data?.type, data?.file);
      formData.append('message', data?.file.name);
      this.props.sendNotification(data?.noturl, formData)
      this.props.insertNewData(data?.url, formData)
      .then(resp=>{ 
        this.setState({disable:false})
        if(data?.dataType === "group"){
          this.props.getGroupMsgBoxData(data?.id, 1);
        }else{
          this.props.getMsgBoxData(data?.id);
        }
        this.props.getChatUsers();
        this.props.getGroupUsers();
        this.props.onClose()
        this.closebtn()
      })
    }
  }
  render() {
    const {messageFileSend, lang} = this.props;
    const {disable} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white p-[20px] rounded-[10px] w-[500px] max-h-[70vh] overflow-auto'>
          {
            messageFileSend?.type === "image"?
            <img className='rounded-[6px] w-full' src={messageFileSend?.preview} />:
            messageFileSend?.type === "pdf"?
            <img className='w-full' src={pdfImg} />:
            <audio src={messageFileSend?.preview} className='listening-audio' controls />
          }
            {/*  */}
            {/* <audio className='listening-audio' controls /> */}
            {/* */}
            <div className='flex justify-between mt-[10px]'>
                <button disabled={disable} onClick={this.closebtn.bind(this)} className='p-[6px] text-[14px] pt-[4px] pb-[4px] text-white font-semibold bg-red rounded-[6px]'>{lang?.close}</button>
                <button disabled={disable} onClick={this.sendBtn.bind(this, messageFileSend)} className='p-[6px] text-[14px] pt-[4px] pb-[4px] text-white font-semibold bg-blue rounded-[6px]'>{lang?.send}</button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  messageFileSend: state.Data.messageFileSend,
  lang: state.Data.lang,
});
const mapDispatchToProps = {insertNewData, changeStateValue, resetStateValue,getGroupMsgBoxData, getMsgBoxData, getChatUsers, getGroupUsers, sendNotification}
export default connect(mapStateToProps, mapDispatchToProps)(MsgFilePreview)
