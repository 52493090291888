import React, { Component } from 'react'
import Header from '../../../../components/modals/Header'
import { connect } from 'react-redux'
import { mixinAlert } from '../../../../actions/SweetAlerts'
import { getTurboModel } from '../../../../actions/robotActions'
import { getExamQuestionGPT } from '../../../../actions/getReviewGpt';
import { changeStateValue, resetStateValue } from '../../../../redux/MainReducer'
import { withHooks } from '../../../../actions/withHooks'
export class RoboQuestionModal extends Component {
    state={
        descPrompt:'',
        score:'',
        robo:'',
        btnDisabled:false,
        questions:[]
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"modalRoboQuestion"})
    }
    getParentDesc(data, text){
        let html =  data?.parentDesscription.replace(/<[^>]+>/g, ' ')
        this.setState({
            robo: `${html} ${text}`
        })
    }
    saveBtn=(text)=>{
        if(text.trim() !=="" && this.state.score !==""){
            this.setState({btnDisabled:true});
            this.props.changeStateValue({name:"loader", value:true})
            this.props.getTurboModel(getExamQuestionGPT(text, this.state.descPrompt))
            .then(resp=>{
                this.props.changeStateValue({name:"loader", value:false})
                this.props.changeStateValue({
                    name:'modalRoboPrev',
                    value: {
                        questions:JSON.parse(resp),
                        parent_id: this.props.modalRoboQuestion?.parentId,
                        score: this.state.score,
                        exam_id: this.props.params.id
                    }
                })
            })
        }else{
            mixinAlert("warning", "Please fill the required field")
        }
    }
  render() {
    const {descPrompt, score, robo, btnDisabled} = this.state;
    const {modalRoboQuestion} = this.props;
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[900px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={"Generate Question"} />
                <div className='bg-white p-[10px] rounded-b-[10px]'>
                    <div>
                        <button onClick={this.getParentDesc.bind(this,modalRoboQuestion, robo)} className='bg-blue text-white p-[7px] text-[14px] font-semibold rounded-[6px]'>Get Parent Description </button>
                    </div>
                    <div className='mt-[20px]'>
                        <button onClick={()=>this.setState({descPrompt: descPrompt == ''? ", description: '',": ''})} className={`${descPrompt === ""? "border border-[var(--blue)] txt-blue": "bg-blue text-white"}  p-[7px] text-[14px] font-semibold rounded-[6px]`}>With Description</button>
                    </div>
                    <div className='mt-[20px]'>
                        <input value={score} onChange={(e)=>this.setState({score: e.target.value})} className={`w-[140px] h-[35px] rounded-[6px] border pl-[7px] outline-none ${score === ""? "border-red-600":"border-gray-300"}`} type='number' placeholder='Score' />
                    </div>
                    <div className='mt-[20px]'>
                        <span className='text-[14px] txt-light-black'>Robo Prompt</span>
                        <textarea placeholder='Robo Prompt' value={robo} onChange={(e)=>this.setState({robo: e.target.value})} className='w-full h-[150px] border rounded-[6px] outline-none p-[7px] text-[14px]' />
                    </div>
                    <div className='flex justify-between items-center mt-[20px]'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Close</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,robo)} className='bg-orange pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    modalRoboQuestion: state.Data.modalRoboQuestion
});
const mapDispatchToProps = {getTurboModel, changeStateValue, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(RoboQuestionModal))
