import React, { Component } from 'react'

export class ProsessingSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={76}
    height={76}
    viewBox="0 0 48 48"
    fill="var(--blue)"
  >
    <title>{"processing"}</title>
    <g data-name="Layer 2">
      <g fill="none" data-name="invisible box">
        <path d="M0 0h48v48H0z" />
        <path d="M0 0h48v48H0z" />
        <path d="M0 0h48v48H0z" />
      </g>
      <g data-name="Q3 icons">
        <path d="M25.4 5.5a1.9 1.9 0 0 0-2.8.2 2 2 0 1 0 2.8-.2ZM31.5 8.4a2.2 2.2 0 0 0 .2 3.1 2.3 2.3 0 1 0 3-3.4 2.2 2.2 0 0 0-3.2.3ZM41.9 19.2a2.7 2.7 0 1 0-3.9.3 2.7 2.7 0 0 0 3.9-.3ZM42 27.6a3.5 3.5 0 1 0-4.5 5.3 3.5 3.5 0 0 0 4.5-5.3ZM26.1 37.1a4.5 4.5 0 1 0 6.3-.5 4.4 4.4 0 0 0-6.3.5ZM9.9 34.3a5.1 5.1 0 0 0 .5 7.1 5 5 0 1 0-.5-7.1Z" />
        <circle
          cx={7.2}
          cy={22.1}
          r={5.5}
          transform="matrix(1 -.08 .08 1 -1.68 .62)"
        />
      </g>
    </g>
  </svg>
    )
  }
}

export default ProsessingSvg
