import React, { Component } from 'react'
import ExamParts from './ExamParts';
import { connect } from 'react-redux';
import { getExamQuestions } from '../../../actions/AdminActions';
import { withHooks } from '../../../actions/withHooks';
import { changeStateValue } from '../../../redux/MainReducer';
import { Link } from 'react-router-dom';
import { mixinAlert } from '../../../actions/SweetAlerts';
import { insertNewData } from '../../../actions/MainAction';
export class ExamParents extends Component {
    state={
        score:'',
        btnDisable:false
    }
    componentDidMount(){
        if(this.props.params.id){
            this.props.getExamQuestions(this.props.params.id)
        }
    }
   
    addScoreBtn=(score)=>{
        if(score && score !==""){
            this.setState({btnDisable:true})
            this.props.insertNewData("exam-auto-score", {exam_id: this.props.params.id, score: score})
            .then(resp=>{
                this.setState({btnDisable:false, score:''})
                if(resp === "success"){
                    this.props.getExamQuestions(this.props.params.id)
                    mixinAlert("success", "Score added successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else{
            mixinAlert("warning", "Please add score firstly.")
        }
    }
  render() {
    const {adminExamQuestions} = this.props;
    const {score, btnDisable} = this.state;
    // console.log(adminExamQuestions)
    return (
      <div className='bg-white p-[20px] mt-[20px] rounded-[12px] shadow'>
        <div className='flex items-center justify-between'>
            <h2 className='text-[22px] font-semibold txt-blue cursor-pointer'>General English Questions</h2>
           <div className='flex items-center gap-[20px]'>
            {/* <div className='flex items-center gap-[10px]'>
                <input value={score} onChange={(e)=>this.setState({score:e.target.value})} className='bg-gray-100 w-[100px] h-[30px] outline-none rounded-[6px] text-[13px] pl-[4px]' type='number' placeholder='Score' />
                <button disabled={btnDisable} onClick={this.addScoreBtn.bind(this, score)} className='bg-orange p-[5px] rounded-[6px] text-white font-semibold text-[14px]'>Add score</button>
            </div> */}
            {
                this.props.params.id?
                <Link target='_blank' className='bg-blue p-[5px] rounded-[6px] text-white font-semibold text-[14px]' to={`/admin-exams/${this.props.params.id}/prev`}>Preview</Link>:null
            }
           </div>
        </div>
        <div>
            <div className='flex items-center gap-[20px] mt-[20px]'>
                <button onClick={()=>{this.props.changeStateValue({name:'adminExamModalData.position', value:true}); this.props.changeStateValue({name:'adminExamModalData.descriptionPosition', value:true})}} className='h-[35px] pl-[7px] pr-[7px] text-[14px] font-semibold bg-blue text-white rounded-[6px]'>Add a Parent</button>
                <button onClick={()=>{this.props.changeStateValue({name:'adminExamModalData.position', value:true}); this.props.changeStateValue({name:'adminExamModalData.roboType', value:true})}} className='h-[35px] pl-[7px] pr-[7px] text-[14px] font-semibold bg-blue text-white rounded-[6px]'>Add a Parent using robo</button>
            </div>
            <div className='bg-gray-100 p-[10px] mt-[20px] rounded-[10px] flex flex-col gap-[10px]'>
                {
                    adminExamQuestions.map((data, i)=>{
                        return(
                            <ExamParts 
                                key={i}
                                data={data}
                                parentRow={i}
                            />
                        )
                    })
                }
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    adminExamQuestions: state.Data.adminExamQuestions
});
const mapDispatchToProps = {changeStateValue, getExamQuestions, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamParents))
