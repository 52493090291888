import React, { Component } from 'react'

export class GenEngCont extends Component {
  render() {
    const {essay="", speaking="", gExam="", gHw=""} = this.props
    return (
        <div className='mt-[20px] bg-gray-100 pt-[4px] pb-[4px] rounded-[6px]'>
            <span className='text-[14px] ml-[20px] txt-blue'>General English</span>
            <ul className='flex justify-between '>
            <li className='w-1/4 flex flex-col items-center p-[0px] border-r'>
                <span className='text-[#64748B] text-[14px] font-[300]'>{essay}/4</span>
                <span className='text-[10px] txt-blue font-semibold'>Essay</span>
            </li>
            <li className='w-1/4 flex flex-col items-center p-[0px] border-r'>
                <span className='text-[#64748B] text-[14px] font-[300]'>{speaking}/4</span>
                <span  className='text-[10px] txt-blue font-semibold'>Sp.</span>
            </li>
            <li className='w-1/4 flex flex-col items-center p-[0px] border-r'>
                <span className='text-[#64748B] text-[14px] font-[300]'>{gExam}/4</span>
                <span  className='text-[10px] txt-blue font-semibold'>Exam</span>
            </li>
            <li className='w-1/4 flex flex-col items-center p-[0px]'>
                <span className='text-[#64748B] text-[14px] font-[300]'>{gHw}/8</span>
                <span  className='text-[10px] txt-blue font-semibold'>PT</span>
            </li>
            </ul>
        </div>
    )
  }
}

export default GenEngCont
