import React, { Component } from 'react'

export class StartExamCircleSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={146}
        height={147}
        fill="none"
      >
        <path
          fill="url(#a)"
          d="M0 73.5C0 33.183 32.683 0 73 0s73 33.183 73 73.5S113.317 147 73 147 0 113.817 0 73.5Z"
        />
        <defs>
          <linearGradient
            id="a"
            x1={0}
            x2={146}
            y1={64.137}
            y2={64.582}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FED433" />
            <stop offset={1} stopColor="#FF8D38" />
          </linearGradient>
        </defs>
      </svg>
    )
  }
}

export default StartExamCircleSvg
