import React, { Component } from 'react'

export class CardPdfSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={15}
        height={15}
        viewBox="0 0 512 512"
        fill={"var(--blue)"}
      >
        <path d="M499.677 426.489a8.017 8.017 0 0 0 8.017-8.017V84.977c0-2.1-.862-4.183-2.347-5.668l-76.96-76.96A8.113 8.113 0 0 0 422.716 0H106.324C92.473 0 81.205 11.268 81.205 25.119v9.086H12.261c-6.987 0-10.616 8.738-5.668 13.685l62.74 62.741-62.74 62.741c-4.947 4.947-1.32 13.685 5.668 13.685h68.944v299.825c0 13.851 11.268 25.119 25.119 25.119h376.251c13.851 0 25.119-11.268 25.119-25.119v-34.205a8.017 8.017 0 0 0-8.017-8.017 8.017 8.017 0 0 0-8.017 8.017v34.205c0 5.01-4.076 9.086-9.086 9.086h-376.25c-5.01 0-9.086-4.076-9.086-9.086V187.056h51.841a8.017 8.017 0 0 0 0-16.034H31.615l54.724-54.724a8.016 8.016 0 0 0 0-11.337L31.615 50.238h348.88v120.785H183.284a8.017 8.017 0 0 0 0 16.034h205.228a8.017 8.017 0 0 0 8.017-8.017V42.221a8.017 8.017 0 0 0-8.017-8.017H97.238v-9.086c0-5.01 4.076-9.086 9.086-9.086H414.7v51.841c0 13.851 11.268 25.119 25.119 25.119h51.841V418.47a8.016 8.016 0 0 0 8.017 8.019zM439.819 76.96c-5.01 0-9.086-4.076-9.086-9.086V27.37l49.589 49.59h-40.503z" />
        <path d="M208.938 76.96h-34.205a8.017 8.017 0 0 0-8.017 8.017v51.307a8.017 8.017 0 0 0 16.034 0v-17.655c.177.012.355.018.534.018h25.653a8.017 8.017 0 0 0 8.017-8.017V84.977a8.017 8.017 0 0 0-8.016-8.017zm-8.017 25.654h-17.637c-.18 0-.357.005-.534.018v-9.638h18.171v9.62zM251.693 76.96h-17.102a8.017 8.017 0 0 0-8.017 8.017v51.307a8.017 8.017 0 0 0 8.017 8.017h34.205a8.017 8.017 0 0 0 8.017-8.017v-34.205c-.001-13.85-11.269-25.119-25.12-25.119zm9.086 51.307h-18.171V92.994h9.086c5.01 0 9.086 4.076 9.086 9.086v26.187zM328.654 76.96h-34.205a8.017 8.017 0 0 0-8.017 8.017v51.307a8.017 8.017 0 0 0 8.017 8.017 8.017 8.017 0 0 0 8.017-8.017v-17.637h17.637c4.428 0 8.017-3.589 8.017-8.017s-3.588-8.017-8.017-8.017h-17.637v-9.62h26.188a8.017 8.017 0 1 0 0-16.033zM414.165 213.779H174.733c-9.136 0-16.568 7.432-16.568 16.568v222.33c0 9.136 7.432 16.568 16.568 16.568h239.432c9.136 0 16.568-7.432 16.568-16.568v-222.33c0-9.136-7.432-16.568-16.568-16.568zm.535 238.897c0 .295-.24.534-.534.534H174.733a.534.534 0 0 1-.534-.534v-222.33c0-.295.239-.534.534-.534h239.432c.294 0 .534.239.534.534v222.33z" />
        <path d="M388.512 316.393H303a8.017 8.017 0 0 0-8.017 8.017v102.614a8.017 8.017 0 0 0 8.017 8.017h85.512a8.017 8.017 0 0 0 8.017-8.017V324.409a8.017 8.017 0 0 0-8.017-8.016zm-8.017 102.613h-69.478v-86.58h69.478v86.58zM397.063 247.983H303c-4.428 0-8.017 3.589-8.017 8.017s3.588 8.017 8.017 8.017h94.063c4.428 0 8.017-3.589 8.017-8.017s-3.589-8.017-8.017-8.017zM397.063 282.188H303c-4.428 0-8.017 3.589-8.017 8.017s3.588 8.017 8.017 8.017h94.063c4.428 0 8.017-3.589 8.017-8.017s-3.589-8.017-8.017-8.017zM277.347 247.983h-85.511a8.017 8.017 0 0 0 0 16.034h85.511c4.428 0 8.017-3.589 8.017-8.017s-3.589-8.017-8.017-8.017zM277.347 282.188h-85.511a8.017 8.017 0 0 0 0 16.034h85.511c4.428 0 8.017-3.589 8.017-8.017s-3.589-8.017-8.017-8.017zM277.347 316.393h-85.511a8.017 8.017 0 0 0 0 16.034h85.511c4.428 0 8.017-3.589 8.017-8.017s-3.589-8.017-8.017-8.017zM277.347 350.597h-85.511a8.017 8.017 0 0 0 0 16.034h85.511c4.428 0 8.017-3.589 8.017-8.017s-3.589-8.017-8.017-8.017zM277.347 384.802h-85.511a8.017 8.017 0 0 0 0 16.034h85.511a8.017 8.017 0 0 0 0-16.034zM277.347 419.006h-85.511a8.017 8.017 0 0 0 0 16.034h85.511a8.018 8.018 0 0 0 0-16.034z" />
      </svg>
    )
  }
}

export default CardPdfSvg
