import React, { Component } from 'react'

export class CourseEnrollSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={35}
        height={35}
        viewBox="0 0 24 24"
      >
        <path
          fill="var(--blue)"
          d="M7.857 10H2.142C.962 10 0 9.04 0 7.857V2.143C0 .96.96 0 2.142 0h5.715C9.04 0 10 .96 10 2.143v5.714C10 9.04 9.04 10 7.857 10zM2.142 2A.146.146 0 0 0 2 2.143v5.714c0 .076.066.143.142.143h5.715A.147.147 0 0 0 8 7.857V2.143A.147.147 0 0 0 7.857 2H2.142zm5.715 22H2.142C.962 24 0 23.04 0 21.857v-5.715C0 14.962.96 14 2.142 14h5.715C9.04 14 10 14.96 10 16.143v5.715C10 23.038 9.04 24 7.857 24zm-5.715-8a.145.145 0 0 0-.142.143v5.715c0 .076.066.142.142.142h5.715A.146.146 0 0 0 8 21.857v-5.715A.146.146 0 0 0 7.857 16H2.142zm19.715 8h-5.715A2.143 2.143 0 0 1 14 21.857v-5.715C14 14.96 14.96 14 16.142 14h5.715C23.04 14 24 14.96 24 16.14v5.715A2.146 2.146 0 0 1 21.856 24zm-5.715-8a.146.146 0 0 0-.143.143v5.715c0 .076.065.143.142.143h5.715a.145.145 0 0 0 .143-.142v-5.715a.146.146 0 0 0-.143-.143h-5.715zm1.676-3.636c-.55 0-1.098-.208-1.516-.626l-4.04-4.04a2.142 2.142 0 0 1 0-3.03L16.3.625c.808-.808 2.225-.807 3.03 0l4.04 4.04a2.142 2.142 0 0 1 0 3.03l-4.04 4.04c-.418.42-.967.628-1.514.628zm0-10.364a.145.145 0 0 0-.102.04l-4.04 4.04a.145.145 0 0 0 0 .203l4.04 4.04a.143.143 0 0 0 .202 0l4.04-4.04a.144.144 0 0 0 0-.202l-4.04-4.04a.141.141 0 0 0-.1-.04z"
        />
      </svg>
    )
  }
}

export default CourseEnrollSvg
