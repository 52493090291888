import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getAdminPriceCalculator, getLessonModes } from '../../actions/StaffAction';
import DropSearch from '../../components/DropSearch';
import { getDropValue } from '../../actions/getRole';
import Input from '../../components/modals/components/Input';
import { mixinAlert } from '../../actions/SweetAlerts';
import DefaulData from '../../components/modals/components/DefaulData';

export class PriceCalculator extends Component {
    state={
        lesson_mode_id:'',
        study_mode:'',
        fee:'',
        count:''
    }
    componentDidMount(){
        this.props.getLessonModes();
        this.props.getAdminPriceCalculator()
    }

    selectLessonMode=(id)=>{
        this.setState({lesson_mode_id:id})
        this.calculateBtn(id, this.state.study_mode, this.props.adminPriceCalculator, this.state.count)
    }
    selectStudyMode=(id)=>{
        this.setState({study_mode:id})
        this.calculateBtn(this.state.lesson_mode_id, id, this.props.adminPriceCalculator, this.state.count)
    }

    calculateBtn=(lesson_mode_id, study_mode, adminPriceCalculator, count)=>{
        if(lesson_mode_id !="" && study_mode !="" && count !=""){
            let check = adminPriceCalculator.find(x=>x?.lesson_mode_id == lesson_mode_id && x?.study_mode == study_mode)
            if(check){
                this.setState({fee: Math.round((check?.fee*count)/12)})
            }else{
                this.setState({fee:""})
            }
        }
        
    }

    onSelectWeekDay=(data)=>{
        this.setState({count: 12*data})
        this.calculateBtn(this.state.lesson_mode_id, this.state.study_mode, this.props.adminPriceCalculator, 12*data)
    }
  render() {
    const {lessonModes, adminPriceCalculator, lang} = this.props;
    const {lesson_mode_id, study_mode, fee, count} = this.state;
    let studyMode =[
        {
            value: 'One to One',
            label:'One to One'
        },
        {
            value: 'Group Two',
            label: 'Group Two'
        },
        {
            value: 'Group Three',
            label: 'Group Three'
        },
      
        
      ];
      let lessonModeOption = [];
      for(const data of lessonModes){
          if(data?.id !=23 && data?.id != 24 && data.courses.length !==0){
              lessonModeOption.push({
                  label: data.title,
                  value: data.id
              })
          }
          
      }
      let weekDay = [
        {
          label: "3 times a week",
          value: 1,
        },
        {
          label: "6 times a week",
          value: 2,
        },
      ]
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Price calcuator" />
            <div className='bg-white p-[20px] mt-[20px] rounded-[10px] shadow '>
                <div className='mt-[20px] flex items-center gap-[20px]'>
                    <button onClick={()=>window.location.reload()} className='bg-orange w-[120px] h-[30px] inline-flex items-center justify-center text-[14px] text-white rounded-[5px]'>
                        Reset
                    </button>
                </div>
                <div className=' grid grid-cols-2 gap-[20px] mt-[20px]'>
                    <DropSearch selectData={this.selectLessonMode.bind(this)} options={lessonModeOption} title="Lesson mode" />
                    <DropSearch selectData={this.selectStudyMode.bind(this)} options={studyMode} title="Study mode" />
                    <DropSearch selectData={this.onSelectWeekDay.bind(this)} options={weekDay} title="How often a week" />
                    {
                        count !=""?
                         <DefaulData title={lang?.num_lessons} value={count} />:null
                    }
                   
                   <div>
                  
                   </div>
                  
                    
                </div>
                <div className='mt-[20px]'>
                  {fee !==""? <h3 className='font-semibold txt-blue text-[17px]'>  Available price: {fee} </h3>:null}
                </div>
              
            </div>
        </div>
       
    )
  }
}
const mapStateToProps = (state) =>({
    lessonModes: state.Data.lessonModes,
    adminPriceCalculator: state.Data.adminPriceCalculator,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getLessonModes, getAdminPriceCalculator}
export default connect(mapStateToProps, mapDispatchToProps)(PriceCalculator)
