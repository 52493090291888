import React, { Component } from 'react'
import DefaulData from '../../../../components/modals/components/DefaulData';
import DropSearch from '../../../../components/DropSearch';
import { getGroupsByTeacher, getTeachers } from '../../../../actions/StaffAction';
import { connect } from 'react-redux';
import { getDropValue } from '../../../../actions/getRole';
import { changeStateValue, resetStateValue } from '../../../../redux/MainReducer';
import { mixinAlert } from '../../../../actions/SweetAlerts';
import Header from '../../../../components/modals/Header';

export class TeacherSelect extends Component {

    componentDidMount(){
        if(this.props.user != ""){
            this.props.getTeachers(100, "", this.props.user?.company_id, "users")
        }
    }
    onSelectTeacher=(id)=>{
        this.props.getGroupsByTeacher(id)
    }
    changePage=()=>{
        if(this.props.studentCalculatorModal?.teacher_id !=""){
             this.props.changeStateValue({
                name:"studentCalculatorModal.page",
                value:"group"
            })
        }else{
            mixinAlert("warning", "Please select a teacher")
        }
    }

    closeModal=()=>{
        this.props.resetStateValue({name:"studentCalculatorModal"});
        this.props.onClose();
    }
  render() {
    const {student, teachers, studentCalculatorModal} = this.props;
    // console.log(teachers)
    return (
        <div className='bg-white w-[700px] shadow rounded-[10px] max-[730px]:ml-[20px] max-[730px]:mr-[20px]'>
            <Header title="Select a teacher" closeBtn={()=>this.props.onClose()} />
            <div className='p-[20px]'>
                {/* <h2 className='text-center txt-blue font-semibold text-[24px]'>Select a teacher</h2> */}
                <div className='grid grid-cols-2 gap-[20px] mt-[20px]'>
                    <DefaulData title="Student" value={student?.full_name} />
                    <DropSearch selectData={this.onSelectTeacher.bind(this)} required value={studentCalculatorModal.teacher_id} placeholder="Teacher" name="studentCalculatorModal.teacher_id"  options={getDropValue(teachers, "full_name", "id")}  title="Teacher" /> 
                </div>
                <div className='flex mt-[20px] justify-between'>
                    <button onClick={this.closeModal.bind(this)} className="h-[25px] bg-red p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                    Close
                    </button>
                    <button onClick={this.changePage.bind(this)} className="h-[25px] bg-blue p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                        Save & next
                    </button>
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    teachers: state.Data.teachers,
    user: state.Data.user,
    studentCalculatorModal: state.Data.studentCalculatorModal,

});
const mapDispatchToProps = {getTeachers, changeStateValue, getGroupsByTeacher, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherSelect)
