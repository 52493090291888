import React, { Component } from 'react'
import { changeStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';
import axios from 'axios';
import { MAIN_API } from '../MAIN_API';
import { generate2FAAuth } from '../actions/TwoFAActions';
import { checkUser, insertDataWithParams, insertNewData } from '../actions/MainAction';
import { withHooks } from '../actions/withHooks';
import { mixinAlert } from '../actions/SweetAlerts';

export class Register2FA extends Component {
    state={
        secret:'',
        qrCode:'',
        otp:'',
        btnDisable:false
    }
    componentDidMount(){
      try{
        this.props.generate2FAAuth()
        .then(resp=>{
       try{
        this.setState({
            qrCode: atob(resp?.qrCodeImage),
            secret: resp?.secret
        })
       }catch(err){

       }
        })
      }catch(err){
        this.props.navigate("/")
      }
    }

    verifyOTP=(secret,otp)=>{
        if(secret?.trim() !="" && otp?.trim() !==""){
            this.setState({btnDisable:true})
            this.props.insertDataWithParams('register-2fa', {secret, token: otp})
            .then(resp=>{
                this.setState({btnDisable:false})
                if(resp?.data?.success){
                    this.props.checkUser()
                    .then(respUser=>{
                        this.props.navigate("/")
                    })
                }else{
                    mixinAlert("error", "Invalid OTP")
                }
                // console.log(resp)
            })
        }
    }
  render() {
    const {qrCode, secret, otp, btnDisable} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px] pt-[100px]'>
            <div className='h-screen flex justify-center items-center'>
                <div className='bg-gray-100 p-[10px] w-[500px] rounded-[10px] border '>
                    <h3 className='text-center text-[22px] font-[600] txt-blue'>SCAN QR CODE TO REGISTER 2FA</h3>

                    <div className='flex justify-center mt-[30px]'>
                        <div dangerouslySetInnerHTML={{__html: qrCode}}></div>
                    </div>
                    <div className='flex flex-col mt-[30px]'>
                        <label  className='text-[14px] font-[500]'>Enter 6 digit Auth code to verify</label>
                        <input value={otp} onChange={(e)=>this.setState({otp: e.target.value})} className='h-[40px] rounded-[6px] outline-none pl-[5px]' placeholder='6 digit Auth Code' type="number" />
                    </div>
                    <div className='mt-[20px] flex justify-center'>
                        <button disabled={btnDisable} onClick={this.verifyOTP.bind(this, secret,otp)} className='bg-blue p-[5px_10px] text-white text-[14px] font-[500] rounded-[6px]'>
                            Verify
                        </button>
                    </div>
                    <div className='mt-[20px]'>
                        <p className='text-center'>Install Google Authenticator for <br /> <a target="_blank" className='text-blue-600' href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Android</a>  or <a target="_blank" className='text-blue-600' href="https://apps.apple.com/az/app/google-authenticator/id388497605">IOS</a> </p>
                    </div>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {changeStateValue, generate2FAAuth, insertDataWithParams, checkUser}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Register2FA))
