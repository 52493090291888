import React, { Component } from 'react'
import Header from '../../../components/modals/Header'
import { connect } from 'react-redux';
import { getTeachers } from '../../../actions/StaffAction';
import { getRole } from '../../../actions/getRole';
import Card4 from '../../../components/cards/Card4';
import LoadMoreBtn from '../../../components/LoadMoreBtn';
import TeacherAssignModal from './TeacherAssignModal';
import Search from '../../../components/Search';
export class TeacherEnrollPage extends Component {
  state={
    page:1,
    teacherId:this.props.teacherId?this.props.teacherId:'',
    search:''
  }
  componentDidMount(){
    this.props.getTeachers(1, '', this.props.user?.company_id);  
  }
  loadMore=(page)=>{
    this.props.getTeachers(page, this.state.search, this.props.user?.company_id);  
  }
  openModal=(data, e)=>{
    e.preventDefault();
    this.setState({teacherId: data?.id})
  }
  searchData=(search)=>{
    this.setState({search:search});
    this.props.getTeachers(1, search, this.props.user?.company_id);  
  }
  render() {
    const {teachers, lang} = this.props;
    const {teacherId} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center pl-[20px] pr-[20px]'>
        <div className='bg-white  max-h-[70vh] w-[966px] overflow-auto rounded-[10px] mt-[30px]'>
            <Header closeBtn={()=>this.props.closeModal()} title={lang?.teacher_enroll} />
           <div className='flex justify-center'>
           <Search onSearchData={this.searchData.bind(this)} />
           </div>
            <div className='p-[20px] '>
              <div className='grid-full-205 mt-[20px]'>
                {
                  teachers?.map((data, i)=>{
                    if(data?.role !== "speaking_teacher"){
                      return(
                        <Card4
                          key={i}
                          name={data?.full_name}
                          // email={data?.email}
                          // phone={data?.phone_number}
                          editBtnPermisiion={false}
                          image={data?.avatar_full_url}
                          role={getRole(data?.role, this.props.lang)}
                          lock={1}
                          info={` `}
                          onClickInfo={this.openModal.bind(this, data)}
                          shadow={true}
                        />
                      )
                    }
                  })
                }
              </div>
              {
                teachers?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={teachers?.length} />:null
            }
            </div>
        </div>
        {
          teacherId !== ""?
          <TeacherAssignModal student={this.props.student} closeAllModal={()=>this.props.closeModal()} closeBtn={()=>{this.setState({teacherId:''});}} teacherId={teacherId} />:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  teachers: state.Data.teachers,
  user: state.Data.user,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getTeachers}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherEnrollPage)
