import React, { Component } from 'react'
import Header from '../../../../components/modals/Header'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
import Input from '../../../../components/modals/components/Input';
import TextEditor from '../../../../components/TextEditor';
import FileInput from '../../../../components/FileInput';
import AnswerBox from './AnswerBox';
import AddQuestionBtn from './AddQuestionBtn';
import FooterBtn from './FooterBtn';
import ImgAnswerBox from './ImgAnswerBox';
import ExamCheckBtn from './ExamCheckBtn';
export class QuestionModal extends Component {
    
    closeModal=()=>{
        this.props.changeStateValue({
            name: 'adminExamModalData',
            value: {
                position: false,
                id: '',
                title: '',
                audio_file: null,
                description: '<p></p>',
                question_image: null,
                question_description: '',
                score: '',
                sub_type: '',
                type: '',
                question_option: '[]',
                video_file: null,
                video_link: null,
                exam_id: '',
                questionPositon: false
            }
        })
    }
  render() {
    const {adminExamModalData} = this.props;
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[900px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={adminExamModalData?.id ===""?"Add Question":"Edit Question"} />
                <div className='p-[10px] rounded-b-[10px] bg-white max-h-[70vh] overflow-auto'>
                    <Input title="Title" required value={adminExamModalData?.title} name="adminExamModalData.title" />
                    <div className='mt-[20px]'>
                        <TextEditor name="adminExamModalData.description" value={adminExamModalData.description} title="Description" />
                    </div>
                    <div className='grid-full-250 mt-[20px]'>
                        <Input  title="Score" required value={adminExamModalData?.score} name="adminExamModalData.score" />
                    </div>
                    <div className='mt-[20px] grid-full-250'>
                        {
                            adminExamModalData.type == 'single_audio_type'  ||  adminExamModalData.type == 'single_audio_type_with_image' ||   adminExamModalData.type == 'single_audio_with_image'?
                            <FileInput title="Audio File" type="audio" name="adminExamModalData.audio_file" valueName="adminExamModalData.audio_full_url" value={adminExamModalData.audio_full_url} />:null
                        }
                    </div>
                    <div className='mt-[20px] grid-full-250'>
                        {
                            adminExamModalData.type == 'single_image_type' ||  adminExamModalData.type == 'single_audio_type_with_image' ?
                            <FileInput title="Image File" type="image" name="adminExamModalData.question_image" valueName="adminExamModalData.image_full_url" value={adminExamModalData.image_full_url} />:null
                        }
                    </div>
                   <ExamCheckBtn data={adminExamModalData} />
                    <div className='grid-full-250 mt-[20px]' >
                        {
                            adminExamModalData.type != 'free_text' && adminExamModalData.type != 'single_image_choice' && adminExamModalData.type != 'single_audio_with_image'?
                            adminExamModalData?.question_option.map((answer, i)=>{
                                return(
                                    <AnswerBox key={i} allData={adminExamModalData?.question_option}  correct={answer.check} row={i} data={answer} />
                                )
                            }):
                            adminExamModalData.type == 'single_image_choice' || adminExamModalData.type == 'single_audio_with_image'?
                            adminExamModalData?.question_option.map((answer, i)=>{
                                return(
                                    <ImgAnswerBox key={i} allData={adminExamModalData?.question_option} correct={answer.check} row={i} data={answer} />
                                )
                            }):null
                        }
                    </div>
                    <AddQuestionBtn />
                    <FooterBtn />
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminExamModalData: state.Data.adminExamModalData
});
const mapDispatchToProps = {changeStateValue, }
export default connect(mapStateToProps, mapDispatchToProps)(QuestionModal)
