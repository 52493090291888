import React, { Component } from 'react'
import Body from './Body';
import Footer from './Footer';
import { connect } from 'react-redux';

export class Parent extends Component {
  state={
    bodyVisible: false,
    position: ''
  }

  stopAudioVideo=(e)=>{
    if(!this.props.openPosition){
      // e.target.scrollIntoViewIfNeeded();
      // const main = document.querySelector("main");
      // if(main){
      //   main.scrollTo( 0, 200)
      // }
    }
    const video = document.querySelectorAll('video');
    const audio = document.querySelectorAll('audio');
    if(video){
        video.forEach(vd=> {
            vd.pause()
        })
    }

    if(audio){
        audio.forEach(ad=>{
            ad.pause()
        })
    }
  }

 
  render() {
    const {status, data,index, parentIndex, type="hw", openPosition, onClickOpen=()=>{}, lang} = this.props;
   
    return (
      <div className='bg-white pt-[30px] pl-[57px] pr-[57px] pb-[30px] rounded-[12px] exam_parent'>
        <div onClick={(e)=>{onClickOpen(openPosition?111:index); this.stopAudioVideo(e)}} className='flex justify-between cursor-pointer exam_parent_header'>
            <h4 className='txt-light-black text-[18px] font-bold'>{data?.title}</h4>
            {
                status === 'Answered'?
                <span className='bg-blue w-[125px] h-[25px] inline-flex justify-center items-center rounded-[3px] text-white text-[10px] font-bold'>{lang?.all_answered}</span>:
                status === 'Unanswered'?
                <span className='bg-red w-[125px] h-[25px] inline-flex justify-center items-center rounded-[3px] text-white text-[10px] font-bold'>{lang?.unanswered}</span>:
                <span className='border border-[var(--blue)] w-[125px] h-[25px] inline-flex justify-center items-center rounded-[3px] txt-blue text-[10px] font-bold'>{lang?.part_answered}</span>
            }
            
        </div> 
        {
          openPosition?
          <>
            <Body parentIndex={parentIndex} data={data} />
            <Footer type={type} data={data} closeBody={()=>onClickOpen(111)} />
          </>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(Parent)
