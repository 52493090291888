import { SOCKET_API } from "../MAIN_API";
import io from 'socket.io-client';
const socket = io(SOCKET_API, {
    transports: ['websocket', 'polling']
});
socket.on('connect', () => {
    console.log('connected to serverd', socket.id);
});

// // socket.on('disconnect', () => {
// //     console.log('disconnected from server');
// //   });
  
// // socket.on('connect_error', (error) => {
// //     console.error('Connection error:', error);
// // });
export default socket;
