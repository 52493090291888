import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getSpeakings } from '../../actions/AdminActions';
import Card1 from '../../components/cards/Card1';
import { getType } from '../../actions/getRole';
import HeaderButtons from '../../components/HeaderButtons';
import { changeStateValue } from '../../redux/MainReducer';
import { getLessons } from '../../actions/MainAction';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Search from '../../components/Search';
import EmptyPage from '../../components/EmptyPage';
import { insertNewData, deleteData } from '../../actions/MainAction';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
export class AdminSpeakings extends Component {
    state={
        page:1,
        search:'',
        active_status:'',
        activePage:0
    }
    componentDidMount(){
        this.props.getSpeakings(1, '', '');
        window.scrollTo(0,0)
    }
    spType(){
        const spType = [
            {
                label: 'Unit Speaking',
                value: 'unit'
            },
            {
                label: 'Mid and End of course Speaking',
                value: 'midterm_end_course'
            }

        ];
        return spType
    }
    openModal=(data="")=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                course_id:data?.course_id? data?.course_id:'',
                speaking_type:data?.speaking_type? data?.speaking_type:'',
                lesson_id:data?.lesson_id? data?.lesson_id:'',
                question:data?.question? data?.question:'',
                title:data?.title? data?.title:'',

                afterCallFunction:this.props.getSpeakings,
                afterCallParams:[this.state.page, this.state.search],
                typeOptions: this.spType(),
                requireds:["course_id", "speaking_type", "lesson_id", "title", "question"],
                url: data?.id? `speakings/${data?.id}`:'speakings',
                modalTitle: data?.id? `Edit Speaking`:'Add Speaking',
            }
        });
        if(data?.course_id){
            this.props.getLessons(data?.course_id)
        }
    }

    loadMore=(page)=>{
        this.setState({page:page});
        this.props.getSpeakings(page, this.state.search, this.state.active_status)
    }
    searchData=(search)=>{
        this.setState({search:search});
        this.props.getSpeakings(this.state.page, search, this.state.active_status)
    }
    changeStatus=(data, status)=>{
        confirmAlert("warning", "Are you sure?", "Status will change", "Yes, change it")
        .then(alertResp=>{
          if(alertResp === "success"){
            let formData = new FormData();
            formData.append("active_status", status==1?'0':'1');
            formData.append("_method", "put");
            this.props.insertNewData(`speaking-active/${data?.id}`, formData)
            .then(resp=>{
              if(resp=="success"){
                mixinAlert("success", "Status change successfully")
                this.props.getSpeakings(this.state.page, this.state.search, this.state.active_status)
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }
        })
      }
    onDeleteData=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`speakings/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getSpeakings(this.state.page, this.state.search, this.state.active_status)
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }

    getDataByStatus=(data, index)=>{
        this.setState({activePage:index, active_status:data?.id});
        this.props.getSpeakings( this.state.page, this.state.search,data?.id);
    }
  render() {
    const {adminSpeakings, user} = this.props;
    const {activePage} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Speakings" />
            <div className='mt-[30px] flex justify-between items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {
                    user?.role === "super_admin"?
                    <HeaderButtons onClick={(data, index)=>this.getDataByStatus(data, index)} buttons={[{title: "All", id:''},{title: "Inactives", id:'0'}]} selected={activePage} />:<div></div>
                }
                <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Speaking", id:0}]} selected={0} />
            </div>
            <div className='flex justify-center'>
                <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
            </div>
            {adminSpeakings.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No results found" subTitle="" /></div>:null}
            <div className='mt-[30px] grid-full-250'>
                {
                    adminSpeakings.map((data, i)=>{
                        return(
                            <Card1 
                                key={i}
                                title={data?.title}
                                cheating={data?.course?.title}
                                status={1}
                                statusBisible={false}
                                close={getType(data?.speaking_type)}
                                btn={false}
                                btn2={true}
                                btn2Text={"Edit"}
                                btn2Color={"bg-gray-400"}
                                onCLickBtn={this.openModal.bind(this, data)}
                                activeStatus={data?.active_status}
                                adminActiveVisible={user?.role === "super_admin"}
                                onClickChangeStatus={this.changeStatus.bind(this, data)}
                                adminDelete={user?.role === "super_admin"}
                                onDeleteBtn={this.onDeleteData.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
            {
                adminSpeakings?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={adminSpeakings?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminSpeakings: state.Data.adminSpeakings,
    user: state.Data.user,
});
const mapDispatchToProps = {getSpeakings, changeStateValue, getLessons,  insertNewData, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSpeakings)




