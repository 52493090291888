import React, { Component } from 'react'

export class MessageSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={19}
        fill="none"
      >
        <path
          fill="#A3AED0"
          d="M0 2.867a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-4.586l-2.707 2.707a1 1 0 0 1-1.414 0l-2.707-2.707H2a2 2 0 0 1-2-2v-11Zm18 0H2v11h5a1 1 0 0 1 .707.293L10 16.453l2.293-2.293a1 1 0 0 1 .707-.293h5v-11Zm-14 3.5a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm0 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z"
        />
      </svg>
    )
  }
}

export default MessageSvg
