import React, { Component } from 'react'
import CardHorisontal from '../CardHorisontal'
import Top3 from '../../../images/rankings/top3.png';
import Top1 from '../../../images/rankings/top1.png';
import Top2 from '../../../images/rankings/top2.png';
import Top4 from '../../../images/rankings/top4.png';
import Top6 from '../../../images/rankings/top6.png';
import Top7 from '../../../images/rankings/top7.png';
import Top8 from '../../../images/rankings/top8.png';
import Top9 from '../../../images/rankings/top9.png';
import Top25 from '../../../images/rankings/top25.png';
import Top75 from '../../../images/rankings/top75.png';
import Top250 from '../../../images/rankings/top250.png';
import Top300 from '../../../images/rankings/top300.png';
import Top400 from '../../../images/rankings/top400.png';
import Top5 from '../../../images/rankings/top5.png';
import Top10 from '../../../images/rankings/top10.png';
import Top20 from '../../../images/rankings/top20.png';
import Top500More from '../../../images/rankings/top500+.png';
import Top50 from '../../../images/rankings/top50.png';
import Top100 from '../../../images/rankings/top100.png';
import Top200 from '../../../images/rankings/top200.png';
import Top500 from '../../../images/rankings/top500.png';
import rank1 from '../../../images/rankings/rank1.png';
import rank2 from '../../../images/rankings/rank2.png';
import rank3 from '../../../images/rankings/rank3.png';
import rank4 from '../../../images/rankings/rank4.png';
import { Link } from 'react-router-dom';
import GraduateSvg from '../svg/GraduateSvg';
import SchSvg from '../svg/SchSvg';
export class PublicCard1 extends Component {
  getSvg(item){
    if(item==1){
      return Top1
    }else if(item==2){
      return Top2
    }else if( item==3){
      return Top3
    }else if( item==4){
      return Top4
    }else if( item==5){
      return Top5
    }else if( item==6){
      return Top6
    }else if( item==7){
      return Top7
    }else if( item==8){
      return Top8
    }else if( item==9){
      return Top9
    }else if(item==10){
      return Top10 
    }else if( item<=20 &&  item>10){
      return Top20 
    }else if( item<=25 &&  item>20){
      return Top25 
    }else if( item<=50 &&  item>25){
      return Top50 
    }else if( item<=75 &&  item>50){
      return Top75
    }else if(item<=100 &&  item>75){
      return Top100 
    }else if(item<=200 &&  item>100){
      return Top200 
    }else if(item<=250 &&  item>200){
      return Top250 
    }else if(item<=300 &&  item>250){
      return Top300 
    }else if(item<=400 &&  item>300){
      return Top400 
    }else if(item<=500 &&  item>400){
      return Top500
    }else{
      return ""
    }
  }
  getFeeAmount(x){
    if(x){
      var parts = x.toString().split(".");
      parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
      return parts.join(",");
    }else{
      return ''
    }
}
  getRandImage(){
    let images = [rank1, rank2, rank3, rank4];
    let rand = Math.floor(Math.random()*4)
    return images[rand]
  }
  render() {
    const {data} = this.props;
    return (
      <div className='bg-white shadow rounded-[12px] relative'>
         <div className="absolute top-3  inset-x-3 flex justify-end">
          {/* {
            this.getSvg(data?.ranking) !==""?
            <img style={{ width: '50px' }} src={this.getSvg(data?.ranking)} />:null
          } */}
          {
            data?.ranking <=500?
            <div className='relative flex justify-center items-center'>
              <img style={{ width: '50px' }} src={this.getRandImage(data?.ranking)} />
              <span className='absolute text-[20px] text-white font-black mt-[9px] different-font'>{data?.ranking}</span>
            </div>:null
          }
            
          </div>
          {
              data?.scholarship !=="" && data?.scholarship?.amount?
                <div className='flex items-center gap-[10px] mt-[3px] absolute bg-blue ml-[10px] top-[28px] p-[2px_4px] rounded-[4px]'>
                {/* <SchSvg /> */}
                <span className='text-[#fff] text-[12px] font-bold '>{data?.scholarship?.track_id===4? "Full State Scholarship":data?.scholarship?.amount? this.getFeeAmount(data?.scholarship?.amount)+" "+data?.scholarship?.currency+" "+"Scholarship":""}</span>
              </div>: <div></div>
            }
        <img src={data?.inst_image?.image_full_url} alt={data?.inst_name} className='w-full h-[191px] rounded-t-[12px]' />
        <CardHorisontal />
        <div className='w-[71px] h-[71px] bg-white rounded-full flex items-center justify-center p-[4px] mt-[-92px] ml-[20px] relative'>
            <img src={data?.user_image} alt={data?.first_name} className='w-full h-full rounded-[50%]' />
        </div>
        <div className='mt-[10px] relative pl-[20px] pr-[20px] pb-[25px]'>
            <h3 className='txt-blue text-[18px] font-bold'>{data?.first_name} {data?.last_name?.substring(0, 1)}...</h3>
            <h4 className='text-[#2C2C2C] text-[14px] font-bold relative group z-10'>
              {
                data?.inst_name?.length<=35?
                data?.inst_name:
                `${data?.inst_name?.substring(0,35)}...`
              }
              {
                 data?.inst_name?.length>35?
                 <span className='absolute bg-white shadow left-0 p-[2px] rounded-[4px] hidden group-hover:inline'>{data?.inst_name}</span>:null
              }
              
            </h4>
            <span className='text-[#595959] text-[12px] font-medium relative group inline-flex gap-[10px] mt-[2px]'>
              <GraduateSvg />
              {
                data?.program_name?.length<=35?
                data?.program_name:
                `${data?.program_name?.substring(0,35)}...`
              }
              {
                 data?.program_name?.length>35?
                 <span className='absolute bg-white shadow left-0 p-[2px] rounded-[4px] hidden group-hover:inline'>{data?.program_name}</span>:null
              }
              
            </span>
           
            <div className='flex items-center gap-[10px] mt-[-2px]'>
              <img className='w-[20px] h-[12px] rounded-[2px]' src={data?.country_image} alt={data?.country_name} />
              <span className='text-[#595959] text-[12px] font-medium '>{data?.country_name}</span>
            </div>
            
           
            <Link to={`/centers/${data?.company_id}`} className='text-blue-600 text-[12px] font-semibold'><span className='text-black inline-block mr-[1px]'>By: </span> {data?.company_name}</Link>
            
          
        </div>
      </div>
    )
  }
}

export default PublicCard1
