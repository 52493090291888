import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import Search from '../../components/Search'
import { connect } from 'react-redux'
import { getStaff, getCompanies } from '../../actions/AdminActions'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import Card4 from '../../components/cards/Card4'
import { getRole } from '../../actions/getRole'
import { changeStateValue } from '../../redux/MainReducer'
import { bigAlert, confirmAlert } from '../../actions/SweetAlerts'
import { deleteData } from '../../actions/MainAction'
export class AdminStaff extends Component {
    state={
        page:1,
        search:''
    }
    componentDidMount(){
        this.props.getStaff(1, '');
        this.props.getCompanies(10, '')
    }
    searchData=(search)=>{
        this.setState({search});
        this.props.getStaff(this.state.page, search)
    }
    loadMore=(page)=>{
        this.setState({page});
        this.props.getStaff(page, this.state.search)
    }
    getAvailableCompany(companies, company_id=""){
        let newData = [];
        for(const data of companies){
            if(!data?.user || company_id!=="" &&data?.id==company_id){
               newData.push({
                    label: data?.name,
                    value: data?.id
                }) 
            }
            
        }
        return newData;
    }
    getRolesData(){
        const roles = [
            {
                 value: 'auditor',
                 label: 'Auditor'
            },
            {
                 value: 'content_manager',
                 label: 'Content Manager'
             },
             {
                 value: 'teacher_manager',
                 label: 'Teacher Manager'
             },
             {
                 value: 'accountant',
                 label: 'Accountant'
             },
             {
                 value: 'content_master',
                 label: 'Content Master'
             },
             {
                 value: 'support',
                 label: 'Support'
             },
       ];
       return roles;
    }
    addBtn=(data)=>{
        this.props.changeStateValue({
            name: "adminModalData",
            value:{
                position: true,
                id: data?.id?data?.id:"",
                first_name: data?.first_name? data?.first_name:"",
                last_name: data?.last_name? data?.last_name:"",
                email: data?.email? data?.email:"",
                phone_number: data?.phone_number? data?.phone_number:"",
                date_of_birth: data?.date_of_birth? data?.date_of_birth:"",
                password: data?.password? data?.password:"",
                role: data?.role? data?.role:"",
                modalTitle: data?.id?"Edit Staff":"Add Staff",
                requireds: ["first_name",  "last_name",  "email", "phone_number", "role"],
                afterCallFunction: this.props.getStaff,
                afterCallParams: [this.state.page, this.state.search],
                company_id:data?.company_id? data?.company_id:"",
                roleOption: this.getRolesData(),
                url: data?.id?`users/${data?.id}`:"users",
                errorMsg: "Email already exists"
            }
        })
    }
    deleteUser=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(alertResp=>{
            if(alertResp === "success"){
                this.props.deleteData(`users/${id}`)
                .then(resp=>{
                    if(resp==="success"){
                        bigAlert("Success!", "Manager Deleted successfully!", "success");
                        this.props.getStaff(this.state.page, this.state.search);  
                    }else{
                        bigAlert("Error!", "Something went wrong!", "error")
                    }
                })
            }
        })
    }
  render() {
    const {adminStaff} = this.props;
 
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Staff" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Add a staff", id:'0'}]} selected={0} />
            </div>
            <div className='flex justify-center'>
                <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
            </div>
            <div className='mt-[42px] grid-full-250'>
            {
                adminStaff?.map((data, i)=>{
                    return(
                        <Card4
                            key={i}
                            name={data?.full_name}
                            email={data?.email}
                            phone={data?.phone_number}
                            image={data?.avatar_full_url}
                            role={getRole(data?.role)}
                            headerBtn={true}
                            lockUnlock={false}
                            deleteBtn={true}
                            onClickEdit={this.addBtn.bind(this,data)}
                            onClickDelete={this.deleteUser.bind(this,data?.id)}
                        />
                    )
                })
            }
            </div>

            {
                adminStaff?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={adminStaff?.length} />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    adminStaff: state.Data.adminStaff,
    adminCompanies: state.Data.adminCompanies,
});
const mapDispatchToProps = {getStaff, changeStateValue, getCompanies, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminStaff)


