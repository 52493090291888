import React, { Component } from 'react'

export class PdfWhiteSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
      >
        <path
          fill="#fff"
          d="M4.822 8.563c-.107 0-.18.01-.217.021v.687c.045.01.1.014.177.014.279 0 .451-.141.451-.38 0-.213-.148-.342-.41-.342Zm2.035.007c-.117 0-.193.01-.238.021v1.523c.045.01.117.01.183.01.476.004.787-.259.787-.814.003-.484-.28-.74-.732-.74Z"
        />
        <path
          fill="#fff"
          d="M8.17 1.167H3.502a1.167 1.167 0 0 0-1.167 1.166v9.334a1.167 1.167 0 0 0 1.167 1.166h7a1.167 1.167 0 0 0 1.166-1.167v-7l-3.5-3.5ZM5.542 9.444c-.18.17-.446.245-.756.245-.06 0-.12-.003-.18-.01v.831h-.521V8.214c.235-.035.473-.05.711-.048.325 0 .556.062.712.187.148.117.248.31.248.538a.748.748 0 0 1-.214.553Zm2.22.79c-.244.204-.617.3-1.072.3-.273 0-.467-.017-.598-.034V8.215c.236-.034.473-.05.711-.049.442 0 .729.08.953.249.242.18.394.466.394.877 0 .445-.163.753-.387.942ZM9.92 8.616h-.893v.531h.835v.428h-.835v.936h-.529V8.184H9.92v.432ZM8.17 5.25h-.583V2.333l2.917 2.917H8.169Z"
        />
      </svg>
    )
  }
}

export default PdfWhiteSvg
