import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getTroubleMakers } from '../../actions/AdminActions';
import TroubleStudentCard from '../../components/TroubleStudentCard';

export class EndOfStudentsStats extends Component {
    componentDidMount(){
        this.props.getTroubleMakers()
    }
  render() {
    const {troubleStudents} = this.props;
    // console.log(troubleStudents)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Trouble Makers" />
            <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[180px] mt-[42px]'>
                <div className='w-full'>

                    {
                        troubleStudents.map((data, i) => {
                            return(
                               <TroubleStudentCard key={i} data={data} />
                            )
                        })
                    }

                    
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    troubleStudents: state.Data.troubleStudents
});
const mapDispatchToProps = {getTroubleMakers}
export default connect(mapStateToProps, mapDispatchToProps)(EndOfStudentsStats)
