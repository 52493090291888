import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../../actions/withHooks';
import { getStudentLogs } from '../../../../actions/StaffAction';
import Card1 from '../../../../components/cards/Card1';
import moment from 'moment';
export class Logs extends Component {
  state={
    logs:[]
  }
  componentDidMount(){
    this.props.getStudentLogs(this.props.params.id)
    .then(resp=>{
      if(resp != "error"){
        this.setState({logs:resp})
      }
    });
    window.scrollTo(0,0)
  }
  getLockDate(data){
    if(+data.attendance_lock_status === 1  || +data.manual_lock_status === 1){
        return moment(data.updated_at).format("DD/MM/YYYY")
    }else{
        return ''
    }
}
  render() {
    const {logs} = this.state;
    const {studentData, lang} = this.props;
    
    return (
      <div className='w-full'>
        <div className='mt-[42px] grid-full-250'>
          <Card1
              title={"Date"}
              date={`R: ${moment(studentData?.created_at).format("DD/MM/YYYY")}`}
              date2={`l: ${this.getLockDate(studentData)}`}
              statusBisible={false}
              btn={false}
          />
          {
            logs?.map((data, i)=>{
              if(data?.enroll_type === "course"){
                return(
                  <Card1
                    key={i}
                    title={data.type === 'add'? lang?.assign_course: lang?.remove_course}
                    date={`${moment(data?.created_at).format("DD/MM/YYYY")}`}
                    cheating={data?.course_title}
                    statusBisible={false}
                    btn={false}
                  />
                )
              }else if(data?.enroll_type === "teacher"){
                return(
                  <Card1
                    key={i}
                    title={data.type === 'add'? lang?.assign_teacher: lang?.remove_teacher}
                    name={data?.teacher_name}
                    date={`${moment(data?.created_at).format("DD/MM/YYYY")}`}
                    cheating={data?.lesson_mode}
                    close={data?.study_mode}
                    statusBisible={false}
                    course={data?.assign_course?.title}
                    btn={false}
                  />
                )
              }
              
            })
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
});
const mapDispatchToProps = {getStudentLogs}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Logs)) 
