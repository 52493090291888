import React, { Component } from 'react'
import { connect } from 'react-redux';

export class Essay extends Component {
  render() {
    const {essay, lang} = this.props;
    var parser = new DOMParser();
    var doc = parser.parseFromString(essay, 'text/html');
    return (
      <div className=' border-t mt-[20px]'>
        <div className='flex justify-between items-center mt-[15px]'>
            <h3 className='txt-blue font-semibold text-[17px]'>{lang?.essay}</h3>
            <span className='font-semibold text-[15px]'>{lang?.word_count}: {doc.children[0].children[1].textContent.match(/(\w+)/g)?doc.children[0].children[1].textContent.match(/(\w+)/g).length:null}</span>
        </div>
        <div className='mt-[10px] ' dangerouslySetInnerHTML={{ __html:essay }}></div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(Essay)
