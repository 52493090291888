import React, { Component } from 'react'
import Header from '../components/header/Header'
import img from "../images/profileBack.png"
import userImg from "../images/defaultUser.jpeg"
import { connect } from 'react-redux'
import { getRole } from '../actions/getRole'
import moment from 'moment'
import EditProfile from '../components/EditProfile'
export class SettingsPage extends Component {
    state={
        editVisible:false
    }
    componentDidMount(){
        window.scrollTo(0,0)
    }
  render() {
    const {user, lang} = this.props;
    const {editVisible} = this.state
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Profile" />
            <div className='bg-white mt-[40px] max-[1100px]:mt-[120px] max-[800px]:mt-[170px] rounded-t-[20px] rounded-b-[12px] pb-[30px]'>
                <div className='relative'>
                    <img src={img} alt="Image" className='w-full min-h-[140px] rounded-t-[12px]' />
                </div>
                <div className='w-[127px] h-[127px] rounded-full p-[5px] bg-white mt-[-44px] relative ml-auto mr-auto max-[600px]:ml-auto max-[600px]:mr-auto'>
                    <img src={user?.avatar_full_url? user?.avatar_full_url:userImg} className='w-full h-full rounded-full ' alt={user?.full_name} />
                </div>
                <div className='flex mt-[20px] justify-between ml-[22px] flex-col mr-[22px] items-center gap-[20px] max-[1300px]:items-start max-[600px]:flex-col max-[600px]:items-center max-[600px]:mr-[22px] max-[600px]:gap-[20px]'>
                    <div className='bg-[#F8FAFC] rounded-[12px] w-full pt-[15px] pb-[15px] grid grid-cols-5 items-center max-[1300px]:grid-cols-2 max-[800px]:grid-cols-1 '>
                        <div className='flex items-center justify-center flex-col  h-[50px] border-r border-r-[2px] border-r-[#E2E8F0] max-[800px]:border-r-[0px]'>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>{lang?.name}</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{user?.full_name}</h4>
                        </div>
                        <div className='flex items-center justify-center flex-col  h-[50px] border-r border-r-[2px] border-r-[#E2E8F0] max-[800px]:border-r-[0px]'>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>{lang?.category}</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{getRole(user?.role, lang)}</h4>
                        </div>
                        <div className='flex items-center justify-center flex-col  h-[50px] border-r border-r-[2px] border-r-[#E2E8F0] max-[1300px]:border-r-[0px] '>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>{lang?.email}</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{user?.email}</h4>
                        </div>
                        <div className='flex items-center justify-center flex-col  h-[50px] border-r border-r-[2px] border-r-[#E2E8F0] max-[800px]:border-r-[0px]'>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>{lang?.phone_number}</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{user?.phone_number? user?.phone_number:lang?.not_mentioned}</h4>
                        </div>
                        <div className='flex items-center justify-center flex-col  h-[50px]'>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>{lang?.date_of_birth}</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{user?.date_of_birth?moment(user?.date_of_birth).format("DD/MM/YYYY"):lang?.not_mentioned}</h4>
                        </div>
                    </div>
                    <div>
                    <button className='bg-blue text-[#0F172A] text-[12px] text-white font-bold  w-[116px] h-[32px] rounded-[8px]  top-[22px]' onClick={()=>this.setState({editVisible:true})}>{lang?.edit_profile}</button>

                    </div>
                </div>
            </div>
            {editVisible? <EditProfile closeEdit={()=>{this.setState({editVisible:false})}} data={user} />:null}
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user,
    lang: state.Data.lang,
})
export default connect(mapStateToProps)(SettingsPage)
