import React, { Component } from 'react'

export class MiniCalendarSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            fill="none"
        >
            <path
            fill="#94A3B8"
            d="M14.25 3h-1.5v-.75a.75.75 0 1 0-1.5 0V3h-4.5v-.75a.75.75 0 0 0-1.5 0V3h-1.5A2.25 2.25 0 0 0 1.5 5.25v9a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-9A2.25 2.25 0 0 0 14.25 3ZM15 14.25a.75.75 0 0 1-.75.75H3.75a.75.75 0 0 1-.75-.75V9h12v5.25Zm0-6.75H3V5.25a.75.75 0 0 1 .75-.75h1.5v.75a.75.75 0 0 0 1.5 0V4.5h4.5v.75a.75.75 0 1 0 1.5 0V4.5h1.5a.75.75 0 0 1 .75.75V7.5Z"
            />
        </svg>
    )
  }
}

export default MiniCalendarSvg
