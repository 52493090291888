import React, { Component } from 'react'
import SideBar from '../components/messages/SideBar'
import MessageBody from '../components/messages/MessageBody'
import { connect } from 'react-redux'

export class MessagesPage extends Component {
  state={
    visible: true
  }
  changeVisibleSide=()=>{
    if(window.innerWidth <=800){
      this.setState({visible:false})
    }else{
      this.setState({visible:true})
    }
  }
  componentDidMount(){
    window.scrollTo(0,0)
    this.changeVisibleSide();
    window.addEventListener("resize", ()=>{
      this.changeVisibleSide();
    })
  }
  render() {
    const {visible} = this.state;
    const {msgBoxInfo} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px] max-[500px]:pl-[10px] max-[500px]:pr-[10px]'>
            <div className='flex gap-[21px] mt-[40px]'>
              {visible || (!visible && msgBoxInfo === "") ?<SideBar visible={visible} />:null}
              {visible || (!visible && msgBoxInfo !== "") ? <MessageBody visible={visible} /> :null}
                
                
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  msgBoxInfo: state.Data.msgBoxInfo
})
export default connect(mapStateToProps)(MessagesPage)
