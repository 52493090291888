import React, { Component } from 'react'
import img from "../../images/profileBack.png"
import { connect } from 'react-redux'
import moment from 'moment'
import { Header } from '../../components/header/Header'
// import EditProfile from '../components/EditProfile'
import { getLevelCheckExam } from '../../actions/PublicActions'
import { Link } from 'react-router-dom'
import LocationSvg from '../components/svg/LocationSvg'
import FbSvg from '../components/svg/FbSvg'
import InstSvg from '../components/svg/InstSvg'
import PhoneSvg from '../components/svg/PhoneSvg'
import WpSvg from '../components/svg/WpSvg'
export class PublicProfile extends Component {
    state={
        editVisible:false
    }
    componentDidMount(){
        window.scrollTo(0,0);
        this.props.getLevelCheckExam();
    }
  render() {
    const {publicUser, lang, levelCheckExam} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px] pt-[100px]'>
            <div className='bg-white shadow mt-[40px] max-[1100px]:mt-[120px] max-[800px]:mt-[170px] rounded-t-[20px] rounded-b-[12px] pb-[30px]'>
                <div className='relative'>
                    <img src={img} alt="Image" className='w-full min-h-[140px] rounded-t-[12px]' />
                </div>
              
                <div className='flex mt-[20px] justify-between ml-[22px] flex-col mr-[22px] items-center gap-[20px] max-[1300px]:items-start max-[600px]:flex-col max-[600px]:items-center max-[600px]:mr-[22px] max-[600px]:gap-[20px]'>
                    <div className='bg-[#F8FAFC] rounded-[12px] w-full pt-[15px] pb-[15px] grid grid-cols-5 items-center max-[1300px]:grid-cols-2 max-[800px]:grid-cols-1 '>
                        <div className='flex items-center justify-center flex-col  h-[50px] border-r border-r-[2px] border-r-[#E2E8F0] max-[800px]:border-r-[0px]'>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>{lang?.name}</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{publicUser?.full_name}</h4>
                        </div>
                        <div className='flex items-center justify-center flex-col  h-[50px] border-r border-r-[2px] border-r-[#E2E8F0] max-[800px]:border-r-[0px]'>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>Level</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{levelCheckExam?.student_level?levelCheckExam?.student_level:<Link to="/level-check">Check Your level</Link>}</h4>
                        </div>
                        <div className='flex items-center justify-center flex-col  h-[50px] border-r border-r-[2px] border-r-[#E2E8F0] max-[1300px]:border-r-[0px] '>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>{lang?.email}</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{publicUser?.email}</h4>
                        </div>
                        <div className='flex items-center justify-center flex-col  h-[50px] border-r border-r-[2px] border-r-[#E2E8F0] max-[800px]:border-r-[0px]'>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>{lang?.phone_number}</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{publicUser?.phone_number? publicUser?.phone_number:lang?.not_mentioned}</h4>
                        </div>
                        <div className='flex items-center justify-center flex-col  h-[50px]'>
                            <span className='text-[#94A3B8] font-medium text-[12px]'>{lang?.date_of_birth}</span>
                            <h4 className='txt-light-black text-[16px] font-bold'>{publicUser?.date_of_birth?moment(publicUser?.date_of_birth).format("DD/MM/YYYY"):lang?.not_mentioned}</h4>
                        </div>
                      
                    </div>
                    {
                        publicUser?.user_apply?
                        <div className='w-full'>
                        <div className='bg-white w-[300px] p-[20px] rounded-[10px] shadow'>
                            <h2 className='text-center txt-blue font-semibold text-[24px]'>{publicUser?.user_apply?.company?.title}</h2>
                            <div className='flex justify-center mt-[7px] gap-[6px]'>
                                <a href={publicUser?.user_apply?.company?.location}>
                                    <LocationSvg />
                                </a>
                                <a href={publicUser?.user_apply?.company?.facebook}>
                                    <FbSvg />
                                </a>
                                <a href={publicUser?.user_apply?.company?.instagram}>
                                    <InstSvg />
                                </a>
                            </div>
                            <div className='flex gap-[8px] mt-[24px] w-full justify-center pl-[20px] pr-[20px] mr-auto'>
                                <a href={`tel:${publicUser?.user_apply?.company?.phone_number}`} className='inline-flex gap-[6px] p-[7px] rounded-[12px] border border-[var(--blue)] w-1/2 justify-center'>
                                    <span className='bg-blue inline-flex w-[16px] h-[16px] rounded-full justify-center items-center'>
                                        <PhoneSvg />
                                    </span>
                                    <span className='text-[#3E3B3B] font-medium text-[12px]'>Call Now</span>
                                </a>
                                <a  href={`https://wa.me/${publicUser?.user_apply?.company?.phone_number?.replace('(', '')?.replace(')', '')?.replace('0', '+994').replaceAll(" ", "")}`} className='inline-flex gap-[6px] p-[7px] rounded-[12px]  w-1/2 border justify-center border-[var(--blue)]'>
                                    <span  className='bg-[#4DDA81] inline-flex w-[16px] h-[16px] rounded-full justify-center items-center'> 
                                        <WpSvg />
                                    </span>
                                    <span className='text-[#3E3B3B] font-medium text-[12px]'>Mesaj Yaz</span>
                                </a>
                            </div>
                            {
                                publicUser?.user_apply?.status==0?
                               <h4 className='mt-[20px] text-[18px] font-semibold text-yellow-700'>Waiting for your confirmation</h4>:
                               <h4 className='mt-[20px] text-[18px] font-semibold text-red-700'>Rejected</h4>
                            }

                            
                        </div>
                    </div>:null
                    }
                    
                </div>
            </div>
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    publicUser: state.Data.publicUser,
    lang: state.Data.lang,
    levelCheckExam: state.Data.levelCheckExam,
});
const mapDispatchToProps = {getLevelCheckExam}
export default connect(mapStateToProps, mapDispatchToProps)(PublicProfile)


