import React, { Component } from 'react'
import imgUrl from "../images/lockExam.png"
import EmptySvg from './svg/EmptySvg';
import { connect } from 'react-redux';
export class EmptyPage extends Component {
  render() {
    const {title = "No exams unlocked", subTitle="(Please ask your manager to unlock the exam for you)", img=true, svg=false, lang} = this.props;
    return (
      <div className='w-full h-[623px] bg-white rounded-[12px] flex justify-center items-center pl-[20px] pr-[20px]'>
        <div className='flex justify-center items-center flex-col'>
          {
            img? 
            <>
              <img className='w-[115px] h-[115px] rounded-full' src={imgUrl} />
              <h3 className='text-[24px] txt-blue font-bold mt-[15px]'>{lang?.noexam_unlock}</h3>
              <p className='text-[24px] font-regular text-center'>{lang?.please_ask_manager_exam}</p>
            </>:null
          }
          {
            svg?
            <>
             <EmptySvg />
             <h3 className='text-[24px] txt-blue font-bold mt-[15px]'>{lang?.no_data_found_empty}</h3>
            </>:null
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(EmptyPage)
