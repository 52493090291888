import React, { Component } from 'react'

export class Timer extends Component {
  getTime(time, defaultTime){
    if(time == ''){
      return defaultTime
    }else if(time != '' && time < 10){
      return `0${time}`
    }else{
      return time
    }
  }
  render() {
    const {time, defaultTime} = this.props
    return (
      <div className='toefl_speaking_timer_container'>
        <h3>RESPONSE TIME</h3>
        <p>00:00:{this.getTime(time, defaultTime)}</p>
      </div>
    )
  }
}

export default Timer