const getTextFromHTML = (text) => {
    var parser = new DOMParser();
    var doc = parser.parseFromString(text, 'text/html');
    return doc.children[0].children[1].textContent;
}
const getQuestionFormat = (text) =>{
    const check = text.includes("Write about:");
    if(check){
        return text.replace("Write about:", "")
    }else{
        return text
    }
}
// const model = "mistral-tiny";
// const model = "gpt-3.5-turbo";
export const getEssayGpt=(data)=>{
   
    let gpt = `
    ${data?.essay?.course_prompt?.prompt}
    Question: ${getQuestionFormat(getTextFromHTML(data.essay.question))}
    Author English level: ${data.essay.title.substring(0, 2)}
    Student Name: ${data?.user?.full_name}
    Response:  ${getTextFromHTML(data.answer)}. 
    `

    const obj = {
        model: "open-mixtral-8x7b",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj
}
export const getEssayWritingGpt=(question, title, answer, course_prompt, student_name)=>{
    let gpt = `
   ${course_prompt}

    Question: ${getQuestionFormat(getTextFromHTML(question))}
    Author English level: ${title.substring(0, 2)}
    Student Name: ${student_name}
    Response:  ${getTextFromHTML(answer)}. 
    `

    const obj = {
        model: "open-mixtral-8x7b",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj
}
export const getEssayGradeWritingGpt=(question, title, answer, prompt)=>{
    let gpt = `
   
   ${prompt}

    Question title: ${getQuestionFormat(getTextFromHTML(question))}
    Student English level: ${title.substring(0, 2)}
    Answer to the question:  ${getTextFromHTML(answer)}. 
  
    `

    const obj = {
        model: "open-mixtral-8x22b",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj
}
export const getEssayGradeWritingIELTSGpt=(question, title, answer, prompt)=>{
    let gpt = `
   
    ${prompt}
    Question title: ${getQuestionFormat(getTextFromHTML(question))}
    Student English level: ${title.substring(0, 2)}
    Answer to the question:  ${getTextFromHTML(answer)}. 
  
    `

    const obj = {
        model: "open-mixtral-8x22b",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj
}
export const getEssayGradeWritingIELTSImageGpt=(question, title, answer, url, prompt)=>{
    let gpt = `
   
    ${prompt}

    Question title: ${getQuestionFormat(getTextFromHTML(question))}
    Student English level: ${title.substring(0, 2)}
    Answer to the question:  ${getTextFromHTML(answer)}. 
    Please, give me response only in standard json format (not gpt 4 format) and just 2 property as (grade and review).
    `

    const obj = {
        model: "gpt-4-vision-preview",
        messages: [
            {
                role: 'user', 
                content: [
                    {
                        type:"text",
                        text: gpt
                    },
                    {
                        type: "image_url",
                        image_url: {
                            url:url
                        }
                    }
                ]
            }
        ],
        max_tokens: 1000
    }
    return obj
}
export const getEssayWritingImageGpt=(question, title, answer, course_prompt, url, student_name)=>{
    let gpt = `
   ${course_prompt}

    Question: ${getQuestionFormat(getTextFromHTML(question))}
    Author English level: ${title.substring(0, 2)}
    Student Name: ${student_name}
    Response:  ${getTextFromHTML(answer)}. 
    `

    const obj = {
        model: "gpt-4-vision-preview",
        messages: [
            {
                role: 'user', 
                content: [
                    {
                        type:"text",
                        text: gpt
                    },
                    {
                        type: "image_url",
                        image_url: {
                            url:url
                        }
                    }
                ]
            }
        ],
        max_tokens: 1000
    }
    return obj
}
export const getEssayModelAnswerGpt=(data)=>{
    let gpt = `
    Author English level: ${data.essay.title.substring(0, 2)}
    Response:  ${getTextFromHTML(data.answer)}. 
    Please, Rewrite this essay..
    `
    const obj = {
        model: "gpt-4o-mini",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj
}

export const getSpeakingGpt = (data, resp)=>{
   
    let gpt = `
        Please give a grade from 0 to 100 to the following recording as an overall grade. 
        Also, evaluate fluency, vocabulary, grammar, assess coherence and cohesion, consider 
        task completion based on the suitable English level of the author as a non-native Speaker of English. 
        Please ignore punctuation marks.  Please, give me response as json format and just 2 property as (grade and review).
        
        Question: ${getQuestionFormat(getTextFromHTML(data.speaking.question)?.split(' ')?.splice(0, getTextFromHTML(data.speaking.question)?.split(' ').length-5)?.join(' '))}

        Talking response to the questions above: ${resp?.answare[0]?.text}. 

    `

    const obj = {
        model: "gpt-4o-mini",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj;
}
export const getSpeakingMistral = (data, text, student_name)=>{
   
    let gpt = `
        ${data?.course_prompt?.prompt}
        Student Name: ${student_name}
        Question: ${getQuestionFormat(getTextFromHTML(data.question)?.split(' ')?.splice(0, getTextFromHTML(data.question)?.split(' ').length-5)?.join(' '))}

        Talking response to the questions above: ${text}. 

    `

    const obj = {
        model: "open-mixtral-8x7b",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj;
}
export const getSpeakingGradeMistral = (data, text,)=>{
   
    let gpt = `
        ${data?.course_prompt?.second_prompt}
        
        Question: ${getQuestionFormat(getTextFromHTML(data.question)?.split(' ')?.splice(0, getTextFromHTML(data.question)?.split(' ').length-5)?.join(' '))}

        Talking response to the questions above: ${text}. 
        Please, give me response as json format and just 2 property as (grade and review).
    `

    const obj = {
        model: "open-mixtral-8x22b",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj;
}


export const getIALExamsMistralGrade=(question, answer,  student_name, level, prompt)=>{
    const gpt = `
    **Question:** [${getTextFromHTML(question)}]
    **Response:**  [${getTextFromHTML(answer)}]. 
    Student Name: ${student_name}
    Author English level: ${level}
   ${prompt}
    `
    const obj = {
        model: "open-mixtral-8x22b",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj;
}
export const getIALExamsGPTGradeImage=(question, answer,  student_name, level, url, prompt)=>{
    const gpt = `
    **Question:** [${getTextFromHTML(question)}]
    **Response:**  [${getTextFromHTML(answer)}]. 
    Student Name: ${student_name}
    Author English level: ${level}
    ${prompt}
    `
    const obj = {
        model: "gpt-4-vision-preview",
        messages: [
            {
                role: 'user', 
                content: [
                    {
                        type:"text",
                        text: gpt
                    },
                    {
                        type: "image_url",
                        image_url: {
                            url:url
                        }
                    }
                ]
            }
        ],
        max_tokens: 1000
    }
    return obj;
}
export const getIALExamsMistralReview=(question, answer,prompt,  student_name, level)=>{
    const gpt = `
    **Question:** [${getTextFromHTML(question)}]
    **Response:**  [${getTextFromHTML(answer)}]. 
    Student Name: ${student_name}
    Author English level: ${level}
    ${prompt}
    `
    const obj = {
        model: "open-mixtral-8x7b",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj;
}
export const getIALExamsGPTReviewImage=(question, answer,prompt,  student_name, level, url)=>{
    const gpt = `
    **Question:** [${getTextFromHTML(question)}]
    **Response:**  [${getTextFromHTML(answer)}]. 
    Student Name: ${student_name}
    Author English level: ${level}
    ${prompt}
    `
    const obj = {
        model: "gpt-4-vision-preview",
        messages: [
            {
                role: 'user', 
                content: [
                    {
                        type:"text",
                        text: gpt
                    },
                    {
                        type: "image_url",
                        image_url: {
                            url:url
                        }
                    }
                ]
            }
        ],
        max_tokens: 1000
    }
    return obj;
}
export const getIALExamsGPT=(data)=>{
    const gpt = `
    **Question:** [${data?.essay?.prompt}]
    **Response:**  [${getTextFromHTML(data?.answer)}]. 
    ${data?.essay?.grade_prompt}
    Please, give me response as json format and just 2 property as (grade and review).
    `
    const obj = {
        model: "gpt-4o-mini",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj;
}

export const getExamParentGPT=(value)=>{
    const obj = {
        "model": "gpt-4o-mini",
        "messages": [{
            "role": "user",
            "content": value
        }]
    }
    return obj;
}
export const getExamQuestionGPT=(value, desc)=>{
    const obj = {
        "model": "gpt-4o-mini",
        "messages": [{
            "role": "user",
            "content": `${value}. Please response as json format as: [{question: '', answers: [], ${desc} correct_answer: ''}]`
        }]
    }
    return obj;
}


export const getSpPracticeGrade= (data) =>{
    let firstPrompt = `
      Student name: ${data?.student?.first_name}
      Student English level: ${data?.speaking?.course?.title}
      Topic to discuss: ${getTextFromHTML(data?.speaking?.question)}
  
      ${data?.speaking?.course_prompt?.second_prompt}
      `
      let finalBox = [{
          role:"user",
          content: firstPrompt
      }];
      for(const item of data?.dialogs){
          finalBox.push({
              content: item?.answer,
              role: item?.role=="Student:" || item?.role=="user" ?"user":"assistant"
          })
      }
    finalBox.push({
        role:"user",
        content: data?.speaking?.course_prompt?.grade_prompt
    })
    return {
        model:"gpt-4o-mini",
        messages: finalBox
    }
}

export const getSpPracticeReview=(data)=>{
    let firstPrompt = `
      Student name: ${data?.student?.first_name}
      Student English level: ${data?.speaking?.course?.title}
      Topic to discuss: ${getTextFromHTML(data?.speaking?.question)}
  
      ${data?.speaking?.course_prompt?.second_prompt}
      `
      let finalBox = [{
          role:"user",
          content: firstPrompt
      }];
      for(const item of data?.dialogs){
          finalBox.push({
            content: item?.answer,
              role: item?.role=="Student:" || item?.role=="user"?"user":"assistant"
          })
      }
      finalBox.push({
        role:"user",
        content: data?.speaking?.course_prompt?.prompt? data?.speaking?.course_prompt?.prompt:''
    })
    return {
        model:"gpt-4o-mini",
        messages: finalBox
    }
}

export const getEssayQuestionModel=(data)=>{
    return {
        model: "gpt-4o-mini",
        messages:[
            {
                role: "user",
                content:data
            }
        ]
    }
}


export const getWritingPublickLevelCheck = (text, propmpt, desc)=>{
    let gpt = `
        ${propmpt}
        Question: ${getTextFromHTML(desc)}
        Response:  ${text}. 
        Please, give me response as json format and just one property as (grade).
    `;
    const obj = {
        model: "open-mixtral-8x7b",
        messages: [
            {role: 'user', content:gpt}
        ]
    }
    return obj;
}

export const examCheckModel=(title, question, answers, parentDesc)=>{
    let gpt = `
        Question:
        ${getTextFromHTML(parentDesc)}
        ${title}
        ${getTextFromHTML(question)}
       


        Answer options:

       ${answers}


        Check the question and then answer options. Show me the correct answer. No explanation needed.
        `
        // console.log(gpt)
    return {
        model:"gpt-4-0125-preview",
        messages:[
            {role:"user", content:gpt}
        ]
    };
}