import React, { Component } from 'react'
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

export class NewRequestModal extends Component {
    closeModal=()=>{
        this.props.changeStateValue({name:"newApplyCheck", value:0})
    }
  render() {
    const {lang} = this.props;
    return (
      <div className='flex background_shadow justify-center items-center'>
        <div className='p-[20px] bg-white shadow w-[400px] rounded-[10px]  max-[450px]:w-[280px] max-[450px]:ml-[20px] max-[450px]:mr-[20px] flex flex-col items-center'>
            <p className='text-center text-[20px] txt-blue font-semibold'>{lang?.new_req_text1}</p>
            <p className='text-center mt-[20px] txt-blue font-semibold'>{lang?.new_req_text2}</p>
            <Link onClick={this.closeModal.bind(this)} className='mt-[20px] text-[14px] text-white bg-blue font-semibold p-[4px_8px] rounded-[6px]' to={'/online-applies'}>{lang?.new_req_btn}</Link>
        </div>
      </div>
    )
  }
}
const mapStateToProps  = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(NewRequestModal)
