import { MAIN_API, MAIN_APIV2 } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";
import axios from "axios";
import { bigAlert } from "./SweetAlerts";
import Swal from "sweetalert2";
import { getErrors } from "./MainAction";
const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
        "Accept": "application/json",
        // "Content-Type": "application/json"
    }
}

export const getChatUsers= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/messages`, {
        params:{type:"active_messages_list"},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'chatUsers', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}
export const getGroupUsers= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/group-messages`, {
        params:{type:"groups_list"},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'groupUsers', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}
export const getMessageAllUsers= (page, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/messages`, {
        params: {type: "message_groups", page: page, keyword: keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'msgAllUsers', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}


export const getMsgBoxData= (id, page=1) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/messages`, {
        params: {type: "messages_list", page: page, message_group_id: id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'msgBoxData', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}
export const getGroupMsgBoxData= (id, page=1) => async dispatch => {

    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/group-messages?type=chat_box&page=${page}&group_id=${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'msgBoxData', value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}
export const getMsgGroupUsers= (teacher_id, student_id, company_id) => async dispatch => {
  
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/messages`, {
        params: {type: 'group_users', teacher_id: teacher_id,  student_id: student_id, company_id: company_id },
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}
export const getGroupsUsers= (id) => async dispatch => {
  
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/group-messages?type=group_users&group_id=${id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}
export const getGroupCreatedUsers= (keyword) => async dispatch => {
  
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/group-user-list?keyword=${keyword}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}
export const getPinneds= (group_id, type) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/message-pin?group_id=${group_id}&type=${type}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        dispatch(getErrors(err))
    })
}

export const sendNotification = (url, formData) => async dispatch => {
    return axios.post(`${MAIN_API}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
        return "success"
    }).catch(err=>{
        return 'error'
    })
}


export const getSupportMessages=(page)=>async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/support-message?page=${page}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "supportMessages", value: resp?.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getSupportPins=(page=1)=>async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/support-msg-pin?page=${page}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value: false}));
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value: false}));
        return []
    })
}