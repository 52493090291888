import React, { Component } from 'react';
import Header from '../../components/header/Header';
import { connect } from 'react-redux';
import { getCourses, getLessons, getEssayByCourse } from '../../actions/MainAction';
import Card2 from '../../components/cards/Card2';
import { changeStateValue } from '../../redux/MainReducer';
import { getDavinchiModel, getMistralModel, getTurboModel } from '../../actions/robotActions';
import DropSearch from '../../components/DropSearch';
import EmptyPage from '../../components/EmptyPage';
import { withHooks } from '../../actions/withHooks';
import { getEssayQuestionModel } from '../../actions/getReviewGpt';
import { getGradeCheck } from '../../actions/getRole';
export class Essays extends Component {
  state={
    lessonId: ''
  }
  componentDidMount(){
    this.props.getCourses();
    this.props.changeStateValue({name: 'studentCourses', value:[]})
    this.props.changeStateValue({name: 'lessons', value:[]});
    window.scrollTo(0,0);
    
    const checkCourse =this.props.params?.courseId;
    const checkLesson =this.props.params?.lessonId;
    if(checkCourse && !checkLesson){
      this.props.getLessons(checkCourse)
    }else if(checkLesson){
    
      this.setState({lessonId:checkLesson})
      this.props.getEssayByCourse(checkLesson)
    }
  }
  getLessonsBtn=(id)=>{
    this.props.getLessons(id)
  }

  getEssayBtn=(data)=>{
    this.setState({lessonId:data?.value})
    this.props.getEssayByCourse(data?.value)
  }
  getAnswer(data){
    if(data){
      if(data?.is_submitted==1){
        return ''
      }else{
        return data?.answer
      }
    }else{
      return ''
    }
  }
  openModal=(data, e) => {
    // console.log(data)
    e.preventDefault();
    if(!(data?.answers?.length !==0 && getGradeCheck(data?.answers, data))){
  
      
      let params = {
        position:true,
        id: data?.id,
        type:'essay_write',
        modalType: 'answer',
        title: data?.title,
        essay_type: data?.essay_type,
        question:data?.essay_type === "robo_unit" || data?.essay_type === "robo_midterm_end_course"?'': `${data?.question} ${data?.image_full_url?`<img src="${data?.image_full_url}" />`:'' }`,
        answer: this.getAnswer(data.latest_answer),
        editorName:"mainModal.answer",
        afterCallFunction: this.props.getEssayByCourse,
        funcParams: [this.state.lessonId],
        outline:data?.essay_type === "robo_unit" || data?.essay_type === "robo_midterm_end_course"?'empty':'',
        course_prompt: data?.course_prompt?.prompt,
        second_prompt: data?.course_prompt?.second_prompt,
        course_id: data?.course_id,
        image_full_url: data?.image_full_url,
        submit_word_count: data?.course_prompt?.word_count,
    }
    this.props.changeStateValue({name: 'mainModal', value: params});
    if(data?.essay_type === "robo_unit" || data?.essay_type === "robo_midterm_end_course"){
      if(data?.answers.length !== 0){
        params = {
          ...params,
          question :`${data?.answers[0]?.question?data?.answers[0]?.question:''} `,
          outline: data?.answers[0]?.outline?data?.answers[0]?.outline:'',
        }
        this.props.changeStateValue({name: 'mainModal', value: params});
      }else{
        params = {
          ...params,
          question:''
        }
        // const obj = {
        //   "model": "gpt-3.5-turbo-instruct",
        //   "prompt": `${data?.question.replace(/<[^>]+>/g, ' ').split("qReq:")[0]?.split("rPrompt:")[1]} \n \n`,
        //   "temperature": 0.7,
        //   "max_tokens": 256,
        //   "top_p": 1,
        //   "frequency_penalty": 0,
        //   "presence_penalty": 0
        // }
        const obj = getEssayQuestionModel(`${data?.question.replace(/<[^>]+>/g, ' ').split("qReq:")[0]?.split("rPrompt:")[1]} \n \n`)
        this.props.getTurboModel(obj)
        .then(resp=>{
          // console.log(resp)
          params={
            ...params,
            question : `<pre class="whitespace-break-spaces">${resp?.replaceAll("*", "")?.replaceAll("#", "")}</pre>`
          }
          this.props.changeStateValue({name: 'mainModal', value: params});
          params={
            ...params,
            outline: ''
          }
          const obj1 = {
            "model": "gpt-3.5-turbo-instruct",
            "prompt": `${resp}. Create a short outline for this question. \n \n`,
            "temperature": 0.7,
            "max_tokens": 256,
            "top_p": 1,
            "frequency_penalty": 0,
            "presence_penalty": 0
          }
          // this.props.getDavinchiModel(obj1)
          // .then(resp1=>{
          //   params={
          //     ...params,
          //     outline : resp1
          //   };
          //   this.props.changeStateValue({name: 'mainModal', value: params});
          // })
        })
        
      }
    }
    }
  }
  onSelectCourse=(data)=>{
    this.props.getLessons(data?.value)
  }
  getCourseVisible(){
    if(!this.props.params?.courseId &&  !this.props.params?.lessonId){
      return true
    }else{
      return false
    }
  }
  getUnitVisible(){
    if(this.props.params?.courseId &&  !this.props.params?.lessonId){
      return true
    }else{
      return false
    }
  }
  getDataVisible(){
    if(this.props.params?.courseId && this.props.params?.lessonId){
      return true
    }else{
      return false
    }
  }
  render() {
    const {studentCourses, lessons, essays, lang} = this.props;
    const courses = [];
    const lessonsAr = [];
    for(const data of studentCourses){
      courses.push({
        label: data?.title,
        value: data?.id
      })
    }
    for(const data of lessons){
      lessonsAr.push({
        label: data?.title,
        value: data?.id
      })
    }
    // console.log(essays)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="essay_writing" />
            <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
              {courses?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please select course and lesson" subTitle="" /></div>:null}
              <div className='w-full grid-full-205 mt-[20px]'>
                {this.getCourseVisible()?
                  courses?.map((data, i)=>{
                    return(
                      <Card2 
                          key={i}
                          title={data?.label}
                          btnTitle={"View"}
                          onClickBtn={this.onSelectCourse.bind(this, data)}
                          btnUrl={`/essays/${data?.value}`}
                      />
                    )
                  }):null
                }
                {this.getUnitVisible()?
                  lessonsAr?.map((data, i)=>{
                    return(
                      <Card2 
                          key={i}
                          title={data?.label}
                          btnTitle={"View"}
                          onClickBtn={this.getEssayBtn.bind(this, data)}
                          btnUrl={`/essays/${this.props.params?.courseId}/${data?.value}`}
                      />
                    )
                  }):null
                }
                
              </div>
               {
                this.getDataVisible()?
                <div className='w-full grid-full-205 mt-[20px]'>
                  {
                    essays?.map((data, i)=>{
                      return(
                        <Card2
                          key={i}
                          title={data?.title}
                          btnTitle={data?.answers?.length !==0 && getGradeCheck(data?.answers, data)? lang?.submitted: data?.essay_type == "midterm_end_course" && data?.unlocks || data?.essay_type == "unit" ||  data?.essay_type == "robo_midterm_end_course" && data?.unlocks || data?.essay_type == "robo_unit"?  lang?.write_essay: lang?.waiting_unlock}
                          status={data?.answers?.length !==0 && getGradeCheck(data?.answers, data)?'0':'1'}
                          onClickBtn={data?.answers?.length !==0 && getGradeCheck(data?.answers, data)?()=>{}:data?.essay_type == "midterm_end_course" && data?.unlocks || data?.essay_type == "unit" ||  data?.essay_type == "robo_midterm_end_course" && data?.unlocks || data?.essay_type == "robo_unit"? this.openModal.bind(this, data):()=>{}}
                          bg={data?.answers?.length !==0 && getGradeCheck(data?.answers, data)?"bg-red":data?.essay_type == "midterm_end_course" && !data?.unlocks  ||  data?.essay_type == "robo_midterm_end_course" && !data?.unlocks ?  "bg-yellow-500": "bg-blue"}
                        />
                      )
                    })
                  }
                </div>:null
               }
                
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
  studentCourses: state.Data.studentCourses,
  lessons: state.Data.lessons,
  essays: state.Data.essays,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getCourses, getLessons, getEssayByCourse, changeStateValue, getDavinchiModel, getMistralModel, getTurboModel}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Essays))
