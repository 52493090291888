import React, { Component } from 'react'

export class CardDateSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    fill="none"
  >
    <path
      fill="#545454"
      d="M16.625 3.5h-1.75v-.875a.875.875 0 0 0-1.75 0V3.5h-5.25v-.875a.875.875 0 0 0-1.75 0V3.5h-1.75A2.625 2.625 0 0 0 1.75 6.125v10.5a2.625 2.625 0 0 0 2.625 2.625h12.25a2.625 2.625 0 0 0 2.625-2.625v-10.5A2.625 2.625 0 0 0 16.625 3.5Zm.875 13.125a.875.875 0 0 1-.875.875H4.375a.875.875 0 0 1-.875-.875V10.5h14v6.125Zm0-7.875h-14V6.125a.875.875 0 0 1 .875-.875h1.75v.875a.875.875 0 0 0 1.75 0V5.25h5.25v.875a.875.875 0 0 0 1.75 0V5.25h1.75a.875.875 0 0 1 .875.875V8.75Z"
    />
  </svg>
    )
  }
}

export default CardDateSvg
