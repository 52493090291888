import React, { Component } from 'react'
import { mixinAlert } from '../../actions/SweetAlerts';

export class Card8 extends Component {
  render() {
    const {data, onDelete=()=>{}} = this.props
    return (
      <div className='bg-white shadow rounded-[10px] p-[10px]'>
        <div>
            <h3 className='mb-[10px] txt-blue'>{data?.title}</h3>
            <img src={data?.image_full_url} className='w-full rounded-[10px] h-[150px]' />
        </div>
        <div className='flex justify-between mt-[10px]'>
            <button onClick={()=>onDelete()} className='bg-red h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Delete</button>
            <button onClick={()=>{navigator.clipboard.writeText(data?.image_full_url); mixinAlert("success", "Copied successfully")}} className='bg-gray-400 h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Copy</button>
            {/* <button className='bg-blue h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Edit</button> */}
        </div>
      </div>
    )
  }
}

export default Card8
