import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
export class AddQuestionBtn extends Component {
    answerType(type){
            return{
                check: false,
                points: 0,
                title: '',
                type: type
            }
    }
    addAnswerBtn(){
        let allAnswer = [...this.props.adminExamModalData?.question_option];
        allAnswer.push(this.answerType(this.props.adminExamModalData?.type));
        this.props.changeStateValue({
            name: 'adminExamModalData.question_option',
            value: allAnswer
        })
    }
  render() {
    return (
      <div className='flex items-center justify-center mt-[30px]'>
            <button onClick={this.addAnswerBtn.bind(this)}  className='font-semibold text-[19px] border-b border-b-[2px] border-b-[var(--blue)]'>+ ADD ANSWER</button>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    adminExamModalData: state.Data.adminExamModalData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(AddQuestionBtn)
