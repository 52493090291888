import React, { Component } from 'react'
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { avatarAppConfig } from "./config";
import { connect } from 'react-redux';
import { getTurboModel } from '../../../actions/robotActions';
import { changeStateValue } from '../../../redux/MainReducer';
import { getGrades, getStringGrade } from '../../../actions/getRole';
import { insertNewData } from '../../../actions/MainAction';
import { bigAlert } from '../../../actions/SweetAlerts';
import { withHooks } from '../../../actions/withHooks';
let interval = '';
export class SpeechToTextLevelCheck extends Component {
    state={
        subscriptionKey: avatarAppConfig.cogSvcSubKey,
        serviceRegion: avatarAppConfig.cogSvcRegion,
        dialogBox:[],
        btnType:"speaking",
        time: 0,
        timer:'00:00',
        btnDisable:false,
        sendToAvatar:true
    }
    getTimeFormat(time){
        if(time < 10){
            return `0${time}`;
        }else{
            return time;
        }
      }
    componentDidMount(){
       


        this.sendToRobo("", true);
        let inc = 0;
        let second = 0;
        let minute = 0;
        interval = setInterval(()=>{
            inc++;
            this.setState({time:inc});
           
            if(inc % 60 === 0){
                minute++;
                second = 0
            }else{
                second++
               
            }
            this.props.changeStateValue({name:"spTimer", value: `${this.getTimeFormat(minute)}:${this.getTimeFormat(second)}`})
        }, 1000)
    }
    getTextFromHTML = (text) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(text, 'text/html');
        return doc.children[0].children[1].textContent;
    }
    sendToRobo(text, firstStart=false){
        let firstPrompt = `
        Student name: ${this.props?.user?.first_name}
        Student English level: ${this.props.modalData?.course?.title}
       

        ${this.props.modalData?.course_prompt?.second_prompt}
        `
        let newBox = [...this.state.dialogBox];
        if(firstStart){
            newBox.push({
                role: "system",
                content: [
                    {
                        type:"text",
                        text: firstPrompt
                    }
                ]
            })
            newBox.push({
                role: "user",
                content: [
                    {
                        type:"text",
                        text: ` Topic to discuss: ${this.getTextFromHTML(this.props.modalData?.question)}`
                    }
                ]
            })
            
        }else{
            newBox.push({
                role: "user",
                content: [
                    {
                        type:"text",
                        text:  text+`. ${this.props.modalData?.next_prompt}`
                    }
                ]
            })
        }
        this.setState({dialogBox: newBox});
        const obj = {
            model : 'gpt-4o-mini',
            messages: newBox
        }
        this.props.getTurboModel(obj)
        .then(resp=>{
            newBox.push({
                role: "assistant",
                content: resp
            })
            this.setState({dialogBox: newBox});

            let permission = false;
            // this.props.modalData.time
            if(this.state.time>this.props.modalData.time){
                permission=true;
            }
            if(!permission){
                this.props.speakSelectedText(resp?.replaceAll("#", "")?.replaceAll("*", ""), this.startSpeechToText)
            }else{
                this.props.speakSelectedText( "The level check is done. I will now evaluate your English skills. Please wait.", this.sendBtn)
            }
            
        })
    }
    startSpeechToText = () => {
        this.setState({btnDisable:false})
        const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(this.state.subscriptionKey, this.state.serviceRegion);
        const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);
        this.setState({btnType:"listening"})
        recognizer.recognizeOnceAsync(result => {
          if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
            // setText(result.text);
            if(this.state.sendToAvatar){

               
                this.sendToRobo(result.text, false)
                this.setState({btnType:"speaking"})
                this.setState({btnDisable:true}) 
            }
            
            // console.log(result.text)
          } else {
            // console.error("Speech recognition failed: ", result.errorDetails);
            this.setState({btnType:"stand"})
          }
        //   setIsRecording(false);
        });
    
        // setIsRecording(true);
    };
    sendBtn=()=>{
        this.setState({btnDisable:true, sendToAvatar:false})
        this.props.changeStateValue({name:'loader', value:true})
        this.props.changeStateValue({
            name:'progressLoading',
            value:{
              position:true,
              text:"Please wait. I'm reviewing your speaking. Don't close the window.",
              complete:false
            }
        })
        let newBox = [...this.state.dialogBox];
        newBox.push({
            role:"user",
            content: this.props.modalData?.course_prompt?.grade_prompt
        })
        this.props.getTurboModel({
            model:"gpt-4o-mini",
            messages: newBox
        }).then(respGrade=>{
            this.props.changeStateValue({name:'loader', value:true});
            let robGrade = 0;
            try{
                robGrade = getStringGrade(respGrade)
            }catch(err){}
            let reviewPrompt = [...this.state.dialogBox];
            reviewPrompt.push({
                role:"user",
                content: this.props.modalData?.course_prompt?.prompt
            })
            this.props.getTurboModel({
                model:"gpt-4o-mini",
                messages: reviewPrompt
            }).then(respReview=>{
                this.props.changeStateValue({name:'loader', value:true})
                let robReview = respReview?.replaceAll("#", "")?.replaceAll("*", "");
                if(robGrade == 0){
                  robReview = `Dear Student,\n\nI looked at your speaking practice. It is scored lower. Because of this, I can't fully review it right now. I'm sorry for this.\n\nCould you try speaking again? Make sure your pronunciation is clear, ideas are well structured. Think of IELTS speaking rules. Thanks for your hard work. I'm eager to see your next Sp. Practice.\n\nBest regards,\nSelty Teacher`
                }

                let lastObj = {
                    speaking_id: this.props.modalData?.id,
                    time: this.state.time,
                     teacher_grade: robGrade,
                    robo_review: robReview,
                    dialogs: JSON.stringify(this.state.dialogBox),
                    grade_log: respGrade,
                    review_log: respReview,
                }
                let formData = new FormData();
                for(const property in lastObj){
                    formData.append(property, lastObj[property])
                }
                this.props.insertNewData("speaking-practice-student-review", formData)
                .then(resp=>{
                this.props.changeStateValue({
                    name:'progressLoading',
                    value:{
                    position:true,
                    text:"Your speaking is ready. Please check your grade",
                    complete:true
                    }
                })
                setTimeout(()=>{
                    this.props.resetStateValue({name:'progressLoading'})
                }, 2000)
                    this.props.navigate("/speaking-practice-reviews")
                    if(resp == 'success'){
                    // bigAlert(`Complete!`, `Speaking completed successfully.`, 'success')
                    }else{
                    bigAlert(`Error!`, `Something went wrong.`, 'error')
                    }
                
                    window.location.reload()
                    this.setState({btnDisable:false})
                })
            })
        })
    }
  render() {
    const {btnDisable, btnType, time} = this.state;
    const {lang, modalData} = this.props
    // console.log(dialogBox)
    return (
      <div className='w-full  flex pl-[40px] pr-[40px] absolute bottom-[40px] max-[500px]:bottom-[60px]  justify-between items-center gap-[20px] max-[600px]:pl-[10px] max-[600px]:pr-[10px]'>
         <button onClick={()=>window.location.reload()} className='bg-red  pl-[20px] pr-[20px]  text-[16px] h-[50px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
        {
            btnType=="speaking"?
            <button className='bg-green-600 w-[180px] max-[600px]:w-[150px] h-[50px] text-white text-[16px] font-semibold rounded-[8px]'>Speaking...</button>:
            btnType=="listening"?
            <button className='bg-blue w-[180px] h-[50px] max-[600px]:w-[150px] text-white text-[16px] font-semibold rounded-[8px]'>Listening...</button>:
            btnType == "stand"?
            <button onClick={this.startSpeechToText.bind(this)} className='bg-orange w-[180px] max-[600px]:w-[150px] h-[50px] text-white text-[16px] font-semibold rounded-[8px]'>Talk to me</button>:null
        }
        {/* {
            time>modalData.time?
            // time>30?
            <button disabled={btnDisable} onClick={this.sendBtn.bind(this)} className='bg-blue h-[50px] pl-[20px] pr-[20px]  text-[16px] rounded-[5px] text-white font-semibold '  >{lang?.submit_grading}</button>:
            <button  className='bg-gray-400 h-[50px] pl-[20px] pr-[20px]  text-[16px] rounded-[5px] text-white font-semibold '  >{lang?.submit_grading}</button>
        } */}
       {/* <button  className='bg-gray-400 h-[50px] pl-[20px] pr-[20px]  text-[16px] rounded-[5px] text-white font-semibold '  >{lang?.submit_grading}</button> */}
        
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getTurboModel,changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SpeechToTextLevelCheck))


