import React, { Component } from 'react'

export class SpPracticeSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
      >
        <path
          stroke="#585874"
          strokeWidth={1.5}
          d="M6.667 6.667a2.667 2.667 0 1 0 0-5.334 2.667 2.667 0 0 0 0 5.334ZM12 11.667c0 1.656 0 3-5.333 3-5.334 0-5.334-1.344-5.334-3 0-1.657 2.388-3 5.334-3 2.945 0 5.333 1.343 5.333 3Z"
        />
        <path
          stroke="#585874"
          strokeLinecap="round"
          strokeWidth={1.5}
          d="M12.667 1.333S14 2.133 14 4c0 1.867-1.333 2.667-1.333 2.667m-1.334-4S12 3.067 12 4c0 .933-.667 1.333-.667 1.333"
        />
      </svg>
    )
  }
}

export default SpPracticeSvg
