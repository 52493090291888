import React, { Component } from 'react'

export class SatCont extends Component {
  render() {
    const {satExam="", satHw="", satVerbExam="", satVerbHw=""} = this.props
    return (
        <div className='mt-[20px] bg-gray-100 pt-[4px] pb-[4px] rounded-[6px]'>
            <span className='text-[14px] ml-[20px] txt-blue'>SAT</span>
            <ul className='flex justify-center '>
            <li className='w-1/4 flex flex-col items-center p-[0px] border-r'>
                <span className='text-[#64748B] text-[14px] font-[300]'>{satExam}/4</span>
                <span  className='text-[10px] txt-blue font-semibold'>M. Exam</span>
            </li>
            <li className='w-1/4 flex flex-col items-center p-[0px]'>
                <span className='text-[#64748B] text-[14px] font-[300]'>{satHw}/3</span>
                <span  className='text-[10px] txt-blue font-semibold'>M. PT</span>
            </li>
            <li className='w-1/4 flex flex-col items-center p-[0px] border-r'>
                <span className='text-[#64748B] text-[14px] font-[300]'>{satVerbExam}/4</span>
                <span  className='text-[10px] txt-blue font-semibold'>V. Exam</span>
            </li>
            <li className='w-1/4 flex flex-col items-center p-[0px]'>
                <span className='text-[#64748B] text-[14px] font-[300]'>{satVerbHw}/3</span>
                <span  className='text-[10px] txt-blue font-semibold'>V. PT</span>
            </li>
            </ul>
        </div>
    )
  }
}

export default SatCont
