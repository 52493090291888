import React, { Component } from 'react'

export class ReportsSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={18}
        height={18}
        viewBox="0 0 32 32"
      >
        <path
          d="M24 7V5H4v18a4 4 0 0 0 4 4h16a4 4 0 0 0 4-4V7h-4zm2 16a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V7h16v16h2V9h2v14zM14 9H8v6h6V9zm-2 4h-2v-2h2v2zm4-4h4v2h-4V9zm0 4h4v2h-4v-2zm-8 4h12v2H8v-2zm0 4h12v2H8v-2z"
          style={{
            fill: "#585874",
          }}
        />
      </svg>
    )
  }
}

export default ReportsSvg
