import React, { Component } from 'react'
import Header from '../../../../components/modals/Header'
import { connect } from 'react-redux';
import { changeStateValue } from '../../../../redux/MainReducer';
import Input from '../../../../components/modals/components/Input';
import TextEditor from '../../../../components/TextEditor';
import FileInput from '../../../../components/FileInput';
import { resetStateValue } from '../../../../redux/MainReducer';
import { mixinAlert } from '../../../../actions/SweetAlerts';
import { insertNewData } from '../../../../actions/MainAction';
import { getExamQuestions } from '../../../../actions/AdminActions';
import { getTurboModel } from '../../../../actions/robotActions';
import { getExamParentGPT } from '../../../../actions/getReviewGpt';
export class ParentModal extends Component {
    state={
        btnDisabled:false,
        robo:''
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"adminExamModalData"});
    }
    saveBtn=(data)=>{
        if(data?.title.trim() !==""){
            this.setState({btnDisabled: true})
            let formData = new FormData();
            let url = "questions";
            for (const property in data) {
                if (data[property])
                  formData.append(property, data[property]);
            }
            formData.append("question_option", `[{\"type\":\"parent\",\"title\":${data.title},\"check\":false,\"points\":0}]`);
            formData.append("type", "parent");
            formData.append("exam_id", this.props.examId);
            if(data.id !== ''){
                formData.append("_method", "put");
                url = `questions/${data.id}`;
            }
            this.props.insertNewData(url,formData)
            .then(resp=>{
                this.setState({btnDisabled: false})
                if(resp === "success"){
                    this.props.getExamQuestions(this.props.examId)
                    this.closeModal()
                    mixinAlert("success", `Exam parent ${data?.id ===""? "added":"edited"} successfully.`);
                }else{
                    mixinAlert("error", "Something went wrong!");
                }
            })
        }else{
            mixinAlert("info", "Please fill the title field");
        }
    }

    sendToGPT=(value)=>{
        if(value.trim() !== ""){
            this.setState({btnDisabled: true})
            this.props.changeStateValue({name:"loader", value:true})
            this.props.getTurboModel(getExamParentGPT(value))
            .then(resp=>{
                this.setState({btnDisabled: false})
                this.props.changeStateValue({name:"loader", value:false})
                this.props.changeStateValue({name:"adminExamModalData.descriptionPosition", value:true})
                this.props.changeStateValue({name:"adminExamModalData.description", value:resp})
            })
        }else{
            mixinAlert("warning", "Please fill the prompt field")
        }
    }
  render() {
    const {adminExamModalData} = this.props;
    const {btnDisabled, robo} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white w-[900px]  shadow bg-white rounded-[10px] '>
            <Header closeBtn={this.closeModal.bind(this)} title={adminExamModalData?.id == ""? "Add Parent": "Edit Parent"} />
            <div className='p-[10px] rounded-b-[10px] bg-white max-h-[70vh] overflow-auto'>
                <Input title="Title" required value={adminExamModalData?.title} name="adminExamModalData.title" />
                {
                    adminExamModalData?.roboType?
                    <div className='mt-[20px]'>
                        <span className='text-[14px] txt-light-black'>Robo Prompt</span>
                        <textarea placeholder='Robo Prompt' value={robo} onChange={(e)=>this.setState({robo: e.target.value})} className='w-full h-[150px] border rounded-[6px] outline-none p-[7px] text-[14px]' />
                        <button onClick={this.sendToGPT.bind(this, robo)} className='bg-blue pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Send</button>
                    </div>:null
                }
                {
                    adminExamModalData?.descriptionPosition?
                    <div className='mt-[20px]'>
                        <TextEditor name="adminExamModalData.description" value={adminExamModalData.description} title="Description" />
                    </div>:null
                }
                
                <div className='mt-[20px] grid-full-250'>
                    <FileInput title="Audio File" type="audio" name="adminExamModalData.audio_file" valueName="adminExamModalData.audio_full_url" value={adminExamModalData.audio_full_url} />
                    <FileInput title="Video File" type="video" name="adminExamModalData.video_file" valueName="adminExamModalData.video_full_url" value={adminExamModalData.video_full_url} />
                    <FileInput title="Image File" type="image" name="adminExamModalData.question_image" valueName="adminExamModalData.image_full_url" value={adminExamModalData.image_full_url} />
                </div>
                <div className='flex justify-between items-center mt-[20px]'>
                    <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Close</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,adminExamModalData)} className='bg-orange pt-[5px] pb-[5px] pl-[10px] pr-[10px]  text-[14px] rounded-[5px] text-white font-semibold'>Save</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminExamModalData: state.Data.adminExamModalData
});
const mapDispatchToProps = {changeStateValue, resetStateValue, insertNewData, getExamQuestions, getTurboModel}
export default connect(mapStateToProps, mapDispatchToProps)(ParentModal)
