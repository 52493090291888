import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
export class Reading extends Component {
  state={
    wordCount: 0
  }
  getWritingText(e){
    this.setState({
      wordCount: e.target.value.trim().split(' ').filter(a=> a != '').length
    })
    this.props.changeStateValue({
      name: e.target.name,
      value: e.target.value
    })
  }
  render() {
    const {title = '', desc = '', writing=false, name='', value=""} = this.props;
    const {wordCount} = this.state
    return (
      <div className='w-full'>
         <p className='font-bold text-[20px]'>{title}</p>
         {
          desc != ''?
          <div className='w-full ' dangerouslySetInnerHTML={{ __html: desc}}></div>:null
         }
         {
          writing?
          <div className='flex flex-col gap-[20px]'>
            <span>Word Count: {wordCount}</span>
             <textarea className='border-[1px] outline-none h-[70vh] border-black rounded-[10px]' value={value} name={name} onChange={this.getWritingText.bind(this)}></textarea> 
          </div>
          :null
         }
         
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Reading)