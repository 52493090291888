import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { changeStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { getAdminPriceCalculator } from '../../actions/StaffAction';
import SectionHeader from '../../components/SectionHeader';
import HeaderButtons from '../../components/HeaderButtons';
import Card1 from '../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';

export class AdminPriceCalculator extends Component {
    componentDidMount(){
        this.props.getAdminPriceCalculator();
    }

    openModal=(data="")=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                lesson_mode_id:data?.lesson_mode_id? data?.lesson_mode_id:'',
                study_mode:data?.study_mode? data?.study_mode:'',
                fee:data?.fee? data?.fee:'',
                afterCallFunction:this.props.getAdminPriceCalculator,
                afterCallParams:[],
                requireds:["lesson_mode_id", "study_mode", "fee", ],
                url: data?.id? `price-calculator/${data?.id}`:'price-calculator',
                modalTitle: data?.id? `Edit Data`:'Add Data',
                overflow_def:true
            }
        });
    }
    onDeleteBtn=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`price-calculator/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getAdminPriceCalculator()
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {adminPriceCalculator} = this.props;
    // console.log(adminPriceCalculator)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Price calculator" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={()=>this.openModal("")} buttons={[{title: "Add a data", id:'0'}]} selected={0} />
            </div>
            <div className='mt-[30px] grid-full-250 items-start'>
                {
                    adminPriceCalculator?.map((data, i) => {
                        return (
                            <Card1
                                key={i}
                                statusBisible={false}
                                status={1}
                                btn={false}
                                title={data?.lesson_mode?.title}
                                grade={data?.fee}
                                exam={data?.study_mode}
                                btn2={true}
                                btn2Text={"Edit"}
                                btn2Color={"bg-blue"}
                                adminDelete={true}
                                onCLickBtn={this.openModal.bind(this, data)}
                                onDeleteBtn={this.onDeleteBtn.bind(this, data?.id)}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) => ({
    adminPriceCalculator: state.Data.adminPriceCalculator,
});
const mapDispatchToProps = {changeStateValue, getAdminPriceCalculator, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminPriceCalculator)
