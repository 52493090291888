import React, { Component } from 'react'
import HeaderSection from '../sections/HeaderSection'
import AcceptedStudents from '../sections/AcceptedStudents'
import ExamResults from '../sections/ExamResults'

export class PublicMain extends Component {
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    return (
      <div>
        {/* <HeaderSection /> */}
        <div className='pt-[100px]'></div>
        <AcceptedStudents viewMore={true} page="0.4" />
        <ExamResults viewMore={true}  page="0.4" type="filter_by_exam" examId="1" companyId="" />
        <ExamResults viewMore={true}  page="0.4" type="filter_by_exam" examId="2" companyId="" />
        <ExamResults viewMore={true}  page="0.4" type="filter_by_exam" examId="3" companyId="" />
        {/* <ExamResults viewMore={true}  page="0.4" type="filter_by_exam" examId="4" companyId="" /> */}
        <ExamResults viewMore={true}  page="0.4" type="filter_by_exam" examId="5" companyId="" />
        {/* <ExamResults viewMore={true}  page="0.4" type="filter_by_exam" examId="6" companyId="" /> */}
        {/* <ExamResults viewMore={true}  page="0.4" type="filter_by_exam" examId="7" companyId="" /> */}
      </div>
    )
  }
}

export default PublicMain
