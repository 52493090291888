import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';
import { bigAlert, confirmAlert } from '../../../actions/SweetAlerts';
import { deleteData } from '../../../actions/MainAction';
import { getOneExamQuestion } from '../../../actions/StaffAction';
import { withHooks } from '../../../actions/withHooks';

export class ExamQuestionContainer extends Component {
    onEditBtn=(data)=>{
        let obj = {
            ...data,
            answer_options:data?.answers,
            position:true
        }
        this.props.changeStateValue({
            name:"poolingExamQuestionData",
            value:obj
        })
    }

    onDeleteBtn=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(alertResp=>{
            if(alertResp === "success"){
                this.props.deleteData(`pooling-exam-questions/${id}`)
                .then(resp=>{
                    if(resp==="success"){
                        bigAlert("Success!", "Question Deleted successfully!", "success");
                        this.props.getOneExamQuestion(this.props.params.id)
                    }else{
                        bigAlert("Error!", "Something went wrong!", "error")
                    }
                })
            }
        })
    }
  render() {
    const {poolingExamParentData} = this.props;
    // console.log(poolingExamParentData)
    return (
      <div>
        {
            poolingExamParentData?.questions?.map((data, i)=>{
                return(
                    <div className='flex justify-between bg-white items-center p-[10px] rounded-[10px] shadow mt-[20px]' key={i}>
                        <h3>{data?.title}</h3>
                        <div className='flex items-center gap-[20px]'>
                            <button onClick={this.onEditBtn.bind(this, data)} className='bg-orange text-[12px] font-semibold text-white p-[3px_7px] rounded-[6px]'>
                                Edit
                            </button>
                            <button onClick={this.onDeleteBtn.bind(this, data?.id)} className='bg-red text-[12px] font-semibold text-white p-[3px_7px] rounded-[6px]'>
                                Delete
                            </button>
                        </div>
                    </div>
                )
            })
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    poolingExamParentData: state.Data.poolingExamParentData
});
const mapDispatchToProps = {changeStateValue, deleteData, getOneExamQuestion}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamQuestionContainer))
