import React, { Component } from 'react'

export class DotsSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 12h.01M12 6h.01M12 18h.01m.99-6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0-12a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
      />
    </svg>
    )
  }
}

export default DotsSvg
