import React, { Component } from 'react'
import QuestionDescription from './QuestionDescription'
import Audio from './Audio'
import Video from './Video'
import Image from './Image';
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
export class SingleChoice extends Component {

  selectAnswer(parentIndex, index, item){
    this.props.changeStateValue({
      name: `examsData.${parentIndex}.student_exam_questions.${index}.given_answer`,
      value: item
    })
  }
  render() {
    const {data, index, parentIndex} = this.props;
    return (
      <div className='mt-[40px]'>
        <h4 className='txt-blue font-bold text-[18px]'>{data?.title}</h4>
        {
          data?.description?
          <QuestionDescription text={data?.description} />:null
        }
       
        {
          data?.audio_full_url?
          <Audio url={ data?.audio_full_url} />:null
        }
        
        {
          data?.video_full_url?
          <Video url={data?.video_full_url} />:null
        }
        {
          data?.image_full_url?
          <Image url={data?.image_full_url} />:null
        }
        <ul className='mt-[30px] flex flex-col gap-[10px] '>
          {
            data?.student_exam_question_answers?.map((item, i)=>{
              if(data?.question_type === 'single_image_choice' || data?.question_type === "single_audio_with_image"){
                return(
                  <li key={i} className='flex items-center gap-[10px] '>
                      <input checked={data?.given_answer?.id === item?.id} onChange={this.selectAnswer.bind(this, parentIndex, index, item)} name={data?.id} id={item?.id} type='radio' />
                      <label className='txt-light-black text-[14px] font-bold  w-full' htmlFor={item?.id} >
                          <img src={item?.answer_full_url} className='w-[140px] rounded-[6px]' />
                      </label>
                  </li>
                )
              }else{
                return(
                  <li key={i} className='flex items-center gap-[10px] '>
                    <input checked={data?.given_answer?.id === item?.id} onChange={this.selectAnswer.bind(this, parentIndex, index, item)} name={data?.id} id={item?.id}  type='radio' />
                    <label className='txt-light-black text-[14px] font-bold  w-full' htmlFor={item?.id} >{item?.title}</label>
                </li>
                )
              }
            })
          }
        </ul>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  examsData: state.Data.examsData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SingleChoice)
