import React, { Component } from 'react'
import Header from './Header';
import { connect } from 'react-redux';
import { getTeacherContracts } from '../../actions/StaffAction';
import FileInput from '../FileInput';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { insertNewData, deleteData } from '../../actions/MainAction';
import ContractPdfSvg from '../svg/ContractPdfSvg';
import MsgDeleteSvg from '../svg/MsgDeleteSvg';
import { resetStateValue, changeStateValue } from '../../redux/MainReducer';
import FileInputPDF from '../FileInputPDF';
import moment from 'moment';
export class TeacherContractModal extends Component {
    state={
        btnDisabled:false,
        contracts:[],
        page:'file',
        date: new Date()
    }
    componentDidMount(){
        // this.props.getTeacherContracts(this.props.teacherContractsModal?.teacher_id)
        // .then(resp=>{
        //     this.setState({contracts:resp})
        // })
    }
    saveBtn=(data, closeModal=()=>{}, type="save")=>{
     
        if((data?.file !=null || data?.image !=null) && data?.date !=""){
            let formData = new FormData();
            if(this.state.page == 'file'){
               formData.append("file", data?.file); 
            }else{
                formData.append("image", data?.image);
            }
            
            
            formData.append("teacher_id", this.props.teacherContractsModal?.teacher_id);
            formData.append("date", moment(new Date(this.props.teacherContractsModal?.date)).format("YYYY-MM-DD"));
            this.props.insertNewData('teacher-contracts', formData)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getTeacherContracts(this.props.teacherContractsModal?.teacher_id, this.props.teacherContractsModal?.date)
                    .then(contracts=>{
                        this.setState({contracts:contracts});
                        this.props.changeStateValue({ name:'teacherContractsModal.file', value:null  });
                        this.props.changeStateValue({ name:'teacherContractsModal.image', value:null  });
                        this.props.changeStateValue({ name:'teacherContractsModal.file_full_url', value:null  });
                        this.props.changeStateValue({ name:'teacherContractsModal.image_full_url', value:null  });
                        if(type=="save_close"){
                            this.closeBtn();
                            let formData = new FormData();
                            formData.append("_method", "put");
                            this.props.insertNewData(`teacher-contracts/${this.props.teacherContractsModal?.teacher_id}`, formData)
                            .then(resp=>{
                                mixinAlert("success", this.props.lang?.teacher_contract_submut)
                            })
                        }
                        // closeModal()
                    });
                    
                }else{
                    mixinAlert("error", "Something went wring.")
                }
            })
        }else{
            if(type=="save_close"){
                this.closeBtn();
                let formData = new FormData();
                formData.append("_method", "put");
                this.props.insertNewData(`teacher-contracts/${this.props.teacherContractsModal?.teacher_id}`, formData)
                .then(resp=>{
                    mixinAlert("success", this.props.lang?.teacher_contract_submut)
                })
                
            }else{
               mixinAlert("warning", "Please, upload file or select date") 
            }
            
        }
       
    }   

    deleteContract=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`teacher-contracts/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getTeacherContracts(this.props.teacherContractsModal?.teacher_id, this.props.teacherContractsModal?.date)
                    .then(contracts=>{this.setState({contracts:contracts})});
                    mixinAlert("success", "Contract deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
    closeBtn=()=>{
        this.props.resetStateValue({name:'teacherContractsModal'})
    }
    getData=(e)=>{
        this.props.changeStateValue({name:'teacherContractsModal.date', value:e.target.value});
        this.props.getTeacherContracts(this.props.teacherContractsModal?.teacher_id, e.target.value)
        .then(contracts=>{this.setState({contracts:contracts})});
    }
  render() {
    const {student, lang,  teacherContractsModal} = this.props;
    const {btnDisabled, contracts, page, date} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white w-[600px] rounded-[10px]'>
            <Header title={lang?.teacher_contract_title} closeBtn={this.closeBtn} />
            <div className='p-[20px]'>
                <div className='flex items-center gap-[20px] '>
                    <button onClick={()=>{this.setState({page:'file'})}} className={`text-[14px] font-semibold p-[3px_7px] rounded-[5px] ${page=="file"?'bg-blue text-white':'txt-blue border border-[var(--blue)]'}`}>PDF</button>
                    <button  onClick={()=>{this.setState({page:'image'})}}  className={`text-[14px] font-semibold p-[3px_7px] rounded-[5px] ${page=="image"?'bg-blue text-white':'txt-blue border border-[var(--blue)]'}`}>Image</button>
                </div>
                <div className='mt-[10px]'>
                    <input type='month' className='border outline-none rounded-[5px] ' onChange={this.getData.bind(this)} value={moment(teacherContractsModal?.date).format("YYYY-MM")} />
                </div>
                {
                    page=="file"?
                    teacherContractsModal?.file || teacherContractsModal?.file ==="" ||  teacherContractsModal?.file ===null? <FileInputPDF nameInp={teacherContractsModal?.file?.name} inpOtherType={true} type="application/pdf" title={"PDF Only"} valueName="teacherContractsModal.file_full_url" value={teacherContractsModal?.file_full_url} name="teacherContractsModal.file" />:null:
                    teacherContractsModal?.image || teacherContractsModal?.image ==="" ||  teacherContractsModal?.image ===null? <FileInput  inpOtherType={true} type="image/png, image/jpg, image/jpeg" title={'PNG, JPG, JPEG'} valueName="teacherContractsModal.image_full_url" value={teacherContractsModal?.image_full_url} name="teacherContractsModal.image" />:null

                }
                <div className='flex flex-wrap gap-[20px] mt-[20px]'>
                    {
                        contracts?.map((data, i)=>{
                           
                                return(
                                    <div key={i} className='group h-[70px]'>
                                        <a target='_blank' download={true} href={data?.file_full_url?data?.file_full_url:data?.image_full_url}>
                                            {
                                                data?.file_full_url?
                                                <ContractPdfSvg />:
                                                <img className='w-[40px] h-[40px] rounded-[5px]' src={data?.image_full_url} />
                                            }
                                            
                                        </a>
                                        {
                                            data?.status == 1?
                                            <button onClick={this.deleteContract.bind(this, data)} className='hidden group-hover:inline'>
                                                <MsgDeleteSvg color={'var(--red)'} />
                                            </button>:null
                                        }
                                        
                                    </div>
                                )
                            
                            
                        })
                    }
                </div>
                
                <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,teacherContractsModal, ()=>{})} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.upload}</button>
                    {
                        contracts.find(x=>x.status==1)?
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,teacherContractsModal, this.closeBtn, "save_close")} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.submit}</button>:
                        <button className='bg-gray-400 h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.submit}</button>

                    }
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang,
    teacherContractsModal: state.Data.teacherContractsModal,
});
const mapDispatchToProps = {getTeacherContracts, insertNewData, deleteData, resetStateValue, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherContractModal)


