import Swal from "sweetalert2";
import { CELTGO_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";
import axios from "axios";
const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("celtgo_token")}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
}


export const getCompanyInOuts=(company_id, year)=>async dispatch =>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${CELTGO_API}/one-company-in-out`, {
        headers: apiHeaders(),
        params:{year, company_id}
    }).then(resp=>{
        dispatch(changeStateValue({name:"celtgoInOuts", value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}

export const getWorkerInOuts=(company_id, year)=>async dispatch =>{
    dispatch(changeStateValue({name:"loader", value:true}))
    return await axios.get(`${CELTGO_API}/get-worker-expences`, {
        headers: apiHeaders(),
        params:{year, company_id}
    }).then(resp=>{
        dispatch(changeStateValue({name:"celtgoWorkerInOuts", value: resp?.data}))
    }).catch(err=>{
        dispatch(changeStateValue({name:"loader", value:false}))
    })
}