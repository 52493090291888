import React, { Component } from 'react'
import { connect } from 'react-redux';
import Header from '../../components/header/Header';
import { getParentStudents, getStudentsCheckins } from '../../actions/MainAction';
import DropDown from '../../components/DropDown';
import MonthlyCalendar from '../../components/MonthlyCalendar';
import moment from 'moment';
import Card1 from '../../components/cards/Card1';
import { mixinAlert } from '../../actions/SweetAlerts';
import EmptyPage from '../../components/EmptyPage';
export class ParentCheckin extends Component {
  state={
    teacherId: '',
    month: moment(new Date()).format("YYYY-MM")
  }
  componentDidMount(){
    this.props.getParentStudents();
    window.scrollTo(0,0)
  }
  selectTeacher=(id)=>{
    this.setState({teacherId:id});
    this.props.getStudentsCheckins(this.state.month, this.props.user?.id, id, this.props.user?.company_id)
  }
  selectMonth=(date)=>{
    this.setState({month:date})
    if(this.state.teacherId !== ""){
      this.props.getStudentsCheckins(date, this.props.user?.id, this.state.teacherId, this.props.user?.company_id)
    }
    
  }
  getCheckInTotal(data){
    let total = 0;
    for(const check_in of data.check_in){
        if(check_in.lesson_mode === data.lesson_mode && +data.student_group_id === +check_in.student_group_id){
            total= total+1;
        }
    }
    return total;
}


  render() {
    const {studentCheckIns, parentStudents, lang} = this.props;
    const {teacherId} = this.state;
    let arr = []
        for (let i = 0; i < parentStudents.length; i++){
            arr.push(...parentStudents[i].teacher)
        }
       
        function unikTeachers(data, key){
            return [ ... new Map(  data.map(x => [key(x), x]) ).values() ]
        }
    const teachers = unikTeachers(arr, it => it.teacher_id);
    let options = [];
    for(const data of teachers){
      options.push({
        title: data?.first_name+" "+data?.last_name,
        id: data?.teacher_id
      })
    }
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="check_ins" />
            <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <div className='flex gap-[20px] max-sm:flex-col-reverse items-center'>
                    <DropDown 
                      options={options}
                      placeholder={lang?.select_teacher}
                      onSelect={this.selectTeacher.bind(this)}
                    />
                    <MonthlyCalendar 
                      onSelect={this.selectMonth.bind(this)}
                    />
                </div>
                {studentCheckIns?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, select a teacher" subTitle="" /></div>:null}
                <div className='mt-[42px] grid-full-220'>
                {
                    studentCheckIns?.map((data, i)=>{
                      if(data.status === '1' || data.status === '0' && this.getCheckInTotal(data) !== 0){
                        return(
                          <Card1
                              key={i}
                              title={data?.lesson_mode}
                              name={data?.full_name}
                              time={this.getCheckInTotal(data)}
                              status={1}
                              statusBisible={false}
                              statusText={data?.status==1? lang?.pass: lang?.fail}
                              group={data.title?data.title:'One to One'}
                              btn={false}
                              url={`/check-in/${data?.student_id}/${teacherId}`}
                              blank={true}

                          />
                        )
                      }
                    })
                }
            </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentCheckIns: state.Data.studentCheckIns,
    parentStudents: state.Data.parentStudents,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getParentStudents, getStudentsCheckins}
export default connect(mapStateToProps, mapDispatchToProps)(ParentCheckin)
