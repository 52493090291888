import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getHwResults, getExamPartScore } from '../../actions/MainAction';
import {jwtDecode} from "jwt-decode";
import Card1 from '../../components/cards/Card1';
import moment from 'moment';
import { changeStateValue } from '../../redux/MainReducer';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Search from '../../components/Search';
import EmptyPage from '../../components/EmptyPage';
export class HwResults extends Component {
    state={
        results:[],
        search:'',
        studentId:''
    }

    async getData(studentId, page, keyword){
      return await this.props.getHwResults(studentId, page, keyword)
    }
    componentDidMount(){
        if(localStorage.getItem('userToken')){
          const data = jwtDecode(localStorage.getItem('userToken'));
          this.setState({studentId:data?.sub})
          this.getData(data.sub, 1, '')
          .then(resp=>{
            this.setState({results:resp})
          })
        }
        window.scrollTo(0,0)
    }
    showResultBtn=(data)=>{
        this.props.getExamPartScore({id:data?.id, type:'hw', exam_type:'current'})
        .then(resp=>{
          if(resp == 'success'){
            this.props.changeStateValue({  name:'partScoreModal.position', value:true });
            this.props.changeStateValue({  name:'partScoreModal.title', value:data?.exam_title});
          }
        })
    }

    loadMore=(page)=>{
      this.getData(this.state.studentId, page, this.state.search)
      .then(resp=>{
          let oldData= page ===1? []:[...this.state.results]
        this.setState({results:[
          ...oldData,
          ...resp
        ]})
      })
    }
    onSearchData=(search)=>{
      this.getData(this.state.studentId, 1, search)
      .then(resp=>{
        this.setState({results:[
          ...resp
        ]})
      })
    }
    onSelectStudent=(id)=>{
      this.setState({studentId:id})
      this.getData(id, 1, this.state.search)
      .then(resp=>{
        this.setState({results:[
          ...resp
        ]})
      })
    }
  render() {
    const {results} = this.state;
    const {user, lang} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="hws_rws" />
            <div className='flex justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
              <Search onSelectStudent={this.onSelectStudent} largWidth={true} student={user?.role == "parent"} onSearchData={this.onSearchData} />
            </div>
            {results?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
                {
                    results?.map((data, i)=>{
                        return(
                            <Card1 
                                key={i}
                                title={data?.exam?.course?.title}
                                exam={data?.exam_title}
                                grade={data?.score}
                                date={moment(data?.updated_at).format("HH:mm, DD/MM/YY")}
                                status={data?.status}
                                statusText={data?.status==1? lang?.pass: lang?.fail}
                                onCLickBtn={this.showResultBtn.bind(this, data)}
                                // time={moment(data?.updated_at).format("HH:mm")}
                                course={data?.exam?.course?.title}
                            />
                        )
                    })
                }
            </div>

            {
                results?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={results?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    hwResults: state.Data.hwResults,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getHwResults, getExamPartScore, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(HwResults)
