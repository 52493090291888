import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getSpeakingReviewByStudent, recheckSpeaking } from '../../../../actions/StaffAction';
import { withHooks } from '../../../../actions/withHooks';
import Search from '../../../../components/Search';
import Card1 from '../../../../components/cards/Card1';
import moment from 'moment';
import LoadMoreBtn from '../../../../components/LoadMoreBtn';
import { getSpeakingAnswer } from '../../../../actions/MainAction';
import { getGradeAll, getGrades, getRole } from '../../../../actions/getRole';
import { getTurboModel, getMistralModel } from '../../../../actions/robotActions';
import { insertNewData } from '../../../../actions/MainAction';
import { getSpeakingGpt, getSpeakingGradeMistral, getSpeakingMistral } from '../../../../actions/getReviewGpt';
import { changeStateValue } from '../../../../redux/MainReducer';
import EmptyPage from '../../../../components/EmptyPage';
export class SpeakingReviews extends Component {
    state={
        reviews:[],
        search:'',
        page:1
    }
    componentDidMount(){
        this.props.getSpeakingReviewByStudent(1, this.props.params.id, '')
        .then(resp=>{this.setState({reviews: resp})});
        window.scrollTo(0,0)
    }
    onSearchData=(search)=>{
        this.setState({search:search})
        this.props.getSpeakingReviewByStudent(1, this.props.params.id, search)
        .then(resp=>{this.setState({reviews: resp})})
    }
    getGrade(reviews, autoGrade, grade, text){
        if(!text){
            return grade
        }else{
            const check = text.split(' ');
            if(check.length<0){
                return grade
            }
        }
        if(reviews.length == 0){
            return grade
        }else{
            let grades = 0;
            for(const data of reviews){
                grades+=data.grade;
            }
            if(autoGrade){
                return  ((grades+autoGrade.grade)/(reviews.length+1)).toFixed(0)
               
            }else{
                return grade;
            }
        }
    }
    closeOpenBtn=(data)=>{
      
        if(this.props.user?.role == "head_teacher" || this.props.user?.role == "company_head"){
            if(data?.is_closed === 1){
                let formData = new FormData();
                formData.append("_method", "put")
                this.props.insertNewData(`speakingsAnswerUpdate/${data?.id}`, formData)
                .then(resp=>this.props.getSpeakingReviewByStudent(this.state.page, this.props.params.id, this.state.search).then(resp=>{this.setState({reviews: resp})}))
            }else{
                if(data?.grade !== null){
                    let formData = new FormData();
                    formData.append("_method", "put")
                    this.props.insertNewData(`speakingsAnswerUpdate/${data?.id}`, formData)
                    .then(resp=>this.props.getSpeakingReviewByStudent(this.state.page, this.props.params.id, this.state.search).then(resp=>{this.setState({reviews: resp})}))
                }
            }
        }
    }
    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getSpeakingReviewByStudent(page, this.props.params.id, this.state.search)
        .then(resp=>{this.setState({reviews: resp})})
    }
    matchDate(created_at){
        const date = moment(new Date(created_at)).add(1, 'week').format('YYYY/MM/DD');
        const newDate = new Date(date);
        const now = new Date();
       return newDate <= now;
    }

    getClosePosition(data){
        if(data?.speaking?.speaking_type == "midterm_end_course"){
            return false;
        }
        if(this.props.user?.role === "head_teacher"){
            if(data?.review.length === 0 ){
                return true;
            }else{
                return false;
            }
        }else if(this.props.user?.role === "teacher" || this.props.user?.role === "speaking_teacher"){
            if(data?.review.length === 0 || this.matchDate(data?.created_at)){
                return true;
            }else{
                return false;
            }
        }else{
            return false
        }
    }
    checkOpen(data){
        if(data?.review.length !==0){
            return false
        }else if(this.matchDate(data?.created_at)){
            return false
        }else{
            return true
        }
    }
    openModal=(data, type="")=>{
       
        this.props.getSpeakingAnswer(data?.speaking?.id, data?.user_id, data?.id)
        .then(resp=>{

            if(resp != 'error'){
                let check = 0;
                if(resp?.answare[0]?.text){
                    check = resp?.answare[0]?.text.split(' ')?.length;
                }
                const params = {
                    position:true,
                    type:'speaking',
                    modalType: 'teacher_review',
                    title: resp?.title,
                    question: resp?.question,
                    speaking: resp?.answare[0]?.image_full_url,
                    reviews: resp?.reviews[0]?.review,
                    teacher: resp?.reviews[0]?.user?.full_name,
                    role: getRole(resp?.reviews[0]?.user?.role, this.props.lang),
                    stuent: data?.user?.full_name,
                    robot_review: check>=0 && type==""? data?.robot_review:null,
                    grade: resp?.reviews[0]?.grade,
                    speaking_robo_waiting: check>=0 && !data?.robot_review? true:false,
                    not_check_robo_text:'',
                    is_closed: data?.is_closed,
                    // teacherEditor: (this.props.user?.role === "teacher" ||  this.props.user?.role === "head_teacher" ||  this.props.user?.role === "speaking_teacher") && resp?.reviews.length === 0 && data?.is_closed ===0?true:false,
                    // gradeInp: (this.props.user?.role === "teacher" ||  this.props.user?.role === "head_teacher" ||  this.props.user?.role === "speaking_teacher") && resp?.reviews.length === 0 && data?.is_closed ===0?true:false,
                    teacherEditor:  this.getClosePosition(data),
                    gradeInp:  this.getClosePosition(data),
                    speaking_type: data?.speaking?.speaking_type,
                    afterCallFunction: this.props.getSpeakingReviewByStudent,
                    funcParams: [this.state.page, this.props.params.id, this.state.search],
                    speaking_answer_id:data?.id,
                    grade_inp:'',
                    review_input:'',
                    user_id:this.props.user?.id
                }
                
                if( !data?.robot_review ||  type == "recheck"){
                    if(type == "recheck"){
                        setTimeout(()=>{
                            this.props.changeStateValue({
                                name:'mainModal.speaking_robo_waiting',
                                value:true
                            })
                        }, 500)
                    }
                    recheckSpeaking(data?.id)
                    .then(resp=>{
                        if(resp !==""){
                            this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade:resp?.grade, review: resp?.review}});
                            this.props.changeStateValue({name: 'mainModal.not_check_robo_text', value: ''});
                            this.props.getSpeakingReviewByStudent(this.state.page, this.props.params.id, this.state.search).then(resp=>{this.setState({reviews: resp})})
                        }else{
                            this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade:resp?.grade, review: resp?.review}});
                            this.props.changeStateValue({name: 'mainModal.not_check_robo_text', value: ''});
                        }
                    })
                   
                }else{
                   
                }
                this.props.changeStateValue({name: 'mainModal', value: params});
            }
        })
    }
  render() {
    const {reviews} = this.state;
    const {speakingByStudent, lang} = this.props;
    return (
      <div className='w-full'>
         <div className='flex justify-center '>
            <Search largWidth={true} onSearchData={this.onSearchData} />
         </div>
         {speakingByStudent.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
        <div className='w-full grid-full-250 mt-[20px]'>
        {
            speakingByStudent?.map((data, i)=>{
                return(
                    <Card1
                        key={i}
                        title={data?.speaking?.title}
                        name={data?.user?.full_name}
                        grade={getGradeAll(data.review,  data.robot_review, data.grade, data.text)}
                        date={ moment(data?.created_at).format("DD/MM/YYYY")}
                        // close={data.is_closed === 0? lang?.status_close: lang?.status_closed}
                        onLineClick={this.closeOpenBtn.bind(this, data)}
                        // status={data?.is_closed}
                        status={getGradeAll(data.review,  data.robot_review, data.grade, data.text) >=data?.speaking?.course_prompt?.grade?1:0}
                        statusText={getGradeAll(data.review,  data.robot_review, data.grade, data.text) >=data?.speaking?.course_prompt?.grade?lang?.pass: lang?.fail}
                        onCLickBtn={this.openModal.bind(this, data, "")}
                        // greenStatus={true}
                        reCheckBtn={(data?.robot_review?.grade==1  && data?.robot_review?.grade_error) && data?.text && data?.robot_review?.recheck_count <=3?true:false}
                        // reCheckBtn={true}
                        onReCheck={this.openModal.bind(this, data, "recheck")}
                    />
                )
            })
        }
        </div>
        {
            speakingByStudent?.length >=20?
            <LoadMoreBtn onLoadData={this.loadMore} count={speakingByStudent?.length} />:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    user: state.Data.user,
    speakingByStudent: state.Data.speakingByStudent,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getSpeakingReviewByStudent, getSpeakingAnswer, insertNewData, getTurboModel, changeStateValue, getMistralModel}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SpeakingReviews))
