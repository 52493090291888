import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import { insertNewData } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/SweetAlerts';
export class SendPrivateMsg extends Component {
    state={
        disable:false
    }
    changeInpValue=(e)=>{
        this.props.changeStateValue({
            name:'privateMsgModal.message',
            value: e.target.value
        })
    }

    sendMsg=(data)=>{
        if(data?.message && data?.message !=""){
            this.setState({disable:true})
            let formData = new FormData();
            for(const property in data){
                if(data[property]){
                    formData.append(property, data[property])
                }
            }
            formData.append("_method", "put")
            let url = `support-message/${data?.id}`;
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.props.afterCallFunction(this.props.afterCallParams)
                this.setState({disable:false})
                this.props.changeStateValue({
                    name:"privateMsgModal",
                    value:{
                        position:false
                    }
                })
                if(resp === "success"){
                    mixinAlert("success", "Private message sent successfully");
                }else{
                    mixinAlert("error", "Something went wrong");
                }
            })  
        }
    }
    closeBtn=()=>{
        this.props.changeStateValue({
            name:"privateMsgModal",
            value:{
                position:false
            }
        })
    }
  render() {
    const {disable} = this.state;
    const {lang, privateMsgModal} = this.props
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='bg-white shadow w-[400px] p-[20px] rounded-[10px]'>
            <h3>Write private message</h3>
            <textarea onChange={this.changeInpValue.bind(this)} value={privateMsgModal?.message} placeholder='Write a message' className='h-[150px] w-full resize-none outline-none bg-gray-100 mt-[10px] rounded-[10px] text-[14px] p-[5px]' />
            <div className='mt-[10px] flex justify-between'>
                <button onClick={this.closeBtn.bind(this)} disabled={disable} className='p-[6px] text-[14px] pt-[4px] pb-[4px] text-white font-semibold bg-red rounded-[6px]'>{lang?.close}</button>
                <button onClick={this.sendMsg.bind(this, privateMsgModal)} disabled={disable}  className='p-[6px] text-[14px] pt-[4px] pb-[4px] text-white font-semibold bg-blue rounded-[6px]'>{lang?.send}</button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang,
    privateMsgModal: state.Data.privateMsgModal,
});
const mapDispatchToProps = {changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(SendPrivateMsg)
