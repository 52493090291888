import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import Search from '../../components/Search'
import { connect } from 'react-redux'
import { getGroups } from '../../actions/AdminActions';
import LoadMoreBtn from "../../components/LoadMoreBtn"
import CardLine from '../../components/cards/CardLine'
import CardCheatingSvg from '../../components/svg/CardCheatingSvg';
import { changeStateValue } from '../../redux/MainReducer'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { deleteData } from '../../actions/MainAction'
export class AdminGroups extends Component {
    state={
        page:1,
        search:''
    }
    searchData=(search)=>{
        this.setState({search})
        this.props.getGroups(this.state.page,search)
    }
    loadMore=(page)=>{
        this.setState({page})
        this.props.getGroups(page, this.state.search)
    }
    componentDidMount(){
        this.props.getGroups(1, '')
    }
    deleteBtn=(id)=>{
        confirmAlert("warning",'Are you sure?', "You won't be able to revert this!")
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.deleteData(`studentgroups/${id}`)
                .then(resp=>{
                    if(resp=="success"){
                        mixinAlert("success", "Deleted successfully");
                        this.props.getGroups(this.state.page, this.state.search)
                    }else{
                        mixinAlert("error", "Something went wrong")
                    }
                })
            }
        })
    }
    addBtn=(data="")=>{
        this.props.changeStateValue({
            name: "adminModalData",
            value:{
                position: true,
                id: data?.id?data?.id:"",
                title: data?.title? data?.title:"",
                code: data?.code? data?.code:"#00FFF8FF",
                modalTitle: data?.id?"Edit Group":"Add Group",
                requireds: ["title"],
                afterCallFunction: this.props.getGroups,
                afterCallParams: [this.state.page, this.state.search],
                url: data?.id?`studentgroups/${data?.id}`:"studentgroups",
            }
        })
    }
  render() {
    const {adminGroups} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Groups" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Add a group", id:'0'}]} selected={0} />
            </div>
            <div className='flex justify-center'>
                <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
            </div>

            <div className='mt-[30px] grid-full-250'>
                {
                    adminGroups?.map((data, i)=>{
                        return(
                            <div className='bg-white p-[10px] rounded-[10px] shadow' key={i}>
                                <ul>
                                    <CardLine svg={<CardCheatingSvg />} title={data?.title} />
                                </ul>
                                <div className='w-full h-[50px] rounded-[10px] mt-[10px]' style={{ backgroundColor: data?.code }}></div>
                                <div className='flex justify-between mt-[10px]'>
                                    <button onClick={this.deleteBtn.bind(this, data?.id)} className='bg-red h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Delete</button>
                                    <button onClick={this.addBtn.bind(this, data)} className='bg-blue h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Edit</button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {
                adminGroups?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={adminGroups?.length} />:null
            }
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    adminGroups: state.Data.adminGroups
});
const mapDispatchToProps = {getGroups, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminGroups)
