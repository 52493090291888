import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import { getOnlineApplies } from '../../actions/StaffAction'
import { connect } from 'react-redux'
import moment from 'moment'
import { insertNewData } from '../../actions/MainAction'
import { mixinAlert } from '../../actions/SweetAlerts'

export class CompanyOnlineApplies extends Component {
    state={
        buttons:[
            {
                title:"Today",
                id:0
            },
            {
                title:"Yesterday",
                id:1
            },
            {
                title:"2 days ago",
                id:2
            },
            {
                title:"3 days agp",
                id:3
            },
            {
                title:"4 days agp",
                id:4
            },
            {
                title:"5 days agp",
                id:5
            },
        ],
        selectedBtn:0,

    }

    onGetData=(data)=>{
        this.setState({selectedBtn: data?.id})
        this.props.getOnlineApplies(data?.id)
    }
   componentDidMount(){
    this.props.getOnlineApplies(0)
   }

   changeApplyStatus=(id, e)=>{
    this.props.insertNewData("apply-by-company", {id: id, status: e.target.value})
    .then(resp=>{
        if(resp == "success"){
            mixinAlert("success", "Status changed successfully");
            this.props.getOnlineApplies(this.state.selectedBtn)
        }else{
            mixinAlert("error", "Something went wronge")
        }
    })
   }
  render() {
    const  {buttons, selectedBtn} = this.state;
    const {onlineAppliesData, lang} = this.props;
    let statusOption = [
        {
            label: lang?.waiting,
            value: 0
        },
        {
            label: lang?.apply_written,
            value: 1
        },
        {
            label:  lang?.apply_called,
            value: 2
        },
        {
            label:  lang?.apply_not_answer,
            value: 3
        },
        {
            label:  lang?.apply_wrong_number,
            value: 4
        },
    ]
    // console.log(onlineAppliesData)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="onlineApply" />
            <div className='mt-[30px]'>
                <HeaderButtons buttons={buttons} selected={selectedBtn} onClick={this.onGetData.bind(this)} />
            </div>
            <div className='grid grid-full-295 mt-[40px] gap-[20px]'>
                {
                    onlineAppliesData?.map((data, i)=>{
                        return (
                            <div key={i} className='bg-white p-[10px] rounded-[10px]'>
                                <ul className='flex flex-col gap-[6px]'>
                                    <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Name:</span> {data?.name}</li>
                                    <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Phone:</span> {data?.phone_number}</li>
                                    {
                                        data?.school_type?
                                        <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>School type:</span> {data?.school_type === "private_school"? "High School":data?.school_type === "secondary_school"? "Secondary School":data?.school_type === "primary_school"? "Primary School":""  }</li>:null
                                    }
                                    {
                                        data?.subject?
                                        <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Subject:</span> {data?.subject}</li>:null
                                    }
                                    
                                    {
                                        data?.company?
                                        <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Company:</span> {data?.company?.title}</li>:null
                                    }
                                    <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Apply date:</span> {moment(data?.created_at)?.format("DD/MM/YYYY")}</li>
                                    <li className='flex items-center gap-[5px] font-[300] text-[14px]'><span className='font-[600]'>Status:</span>
                                    <select onChange={this.changeApplyStatus.bind(this, data?.id)} className=' ourline-none border rounded-[6px] text-[14px]' value={data?.status}>
                                    {
                                        statusOption?.map((key, j)=>{
                                            return(
                                                <option  className='text-[14px]' key={j} value={key?.value}>{key?.label}</option>
                                            )
                                        })
                                    }
                                </select>
                                    </li>
                                </ul>
                                
                            </div>
                        )
                    })
                }
            </div>
         </div>
    )
  }
}
const mapStateToProps = (state) => ({
    onlineAppliesData: state.Data.onlineAppliesData,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getOnlineApplies, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyOnlineApplies)
