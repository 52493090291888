import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import { changeStateValue } from '../../redux/MainReducer'
import { deleteData } from '../../actions/MainAction'
import { getManagerCompanies } from '../../actions/StaffAction'
import { connect } from 'react-redux'
import { getCompanies, getManagers } from '../../actions/AdminActions'
import { getDropValue } from '../../actions/getRole'
import Card1 from '../../components/cards/Card1'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'

export class AdminManagerCompaniesPage extends Component {
    componentDidMount(){
        this.props.getManagerCompanies();
        this.props.getManagers(10, '');
        this.props.getCompanies(10, '')
    }
    addBtn=(data="")=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                user_id:data?.user_id? data?.user_id:'',
                company_id:data?.company_id? data?.company_id:'',
                managerOpt: getDropValue(this.props.adminManagers, "full_name", "id"),
                companyOption: getDropValue(this.props.adminCompanies, "name", "id"),
                afterCallFunction:this.props.getManagerCompanies,
                afterCallParams:[],
                requireds:["name", "country", "city"],
                url: data?.id? `manager-companies/${data?.id}`:'manager-companies',
                modalTitle: data?.id? `Edit company`:'Add company',
                overflow_def:true
            }
        });
    }
    onDeleteBtn=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`manager-companies/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getManagerCompanies();
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {managerCompanies} = this.props;
    // console.log(managerCompanies)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Manager companies" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Match company", id:'0'}]} selected={0} />
            </div>
            <div className='mt-[30px] grid-full-250'>
                {
                    managerCompanies?.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.company?.name}
                                name={data?.user?.full_name}
                                statusBisible={false}
                                status={1}
                                onCLickBtn={this.addBtn.bind(this, data)}
                                adminDelete={true}
                                onDeleteBtn={this.onDeleteBtn.bind(this, data)}
                                titleLink={`/audit-company/${data?.company?.id}/${data?.company?.name}`}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    managerCompanies: state.Data.managerCompanies,
    adminCompanies: state.Data.adminCompanies,
    adminManagers: state.Data.adminManagers,
});
const mapDispatchToProps = {changeStateValue, deleteData, getManagerCompanies, getManagers, getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(AdminManagerCompaniesPage)
