import React, { Component } from 'react'
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';

export class SingleChoice extends Component {
  onselectAnswer=(value)=>{
    this.props.changeStateValue({
      name:`examsData.${this.props.index}.questions.${this.props.questionIndex}.given_answer`,
      value: value
    })
  }
  render() {
    const {answers=[], id, item} = this.props;
    // console.log(item)
    return (
        <ul className='border-b pb-[20px] mb-[20px] flex flex-col gap-[10px]'>
            {
                answers?.map((data, i)=>{
                    return (
                         <li className='flex items-center gap-[5px]' key={i}>
                           <input className='w-[18px] h-[18px]' checked={item?.given_answer && item?.given_answer?.id == data?.id? true: false} onChange={this.onselectAnswer.bind(this, data, i)} name={`${id}`} id={data?.id} type='radio' />
                           <label className='text-[14px] font-[500] txt-light-black cursor-pointer w-full inline-block' htmlFor={data?.id}> {data?.title}</label>
                         </li>
                    )
                })
            }
           
        </ul>
    )
  }
}
const mapStateToProps = (state)=>({})
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SingleChoice)
