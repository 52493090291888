import React, { Component } from 'react'
import Header from './Header'
import Input from './components/Input'
import DropSearch from '../DropSearch'
import UserModalFooter from './components/UserModalFooter'
import { connect } from 'react-redux'
import { changeStateValue } from '../../redux/MainReducer'
import MultiSelect from '../MultiSelect'
import InputPhone from '../InputPhone'
import InpImage from './components/InpImage'
import AdminSelectStudent from './AdminSelectStudent'
import SendVerifyBtn from '../SendVerifyBtn'
export class UserModal extends Component {

  closeModal=()=>{
    this.props.changeStateValue({name: "userModalData", value:{position: false}});
  }
  changeStatus=(e)=>{
    this.props.changeStateValue({
      name: "userModalData.teacher_status",
      value: e.target.checked?'1':'0'
    })
  }
  render() {
    const {userModalData, officeAllStudents, lang, user} = this.props;
    let students = [];
    for(const data of officeAllStudents){
      students.push({
        label: data?.full_name,
        value: data?.id
      })
    }
    return (
      <div  className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
         <div className='bg-white w-[600px]  shadow bg-white rounded-[10px] '>
            <Header closeBtn={this.closeModal.bind(this)} title={userModalData?.title} />
            <div className='p-[20px] max-h-[70vh] overflow-auto'>
                { userModalData?.img? <InpImage name="userModalData.avatar_url" valueName={'userModalData.avatar_full_url'} value={userModalData?.avatar_full_url} />:null  }
                <div className='grid grid-cols-2 gap-[20px] max-[450px]:grid-cols-1'>
                  {  userModalData?.first_name || userModalData?.first_name ===""?  <Input  required name="userModalData.first_name"  value={userModalData?.first_name}  title={lang?.first_name} />:null  }
                  {  userModalData?.full_name || userModalData?.full_name ===""?  <Input  required name="userModalData.full_name"  value={userModalData?.full_name}  title={lang?.full_name}/>:null  }
                  {  userModalData?.last_name || userModalData?.last_name ===""?  <Input  required name="userModalData.last_name"  value={userModalData?.last_name}  title={lang?.last_name} />:null  }
                  {  userModalData?.role_options ? <DropSearch required value={userModalData.role} placeholder={lang?.select_a_role} options={userModalData?.role_options} name="userModalData.role" title={lang?.select_a_role} /> :null }
                 
                 
                  { user?.role !=="super_admin" && userModalData?.childs ? <MultiSelect selected={userModalData.childs} placeholder={lang?.students} options={students} name="userModalData.childs" title={lang?.students}  />:null }
                  {user?.role == "super_admin" &&  userModalData?.childs ?<AdminSelectStudent selected={userModalData.childs} placeholder={lang?.students} options={students} name="userModalData.childs"  />:null}
                  
                  {  userModalData?.email || userModalData?.email ===""?  <Input required  name="userModalData.email"  value={userModalData?.email}  title={lang?.email} type="email" />:null  }
                  {  userModalData?.phone_number || userModalData?.phone_number ===""?  <InputPhone required  name="userModalData.phone_number"  value={userModalData?.phone_number} type="tel" title={lang?.phone_number} />:null  }
                  {  userModalData?.second_phone_number || userModalData?.second_phone_number ===""?  <InputPhone  name="userModalData.second_phone_number"  value={userModalData?.second_phone_number} type="tel" title={lang?.phone_number} />:null  }
                  {  userModalData?.date_of_birth || userModalData?.date_of_birth ===""?  <Input   name="userModalData.date_of_birth"  value={userModalData?.date_of_birth} type="date" title={lang?.date_of_birth} />:null  }
                  {  userModalData?.password || userModalData?.password ===""?  <Input   name="userModalData.password"  value={userModalData?.password}  title={lang?.password} type="password" />:null  }
                  {  userModalData?.date || userModalData?.date ===""?  <Input required={true}  name="userModalData.date"  value={userModalData?.date}  title={lang?.date} type="date" />:null  }
                  {  userModalData?.course || userModalData?.course ===""?  <Input required={true}  name="userModalData.course"  value={userModalData?.course}  title={lang?.subject}  />:null  }
                  {  userModalData?.score || userModalData?.score ===""?  <Input required={true}  name="userModalData.score" type="number"  value={userModalData?.score}  title={lang?.Score}  />:null  }
                  {  userModalData?.monthly_date || userModalData?.monthly_date ===""?  <Input required={true}  name="userModalData.monthly_date" type="month"  value={userModalData?.monthly_date}  title={lang?.date}  />:null  }
                  {  userModalData?.title_input || userModalData?.title_input ===""?  <Input required={true}  name="userModalData.title_input" type="text"  value={userModalData?.title_input}  title={lang?.title}  />:null  }
                  {  userModalData?.bonus || userModalData?.bonus ===""?  <Input required={true}  name="userModalData.bonus" type="number"  value={userModalData?.bonus}  title={lang?.bonus}  />:null  }
                  {/* {
                    userModalData?.teacher_status || userModalData?.teacher_status ===""?
                    <div className='flex items-center gap-[10px]'>
                      <input onChange={this.changeStatus.bind(this)} checked={userModalData?.teacher_status == 1? true:false} type='checkbox' id='seniorCheckId' />
                      <label htmlFor='seniorCheckId'>{lang?.senior_teacher}</label>
                    </div>:null
                  } */}
                 <div className='flex items-end pb-[5px]'>
                  <SendVerifyBtn data={userModalData} />
                 </div>
                </div>
                <UserModalFooter closeBtn={this.closeModal.bind(this)} data={userModalData} />
            </div>
          
         </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  userModalData: state.Data.userModalData,
  officeAllStudents: state.Data.officeAllStudents,
  lang: state.Data.lang,
  user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(UserModal)
