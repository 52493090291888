import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Search from '../../components/Search'
import { connect } from 'react-redux'
import { getAllocations, getCompanies, getNotAssignedCourses, getNotAssignCompanies } from '../../actions/AdminActions'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import Card1 from '../../components/cards/Card1'
import { getDropValue } from '../../actions/getRole'
import DropSearch from '../../components/DropSearch'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { insertNewData, deleteData, getCourses } from '../../actions/MainAction'
import MultiSelect from '../../components/MultiSelect'
import { changeStateValue } from '../../redux/MainReducer'
export class AdminAllocations extends Component {
    state={
        page:1,
        search:'',
        company_id:'',
        course_id:'',
        btnDisable:false,
        selectedAllocates:[],
        selectedNonAllocates:[],
    }
    componentDidMount(){
        this.props.getAllocations(1, '');
        this.props.getCourses()
    }
    searchData=(search)=>{
        this.setState({search});
        this.props.getAllocations(this.state.page, search, this.state.course_id)
    }
    loadMore=(page)=>{
        this.setState({page});
        this.props.getAllocations(page, this.state.search, this.state.course_id)
    }

    onDeleteBtn=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`courseAssigns/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getAllocations(this.state.page, this.state.search, this.state.course_id)
                    if(this.state.course_id !==""){
                        this.props.getNotAssignCompanies(this.state.course_id)
                    }
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }

    selectCompany=(id)=>{   
        this.setState({company_id:id});
        this.props.getNotAssignedCourses(id)
    }
    selectCourse=(id)=>{
        this.setState({course_id:id});
        this.props.getNotAssignCompanies(id);
        this.props.getAllocations(this.state.page, this.state.search, id)
    }
    allocateBtn=(selectedCompanies, course_id)=>{
        if(selectedCompanies.length !=0&& course_id !=""){
            let companies = [];
            for(const x of selectedCompanies){
                companies.push(x.id)
            }
            this.setState({btnDisable:true})
            let formData = new FormData();
            formData.append('company_id', companies.join());
            formData.append('course_id', course_id);
            this.props.insertNewData('store-all-company', formData)
            .then(resp=>{
                this.setState({btnDisable:false});
                this.props.changeStateValue({name:'selectedCompanies', value:[]})
                if(resp === "success"){
                    this.props.getAllocations(this.state.page, this.state.search, this.state.course_id)
                    this.props.getNotAssignCompanies(course_id)
                    mixinAlert("success", "Allocate successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else{
            mixinAlert("warning", "Please select required fields.")
        }
    }
    allocateAll=(selectedCompanies, course_id, url)=>{
        if(selectedCompanies.length !=0 && course_id !=""){
            // let companies = [];
            // for(const x of selectedCompanies){
            //     companies.push(x.id)
            // }
    //    console.log(selectedCompanies, course_id)
            this.setState({btnDisable:true})
            let formData = new FormData();
            formData.append('company_id', selectedCompanies.join());
            formData.append('course_id', course_id);
            this.props.insertNewData(url, formData)
            .then(resp=>{
                // console.log(resp)
                this.setState({btnDisable:false});
                // this.props.changeStateValue({name:'selectedCompanies', value:[]})
                this.setState({selectedAllocates:[]})
                if(resp === "success"){
                    this.props.getAllocations(this.state.page, this.state.search, this.state.course_id)
                    this.props.getNotAssignCompanies(course_id)
                    mixinAlert("success", "Allocate successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else{
            mixinAlert("warning", "Please select required fields.")
        }
    }
    addToCompany=(company_id, course_id)=>{
        if(course_id !==""){
            this.setState({btnDisable:true})
            let formData = new FormData();
            formData.append("companie_id", company_id);
            formData.append("course_id", course_id);
            this.props.insertNewData('courseAssigns', formData)
            .then(resp=>{
                this.setState({btnDisable:false});
                if(resp === "success"){
                    this.props.getAllocations(this.state.page, this.state.search, this.state.course_id)
                    this.props.getNotAssignCompanies(course_id)
                    mixinAlert("success", "Allocate successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else{
            mixinAlert("warning", "Please select a course.")
        }
        

    }
    selectAllAllocatebtn=(adminAllocations)=>{
        let x = [];
        for(const data of adminAllocations){
            x.push(data?.companie_id)
        }
        this.setState({selectedAllocates:x})
    }
    selectAllNonAllocatebtn=(notAssignedCompanies)=>{
        let x = [];
        for(const data of notAssignedCompanies){
            x.push(data?.id)
        }
        this.setState({selectedNonAllocates:x})
    }

    checkExists(arr, id){
        return arr.includes(id)
    }

    onchangeCheck=(arr, id, name)=>{
        let newArr = [...arr]
        const check = newArr.findIndex(x=>x==id);
        if(check != -1){
            newArr.splice(check, 1)
        }else{
            newArr.push(id)
        }
        this.setState({
            [name]: newArr
        });
        // return 
    }
  render() {
    const {adminAllocations, selectedCompanies, notAssignedCompanies, studentCourses} = this.props;
    const {company_id, course_id, btnDisable, selectedAllocates, selectedNonAllocates} = this.state;
    // console.log(adminAllocations)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Allocation" />
            <div className='mt-[30px]  white-search flex justify-start items-start max-[1100px]:mt-[120px] gap-[30px] max-[800px]:mt-[180px]'>
                {/* <DropSearch selectData={this.selectCompany.bind(this)} required value={company_id} placeholder="Select a Company" options={getDropValue(adminCompanies, "name","id")} title="Select a Company" /> */}
                {/* <DropSearch selectData={this.selectCourse.bind(this)}  required value={course_id} placeholder="Select a Course" options={getDropValue(studentCourses, "title","id")} title="Select a Course" /> */}
                {/* {
                    notAssignedCompanies?.length !=0?
                    <div className='max-w-[600px]'>
                        <MultiSelect options={getDropValue(notAssignedCompanies, "name", "id")} name="selectedCompanies" selected={selectedCompanies} title="Select Companies" placeholder="Select Companies" />
                    </div>:null
                }
                
                <button disabled={btnDisable} onClick={this.allocateBtn.bind(this, selectedCompanies, course_id)} className='bg-blue mt-[25px] text-white text-[14px] font-semibold p-[7px] rounded-[5px]'>
                    Enroll a course
                </button> */}
                {/* {
                    course_id !==""?
                    <button disabled={btnDisable} onClick={this.allocateBtn.bind(this, notAssignedCompanies, course_id)} className='bg-blue mt-[25px] text-white text-[14px] font-semibold p-[7px] rounded-[5px]'>
                        Allocate all
                    </button> :null
                } */}
            </div>
            {/* <div className='flex justify-center'>
                <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
            </div> */}
            <div className='flex flex-wrap gap-[20px] mt-[20px]'>
            {
                studentCourses.map((data, i)=>{
                    return(
                        <button onClick={this.selectCourse.bind(this, data?.id)} key={i} className={`p-[10px_20px] bg-white shadow rounded-[8px] ${course_id === data?.id?"border border-[var(--blue)] border-[2px]":""}`}>
                            {
                                data?.title
                            }
                        </button>
                    )
                })
            }
            </div>
            {
                course_id !=""?
                <div>
                    <span className='block mt-[30px] text-[22px]'>Not allocated companies</span>
                  <div  className='flex items-center gap-[20px]'>
                    <button onClick={this.selectAllNonAllocatebtn.bind(this, notAssignedCompanies)} className='bg-blue mt-[25px] text-white text-[14px] font-semibold p-[7px] rounded-[5px]'>Select all</button>
                    {
                        selectedNonAllocates.length !=0?
                        <button onClick={this.allocateAll.bind(this, selectedNonAllocates, course_id, 'store-all-company')} className='bg-blue mt-[25px] text-white text-[14px] font-semibold p-[7px] rounded-[5px]'>Allocate all</button>:null
                    }
                  </div>
                    <div className='mt-[20px] grid-full-250'>
                        {
                            notAssignedCompanies?.map((data, i)=>{
                                return(
                                    <div>
                                         <input  onChange={this.onchangeCheck.bind(this, selectedNonAllocates, data?.id, "selectedNonAllocates")} 
                                         checked={this.checkExists(selectedNonAllocates, data?.id)} className='cursor-pointer' type='checkbox' />
                                        <Card1 
                                            key={i}
                                            title={data?.name}
                                            // exam={data?.course?.title}
                                            statusBisible={false}
                                            status={1}
                                            btn={false}
                                            btn2={true}
                                            btn2Color={'bg-blue'}
                                            btn2Text={"Allocate"}
                                            adminDelete={false}
                                            onCLickBtn={this.addToCompany.bind(this, data?.id, course_id)}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>:null
            }
            
            <span className='block mt-[30px] text-[22px]'>Allocated companies</span>
            <div className='flex items-center gap-[20px]'>
                <button onClick={this.selectAllAllocatebtn.bind(this, adminAllocations)} className='bg-blue mt-[25px] text-white text-[14px] font-semibold p-[7px] rounded-[5px]'>Select all</button>
                {
                    selectedAllocates?.length !=0?
                    <button onClick={this.allocateAll.bind(this, selectedAllocates, course_id, "delete-all-company")} className='bg-blue mt-[25px] text-white text-[14px] font-semibold p-[7px] rounded-[5px]'>Remove Allocate all</button>
                    :null
                }
            </div>
            <div className='mt-[20px] grid-full-250'>
                {
                    adminAllocations?.map((data, i)=>{
                        return(
                           <div>
                            <input onChange={this.onchangeCheck.bind(this, selectedAllocates, data?.companie_id, "selectedAllocates")} checked={this.checkExists(selectedAllocates, data?.companie_id)} className='cursor-pointer' type='checkbox' />
                                <Card1 
                                    key={i}
                                    title={data?.company?.name}
                                    exam={data?.course?.title}
                                    statusBisible={false}
                                    status={1}
                                    btn={false}
                                    btn2={true}
                                    btn2Text={"Edit"}
                                    adminDelete={true}
                                    onDeleteBtn={this.onDeleteBtn.bind(this, data)}
                                />
                           </div>
                        )
                    })
                }
            </div>
            {
                adminAllocations?.length >=40?
                <LoadMoreBtn onLoadData={this.loadMore} count={adminAllocations?.length} />:null
            }
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
    adminAllocations: state.Data.adminAllocations,
    adminCompanies: state.Data.adminCompanies,
    companyNotAssignedCourses: state.Data.companyNotAssignedCourses,
    notAssignedCompanies: state.Data.notAssignedCompanies,
    studentCourses: state.Data.studentCourses,
    selectedCompanies: state.Data.selectedCompanies,
});
const mapDispatchToProps = {getAllocations, getCompanies,getCourses,changeStateValue, getNotAssignedCourses, insertNewData, deleteData,getNotAssignCompanies }
export default connect(mapStateToProps, mapDispatchToProps)(AdminAllocations)
