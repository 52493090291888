import React, { Component } from 'react'
import logo from "../images/logo.png"
export class LoadingPage extends Component {
  render() {
    return (
      <div className='bg-[#f5f5f5] flex flex-col justify-center w-full items-center h-screen justify-center items-center'>
        <img className='w-[300px]' src={logo} alt="Logo" />
        <h3 className='txt-blue font-semibold mt-[30px] text-[24px] text-center'>Loading ...</h3>
      </div>
    )
  }
}

export default LoadingPage
