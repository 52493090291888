import React, { Component } from 'react'

export class CardStatisticSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={14}
        height={14}
        viewBox="0 0 50 50"
      >
        <g fill="#1E398F">
          <path d="M9.037 40.763h4.286a1 1 0 0 0 1-1v-7.314a1 1 0 0 0-1-1H9.037a1 1 0 0 0-1 1v7.314a1 1 0 0 0 1 1zm1-7.315h2.286v5.314h-2.286v-5.314zM21.894 40.763a1 1 0 0 0 1-1v-20.64a1 1 0 0 0-1-1h-4.286a1 1 0 0 0-1 1v20.64a1 1 0 0 0 1 1h4.286zm-3.286-20.64h2.286v18.64h-2.286v-18.64zM30.465 40.763a1 1 0 0 0 1-1V25.96a1 1 0 0 0-1-1H26.18a1 1 0 0 0-1 1v13.803a1 1 0 0 0 1 1h4.285zM27.18 26.96h2.286v11.803H27.18V26.96zM33.751 9.763v30a1 1 0 0 0 1 1h4.286a1 1 0 0 0 1-1v-30a1 1 0 0 0-1-1h-4.286a1 1 0 0 0-1 1zm2 1h2.286v28h-2.286v-28z" />
        </g>
      </svg>
    )
  }
}

export default CardStatisticSvg
