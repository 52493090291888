import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
export class FreeText extends Component {
    componentDidMount () {
        const input = document.querySelectorAll('.free_text_input');
        this.props.changeStateValue({
            name:'freetextExamAnswers',
            value: this.props.answers.student_exam_question_answers
        })
        if(input){
            input.forEach((x, i) => {
                x.addEventListener('input', (e) => {
                   const value = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "").replaceAll(',','').
                   replaceAll('.','').replaceAll('?','').replaceAll('!','').replaceAll('+','').
                   replaceAll('-','').replaceAll('{','').replaceAll('}','').replaceAll('(','').
                   replaceAll(')','').replaceAll('[','').replaceAll(']','').replaceAll(':','').
                   replaceAll(';','').replaceAll('"','').replaceAll("'",'').replaceAll('|','').
                   replaceAll('/','').replaceAll('_','').replaceAll('İ', 'I').replaceAll('i', 'i').toLowerCase().trim();
                    this.props.changeStateValue({
                        name:`freetextExamAnswers.${+e.target.getAttribute('aria-label')}.given_answer`,
                        value: value
                    })
                })
            })
        }
    }
  render() {
    const {answers, index,parentIndex } = this.props;
    let arr = []
    let ques = []
    for(let i = 0; i < answers.description.split('}}}').length; i++){
        if(answers.description.split('}}}')[i].includes('{{{')){
            
            arr.push(`${answers.description.split('}}}')[i].split('{{{')[0]} <input type="text" id='${answers.id}' style='width: ${(answers.student_exam_question_answers[i].title.length + 2)*10}px; min-width: 100px; max-width: 500px' value='${answers.student_exam_question_answers[i].given_answer === "N/A" ? "":answers.student_exam_question_answers[i].given_answer}' aria-label='${i}' class="free_text_input border rounded-[5px]"/> |`)
            ques.push(answers.description.split('}}}')[i].split('{{{')[1].split('|'))
        }else{
            arr.push(answers.description.split('}}}')[i])
        }
        
    }
    const str = String(arr).replaceAll('|,', '')

    return (
        <div className='free_text_main'>
            <h2 className='free_text_title'>{answers.title}</h2>
            <div className='free_text_container' dangerouslySetInnerHTML={{ __html:str }}></div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    freetextExamAnswers: state.Data.freetextExamAnswers
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(FreeText)
