import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getExamsQuestions, insertNewData, getLevelCheckExam } from '../../../actions/PublicActions';
import ParentDescription from '../../../components/exams/ParentDescription';
import SectionHeader from '../../components/SectionHeader';
import Audio from '../../../components/exams/Audio';
import Video from '../../../components/exams/Video';
import Image from '../../../components/exams/Image';
import QuestionDescription from '../../../components/exams/QuestionDescription';
import { partExamsScore } from '../../components/getLevel';
import { changeStateValue } from '../../../redux/MainReducer';
import { bigAlert } from '../../../actions/SweetAlerts';
import { withHooks } from '../../../actions/withHooks';
import { Link } from 'react-router-dom';
export class ListeningExam extends Component {
    state={
        level:1,
        level1Score:'',
        level2Score:'',
        level3Score:'',
        btnDisable:true,
        selecteds:[]
    }
    componentDidMount(){
        this.props.getExamsQuestions('listening', this.state.level);
        window.scrollTo(0,0)
    }
    selectAnswer=(item, selecteds)=>{
        const checkIndex = selecteds.findIndex(data=> data?.question_id == item?.question_id);
        if(checkIndex !== -1){
            selecteds.splice(checkIndex, 1)
        }
        selecteds.push(item);
        if(selecteds.length === this.props.examQuestions?.questions.length){
            this.setState({btnDisable:false})
        }
        this.setState({selecteds})
    }
    nextBtn=(selecteds)=>{
        this.props.changeStateValue({
            name:'examQuestions',
            value:''
        })
        let score = 0;
        for(let i = 0; i < selecteds.length; i++){
            if(selecteds[i]?.is_correct == '1'){
                score+=selecteds[i]?.score
            }
        }
        this.setState({selecteds:[], btnDisable:true})
        const params = partExamsScore(this.state.level, score);
        if(params.type === "contuniue"){
            this.setState({[`level${params?.level}Score`]: score, level: params?.level+1});
            this.props.getExamsQuestions('listening', params?.level+1);
            bigAlert("Done!", `You have passed previous level. Now you will take next level test`, "success");
        }else if(params.type === "complete"){
           
            let scores = {
                beginner_listening_score: this.state.level1Score,
                middle_listening_score: this.state.level2Score,
                advanced_listening_score: this.state.level3Score,
                listening_complete:'1',
                [`${params?.level==1? 'beginner_listening_score':params?.level==2? 'middle_listening_score':'advanced_listening_score'}`]: score
            }
            let formData = new FormData();
            for(const property in scores){
                if(scores[property])
                    formData.append(property, scores[property]);
            }
            this.props.insertNewData('set-exam-score',formData)
            .then(resp=>{
                bigAlert("Completed!", `Exam Completed successfully`, "success");
                this.props.getLevelCheckExam()
                this.props.navigate("/level-check")
            })
        }
    }
  render() {
    const {examQuestions} = this.props;
    const {btnDisable, selecteds} = this.state;
    return (
      <div className='pt-[130px] container ml-auto mr-auto'>
        <SectionHeader  line={false} title={examQuestions?.title} />
         {
            examQuestions !==""?
            <div className='bg-white shadow-[0_0px_4px_1px_rgba(0,0,0,0.25)] p-[20px] rounded-[10px]'>
                {
                    examQuestions?.description?
                    <ParentDescription text={ examQuestions?.description} />:null
                }
                {
                    examQuestions?.audio_full_url?
                    <Audio url={examQuestions?.audio_full_url} />:null
                }
                {
                    examQuestions?.video_full_url?
                    <Video url={examQuestions?.video_full_url} />:null
                }
                {
                    examQuestions?.image_full_url?
                    <Image url={examQuestions?.image_full_url} />:null
                }
                <div>
                    {
                        examQuestions?.questions?.map((data, i)=>{
                            return(
                                <div key={i} className='mt-[40px]'>
                                    <h4 className='txt-blue font-bold text-[18px]'>{data?.title}</h4>
                                    {
                                        data?.description?
                                        <QuestionDescription text={data?.description} />:null
                                    }
                                    {
                                        data?.audio_full_url?
                                        <Audio url={ data?.audio_full_url} />:null
                                    }
                                    
                                    {
                                        data?.video_full_url?
                                        <Video url={data?.video_full_url} />:null
                                    }
                                    {
                                        data?.image_full_url?
                                        <Image url={data?.image_full_url} />:null
                                    }
                                    <ul className='mt-[30px] flex flex-col gap-[10px] '>
                                        {
                                            data?.answers?.map((item, j)=>{
                                            return(
                                                <li key={j} className='flex items-center gap-[10px] '>
                                                    <input onClick={this.selectAnswer.bind(this, item, selecteds)} id={item?.id} name={data?.id} type='radio' />
                                                    <label className='txt-light-black text-[14px] font-bold  w-full cursor-pointer' htmlFor={item?.id} >{item?.title}</label>
                                                </li>
                                            )
                                            })
                                        }
                                    </ul>

                                    <span className='inline-block w-[40%] border-b border-dashed mt-[50px]'></span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='flex mt-[30px] justify-between'>
                    <Link className={`bg-red text-white pl-[8px] pr-[8px] h-[25px] rounded-[6px] text-[14px] font-semibold pt-[2px]`} to="/level-check">Back</Link>
                    <button disabled={btnDisable} onClick={this.nextBtn.bind(this, selecteds)} className={`${btnDisable?'bg-gray-500 cursor-not-allowed': 'bg-blue'} text-white pl-[8px] pr-[8px] h-[25px] rounded-[6px] text-[14px] font-semibold`}>Next</button>
                </div>
            </div>:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examQuestions: state.Data.examQuestions
});
const mapDispatchToProps = {getExamsQuestions, changeStateValue, insertNewData, getLevelCheckExam}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ListeningExam))
