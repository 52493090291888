import React, { Component } from 'react'

export class CardUnLockSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={9}
            height={10}
            fill="none"
        >
            <g clipPath="url(#a)">
            <path
                fill="#1E398F"
                d="M0 9.168c0 .22.092.433.256.589A.898.898 0 0 0 .875 10h7a.898.898 0 0 0 .619-.244.814.814 0 0 0 .256-.59V5.002a.814.814 0 0 0-.256-.59.898.898 0 0 0-.619-.243h-5.25V2.709c0-.442.184-.866.513-1.178a1.795 1.795 0 0 1 1.237-.488c.464 0 .91.175 1.237.488.329.312.513.736.513 1.178v.118c0 .138.058.27.16.368a.561.561 0 0 0 .387.153.561.561 0 0 0 .387-.153.509.509 0 0 0 .16-.368V2.71c0-.719-.3-1.407-.833-1.915A2.918 2.918 0 0 0 4.375 0c-.754 0-1.478.286-2.01.794A2.645 2.645 0 0 0 1.53 2.71v1.458H.875a.898.898 0 0 0-.619.244.814.814 0 0 0-.256.59v4.166Zm3.5-2.709c0-.144.04-.286.114-.411a.854.854 0 0 1 .313-.305.908.908 0 0 1 .865-.016.86.86 0 0 1 .326.292.805.805 0 0 1-.265 1.139l-.004.002s.086.491.182 1.07v.001a.305.305 0 0 1-.096.22.336.336 0 0 1-.232.092h-.657a.336.336 0 0 1-.232-.091.305.305 0 0 1-.096-.221L3.9 7.16a.852.852 0 0 1-.294-.302.803.803 0 0 1-.107-.398H3.5Z"
            />
            </g>
            <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h8.75v10H0z" />
            </clipPath>
            </defs>
        </svg>
        
    )
  }
}

export default CardUnLockSvg
