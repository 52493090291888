import React, { Component } from 'react'

export class ReportWaitingSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={20}
        height={20}
        viewBox="0 0 297 297"
        fill={"var(--orange)"}
      >
        <path d="M251.01 277.015h-17.683l-.002-31.558c0-31.639-17.358-60.726-48.876-81.901-3.988-2.682-6.466-8.45-6.466-15.055s2.478-12.373 6.464-15.053c31.52-21.178 48.878-50.264 48.88-81.904V19.985h17.683c5.518 0 9.992-4.475 9.992-9.993 0-5.518-4.475-9.992-9.992-9.992H45.99c-5.518 0-9.992 4.475-9.992 9.992 0 5.519 4.475 9.993 9.992 9.993h17.683v31.558c0 31.642 17.357 60.728 48.875 81.903 3.989 2.681 6.467 8.448 6.467 15.054s-2.478 12.373-6.466 15.053c-31.519 21.177-48.876 50.263-48.876 81.903v31.558H45.99c-5.518 0-9.992 4.475-9.992 9.993 0 5.519 4.475 9.992 9.992 9.992h205.02c5.518 0 9.992-4.474 9.992-9.992 0-5.518-4.475-9.992-9.992-9.992zM83.657 245.456c0-33.425 25.085-55.269 40.038-65.314 9.583-6.441 15.304-18.269 15.304-31.642s-5.721-25.2-15.305-31.642c-14.952-10.046-40.037-31.89-40.037-65.315V19.985h129.686l-.002 31.558c0 33.424-25.086 55.269-40.041 65.317-9.581 6.441-15.301 18.269-15.301 31.64s5.72 25.198 15.303 31.642c14.953 10.047 40.039 31.892 40.041 65.314v31.558h-3.312c-8.215-30.879-50.138-64.441-55.377-68.537a9.993 9.993 0 0 0-12.309 0c-5.239 4.095-47.163 37.658-55.378 68.537h-3.311v-31.558zm105.376 31.559h-81.067c6.584-15.391 25.383-34.873 40.534-47.76 15.152 12.887 33.95 32.369 40.533 47.76z" />
        <path d="M148.497 191.014c2.628 0 5.206-1.069 7.064-2.928 1.868-1.858 2.928-4.437 2.928-7.064s-1.06-5.206-2.928-7.065a10.072 10.072 0 0 0-7.064-2.927 10.067 10.067 0 0 0-7.064 2.927c-1.859 1.859-2.928 4.438-2.928 7.065s1.068 5.206 2.928 7.064a10.068 10.068 0 0 0 7.064 2.928zM148.5 138.019c5.519 0 9.992-4.474 9.992-9.992v-17.664c0-5.518-4.474-9.993-9.992-9.993s-9.992 4.475-9.992 9.993v17.664c0 5.518 4.473 9.992 9.992 9.992z" />
      </svg>
    )
  }
}

export default ReportWaitingSvg
