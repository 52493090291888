import React, { Component } from 'react'
import SearchSvg from '../svg/SearchSvg'
import { connect } from 'react-redux';
import { getDictionary } from '../../actions/StaffAction';
export class Dictionary extends Component {
  state={
    search:'',
    definition:[],
    example:[],
    position: false,
    openMobile:false
  }

  getInpValue=(e)=>{
    this.setState({search: e.target.value})
    if(e.target.value ===""){
      this.setState({ definition:[], example:[],position:false})
    }
  }
  openMobileBtn=(openMobile)=>{
    if(window.innerWidth<430){
      this.setState({openMobile:!openMobile})
    }
  }

  onSearch(search,e){
    e.preventDefault();
    if(search != '' && search.length >0){
      this.setState({position:true})
        this.props.getDictionary(search, "definitions")
        .then(resp=>{
          this.setState({definition: resp})
          // console.log(resp)
        })
       
    }
  }
  render() {
    const {lang} = this.props
    const {search, definition, example, position, openMobile} = this.state; 
    return (
      <div className='relative'>
        <form onSubmit={this.onSearch.bind(this, search)}  style={openMobile?{width:'200px', position:'absolute', top:0, marginTop:'-20px', zIndex:22}:{}} className='bg-light-gray w-[214px] h-[41px] rounded-[30px] flex items-center pl-[20px] gap-[5px] max-[500px]:w-[150px] search_cont'>
         <button onClick={this.openMobileBtn.bind(this, openMobile)}>
            <SearchSvg />
         </button>
          <input onBlur={()=>{this.setState({openMobile:false}); if(window.innerWidth<430){this.setState({search:'', definition:[], example:[], position:false})}}} style={openMobile?{display:'inline'}:{}}  onChange={this.getInpValue.bind(this)} value={search} placeholder={lang?.dictionary}  className='placeholder-txt-blue bg-light-gray outline-none w-[80%] text-[14px] txt-dark-gray' type='text' />
        </form>
        {
         position?
          <div className='absolute w-full w-[190%] p-[10px] max-[430px]:w-[80vw] rounded-[5px] z-50 shadow bg-white mt-[10px] max-h-[200px] overflow-auto'>
            {
                definition?.map((data, i)=>{
                  return(
                    data?.meanings?.map((item, j)=>{
                      if(item?.antonyms?.length !==0){
                       return(
                        <>
                         <span className='txt-blue font-semibold block mt-[14px]'>Antonyms</span>
                        {
                          item?.antonyms?.map((ant, k)=>{
                            return(
                              <li key={k}>{ant}</li>
                            )
                          })
                        }
                        </>
                       )
                      }
                      if(item?.definitions?.length !== 0){
                       return(
                        <>
                         <span className='txt-blue font-semibold block mt-[14px]'>Definitions</span>
                        {
                           item?.definitions?.map((ant, k)=>{
                            return(
                              <li key={k}>{ant?.definition}</li>
                            )
                          })
                        }
                        </>
                       )
                      }
                      if(item?.synonyms?.length !==0){
                       return(
                        <>
                         <span className='txt-blue font-semibold block mt-[14px]'>Synonyms</span>
                        {
                          item?.synonyms?.map((ant, k)=>{
                            return(
                              <li key={k}>{ant}</li>
                            )
                          })
                        }
                        </>
                       )
                      }
                    })

                  )
                })
            }
          </div>:null
        }
       
      </div>
    )
  }
}
const mapStateToProps = (state) =>({ lang: state.Data.lang});
const mapDispatchToProps = {getDictionary}
export default connect(mapStateToProps, mapDispatchToProps)(Dictionary)
