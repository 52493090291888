import React, { Component } from 'react'
import Header from '../../components/header/Header'
import MiniCalendar from '../../components/MiniCalendar'
import { connect } from 'react-redux'
import { getCompanyWiseReportsUngraded } from '../../actions/StaffAction'
import moment from 'moment'
import Card1 from '../../components/cards/Card1'
import Card2 from '../../components/cards/Card2'
import EmptyPage from '../../components/EmptyPage'
export class AssessmentUngraded extends Component {
    componentDidMount(){
        this.props.getCompanyWiseReportsUngraded(moment(new Date()).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"));
        window.scrollTo(0,0)
    }
    onSelectDate=(date)=>{
        this.props.getCompanyWiseReportsUngraded(date?.startDate, date?.endDate);
    }
    onClickBtn=()=>{
        localStorage.setItem("goRp", "reports")
    }
  render() {
    const {assesmentsUngraded, user, lang} = this.props;
    let result = assesmentsUngraded.reduce(function (r, a) {
        r[a.teacher_name] = r[a.teacher_name] || [];
        r[a.teacher_name].push(a);
        return r;
    }, {});
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="rp_ungraded" />
            <div className='flex mt-[30px] max-sm:justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <MiniCalendar onSelectDate={this.onSelectDate.bind(this)} />
            </div>
            {assesmentsUngraded.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please, change date to show ungradeds" subTitle="" /></div>:null}
            {/* <Search onSearchData={this.onSearchData} /> */}
            {
                Object.values(result).map((item, j)=>{
                    return(
                        <div key={j}>
                            {user?.role !== "teacher" && user?.role !== "speaking_teacher"? <h2 className='mt-[20px] font-semibold text-[25px]'>{item[0]?.teacher_name} ({item?.length})</h2>:null}
                            
                            <div className='mt-[12px] grid-full-220'>
                                {

                                    item?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map((data, i)=>{
                                        return(
                                            <Card2
                                                key={i}
                                                title={data?.student_name}
                                                subTitle={moment(data?.date).format("DD/MM/YYYY")}
                                                statusBisible={false}
                                                downloadUrl={``}
                                                btnTitle={lang?.go_to_reports}
                                                target="_blank"
                                                btnUrl={`/students/${data?.user_id}`}
                                                onClickBtn={this.onClickBtn.bind(this)}
                                            />
                                        )
                                    })
                                }
                            
                            </div>
                        </div>
                    )
                })
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    assesmentsUngraded: state.Data.assesmentsUngraded,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getCompanyWiseReportsUngraded}
export default connect(mapStateToProps, mapDispatchToProps)(AssessmentUngraded)
