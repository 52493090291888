import React, { Component } from 'react'
import Header from './Header'
import UploadSvg from '../svg/UploadSvg'
import PdfSvg from '../svg/PdfSvg'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer'
import { deleteData, insertDataWithParams } from '../../actions/MainAction'
import { connect } from 'react-redux'
import UploadExamSvg from '../svg/UploadExamSvg'
import Pdf2Svg from '../svg/Pdf2Svg'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'

export class SeniorModal extends Component {
    state={
        btnDisable:false
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"seniorModalData"})
    }
    removeBtn=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`senior-status/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.changeStateValue({ name:"seniorModalData.file_full_url", value: ""});
                    this.props.changeStateValue({ name:"seniorModalData.file", value: "" });
                    this.props.changeStateValue({ name:"seniorModalData.file_type", value: "" });
                    this.props.changeStateValue({ name:"seniorModalData.id", value: "" });
                    this.props.seniorModalData?.getAfterChange()
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }

    uploadFile=(seniorModalData, e)=>{
        let formData = new FormData();
        formData.append("teacher_id", seniorModalData?.teacher_id);
        formData.append("file", e.target.files[0]);
        formData.append("file_type", e.target.files[0]?.type)
        let url = "senior-status"
        if(seniorModalData?.id && seniorModalData?.id !="" ){
            url=`senior-status/${seniorModalData?.id}`
            formData.append("_method", "put")
        }
        this.props.insertDataWithParams(url, formData)
        .then(resp=>{
            if(resp?.msg == "success"){
             
                this.props.changeStateValue({ name:"seniorModalData.file_full_url", value: resp?.data?.data?.file_full_url });
                this.props.changeStateValue({ name:"seniorModalData.file", value: resp?.data?.data?.file });
                this.props.changeStateValue({ name:"seniorModalData.file_type", value: resp?.data?.data?.file_type });
                this.props.changeStateValue({ name:"seniorModalData.id", value: resp?.data?.data?.id });
                seniorModalData?.getAfterChange();
                mixinAlert("success", "data upload successfully");
            }else{
                mixinAlert("error","something went wrong.");
            }
        })
    }
  render() {
    const {seniorModalData} = this.props;
    const {btnDisable} = this.state;
    // console.log(seniorModalData)
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='w-[500px] '>
            <Header title="Upload senior status" closeBtn={this.closeModal.bind(this)} />
            <div className='bg-white p-[20px] rounded-b-[10px]'>
               <div className='flex flex-col justify-center items-center w-full'>
                    <label htmlFor='cvFileUpload' className='cursor-pointer bg-gray-100 inline-flex w-[80px] h-[80px] justify-center items-center rounded-full'>
                        <UploadExamSvg color={"#000"} />
                    </label>
                    <input onChange={this.uploadFile.bind(this, seniorModalData)} id="cvFileUpload" type='file' accept='image/*, application/pdf' className='hidden' />
                    {
                        seniorModalData?.file_type ==="application/pdf"?
                        <a className='mt-[20px] inline-block' target='_blank' href={seniorModalData?.file_full_url}>
                        <Pdf2Svg />
                        </a>:
                        <a  className='mt-[20px] inline-block' target='_blank'  href={seniorModalData?.file_full_url}>
                        <img src={seniorModalData?.file_full_url} className='max-w-[200px] mt-[20px]' />
                        </a>
                        
                    }
                    {
                seniorModalData?.file ?
                <button disabled={btnDisable} onClick={this.removeBtn.bind(this, seniorModalData?.id)} className='mt-[20px] bg-orange text-[12px] text-white h-[30px] p-[0_10px] rounded-full'>
                  Delete
                </button>:null
              }
               </div>
               <div>
                <button onClick={this.closeModal.bind(this)} className='bg-blue h-[25px]  pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Save</button>
               </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    seniorModalData: state.Data.seniorModalData
});
const mapDispatchToProps = {changeStateValue, deleteData, resetStateValue, insertDataWithParams}
export default connect(mapStateToProps, mapDispatchToProps)(SeniorModal)
