import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import PublicCard4 from '../components/cards/PublicCard4'
import AcceptedStudents from '../sections/AcceptedStudents'
import { connect } from 'react-redux'
import { getSingleCompany } from '../../actions/PublicActions'
import { withHooks } from '../../actions/withHooks'
import SingleCenterPhoneSvg from '../components/svg/SingleCenterPhoneSvg'
import SingleCenterOfficeSvg from '../components/svg/SingleCenterOfficeSvg'
import SingleCenterInstagramSvg from '../components/svg/SingleCenterInstagramSvg'
import SingleCenterFbSvg from '../components/svg/SingleCenterFbSvg'
import SingleCenterLocationSvg from '../components/svg/SingleCenterLocationSvg'
import ExamResults from '../sections/ExamResults'
import SingleCenterWhatsappSvg from '../components/svg/SingleCenterWhatsappSvg'
export class SingleCenterPage extends Component {
  state={
    companyData:''
  }
  componentDidMount(){
    this.props.getSingleCompany(this.props.params.id).then(resp=>this.setState({companyData:resp}));
    window.scrollTo(0,0)
  }
  render() {
    const {companyData} = this.state;
    return (
        <section className='pt-[110px]'>
            <SectionHeader title={companyData?.title} />
            {
              companyData !==""?
              <div className='container ml-auto mr-auto grid grid-cols-5 gap-[10px] max-[1053px]:grid-cols-3 max-[685px]:grid-cols-2 max-[1053px]:pl-[20px] max-[1053px]:pr-[20px] max-[520px]:grid-cols-1'>
                <PublicCard4 svg={ <SingleCenterPhoneSvg />} title={companyData?.phone_number} url={`tel:${companyData?.phone_number}`} />
                <PublicCard4 svg={ <SingleCenterWhatsappSvg />} title={"Whatsapp"} url={`https://wa.me/+994${companyData?.phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")}`}/>
                <PublicCard4 svg={ <SingleCenterInstagramSvg />} title={"Instagram"} url={companyData?.instagram}/>
                <PublicCard4 svg={ <SingleCenterFbSvg />} title={"Facebook"} url={companyData?.facebook}/>
                <PublicCard4 svg={ <SingleCenterLocationSvg />} title={"Location"} url={companyData?.location}/>
              </div>:null
            }
            {
              companyData !==""?
              <>
                <AcceptedStudents page={0.4} loadPage={8} companyId={companyData?.id} />
                <ExamResults page={0.4}  loadPage={8} type="filter_by_exam" examId="1" companyId={companyData?.id} />
                <ExamResults page={0.4}  loadPage={8} type="filter_by_exam" examId="2" companyId={companyData?.id} />
                <ExamResults page={0.4}  loadPage={8} type="filter_by_exam" examId="3" companyId={companyData?.id} />
                <ExamResults page={0.4}  loadPage={8} type="filter_by_exam" examId="4" companyId={companyData?.id} />
                <ExamResults page={0.4}  loadPage={8} type="filter_by_exam" examId="5" companyId={companyData?.id} />
                <ExamResults page={0.4}  loadPage={8} type="filter_by_exam" examId="6" companyId={companyData?.id} />
                <ExamResults page={0.4}  loadPage={8} type="filter_by_exam" examId="7" companyId={companyData?.id} />
              </>:null
            }
            
        </section>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {getSingleCompany}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SingleCenterPage))
