import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCertificates } from '../../../../actions/MainAction';
import { withHooks } from '../../../../actions/withHooks';
import Card2 from '../../../../components/cards/Card2';
import HeaderButtons from '../../../../components/HeaderButtons';
import { changeStateValue } from '../../../../redux/MainReducer';
import EmptyPage from '../../../../components/EmptyPage';
export class Certificates extends Component {
    state={
        buttons:[
            {
                title: this.props.lang?.create_pearson_cert,
                id:0,
            },
            // {
            //     title: this.props.lang?.create_unit_cert,
            //     id:1,
            // }
        ]
    }
    componentDidMount(){
        this.props.getCertificates(this.props.params.id, '');
        window.scrollTo(0,0)
    }
    openModal=(data)=>{
        this.props.changeStateValue({
          name: "userModalData",
          value:{
              position: true,
              id: "",
              title:"Generate Certificate",
              date:'',
              course:'',
              score:'',
              unRequireds: ["id", "unRequireds", "afterCallFunction", "funcParams"],
              afterCallFunction: this.props.getCertificates,
              funcParams: [this.props.params.id, ''],
              student_id: this.props.params.id,
              student_name: this.props.studentName,
              url:data?.id === 0? "student-manual-certificate-high": "student-manual-certificate",
              version:'v2'
          }
      })
    }
  render() {
    const {buttons} = this.state;
    const {certificates, user} = this.props;
    return (
      <div className='w-full'>
        {
        user?.role !== "head_teacher" && user?.role !== "teacher"  && user?.role !== "speaking_teacher"?
        <HeaderButtons padding={true} onClick={this.openModal.bind(this)} selected={2} buttons={buttons} />:null
        }
        {certificates.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
         <div className='mt-[42px] grid-full-205'>
            {
              certificates?.map((data, i)=>{
                return(
                  <Card2
                    key={i}
                    title={data?.course_type}
                    download={true}
                    downloadUrl={data?.certificate_full_url}
                  />
                )
              })
            }
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  certificates: state.Data.certificates,
  user: state.Data.user,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getCertificates, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Certificates))
