import React, { Component } from 'react'
import AccountantCompanies from '../accountant_role/AccountantCompanies'
import Header from '../../components/header/Header'

export class AdminCompanyPays extends Component {
  render() {
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
        <Header title="Company Pays" />
            <div className='mt-[42px] flex gap-[31px] flex-col'>
                <AccountantCompanies />
            </div>
      </div>
    )
  }
}

export default AdminCompanyPays
