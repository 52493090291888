import React, { Component } from 'react'
import Header from './Header'
import Input from './components/Input'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import { getGroupsLink, getOfficeStudents } from '../../actions/StaffAction';
import { insertNewData } from '../../actions/MainAction';
import { mixinAlert } from '../../actions/SweetAlerts';

export class TeacherCreateGroupModal extends Component {
    state={
        btnDisabled:false
    }
    componentDidMount(){
        this.props.getOfficeStudents(10, '', this.props.user?.company_id, '', '', '', this.props.user?.role=="speaking_teacher"?'':this.props.user?.id);  
    }

    checkCheckbox(students, id){
        return students.includes(id)
    }
    addToData(id, e){
        let arr= [...this.props.createGroupModal?.students];
        const checkIndex = arr.findIndex(x=>x == id);
        if(checkIndex !== -1){
            arr.splice(checkIndex, 1)
        }else{
            arr.push(id)
        }
       this.props.changeStateValue({
        name:"createGroupModal.students",
        value: arr
       })
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"createGroupModal"})
    }

    saveBtn=(data)=>{
        if(data?.title !== "" && data?.students.length !== 0){
            this.setState({btnDisabled:true})
            let formData = new FormData();
            formData.append("students", JSON.stringify(data?.students));
            formData.append("title", data?.title);
            let url = "teacher-group";
            if(data?.id && data?.id !=""){
                formData.append("_method", "put");
                url=`teacher-group/${data?.id}`;

            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisabled:false})
                if(resp == "success"){
                    this.props.getGroupsLink()
                    mixinAlert("success", "Data saved successfully");
                    this.closeModal()
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else{
            mixinAlert("warning", "You missed the write title or don't select a student.")
        }
        console.log(data)
    }
  render() {
    const {createGroupModal, officeStudents, lang} = this.props;
    const {btnDisabled} = this.state;
    console.log(officeStudents)
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='bg-white w-[500px] rounded-[10px]'>
            <Header closeBtn={this.closeModal.bind(this)} title="Create a group" />
            <div className='p-[20px]'>
                <Input required type="text" value={createGroupModal?.title} name="createGroupModal.title" title='Name' />
                <div className='flex flex-col gap-[5px] mt-[10px] bg-gray-100 rounded-[5px] p-[10px] max-h-[60vh] overflow-auto'>
                    {
                        officeStudents?.map((data, i) => {
                            return (
                                <div key={i} className='flex items-center gap-[6px] bg-white p-[5px] rounded-[3px]'>
                                    <input onChange={this.addToData.bind(this, data?.id)} checked={this.checkCheckbox(createGroupModal?.students, data?.id)} type='checkbox' id={data?.id} />
                                    <label className='w-full cursor-pointer' htmlFor={data?.id}>{data?.full_name}</label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this, createGroupModal)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save}</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    createGroupModal: state.Data.createGroupModal,
    user: state.Data.user,
    officeStudents: state.Data.officeStudents,
    lang: state.Data.lang,
});
const mapDispatchToProps = {resetStateValue, getOfficeStudents, changeStateValue, insertNewData, getGroupsLink}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherCreateGroupModal)
