import React, { Component } from 'react'
import MonthlyCalendar from '../../../components/MonthlyCalendar'
import { connect } from 'react-redux'
import { getUserData, getTeacherSalary, getLessonModes, getOfficeAllStudents, getGroupsByTeacher } from '../../../actions/StaffAction'
import { withHooks } from '../../../actions/withHooks'
import moment from 'moment';
import {SalaryCounting} from '../../../actions/salaryCounting'
import Card5 from '../../../components/cards/Card5'
import { bigAlert, confirmAlert, mixinAlert } from '../../../actions/SweetAlerts'
import { insertNewData } from '../../../actions/MainAction'
import AddStudentToExistsGroupModal from '../../../components/modals/AddStudentToExistsGroupModal'
import HeaderButtons from '../../../components/HeaderButtons';
import { changeStateValue } from '../../../redux/MainReducer'
import TeacherEnrollModal from '../../../components/modals/TeacherEnrollModal'
import EnrollCheckInModal from '../../../components/modals/EnrollCheckInModal'
import Header from '../../../components/modals/Header'
import Search from '../../../components/Search'
export class TeacherAssignModal extends Component {
    state={
        teacherData:'',
        date: moment(new Date()).format("YYYY-MM-DD"),
        salaryCounting: new SalaryCounting(),
        addStudentModal:{
            position:false,
            company_id:'',
            current_student:[],
            lesson_mode_id:'',
            lesson_mode:'',
            student_group_id:'',
            study_mode:'',
            lesson_houre:'',
            teacher_id:'',
        },
        checkinsModal:{
            position: false
        },
        search:''
    }
    componentDidMount(){
        this.props.getUserData(this.props.teacherId)
        .then(resp=>{
            this.setState({teacherData: resp});
            this.props.getTeacherSalary(resp?.id, this.state.date)
        });
        this.props.getLessonModes()
        .then(resp=>{
            this.setState({salaryCounting:  new SalaryCounting(0, resp)})
        })
    }
    selectMonth=(date)=>{
        this.setState({date: date})
        this.props.getTeacherSalary(this.state.teacherData?.id, date)
    }
    callAfterChange=()=>{
        this.props.getTeacherSalary(this.state.teacherData?.id, this.state.date)
    }
    onClickLockUnlock=(data,type, status)=>{
        if(type === "group"){
            confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, Lock it")
            .then(alertResp=>{
                if(alertResp === "success"){
                    let formData = new FormData();
                    let url='teacher-group-active-passive';
                    formData.append('group',  JSON.stringify(data));
                    formData.append('date', moment(this.state.date).format("YYYY-MM"));
                    formData.append('status', status);
                    this.props.insertNewData(url, formData)
                    .then(resp=>{
                        if(resp === "success"){
                            this.props.getTeacherSalary(this.state.teacherData?.id, this.state.date)
                            bigAlert("Success", "Group locked successfully", "success");
                        }else{
                            bigAlert("Error", "Something went wrong", "error")
                        }
                    })
                }
            })
        }else{
            let formData = new FormData();
            let url=`teacher-enroll-active-passive/${data.id}`
            formData.append('status', status);
            formData.append('_method', 'put');
            formData.append('check_in', JSON.stringify(data.check_in));
            formData.append('study_mode', data.study_mode)
            formData.append('lesson_mode', data.lesson_mode)
            formData.append('date', moment(this.state.date).format("YYYY-MM"));
            formData.append('student_group_id', data.student_group_id);
            formData.append('teacher_id', this.props.teacherId);
            confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", `Yes, ${status === '0'?'Lock':'Unlock'} it`)
            .then(alertResp=>{
                if(alertResp === "success"){
                  
                    this.props.insertNewData(url, formData)
                    .then(resp=>{
                        if(resp === "success"){
                            this.props.getTeacherSalary(this.state.teacherData?.id, this.state.date)
                            bigAlert("Success", `Student ${status === '0'?'Locked':'Unlocked'} successfully`, "success");
                        }else{
                            bigAlert("Error", "Something went wrong", "error")
                        }
                    })
                }
            })
        }
        
    }

    addStudentBtn=(data)=>{
        this.props.getOfficeAllStudents(this.props.user?.company_id);
        let students = [];
        for(const item of data){students.push(item?.student)}
        const item = data.filter(x=>+x.status==1);
        this.setState({
            addStudentModal:{
                position:true,
                company_id:this.props.user?.id,
                current_student: students,
                lesson_mode_id: item[0]?.lesson_mode_id,
                lesson_mode: item[0]?.lesson_mode,
                student_group_id: item[0]?.student_group_id,
                study_mode: item[0]?.study_mode,
                lesson_houre: item[0]?.lesson_houre,
                fee: item[0]?.fee,
                course_id: item[0]?.course_id,
                teacher_id: this.props.teacherId,
                student_id: this.props.params.id,
                exists:true,
                student_name:this.props.student?.full_name,
                count:'',
                file: null,
                image:null,
                file_full_url: null,
                image_full_url:null,
            }
        })
    }

    addStudent=(data, type, groups="")=>{
        if(data?.id){
            mixinAlert("warning", this.props.lang?.alert_edit_fee)
            // 
            return
        }
        this.props.getOfficeAllStudents(this.props.user?.company_id);
        this.props.getGroupsByTeacher(this.props.teacherId);
        this.props.changeStateValue({
            name:'teacherEnrollModalData',
            value: {
                position: true,
                id: data?.id?data?.id:'',
                company_id:this.props.user?.company_id,
                check_in: data?.check_in?data?.check_in:[],
                group_id: data?.student_group_id?data?.student_group_id:'',
                group_name: data?.group_name?data?.group_name:'',
                lesson_hour: data?.lesson_houre?data?.lesson_houre:'',
                lesson_mode: data?.lesson_mode?data?.lesson_mode:'',
                lesson_mode_id: data?.lesson_mode_id?data?.lesson_mode_id:'',
                fee: data?.fee?data?.fee:'',
                course_id: data?.course_id?data?.course_id:'',
                student_id: this.props.params.id,
                study_mode: data?.study_mode?data?.study_mode:'',
                teacher_id:this.props.teacherId,
                exists:data?.id?true:false,
                student_name: this.props.student?.full_name,
                group_lock: type==="group"?true:false,
                groups: groups!==""?groups:[],
                studentExists:true,
                count: '',
                note: '',
                file: null,
                image:null,
                file_full_url: null,
                image_full_url:null,
            }
        })
    }
    showCheckins=(data)=>{
        this.setState({checkinsModal:{position:true, ...data}})
    }
    getFilterByDate(data){
        let x = [...data].sort((x, y)=> {  return y[y.length-1]?.id - x[x.length-1]?.id});
        return x
    }
    onSearchData=(search)=>{
        this.setState({search})
    }
  render() {
    const {teacherData, salaryCounting, addStudentModal, checkinsModal, search} = this.state;
    const {teacherSalary, teacherEnrollModalData, lang} = this.props;
    let result = [];
    if(teacherSalary.check_ins){
       result = teacherSalary.check_ins.reduce(function (r, a) {
        r[a.group_name] = r[a.group_name] || [];
        r[a.group_name].push(a);
        return r;
      }, {});
    }
    // console.log(teacherSalary)
    return (
       <div className='background_shadow flex items-center justify-center p-[20px] '>
           <div className='bg-white max-h-[80vh] w-[1066px] overflow-auto rounded-[10px] pb-[20px]'>
            <Header title={teacherData?.full_name} closeBtn={()=>this.props.closeBtn()} />
            <div className='w-full max-sm:pl-[10px] max-sm:pr-[10px] pl-[27px] pr-[27px] '>
                
                <div className='flex mt-[20px] justify-between max-[400px]:flex-col-reverse items-center'>
                    <MonthlyCalendar
                        onSelect={this.selectMonth.bind(this)}
                    />
                    <HeaderButtons onClick={this.addStudent.bind(this)} buttons={[{title: lang?.add_a_student, id:0}]} />
                </div>
                <div className='flex justify-center  max-[1100px]:mt-[120px] max-[800px]:mt-[180px] '>
                    <Search largWidth={true} onSearchData={this.onSearchData} />
                </div>
                <div className='mt-[42px] items-start grid-full-320'>
                    {
                        this.getFilterByDate(Object.values(result)).map((data, i)=>{
                            if(data[0]?.group_name !== "One to One" && (!data[0]?.group_name.includes("Locked") || data[0]?.group_name.includes("Locked") && data.find(result => result.check_in.length !== 0))){
                                if(data?.find(x=>x?.student?.full_name?.toLowerCase().includes(search.toLowerCase()))){
                                    return(
                                        <Card5
                                            key={i}
                                            group={data[0]?.group_name.replace("Locked", "")} 
                                            time={salaryCounting.getLessonHour(data[0]?.lesson_houre)} 
                                            subject={data[0]?.lesson_mode}
                                            type={data[0]?.study_mode}
                                            students={salaryCounting.getGroupStudents(data)}
                                            btn={true}
                                            groupLockStatus={data[0]?.group_name.includes("Locked")?true:false}
                                            modeType="group"
                                            course={data.filter(x=>x.status==1)[0]?.course?.title}
                                            onClickLockUnlock={this.onClickLockUnlock.bind(this, data, "group")}
                                            onClickLockUnlockStudent={(index, status)=>this.onClickLockUnlock(data[index], "individual",status)}
                                            onClickAddStudent={data[0]?.group_name.includes("Locked")?()=>{}:this.addStudentBtn.bind(this, data)}
                                            onClickEdit={data[0]?.group_name.includes("Locked")?()=>{}:this.addStudent.bind(this, data[0], "group", data)}
                                            onShowCheckins={this.showCheckins.bind(this)}
                                            shadow={true}
                                        />
                                    )
                                }
                                
                            }else if(data[0]?.group_name === "One to One"){
                                return(
                                    data.map((item, j)=>{
                                        if(item?.status === "1" || (item?.status === "0" && item?.check_in.length !== 0)){
                                            if(item?.student?.full_name?.toLowerCase().includes(search.toLowerCase())){
                                                return(
                                                    <Card5 
                                                        key={i+""+j}
                                                        group={item?.group_name.replace("Locked", "")} 
                                                        time={salaryCounting.getLessonHour(item?.lesson_houre)} 
                                                        subject={item?.lesson_mode}
                                                        type={item?.study_mode}
                                                        students={salaryCounting.getIndividualStudents(item)}
                                                        btn={true}
                                                        course={item?.course?.title}
                                                        groupLockStatus={item?.status ==="1"? false:true}
                                                        modeType="individual"
                                                        onClickLockUnlock={this.onClickLockUnlock.bind(this, item, "individual")}
                                                        onClickLockUnlockStudent={(index, status)=>this.onClickLockUnlock(item, "individual",status)}
                                                        onClickEdit={item?.status ==="1"?this.addStudent.bind(this, item, "individual"):()=>{}}
                                                        onShowCheckins={this.showCheckins.bind(this)}
                                                        shadow={true}
    
                                                    />
                                                ) 
                                            }
                                       
                                        }
                                        
                                    })
                                
                                )
                            }
                        })
                    }
                    
                </div>
                {addStudentModal?.position?<AddStudentToExistsGroupModal callAfterChange={this.callAfterChange.bind(this)} data={addStudentModal} closeBtn={(type)=>{this.setState({addStudentModal:{position:false}});if(type !=="single"){this.props.closeAllModal()} }} /> :null}    
                {teacherEnrollModalData?.position? <TeacherEnrollModal closeAllModal={()=>this.props.closeAllModal()} />:null}
                {checkinsModal?.position? <EnrollCheckInModal data={checkinsModal} closeModalBtn={()=>{this.setState({checkinsModal:{position:false}}); this.props.closeAllModal()}} />:null}
            </div>
           </div>
       </div>
    )
  }
}
const mapStateToProps = (state)=> ({
    user: state.Data.user,
    teacherSalary: state.Data.teacherSalary,
    teacherEnrollModalData: state.Data.teacherEnrollModalData,
    lang: state.Data.lang,
})
const mapDispatchToProps = { getUserData, getTeacherSalary, getLessonModes,getGroupsByTeacher, insertNewData, getOfficeAllStudents, changeStateValue }
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(TeacherAssignModal))


