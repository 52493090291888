import React, { Component } from 'react'

export class TStatsSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        viewBox="0 0 32 32"
        fill="#585874"
      >
        <title>{"stats"}</title>
        <path d="M5.998 5h-.987v23h20v-.963l-18.996-.049L5.998 5zm2.013 20h5V14.125l-5 5V25zm6 0h5v-9.875l-2 2-3-3V25zm6 0h5V9.813l-5 5V25zm-6.624-13.933 3.62 3.6 7.148-7.112 1.834 1.844L25.945 5l-4.415.023 1.717 1.722-6.242 6.26-3.689-3.703-5.623 5.604.939.912 4.755-4.751z" />
      </svg>
    )
  }
}

export default TStatsSvg
