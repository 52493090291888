import React, { Component } from 'react'
import MsgDeleteSvg from './svg/MsgDeleteSvg';
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
export class FileInput extends Component {
   state={
    base64:'',
    nameInp:''
   }
   getBase64(file){
    return new Promise((res, rej)=>{
        let reader = new FileReader();
        reader.onload = () => {
            res(reader.result);
        }
        reader.readAsDataURL(file)
    })
   }
   getInpValue=(onChangeParent, e)=>{
    this.getBase64(e.target.files[0])
    .then(resp=>{
        this.setState({base64: resp, nameInp: e.target.files[0]?.name});
        this.props.changeStateValue({name: e.target.name, value: e.target.files[0]})
        onChangeParent(e.target.files[0])
    })
   }

   deleteFile=(onChangeParent,e)=>{
    e.preventDefault();
    this.props.changeStateValue({name: this.props.name, value: null});
    this.props.changeStateValue({name: this.props.valueName, value: null});
    this.setState({base64: '', nameInp: ''});
    onChangeParent(null)
   }
  componentDidMount(){
    this.setState({
        base64:'',
        nameInp:''
    })
  }
  render() {
    const {type, name, value="", title, inpOtherType=false, onChangeParent=()=>{}, placeholder=""} = this.props;
    const {base64, nameInp} = this.state;
    return (
      <div>
        <label className='text-[14px] txt-light-black' >{!inpOtherType?title:''}</label>
        <input id={name} name={name} onChange={this.getInpValue.bind(this, onChangeParent)} className='hidden' type="file" accept={type==="image" || type==="audio" || type==="video"?`${type}/*`:type} />
        {
            !inpOtherType?
            <label htmlFor={name} className='bg-gray-100 flex h-[35px] rounded-[6px] cursor-pointer items-center p-[8px] justify-between'>
                <span className='text-[15px] font-regular text-gray-600'>{nameInp==="" &&placeholder===""? title:nameInp!==""? nameInp.length>20?`${nameInp.substring(0, 20)}...`:nameInp:placeholder}</span>
                <button onClick={this.deleteFile.bind(this, onChangeParent)}>
                    <MsgDeleteSvg />
                </button>
            </label>:
            <div className='flex justify-center'>
                 <label htmlFor={name} className='bg-gray-100 flex h-[135px] w-[135px] rounded-full cursor-pointer items-center p-[8px] flex-col items-center justify-center gap-[10px]'>
                    <span className='text-[15px] font-regular text-gray-600 text-center'>{nameInp===""?title:nameInp.length>15?`${nameInp.substring(0, 15)}...`:nameInp}</span>
                    <button onClick={this.deleteFile.bind(this, onChangeParent)}>
                        <MsgDeleteSvg />
                    </button>
                </label>
            </div>
        }
       
        <div>
            {
                type === "image"?
                base64 !== ""?
                <img className='w-full mt-[20px] rounded-[6px]' src={base64}  />:
                value && value !== ""?
                <img className='w-full mt-[20px] rounded-[6px]' src={value}  />:null
               :null
            }
            {
                type === "audio"?
                base64 !== ""?
                <audio className='w-full mt-[20px]' src={base64} controls />:
                value && value !== ""?
                <audio className='w-full mt-[20px]' src={value} controls />:null
                :null
            }
            {
                type === "video"?
                base64 !== ""?
                <video className='w-full mt-[20px] rounded-[6px]' src={base64} controls />:
                value && value !== ""?
                <video className='w-full mt-[20px] rounded-[6px]' src={value} controls />:null
                :null
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(FileInput)
