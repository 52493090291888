import React, { Component } from 'react'

export class SkeletonCard extends Component {
  render() {
    const {height=false} = this.props
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',  height: '100px' }} className={`skeleton skeleton-loading ${height?'h-[300px_!important]':''}`}>
           
            <div style={{ width: '100%' }} className="skeleton-content">
                <h4 className={`${height?'h-[100px_!important]':''}`}></h4>
                <div className={`skeleton-description${height?' h-[170px_!important]':''}`}>
                
                </div>
            </div>
        </div>
    )
  }
}

export default SkeletonCard
