// import React, { Component } from 'react'
// import { changeStateValue } from '../../../redux/MainReducer';
// import { connect } from 'react-redux';

// export class DropDown extends Component {
//     shuffle(array) {
//         return array.sort(() => Math.random() - 0.5);
//     }
//     getOptions(options){
       
//         let x = `<option value="N/A">N/A</option>`;
//         for(const data of this.shuffle(options)){
//             x+=`<option value="${data}">${data}</option>`
//         }
//         return x
//     }
//     componentDidMount(){
//       const input = document.querySelectorAll(`.drop_question_select_${this.props.answers?.id}`);
     
//       if(input){
//           input.forEach((x, i)=>{
//             x.addEventListener('input', (e)=>{
//               this.props.changeStateValue({
//                 name:`examsData.${this.props.index}.questions.${this.props.questionIndex}.answers.${+e.target.getAttribute('aria-label')}.given_answer`,
//                 value: e.target.value
//               })
//             })
//           })
//         }

     
//     }
   
//   render() {
//     const {answers, index} = this.props;
//     let arr = []
//     let ques = []
//     for(let i = 0; i < answers.description.split('}}}').length; i++){
//         if(answers.description.split('}}}')[i].includes('{{{')){
//             ques.push(answers.description.split('}}}')[i].split('{{{')[1].split('|'))
//             arr.push(`${answers.description.split('}}}')[i].split('{{{')[0]} <select  class="drop_question_select_${answers?.id} border rounded-[6px] min-w-[90px] p-[2px] cursor-pointer">${this.getOptions(answers.description.split('}}}')[i].split('{{{')[1].split('|'))}</select> |`)
         
//         }else{
//             arr.push(answers.description.split('}}}')[i])
//         }
        
//     }
//     const str = String(arr).replaceAll('|,', '');
//     return (
//         <div>
//         <div className='free_text_container' dangerouslySetInnerHTML={{ __html:str }}></div>
//      </div>
//     )
//   }
// }
// const mapStateToProps = (state) =>({});
// const mapDispatchToProps = {changeStateValue}
// export default connect(mapStateToProps, mapDispatchToProps)(DropDown)


import React, { Component } from 'react';
import { changeStateValue } from '../../../redux/MainReducer';
import { connect } from 'react-redux';

export class DropDown extends Component {
  state = {
    selectedAnswers: [], // Track answers for each select
  };

  shuffle(array) {
    return array.sort(() => Math.random() - 0.5);
  }

  getOptions(options) {
    return [
      <option key="N/A" value="N/A">
        N/A
      </option>,
      ...this.shuffle(options).map((data, index) => (
        <option key={index} value={data}>
          {data}
        </option>
      )),
    ];
  }

  handleChange = (e, answerIndex) => {
    const { value } = e.target;

    // Update the local state for that specific dropdown
    const updatedAnswers = [...this.state.selectedAnswers];
    updatedAnswers[answerIndex] = value;

    this.setState(
      { selectedAnswers: updatedAnswers },
      () => {
        // Dispatch the state update to Redux after updating local state
        this.props.changeStateValue({
          name: `examsData.${this.props.index}.questions.${this.props.questionIndex}.answers.${answerIndex}.given_answer`,
          value: value,
        });
      }
    );
  };
getHtml(text){
  return(
    <span dangerouslySetInnerHTML={{__html:text}}></span>
  )
}
  render() {
    const { answers } = this.props;
    let arr = [];

    const parts = answers.description.split('}}}');
    parts.forEach((part, i) => {
      if (part.includes('{{{')) {
        const [text, optionsString] = part.split('{{{');
        const options = optionsString.split('|');

        arr.push(
          <span key={`text-${i}`}>{this.getHtml(text)}</span>,
          <select
            key={`select-${i}`}
            className={`drop_question_select_${answers?.id} border rounded-[6px] min-w-[90px] p-[2px] cursor-pointer inline-block`}
            value={this.state.selectedAnswers[i] || 'N/A'} // Set selected value for this dropdown
            onChange={(e) => this.handleChange(e, i)} // Update only the relevant dropdown
          >
            {this.getOptions(options)}
          </select>,
          <span key={`pipe-${i}`}>  </span>
        );
      } else {
        arr.push(<span key={`text-part-${i}`}>{this.getHtml(part)}</span>);
      }
    });

    return (
      <div>
        <div className="free_text_container">{arr}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { changeStateValue };

export default connect(mapStateToProps, mapDispatchToProps)(DropDown);
