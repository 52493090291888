import React, { Component } from 'react'
import logo from "../../images/logo.png"
import { Link } from 'react-router-dom'
export class Logo extends Component {
  render() {
    return (
      <div className='flex justify-center pt-[26px] pb-[20px] border-b-[1px] border-light-gray h-[100px]'>
        <Link to='/'>
            <img className='w-[159.38px] h-[50px]' src={logo} />
        </Link>
      </div>
    )
  }
}

export default Logo
