import React, { Component } from 'react'

export class WpSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={8}
            height={8}
            fill="none"
        >
            <g clipPath="url(#a)">
            <path
                fill="#fff"
                d="M6.35 1.637a3.272 3.272 0 0 0-2.337-.97A3.309 3.309 0 0 0 1.15 5.62L.683 7.333l1.75-.46a3.304 3.304 0 0 0 1.58.404 3.309 3.309 0 0 0 3.304-3.304c0-.883-.344-1.713-.967-2.336Zm-2.337 5.08c-.493 0-.976-.134-1.4-.384l-.1-.06-1.04.274.277-1.014-.067-.103a2.755 2.755 0 0 1-.42-1.46A2.751 2.751 0 0 1 4.01 1.223c.733 0 1.423.287 1.94.807a2.728 2.728 0 0 1 .803 1.943 2.738 2.738 0 0 1-2.74 2.744ZM5.52 4.663c-.083-.04-.49-.24-.563-.27-.077-.026-.13-.04-.187.04-.057.084-.213.27-.26.324-.047.056-.097.063-.18.02-.083-.04-.35-.13-.663-.41-.247-.22-.41-.49-.46-.574-.047-.083-.007-.126.036-.17.037-.036.084-.096.124-.143.04-.047.056-.083.083-.137.027-.056.013-.103-.007-.143-.02-.04-.186-.447-.253-.613-.067-.16-.137-.14-.187-.144h-.16a.3.3 0 0 0-.22.104c-.073.083-.286.283-.286.69 0 .406.296.8.336.853.04.057.584.89 1.41 1.247.197.086.35.136.47.173.197.063.377.053.52.033.16-.023.49-.2.557-.393.07-.193.07-.357.047-.393-.024-.037-.074-.054-.157-.094Z"
            />
            </g>
            <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h8v8H0z" />
            </clipPath>
            </defs>
        </svg>
    )
  }
}

export default WpSvg
