import React, { Component } from 'react'
import Header from '../../../components/header/Header'
import { withHooks } from '../../../actions/withHooks';
import { connect } from 'react-redux';
import { getSingleData, getUserData } from '../../../actions/StaffAction';
import { managerRoleButtons, headTeacherRoleButtons, getPages } from './pages';
import ExamResults from './pages/ExamResults';
import UnlockExams from './pages/UnlockExams';
import EssayReviews from './pages/EssayReviews';
import UnlockEssays from './pages/UnlockEssays';
import IalExReviews from './pages/IalExReviews';
import UnlockIalEx from './pages/UnlockIalEx';
import HwResults from './pages/HwResults';
import UnlockHw from './pages/UnlockHw';
import SpeakingReviews from './pages/SpeakingReviews';
import SpPractice from './pages/SpPractice';
import Certificates from './pages/Certificates';
import Logs from './pages/Logs';
import Reports from './pages/Reports';
import Payments from './pages/Payments';
import PageButtons from './PageButtons';
import OtherReports from './pages/OtherReports';
import DefaultPage from './pages/DefaultPage';
import { Link } from 'react-router-dom';
import Notes from './pages/Notes';
import ExamSchedule from './pages/ExamSchedule';
import StudentEnroll from './pages/StudentEnroll';
import UnlockSpeaking from './pages/UnlockSpeaking';
import { deleteData, insertNewData } from '../../../actions/MainAction';
import { mixinAlert } from '../../../actions/SweetAlerts';
export class CeltStudentMainPage extends Component {
    state={
        studentData:'',
        page: 'default',
        selected:'default',
        loginBlock:''
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        this.props.getUserData(this.props.params.id)
        .then(resp=>{
            this.setState({studentData:resp})
        });
        this.props.getSingleData(`login-block?student_id=${this.props.params.id}&type=single`)
        .then(resp=>{
            this.setState({loginBlock:resp})
        })
        if(localStorage.getItem("goRp")){
            this.setState({ 
                page: 'reports',
                selected:'reports'
            });
            localStorage.removeItem("goRp")
        }
        if(localStorage.getItem("goPayment")){
            // this.setState({ 
            //     page: 'payments',
            //     selected:'payments'
            // });
            // localStorage.removeItem("goPayment")
        }
        if(localStorage.getItem("dash")){
            this.setState({ 
                page: 'schedule',
                selected:'schedule'
            });
            localStorage.removeItem("dash")
        }

        if(this.props.user?.role == "support"){
            this.setState({ 
                page: 'unlock_exams',
                selected:'unlock_exams'
            })
        }
    }

    getStudentPages=(data, index)=>{
        if(data?.id == "unlock_exams" || data?.id =="unlock_ial_exams" || data?.id=="unlock_essays" || data?.id=="unlock_hws" || data?.id=="unlock_speakings"){
            this.props.navigate("?select_course")
        }
        this.setState({selected:data.id})
        this.setState({page:data.id}) 
       
    }
    getStudentName=(studentData, user)=>{
        if(user?.role == "super_admin"){
            if(studentData !=""){
                return studentData?.full_name+" "+studentData?.company?.name
            }else{
                return '';
            }
            
        }else{
            return studentData?.full_name
        }
    }
    componentDidUpdate(prevProps, prevState){
      
        if(prevProps?.user != this.props.user){
            if(this.props.user?.role == "support"){
                this.setState({ 
                    page: 'unlock_exams',
                    selected:'unlock_exams'
                })
            }
        }
    }
    onClickBlock=(data, id)=>{
        if(data == ""){
            this.props.insertNewData("login-block", {student_id:id})
            .then(resp=>{
                mixinAlert("success", 'Student login blocked successfully');
                this.props.getSingleData(`login-block?student_id=${this.props.params.id}&type=single`)
                .then(resp=>{
                    this.setState({loginBlock:resp})
                })
            })
        }else{
            this.props.deleteData(`login-block/${data?.id}`)
            .then(resp=>{
                mixinAlert("success", 'Student login block deleted successfully');
                this.props.getSingleData(`login-block?student_id=${this.props.params.id}&type=single`)
                .then(resp=>{
                    this.setState({loginBlock:resp})
                })
            })
        }
    }

    removeAllTokens=(id)=>{
        this.props.insertNewData(`delete-all-tokens/${id}`, {})
        .then(resp=>{
            if(resp=="success"){
                mixinAlert("success", "Tokens deleted successfully");
            }else{
                mixinAlert("error", "Something went wrong");
            }
        })
    }
  render() {
    const {studentData, page, selected, loginBlock} = this.state;
    const {user, lang} = this.props;
    let pages = [];
    if(user?.role === "company_head"  || user?.role === "auditor"){
        pages = getPages(lang)?.managerRoleButtons;
    }else if(user?.role == "office_manager"){
        pages=getPages(lang)?.officeManagerRoleButtons;
    }else if(user?.role == "head_teacher"){
        pages =  getPages(lang)?.headTeacherRoleButtons
    }else if(user?.role == "support"){
        pages =  getPages(lang)?.supportRoleButtons
    }else if(user?.role == "super_admin"){
        pages =  getPages(lang)?.superadminRoleButtons
    }else{
        pages =  getPages(lang)?.teacherRoleButtons
    }
    // console.log(studentData)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header lginBlockVisible={user?.role == "company_head" || user?.role == "office_manager" || user?.role == "super_admin" || user?.role == "support"?true:false} onClickBlock={this.onClickBlock.bind(this, loginBlock, studentData?.id)} loginBlockStatus={loginBlock==""?1:0} title={this.getStudentName(studentData, user)} lockVisible={true} lockStatus={studentData.attendance_lock_status === 1  || studentData.manual_lock_status === 1?true:false} />
            <div className='mt-[42px] mb-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px] max-[330px]:mt-[200px]'>
                {
                    user?.role == "super_admin"?
                    <Link className='bg-blue text-white font-semibold text-[13px] p-[5px_8px] rounded-[5px] mb-[20px] inline-block' to={`/students/${studentData?.id}/edit`} >Go Edit</Link>:null
                }
                {
                    user?.role == "support"?
                    <button onClick={this.removeAllTokens.bind(this, studentData?.id)} className='bg-blue text-white font-semibold  p-[5px_8px] rounded-[6px] mb-[10px]'>Log all out</button>:null
                }
                <PageButtons onClick={(data, i)=>this.getStudentPages(data, i)} selected={selected} buttons={pages}  />
            </div>
            
            <div className='mt-[2px] flex gap-[30px]'>
            

                {  
                   page === "default" && user?.role !="support"? <DefaultPage examType="gen_exam" />:
                   page === "exam_results"? <ExamResults examType="gen_exam" />:
                   page === "all_ielts"? <ExamResults examType="all_ielts" />:
                   page === "sat_math"? <ExamResults examType="sat_math" />:
                   page === "sat_verbal"? <ExamResults examType="sat_verbal" />:
                   page === "all_others"? <ExamResults examType="all_others" />:
                   page === "mock_exam"? <ExamResults examType="mock_exam" />:
                   page === "unlock_exams"? <UnlockExams studentData={studentData} />:
                   page === "essay_review"? <EssayReviews />:
                   page === "unlock_essays"? <UnlockEssays studentData={studentData} />:
                   page === "unlock_speakings"? <UnlockSpeaking studentData={studentData} />:
                   page === "ial_ex_reviews"? <IalExReviews />:
                   page === "unlock_ial_exams"? <UnlockIalEx />:
                   page === "hw_results"? <HwResults examType="gen_exam" />:
                   page === "sat_math_hw"? <HwResults examType="sat_math" />:
                   page === "sat_verbal_hw"? <HwResults examType="sat_verbal" />:
                   page === "all_others_pt"? <HwResults examType="all_others" />:
                   page === "unlock_hws"? <UnlockHw />:
                   page === "speakings"? <SpeakingReviews />:
                   page === "sp_practice"? <SpPractice />:
                   page === "certs"? <Certificates studentName={studentData?.full_name} />:
                   page === "logs"? <Logs studentData={studentData} />:
                   page === "reports"? <Reports />:
                   page === "other_reports"? <OtherReports />:
                   page === "notes"? <Notes />:
                   page === "schedule"? <ExamSchedule studentData={studentData} />:
                   page === "enrol_payments"? <StudentEnroll />:
                   page === "supp_gen_exam"?  <DefaultPage examType="gen_exam" />:
                   page === "payments"? <Payments />:null
                }
               
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
   user: state.Data.user,
   lang: state.Data.lang,
});
const mapDispatchToProps = {getUserData, getSingleData, insertNewData, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(CeltStudentMainPage))
