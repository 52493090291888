import React, { Component } from 'react'

export class CheckSvg extends Component {
  render() {
    const {color = "green"} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 12.611 8.923 17.5 20 6.5"
        />
      </svg>
    )
  }
}

export default CheckSvg
