import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import { getExamQuestions } from '../../../actions/AdminActions';
import Header from '../../../components/header/Header';
import Audio from '../../../components/exams/Audio';
import Video from '../../../components/exams/Video';
import Image from '../../../components/exams/Image';
import SingleChoice from '../../../components/exams/SingleChoice';
import MultiChoice from '../../../components/exams/MultiChoice';
import DropDown from '../../../components/exams/DropDown';
import FreeText from '../../../components/exams/FreeText';
export class AdminExamPreview extends Component {
    componentDidMount(){
        if(this.props.params.id){
            this.props.getExamQuestions(this.props.params.id)
        }
        window.scrollTo(0,0)
    }
  render() {
    const {adminExamQuestions} = this.props;
    // console.log(adminExamQuestions)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={`Exam Preview`} />
            <div className='mt-[40px] flex flex-col gap-[20px]'>
                {
                    adminExamQuestions.map((data, j)=>{
                        return(
                            <div key={j} className='bg-white p-[20px] rounded-[10px]'>
                                <div>
                                    <h2 className='txt-blue text-[19px] font-semibold'>{data?.title}</h2>
                                    <p className='mt-[20px]' dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                                    { data?.image_full_url?  <Image url={data?.image_full_url} />:null }
                                    { data?.audio_full_url?  <Audio url={data?.audio_full_url} />:null }
                                    { data?.video_full_url?  <Video url={data?.video_full_url} />:null }
                                </div>
                                {
                                    data?.children?.map((item, i)=>{
                                       return(
                                        <div key={i} className='mt-[20px]'>
                                            <h2 className='txt-blue text-[16px] font-semibold'>{item?.title}</h2>
                                            <p className='mt-[20px]' dangerouslySetInnerHTML={{ __html: item?.description }}></p>
                                            { item?.image_full_url?  <Image url={item?.image_full_url} />:null }
                                            { item?.audio_full_url?  <Audio url={item?.audio_full_url} />:null }
                                            { item?.video_full_url?  <Video url={item?.video_full_url} />:null }
                                            <div>
                                                {
                                                    item?.answers.map((params, k)=>{
                                                        if(item?.type !== "free_text" && item?.type !== "dropdown_question_type" && item?.type !== "multiple_choice"){
                                                            return(
                                                                <div className='flex items-center gap-[6px]' key={k}>
                                                                    <input type='radio' checked={params?.is_correct =='1'} />
                                                                    {item?.type === 'single_image_choice' || item?.type === "single_audio_with_image"?
                                                                    <img  className='w-[140px] rounded-[6px]' src={params?.answer_full_url}/>:
                                                                    <span className='text-[15px]'>{params?.title}</span>
                                                                    }
                                                                    
                                                                </div>
                                                            )
                                                        }else if(item?.type === "multiple_choice"){
                                                            return(
                                                                <div className='flex items-center gap-[6px]' key={k}>
                                                                    <input type='checkbox' checked={params?.is_correct =='1'} />
                                                                    <span className='text-[15px]'>{params?.title}</span>
                                                                </div>
                                                            )
                                                        }else if(item?.type === "dropdown_question_type"){
                                                            return(
                                                                <div key={k}> 
                                                                    <span>{params?.title}</span>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            )
                                                        }
                                                        
                                                    })
                                                }
                                            </div>
                                        </div>
                                       )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminExamQuestions: state.Data.adminExamQuestions
});
const mapDispatchToProps = {getExamQuestions}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminExamPreview))
