import React, { Component } from 'react'
import Header from "./Header";
import Input from "./components/Input";
import { connect } from 'react-redux';
import ReviewGrade from '../cards/ReviewGrade';
import { changeStateValue } from '../../redux/MainReducer';
import TextEditor from '../TextEditor';
import { bigAlert, mixinAlert } from '../../actions/SweetAlerts';
import { insertNewData } from '../../actions/MainAction';
import FileInputPDF from '../FileInputPDF';
import ContractPdfSvg from '../svg/ContractPdfSvg';
export class ReportModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.changeStateValue({
            name:'reviewModalData',
            value:{position: false}
        })
    }
    saveBtn=(data)=>{
        if(data?.note.trim() !== '' && data?.date !== ''){
            this.setState({btnDisabled:true})
            let url = "assessments";
            let formData = new FormData();
            for(const property in data){
                if(data[property]){
                    formData.append(property, data[property]);
                }
                
            }
            if(data?.id && data?.id !== ""){
                url = `assessments/${data?.id}`;
                formData.append("_method", "put")
            }
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisabled:true})
                if(resp === "success"){
                    bigAlert("Done!", "Report sent successfully!", "success");
                    if(data?.id && data?.id !="" ){
                        if(this.props.user?.role == "company_head" || this.props.user?.role == "office_manager"){
                            if(data?.status==0){
                                this.props.insertNewData(`approve-assessments/${data?.id}`, {user_id:data?.user_id})
                            }
                        }
                        
                    }
                    this.closeModal();
                    this.props.callAfterSubmit()
                }else{
                    bigAlert("Error!", "Something went wrong!", "error");
                }
            })
        }else{
            mixinAlert("info", "Please fill the required inputs")
        }
    }
    gradeData=(name, data)=>{
        this.props.changeStateValue({
            name:name,
            value:data
        })
    }
  render() {
    const {btnDisabled}= this.state;
    const {reviewModalData, lang, user} = this.props;
    return (
        <div  className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[700px]  shadow bg-white rounded-[10px] '>
            <Header closeBtn={this.closeModal.bind(this)} title={"Add Review"} />
            <div className='p-[20px] max-h-[70vh] overflow-auto'>
                <div className=''>
                    {
                        (reviewModalData?.id && reviewModalData?.id !=="" && (user?.role == "company_head" || user?.role == "offuce_manager")) || !reviewModalData?.id?
                        <div className='w-[200px]'>
                            <Input type="date" name={"reviewModalData.date"} value={reviewModalData?.date} title='Select Date' />
                        </div>:null
                    }
                   
                    <div className='w-[200px] mt-[20px]'>
                        <FileInputPDF type="application/pdf" name="reviewModalData.file" nameInp={reviewModalData.file?.name} valueName={'reviewModalData.file_full_url'} title="Upload PDF" />
                    </div>
                    <div className='mt-[10px]'>
                        {
                            reviewModalData?.file_full_url?
                                <a href={reviewModalData?.file_full_url} download target='_blank'>
                                    <ContractPdfSvg />
                                </a>
                            :null
                        }
                    </div>
                    {/* <div className='mt-[20px] flex flex-col gap-[10px]'>
                        <ReviewGrade title="Homework " onClickGrade={this.gradeData.bind(this, 'reviewModalData.homework')} subTitle="(Ev Tapşırığı)" point={reviewModalData?.homework }/>
                        <ReviewGrade title="Vocabulary " onClickGrade={this.gradeData.bind(this, 'reviewModalData.participation')} subTitle="(Söz bazası)" point={reviewModalData?.participation }/>
                        <ReviewGrade title="Performance " onClickGrade={this.gradeData.bind(this, 'reviewModalData.performance')} subTitle="(Dərsdə Aktivlik)" point={reviewModalData?.performance }/>
                        <ReviewGrade  title="Reading skills " onClickGrade={this.gradeData.bind(this, 'reviewModalData.readingComprehension')} subTitle="(Mətn anlama)" point={reviewModalData?.readingComprehension }/>
                        <ReviewGrade title="Listening skills " onClickGrade={this.gradeData.bind(this, 'reviewModalData.listening_skills')} subTitle="(Dinləmə)" point={reviewModalData?.listening_skills }/>
                        <ReviewGrade title="Speaking skills " onClickGrade={this.gradeData.bind(this, 'reviewModalData.speakingFluency')} subTitle="(Danışıq)" point={reviewModalData?.speakingFluency }/>
                        <ReviewGrade title="Writing/Essays " onClickGrade={this.gradeData.bind(this, 'reviewModalData.writingSkills')} subTitle="(Yazı/Esse)" point={reviewModalData?.writingSkills }/>
                    </div> */}
                    <div className='mt-[20px]'>
                        <TextEditor required={true} name="reviewModalData.note" value={reviewModalData?.note} />
                    </div>
                </div>
                <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={()=>{this.closeModal()}} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this, reviewModalData)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{this.props.user?.role == "teacher" || this.props.user?.role == "head_teacher" || this.props.user?.role == "speaking_teacher" ? lang.save:(this.props.user?.role == "company_head" || this.props.user?.role == "office_manager") && reviewModalData?.status==1? lang?.save: lang?.send_approve}</button>
                </div>
            </div>
            
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    reviewModalData: state.Data.reviewModalData,
    lang: state.Data.lang,
    user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(ReportModal)
