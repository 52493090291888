import React, { Component } from 'react'
import Searchable from 'react-searchable-dropdown';
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer'; 
export class DropSearch extends Component {
    selectValue=(name, selectData,data)=>{
      if(name){
        this.props.changeStateValue({
          name: name, 
          value: data
        })
      }
      selectData(data);
    }

    getPlaceholder(placeholder, value, options){
      if(value === ""){
        return placeholder;
      }else{
        const check = options.find(item=>item?.value === value);
        if(check){
          return check?.label
        }else{
          return placeholder
        }
      }
    }
  render() {
    const {options=[], name, placeholder, title="", value="", required=false, selectData=()=>{}, lang} = this.props;
    return (
      <div>
        {title !== "" ? <label className='text-[14px] txt-light-black'>{title}</label>:null }
        <Searchable
            placeholder={this.getPlaceholder(placeholder, value, options)}
            options={options}
            onSelect={this.selectValue.bind(this.label, name, selectData)}
        />
        {
            required && value===""?
            <span className='text-[11px] text-red-700'>{lang?.field_required}</span>:null
        }
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(DropSearch)
