import React, { Component } from 'react'
import MonthlyCalendar from './MonthlyCalendar'
import Search from './Search';
import LockUnlockBtns from './LockUnlockBtns';
import DropSearch from './DropSearch';
import { connect } from 'react-redux';
import HeaderButtons from './HeaderButtons';

export class SectionHeader extends Component {
  selectStudentFilter=()=>{

  }
  render() {
    const {onSelect=()=>{}, onSearchData=()=>{},
    calendar=true, lockUnlock=false, btnTitle, onSelectType=()=>{}, onClickAdd=()=>{}, 
    drop1=false, drop2=false, drop1Option=[], rightLine=false, drop2Option=[], drop1Title, drop2Title, onSelectDrop=()=>{}, user, buttons=[], onClick=()=>{}, selected=0, right=true} = this.props;
    return (
      <div className='flex w-full h-[56px] p-[16px]  rounded-[12px] items-center justify-between bg-white mt-[40px] max-md:bg-[var(--body)] max-md:gap-[10px] max-md:flex-col-reverse max-md:h-auto max-md:justify-center'>
         {
           btnTitle && !right && (user?.role === "office_manager" || user?.role === "company_head")?
            <button onClick={()=>onClickAdd()} className='bg-blue text-[12px] font-bold text-white pl-[10px] pr-[10px] pt-[3px] pb-[3px] rounded-[3px]'>{btnTitle}</button>:null
          }
         {
          drop1 || drop2? 
          <div className='flex gap-[20px] white-search max-[500px]:flex-col items-center'>
            {drop1?<DropSearch  options={drop1Option} selectData={(data)=>onSelectDrop(data, "drop1")} placeholder={drop1Title}/>:null}
            {rightLine?<span className='inline-block w-[2px] h-[30px] bg-gray-100 max-md:hidden'></span>:null }
            {drop2?<DropSearch  options={drop2Option} selectData={(data)=>onSelectDrop(data, "drop2")} placeholder={drop2Title}/>:null}
            {rightLine?<span className='inline-block w-[2px] h-[30px] bg-gray-100 max-md:hidden'></span>:null }
          </div>:null
         }
         {
          buttons.length !==0?
          <HeaderButtons buttons={buttons} onClick={(data, i)=>onClick(data, i)} selected={selected} />:null
         }
        {calendar? <MonthlyCalendar bg="bg-white" onSelect={date=>onSelect(date)} />:null}
        {
          lockUnlock?
          <LockUnlockBtns onSelectType={(type)=>onSelectType(type)} />:null
        }
        {!calendar && !lockUnlock?<div></div>:null }
        <div className='flex items-center max-sm:flex-col-reverse gap-[20px]'>
        {
           btnTitle && right && (user?.role === "office_manager" || user?.role === "company_head")?
            <button onClick={()=>onClickAdd()} className='bg-blue text-[12px] font-bold text-white pl-[10px] pr-[10px] pt-[3px] pb-[3px] rounded-[3px]'>{btnTitle}</button>:null
          }
          <Search onSearchData={(search)=>onSearchData(search)} mt="" />
          
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user
})
export default connect(mapStateToProps)(SectionHeader)
