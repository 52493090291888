import React, { Component } from 'react'
import Header from './Header'
import { connect } from 'react-redux';
import { resetStateValue } from '../../redux/MainReducer';
import FileInput from '../FileInput';
import { mixinAlert } from '../../actions/SweetAlerts';
import { insertNewData,  } from '../../actions/MainAction';
import { getCoachException } from '../../actions/StaffAction';
import Input from './components/Input';
export class CoachExceptionModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.resetStateValue({name: "coachExceptionModal"})
    }
    saveBtn=(data)=>{
        if(data?.image !==null && data?.image !=="" && data?.count && data?.count >0){
            this.setState({btnDisabled:true})
            let formData = new FormData();
            for(const property in data){
                formData.append(property, data[property])
            }
            this.props.insertNewData('coach-exception', formData)
            .then(resp=>{
                this.setState({btnDisabled:false})
                if(resp=="success"){
                    this.props.getCoachException(data?.student_id)
                    mixinAlert("success", "permission card uploaded successfully");
                    this.closeModal()
                }else{
                    mixinAlert("error", "Something went wrong.")
                }
            })
        }else{
            mixinAlert("warning", "Please upload permission card image and add count")
        }
    }
  render() {
    const {coachExceptionModal, lang} = this.props;
    const {btnDisabled} = this.state;
    return (
        <div className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[700px]  shadow bg-white rounded-[10px] max-h-[70vh]'>
                <Header closeBtn={this.closeModal.bind(this)} title={lang?.upload_coach_excp_docs} />
                <div className='p-[20px] max-h-[60vh] overflow-auto'>
                    {coachExceptionModal?.image || coachExceptionModal?.image ==="" ||  coachExceptionModal?.image ===null? <FileInput inpOtherType={true} type="image/png, image/jpeg, image/jpg" title={lang?.upload_coach_excp_docs} valueName="coachExceptionModal.image_full_url" value={coachExceptionModal?.image_full_url} name="coachExceptionModal.image" />:null}
                    <div className='mt-[20px]'>
                        <Input type="number" name="coachExceptionModal.count" value={coachExceptionModal?.count} required title="Exam taking time" />
                    </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,coachExceptionModal)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.upload}</button>
                    </div>
                </div>
               
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    coachExceptionModal: state.Data.coachExceptionModal,
    lang: state.Data.lang,
});
const mapDispatchToProps = {resetStateValue, insertNewData, getCoachException}
export default connect(mapStateToProps, mapDispatchToProps)(CoachExceptionModal)


