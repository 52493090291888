import React, { Component } from 'react'

export class GraduateSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 -5 42 42"
        fill={'var(--blue)'}
      >
        <title>{"graduate"}</title>
        <path d="M32.639 17.561s-4.592-4.682-10.92-4.682c-6.183 0-12.295 4.682-12.295 4.682l-3.433-1.433v4.204c.541.184.937.682.937 1.285a1.36 1.36 0 0 1-.953 1.288l1.015 2.831H3.994l1.024-2.855a1.369 1.369 0 0 1-.025-2.511v-4.659L0 13.629l21.969-9.861 20.156 9.985-9.486 3.808zm-11.17-2.31c6.366 0 9.486 3.37 9.486 3.37v6.99s-3.245 2.621-9.985 2.621-8.987-2.621-8.987-2.621v-6.99s3.12-3.37 9.486-3.37zm-.125 11.483c4.412 0 7.989-.895 7.989-1.997s-3.577-1.997-7.989-1.997-7.988.895-7.988 1.997 3.576 1.997 7.988 1.997z" />
      </svg>
    )
  }
}

export default GraduateSvg
