import React, { Component } from 'react'

export class CardTotalSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={10}
        height={10}
        baseProfile="tiny"
        viewBox="0 0 256 256"
        fill='#1E398F'
      >
        <path d="M5 5v246h246V5H5zm232.3 13.7v59.1h-16.6c-1.2-12.8-8-22.6-16.2-22.6s-15 9.8-16.2 22.6h-81.6c-1.2-12.8-8-22.6-16.2-22.6s-15 9.8-16.2 22.6h-6.4c-1.2-12.8-8-22.6-16.2-22.6s-15 9.8-16.2 22.6H18.7V18.7h218.6zm-16.6 152.4c-1.2-12.8-8-22.6-16.2-22.6s-15 9.8-16.2 22.6H182c-1.2-12.8-8-22.6-16.2-22.6s-15 9.8-16.2 22.6h-6.4c-1.2-12.8-8-22.6-16.2-22.6s-15 9.8-16.2 22.6H18.7V85.3h17.1c1.1 13 7.9 23.1 16.2 23.1s15.1-10.1 16.2-23.1h6.3c1.1 13 7.9 23.1 16.2 23.1s15.1-10.1 16.2-23.1h81.5c1.1 13 7.9 23.1 16.2 23.1 8.3 0 15.1-10.1 16.2-23.1h16.6v85.9h-16.7zm-202 66.2v-58.7h92.2c1.1 13 7.9 23.1 16.2 23.1s15.1-10.1 16.2-23.1h6.3c1.1 13 7.9 23.1 16.2 23.1 8.3 0 15.1-10.1 16.2-23.1h6.3c1.1 13 7.9 23.1 16.2 23.1 8.3 0 15.1-10.1 16.2-23.1h16.6v58.7H18.7z" />
      </svg>
    )
  }
}

export default CardTotalSvg
