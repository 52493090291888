import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
import Header from './Header';
import DropSearch from '../DropSearch';
import DefaulData from './components/DefaulData';
import moment from 'moment';
import { insertNewData, getCourses } from '../../actions/MainAction';
import { getTeacherSalary } from '../../actions/StaffAction';
import { bigAlert, mixinAlert } from '../../actions/SweetAlerts';
import Input from './components/Input';
import FileInput from '../FileInput';
import FileInputPDF from '../FileInputPDF';
export class TeacherEnrollModal extends Component {
    state={
        btnDisabled:false,
        fileType:'image'
    }
    componentDidMount(){
        this.props.getCourses()
    }
    closeModalBtn=()=>{
        this.props.changeStateValue({
            name: 'teacherEnrollModalData.position',
            value: false
        });
    }
    saveBtn=(data, lessonModeOption, closeAllModal)=>{
        const date = new Date()
        if(data.study_mode !== '' &&  data.teacher_id !== '' && data.lesson_mode_id !== '' && data.lesson_hour !== '' && data.group_id !== '' && data.student_id !== '' && data.course_id !="" && data.fee !=""){
            
            if(!data?.group_lock){
                let formData = new FormData();
                
                formData.append('study_mode', data.study_mode);
                formData.append('teacher_id', data.teacher_id);
                formData.append('lesson_mode', lessonModeOption?.find(item=>item?.value === data.lesson_mode_id)?.label);
                formData.append('lesson_houre', data.lesson_hour);
                formData.append('student_group_id', data.group_id);
                formData.append('student_id', data.student_id);
                formData.append('lesson_mode_id', data.lesson_mode_id);
                formData.append('course_id', data.course_id);
                formData.append('student_id', data.student_id);
                formData.append('date', moment(date).format("YYYY-MM"));
                formData.append('check_in', JSON.stringify(data.check_in));
                formData.append('fee', data?.fee);
                formData.append('count', data?.count);
                formData.append('note', data?.note);
                if(data?.image){
                    formData.append('image', data?.image);
                }
                if(data?.file){
                    formData.append('file', data?.file);
                }
                this.setState({btnDisabled:true})
                let url = '';
                if(data.id === ''){
                    url = `teacherEnrolls`; 
                  
                    if(data?.count ==""  || data?.count <= 0 || (!data?.image && !data?.file)){
                        mixinAlert("warning", "Please add count or upload file");
                        this.setState({btnDisabled:false})
                        return
                    }
                }else{
                    url = `teacherEnrolls-update/${data.id}`; 
                    formData.append('_method', 'put');
                }
                this.props.insertNewData(url, formData)
                .then(resp=>{
                    this.setState({btnDisabled:false})
                    if(resp === "success"){
                        this.closeModalBtn();
                        bigAlert("Done!", this.props.lang?.st_assign, "success");
                        this.props.getTeacherSalary(data?.teacher_id, moment(date).format("YYYY-MM"));
                        closeAllModal();
                    }else{
                        bigAlert("Error!", this.props.lang?.popup_went_wrong, "error")
                    }
                })
            }else{
                let formData = new FormData();
                formData.append('id', JSON.stringify(data.groups))
                formData.append('lesson_houre', data.lesson_hour);
                formData.append('course_id', data.course_id);
                formData.append('fee', data.fee);
                this.props.insertNewData("teacher-group-update", formData)
                .then(resp=>{
                    this.setState({btnDisabled:false})
                    if(resp === "success"){
                        this.closeModalBtn();
                        bigAlert("Done!", this.props.lang?.group_edited, "success");
                        this.props.getTeacherSalary(data?.teacher_id, moment(date).format("YYYY-MM"));
                    }else{
                        bigAlert("Error!", this.props.lang?.popup_went_wrong, "error")
                    }
                })
            }
            
        }else{
            mixinAlert("info", this.props.lang?.fill_req_inputs);
        }
    }

    selectGroup=(data)=>{
        this.props.changeStateValue({
            name: 'teacherEnrollModalData.group_id',
            value: data?.id
        });
        this.props.changeStateValue({
            name: 'teacherEnrollModalData.group_name',
            value: data?.title
        });
        if(data?.mode){
            this.props.changeStateValue({  name: 'teacherEnrollModalData.lesson_mode', value: data?.mode?.lesson_mode });
            this.props.changeStateValue({  name: 'teacherEnrollModalData.lesson_mode_id', value: data?.mode?.lesson_mode_id });
            this.props.changeStateValue({  name: 'teacherEnrollModalData.study_mode', value: data?.mode?.study_mode });
            this.props.changeStateValue({  name: 'teacherEnrollModalData.lesson_hour', value: data?.mode?.lesson_houre });
            this.props.changeStateValue({  name: 'teacherEnrollModalData.exists', value: true });
        }else{
            if(this.props.teacherEnrollModalData.exists){
                this.props.changeStateValue({  name: 'teacherEnrollModalData.lesson_mode', value: '' });
                this.props.changeStateValue({  name: 'teacherEnrollModalData.lesson_mode_id', value: '' });
                this.props.changeStateValue({  name: 'teacherEnrollModalData.study_mode', value: '' });
                this.props.changeStateValue({  name: 'teacherEnrollModalData.lesson_hour', value: '' });
                this.props.changeStateValue({  name: 'teacherEnrollModalData.exists', value: false }); 
            }
            
        }
    }
    getPlaceholder(placeholder, value, options){
        if(value === ""){
          return placeholder;
        }else{
          const check = options.find(item=>item?.value?.id === value);
          if(check){
            return check?.label
          }else{
            return placeholder
          }
        }
      }
      changeFileType=(type)=>{
        this.setState({
            fileType:""
        });
        this.props.changeStateValue({ name:"teacherEnrollModalData.image", value:null })
        this.props.changeStateValue({ name:"teacherEnrollModalData.image_full_url", value:null })
        this.props.changeStateValue({ name:"teacherEnrollModalData.file", value:null })
        this.props.changeStateValue({ name:"teacherEnrollModalData.file_full_url", value:null })
        setTimeout(()=>{
            this.setState({
                fileType:type
            });
        },100)
      }
  render() {
    const {teacherEnrollModalData, officeAllStudents, lessonModes, groupsByTeacher, closeAllModal=()=>{}, lang, studentCourses} = this.props;
    const {btnDisabled, fileType} = this.state;
   
    let groupOption = [];
    let coursesOption = [];
    const checkCourse = lessonModes.find(item=>item?.id == teacherEnrollModalData?.lesson_mode_id);
    if(checkCourse){
        for(const data of checkCourse?.courses){
            if(data.id!==36 && data?.id!==30 &&  data?.id!==35){
                coursesOption.push({
                    label: data?.title,
                    value: data?.id
                })
            }
        }
    }
   
    const params = {
        label: `One to One`  ,
        value: {id: 9999}
    }
    let lessonModeOption = [];
    for(const data of lessonModes){
        if(data?.id !=23 && data?.id != 24 && data.courses.length !==0){
            lessonModeOption.push({
                label: data.title,
                value: data.id
            })
        }
        
    }
    // groupOption.push(params)
    for(const group of groupsByTeacher){
        if(group.count == 0){
           const params = {
                label: `${group.title} (${group.count})`  ,
                value: group
            }
            groupOption.push(params) 
        }
        
    }
   
    let studentsOption = [];
    for(const student of officeAllStudents){
        if(+student.attendance_lock_status !== 1 && +student.manual_lock_status === 0 ){
            const params = {
                label: student.full_name,
                value: student.id
            }
            studentsOption.push(params)
        }
        
    }
    let studyMode =[];
    if(teacherEnrollModalData?.group_id === 9999){
        studyMode=[
            {
                value: 'One to One',
                label:'One to One'
            },
        ]
    }else{
        studyMode =[
            {
                value: 'One to One',
                label:'One to One'
            },
            {
                value: 'Group Two',
                label: 'Group Two'
            },
            {
                value: 'Group Three',
                label: 'Group Three'
            },
           
            
        ];
    }
    let classTime = [ {
        label: "45 min",
        value: 45
    },
    {
        label: "1 hour",
        value: 60
    },
    {
        label: "1.5 hours",
        value: 90
    },
    {
        label: "2 hours",
        value: 120
    },
    {
        label: "2.5 hours",
        value: 150
    },
    {
        label: "3 hours",
        value: 180
    },
    {
        label: "4 hours",
        value: 240
    }];

    // console.log(teacherEnrollModalData)
    return (
        <div  className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[630px]  shadow bg-white rounded-[10px] '>
            <Header closeBtn={this.closeModalBtn.bind(this)} title={"Student enrollment"} />
            <div className='p-[20px] max-h-[80vh] overflow-auto max-[700px]:overflow-auto'>
                <div className='grid grid-cols-2 gap-[20px] max-[500px]:grid-cols-1'>
                    {
                        teacherEnrollModalData.student_name !== ""?
                        <DefaulData title="Select a student" value={teacherEnrollModalData?.student_name} />:
                        <DropSearch required value={teacherEnrollModalData.student_id} placeholder="Select a student" options={studentsOption} name="teacherEnrollModalData.student_id" title="Select a student" /> 
                    }
                     {
                        teacherEnrollModalData?.exists?
                        <DefaulData title="Select a lesson mode" value={lessonModeOption?.find(item=>item?.value === teacherEnrollModalData.lesson_mode_id)?.label} />:
                        <DropSearch required value={teacherEnrollModalData.lesson_mode_id} placeholder="Select a lesson mode" name="teacherEnrollModalData.lesson_mode_id"  options={lessonModeOption}  title="Select a lesson mode" /> 
                    }
                   
                    {
                        teacherEnrollModalData?.exists?
                        <DefaulData title="Select a Course" value={coursesOption?.find(x=>x.value == teacherEnrollModalData?.course_id)?.label} />:
                        <DropSearch required value={teacherEnrollModalData.course_id} placeholder="Select a Course" name="teacherEnrollModalData.course_id"  options={coursesOption}  title="Select a Course" /> 
                    }
                    {
                        teacherEnrollModalData?.exists?
                        <DefaulData title="Select a group" value={teacherEnrollModalData?.group_name} />:
                        <DropSearch required value={teacherEnrollModalData.group_id} placeholder={this.getPlaceholder("Select a group", teacherEnrollModalData.group_id, groupOption)} selectData={this.selectGroup.bind(this)} options={groupOption}  title="Select a group" /> 
                    }
                   
                    {
                        teacherEnrollModalData?.exists?
                        <DefaulData title="Select a study mode" value={studyMode?.find(item=>item?.value === teacherEnrollModalData.study_mode)?.label} />:
                        <DropSearch required value={teacherEnrollModalData.study_mode} placeholder="Select a study mode" name="teacherEnrollModalData.study_mode"  options={studyMode}  title="Select a study mode" /> 
                    }
                     <DropSearch required value={teacherEnrollModalData.lesson_hour} placeholder="Select a time" name="teacherEnrollModalData.lesson_hour"  options={classTime}  title="Select a time" /> 
                    
                    {/* {teacherEnrollModalData?.fee || teacherEnrollModalData?.fee ===""? <Input  required name="teacherEnrollModalData.fee" type="number" value={teacherEnrollModalData?.fee}  title={"Amount of payment"} />:null} */}
                    {
                        !teacherEnrollModalData?.exists?
                        teacherEnrollModalData?.count || teacherEnrollModalData?.count ===""? <Input  required name="teacherEnrollModalData.count" type="number" value={teacherEnrollModalData?.count}  title={lang?.num_lessons} />:null:null
                    }
                    {
                        !teacherEnrollModalData?.exists?
                        teacherEnrollModalData?.note || teacherEnrollModalData?.note ===""? <Input   name="teacherEnrollModalData.note" type="text" value={teacherEnrollModalData?.note}  title={lang?.pay_note} />:null:null
                    }
                    {
                         !teacherEnrollModalData?.exists?
                         <div className='flex justify-between items-start gap-[10px] mt-[-4px]'>
                           
                            {
                            fileType=="image"?
                            <FileInput placeholder={'Payment receipt only.'} required type="image" name="teacherEnrollModalData.image" title="Upload Image" value={teacherEnrollModalData?.image_ful_url} valueName="teacherEnrollModalData.image_full_url"  />:
                            fileType == "pdf"?
                            <FileInput placeholder={'Payment receipt only.'} required  type="application/pdf" name="teacherEnrollModalData.file" value={teacherEnrollModalData?.file} title="Upload file" valueName="teacherEnrollModalData.file_full_url" />:null
                            }
                             <div className='flex items-center gap-[10px] mt-[30px]'>
                                <button onClick={this.changeFileType.bind(this, 'image')} className={`text-[12px] ${fileType=="image"? ' bg-blue text-white':'border txt-blue'} rounded-[4px] font-regular h-[20px] p-[0_5px] `}>Image</button>
                                <button onClick={this.changeFileType.bind(this, 'pdf')} className={`text-[12px] ${fileType=="pdf"? ' bg-blue text-white':'border txt-blue'} rounded-[4px] font-regular h-[20px] p-[0_5px] `}>PDF</button>
                            </div>
                        </div>:null
                    }
                </div>
               <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={this.closeModalBtn.bind(this)} className='bg-red h-[25px] text-[12px] pl-[10px] pr-[10px]  rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,teacherEnrollModalData, lessonModeOption, closeAllModal)} className='bg-blue h-[25px] text-[12px] pl-[10px] pr-[10px]   rounded-[5px] text-white font-semibold'>{lang?.upl_enr}</button>
                </div>
            </div>
            
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    teacherEnrollModalData: state.Data.teacherEnrollModalData,
    officeAllStudents: state.Data.officeAllStudents,
    lessonModes: state.Data.lessonModes,
    groupsByTeacher: state.Data.groupsByTeacher,
    lang: state.Data.lang,
    studentCourses: state.Data.studentCourses,
});
const mapDispatchToProps = {changeStateValue,insertNewData, getTeacherSalary, getCourses}
export default connect(mapStateToProps, mapDispatchToProps)(TeacherEnrollModal)
