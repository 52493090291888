import React, { Component } from 'react'

export class CardTeacherSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
    >
        <path
        fill="#1E398F"
        d="M11.813 2.297H2.187a.766.766 0 0 0-.765.765v7.876a.766.766 0 0 0 .766.765h.732a.328.328 0 0 0 .296-.188 2.734 2.734 0 0 1 4.943 0 .328.328 0 0 0 .296.188h3.357a.766.766 0 0 0 .766-.765V3.061a.765.765 0 0 0-.765-.765ZM4.266 7.875a1.422 1.422 0 1 1 2.843 0 1.422 1.422 0 0 1-2.843 0Zm7.656 3.063a.109.109 0 0 1-.11.109H8.655a3.409 3.409 0 0 0-1.721-1.51 2.078 2.078 0 1 0-2.493 0c-.73.29-1.339.823-1.721 1.51h-.533a.11.11 0 0 1-.109-.11V3.064a.11.11 0 0 1 .11-.11h9.624a.11.11 0 0 1 .11.11v7.874Zm-1.094-6.563v5.25a.328.328 0 0 1-.328.328h-.875a.328.328 0 1 1 0-.656h.547V4.703H3.828v.547a.328.328 0 0 1-.656 0v-.875a.328.328 0 0 1 .328-.328h7a.328.328 0 0 1 .328.328Z"
        />
    </svg>
    )
  }
}

export default CardTeacherSvg
