import React, { Component } from 'react'
import MiniCalendarSvg from './svg/MiniCalendarSvg'
import BottomSvg from './svg/BottomSvg'
import DropBottomSvg from './svg/DropBottomSvg'
import Calendar from './Calendar'
import moment from 'moment'
import { connect } from 'react-redux'

export class MiniCalendar extends Component {
    state={
        calendarVisible:false,
        prevDate: moment(new Date()).format("DD-MM-YYYY"),
        nextDate:moment(new Date()).format("DD-MM-YYYY")
    }
    getLastMonth(){
        let date = new Date();
        date.setMonth( date.getMonth()-1);
        return moment(date)
    }
    getLastWeek(day){
        const now = new Date();
        return moment(new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - day,
        ))
    }
    getLongTime=(type)=>{
        if(type === "month"){
            this.setState({prevDate: this.getLastMonth().format("DD-MM-YYYY")});
            this.props.onSelectDate({startDate:this.getLastMonth().format("YYYY-MM-DD"), endDate: moment(new Date()).format("YYYY-MM-DD")})
        }else if(type === "week"){
            this.setState({prevDate: this.getLastWeek(7).format("DD-MM-YYYY")});
            this.props.onSelectDate({startDate:this.getLastWeek(7).format("YYYY-MM-DD"), endDate: moment(new Date()).format("YYYY-MM-DD")})
        }else if(type === "yesterday"){
            this.setState({prevDate: this.getLastWeek(1).format("DD-MM-YYYY")});
            this.props.onSelectDate({startDate:this.getLastWeek(1).format("YYYY-MM-DD"), endDate: moment(new Date()).format("YYYY-MM-DD")})
        }
    }
    selectDate=(date)=>{
        this.setState({
            prevDate: moment(date).format("DD-MM-YYYY"),
            nextDate:moment(date).format("DD-MM-YYYY")
        })
        this.props.onSelectDate({startDate: moment(date).format("YYYY-MM-DD"), endDate: moment(date).format("YYYY-MM-DD")})
    }
  render() {
    const {calendarVisible, prevDate, nextDate} = this.state;
    const {lang} = this.props;
    return (
      <div>
        {calendarVisible?<div onClick={()=>this.setState({calendarVisible:false})}  className='absolute bg-none z-10 w-[100vw] h-[100vh] top-0 left-0'></div>:null }
        <div className='relative '>
            <div onClick={()=>this.setState({calendarVisible: !calendarVisible})} className='flex items-center justify-between cursor-pointer w-[290px] bg-white rounded-[5px] p-[7px]'>
                <MiniCalendarSvg />
                <div className='flex gap-[3px] max-[400px]:flex-col text-[#94A3B8] items-center'>
                    <span>{prevDate}</span>
                    <span className='max-[400px]:hidden'>/</span>
                    <span>{nextDate}</span>
                </div>
                <DropBottomSvg />
            </div>
            {
                calendarVisible?
                <div className='bg-white absolute w-[120%] max-[320px]:w-[100%] max-[320px]:ml-0 ml-[-10%] shadow rounded-[5px] mt-[5px] mini_calendar z-20'>
                    <Calendar typeMini={true} selectCurrentDate={this.selectDate.bind(this)} onChange={()=>{}} />
                    <div className='flex p-[10px] justify-between max-[320px]:flex-col max-[320px]:gap-[10px]'>
                        <button onClick={this.getLongTime.bind(this, 'month')} className='rounded-[3px] border-blue-700 border-[1px] pl-[5px] pr-[5px] h-[25px] text-[14px] font-semibold txt-blue hover:bg-blue-800 hover:text-white'>{lang?.last_month}</button>
                        <button onClick={this.getLongTime.bind(this, 'week')} className='rounded-[3px] border-blue-700 border-[1px] pl-[5px] pr-[5px] h-[25px] text-[14px] font-semibold txt-blue hover:bg-blue-800 hover:text-white'>{lang?.last_week}</button>
                        <button onClick={this.getLongTime.bind(this, 'yesterday')} className='rounded-[3px] border-blue-700 border-[1px] pl-[5px] pr-[5px] h-[25px] text-[14px] font-semibold txt-blue hover:bg-blue-800 hover:text-white'>{lang?.yesterday}</button>
                    </div>
                </div>:null
            }
           
        </div>
       
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
})
export default connect(mapStateToProps)(MiniCalendar)
