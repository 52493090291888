import React, { Component } from 'react'

export class MoreSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    fill="none"
  >
    <circle cx={17.759} cy={17.5} r={16.5} fill="#1E398F" stroke="#1E398F" />
    <path
      fill="#fff"
      d="M20.816 17h-8.713a.454.454 0 0 0-.331.146.517.517 0 0 0-.138.354c0 .133.05.26.138.354a.455.455 0 0 0 .331.146h8.713l-3.42 3.646a.518.518 0 0 0-.137.354c0 .133.05.26.137.354a.455.455 0 0 0 .332.147.455.455 0 0 0 .332-.147l4.219-4.5a.503.503 0 0 0 .138-.354.53.53 0 0 0-.138-.354l-4.219-4.5a.455.455 0 0 0-.332-.147.455.455 0 0 0-.332.147.518.518 0 0 0-.137.354c0 .133.05.26.137.354L20.816 17Z"
    />
  </svg>
    )
  }
}

export default MoreSvg
