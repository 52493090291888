import React, { Component } from 'react'

export class CardExamSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      fill="none"
    >
      <path
        fill="#1E398F"
        d="M4.666 3a.333.333 0 0 1 .333-.333h2.667a.333.333 0 0 1 0 .666H4.999A.333.333 0 0 1 4.666 3Zm.333 1a.333.333 0 1 0 0 .667h2.667a.333.333 0 0 0 0-.667H4.999Zm-.333 3.333A.333.333 0 0 1 4.999 7h2.667a.333.333 0 0 1 0 .667H4.999a.333.333 0 0 1-.333-.334Zm.333 1A.333.333 0 1 0 5 9h2.667a.333.333 0 0 0 0-.667H4.999Z"
      />
      <path
        fill="#1E398F"
        fillRule="evenodd"
        d="M1.334 7a.333.333 0 0 1 .333-.333h1.667A.333.333 0 0 1 3.667 7v1.667A.333.333 0 0 1 3.334 9H1.667a.333.333 0 0 1-.333-.333V7Zm.667.333v1h1v-1H2Z"
        clipRule="evenodd"
      />
      <path
        fill="#1E398F"
        d="M3.903 3.236a.333.333 0 0 0-.472-.472L2.334 3.862l-.431-.431a.333.333 0 0 0-.472.471l.903.903 1.569-1.57Z"
      />
      <path
        fill="#1E398F"
        fillRule="evenodd"
        d="M1.333 0A1.333 1.333 0 0 0 0 1.333v9.334A1.333 1.333 0 0 0 1.333 12H8a1.334 1.334 0 0 0 1.333-1.333V1.333A1.333 1.333 0 0 0 8 0H1.333ZM.667 1.333a.667.667 0 0 1 .666-.666H8a.667.667 0 0 1 .667.666v9.334a.667.667 0 0 1-.667.666H1.333a.667.667 0 0 1-.666-.666V1.333Zm9.333 2a1 1 0 0 1 2 0v6.768l-1 1.5-1-1.5V3.333ZM11 3a.333.333 0 0 0-.333.333V4h.666v-.667A.333.333 0 0 0 11 3Zm0 7.399-.333-.5V4.667h.666v5.232l-.333.5Z"
        clipRule="evenodd"
      />
    </svg> 
    )
  }
}

export default CardExamSvg
