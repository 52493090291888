import React, { Component } from 'react'
import Header from './Header'
import DropSearch from '../DropSearch'
import { connect } from 'react-redux'
import { getCourses, getLessons, insertNewData } from '../../actions/MainAction'
import { getDropValue } from '../../actions/getRole'
import { mixinAlert } from '../../actions/SweetAlerts'
import { resetStateValue } from '../../redux/MainReducer'
import { getExamSettings } from '../../actions/AdminActions'
export class AdminExamSttingsModal extends Component {
    state={
        course_id:'',
        lesson_id:'',
        params : [],
        speaking_count:'0',
        essay_count:'0',
        homework_count:'0',
        speaking_practice_count:'0',
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.resetStateValue({name: "examSettingModal"})
    }

    getMaxValue(params, property){
        let arr = [];
        for(const data of params){
            arr.push(data[property])
        }
        return Math.max(...arr)
    }

    componentDidMount(){
        this.props.getCourses();
        if(this.props.examSettingModal.params.length != 0){
            this.setState({
              
                speaking_count: this.getMaxValue(this.props.examSettingModal.params, 'speaking_count'),
                essay_count: this.getMaxValue(this.props.examSettingModal.params, 'essay_count'),
                homework_count: this.getMaxValue(this.props.examSettingModal.params, 'homework_count'),
                speaking_practice_count: this.getMaxValue(this.props.examSettingModal.params, 'speaking_practice_count'),
                course_id: this.props.examSettingModal.params[0].course_id,
                lesson_id: this.props.examSettingModal.params[0].lesson_id,
            });
            let prms = [];
            for(const item of this.props.examSettingModal.params){
                prms.push({...item})
            }
            this.setState({params:prms});
           
        }
    }

    selectCourse=(data)=>{
        this.props.getLessons(data)
    }
    selectData=(lesson, params, id,e)=>{
        let newParams = [...params]
        if(newParams.length ==0){
            lesson.forEach(item=>{
                newParams.push({
                    speaking_count:'0',
                    essay_count:'0',
                    homework_count:'0',
                    speaking_practice_count:'0',
                    course_id:item.course_id,
                    lesson_id:item.id,
                    select:0
                })
            })
        }
        const findItem = newParams.find(item=>item?.lesson_id == id);
        if(findItem){
            findItem.select = e.target.checked?'1':'0';
            if(!e.target.checked){
                findItem.speaking_count = '0';
                findItem.essay_count = '0';
                findItem.homework_count = '0';
                findItem.speaking_practice_count = '0';
            }
        }
        this.setState({
            params: newParams
        })
    }
    saveBtn=(params)=>{
        if(params.length !=0){
            this.setState({btnDisable:true})
            params.forEach(item=>{
                if(item.select == 1){
                    item.speaking_count = this.state.speaking_count;
                    item.essay_count = this.state.essay_count;
                    item.homework_count = this.state.homework_count;
                    item.speaking_practice_count = this.state.speaking_practice_count;
                }else{
                    item.speaking_count = '0';
                    item.essay_count = '0';
                    item.homework_count = '0';
                    item.speaking_practice_count = '0';
                }
                
            });
            let formData = new FormData();
            formData.append("params", JSON.stringify(params))
            let url = 'exam-requirements';
            if(params[0]?.id && params[0]?.id != ''){
                url = `exam-requirements/1`;
                formData.append("_method", "put")
            }
            this.props.insertNewData(url,formData)
            .then(resp=>{
                if(resp==="success"){
                    this.props.getExamSettings(1)
                    this.closeModal();
                    mixinAlert("success", "Exam settings successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
        }else{
            mixinAlert("warning", "Please select a unit")
        }
    }
    selectAllBtn(lesson){
        let newParams = []
        if(newParams.length ==0){
            lesson.forEach(item=>{
                newParams.push({
                    speaking_count:'0',
                    essay_count:'0',
                    homework_count:'0',
                    speaking_practice_count:'0',
                    course_id:item.course_id,
                    lesson_id:item.id,
                    select:'1'
                })
            })
        }
        this.setState({
            params: newParams
        })
    }
  render() {
    const {studentCourses, lessons} = this.props;
    const {course_id,  params, essay_count, speaking_count, speaking_practice_count, homework_count, btnDisabled} = this.state;
    return (
        <div className='background_shadow flex justify-center items-center max-sm:pl-[20px] max-sm:pr-[20px]'>
            <div className='bg-white w-[700px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={"Exam Settings"} />
                <div className='p-[20px] max-h-[80vh] overflow-auto'>
                    <DropSearch selectData={this.selectCourse.bind(this)}  value={course_id} placeholder="Select a Course" options={getDropValue(studentCourses, "title","id")} title="Select a Course" />
                    <div className='grid-full-205'>
                       <div>
                            <label htmlFor='inpEssay' className='text-[13px]'>Essay</label>
                            <input  onChange={(e)=>this.setState({essay_count: e.target.value})} id='inpEssay' className='bg-gray-100 w-full h-[30px] rounded-[5px] pl-[4px] text-[13px]' placeholder={essay_count} type='number' />
                       </div>
                       <div>
                            <label htmlFor='inpSpeaking' className='text-[13px]'>Speaking</label>
                            <input  onChange={(e)=>this.setState({speaking_count: e.target.value})} id='inpSpeaking' className='bg-gray-100 w-full h-[30px] rounded-[5px] pl-[4px] text-[13px]' placeholder={speaking_count} type='number' />
                       </div>
                       <div>
                            <label htmlFor='inpSpPr' className='text-[13px]'>Sp. Practice</label>
                            <input  onChange={(e)=>this.setState({speaking_practice_count: e.target.value})} id='inpSpPr' className='bg-gray-100 w-full h-[30px] rounded-[5px] pl-[4px] text-[13px]' placeholder={speaking_practice_count} type='number' />
                       </div>
                       <div>
                            <label htmlFor='inpHw' className='text-[13px]'>PT</label>
                            <input  onChange={(e)=>this.setState({homework_count: e.target.value})} id='inpHw' className='bg-gray-100 w-full h-[30px] rounded-[5px] pl-[4px] text-[13px]' placeholder={homework_count} type='number' />
                       </div>
                    </div>
                    {
                        lessons.length !==0?
                        <div className='mt-[20px]'>
                            <button onClick={this.selectAllBtn.bind(this, lessons)} className='bg-blue rounded-[7px] h-[30px] pl-[5px] pr-[5px] text-white text-[13px] mb-[20px]'>Select all</button>
                            <ul className='flex flex-col gap-[10px]'>
                                {
                                    lessons?.map((item, k)=>{
                                        return(
                                            <li className='text-[14px] font-regular border-b border-b-dahsed flex items-center gap-[10px]' key={k}>
                                                <input  checked={params.find(data=>data.select == 1 && data.lesson_id == item.id)?true:false} onChange={this.selectData.bind(this,lessons, params, item.id )} id={item?.id} type='checkbox' />
                                                <label htmlFor={item?.id} className='w-full cursor-pointer'>{item?.title}</label>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>:null
                    }
                      <div className='flex mt-[20px] items-center justify-between'>
                            <button disabled={btnDisabled} onClick={this.closeModal.bind(this)} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Close</button>
                            <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,params)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>Save</button>
                        </div>
                </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentCourses: state.Data.studentCourses,
    lessons: state.Data.lessons,
    examSettingModal: state.Data.examSettingModal,
});
const mapDispatchToProps = {getCourses, getLessons, insertNewData, resetStateValue, getExamSettings}
export default connect(mapStateToProps, mapDispatchToProps)(AdminExamSttingsModal)
