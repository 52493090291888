import React, { Component } from 'react'

export class Instructions extends Component {
  render() {
    return (
      <div className='w-full flex flex-col gap-[10px] justify-center items-center'>
        <h3 className='text-center text-[28px] uppercase font-[700]'>General instructions</h3>
        <ul className='max-w-600px] flex flex-col gap-[7px]'>
            <li className='flex '>
                <span className='text-[16px'>The exam is divided into 3 parts. The name of each part is mentioned on the top of the page.</span>
            </li>
            <li className='flex '>
                <span className='text-[16px'>There will be an active timer to remind you of how much time is left.</span>
            </li>
            <li className='flex '>
                <span className='text-[16px'>You will use a computer and headset to read and respond to questions.</span>
            </li>
        </ul>
        <button onClick={()=>this.props.onChangePage()} className='bg-blue p-[4px_10px] text-white text-[14px] rounded-[6px]'>Next Step</button>
      </div>
    )
  }
}

export default Instructions
