import React, { Component } from 'react'

export class CloseSvg extends Component {
  render() {
    const {color="#fff", size="20"} = this.props;
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2999 16.4833L9.99994 11.1749L4.69993 16.4833L3.5166 15.2999L8.82493 9.99994L3.5166 4.69993L4.69993 3.5166L9.99994 8.82493L15.2999 3.52494L16.4749 4.69993L11.1749 9.99994L16.4749 15.2999L15.2999 16.4833Z" fill={color}/>
        </svg>
    )
  }
}

export default CloseSvg
