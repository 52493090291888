import React, { Component } from 'react'
import SelectCompany from './SelectCompany'
import SelectType from './SelectType';
import SelectDate from './SelectDate';
import { connect } from 'react-redux';
import { insertNewData, checkPublicUser } from '../../../actions/PublicActions';
import { bigAlert } from '../../../actions/SweetAlerts';
import { withHooks } from '../../../actions/withHooks';
export class ApplyModal extends Component {
    state={
        company_id:'',
        date:'',
        type:'',
        page:1,
        btnDisable: false
    }
    selectCompany=(id)=>{
        this.setState({company_id:id, page:2});
    }
    selectType=(id)=>{
        this.setState({type:id, page:3});
    }
    selectDate=(data)=>{
        this.setState({date: data?.date})
    }
    getNextPosition=(page, date, company_id, type)=>{
        if(page===1){
            return company_id !=="";
        }else if(page === 2){
            return  type !== "";
        }
    }
    applyCompany=(data)=>{
        this.setState({btnDisable: true})
        this.props.insertNewData('student-apply', data)
        .then(resp=>{
            this.props.checkPublicUser()
            this.setState({btnDisable: false})
            this.props.onClose()
            if(resp=="success"){
                this.props.navigate("/profile")
                bigAlert("Done!", "Enrollment completed!", "success")
            }else{
                bigAlert("Error!", "Something went wrong!", "error")
            }
        })
    }
  render() {
    const {company_id, page, type, date} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='w-[1100px] p-[20px] shadow bg-white rounded-[10px]'>
            <h3 className='text-[28px] font-bold text-center'>Enroll at the Center</h3>
            {
                page ===1?
                <SelectCompany selected={company_id} onSelectCompany={this.selectCompany.bind(this)} />:null
            }
            {
                page ===2?
                <SelectType selected={type} onSelectType={this.selectType.bind(this)} />:null
            }
            {
                page ===3?
                <SelectDate selected={date} onSelectDate={this.selectDate.bind(this)} />:null
            }
            <div className='flex justify-between items-center mt-[10px]'>
                <button onClick={()=>this.props.onClose()} className='bg-red h-[25px] pl-[8px] pr-[8px] text-[14px] text-white rounded-[6px]'>Close</button>
                <div className='flex gap-[20px] items-center'>
                    {
                        page !==1?
                        <button className='border border-[var(--blue)] h-[25px] pl-[8px] pr-[8px] text-[14px] txt-blue rounded-[6px]' onClick={()=>this.setState({page:page-1})}>Previous</button>:null
                    }
                    {
                        this.getNextPosition(page, date, company_id, type)?
                        <button onClick={()=>this.setState({page:page+1})} className='bg-blue h-[25px] pl-[8px] pr-[8px] text-[14px] text-white rounded-[6px]'>Next</button>:null
                    }
                    {
                        page === 3 && date !==""?
                        <button onClick={this.applyCompany.bind(this, this.state)} className='bg-blue h-[25px] pl-[8px] pr-[8px] text-[14px] text-white rounded-[6px]'>Enroll Now</button>:null
                    }
                     
                </div>
            </div>
        </div>
       
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {insertNewData, checkPublicUser}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ApplyModal))
