import React, { Component } from 'react'

export class ResourcesSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        fill="none"
      >
        <path
          stroke="#585874"
          strokeWidth={1.5}
          d="m12.375 7.688 3.375 1.687L9 12.75 2.25 9.375l3.375-1.688m6.75 3.75 3.375 1.688L9 16.5l-6.75-3.375 3.375-1.688M9 2.25l6.75 3.375L9 9 2.25 5.625 9 2.25Z"
        />
      </svg>
    )
  }
}

export default ResourcesSvg
