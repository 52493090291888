import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCompanies } from '../../actions/AdminActions';
import Card2 from '../../components/cards/Card2';
import { Link } from 'react-router-dom';
export class AccountantCompanies extends Component {
    componentDidMount(){
        this.props.getCompanies(10)
    }
  render() {
    const {adminCompanies, lang} = this.props;
    // data?.id !=19 &&
    return (
      <div>
        <div className='grid-full-250'>
            {
                adminCompanies.map((data, i)=>{
                    if(data?.id != 19 && data?.id != 29 && data?.id !=32){
                       return(
                        <div  key={i} className={`bg-white rounded-[12px] flex flex-col  justify-between p-[20px] shadow shadow-lg border border-gray-300`}>
                            <ul className='flex flex-col gap-[10px]'>
                                <li>
                                    <h3>{data?.name}</h3>
                                </li>
                            </ul>
                           {/* <div className='flex justify-between mt-[10px]'>
                                <Link target={"_blank"} to={`accountant-teachers-contracts/${data?.id}/${data?.name}`} className='p-[5px] pt-[2px] pb-[2px] bg-blue text-white text-[12px] rounded-[6px]'>Salary docs</Link>
                                <Link target={"_blank"} to={`accountant-payments/${data?.id}/${data?.name}`} className='p-[5px] pt-[2px] pb-[2px] bg-blue text-white text-[12px] rounded-[6px]'>Pays</Link>
                                <Link target={"_blank"} to={`accountant-teachers/${data?.id}/${data?.name}`} className='p-[5px] pt-[2px] pb-[2px] bg-blue text-white text-[12px] rounded-[6px]'>T. Counting</Link>
                           </div> */}
                           <div className='flex justify-center mt-[20px]'>
                            <Link target={"_blank"} to={`/accountant-teachers-contracts/${data?.id}/${data?.name}`} className='p-[5px] pt-[2px] pb-[2px] bg-blue text-white text-[12px] rounded-[6px]'>{lang?.view}</Link>
                           </div>
                        </div>
                    ) 
                    }
                    
                })
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminCompanies: state.Data.adminCompanies,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getCompanies}
export default connect(mapStateToProps, mapDispatchToProps)(AccountantCompanies)
