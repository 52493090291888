import React, { Component } from 'react'
import ContactSvg from '../svg/ContactSvg'
import GroupUsersSvg from '../svg/GroupUsersSvg'
import WriteSvg from '../svg/WriteSvg';
import SendAllModal from '../modals/SendAllModal';
import { connect } from 'react-redux';

export class SideBarHeader extends Component {
  state={
    allModal:false
  }
  render() {
    const {openModal=()=>{}, user, lang} = this.props;
    const {allModal} = this.state
    return (
        <div className='flex justify-between mt-[22px] pl-[15px] pr-[15px] items-center'>
            <h3 className='txt-light-black text-[22px] bold'>{lang?.chat}</h3>
           <div className='flex items-center gap-[10px]'>
            {
              user?.role === "company_head"?
              <button onClick={()=>this.setState({allModal:true})} className='bg-blue p-[3px] rounded-[3px]'>
                  <WriteSvg />
              </button>:null
            }
            {
              user?.role !== "student" && user?.role !== "parent"?
               <button onClick={()=>openModal()} className='bg-blue p-[3px] rounded-[3px]'>
                  <GroupUsersSvg />
              </button>:null
            }
             
           </div>
           {allModal? <SendAllModal closeModal={()=>this.setState({allModal:false})} />:null}
           
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  user: state.Data.user,
  lang: state.Data.lang,
})
export default connect(mapStateToProps)(SideBarHeader)
