import React, { Component } from 'react'
import { connect } from 'react-redux';
let audio = new Audio();
export class Section2 extends Component {
    playeAudioBtn(){
      
        audio.src = localStorage.getItem('recorderResult');
        audio.play()
    }
    componentWillUnmount(){
      audio.pause()
    }
  render() {
    const {continueBtn, studentToeflSpeakingExam, getBeforeSection} = this.props
    return (
      <div>
          <div className='writing_direction_body'>
            <p>Your response time has ended. Stop speaking now.</p>
            <p>Click on <b><u>Playback Response</u></b> to hear your recording. Once you have heard your response you may make any necessary adjustment to your microphone.</p>
            <p>Click on <b><u>Record Again</u></b>  to go back to the question and immediately record your response again.</p>
            <p>Click on <b><u>Continue</u></b>  to accept your current settings now and to continue on to the next screen.</p>
            <p>Please select your choice above.</p>
          </div>
          <div className='sp_toefl_btn_container'>
           
            <button onClick={this.playeAudioBtn.bind(this)}> Playback Response</button>
            <button onClick={()=>getBeforeSection(1)}> Record Again</button>
            <button onClick={()=>continueBtn(studentToeflSpeakingExam)}>Continue</button>
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentToeflSpeakingExam: state.Data.studentToeflSpeakingExam
});
export default connect(mapStateToProps)(Section2)