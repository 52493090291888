import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getResources } from '../../actions/MainAction';
import Card2 from '../../components/cards/Card2';
import EmptyPage from '../../components/EmptyPage';
export class Resources extends Component {
    componentDidMount(){
        this.props.getResources();
        window.scrollTo(0,0)
    }
  render() {
    const {resourcesData, lang} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="downloads" />
            {resourcesData?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-205 max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
               {
                resourcesData?.map((data, i)=>{
                    return(
                        <Card2 
                            key={i}
                            title={data?.title}
                            download={true}
                            downloadUrl={data?.image_full_url}
                            downBtnTitle={lang?.download}
                        />
                    )
                })
               }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    resourcesData: state.Data.resourcesData,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getResources}
export default connect(mapStateToProps, mapDispatchToProps)(Resources)
