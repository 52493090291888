import React, { Component } from 'react'
import azImg from '../../images/azImg.png';
import engImg from '../../images/engImg.png';
import BottomSvg from '../svg/BottomSvg';
import { azLang, engLang } from '../../lang';
import { connect } from 'react-redux';
import {changeStateValue} from "../../redux/MainReducer"
export class ChangeLang extends Component {
  state={
    visibleHidden: false,
    type: 'eng'
  }

  getTypeChange(type){
    this.setState({type})
    localStorage.setItem("celtLang", type)
    this.props.changeStateValue({
      name: 'lang',
      value: type==="az"?azLang:engLang
    })
  }
  changeLang=(type)=>{
    this.getTypeChange(type)
  }

  componentDidMount(){
    window.addEventListener("click", (e)=>{
      if(typeof e.target.className === "string" && !e.target.className.includes("nonClose")){
          this.setState({visibleHidden:false})
      }
    })
    if(localStorage.getItem("celtLang")){
      const type = localStorage.getItem("celtLang");
      this.getTypeChange(type)
    }
  }
  render() {
    const {visibleHidden, type} = this.state;
    return (
      <div className='relative nonClose'>
        <div onClick={()=>this.setState({visibleHidden: !visibleHidden})} className='flex items-center gap-[7px] cursor-pointer nonClose'>
          <img className='w-[31px] h-[31px] nonClose rounded-full' src={type==="eng"? engImg: azImg} />
          <span className='nonClose'>
            <BottomSvg />
          </span>
        </div>
        {
          visibleHidden?
          <div className='bg-white p-[10px] absolute w-full shadow left-[-6px] mt-[5px] rounded-[4px] flex flex-col justify-center gap-[6px] z-50'>
            <button onClick={this.changeLang.bind(this, "az")}>
              <img src={azImg} className='w-[25px] h-[25px] rounded-full' />
            </button>
            <button onClick={this.changeLang.bind(this, "eng")}>
              <img   src={engImg} className='w-[25px] h-[25px] rounded-full' />
            </button>
          </div>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ChangeLang)
