import moment from 'moment';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class StatisticCard extends Component {
    state={
        year: moment(new Date()).format("YYYY")
    }
    getYear(time){
        let date = new Date();
        return date.getFullYear() - time;
    }
  render() {
    const {title, data=[],  yearlyCalendar=false, onChangeYear=()=>{}, link=""} = this.props;
    const {year} = this.state;
    return (
        <div className=' rounded-b-[10px]  rounded-t-[10px]'>
            <div className='bg-blue rounded-t-[10px] h-[43px] flex items-center justify-center'>
                <h3 className='text-center text-white  font-semibold text-[16px]'>{title}</h3>
            </div>
            <ul className='bg-white rounded-b-[10px] '>
                {
                    data?.map((item, i)=>{
                        return(
                            <li className='flex items-center' key={i}>
                                {
                                    item?.data.map((params, j)=>{
                                        if(link ===""){
                                            return(
                                                <span className={` ${item?.bold? "font-[500]":"font-[300]"} border-t ${j !== item?.data?.length-1?"border-r":""} relative completeText w-1/2 flex items-center justify-center text-[14px] txt-light-black  pt-[7px] pb-[7px] h-[35px]`} key={i+""+j}>
                                                    {
                                                        String(params).length <=20?params: `${String(params)?.substring(0, 20)}...`
                                                    }
                                                    {
                                                        String(params).length >20?
                                                        <span className='txt-light-black text-[12px] bg-white z-20 font-regular longtext'> {params}</span>:null
                                                    }
                                                     
                                                </span>
                                            )
                                        }else{
                                            return(
                                                <Link to={link} className={` ${item?.bold? "font-[500]":"font-[300]"} border-t ${j !== item?.data?.length-1?"border-r":""} w-1/2 flex items-center justify-center text-[14px] txt-light-black  pt-[7px] pb-[7px] h-[35px]`} key={i+""+j}>{params}</Link>
                                            )
                                        }
                                        
                                    })
                                }
                            </li>
                        )
                    })
                }
                {
                yearlyCalendar?
                <div className='flex items-center justify-between pl-[4px] pr-[4px] border-t pt-[10px] pb-[10px]'>
                    <button onClick={()=>{onChangeYear(this.getYear(2)); this.setState({year: this.getYear(2)})}} className={`p-[6px] text-[14px] font-semibold rounded-[10px] w-1/4 ${year == this.getYear(2)? "bg-blue text-white": "bg-white txt-blue"}`}>{this.getYear(2)}</button>
                    <button onClick={()=>{onChangeYear(this.getYear(1)); this.setState({year: this.getYear(1)})}} className={`p-[6px] text-[14px] font-semibold rounded-[10px] w-1/4 ${year == this.getYear(1)? "bg-blue text-white": "bg-white txt-blue"}`}>{this.getYear(1)}</button>
                    <button onClick={()=>{onChangeYear(this.getYear(0)); this.setState({year: this.getYear(0)})}} className={`p-[6px] text-[14px] font-semibold rounded-[10px] w-1/4 ${year == this.getYear(0)? "bg-blue text-white": "bg-white txt-blue"}`}>{this.getYear(0)}</button>
                    <button onClick={()=>{onChangeYear(this.getYear(-1)); this.setState({year: this.getYear(-1)})}} className={`p-[6px] text-[14px] font-semibold rounded-[10px] w-1/4 ${year == this.getYear(-1)? "bg-blue text-white": "bg-white txt-blue"}`}>{this.getYear(-1)}</button>
                </div>:null
            }
           
            </ul>
            
        </div>
    )
  }
}

export default StatisticCard
