import React, { Component } from 'react'

export class LeftBigSvg extends Component {
  render() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            className="icon"
            viewBox="0 0 1024 1024"
        >
            <path d="M768 903.232 717.568 960 256 512 717.568 64 768 120.768 364.928 512z" />
        </svg>
    )
  }
}

export default LeftBigSvg
