import React, { Component } from 'react'
import MsgDeleteSvg from '../svg/MsgDeleteSvg'
import { connect } from 'react-redux';

export class UserCard extends Component {
  render() {
    const {title, subTitle, date, count=0, type, subTitle2="", onClick=()=>{}, user} = this.props;
    return (
        <div onClick={()=>onClick()} className='p-[16px] flex  justify-between border-b border-[#F1F5F9] group/item hover:bg-[#F8FAFC] cursor-pointer'>
            <div className='flex items-center gap-[16px]'>
                <div className='flex flex-col'>
                    <h4 title={title} className='txt-light-black text-[14px] font-bold'>{title}</h4>
                    <span title={subTitle} className='txt-light-black text-[12px] font-regular'>{subTitle && subTitle.length >12?`${subTitle.substring(0, 12)}...`:subTitle}</span>
                    {subTitle2 !== ""? <span title={subTitle2} className='txt-light-black text-[12px] font-regular'>{subTitle2}</span>:null}

                </div>
            </div>
            <div className='flex flex-col items-end gap-[5px] justify-between'>
                <div className='flex items-center gap-[5px]'>
                    {count !== 0? <span className='text-white font-bold min-w-[20px] text-center text-[12px] bg-blue rounded-full pl-[6px] pr-[6px]'>{count}</span>:null}
                    {
                        (user?.role == "company_head" ||  user?.role == "office_manager" ||  user?.role == "teacher" || user?.role == "head_teacher") && type === 'group'?
                        <button onClick={()=>onClick('delete')} className='hidden group-hover/item:inline '>
                            <MsgDeleteSvg />
                        </button> :null
                    }
                    
                </div>
                <span className='text-[12px] font-medium txt-light-black'>{date}</span>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    user: state.Data.user
})
export default connect(mapStateToProps)(UserCard)
