import React, { Component } from 'react'
import SectionHeader from '../../components/SectionHeader'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import { connect } from 'react-redux'
import { getStaffExamResults } from '../../actions/StaffAction'
import moment from 'moment'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import Card1 from '../../components/cards/Card1'
import { getExamPartScore } from '../../actions/MainAction';
import { changeStateValue } from '../../redux/MainReducer'
import EmptyPage from '../../components/EmptyPage'
import { getSATScore, ieltsScoreCalculator } from '../../actions/getRole'
import { withHooks } from '../../actions/withHooks'
export class CeltExamResults extends Component {
  state={
    buttons: [
      {
        title: this.props.lang?.all,
        value:''
      },
      {
        title: this.props.lang?.failed,
        value: "Failed"
      },
      {
        title: this.props.lang?.passed,
        value: "Passed"
      },
    ],
    selected:0,
    type:'',
    page:1,
    search:'',
    date:''
  }

  componentDidMount(){
    this.props.getStaffExamResults({page:1, keyword:'', type:'month-wise-exam-result', date:moment(new Date()).format("YYYY-MM"), orderField:"exam_title", orderMode: "asc", resultType:'', data_type:'current'});
    window.scrollTo(0,0)
  }
  getResultsByType=(data, i)=>{
    this.setState({selected: i, type: data?.value});
    this.props.getStaffExamResults({page:this.state.page, keyword:this.state.search, type:'month-wise-exam-result', date:this.state.date, orderField:"exam_title", orderMode: "asc", resultType:data?.value, data_type:'current'})
  }
  getResultsByDate=(date)=>{
    this.setState({date:date})
    this.props.getStaffExamResults({page:this.state.page, keyword:this.state.search, type:'month-wise-exam-result', date:date, orderField:"exam_title", orderMode: "asc", resultType:this.state.type, data_type:'current'})
  }
  getResultsBySearch=(search)=>{
    this.setState({search:search})
    this.props.getStaffExamResults({page:this.state.page, keyword:search, type:'month-wise-exam-result', date:this.state.date, orderField:"exam_title", orderMode: "asc", resultType:this.state.type, data_type:'current'})
  }
  loadMore=(page)=>{
    this.setState({page:page})
    this.props.getStaffExamResults({page:page, keyword:this.state.search, type:'month-wise-exam-result', date:this.state.date, orderField:"exam_title", orderMode: "asc", resultType:this.state.type, data_type:'current'})
  }
  getPlagiarism(data){
    let cases = [];
    if(data){
        data.map((pl, i)=>{
            cases.push(+pl.case)
        })
        if(cases.length !== 0){
            if(Math.max(...cases) == 3){
                return '90%';
            }else if(Math.max(...cases) == 1){
                return '40%';
            }else if(Math.max(...cases) == 0){
                return '0%';
            }
        }else{
            return '0%';
        } 
    }else{
        return '0%';
    } 
    
}
showResultBtn=(data)=>{
  if(this.props.user?.role == "company_head" || this.props.user?.role == "super_admin"){
    if(data?.exam_title?.toLowerCase().includes("digital sat")){
        this.props.navigate(`/admin-exam-results/${data?.id}/reviews`);
        return
    }
  }
  if(data?.exam?.exam_type === "pooling_standart"){
    this.props.changeStateValue({  name:'partScoreModal.position', value:true });
    this.props.changeStateValue({  name:'partScoreModal.title', value:data?.exam_title});
    this.props.changeStateValue({  name:'partScoreModal.data', value:[{
      title:data?.exam_title,
      points: ieltsScoreCalculator(data?.score),
      out_of: 9
    }]});
    return
  }
  // console.log(data)
  this.props.getExamPartScore({id:data?.id, type:data?.exam?.exam_type =="pooling_dynamic"?"dynamic":'exam', exam_type:'current'})
  .then(resp=>{
    if(resp == 'success'){
      this.props.changeStateValue({  name:'partScoreModal.position', value:true });
      this.props.changeStateValue({  name:'partScoreModal.title', value:data?.exam_title});
    }
  })
}
  render() {
    const {staffExamResults, lang} = this.props;
    const {buttons, selected} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="exam_results" />
            <div className='max-[1100px]:mt-[120px] max-[800px]:mt-[160px]'>
               <SectionHeader onSearchData={search=>this.getResultsBySearch(search)} onSelect={(date)=>this.getResultsByDate(date)} />
            </div>
           
            <div className='mt-[28px]  '>
              <HeaderButtons onClick={(data, i)=>this.getResultsByType(data, i)} buttons={buttons} selected={selected} />
            </div>
            {staffExamResults.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No results found" subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
                {
                  staffExamResults?.map((data, i)=>{
                    return(
                      <Card1 
                        key={i}
                        titleLink={`/students/${data?.student?.id}`}
                        title={data?.student?.full_name}
                        exam={data?.exam?.title}
                        status={data?.status}
                        time={data?.spend_time}
                        grade={getSATScore(data.exam_title, +data.score)}
                        statusText={data?.status == 1? lang?.pass: lang?.fail}
                        date={moment(data?.updated_at).format("HH:mm, DD/MM/YY")}
                        cheatingExam={this.getPlagiarism(data.plagiarism)}
                        onCLickBtn={this.showResultBtn.bind(this, data)}
                      />
                    )
                  })
                }
            </div>
            {
                staffExamResults?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={staffExamResults?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  staffExamResults: state.Data.staffExamResults,
  lang: state.Data.lang,
  user: state.Data.user,
});
const mapDispatchToProps = {getStaffExamResults, getExamPartScore, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(CeltExamResults))
