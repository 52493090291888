import React, { Component } from 'react'
import Header from '../components/header/Header'
import BoxHeader from '../components/support_message/BoxHeader'
import FromCard from '../components/support_message/FromCard'
import ToCard from '../components/support_message/ToCard'
import BoxFooter from '../components/support_message/BoxFooter'
import { connect } from 'react-redux'
import { getSupportMessages } from '../actions/MessageAction'
import moment from 'moment'
import { getColor, getRole } from '../actions/getRole'
import ReplyContainer from '../components/messages/ReplyContainer'
import MsgRecordAudio from '../components/support_message/MsgRecordAudio'
import MsgFilePreview from '../components/support_message/MsgFilePreview'
import SendPrivateMsg from '../components/support_message/SendPrivateMsg'
import RecycleSvg from '../components/svg/RecycleSvg'
export class SupportMessage extends Component {
    state={
        reply:'',
        deletedMsg:[],
        recording:false,
        page:1,
        lineHeight:1
    }
    replyBtn=(data)=>{
        const x = document.querySelector('#supportMsgBox');
        if(x){
            x.focus()
        }
        this.setState({reply:data})
    }
    loadMoreMsg=(page)=>{
        let inc = page;
        inc++;
        this.setState({page:inc})
        this.props.getSupportMessages(inc)
    }
    componentDidMount(){
        this.props.getSupportMessages(this.state.page)
    }
    getHeight(reply, lineHeight){
        if(reply == '' && lineHeight ==1){
            return '60vh'
        }else if(reply =="" && lineHeight !=1){
            if(lineHeight==2){
                return 'calc(60vh - 30px)'
            }else  if(lineHeight > 2){
                return 'calc(60vh - 60px)'
            }
        }else if(reply !="" && lineHeight ==1){
            return 'calc(60vh - 70px)'
        }else if(reply !="" && lineHeight !=1){
            if(lineHeight==2){
                return 'calc(60vh - 100px)'
            }else  if(lineHeight > 2){
                return 'calc(60vh - 130px)'
            }
        }
    }
  render() {
    const {supportMessages, user, lang, supportMsgFile, privateMsgModal} = this.props;
    const {reply, recording, page, lineHeight} = this.state;
    // console.log(supportMessages)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="it_support" />
            <div className='w-full pl-[27px] pr-[27px] max-[500px]:pl-[10px] max-[500px]:pr-[10px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <div className='flex gap-[21px] mt-[40px]'>
                    <div className=' bg-white rounded-[12px] w-full p-[0_16px_16px_16px] pb-[0px] flex flex-col'>
                        <BoxHeader />
                        <div  style={{ height: this.getHeight(reply, lineHeight), overflow:'auto'}} className='bg-[#F5F5F5] h-[60vh] rounded-[12px] w-full p-[16px] mt-[10px] flex flex-col-reverse'>
                            <div className='flex gap-[25px] flex-col-reverse'>
                                {
                                    supportMessages?.map((data, i)=>{
                                        if(data?.user_id === user?.id){
                                           
                                            return(
                                                <FromCard 
                                                    key={i}
                                                    msg={data?.message?data?.message:""}
                                                    date={moment(data?.created_at).format("DD/MM HH:mm")}
                                                    forward={data?.reply !== null}
                                                    forwardUser={data?.reply?.replied_message?.user?.full_name}
                                                    forwardMsg={data?.reply?.replied_message?.message}
                                                    forwardColor={getColor(data?.reply?.replied_message?.user?.role)}
                                                    msgImage={data?.image_full_url}
                                                    msgAudio={data?.audio_full_url}
                                                    msgPdf={data?.file_full_url}
                                                    msgVideo={data?.video_full_url}
                                                    onReply={this.replyBtn.bind(this, data)}
                                                    data={data}
                                                    privateMode={data?.type}
                                                    userImg={user?.avatar_full_url}
                                                /> 
                                            )
                                        }else{
                                            if((data?.type == 0 && user?.role === "support") || data?.type == 1){
                                                return(
                                                    <ToCard 
                                                        key={i}
                                                        userImg={data?.user?.avatar_full_url}
                                                        msg={data?.message?data?.message:""}
                                                        date={moment(data?.created_at).format("DD/MM HH:mm")}
                                                        forward={data?.reply !== null}
                                                        userName={data?.user?.full_name}
                                                        role={getRole(data?.user?.role,lang)}
                                                        forwardUser={data?.reply?.replied_message?.user?.full_name}
                                                        forwardMsg={data?.reply?.replied_message?.message}
                                                        color={getColor(data?.user?.role)}
                                                        forwardColor={getColor(data?.reply?.replied_message?.user?.role)}
                                                        msgImage={data?.image_full_url}
                                                        msgAudio={data?.audio_full_url}
                                                        msgVideo={data?.video_full_url}
                                                        msgPdf={data?.file_full_url}
                                                        onReply={this.replyBtn.bind(this, data)}
                                                        data={data}
                                                        privateMode={data?.type}
                                                        company={data?.user?.company?.name}
                                                    />
                                                )
                                            }
                                            
                                        }
                                    })
                                }
                               
                               {
                              supportMessages?.length >=30?
                                <div className='flex justify-center'>
                                    <button onClick={this.loadMoreMsg.bind(this, page)}>
                                        <RecycleSvg />
                                    </button>
                                </div>:null
                                }
                            </div>
                        </div>
                        {
                            reply !==""?
                            <ReplyContainer color={getColor(reply?.user?.role)} name={reply?.user?.id === user?.id? "You":reply?.user?.full_name} msg={reply?.message} onClose={()=>this.setState({reply:''})} />:null
                        }
                        {
                            !recording?
                            <BoxFooter closeReply={()=>this.setState({reply:""})} onClickRecord={()=>{this.setState({recording:true})} } reply={reply} afterCallFunction={this.props.getSupportMessages} afterCallParams={[this.state.page]} />:
                            <MsgRecordAudio reply={reply} afterCallFunction={this.props.getSupportMessages} afterCallParams={[this.state.page]} onClickRecord={()=>this.setState({recording:false})} />
                        }
                        
                    </div>
                    {privateMsgModal?.position? <SendPrivateMsg afterCallFunction={this.props.getSupportMessages} afterCallParams={[this.state.page]} />:null}
                    {supportMsgFile?.position? <MsgFilePreview  onClose={()=>{this.setState({reply:''})}} afterCallFunction={this.props.getSupportMessages} afterCallParams={[this.state.page]} />:null}
                </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    supportMessages: state.Data.supportMessages,
    user: state.Data.user,
    lang: state.Data.lang,
    supportMsgFile: state.Data.supportMsgFile,
    privateMsgModal: state.Data.privateMsgModal,
});
const mapDispatchToProps = {getSupportMessages}
export default connect(mapStateToProps, mapDispatchToProps)(SupportMessage)
