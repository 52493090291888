import React, { Component } from 'react'

export class CardTypeSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="var(--red)"
          fillRule="evenodd"
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12ZM12 9.876 8.814 6.69A1.502 1.502 0 0 0 6.69 8.814L9.876 12 6.69 15.186a1.502 1.502 0 1 0 2.124 2.124L12 14.124l3.186 3.186a1.502 1.502 0 0 0 2.124-2.124L14.124 12l3.186-3.186a1.502 1.502 0 0 0-2.124-2.124L12 9.876Z"
          clipRule="evenodd"
        />
      </svg>
    )
  }
}

export default CardTypeSvg
