import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getStaffSpeakingReview, recheckSpeaking } from '../../actions/StaffAction';
import Header from '../../components/header/Header';
import Search from '../../components/Search';
import Card1 from '../../components/cards/Card1';
import moment from 'moment';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import { getSpeakingAnswer } from '../../actions/MainAction';
import { getGradeAll, getGrades, getRole } from '../../actions/getRole';
import { changeStateValue } from '../../redux/MainReducer';
import { getTurboModel, getMistralModel } from '../../actions/robotActions';
import { getSpeakingGpt, getSpeakingGradeMistral, getSpeakingMistral } from '../../actions/getReviewGpt';
import { insertNewData } from '../../actions/MainAction';
import EmptyPage from '../../components/EmptyPage';
import AdminReviewLogModal from '../../components/modals/AdminReviewLogModal';
import { confirmAlert } from '../../actions/SweetAlerts';
export class AdminSpeakingReviews extends Component {
    state={
        search:'',
        page:1,
        logModal:{
            position:false,
            review:'',
            grade:''
        }
    }
    onSearchData=(search)=>{
        this.setState({search:search});
        this.props.getStaffSpeakingReview({keyword: search, query_type: 'student_answer', type: 'super_admin', teacher_student_assign: 'teacher_student_assign', page:1})
    }
    componentDidMount(){
        this.props.getStaffSpeakingReview({keyword: '', query_type: 'student_answer', type: 'super_admin', teacher_student_assign: 'teacher_student_assign', page:1});
        window.scrollTo(0,0)
    }
    loadMore=(page)=>{
        this.setState({page:page})
        this.props.getStaffSpeakingReview({keyword: this.state.search, query_type: 'student_answer', type: 'super_admin', teacher_student_assign: 'teacher_student_assign', page:page})
    }
    getGrade(reviews, autoGrade, grade, text){
       
       
        if(!text){
            
            return grade
        }else{
            const check = text.split(' ');
            if(check.length<0){
                return grade
            }
        }
        if(reviews.length == 0){
            return grade
        }else{
            let grades = 0;
            for(const data of reviews){
                grades+=data.grade;
            }
            if(autoGrade){
                return  ((grades+autoGrade.grade)/(reviews.length+1)).toFixed(0)
               
            }else{
               
                return grade;
            }
        }
    }
    closeOpenBtn=(data)=>{
      
        if(this.props.user?.role == "head_teacher" || this.props.user?.role == "super_admin"){
            if(data?.is_closed === 1){
                let formData = new FormData();
                formData.append("_method", "put")
                this.props.insertNewData(`speakingsAnswerUpdate/${data?.id}`, formData)
                .then(resp=>this.props.getStaffSpeakingReview({keyword: this.state.search, query_type: 'student_answer', type: 'super_admin', teacher_student_assign: 'teacher_student_assign', page:this.state.page}))
            }else{
                if(data?.grade !== null){
                    let formData = new FormData();
                    formData.append("_method", "put")
                    this.props.insertNewData(`speakingsAnswerUpdate/${data?.id}`, formData)
                    .then(resp=>this.props.getStaffSpeakingReview({keyword: this.state.search, query_type: 'student_answer', type: 'super_admin', teacher_student_assign: 'teacher_student_assign', page:this.state.page}))
                }
            }
        }
    }

    openModal=(data, type="")=>{
       
       
       
        this.props.getSpeakingAnswer(data?.speaking?.id, data?.user_id, data?.id)
        .then(resp=>{
           
            if(resp != 'error'){
                let check = 0;
                if(resp?.answare[0]?.text){
                    check = resp?.answare[0]?.text.split(' ')?.length;
                }
                const params = {
                    position:true,
                    type:'speaking',
                    modalType: 'teacher_review',
                    title: resp?.title,
                    question: resp?.question,
                    speaking: resp?.answare[0]?.image_full_url,
                    reviews: resp?.reviews[0]?.review,
                    teacher: resp?.reviews[0]?.user?.full_name,
                    role: getRole(resp?.reviews[0]?.user?.role, this.props.lang),
                    stuent: data?.user?.full_name,
                    robot_review: check>=0 && type==""? data?.robot_review:null,
                    grade: resp?.reviews[0]?.grade,
                    speaking_robo_waiting: check>=0 && !data?.robot_review ? true:false,
                    not_check_robo_text:'',
                    
                }
                if(!resp?.answare[0]?.text){
                    // params.not_check_robo_text =this.props.lang?.speaking_robo_wrong_text
                }
               
                if( !data?.robot_review ||  type == "recheck"){
                    if(type == "recheck"){
                        setTimeout(()=>{
                            this.props.changeStateValue({
                                name:'mainModal.speaking_robo_waiting',
                                value:true
                            })
                        }, 500)
                    }
                    recheckSpeaking(data?.id)
                    .then(resp=>{
                        // console.log(resp)
                        if(resp !==""){
                            this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade:resp?.grade, review: resp?.review}});
                            this.props.changeStateValue({name: 'mainModal.not_check_robo_text', value: ''});
                            this.props.getStaffSpeakingReview({keyword: this.state.search, query_type: 'student_answer', type: 'super_admin', teacher_student_assign: 'teacher_student_assign', page:this.state.page})
                        }else{
                            this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade:resp?.grade, review: resp?.review}});
                            this.props.changeStateValue({name: 'mainModal.not_check_robo_text', value: ''});
                        }
                    })
                   
                    // this.props.getMistralModel(getSpeakingGradeMistral(resp, resp?.answare[0]?.text))
                    // .then(respGrade=>{
                    //     let grade_log = respGrade;
                    //     let grade = 1;
                    //     let grade_error = '';
                    //     try{
                    //         grade = JSON.parse(respGrade)?.grade
                    //     }catch(err){
                    //         grade_error=respGrade
                    //         grade =  getGrades(respGrade)
                    //     }
                      
                    //     this.props.getMistralModel(getSpeakingMistral(resp, resp?.answare[0]?.text, data?.user?.full_name))
                    //     .then(reviewResp=>{
                    //         let review_log = reviewResp;
                    //         this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade:grade, review: reviewResp}});
                    //         this.props.changeStateValue({name: 'mainModal.not_check_robo_text', value: ''});
                    //         const review_params={
                    //             speaking_answer_id: data?.id,
                    //             review: reviewResp,
                    //             close: 1,
                    //             grade: grade,
                    //             grade_error: grade_error,
                    //             grade_log: grade_log,
                    //             review_log:review_log
                    //         }
                    //         this.props.insertNewData("speaking-auto-grade", review_params)
                    //         .then(resp=>this.props.getStaffSpeakingReview({keyword: this.state.search, query_type: 'student_answer', type: 'super_admin', teacher_student_assign: 'teacher_student_assign', page:this.state.page}))
                    //     })
                    // })
                }else{
                    // if(!data?.robot_review && !resp?.answare[0]?.text){
                    //     let notReview = `Dear Student,\n\nWe encountered issues with your recent speaking recording—it was not clear enough to review. Could you please record and submit it again? Here are some quick tips: \n\n 1. Check Your Equipment: Ensure your microphone works.\n\n 2. Find a Quiet Spot: Record in a place with minimal background noise. \n\n 3. Speak Clearly: Talk directly into the microphone and check the recording before submitting. \n\n Your input is important, and we're here to help if you need it. Thanks for your understanding and prompt attention to this matter. \n\nBest, \nSelty teacher`
                    //     const review_params={
                    //         speaking_answer_id: data?.id,
                    //         review: notReview,
                    //         close: 1,
                    //         grade: '0'
                    //     }
                    //    setTimeout(()=>{
                    //     this.props.changeStateValue({name: 'mainModal.robot_review', value: {grade:'0', review: notReview}});
                    //     this.props.changeStateValue({name: 'mainModal.not_check_robo_text', value: ''});
                    //    }, 1000)
                    //     this.props.insertNewData("speaking-auto-grade", review_params)
                    //     .then(resp=>this.props.getStaffSpeakingReview({keyword: this.state.search, query_type: 'student_answer', type: 'super_admin', teacher_student_assign: 'teacher_student_assign', page:this.state.page}))
                    // }
                }
                this.props.changeStateValue({name: 'mainModal', value: params});
            }
        })
    }
    matchDate(created_at){
        const date = moment(new Date(created_at)).add(1, 'week').format('YYYY/MM/DD');
        const newDate = new Date(date);
        const now = new Date();
       return newDate <= now;
    }

    getClosePosition(data){
      
        if(this.props.user?.role === "head_teacher"){
            if(data?.review.length === 0 || this.matchDate(data?.created_at)){
                return true;
            }else{
                return false;
            }
        }else if(this.props.user?.role === "teacher" || this.props.user?.role === "speaking_teacher"){
            if(data?.review.length === 0 || this.matchDate(data?.created_at)){
                return true;
            }else{
                return false;
            }
        }else{
            return false
        }
    }
    checkOpen(data){
        if(data?.review.length !==0){
            return false
        }else if(this.matchDate(data?.created_at)){
            return false
        }else{
            return true
        }
    }
    openLog=(data)=>{
        this.setState({
            logModal:{
                position:true,
                review:data?.review_log,
                grade:data?.grade_log
            }
        })
    }
    adminZeroClick=(data)=>{
        confirmAlert("warning","Are you sure?","You won't be able to revert this!","Yes, Zero it." )
        .then(respAlert=>{
            if(respAlert === "success"){
                this.props.insertNewData('auto-zero-speaking', {id:data?.id})
                .then(resp=>{
                    this.props.getStaffSpeakingReview({keyword: this.state.search, query_type: 'student_answer', type: 'super_admin', teacher_student_assign: 'teacher_student_assign', page:this.state.page})
                })
            }
        })
        
    }
  render() {
    const {staffSpeaking, lang} = this.props;
    const {logModal} = this.state;
    // console.log(staffSpeaking)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="sp_reviews" />
            <div className='flex justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[160px]'>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            {staffSpeaking.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
                {
                    staffSpeaking?.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.speaking?.title}
                                userLink={`/students/${data?.user?.id}`}
                                name={data?.user?.full_name}
                                grade={getGradeAll(data.review,  data.robot_review, data.grade, data.text)}
                                date={ moment(data?.created_at).format("DD/MM/YYYY")}
                                // close={data.is_closed === 0? lang?.status_close: lang?.status_closed}
                                onLineClick={this.closeOpenBtn.bind(this, data)}
                                status={getGradeAll(data.review,  data.robot_review, data.grade, data.text) >=data?.speaking?.course_prompt?.grade?1:0}
                                statusText={getGradeAll(data.review,  data.robot_review, data.grade, data.text) >=data?.speaking?.course_prompt?.grade?lang?.pass: lang?.fail}
                                onCLickBtn={this.openModal.bind(this, data, "")}
                                // greenStatus={true}
                                reCheckBtn={true}
                                // reCheckBtn={(data?.robot_review?.grade==1 && data?.robot_review?.grade_error) && data?.text && data?.robot_review?.recheck_count <=3?true:false}
                                onReCheck={this.openModal.bind(this, data, "recheck")}
                                adminLogBtn={true}
                                adminZeroBtn={true}
                                adminLogOnclick={this.openLog.bind(this, data?.robot_review)}
                                adminZeroClick={this.adminZeroClick.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
            {
                staffSpeaking?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={staffSpeaking?.length} />:null
            }
             {
                logModal?.position?
                <AdminReviewLogModal grade={logModal?.grade} review={logModal?.review} onClose={()=>this.setState({logModal:{position:false}})} />:null
            }
            
    </div>
    )
  }
}
const mapStateToProps = (state) =>({
    staffSpeaking: state.Data.staffSpeaking,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getStaffSpeakingReview, getSpeakingAnswer, changeStateValue, getTurboModel, insertNewData, getMistralModel}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSpeakingReviews)


