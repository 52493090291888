import React, { Component } from 'react'

export class StatCard extends Component {
    getStatisticsValue(data){
        if(data > 100){
            return 100;
        }else if(data === 'NaN'){
            return 0;
        }else{
            return data
        }
    }
  render() {
    const {data} = this.props
    return (
      <div className='bg-white  rounded-b-[10px]  rounded-t-[10px]'>
        <div className='bg-blue rounded-t-[10px] h-[43px] flex items-center justify-center'>
            <h3 className='text-center text-white  font-semibold text-[16px]'>{data?.teacher_name}</h3>
        </div>
        <div>
            <ul>
                <li className='flex justify-between border-b'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>Student</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.studentCount}</span>
                    <div className='flex flex-col w-1/3 pt-[10px] pb-[10px] items-center justify-center'>
                        <span className='text-[14px] border-b border-dashed w-full text-center pt-[5px] pb-[5px]'>G.Eng: {data?.general_english_students}</span>
                        <span className='text-[14px] border-b border-dashed w-full text-center pt-[5px] pb-[5px]'>SAT I: {data?.sat_students}</span>
                        <span className='text-[14px] pt-[5px]'> IELTS: {data?.ielts_students}</span>
                    </div>
                </li>
                <li className='flex justify-between border-b'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>Check-Ins</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.attendance}</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]'></span>
                </li>
                <li className='flex justify-between border-b'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>Essays</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.essay}</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]'>{ this.getStatisticsValue((((data.essay)*100)/(data.general_english_students*8)).toFixed(0))}%</span>
                </li>
                <li className='flex justify-between border-b'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>Reports</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.assessment}</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]'>{this.getStatisticsValue(((data.assessment*100)/(data.attendance)).toFixed(0))}%</span>
                </li>
                <li className='flex justify-between border-b'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>Speakings</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.speaking}</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]'>{ this.getStatisticsValue((((data.speaking)*100)/(data.general_english_students*4)).toFixed(0))}%</span>
                </li>
                <li className='flex justify-between border-b'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>G.Eng Exam</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.monthly_general_english_exams_taken}</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]'>{ this.getStatisticsValue(((data.monthly_general_english_exams_taken*100)/(data.general_english_students*4)).toFixed(0))}%</span>
                </li>
                <li className='flex justify-between border-b'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>SAT Exam</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.monthly_sat_exam_takens}</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]'>{ this.getStatisticsValue(((data.monthly_sat_exam_takens*100)/(data.sat_students*4)).toFixed(0))}%</span>
                </li>
                <li className='flex justify-between border-b'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>IELTS Exam</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.monthly_ielts_exam_takens}</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]'>{ this.getStatisticsValue(((data.monthly_ielts_exam_takens*100)/(data.ielts_students*8)).toFixed(0))}%</span>
                </li>
                <li className='flex justify-between border-b'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>PT G.Eng</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.monthly_general_english_homework_taken}</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]'>{ this.getStatisticsValue(((data.monthly_general_english_homework_taken*100)/(data.general_english_students*15)).toFixed(0))}%</span>
                </li>
                <li className='flex justify-between rounded-b-[10px]'>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px] border-r inline-flex items-center justify-center'>PT SAT</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]  border-r inline-flex items-center justify-center'>{data?.monthly_sat_homework_taken}</span>
                    <span className='w-1/3 text-[14px] text-center pt-[10px] pb-[10px]'>{ this.getStatisticsValue(((data.monthly_sat_homework_taken*100)/(data.sat_students*3)).toFixed(0))}%</span>
                </li>
               
            </ul>
        </div>
      </div>
    )
  }
}

export default StatCard
