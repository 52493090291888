import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getCoursesData } from '../../actions/MainAction';
import { withHooks } from '../../actions/withHooks';
import Header from '../../components/header/Header';
import Card3 from '../../components/cards/Card3'
import EmptyPage from '../../components/EmptyPage';
import { getLessons } from '../../actions/MainAction';
import Card2 from '../../components/cards/Card2';
import CloseSvg from '../../public_pages/components/svg/CloseSvg';
export class UnitsPage extends Component {
    state={
        video:''
    }
    componentDidMount(){
        this.props.getCoursesData(this.props.params.id);
        this.props.getLessons(this.props.params.id)
        window.scrollTo(0,0)
    }

    onClickBtn=(data)=>{
        this.setState({video: data?.video_full_url})
    }
  render() {
    const {coursesData, lessons, lang} = this.props;
    const {video} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={coursesData?.title} />
            {lessons?.filter(a=>a.video_file !=null)?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-205 max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                {
                    lessons?.map((data, i)=>{
                        if(data?.video_file){
                           return(
                                <Card2
                                    key={i}
                                    title={data?.title}
                                    btnTitle={lang?.view}
                                    onClickBtn={this.onClickBtn.bind(this, data)}
                                />
                            ) 
                        }
                        
                    })
                }
            </div>
            {
                video !==""?
                <div className='bg-[rgb(31,41,55)] fixed top-0 left-0 w-full h-screen flex items-center justify-center z-[999]'>
                    <button onClick={()=>this.setState({video:''})} className='w-[40px] h-[40px] inline-flex items-center justify-center rounded-full top-[30px] left-[30px] absolute border border-gray'>
                    <CloseSvg />
                    </button>
                    <video controls className='max-h-[70vh] max-w-[80%]' src={video} />
                </div>:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    coursesData: state.Data.coursesData,
    lessons: state.Data.lessons,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getCoursesData, getLessons}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(UnitsPage))


