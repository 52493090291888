import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAdminPrompts } from '../../actions/AdminActions';
import HeaderButtons from '../../components/HeaderButtons';
import Header from '../../components/header/Header';
import { changeStateValue } from '../../redux/MainReducer';
import Card1 from '../../components/cards/Card1';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
export class AdminPrompts extends Component {
    state={
        activePage:0,
        pageType: 'speaking'
    }
    componentDidMount(){
        this.props.getAdminPrompts(this.state.pageType)
    }
    promptType=()=>{
        const btns = [
            {
                label: "Speakings",
                value: "speaking"
            },
            {
                label: "Essays",
                value: "essays"
            },
            {
                label: "IAL Exams",
                value: "a_level"
            },
            {
                label: "Sp. Practice",
                value: "sp_practice"
            },
        ]
        return btns
    }
    openModal=(data)=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                course_id:data?.course_id? data?.course_id:'',
               
                type:data?.type? data?.type:'',
                prompt:data?.prompt? data?.prompt:'',
                second_prompt:data?.second_prompt? data?.second_prompt:'',
                word_count:data?.word_count? data?.word_count:'',
                grade:data?.grade? data?.grade:'',
                duration:data?.duration? data?.duration:'',
                grade_prompt: this.state.pageType=="sp_practice"? data?.grade_prompt?  data?.grade_prompt: '':undefined,

                afterCallFunction:this.props.getAdminPrompts,
                afterCallParams:[this.state.pageType],
                typeOptions: this.promptType(),
                requireds:["course_id", "type", "prompt"],
                url: data?.id? `robo-prompt/${data?.id}`:'robo-prompt',
                modalTitle: data?.id? `Edit Prompt`:'Add Prompt',
            }
        });
    }

    onDeleteData=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`robo-prompt/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getAdminPrompts(this.state.pageType)
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }

    getDataByType=(data, index)=>{
        this.setState({activePage:index, pageType: data?.id});
        this.props.getAdminPrompts(data?.id)

    }
  render() {
    const {adminPrompt, user} = this.props;
    const {activePage} = this.state;
    const btns = [
        {
            title: "Speakings",
            id: "speaking"
        },
        {
            title: "Essays",
            id: "essays"
        },
        {
            title: "IAL Exams",
            id: "a_level"
        },
        {
            title: "Sp. Practice",
            id: "sp_practice"
        },
    ]
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Prompts" />
            <div className='mt-[30px] flex justify-between items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons onClick={(data, index)=>this.getDataByType(data, index)} buttons={btns} selected={activePage} />
                <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Prompt", id:0}]} selected={0} />
            </div>
            <div className='mt-[30px] grid-full-250'>
                {
                    adminPrompt.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.courses?.title}
                                cheating={btns.find(item=>item?.id==data?.type)?.title}
                                status={1}
                                statusBisible={false}
                                btn={false}
                                btn2={true}
                                btn2Text={"Edit"}
                                btn2Color={"bg-gray-400"}
                                onCLickBtn={this.openModal.bind(this, data)}
                                adminDelete={user?.role === "super_admin"}
                                onDeleteBtn={this.onDeleteData.bind(this, data)}
                            />
                        )
                    })
                }
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminPrompt: state.Data.adminPrompt,
    user: state.Data.user,
});
const mapDispatchToProps = {getAdminPrompts, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminPrompts)
