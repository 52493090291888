import { MAIN_API, MAIN_APIV2, PUBLIC_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";
import axios from "axios";
import { bigAlert, mixinAlert } from "./SweetAlerts";
import Swal from "sweetalert2";
import { loginToPublicStaff } from "./PublicActions";
const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
        "Accept": "application/json",
        // "Content-Type": "application/json"
    }
}

export const getStudentCoach = () => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-coach`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'studentCoach', value: resp?.data}))
        return resp?.data?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
    })
}
export const getStudentWeeklyCoach = () => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/student-weekly-coach`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'studentWeeklyCoach', value: resp?.data}))
        return resp?.data?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
    })
}
export const getTeacherCoach = (type) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/teacher-coach?query_type=${type}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'teacherCoach', value: resp?.data}))
        return resp?.data?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}
export const getCompanyCoach = (type) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/company-coach?query_type=${type}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'companyCoach', value: resp?.data}))
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
    })
}
export const getCompanyDataCoach = (company_id, page=1) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/audit-company-coach?company_id=${company_id}&page=${page}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'companyCoach', value: resp?.data}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        // dispatch(getErrors(err))
    })
}
export const getAuditCoach = (page=1) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/audit-coach?page=${page}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'adminCompanies', value: resp?.data}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}
export const getWeeklyAuditCoach = (page=1) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/audit-weekly-coach`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'adminWeeklyCompanies', value: resp?.data}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}
export const getWeeklyAuditCoachOne = (company_id) => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/company-weekly-coach-audit?company_id=${company_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'auditWeeklyCoachOne', value: resp?.data?.students}))
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}
export const getEndOfCourseData = (company_id="", type="all") => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/not-endof-course?company_id=${company_id}&type=${type}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'endOfCourseData', value: resp?.data}))
        // console.log("ddfff", resp?.data)
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}
export const getFirstFailedCoach = (company_id="") => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/first-failed-coach?company_id=${company_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'firstFailedCoach', value: resp?.data}))
        // console.log("ddfff", resp?.data)
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}
export const getAssesmentsCoach = (company_id="") => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/assessment-coach?company_id=${company_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'assessmentCoach', value: resp?.data}))
        // console.log("ddfff", resp?.data)
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}
export const getExceptionCoach = (company_id="") => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/exception-coach?company_id=${company_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'exceptionCoach', value: resp?.data}))
        // console.log("ddfff", resp?.data)
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}


export const getAllExceptionCoach = () => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/all-exception-coach`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'allExceptionCoach', value: resp?.data}))
        // console.log("ddfff", resp?.data)
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}



export const getStudentFailedExams = (start_date,company_id="") => async dispatch => {
    // dispatch(changeStateValue({name: "loader", value:true}));
    return axios.get(`${MAIN_API}/failed-exam-coach?company_id=${company_id}&start_date=${start_date}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'studentFailedExamsCoach', value: resp?.data}))
        // console.log("ddfff", resp?.data)
        return resp?.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        console.log(err.response)
        // dispatch(getErrors(err))
    })
}


