import React, { Component } from 'react'
import ReplySvg from '../svg/ReplySvg'
import EraseSvg from '../svg/EraseSvg'
import { connect } from 'react-redux';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData, insertNewData } from '../../actions/MainAction';
import { getSupportMessages} from '../../actions/MessageAction';
import PinSvg from '../svg/PinSvg';
import PrivateMsgSvg from '../svg/PrivateMsgSvg';
export class ToolTop extends Component {
    deleteData=(data)=>{

        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(respAlert=>{
            if(respAlert === "success"){
                let url = `support-message/${data?.id}`
                this.props.deleteData(url)
                .then(resp=>{
                    if(resp === "success"){
                        mixinAlert("success", "Message deleted successfully!");
                        this.props.getSupportMessages(1)
                        this.props.onClose()
                    }else{
                        mixinAlert("error", "Something went wrong!");
                    }
                })
            }
        })

    }
    onClickPin=(data, boxData)=>{
        let formData = new FormData();
        formData.append("message_id", data?.id);
        this.props.insertNewData('support-msg-pin', formData)
        .then(resp=>{
            this.props.onClose()
            if(resp=="success"){
                mixinAlert("success", "Message pinned successfully.")
            }else{
                mixinAlert("error", "Something went wrong.")
            }
        })
    }

    onClickPrivate=(data)=>{
        const obj = {
            user_id: data?.user?.id,
            type:'0'
        }
        this.props.insertNewData('support-message', obj)
        .then(resp=>{
            this.props.onClose()
            this.props.getSupportMessages(1)
            if(resp=="success"){
                mixinAlert("success", "Private message created successfully.")
            }else{
                mixinAlert("error", "Something went wrong.")
            }
        })
    }
  render() {
    const {left, onReply=()=>{}, user, data, lang, msgBoxInfo} = this.props;
    return (
        <div className={`w-[80px] p-[5px] gap-[6px] rounded-[10px] shadow bg-white absolute ${left? 'right-[20px] top-[-15px] max-[650px]:right-[-80px]':'right-[-78px] top-[-15px]' }  flex flex-col z-50`}>
            <button onClick={()=>onReply()} className='flex items-center gap-[5px] text-[13px] txt-light-black font-[300]'>
                <ReplySvg />
                <span>{lang?.reply}</span>
            </button>
            <button onClick={this.onClickPin.bind(this, data, msgBoxInfo)} className='flex items-center gap-[5px] text-[13px] txt-light-black font-[300]'>
                <PinSvg />
                <span>Pin</span>
            </button>
            {
                user?.role === "support" || user?.id === data?.user_id?
                <button onClick={this.deleteData.bind(this, data)} className='flex items-center gap-[5px] text-[13px] txt-light-black font-[300]'>
                    <EraseSvg />
                    <span>{lang?.delete}</span>
                </button>:null
            }
            {
                user?.role === "support" ?
                <button onClick={this.onClickPrivate.bind(this, data)} className='flex items-center gap-[5px] text-[13px] txt-light-black font-[300]'>
                <PrivateMsgSvg />
                <span>P. msg</span>
            </button>:null
            }
        </div>
    )
  }
}
const mapStateToProps  =(state)=>({
    user: state.Data.user,
    msgBoxInfo: state.Data.msgBoxInfo,
    lang: state.Data.lang,
});
const mapDispatchToProps = {deleteData, getSupportMessages, insertNewData,}
export default connect(mapStateToProps, mapDispatchToProps)(ToolTop)
