import React, { Component } from 'react'
import CloseSvg from '../../../../components/svg/CloseSvg'
import ExamCheckSvg from '../../../../components/svg/ExamCheckSvg'
import ImageInpSvg from '../../../../components/svg/ImageInpSvg'
import { connect } from 'react-redux'
import { changeStateValue } from '../../../../redux/MainReducer'
import MsgDeleteSvg from '../../../../components/svg/MsgDeleteSvg'
export class ImgAnswerBox extends Component {
    state={
        file: ''
    }
    getBase64=(file)=>{
        return new Promise((res, rej)=>{
          let reader = new FileReader();
          reader.onload = ()=>{
            res(reader.result)
          }
          reader.readAsDataURL(file)
        })
    }

    getInpFile(row, type, e){
        if(type === "delete"){
            e.preventDefault()
            this.deleteFile(row)
            return
        }
        let answer={};
        for(const property in this.props.adminExamModalData?.question_option[row]){
          answer[property] = this.props.adminExamModalData?.question_option[row][property]
        }
        let allAnswer = [];
        for(const item of this.props.adminExamModalData?.question_option){
          let x= {}
          for(const property in item){
            x[property] = item[property]
          }
          allAnswer.push(x)
        }
        answer.title = e.target.files[0];
        allAnswer[row]=answer;
        this.props.changeStateValue({
            name: 'adminExamModalData.question_option',
            value: allAnswer
        })
        this.getBase64(e.target.files[0])
        .then(resp=>{
            this.setState({
                file: resp
            })
        })
    }
   
    checkCorrectBtn(row){
        let answer={};
        for(const property in this.props.adminExamModalData?.question_option[row]){
            answer[property] = this.props.adminExamModalData?.question_option[row][property]
        }
        let allAnswer = [];
        for(const item of this.props.adminExamModalData?.question_option){
            let x= {}
            for(const property in item){
                x[property] = item[property]
            }
            allAnswer.push(x)
        }
        for(const data of allAnswer){
            if(data){
                data.check =false;
                data.points = 0;
            }
            
        }
        answer.check = !answer.check;
        allAnswer[row]=answer;
        this.props.changeStateValue({
            name: 'adminExamModalData.question_option',
            value: allAnswer
        })
    }
    removeAnswer(row){
        let allAnswer = [];
        for(const item of this.props.adminExamModalData?.question_option){
            let x= {}
            for(const property in item){
                x[property] = item[property]
            }
            allAnswer.push(x)
        }
        allAnswer.splice(row, 1);

        this.props.changeStateValue({
            name: 'adminExamModalData.question_option',
            value: allAnswer
        })
    }

    deleteFile=(row)=>{
       
        let answer={};
        for(const property in this.props.adminExamModalData?.question_option[row]){
          answer[property] = this.props.adminExamModalData?.question_option[row][property]
        }
        let allAnswer = [];
        for(const item of this.props.adminExamModalData?.question_option){
          let x= {}
          for(const property in item){
            x[property] = item[property]
          }
          allAnswer.push(x)
        }
        answer.title = "";
        allAnswer[row]=answer;
        this.props.changeStateValue({
            name: 'adminExamModalData.question_option',
            value: allAnswer
        })
        this.setState({
            file: ""
        })
    }
  render() {
    const {correct = false, data,  allData, row} = this.props;
    const {file} = this.state;
    return (
        <div className={`border rounded-[8px] border-[2px] p-[10px] ${correct? "border-[#06991f]": "border-gray-400 "} hover:border-[#06991f] cursor-pointer`}>
         <div className='flex items-center justify-between'>
            <span onClick={this.checkCorrectBtn.bind(this, row)} className={`text-[15px] ${correct? "text-[#06991f]":"txt-blue "} inline-flex items-center gap-[5px]`}>{correct? <ExamCheckSvg />:null} {correct?"Correct Answer":"Mark as correct"}</span>
            {
                row !=0?
                <button onClick={this.removeAnswer.bind(this, row)}>
                  <CloseSvg color="var(--red)" />
              </button>:null
            }
        </div>
        
        <div className=' mt-[10px] flex gap-[10px] items-end'>
            <label htmlFor={`answerImage${row}`} className='w-full outline-none txt-blue cursor-pointer  border-b border-b-[2px] inline-flex items-center justify-between'>{data.title != ''? data?.title?.name? data?.title?.name.length<15?data?.title?.name:`${data?.title?.name.substring(0, 15)}...`  :data?.title.length<15?data?.title:`${data?.title.substring(0, 15)}...`: 'Add Image file'} {data?.title !=""?  <button className='hover:bg-gray-100 p-[3px] rounded-[4px]' onClick={this.getInpFile.bind(this, row, "delete")}> <MsgDeleteSvg /> </button>:null} </label>
            <label htmlFor={`answerImage${row}`} className='rounded-[6px] cursor-pointer border-gray-500 border border-dashed min-w-[80px] w-[80px] h-[80px] inline-flex items-center justify-center flex-col'>
                {
                    file !== ""?
                    <img className='w-full h-full rounded-[6px]' src={file} />:
                    data.answer_full_url && data.answer_full_url != ''?
                    <img className='w-full h-full rounded-[6px]' src={data.answer_full_url} />:
                    <>
                    <ImageInpSvg />
                    <span>IMAGE</span>
                    </>
                }
                
                
            </label>
            <input onChange={this.getInpFile.bind(this, row, "")} className='hidden' id={`answerImage${row}`} type='file' accept='image/*' />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    adminExamModalData: state.Data.adminExamModalData
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ImgAnswerBox)
