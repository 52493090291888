import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Card2 from '../../components/cards/Card2'
import { connect } from 'react-redux'
import { getCourses, getSpeakingByCourse } from "../../actions/MainAction";
import { changeStateValue } from '../../redux/MainReducer'
import DropSearch from '../../components/DropSearch'
import EmptyPage from '../../components/EmptyPage';
import { withHooks } from '../../actions/withHooks';
import { bigAlert } from '../../actions/SweetAlerts';
import { getGradeCheck } from '../../actions/getRole';
export class Speakings extends Component {
  state={
    courseId: ''
  }
  componentDidMount(){
    this.props.getCourses();
    this.props.changeStateValue({name: 'studentCourses', value:[]})
    this.props.changeStateValue({name: 'lessons', value:[]});
    window.scrollTo(0,0);
    const checkCourse =this.props.params?.courseId;
    if(checkCourse){
      this.setState({courseId:checkCourse})
      this.props.getSpeakingByCourse(checkCourse)
    }
  }
  getSpeakings(data){
    this.setState({courseId:data?.value})
    this.props.getSpeakingByCourse(data?.value)
  }

  openModal=(data, e)=>{
   
    e.preventDefault();
    // bigAlert("", "Our voice recording service is currently unavailable. We apologize for any inconvenience and kindly ask you to return in two hours. Thank you for your understanding.  CELT. vip Support team", "warning")
    // return
    let params = {
      position:true,
      id: data?.id,
      type:'speaking_write',
      modalType: 'answer',
      title: data?.title,
      question: data?.question,
      answer: '',
      afterCallFunction: this.props.getSpeakingByCourse,
      funcParams: [this.state.courseId],
      upload_permission: data?.speaking_type=="midterm_end_course"
    }
    this.props.changeStateValue({name: 'mainModal', value: params});
  }
  getCourseVisible(){
    if(!this.props.params?.courseId ){
      return true
    }else{
      return false
    }
  }
  getDataVisible(){
    if(this.props.params?.courseId){
      return true
    }else{
      return false
    }
  }

  
  render() {
    const {studentCourses, speakings, lang} = this.props;
    const courses = [];
    for(const data of studentCourses){
      courses.push({
        label: data?.title,
        value: data?.id
      })
    }
  // console.log(speakings)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="sp_record" />
            <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
             
                  {courses?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found. Please select a course" subTitle="" /></div>:null}
                  <div className='w-full grid-full-205 mt-[20px]'>
                    {this.getCourseVisible()?
                      courses?.map((data, i)=>{
                        return(
                          <Card2 
                              key={i}
                              title={data?.label}
                              btnTitle={"View"}
                              onClickBtn={this.getSpeakings.bind(this, data)}
                              btnUrl={`/speaking/${data?.value}`}
                          />
                        )
                      }):null
                    }
                </div>
                {
                  this.getDataVisible()?
                  <div className='w-full grid-full-205 mt-[20px]'>
                  {
                    speakings?.map((data, i)=>{
                      return(
                        <Card2 
                          key={i}
                          title={data?.title}
                          btnTitle={data.answare.length!==0 && getGradeCheck(data.answare, data)? lang?.submitted: data?.speaking_type === "midterm_end_course" && !data?.unlocks?lang?.waiting_unlock: lang?.record_now}
                          status={data.answare.length!==0 && getGradeCheck(data.answare, data)? '0': '1'}
                          onClickBtn={data.answare.length!==0 && getGradeCheck(data.answare, data)?()=>{}:(data?.speaking_type === "midterm_end_course" && data?.unlocks) || data?.speaking_type === "unit" ?  this.openModal.bind(this, data):()=>{}}
                          bg={data.answare.length!==0 && getGradeCheck(data.answare, data)? "bg-red": data?.speaking_type === "midterm_end_course" && !data?.unlocks?"bg-yellow-500": "bg-blue"}
                          // 
                          
                        />
                      )
                    })
                  }
                </div>:null
                }
                
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  studentCourses: state.Data.studentCourses,
  speakings: state.Data.speakings,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getCourses,changeStateValue, getSpeakingByCourse}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Speakings))
