import React, { Component } from 'react'
import { connect } from 'react-redux';
import img from '../../../images/conversation3.webp'
export class Listening extends Component {
  state={
    audioRef: React.createRef(),
    duration: 100,
    currentDuration: 0,
    buttonVisible: true
  }
  componentDidMount(){
    this.setState({
      duration: 100,
      currentDuration: 0,
    })
    window.addEventListener('beforeunload', (e)=>{
      e.preventDefault();
      localStorage.setItem('audio_duration', this.state.duration)
      localStorage.setItem('audio_current_duration', this.state.currentDuration)
      return ""
    })
  }

  playAudio(audio, e){
    this.setState({
      buttonVisible: false
    })
    if(audio){
      audio.current.play();
      if(localStorage.getItem('audio_duration') && localStorage.getItem('audio_current_duration')){
        this.setState({
          duration: +localStorage.getItem('audio_duration'),
          currentDuration: +localStorage.getItem('audio_current_duration')
        })
        audio.current.currentTime = +localStorage.getItem('audio_current_duration')
      }else{
        setTimeout(()=>{
   
          this.setState({
            duration: audio?.current?.duration
          })
        }, 1000)
      }
     
    }
    
  }

  getTime(audio, e){
    if(audio){
      this.setState({
        currentDuration: audio.current.currentTime
      })
    }
    
  }
  changePart(data, e){
    localStorage.removeItem('audio_duration') 
     localStorage.removeItem('audio_current_duration')
    this.props.continueBtn(this.props.examData)
  }

  componentWillUnmount(){
    this.setState({
      duration: 100,
      currentDuration: 0,
      audioRef: React.createRef(),
    })
    localStorage.removeItem('audio_duration') 
    localStorage.removeItem('audio_current_duration')
  }
  render() {
    const {title, url, examData} = this.props;
    const {audioRef, duration, currentDuration, buttonVisible} = this.state;

    return (
      <div>
        <p className='student_toefl_exam_title'>{title}</p>
        <div style={{ display: 'none' }}>
            <audio onPause={this.changePart.bind(this, examData)} onTimeUpdate={this.getTime.bind(this, audioRef)} ref={audioRef} controls src={url} />
        </div>
        {
             !buttonVisible?
             <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}><img src={img} /></div>:null
        }
        
        <div className='toefl_listening_audio_container'>
          {
            !buttonVisible?
            <div className='toefl_listening_card'>
              <span className='toefl_listening_line_container'>
                <span className='toefl_listening_line' style={{ width: `${currentDuration*100/duration}%` }}></span>
              </span>
            </div>:null
          }
          
          {
            buttonVisible?
            <button className='toefl_exam_continue_btn' onClick={this.playAudio.bind(this, audioRef)}>Play</button>:null
          }
          
        </div>
        {/* ontimeupdate */}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Listening)