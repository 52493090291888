import React, { Component } from 'react'
import Calendar from '../../../../components/Calendar'
import { connect } from 'react-redux'
import { getStudentPayment } from '../../../../actions/StaffAction'
import { withHooks } from '../../../../actions/withHooks'
import moment from 'moment'
export class Payments extends Component {
  state={
    paymentData:[]
  }
  getCheckinData=(date)=>{
    const allDays = new Date(
      date.getFullYear(),
      date.getMonth()+1,
      0
  ).getDate()
  // console.log(allDays)
    this.props.getStudentPayment(this.props.params.id, moment(date).format(`YYYY-MM-01`), moment(date).format(`YYYY-MM-${allDays}`))
    .then(resp=>{
      this.setState({paymentData: resp})
    })
  }
  componentDidMount(){
    window.scrollTo(0,0)
  }
  render() {
    const {paymentData} = this.state;
    return (
      <div className='w-full'>
         <Calendar getAfterChange={this.getCheckinData.bind(this)} studentId={this.props.params.id} paymentMode={true} checkinDates={paymentData} onChange={this.getCheckinData} />
      </div>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {getStudentPayment}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Payments))
