import React, { Component } from 'react'

export class SectionHeader extends Component {
  render() {
    const {title, line=true} = this.props
    return (
      <div className='flex justify-center mt-[50px] mb-[50px] gap-[20px] items-center max-[685px]:flex-col max-[685px]:gap-[10px]'>
        {line?<span className='h-[15px] border-t border-b border-t-[var(--blue)] border-b-[var(--blue)] inline-block w-[150px] max-[685px]:h-[0px] max-[685px]:w-[40%] max-[685px]:border-b-[0px]'></span>:null}
        <h2 className='text-[40px] font-bold max-[520px]:text-[24px]'>{title}</h2>
        {line?<span className='h-[15px] border-t border-b border-t-[var(--blue)] border-b-[var(--blue)] inline-block w-[150px] max-[685px]:h-[0px] max-[685px]:w-[40%] max-[685px]:border-b-[0px]'></span>:null}
      </div>
    )
  }
}

export default SectionHeader
