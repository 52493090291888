import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue } from '../redux/MainReducer';
import gif from "../images/searchingGif.gif"
export class ProgressLoading extends Component {
    state={
        percentage:0
    }

    getRnd(num1, num2){
      return Math.floor((Math.random()*(num2-num1))+num1)
    }
    componentDidMount(){
        setTimeout(()=>{
            this.setState({percentage:this.getRnd(20,40)})
        }, 600)
        setTimeout(()=>{
            this.setState({percentage:this.getRnd(60,80)})
        }, 1500)
    }
  render() {
    const {percentage} = this.state;
    const {progressLoading} = this.props;
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white p-[20px] rounded-[10px] shadow ml-[20px] mr-[20px] w-[400px]'>
          {/* <div className='mt-[20px] mb-[50px] relative'>
            <div className="loader"></div>
          </div> */}
            <h3 className='text-center text-[15px] font-[600] '>{progressLoading?.text}</h3>
            <div className='flex justify-center mt-[20px]'>
            <img className='w-[120px]' src={gif} />
            </div>
            {/* <div className='relative w-full h-[20px] rounded-[6px] bg-gray-100 mt-[20px]'>
                <div style={{ width: progressLoading?.complete? '100%':`${percentage}%`, transition:'0.2s' }} className='h-[20px] bg-blue rounded-[6px] flex items-center justify-end pl-[20px] pr-[5px]'>
                    <span className='text-white text-[12px]'>{progressLoading?.complete?100:percentage}%</span>
                </div>
            </div> */}
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  progressLoading: state.Data.progressLoading
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ProgressLoading)
