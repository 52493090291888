import React, { Component } from 'react'
import { connect } from 'react-redux';
import { forgotPsw } from '../actions/PublicActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { withHooks } from '../actions/withHooks';
import logo from "../images/logo.png"
import LoginPenSvg from '../components/svg/LoginPenSvg';
import { mixinAlert } from '../actions/SweetAlerts';
import LoginEmailSvg from '../components/svg/LoginEmailSvg';
import { forgotPswVip } from '../actions/PublicActions';
export class ForgotPassword extends Component {
  state={
    email:'',
    btnDisable:false
  }
  
  confirmBtn=(data, e)=>{
    e.preventDefault();
    if(data?.email?.trim() !==""){
        if(!data?.email?.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
            mixinAlert("warning", "The email should be valid email format.");
            return
        }
        this.setState({btnDisable:true});
        this.props.forgotPswVip(data)
        .then(resp=>{
          this.setState({btnDisable:false});
          if(resp === "success"){
            this.props.navigate("/login");
            mixinAlert("success", "Please, check the email.");
            return
          }else{
            this.props.forgotPsw(data)
            .then(resp=>{
                if(resp === "success"){
                    this.props.navigate("/login");
                    mixinAlert("success", "Please, check the email.");
                }else{
                    mixinAlert("error", "The email does not exists");
                }
            })
          }
        })
       
    }
  }
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render() {
    const {email, btnDisable} = this.state;
    return (
      <div className='flex bg-[#f5f5f5] items-center justify-center min-h-screen w-full mb-[-120px]'>
        <div className='w-[510px] bg-white pt-[44px] pl-[54px] pr-[54px]  max-[660px]:pl-[20px]  max-[660px]:pr-[20px] pb-[50px] rounded-[12px] shadow max-[520px]:ml-[20px] max-[520px]:mr-[20px]'>
          <div className='flex justify-center'>
            {/* <img className='w-[160px] h-[50px]' src={logo} /> */}
            <h2 className='text-[30px] font-semibold txt-blue'>Forgot Password</h2>

          </div>
          <div className='mt-[20px]'>
            <h4 className='txt-light-black text-[24px] font-bold'>Enter your email</h4>
            <p className='text-[14px] text-[#64748B] font-regular'>Enter the email address associated with your account and we will send you a link to reset your password.</p>
          </div>
          <form className='mt-[35px]'>
              <div className='flex flex items-center p-[16px] gap-[16px] w-full border h-[56px] border-[#E2E8F0] rounded-[12px]'>
                <LoginEmailSvg />
                <input className='bg-white w-[80%] outline-none h-[40px] rounded-full p-2 text-[14px]' type='email' id='emailPsw' placeholder='example@example.com' value={email} onChange={(e)=>this.setState({email: e.target.value})} />         
              </div>
             
              <button disabled={btnDisable} onClick={this.confirmBtn.bind(this,this.state)} className='mt-[33px] bg-blue w-full h-[56px] font-bold text-[16px] rounded-[12px] text-white '>Continue</button>
             
          </form>
          <div className='pt-[30px] flex justify-center'>
            <p><Link className='txt-blue' to={'/login'}>Back to Sign In</Link></p>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({});
const mapDispatchToProps = {forgotPsw, forgotPswVip}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ForgotPassword))




