import React, { Component } from 'react'

export class SupportSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={18}
        height={18}
        className="icon"
        viewBox="0 0 1024 1024"
      >
        <path
          fill="#585874"
          d="M953.6 234.667 768 418.133s-55.467 0-110.933-55.466S601.6 251.733 601.6 251.733l185.6-185.6c-104.533-25.6-230.4 8.534-307.2 85.334-115.2 115.2-12.8 262.4-42.667 292.266C275.2 612.267 108.8 740.267 104.533 746.667c-49.066 49.066-51.2 128-4.266 174.933 46.933 46.933 125.866 44.8 174.933-4.267 6.4-6.4 142.933-179.2 302.933-339.2 29.867-29.866 170.667 78.934 290.134-38.4C942.933 462.933 979.2 339.2 953.6 234.667zM200.533 876.8c-29.866 0-53.333-23.467-53.333-53.333 0-32 23.467-55.467 53.333-55.467s53.334 23.467 53.334 53.333S230.4 876.8 200.533 876.8z"
        />
      </svg>
    )
  }
}

export default SupportSvg
