import React, { Component } from 'react'
import Header from './Header'
import ContractPdfSvg from '../svg/ContractPdfSvg';

export class FinanceDetailModal extends Component {
    closeBtn=()=>{
        this.props.closeBtn()
    }
    closeBackground(e){
        if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
          this.props.closeBtn();
        }
      }
  render() {
    const {data, studentName, lang} = this.props;
    // console.log(data)
    return (
        <div onClick={this.closeBackground.bind(this)} className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[738px]  rounded-[10px] ml-[10px] mr-[10px]'>
                <Header closeBtn={this.closeBtn.bind(this)} title={studentName} />
                <div className='p-[20px] overflow-auto max-h-[70vh]'>
                    <div className='flex items-center justify-between border '>
                    <span className='border w-1/3 text-center p-2 text-base font-bold'>{lang?.title}</span>
                        <span className='border w-1/3 text-center p-2 text-base font-bold'>{lang?.amount}</span>
                        
                        <span className='border w-1/3 text-center p-2 text-base font-bold'>Files</span>
                    </div>
                    {
                        data?.map((item, j)=>{
                            if(item?.type === "outs"){
                                return(
                                    <div key={j} className='flex'>
                                        <span className='border w-1/3 text-left p-2 text-base font-regular'>{item?.title}</span>

                                        <span className='border w-1/3 text-center p-2 text-base font-regular'>{item?.amount}</span>
                                        <span className='border w-1/3 text-center p-2 text-base font-regular'>
                                            {
                                                item?.file?
                                                <a download={true} target='_blank' href={item?.file?.file_full_url}>
                                                    <ContractPdfSvg className='w-6 cursor-pointer' />
                                                </a>:null
                                            }
                                        </span>
                                    </div>
                                   )
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
  }
}

export default FinanceDetailModal
