import React, { Component } from 'react'
import Card1 from '../../components/cards/Card1'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getEssayReview } from '../../actions/MainAction';
import {jwtDecode} from "jwt-decode";
import moment from 'moment';
import { changeStateValue } from '../../redux/MainReducer';
import { getGradeAll, getRole } from '../../actions/getRole';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Search from '../../components/Search';
import EmptyPage from '../../components/EmptyPage';
import { checkEssayPlagiarism } from '../../actions/MainAction';
export class EssayReviews extends Component {
    state={
        search:'',
        studentId:''
    }
    async getData(id, page, keyword){
        return await this.props.getEssayReview(id, page, keyword);
    }
    componentDidMount(){
        if(localStorage.getItem('userToken')){
            const data = jwtDecode(localStorage.getItem('userToken'));
           this.setState({studentId:data?.sub});
           this.getData(data?.sub, 1, '')
        }
        window.scrollTo(0,0)
    }
    getGrade(reviews, autoGrade, grade){
        if(reviews.length == 0){
            return grade
        }else{
            if(grade == 0){
                return '0';
            }
            let grades = 0;
            for(const data of reviews){
                grades+=data.grade;
            }
            if(autoGrade){
                return  ((grades+autoGrade.grade)/(reviews.length+1)).toFixed(0)
               
            }else{
                return grade;
            }
        }
    }

    openModal=(data)=>{
        const params = {
            position:true,
            type:'essay',
            modalType: 'review',
            title: 'QUESTIONS',
            question: data?.question ? `<pre class='generatedEssayStyle'>${data?.question } ${data?.essay?.image_full_url?`<img src="${data?.essay?.image_full_url}" />`:'' } <br /><hr /> <br />${data?.outline}</pre>`:`${data?.essay?.question}  ${data?.essay?.image_full_url?`<img src="${data?.essay?.image_full_url}" />`:'' }`, 
            essay: data?.answer,
            reviews: data?.reviews[0]?.review,
            teacher: data?.reviews[0]?.user?.full_name,
            role: getRole(data?.reviews[0]?.user?.role, this.props.lang),
            stuent: data?.user?.full_name,
            robot_review:data?.robot_review,
            grade: data?.grade,
            model_answer: data?.model_answer?.answer,
        }
        this.props.changeStateValue({name: 'mainModal', value: params});
        let type = 'unit';
        if(data.essay.title.split(' ').includes('Movie')){
            type = 'movie'
        }
        this.props.checkEssayPlagiarism(data.id, data.essay_id, type, data.user.company_id, false)
    }

    loadMore=(page)=>{
        this.getData(this.state.studentId,  page, this.state.search)
    }
    onSearchData=(search)=>{
        this.setState({search:search})
        this.getData(this.state.studentId,  1, search)
    }
  render() {
    const {essayReviews, lang} = this.props;
    // console.log(essayReviews)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="essay_rws" />
            <div className='flex justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            {essayReviews?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}

            <div className='mt-[42px] grid-full-250'>
                {
                    essayReviews?.map((data, i)=>{
                        // if((data.essay.essay_type === "unit" || data.essay.essay_type === "robo_unit") || (data.essay.essay_type === "midterm_end_course" || data.essay.essay_type === "robo_midterm_end_course") && data.reviews.find(review => review.head_teacher_confirm === 1)){
                            return(
                                <Card1 
                                    key={i}
                                    title={data?.essay?.title}
                                    date={moment(data?.submit_date).format("DD/MM/YYYY")}
                                    grade={getGradeAll(data.reviews,  data.robot_review, data.grade)}
                                    status={getGradeAll(data.reviews,  data.robot_review, data.grade)>=data?.essay?.course_prompt?.grade?1:0}
                                    statusText={getGradeAll(data.reviews,  data.robot_review, data.grade)>=data?.essay?.course_prompt?.grade?lang?.pass: lang?.fail}
                                    onCLickBtn={this.openModal.bind(this, data)}
                                />
                            )
                        // }
                        
                    })
                }
                
            </div>
            {
                essayReviews?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={essayReviews?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    essayReviews: state.Data.essayReviews,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getEssayReview, changeStateValue, checkEssayPlagiarism}
export default connect(mapStateToProps, mapDispatchToProps)(EssayReviews)
