import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withHooks } from '../../../actions/withHooks';
import { getTeacherBonuses, getUserData } from '../../../actions/StaffAction';
import Header from '../../../components/header/Header';
import Card1 from '../../../components/cards/Card1';
import moment from 'moment';
import HeaderButtons from '../../../components/HeaderButtons';
import { changeStateValue } from '../../../redux/MainReducer';
import EmptyPage from '../../../components/EmptyPage';
export class TeacherBonus extends Component {
    state={
        teacherData:''
    }
    componentDidMount(){
        this.props.getUserData(this.props.params.id)
        .then(resp=>{this.setState({teacherData:resp})})
        this.props.getTeacherBonuses(this.props.params.id);
        window.scrollTo(0,0)
    }
    matchDate(created_at){
        const date = moment(created_at).format('YYYY/MM/DD');
        const newDate = new Date(`${new Date(date).getFullYear()}/${new Date(date).getMonth()+1}/${new Date(date).getDate()+7}`);
        const now = new Date();
       return newDate >= now;
    }
    addEditData=(data)=>{
        this.props.changeStateValue({
            name: "userModalData",
            value:{
                position: true,
                id: data?.id?data?.id:"",
                title_input: data?.title? data?.title:"",
                bonus: data?.bonus? data?.bonus:"",
                monthly_date: data?.date? data?.date:"",
                title: data?.id?"Edit Bonus":"Add Bonus",
                unRequireds: ["id",  "date_of_birth","password", "unRequireds", "afterCallFunction", "funcParams"],
                afterCallFunction: this.props.getTeacherBonuses,
                teacher_id: this.props.params.id,
                funcParams: [this.props.params.id],
                url:'teacher-bonus'
            }
        })
    }
  render() {
    const {teacherBonuses, user, lang} = this.props;
    const {teacherData} = this.state;
    return (
        <div className='w-full max-sm:pl-[10px] max-sm:pr-[10px] pl-[27px] pr-[27px]'>
            <Header title={teacherData?.full_name} />
            <div className='mt-[42px] flex justify-end  max-[1100px]:mt-[120px] max-[800px]:mt-[180px] max-[330px]:mt-[200px]'>
                <HeaderButtons onClick={this.addEditData.bind(this, '')} buttons={[{title: lang?.add_bonus, id:0}]} />
            </div>
            {teacherBonuses.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[20px] grid-full-220'>
                {
                    teacherBonuses?.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.date}
                                close={data?.title}
                                cheating={data?.bonus}
                                date={moment(data?.created_at).format("DD/MM/YYYY")}
                                status={user?.role === "super_admin" || (user?.role === "company_head" && this.matchDate(data?.created_at))?1:0}
                                statusText={user?.role === "super_admin" || (user?.role === "company_head" && this.matchDate(data?.created_at))?lang?.unlocked: lang?.locked}
                                onCLickBtn={user?.role === "super_admin" || (user?.role === "company_head" && this.matchDate(data?.created_at))?this.addEditData.bind(this, data):()=>{}}
                                btn={false}
                                btn2={true}
                                btn2Text={lang?.edit}
                                btn2Color={user?.role === "super_admin" || (user?.role === "company_head" && this.matchDate(data?.created_at))?"bg-blue":"bg-gray-500 cursor-not-allowed"}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    teacherBonuses: state.Data.teacherBonuses,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = { getTeacherBonuses, getUserData, changeStateValue }
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(TeacherBonus))
