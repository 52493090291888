import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../redux/MainReducer';
import { connect } from 'react-redux';
import socket from '../../actions/socketIo';

export class ExamPermissionModal extends Component {
   
    examPermission=(status, data)=>{
      
        socket.emit("support_response", JSON.stringify({status, ...data}));
        this.props.resetStateValue({name:"examPermissionModalData"})
    }
    // componentWillUnmount(){
    //   socket.off("connect");
    //   socket.off("support_response")
    //   socket.disconnect()
    // }
  render() {
    const {examPermissionModalData} = this.props;
    // console.log(examPermissionModalData)
    return (
      <div className='background_shadow flex justify-center items-center'>
        <div className='bg-white  p-[20px] shadow rounded-[10px] w-[400px]'>
            <h3 className='text-center txt-blue font-semibold text-[18px]'>Exam start approval</h3>
            <h3 className='text-center txt-blue font-[300] text-[15px]'>Please, check student and exam details and then approve or reject</h3>
            <ul className='mt-[15px]'>
                <li className='text-[16px] font-[300] '><span className='font-[500]'>Student:</span> {examPermissionModalData?.student}</li>
                <li className='text-[16px] font-[300] '><span className='font-[500]'>Exam:</span> {examPermissionModalData?.exam}</li>
                <li className='text-[16px] font-[300] '><span className='font-[500]'>Course:</span> {examPermissionModalData?.course}</li>
            </ul>
            <div className='flex justify-between mt-[15px]'>
                <button onClick={this.examPermission.bind(this, "cancel",examPermissionModalData )} className='bg-red text-[12px] font-semibold h-[25px] p-[0_5px] rounded-[5px] text-white'>
                    Reject
                </button>
                <button onClick={this.examPermission.bind(this, "accept",examPermissionModalData )} className='bg-blue text-[12px] font-semibold h-[25px] p-[0_5px] rounded-[5px] text-white'>
                    Approve
                </button>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    examPermissionModalData: state.Data.examPermissionModalData
});
const mapDispatchToProps = {changeStateValue, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ExamPermissionModal)
