import React, { Component } from 'react'
import SearchSvg from './svg/SearchSvg'
import SelectStudent from './SelectStudent';
import { connect } from 'react-redux';

export class Search extends Component {
    state={
        search:''
    }

    changeInpValue(e){
        this.setState({search:e.target.value});
        if(e.target.value === ''){
            this.props.onSearchData('');
        }
    }

    searchBtn(search, e){
        e.preventDefault();
        if(search.trim() !== ''){
            this.props.onSearchData(search.trim())
        }
    }
  render() {
    const {search} = this.state;
    const {student = false, onSelectStudent=()=>{}, searchVisible=true, bg="bg-light-gray", largWidth=false, mt="mt-[20px]", lang} = this.props
    return (
      <div className={`flex justify-between ${bg === "bg-light-gray"?`${mt}`:''} max-md:flex-col relative max-md:gap-[10px] gap-[30px]`}>
        {
            student?
            <SelectStudent onSelectStudent={(id)=>onSelectStudent(id)} />:<div></div>
        }
        {
            searchVisible?
            <div className='h-[56px] p-[10px] bg-white rounded-[30px] flex items-center gap-[20px] headerbar'>
                <form onSubmit={this.searchBtn.bind(this, search)} className={`${bg} ${largWidth?"w-[400px] ":" w-[214px]"} h-[38px] max-md:w-[100%] rounded-[30px] flex items-center pl-[20px] gap-[5px]`}>
                    <button >
                        <SearchSvg />
                    </button>
                    <input value={search} onChange={this.changeInpValue.bind(this)} placeholder={lang?.search}  className={`placeholder-txt-blue ${bg} w-[80%] outline-none text-[14px] txt-dark-gray`} type='text' />
                </form>
            </div>:null
        }
       
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
})
export default connect(mapStateToProps)(Search)
