import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import { connect } from 'react-redux'
import { getAdminLessons, getCourseRequireds } from '../../actions/AdminActions'
import { changeStateValue } from '../../redux/MainReducer'
import Card1 from '../../components/cards/Card1'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { deleteData } from '../../actions/MainAction'

export class AdminCourseRequiredPage extends Component {
    componentDidMount(){
        this.props.getCourseRequireds()
      
    }

    getRequireds(data){
        let requireds = [];
        for(const item of data){
            requireds.push({
                label: `${item?.course?.title} - ${item?.title}`,
                value: item?.id
            })
        }
        return requireds;
    }
    getRequireds2(data){
        let requireds = [];
        for(const item of data){
            requireds.push({
                label: `${item?.title}`,
                value: item?.id
            })
        }
        return requireds;
    }
    openModal=(data)=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                end_of_course_id:data?.id? data?.id:'',
                afterCallFunction:this.props.getCourseRequireds,
                afterCallParams:[],
                requireds:["end_of_course_id" ],
                url: data?.id? `course-requireds/${data?.id}`:'course-requireds',
                modalTitle: data?.id? `Edit Course required`:'Add Course required' ,
                required_id:data==""?[]:this.getRequireds(data?.requireds),
                overflow_def:true
            }
        });
    }
    onDeleteBtn=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`course-requireds/${id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getCourseRequireds()
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {courseRequireds} = this.props;
    // console.log(courseRequireds)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Course Requireds" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Required", id:0}]} selected={0} />
            </div>
            <div className='mt-[30px] grid-full-250 items-start'>
            {
              courseRequireds?.map((data, i)=>{
                return (
                    <Card1
                      key={i}
                      statusBisible={false}
                      status={1}
                      btn={false}
                      title={data?.title}
                      btn2={true}
                      btn2Text={"Edit"}
                      btn2Color={"bg-blue"}
                      adminDelete={true}
                      onCLickBtn={this.openModal.bind(this, data)}
                      onDeleteBtn={this.onDeleteBtn.bind(this, data?.id)}
                      moreOption={this.getRequireds2(data?.requireds)}
                    />
                  )
              })
            }
          </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    courseRequireds: state.Data.courseRequireds,
   
});
const mapDispatchToProps = {getCourseRequireds, changeStateValue, getAdminLessons, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCourseRequiredPage)
