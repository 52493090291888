import React, { Component } from 'react'
import Header from './Header';
import { connect } from 'react-redux';
import { getStudentContracts } from '../../actions/StaffAction';
import FileInput from '../FileInput';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { insertNewData, deleteData } from '../../actions/MainAction';
import ContractPdfSvg from '../svg/ContractPdfSvg';
import MsgDeleteSvg from '../svg/MsgDeleteSvg';
import { resetStateValue } from '../../redux/MainReducer';
import FileInputPDF from '../FileInputPDF';
export class ContractModal extends Component {
    state={
        btnDisabled:false,
        contracts:[],
        page:'file'
    }
    componentDidMount(){
        this.props.getStudentContracts(this.props.student?.id)
        .then(resp=>{
            this.setState({contracts:resp})
        })
    }
    saveBtn=(data, closeModal=()=>{}, type="save")=>{
        if(data?.file || data?.image){
            let formData = new FormData();
            if(this.state.page == 'file'){
               formData.append("file", data?.file); 
            }else{
                formData.append("image", data?.image);
            }
            
            
            formData.append("student_id", this.props.student?.id);
            this.props.insertNewData('student-contracts', formData)
            .then(resp=>{
                // console.log(resp)
                if(resp == "success"){
                    this.props.getStudentContracts(this.props.student?.id)
                    .then(contracts=>{
                        this.setState({contracts:contracts});
                        this.props.resetStateValue({ name:'studentContractsModal'  });
                        closeModal()
                    });
                    mixinAlert("success", "Contract uploaded successfully")
                }else{
                    mixinAlert("error", "Something went wring.")
                }
            })
        }else{
            if(type=="save_close"){
                closeModal()
            }else{
               mixinAlert("warning", "Please, upload file.") 
            }
            
        }
       
    }   

    deleteContract=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`student-contracts/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getStudentContracts(this.props.student?.id)
                    .then(contracts=>{this.setState({contracts:contracts})});
                    mixinAlert("success", "Contract deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {student, lang, closeBtn=()=>{}, studentContractsModal} = this.props;
    const {btnDisabled, contracts, page} = this.state;
    return (
      <div className='background_shadow flex items-center justify-center'>
        <div className='bg-white w-[600px] rounded-[10px]'>
            <Header title={lang?.contracts} closeBtn={closeBtn} />
            <div className='p-[20px]'>
                <div className='flex items-center gap-[20px] '>
                    <button onClick={()=>{this.setState({page:'file'})}} className={`text-[14px] font-semibold p-[3px_7px] rounded-[5px] ${page=="file"?'bg-blue text-white':'txt-blue border border-[var(--blue)]'}`}>PDF</button>
                    <button  onClick={()=>{this.setState({page:'image'})}}  className={`text-[14px] font-semibold p-[3px_7px] rounded-[5px] ${page=="image"?'bg-blue text-white':'txt-blue border border-[var(--blue)]'}`}>Image</button>
                </div>
                {
                    page=="file"?
                    studentContractsModal?.file || studentContractsModal?.file ==="" ||  studentContractsModal?.file ===null? <FileInputPDF nameInp={studentContractsModal?.file?.name} inpOtherType={true} type="application/pdf" title={"PDF Only"} valueName="studentContractsModal.file_full_url" value={studentContractsModal?.file_full_url} name="studentContractsModal.file" />:null:
                    studentContractsModal?.image || studentContractsModal?.image ==="" ||  studentContractsModal?.image ===null? <FileInput  inpOtherType={true} type="image/png, image/jpg, image/jpeg" title={'PNG, JPG, JPEG'} valueName="studentContractsModal.image_full_url" value={studentContractsModal?.image_full_url} name="studentContractsModal.image" />:null

                }
                <div className='flex flex-wrap gap-[20px] mt-[20px]'>
                    {
                        contracts?.map((data, i)=>{
                            return(
                                <div key={i} className='group h-[70px]'>
                                    <a target='_blank' download={true} href={data?.file_full_url?data?.file_full_url:data?.image_full_url}>
                                        {
                                            data?.file_full_url?
                                            <ContractPdfSvg />:
                                            <img className='w-[40px] h-[40px] rounded-[5px]' src={data?.image_full_url} />
                                        }
                                        
                                    </a>
                                    <button onClick={this.deleteContract.bind(this, data)} className='hidden group-hover:inline'>
                                        <MsgDeleteSvg color={'var(--red)'} />
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
                
                <div className='flex mt-[20px] items-center justify-between'>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,studentContractsModal, ()=>{})} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.upload}</button>
                    <button disabled={btnDisabled} onClick={this.saveBtn.bind(this,studentContractsModal, closeBtn, "save_close")} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save_close}</button>
                </div>
            </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang,
    studentContractsModal: state.Data.studentContractsModal,
});
const mapDispatchToProps = {getStudentContracts, insertNewData, deleteData, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ContractModal)
