import Swal from "sweetalert2";
import { MAIN_API, MAIN_APIV2, PUBLIC_API } from "../MAIN_API";
import { changeStateValue } from "../redux/MainReducer";
import axios from "axios";
const apiHeaders = () => {
    return {
        "Authorization": `Bearer ${localStorage.getItem("userToken")}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
}

export const insertNewData = (url, formData) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return axios.post(`${MAIN_API}/${url}`, formData, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: 'loader', value: false}));
        return "success"
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return 'error'
    })
}

export const getIALExams = (page=1, keyword="",type='', lessonId='', studentId='', active_type='') => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/new-essays?type=${type}&lesson_id=${lessonId}&student_id=${studentId}&active_type=${active_type}&page=${page}&keyword=${keyword}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminIalExamsData", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getSpPractice = (page=1, keyword="", active_status="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/speaking-practice?page=${page}&keyword=${keyword}&active_status=${active_status}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminSpPractice", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getEssays = (page, keyword, active_status="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/essays`, {
        params:{page, keyword,active_status},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminEssays", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getSpeakings = (page, keyword, active_status="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/speakings`, {
        params:{page, keyword, active_status},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminSpeakings", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getExams = (page, keyword, active_type="") => async dispatch => {

    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/exams`, {
        params:{page, keyword, active_type},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminExams", value:resp.data?.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getExamQuestions = (id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/questions`, {
        params:{exam_id:id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminExamQuestions", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getImages = (page=1, keyword="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/upload-image`, {
        params:{page, keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminImages", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getExamExceptions = (status=0) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/student-ex-apply`, {
        params:{status},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminExceptions", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getTeacherSeniorRequests = (status=0) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/senior-status`, {
        params:{status},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminTeacherSeniorRequests", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getCoachExceptions = (status=0) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/coach-exception`, {
        params:{status},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminCoachExceptions", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getExamSettings= (page=1) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/exam-requirements`, {
        params:{page},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminExamSettings", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getCompanies= (page=1, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/companies`, {
        params:{page, keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminCompanies", value:resp.data}));
        return resp.data
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getManagers= (page=1, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/users`, {
        params:{page, keyword, user_type: "company_head"},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminManagers", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getStaff= (page=1, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/users`, {
        params:{page, keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminStaff", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getGroups= (page=1, keyword) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/studentgroups`, {
        params:{page, keyword},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminGroups", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getAllocations= (page=1, keyword, course_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/courseAssigns`, {
        params:{page, keyword, course_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminAllocations", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getNotAssignedCourses= (company_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/courses`, {
        params:{company_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "companyNotAssignedCourses", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}




export const getAdminLessons= (page=1,keyword, course_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/lessons`, {
        params:{page, keyword, course_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminLessons", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}




export const getAdminLessonModes= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/lesson-mode`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminLessonModes", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}

export const getAdminUnlockStudents= (company_id="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/users?user_type=super_admin_unlocks&company_id=${company_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminUnlockStudents", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getAdminExamResults= (page, date, resultType, keyword, company_id, type) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/studentAnswer`, {
        params:{page, keyword, company_id, type, date, orderField: 'exam_title', orderMode:"asc", resultType, data_type:'current'},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminExamResults", value:resp.data}));
    }).catch(err=>{
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}

export const getAdminPrompts= (type) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/robo-prompt`, {
        params:{type},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminPrompt", value:resp.data}));
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getExamPrompts= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/exam-prompt`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "examPrompts", value:resp.data}));
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getAdminCourseRules= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/course-rules`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "adminCourseRules", value:resp.data}));
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}

export const getNotAssignedEnrolls= (company_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/get-now-assign-enrolls?company_id=${company_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "notAssignedEnrolls", value:resp.data}));
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}

export const getNotAssignCompanies= (course_id) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/get-not-assign-compny?course_id=${course_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "notAssignedCompanies", value:resp.data}));
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getCompanyTeachers= (company_id, search="") => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/users?user_type=course_enroll_teacher&company_id=${company_id}&keyword=${search}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}
export const getCompanyTeachersContracts= (company_id, date) => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/teachers-with-contracts?date=${date}&company_id=${company_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getTypeofStudents= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:true}));
    return await axios.get(`${MAIN_API}/get-all-student-stypes`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getTotalStats= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:false}));
    return await axios.get(`${MAIN_API}/examStatus`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "loader", value:false}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}


export const getCourseRequireds= () => async dispatch => {
    dispatch(changeStateValue({name: "loader", value:false}));
    return await axios.get(`${MAIN_API}/course-requireds`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "courseRequireds", value:resp?.data}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loader", value:false}));
    })
}



export const getTroubleMakers= () => async dispatch => {
    dispatch(changeStateValue({name: "loaderLong", value:true}));
    return await axios.get(`${MAIN_API}/continue-students`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "troubleStudents", value:resp?.data}));
        dispatch(changeStateValue({name: "loaderLong", value:false}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loaderLong", value:false}));
    })
}



export const getPoolingExams= (exam_id="") => async dispatch => {
    dispatch(changeStateValue({name: "loaderLong", value:true}));
    return await axios.get(`${MAIN_API}/pooling-exam`, {
        params:{exam_id},
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "poolingExams", value:resp?.data}));
        dispatch(changeStateValue({name: "loaderLong", value:false}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loaderLong", value:false}));
    })
}


export const getEndStats= () => async dispatch => {
    dispatch(changeStateValue({name: "loaderLong", value:true}));
    return await axios.get(`${MAIN_API}/end-of_exam-excell`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "endStatExcelData", value:resp?.data}));
        dispatch(changeStateValue({name: "loaderLong", value:false}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loaderLong", value:false}));
    })
}



export const getMocExamParents= (exam_id) => async dispatch => {
    dispatch(changeStateValue({name: "loaderLong", value:true}));
    return await axios.get(`${MAIN_API}/dynamic-exam-parent?exam_id=${exam_id}`, {
        headers: apiHeaders()
    }).then(resp=>{
        dispatch(changeStateValue({name: "mocExamParents", value:resp?.data}));
        dispatch(changeStateValue({name: "loaderLong", value:false}));
        return resp?.data
    }).catch(err=>{
        console.log(err.response)
        dispatch(changeStateValue({name: "loaderLong", value:false}));
    })
}


