import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getEssayUngraded } from '../../actions/StaffAction';
import Card1 from '../../components/cards/Card1';
import moment from 'moment';
import Search from '../../components/Search';
import EmptyPage from '../../components/EmptyPage';
export class EssayUngraded extends Component {
    state={
        search:''
    }
    componentDidMount(){
        this.props.getEssayUngraded("");
        window.scrollTo(0,0)
    }
    onSearchData=(search)=>{
        // this.props.getEssayUngraded(search)
        this.setState({search})
    }
  render() {
    const {essayUngraded} = this.props;
    const {search} = this.state
    let essayUngradedData = [...essayUngraded];
    if(search !== ""){
        essayUngradedData = essayUngraded.filter(x=>x.student_info.toLowerCase().includes(search.toLowerCase()) || x.teacher.toLowerCase().includes(search.toLowerCase()) || x.title.toLowerCase().includes(search.toLowerCase()))
    }
    let result = essayUngradedData.reduce(function (r, a) {
        r[a.teacher] = r[a.teacher] || [];
        r[a.teacher].push(a);
        return r;
    }, {});
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="es_ungraded" />
            <div className='flex justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[160px]'>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            {essayUngradedData.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            {
                 Object.values(result).map((item, j)=>{
                    return(
                        <div key={j}>
                            <h2 className='mt-[20px] font-semibold text-[25px]'>{item[0]?.teacher} ({item?.length})</h2>
                            <div className='mt-[12px] grid-full-220'>
                                {

                                    item?.sort((a, b) => new Date(b.date) - new Date(a.date))?.map((data, i)=>{
                                        return(
                                            <Card1 
                                                key={i}
                                                title={data?.title}
                                                name={data?.student_info}
                                                date={moment(data?.date).format("DD/MM/YYYY")}
                                                statusBisible={false}
                                                btn={false}
                                            />
                                        )
                                    })
                                }
                            
                            </div>
                        </div>
                    )
                 })
            }
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    essayUngraded: state.Data.essayUngraded
});
const mapDispatchToProps = { getEssayUngraded }
export default connect(mapStateToProps, mapDispatchToProps)(EssayUngraded)
