import React, { Component } from 'react'
import { connect } from 'react-redux';

export class PageButtons extends Component {
  state={
    page:'chat'
  }
  render() {
    const {onChangePage=()=>{}, lang} = this.props;
    const {page} = this.state;
    return (
        <div className='ml-[15px] mr-[15px] h-[38px] rounded-[12px] bg-[#F8FAFC] mt-[22px] flex items-center pl-[4px] pr-[4px]'>
            <button onClick={()=>{onChangePage("chat"); this.setState({page:"chat"})}} className={`${page == "chat"? "bg-white h-[30px] txt-light-black":"text-[#64748B]"}  rounded-[12px] w-1/2 text-[12px] font-bold `}>{lang?.active}</button>
            <button onClick={()=>{onChangePage("contacts"); this.setState({page:"contacts"})}} className={`${page == "contacts"? "bg-white h-[30px] txt-light-black":"text-[#64748B]"}  rounded-[12px] w-1/2 text-[12px] font-bold `}>{lang?.contants}</button>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  lang: state.Data.lang
})
export default connect(mapStateToProps)(PageButtons)
