import React, { Component } from 'react'
import { connect } from 'react-redux';
import FileInput from '../FileInput';
import Header from './Header';
import Input from './components/Input';
import { getExamTypes, insertNewDataStaff } from '../../actions/PublicActions';
import DropSearch from '../DropSearch';
import { mixinAlert } from '../../actions/SweetAlerts';
import { resetStateValue } from '../../redux/MainReducer';
export class PublicExamResultsModal extends Component {
    state={
        btnDisabled:false
    }
    closeModal=()=>{
        this.props.resetStateValue({name:"addExResultsData"})
    }
    componentDidMount(){
        this.props.getExamTypes()
    }
    saveBtn=(data)=>{
        if(data?.student_name?.trim() !=="" && data?.exam_type_id !=="" && data?.score !=="" && data?.date !=="" && data?.image !=="" && data?.cert_image !=="" ){
            if(!data?.cert_image || !data?.image){
                mixinAlert("warning", "Image inputs are required");
                return;
            }
            this.setState({btnDisabled:true})
            let formData = new FormData();
            let url = 'company-student-exam';
            for(const property in data){
                formData.append(property, data[property])
            }
            if(data?.id && data?.id !==""){
                formData.append("_method", "put");
                url = `company-student-exam/${data?.id}`
            }

            this.props.insertNewDataStaff(url, formData)
            .then(resp=>{
                this.setState({btnDisabled:false});
                if(resp === "success"){
                    data.callAfterFunction(...data?.afterParams);
                    this.closeModal();
                    mixinAlert("success", "Data added successfully");
                }else{
                    mixinAlert("error", "Something went wrong");
                }
            })

        }else{
            mixinAlert("warning", "Please fill the all inputs")
        }
    }
  render() {
    const {addExResultsData, publicExamTypes, lang} = this.props;
    const {btnDisabled} = this.state;
    let examTypes = [];
    for(const property of publicExamTypes){
        examTypes.push({
            label: property?.title,
            value: property?.id
        })
    }
    return (
        <div className='background_shadow flex items-center justify-center'>
            <div className='bg-white w-[700px]  shadow bg-white rounded-[10px] '>
                <Header closeBtn={this.closeModal.bind(this)} title={addExResultsData?.modalTitle} />
                <div  className='p-[20px] max-h-[70vh] overflow-auto'>
                    <div className='grid grid-cols-2 gap-[20px] max-[450px]:grid-cols-1'>
                        <FileInput  type="image" name="addExResultsData.image" title="Student Image" value={addExResultsData.image_full_url} valueName="addExResultsData.image_full_url" />
                        <FileInput  type="image/*, application/pdf" name="addExResultsData.cert_image" title="Certificate Image or PDF" value={addExResultsData.cert_image_full_url} valueName="addExResultsData.cert_image_full_url" />
                        <Input required name="addExResultsData.student_name" value={addExResultsData.student_name} title="Student name" />
                        <DropSearch required name="addExResultsData.exam_type_id" value={addExResultsData.exam_type_id} options={examTypes} title='Select an Exam' placeholder="Select an Exam" />
                        <Input required type="number" name="addExResultsData.score" value={addExResultsData.score} title="Score" />
                        <Input required type="date" name="addExResultsData.date" value={addExResultsData.date} title="Date" />
                    </div>
                    <div className='flex mt-[20px] items-center justify-between'>
                        <button disabled={btnDisabled} onClick={()=>{this.closeModal()}} className='bg-red h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.close}</button>
                        <button disabled={btnDisabled} onClick={this.saveBtn.bind(this, addExResultsData)} className='bg-blue h-[25px] pl-[10px] pr-[10px]  text-[12px] rounded-[5px] text-white font-semibold'>{lang?.save}</button>
                    </div>
                </div>
                
            </div>
            
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    addExResultsData: state.Data.addExResultsData,
    user: state.Data.user,
    publicExamTypes: state.Data.publicExamTypes,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getExamTypes, insertNewDataStaff, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(PublicExamResultsModal)
