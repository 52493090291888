import React, { Component } from 'react'

export class CardSchYearSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        fill="none"
      >
        <path
          fill="#1E398F"
          d="M12.736 1.737H10.67a.156.156 0 0 0-.156.156c0 .026.008.05.02.072v1.19a.331.331 0 0 1-.332.331h-.643a.308.308 0 0 1-.308-.308V1.984a.152.152 0 0 0 .033-.091.156.156 0 0 0-.156-.156H4.96a.156.156 0 0 0-.156.156c0 .024.006.046.016.067v1.203a.324.324 0 0 1-.324.323h-.57a.327.327 0 0 1-.328-.327V1.97a.151.151 0 0 0 .01-.136.156.156 0 0 0-.144-.096H1.286a.348.348 0 0 0-.347.347V12.42c0 .192.156.348.347.348h11.45a.348.348 0 0 0 .347-.348V2.084a.347.347 0 0 0-.347-.347Zm0 10.719H1.286a.035.035 0 0 1-.032-.022.035.035 0 0 1-.003-.014V4.625h11.52v7.795a.035.035 0 0 1-.021.033.034.034 0 0 1-.014.003Z"
        />
        <path
          fill="#1E398F"
          d="M4.251 3.13c.087 0 .157-.07.157-.156V.875a.156.156 0 1 0-.313 0v2.1c0 .086.07.155.156.155Zm5.654 0c.086 0 .156-.07.156-.156V.875a.157.157 0 0 0-.096-.144.156.156 0 0 0-.216.144v2.1c0 .086.07.155.156.155ZM3.445 6.986a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM5.917 6.986a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM8.39 6.986a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM3.445 9.382a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM5.917 9.382a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM8.39 9.382a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM3.445 11.778a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM5.917 11.778a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM8.39 11.778a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM10.803 6.986a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM10.803 9.382a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72ZM10.803 11.778a.86.86 0 1 0 0-1.72.86.86 0 0 0 0 1.72Z"
        />
      </svg>
    )
  }
}

export default CardSchYearSvg
