import React, { Component } from 'react'
import Header from '../components/header/Header'
import Calendar from '../components/Calendar'
import { connect } from 'react-redux'
import { getCheckins } from '../actions/MainAction'
import { withHooks } from '../actions/withHooks'
import moment from 'moment';
import { getUserData } from '../actions/StaffAction'
export class CheckinByStudentTeacher extends Component {
    state={
        studentData:''
    }
    getCheckinData=(date)=>{
        this.props.getCheckins({user_id: this.props.params.student_id, month:moment(date).format("MM"), date_month:moment(date).format("YYYY-MM"), teacher_id:this.props.params.teacher_id, year:moment(date).format("YYYY") })
    }
    componentDidMount(){
        window.scrollTo(0,0);
        this.props.getUserData(this.props.params.student_id)
        .then(resp=>{
            this.setState({studentData:resp})
        });
    }
  render() {
    const {checkins} = this.props;
    const {studentData} = this.state;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={studentData?.full_name} />
            <div className='mt-[42px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <Calendar getAfterChange={(date)=>this.getCheckinData(date)} studentId={this.props.params.student_id} teacherId={this.props.params.teacher_id} checkinMode={true} checkinDates={checkins} onChange={this.getCheckinData} />
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
    checkins: state.Data.checkins
});
const mapDispatchToProps = {getCheckins, getUserData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(CheckinByStudentTeacher))
