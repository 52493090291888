import React, { Component } from 'react'

export class MusicSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={10}
        height={12}
        fill="none"
      >
        <path
          fill="#fff"
          d="M4.273 10.083c.37 0 .681-.126.934-.379s.379-.564.379-.933V6.583h1.75V5.416H5.003v2.26a1.074 1.074 0 0 0-.343-.167 1.406 1.406 0 0 0-.387-.05c-.369 0-.68.126-.933.378-.253.253-.38.564-.38.934 0 .37.127.68.38.933s.564.38.933.38Zm-2.77 1.75c-.321 0-.596-.114-.825-.343a1.121 1.121 0 0 1-.342-.824V1.333c0-.32.114-.595.343-.824.229-.229.503-.343.824-.342h4.666l3.5 3.5v7c0 .32-.114.595-.343.824a1.121 1.121 0 0 1-.823.342h-7ZM5.586 4.25h2.917L5.586 1.333V4.25Z"
        />
      </svg>
    )
  }
}

export default MusicSvg
