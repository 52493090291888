import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import Search from '../../components/Search'
import { changeStateValue } from '../../redux/MainReducer'
import { connect } from 'react-redux'
import { getPoolingExams } from '../../actions/AdminActions'
import LoadMoreBtn from '../../components/LoadMoreBtn'
import ExamModal from './exam_pool/ExamModal'
import Card1 from '../../components/cards/Card1'
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts'
import { deleteData } from '../../actions/MainAction'

export class AdminPoolingExams extends Component {
    state={
        page:1,
        search:''
    }
    componentDidMount(){
        this.props.getPoolingExams(1, "");
    }
    getDefaultSetting(){
        return {
            id:'',
            category_id:'',
            sub_category_id:'',
            section_id:'',
            count:''
        }
    }
    openModal=(data)=>{
        // console.log("sss")
        this.props.changeStateValue({
            name:"examPoolingModaldata",
            value:{
                position: true,
                modalTitle: `${data?.id == ""? "Add":"Edit"} exam`,
                id: data?.id?data?.id:'',
                title: data?.title?data?.title:'',
                duration_minutes: data?.duration_minutes?data?.duration_minutes:'',
                retake_minutes: data?.retake_minutes?data?.retake_minutes:'',
                retake_time: data?.retake_time?data?.retake_time:'',
                points: data?.points?data?.points:'',
                // active_status: data?.active_status?data?.active_status:'',
                type: data?.type?data?.type:'0',
                exam_type: data?.exam_type?data?.exam_type:'0',
                standart_exam_settings: data?.standart_exams? data?.standart_exams:[this.getDefaultSetting()],
                getAfterFunction: this.props.getPoolingExams,
                afterParams: [this.state.page, this.state.search]
            }
        })
    }
    onSearchData=(search) => {
        this.setState({search});
        this.props.getPoolingExams(this.state.page, search);
    }
    loadMore=(page) => {
        this.setState({page});
        this.props.getPoolingExams(page, this.state.search);
    }

    onDeleteData=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
          if(alertResp === "success"){
            this.props.deleteData(`exams/${data?.id}`)
            .then(resp=>{
              if(resp == "success"){
                this.props.getPoolingExams(this.state.page, this.state.search);
                mixinAlert("success", "Data deleted successfully")
              }else{
                mixinAlert("error", "Something went wrong")
              }
            })
          }
        })
    }
  render() {
    const {poolingExams, examPoolingModaldata, user} = this.props;
    // console.log(poolingExams)

    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Pooling exams" />
            <div className='flex justify-end mt-[20px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
           
            <HeaderButtons onClick={this.openModal.bind(this, '')} buttons={[{title: "Add Exam", id:0}]} selected={0} />
            </div>
            <div className='flex justify-center '>
                <Search largWidth={true} onSearchData={this.onSearchData} />
            </div>
            <div className='grid-full-250 mt-[20px]'>
            {
                poolingExams.map((data,i)=>{
                    return(
                        <Card1
                            key={i}
                            title={data?.standart_exams[0]?.category?.title}
                            exam={data?.title}
                            close={data?.standart_exams[0]?.section?.title}
                            status={1}
                            statusBisible={false}
                            // btn={true}
                            // url={`/admin-exams/${data?.id}`}
                            blank={true}
                            // activeStatus={data?.active_status}
                            // adminActiveVisible={user?.role === "super_admin"}
                            // onClickChangeStatus={this.changeStatus.bind(this, data)}
                            onCLickBtn={this.openModal.bind(this, data)}
                            adminDelete={user?.role === "super_admin"}
                            onDeleteBtn={this.onDeleteData.bind(this, data)}
                            user={data?.user?.full_name}
                        />
                    )
                })
            }
        </div>
            {
                poolingExams?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={poolingExams?.length} />:null
            }
            {examPoolingModaldata?.position?  <ExamModal /> :null}
           
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
    poolingExams: state.Data.poolingExams,
    examPoolingModaldata: state.Data.examPoolingModaldata,
    user: state.Data.user,
});
const mapDispatchToProps = {changeStateValue, getPoolingExams, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminPoolingExams)
